import diff from 'diff-arrays-of-objects';

export default function _({
  outrosLocacao,
  outrosInput,
}) {
  // se opção não foi gravada no banco antes
  if (Object.values(outrosLocacao || []).length <= 0 &&
    Object.values(outrosInput || []).length <= 0) {
    return []
  }
  
  // se a opção não for mais selecionada
  if (Object.values(outrosInput || []).length <= 0) {
    return outrosLocacao?.map(({uuid}) => ({uuid, outro_finalidade: ''}))
  }

  // cria listas temporarias dos cards
  const outrosLocacaoTmp = outrosLocacao?.map(({uuid, outro_finalidade, ld_finalidade_acesso}) => 
    ({ uuid, outro_finalidade, finalidade_acesso_uuid: ld_finalidade_acesso?.uuid }));
  const outrosInputTmp = outrosInput.map(({uuid, outro_finalidade, finalidade_acesso_uuid}) => 
    ({ uuid: uuid || '', outro_finalidade, finalidade_acesso_uuid }));  

  // ve se tem diferença entre os cards
  const results = diff(outrosLocacaoTmp, outrosInputTmp, 'uuid');
  const { updated, added, removed } = results;

  const outrosDiff = added.concat(updated, removed?.map(({uuid}) => ({ uuid, outro_finalidade: '' })));
  return outrosDiff;
}
