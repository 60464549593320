import styled from 'styled-components';
import { ButtonGroup, MenuList } from '@material-ui/core';

export const StyledButtonGroup = styled(ButtonGroup)`
  background-color: #26a2b1;
  border-radius: 50px !important;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.98);
  }

  &[disabled] {
    border: none;
  }

  & > button {
    &[disabled] {
      color: #ffffff;
      border: none;
    }

    &:hover {
      background-color: #26a2b1;
    }

    border-radius: 50px !important;
    text-transform: capitalize;
    border: none;
    color: #ffffff;
    font-family: 'InterSemiBold';
    font-size: 16px;
    line-height: 20px;
  }

  & > button.default-button {
    padding: 0 40px;
  }

  & > button.arrow-button {
    border-left: 1px #ffffff5c solid;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    padding: 0 10px;

    & svg {
      font-size: 30px;
    }
  }

  .icon-spin {
    color: #ffffff;
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export const StyledMenuList = styled(MenuList)`
  & li {
    font-family: 'InterRegular';
    font-size: 16px;
    line-height: 20px;
  }
`;
