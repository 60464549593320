import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import * as Yup from 'yup';
import { FiLoader } from 'react-icons/fi';
import { isAfter, parseISO } from 'date-fns';
import { Input } from '~/components/Forms';

import { PageContainer, TableHeader } from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';

import { Wrap, WrapButtons } from './styles';

import Button from '~/components/Button';
import { ListaPageObras, NavBarHeader } from '~/components/Usuario';
import { showToastMessage } from '~/services/notification';

import userMeAction from '../../../store/ducks/userMe';
import ApiClient from '~/utils/ApiClient';

import { useCurrentUser } from '~/hooks/currentUser';
import { useAuth } from '~/hooks/auth';
import LogoIcon from '~/components/LogoIcon';
import Load from '~/components/Load';
import SolicitacaoObras from '~/pages/Usuario/Home/components/SolicitacaoObras';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';
import buildQueryPackageFilters from '~/functions/buildQueryPackageFilters';

export default function HomeUser() {
  const dispatch = useDispatch();
  const { user: userPF } = useAuth();
  const { user } = useCurrentUser();
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dataWorkList, setDataWorkList] = useState([]);
  const [filters, setFilters] = useState('');
  const [searchFormData, setSearchFormData] = useState({});

  const formRef = useRef();

  const { data: userData } = useSelector(state => state.userMe);

  const location = useLocation();
  // const { state } = location;

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  const userMe = useCallback(() => {
    dispatch(userMeAction.showRequest());
  }, [dispatch]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      let result = {};

      if (user?.cnpj) {
        result = await ApiClient.doRequest({
          method: ApiClient.GET,
          uri: `work-search-type?=desc&filters[]uuid=${user?.uuid}&page=${page}${filters}`,
          expectedStatus: 200,
        });
      }
      if (userPF?.uuid && !user?.cnpj) {
        result = await ApiClient.doRequest({
          method: ApiClient.GET,
          uri: `work-search-type?page=${page}${filters}`,
          expectedStatus: 200,
        });
      }

      setTotPage(result.data?.meta?.pagination?.total_pages);

      setDataWorkList(result.data?.data);
    } catch (e) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao listar obras',
      });
    } finally {
      setLoading(false);
    }
  }, [filters, page, user?.cnpj, user?.uuid, userPF?.uuid]);

  // avisos
  const remindersListFetch = useCallback(async () => {
    setLoading(true);
    const reminders = await ApiClient.doRequest({
      method: ApiClient.GET,
      uri: `reminder?order[id]=desc&filters[][reminder_type.name@equal]=Aviso&paginate=3`,
    });

    reminders?.data?.data
      ?.filter(reminder => isAfter(parseISO(reminder.ends), new Date()))
      .forEach(reminder =>
        showToastMessage({
          type: 'info',
          text: `${reminder.title} - ${reminder.text}`,
          timer: 15,
        }),
      );
    setLoading(false);
  }, []);

  useEffect(() => {
    userMe();
    remindersListFetch();
    fetchData();
  }, [userMe, remindersListFetch, fetchData]);

  const renderList = useCallback(() => {
    if (loading) return <FiLoader size={20} className="icon-spin" />;

    if (!loading && dataWorkList?.length <= 0) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {dataWorkList?.map(lista => (
          <ListaPageObras key={lista.id} data={lista} />
        ))}
      </>
    );
  }, [dataWorkList, loading]);

  function handleResetSearchFields() {
    setPage(1);
    setFilters('');
    setSearchFormData({
      created_at: { value: '' },
      protocol: { value: '' },
      titulo_obra: { value: '' },
    });
  }
  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    setSearchFormData(state => ({
      ...state,
      [name]: {
        value: type === 'checkbox' ? checked : value,
        filterName: e.target?.getAttribute('filterName'),
        filterOperator: e.target?.getAttribute('filterOperator'),
      },
    }));
  }

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          titulo_obra: Yup.string().test(
            'titulo_obra',
            'Informe ao menos 2 caracteres para buscar',
            value => !(value && value?.length < 2),
          ),
          protocol: Yup.string().test(
            'protocol',
            'Informe ao menos 2 caracteres para buscar',
            value => !(value && value?.length < 2),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setPage(1);
        setFilters(buildQueryPackageFilters(searchFormData));
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [searchFormData],
  );

  return (
    <>
      <Load inLoad={loading} />

      <Header>
        <LogoIcon />
        <NavBarHeader />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Acompanhamento de obras</h1>
            <span>Home | Obras</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      <PageContainer>
        <PageContent>
          <WhiteBox column aCenter marginBottom="30px">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Wrap>
                <Input
                  type="date"
                  name="created_at"
                  label="Data de Criação"
                  value={searchFormData.created_at?.value}
                  onChange={handleChange}
                  filterName="created_at"
                  filterOperator="equal"
                />
                <Input
                  type="text"
                  name="protocol"
                  label="N.º da Obra"
                  value={searchFormData.protocol?.value}
                  onChange={handleChange}
                  filterOperator="equal"
                  filterName="protocol"
                />
                <Input
                  type="text"
                  name="titulo_obra"
                  label="Nome da Obra"
                  value={searchFormData.titulo_obra?.value}
                  onChange={handleChange}
                  filterOperator="equal"
                  filterName="titulo_obra"
                />
                <WrapButtons>
                  <Button
                    type="submit"
                    btType="#26A2B1"
                    label="Buscar"
                    full
                    width="170px"
                  />
                  <Button
                    btType="#707683"
                    onClick={handleResetSearchFields}
                    label="Limpar"
                    full
                    width="100px"
                  />
                </WrapButtons>
              </Wrap>
            </Form>
            <table style={{ width: '95%' }}>
              <TableHeader style={{ justifyContent: 'space-evenly' }}>
                <tr>
                  <th style={{ paddingLeft: 10 }}>Data de Cadastro</th>
                  <th>Nº da Obra</th>
                  <th>Nome</th>
                  <th>Produtor</th>
                  <th>Status</th>
                  <th>
                    {
                      clientResources.labels.homeUser
                        .responsavelSpFilmColumnObrasTable
                    }
                  </th>
                  <th>Produtora</th>
                  <th style={{ paddingRight: 10 }}>
                    <Link
                      to={{
                        pathname: '/solicitacao-nova-obra',
                      }}
                    >
                      <Button
                        btType="#26A2B1"
                        label="Nova"
                        full
                        width="170px"
                      />
                    </Link>
                  </th>
                </tr>
              </TableHeader>
              <tbody style={{ width: '95%' }}>{renderList()}</tbody>
            </table>
            <BasicPagination />
          </WhiteBox>

          <SolicitacaoObras />
        </PageContent>
      </PageContainer>

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
