import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '~/services/notification';

import api from '~/services/api';
import PasswordActions from '../ducks/create-password';

export function* create(action) {
  try {
    const { data: post } = action;

    const { data } = yield call(api.patch, `/user/create-password`, post);

    yield put(PasswordActions.createSuccess(data));

    showToastMessage({
      type: 'success',
      text: 'Senha cadastrada com sucesso',
    });

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (err) {
    yield put(PasswordActions.createFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}
