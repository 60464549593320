import diff from 'diff-arrays-of-objects';
import { isSafari } from 'react-device-detect';
import { getBrowserTransformDate } from '~/pages/Usuario/Locacoes/functions/date-hour-utils';

export default function diffDatasFilmagem({
  datasFilmagemLocacao,
  datasFilmagemInput,
  dateKeyName = 'date',
}) {
  // if equals
  if (
    JSON.stringify(datasFilmagemLocacao.sort()) ===
    JSON.stringify(datasFilmagemInput.sort())
  ) {
    return null;
  }

  const results = diff(datasFilmagemLocacao, datasFilmagemInput, 'uuid');

  const { added, updated, removed } = results;

  const datasFilmagemDiff = added.concat(
    updated,
    removed?.map(data => ({ uuid: data.uuid, [dateKeyName]: '' })),
  );

  return isSafari
    ? datasFilmagemDiff.map(data => ({
        ...data,
        [dateKeyName]: getBrowserTransformDate(data[dateKeyName]),
      }))
    : datasFilmagemDiff;
}
