/* eslint-disable no-case-declarations */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { AiOutlineMail } from 'react-icons/ai';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { BsBell, BsCardChecklist, BsFillEyeFill } from 'react-icons/bs';
import Swiper from 'react-id-swiper';
import CryptoJS from 'crypto-js';

import Modal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { format, parseISO } from 'date-fns';
import { Form } from '@unform/web';

import { ApprovalContainer, PageContainer } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import {
  Container,
  Cards,
  CardImage,
  Notification,
  CardTitle,
  HeaderBox,
  Lembretes,
  CardLembrete,
  DateLembrete,
  DescriptionLembrete,
  ModalContent,
  overlayStyle,
  contentStyle,
  ButtonModal,
  RegAtivSideLeft,
  RegAtivSideRight,
  Timeline,
  CardAtividade,
  Separator,
  CardAtivField,
  Wrap,
  EmptyListTest,
  Arrow,
  RegistroContainer,
  ViewLinkWrapper,
} from './styles';

import { ModalLembrete } from '~/components/Admin';

import Locacoes from '~/assets/images/icons/Locacoes.svg';
import Obras from '~/assets/images/icons/Obras.svg';

import { Input } from '~/components/Forms';
import ApiClient from '~/utils/ApiClient';
import DateUtil from '~/utils/DateUtil';
import Load from '~/components/Load';
import { showToastMessage } from '~/services/notification';
import validateDate from '~/functions/validateDate';
import WhiteBox from '~/components/WhiteBox';
import tenancy_env from '~/utils/MultiTenancyUtil';

const ITEM_TYPE_COLOR = {
  Locacoes: '#123112',
  Obras: '#126576',
};

function Home() {
  const formRef = useRef();
  const obrasListRef = useRef();

  const [swiper, updateSwiper] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totPages, setTotPages] = useState(1);
  const [openModalLembrete, setOpenModalLembrete] = useState(false);
  const [valueData, setValueData] = useState(new Date());
  const [formData, setFormData] = useState({});
  const [dataLembretes, setDataLembretes] = useState([]);
  const [atividadesList, setAtividadesList] = useState([]);
  const [inLoad, setInLoad] = useState(false);
  const [dataDashboard, setDataDashboard] = useState([]);
  // const [tipoObraFilterSelecionada, setTipoObraFilterSelecionada] = useState([
  //   'Locacoes',
  //   'Obras',
  // ]);
  const [tabIndex, setTabIndex] = useState(0);

  const params = {
    swiper,
    slidesPerView: 3,
    spaceBetween: 30,
    initialSlide: 0,
    loop: false,
    freeMode: true,
    getSwiper: updateSwiper,
    autoplay: {
      delay: 4000,
    },
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  // function handleTipoObraFilterClick(tipoObra) {
  //   const tipoObraEncontrada = tipoObraFilterSelecionada.findIndex(
  //     item => item === tipoObra,
  //   );

  //   if (tipoObraEncontrada < 0) {
  //     setTipoObraFilterSelecionada(state => [...state, tipoObra]);
  //   } else {
  //     const filtrada = tipoObraFilterSelecionada.filter(
  //       item => item !== tipoObra,
  //     );
  //     setTipoObraFilterSelecionada(filtrada);
  //   }
  // }

  async function handleSubmit() {
    try {
      formRef.current.setErrors({});
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function handleValueData(value) {
    setValueData(value);
  }

  async function load() {
    try {
      setInLoad(true);

      const result = await ApiClient.doRequest({
        uri: 'reminder?order[id]=desc',
        method: ApiClient.GET,
      });

      const dasboardResponse = await ApiClient.doRequest({
        uri: 'dashboard-total',
        method: ApiClient.GET,
      });

      setDataLembretes(result.data.data ?? []);
      setDataDashboard(dasboardResponse.data.data ?? []);
    } catch (e) {
      showToastMessage({
        type: 'error',
        text: `Erro: ${e.message}`,
      });
    } finally {
      setInLoad(false);
    }
  }

  const renderAtividades = useCallback(async () => {
    try {
      switch (tabIndex) {
        case 0:
          const filterPrevisaoInicioFilmagem =
            formData?.filter_previsao_inicio_filmagem
              ? `&filters[][visit_date@like]=${formData?.filter_previsao_inicio_filmagem}`
              : '';

          setInLoad(true);
          const resp = await ApiClient.doRequest({
            uri: `work?page=${page}&order[id]=desc${filterPrevisaoInicioFilmagem}`,
            method: ApiClient.GET,
            expectedStatus: 200,
          });

          setTotPages(resp.data.meta.pagination.total_pages);

          setAtividadesList(state => [
            ...state,
            ...resp?.data?.data.map(atividade => ({
              type: 'Obras',
              ...atividade,
            })),
          ]);

          break;
        case 1:
          let filterPeriodo = '';

          if (
            formData?.filter_data_inicial_periodo &&
            formData?.filter_data_final_periodo
          ) {
            filterPeriodo = `&inicio=${formData?.filter_data_inicial_periodo}&fim=${formData?.filter_data_final_periodo}`;
          }

          setInLoad(true);
          const respLoc = await ApiClient.doRequest({
            uri: `registro-atividade?page=${page}${filterPeriodo}`,
            method: ApiClient.GET,
            expectedStatus: 200,
          });

          setTotPages(respLoc.data.last_page);

          setAtividadesList(state => [
            ...state,
            ...respLoc?.data?.data.map(atividade => {
              const ativ = {
                type: 'Locacoes',
                ...atividade,
              };

              const templateDataLocacao = {
                'Locação Acesso Calçadões': {
                  ...ativ,
                  local:
                    atividade?.address_filmagem || atividade?.address_calcadao,
                },
                'Locação Feira Livre': {
                  ...ativ,
                  local: atividade?.address,
                },
                'Locação Autódromo': {
                  ...ativ,
                  local: atividade?.ld_lista_autodromo?.name,
                },
                'Locação Cemitério': {
                  ...ativ,
                  local:
                    atividade?.ld_cemiterio?.nome === 'Outro'
                      ? atividade?.outro_cemiterio
                      : atividade?.ld_cemiterio?.nome,
                },
                'Locação Galeria': {
                  ...ativ,
                  local: atividade?.ld_galeria?.name,
                },
                'Locação Filmagem Mercado': {
                  ...ativ,
                  local:
                    atividade?.l_mercados?.name === 'Outro'
                      ? atividade?.outro_mercado
                      : atividade?.l_mercados?.name,
                },
                'Locação Filmagem Parque': {
                  ...ativ,
                  local:
                    atividade?.l_parques?.name === 'Outro'
                      ? atividade?.outro_parque
                      : atividade?.l_parques?.name ?? 'Não informado',
                },
                'Locação Planetário': {
                  ...ativ,
                  local: atividade?.ld_planetario?.name,
                },
                'Locação Teatro': {
                  ...ativ,
                  local: atividade?.ld_teatro?.name,
                },
                'Locação Terminal': {
                  ...ativ,
                  local: atividade?.ld_terminais?.name,
                },
                'Locação Praça Pública': {
                  ...ativ,
                  address: atividade?.street,
                },
                'Locação Camera Car': {
                  ...ativ,
                  address: atividade?.endereco_filmagem ?? 'Não informado',
                },
                'Locação Projeção Pública': {
                  ...ativ,
                  address: `${atividade?.address_local}, ${atividade?.number_local}, ${atividade?.neighborhood_local}`,
                },
                'Locação Outros Espaços': {
                  ...ativ,
                  address: atividade?.address,
                },
                'Locação Espaços Cultura': {
                  ...ativ,
                  address: atividade?.endereco_local,
                },
                'Locação Espaços SMAD': {
                  ...ativ,
                  address: atividade?.address,
                },
                'Locação Utilização de Via': {
                  ...ativ,
                  address:
                    atividade?.address_temp ||
                    `${atividade?.address}, ${atividade?.number}, ${atividade?.neighborhood}, ${atividade?.ld_complemento_numero?.name} ${atividade?.via_sentido}`,
                },
                'Locação Reserva de Vaga': {
                  ...ativ,
                  address: `${atividade?.street}, ${atividade?.number}, ${atividade?.neighborhood}. CEP: ${atividade?.cep}`,
                },
              };

              return { ...(templateDataLocacao[atividade.name_view] || ativ) };
            }),
          ]);

          break;
        default:
          break;
      }

      // atividadesAux.sort(
      //   (a, b) => new Date(b.created_at) - new Date(a.created_at),
      // );
    } catch (error) {
      setAtividadesList([]);
      showToastMessage({
        type: 'error',
        text: 'Erro ao buscar registros de atividades',
      });
    } finally {
      setInLoad(false);
    }
  }, [
    formData?.filter_data_final_periodo,
    formData?.filter_data_inicial_periodo,
    formData?.filter_previsao_inicio_filmagem,
    page,
    tabIndex,
  ]);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    renderAtividades();
  }, [renderAtividades]);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  const resetData = () => {
    setAtividadesList([]);
    setPage(1);
    setTotPages(1);
  };

  function handleChange(event) {
    resetData();
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  const renderLembretes = dataLembretes.map(lembrete => {
    if (!new DateUtil(lembrete.ends).isBeforeNow()) {
      return <></>;
    }

    return (
      <CardLembrete key={lembrete.uuid}>
        <DateLembrete>
          {DateUtil.raw(lembrete.starts).toDateTime()}
        </DateLembrete>
        <DescriptionLembrete>{lembrete.text}</DescriptionLembrete>
      </CardLembrete>
    );
  });

  const renderTimeline = () => {
    if (!inLoad && atividadesList?.length <= 0)
      return (
        <EmptyListTest style={{ textAlign: 'center' }}>
          Não há registros
        </EmptyListTest>
      );

    return atividadesList?.map(atividade => (
      <CardAtividade
        key={atividade.uuid}
        tagColor={ITEM_TYPE_COLOR[atividade.type]}
      >
        {atividade.type === 'Obras' && (
          <>
            <Wrap>
              <CardAtivField>
                <h5>Protocolo:</h5>
                <p>{atividade.protocol}</p>
              </CardAtivField>
              <CardAtivField>
                <h5>Nome:</h5>
                <p>{atividade.titulo_obra}</p>
              </CardAtivField>
              <CardAtivField>
                <h5>Data/Hora da criação:</h5>
                <p>
                  {atividade.created_at &&
                    format(new Date(atividade.created_at), 'dd/MM/yyyy HH:mm')}
                </p>
              </CardAtivField>
            </Wrap>
            <Wrap>
              <CardAtivField>
                <h5>Data prevista para início da filmagem:</h5>
                <p>
                  {atividade?.visit_date?.changes_to_approve ? (
                    <ApprovalContainer
                      hasApprovalFields
                      style={{
                        cursor: 'help',
                      }}
                    >
                      <span tooltip="Alteração a ser analisada pela SPFilm">
                        {validateDate(
                          atividade?.visit_date?.value,
                          'yyyy-MM-dd HH:mm:ss',
                        )
                          ? format(
                              parseISO(atividade?.visit_date?.value),
                              'dd/MM/yyyy',
                            )
                          : ''}
                      </span>
                    </ApprovalContainer>
                  ) : (
                    <span>
                      {validateDate(
                        atividade?.visit_date?.value,
                        'yyyy-MM-dd HH:mm:ss',
                      )
                        ? format(
                            parseISO(atividade?.visit_date?.value),
                            'dd/MM/yyyy',
                          )
                        : ''}
                    </span>
                  )}
                </p>
              </CardAtivField>
              {atividade.responsavel_user?.user_personal_data?.name && (
                <CardAtivField>
                  <h5>Responsável pelo atendimento:</h5>
                  <p>{atividade.responsavel_user?.user_personal_data?.name}</p>
                </CardAtivField>
              )}
            </Wrap>
            <Wrap style={{ marginBottom: 0 }}>
              <ViewLinkWrapper
                to={{
                  pathname: 'redireciona-endpoint',
                  search: `query=${encodeURIComponent(
                    CryptoJS.AES.encrypt(
                      JSON.stringify({
                        is_admin: true,
                        uuid: atividade?.uuid,
                        endpoint: 'obra',
                        mode: 'view',
                      }),
                      tenancy_env('REACT_APP_API_BASE_URL'),
                    ).toString(),
                  )}`,
                }}
                target="_blank"
              >
                <span>Visualizar</span>
                <BsFillEyeFill color="#4f4f4f" size={24} />
              </ViewLinkWrapper>
            </Wrap>
          </>
        )}

        {atividade.type === 'Locacoes' && (
          <>
            <Wrap>
              <CardAtivField>
                <h5>Protocolo:</h5>
                <p>{atividade.protocol}</p>
              </CardAtivField>
              <CardAtivField>
                <h5>Tipo:</h5>
                <p>{atividade.name_view}</p>
              </CardAtivField>
              <CardAtivField>
                <h5>Nome:</h5>
                <p>{atividade.titulo_locacao || 'Não informado'}</p>
              </CardAtivField>
              {atividade?.work?.responsavel_user?.user_personal_data?.name && (
                <CardAtivField>
                  <h5>Responsável pelo atendimento:</h5>
                  <p>
                    {
                      atividade?.work?.responsavel_user?.user_personal_data
                        ?.name
                    }
                  </p>
                </CardAtivField>
              )}
            </Wrap>
            {[
              'Locação Autódromo',
              'Locação Cemitério',
              'Locação Galeria',
              'Locação Filmagem Mercado',
              'Locação Filmagem Parque',
              'Locação Planetário',
              'Locação Teatro',
              'Locação Terminal',
            ].includes(atividade.name_view) && (
              <Wrap>
                <CardAtivField>
                  <h5>Local:</h5>
                  <p>{atividade.local}</p>
                </CardAtivField>
              </Wrap>
            )}

            {[
              'Locação Espaços SMAD',
              'Locação Espaços Cultura',
              'Locação Outros Espaços',
              'Locação Projeção Pública',
              'Locação Praça Pública',
              'Locação Utilização de Via',
              'Locação Reserva de Vaga',
              'Locação Camera Car',
            ].includes(atividade.name_view) && (
              <Wrap>
                <CardAtivField>
                  <h5>Endereço:</h5>
                  <p>{atividade.address}</p>
                </CardAtivField>
              </Wrap>
            )}

            <Wrap style={{ marginBottom: 0 }}>
              <ViewLinkWrapper
                to={{
                  pathname: 'redireciona-endpoint',
                  search: `query=${encodeURIComponent(
                    CryptoJS.AES.encrypt(
                      JSON.stringify({
                        is_admin: true,
                        uuid: atividade?.uuid,
                        endpoint: atividade?.endpoint,
                        mode: 'view',
                        nome_projeto: atividade?.work?.nome_projeto,
                      }),
                      tenancy_env('REACT_APP_API_BASE_URL'),
                    ).toString(),
                  )}`,
                }}
                target="_blank"
              >
                <span>Visualizar</span>
                <BsFillEyeFill color="#4f4f4f" size={24} />
              </ViewLinkWrapper>
            </Wrap>
          </>
        )}
      </CardAtividade>
    ));
  };

  const handleScrollTimeline = e => {
    const element = e.target;
    const height = element.scrollHeight - element.clientHeight;
    const percentScroll = Math.round((element.scrollTop / height) * 100);

    if (percentScroll === 100 && page < totPages) {
      element.scrollTop -= 100;
      setPage(page + 1);
    }
  };

  const renderPanel = () => {
    return (
      <RegistroContainer>
        <RegAtivSideLeft>
          <h2>Registro de Atividades</h2>
          {formData && (
            <Form ref={formRef} onSubmit={handleSubmit}>
              {tabIndex === 0 && (
                <Input
                  type="date"
                  name="filter_previsao_inicio_filmagem"
                  label="Previsão de início da filmagem (Obra)"
                  onChange={handleChange}
                  value={formData?.filter_previsao_inicio_filmagem}
                />
              )}
              {/* <ObraTypeFiltersContainer>
                      <ObraTypeFilter
                        onClick={() => handleTipoObraFilterClick('Locacoes')}
                        tagColor={ITEM_TYPE_COLOR['Locacoes']}
                        className={`${
                          !tipoObraFilterSelecionada.includes('Locacoes') &&
                          'filter-selected'
                        }`}
                      >
                        Locações
                      </ObraTypeFilter>
                      <ObraTypeFilter
                        onClick={() => handleTipoObraFilterClick('Obras')}
                        tagColor={ITEM_TYPE_COLOR['Obras']}
                        className={`${
                          !tipoObraFilterSelecionada.includes('Obras') &&
                          'filter-selected'
                        }`}
                      >
                        Obras
                      </ObraTypeFilter>
                    </ObraTypeFiltersContainer> */}
              {tabIndex === 1 && (
                <>
                  <Wrap>
                    <Input
                      type="date"
                      name="filter_data_inicial_periodo"
                      label="Data inicial do período"
                      onChange={handleChange}
                      value={formData?.filter_data_inicial_periodo}
                    />
                  </Wrap>
                  <Wrap>
                    <Input
                      type="date"
                      name="filter_data_final_periodo"
                      label="Data final do período"
                      onChange={handleChange}
                      value={formData?.filter_data_final_periodo}
                    />
                  </Wrap>
                </>
              )}
            </Form>
          )}
        </RegAtivSideLeft>
        <RegAtivSideRight>
          <Separator />
          <Timeline ref={obrasListRef} onScroll={handleScrollTimeline}>
            <div style={{ width: '100%' }}>{renderTimeline()}</div>
          </Timeline>
        </RegAtivSideRight>
      </RegistroContainer>
    );
  };

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Load inLoad={inLoad} />
        <Cards>
          <Swiper {...params}>
            <WhiteBox aCenter maxWidth="460px" cursorPointer marginBottom>
              <a href="/locacoes">
                <CardImage>
                  <Notification>{dataDashboard?.novas_locaco}</Notification>
                  <img src={Locacoes} alt="Locações" />
                </CardImage>
                <CardTitle>Novas locações</CardTitle>
              </a>
            </WhiteBox>

            <WhiteBox aCenter maxWidth="460px" cursorPointer marginBottom>
              <a href="/obras">
                <CardImage>
                  <Notification>{dataDashboard.novas_obra}</Notification>
                  <img src={Obras} alt="Obras" />
                </CardImage>
                <CardTitle>Novas obras</CardTitle>
              </a>
            </WhiteBox>

            <WhiteBox aCenter maxWidth="460px" cursorPointer marginBottom>
              <a href="/visitas-tecnicas">
                <CardImage>
                  <Notification>{dataDashboard.visita_formulario}</Notification>
                  <BsCardChecklist color="#26A2B1" size={72} />
                </CardImage>
                <CardTitle>Visitas</CardTitle>
              </a>
            </WhiteBox>

            <WhiteBox aCenter maxWidth="460px" cursorPointer marginBottom>
              <a href="/cartas-de-apoio">
                <CardImage>
                  <Notification>
                    {dataDashboard.visita_carta_apoio}
                  </Notification>
                  <AiOutlineMail color="#26A2B1" size={72} />
                </CardImage>
                <CardTitle>Cartas de Apoio</CardTitle>
              </a>
            </WhiteBox>

            <WhiteBox aCenter maxWidth="460px" cursorPointer marginBottom>
              <a href="/avisos">
                <CardImage>
                  <Notification>{dataDashboard.visita_aviso}</Notification>
                  <BsBell color="#26A2B1" size={72} />
                </CardImage>
                <CardTitle>Avisos</CardTitle>
              </a>
            </WhiteBox>
          </Swiper>

          <Arrow direction="left" onClick={goPrev}>
            <MdChevronLeft />
          </Arrow>
          <Arrow direction="right" onClick={goNext}>
            <MdChevronRight />
          </Arrow>
        </Cards>

        <WhiteBox column aCenter jCenter>
          <HeaderBox>
            <h2>Últimos Lembretes</h2>
            <Button
              type="submit"
              btType="#26A2B1"
              action={() => setOpenModalLembrete(true)}
              label="Adicionar Lembrete"
              full
              width="190px"
            />
          </HeaderBox>
          <Lembretes>{renderLembretes}</Lembretes>
        </WhiteBox>

        <WhiteBox marginTop="32px">
          <Tabs
            style={{ width: '100%' }}
            selectedIndex={tabIndex}
            onSelect={index => {
              setTabIndex(index);
              resetData();
              setFormData({});
            }}
          >
            <TabList style={{ border: 0 }}>
              <Tab>Obra</Tab>
              <Tab>Locações</Tab>
            </TabList>
            <TabPanel>{renderPanel()}</TabPanel>
            <TabPanel>{renderPanel()}</TabPanel>
          </Tabs>
        </WhiteBox>
      </Container>
      <Modal
        ariaHideApp={false}
        onRequestClose={handleCloseModal}
        isOpen={open}
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <ButtonModal onClick={handleCloseModal}>X</ButtonModal>
        <ModalContent>
          <Calendar
            value={valueData}
            onChange={handleValueData}
            locale="pt-BR"
            className="calendario"
          />
        </ModalContent>
      </Modal>
      <ModalLembrete
        openModal={openModalLembrete}
        closeModal={() => setOpenModalLembrete(false)}
        loadReminder={load}
      />
    </PageContainer>
  );
}

export default withRouter(Home);
