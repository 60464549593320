/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

import * as Yup from 'yup';
import { Form } from '@unform/web';

import { useSelector, useDispatch } from 'react-redux';
import PasswordActions from '~/store/ducks/create-password';

import Button from '~/components/Button';

import { Input } from '~/components/Forms';

import {
  PageContainer,
  Container,
  Content,
  Wrap,
  Logotips,
  HeroImage,
} from './styles';

import { showModalMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import clientResources from '~/data/clientResources';

export default function CriarSenha() {
  const formRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealRepeatPassword, setRevealRepeatPassword] = useState(false);
  const token = useLocation().search?.split('token=')[1]?.split('&')[0];
  const [loading, setLoading] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleSubmit() {
    try {
      if (!token) {
        await showModalMessage({
          type: 'error',
          text: 'Token não informado',
        });
        return;
      }

      formRef.current.setErrors({});
      formData.token = token;
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        email: Yup.string().typeError(defaultMsg).required(defaultMsg),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      if (formData.password !== formData.password_confirmation) {
        await showModalMessage({
          type: 'error',
          text: 'As senhas não coincidem.',
        });
        return;
      }

      if (formData.password?.length < 6) {
        await showModalMessage({
          type: 'error',
          text: 'Senha deve possuir 6 ou mais caracteres',
        });
        return;
      }

      setLoading(true);

      await ApiClient.doRequest({
        uri: 'user/create-password',
        auth: false,
        method: ApiClient.PATCH,
        expectedStatus: 201,
        body: formData,
      });

      await showModalMessage({
        type: 'success',
        text: 'Senha cadastrada com sucesso',
      });

      history.push('/login');
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: JSON.parse(err.message)?.error?.error?.message,
      });

      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <PageContainer background={clientResources.images.login.heroLoginBg}>
        <Header>
          <LogoIcon disabled />
          <Button btType="#26A2B1" label="Cadastrar" full width="167px" />
        </Header>
        <Container>
          <Content>
            <h1>Crie sua senha</h1>
            {formData && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Wrap>
                  <Input
                    name="email"
                    label="E-mail"
                    onChange={handleChange}
                    value={formData.email}
                  />
                </Wrap>
                <Wrap>
                  <button
                    className="eye"
                    type="button"
                    onClick={() => setRevealPassword(!revealPassword)}
                  >
                    {revealPassword === true ? (
                      <BsFillEyeSlashFill color="#99A6B7" size={24} />
                    ) : (
                      <BsFillEyeFill color="#99A6B7" size={24} />
                    )}
                  </button>
                  <Input
                    name="password"
                    label="Senha"
                    placeholder="Senha"
                    onChange={handleChange}
                    type={revealPassword === true ? 'text' : 'password'}
                    value={formData.password}
                  />
                </Wrap>
                <Wrap>
                  <button
                    className="eye"
                    type="button"
                    onClick={() =>
                      setRevealRepeatPassword(!revealRepeatPassword)
                    }
                  >
                    {revealRepeatPassword === true ? (
                      <BsFillEyeSlashFill color="#99A6B7" size={24} />
                    ) : (
                      <BsFillEyeFill color="#99A6B7" size={24} />
                    )}
                  </button>
                  <Input
                    name="password_confirmation"
                    label="Confirmar senha"
                    placeholder="Repita a senha"
                    onChange={handleChange}
                    type={revealRepeatPassword === true ? 'text' : 'password'}
                    value={formData.password_confirmation}
                  />
                </Wrap>
                <Wrap className="forgot-password">
                  <Button
                    type="submit"
                    btType="#26A2B1"
                    label="Enviar"
                    full
                    width="140px"
                    loading={loading}
                  />
                </Wrap>
              </Form>
            )}
          </Content>
        </Container>
        <Logotips>
          <img
            src={clientResources.images.system.spcineLogo}
            alt={clientResources.images.system.altLogo}
          />
          <img
            src={clientResources.images.system.culturaSpLogo}
            alt={clientResources.images.system.altCultura}
          />
        </Logotips>
      </PageContainer>
    </>
  );
}
