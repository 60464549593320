import React from 'react';
import { isSafari } from 'react-device-detect';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import ReactInputMask from 'react-input-mask';
import { Container, StyledInput } from './styles';

const InputNoForm = ({
  type,
  acceptPast,
  name,
  required,
  questionTooltip,
  questionOnClick,
  label,
  mask,
  ...props
}) => {
  const handleBlockText = event => {
    if (/[a-zA-Z]/.test(event.key)) {
      event.preventDefault();
    }
  };

  if (isSafari && type === 'time') {
    return (
      <Container>
        <label htmlFor={name}>
          {label}
          {label && required && <span className="danger">*</span>}
          {questionTooltip && (
            <button
              type="button"
              tooltip={questionTooltip}
              onClick={questionOnClick}
              style={{ height: 0 }}
            >
              <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
            </button>
          )}
        </label>
        <StyledInput
          id={name}
          name={name}
          type="text"
          pattern="/^[0-2]{1}[0-3]{1}\:[0-2]{1}[0-9]{1}$/"
          placeholder="hh:mm"
          onKeyPress={handleBlockText}
          maxLength={5}
          required={required}
          {...props}
        />
      </Container>
    );
  }

  if (isSafari && type === 'date') {
    return (
      <Container>
        <label htmlFor={name}>
          {label}
          {label && required && <span className="danger">*</span>}
          {questionTooltip && (
            <button
              type="button"
              tooltip={questionTooltip}
              onClick={questionOnClick}
              style={{ height: 0 }}
            >
              <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
            </button>
          )}
        </label>
        <StyledInput
          id={name}
          name={name}
          type="text"
          placeholder="dd/mm/aaaa"
          maxLength={10}
          onKeyPress={handleBlockText}
          required={required}
          {...props}
        />
      </Container>
    );
  }

  if (mask) {
    return (
      <Container>
        {label && (
          <label htmlFor={name}>
            {label}
            {label && required && <span className="danger">*</span>}
            {questionTooltip && (
              <button
                type="button"
                tooltip={questionTooltip}
                onClick={questionOnClick}
                style={{ height: 0 }}
              >
                <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
              </button>
            )}
          </label>
        )}
        <ReactInputMask
          id={name}
          name={name}
          type={type}
          required={required}
          mask={mask}
          {...props}
        />
      </Container>
    );
  }

  return (
    <Container>
      <label htmlFor={name}>
        {label}
        {label && required && <span className="danger">*</span>}
        {questionTooltip && (
          <button
            type="button"
            tooltip={questionTooltip}
            onClick={questionOnClick}
            style={{ height: 0 }}
          >
            <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
          </button>
        )}
      </label>
      <StyledInput
        id={name}
        name={name}
        type={type}
        min={
          !acceptPast &&
          type === 'date' &&
          new Date().toISOString().split('T')[0]
        }
        max={type === 'date' && '2999-12-31'}
        required={required}
        {...props}
      />
    </Container>
  );
};

export default InputNoForm;
