import React, { useRef, useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Modal from 'react-modal';

import { BiLoaderAlt } from 'react-icons/bi';
import { Input, Select } from '~/components/Forms';
import { Button } from '~/components/global';

import {
  ModalContent,
  overlayStyle,
  contentStyle,
  ModalButton,
  Wrap,
  WrapButtons,
  MainLoading,
} from './styles';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage, showToastMessage } from '~/services/notification';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const ModalGerenciamentoEquipe = ({
  openModal,
  closeModal,
  data: dataModal,
  load,
}) => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [roles, setRoles] = useState([]);
  const [isNewUser, setIsNewUser] = useState(true);

  const userRoles = useCallback(async () => {
    try {
      setLoading(true);
      const result = await ApiClient.doRequest({
        uri: 'role?paginate=false',
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setRoles(
        result.data.data
          .filter(
            item => item.name === 'Administrador' || item.name === 'Moderador',
          )
          .map(item => ({
            value: item.uuid,
            label: item.name,
          })),
      );
    } catch (error) {
      showToastMessage({
        type: 'error',
        text: `Erro: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  function handleChange(name, value) {
    setFormData(state => ({ ...state, [name]: value }));
  }

  useEffect(() => openModal && userRoles(), [openModal, userRoles]);

  useEffect(() => {
    if (openModal && !loading) {
      const name = dataModal?.user_personal_data?.name || '';
      const email = dataModal?.email || '';

      formRef.current?.setFieldValue('name', name);
      formRef.current?.setFieldValue(
        'role_uuid',
        dataModal?.roles
          ?.map(role => ({ label: role.name, value: role.uuid }))
          .find(role => true),
      );
      formRef.current?.setFieldValue('email', email);

      handleChange('name', name);

      setIsNewUser(!dataModal);
    }
  }, [
    dataModal,
    dataModal?.email,
    dataModal?.roles,
    dataModal?.user_personal_data?.name,
    loading,
    openModal,
  ]);

  async function handleValidationFields() {
    try {
      formRef.current.setErrors({});

      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        name: Yup.string().typeError(defaultMsg).required(defaultMsg),
        role_uuid: Yup.string().typeError(defaultMsg).required(defaultMsg),
        email: Yup.string().test(
          'email-informado',
          defaultMsg,
          value => !(isNewUser && (value?.length <= 0 || !value)),
        ),
        password: Yup.string().test(
          'senha-informada',
          defaultMsg,
          value => !(isNewUser && (value?.length <= 0 || !value)),
        ),
        confirm_password: Yup.string().test(
          'confirma-senha-informada',
          'Senhas estão diferentes',
          value => !(isNewUser && value !== formData.password),
        ),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!(await handleValidationFields())) {
      return;
    }

    try {
      setLoading(true);

      if (isNewUser) {
        await ApiClient.doRequest({
          uri: `user-admin`,
          method: ApiClient.POST,
          body: formData,
          expectedStatus: 201,
        });
      } else {
        await ApiClient.doRequest({
          uri: `admin-change/${dataModal?.uuid}`,
          method: ApiClient.PATCH,
          body: formData,
          expectedStatus: 201,
        });
      }

      await showModalMessage({
        type: 'success',
        text: `Usuário ${isNewUser ? 'cadastrado' : 'alterado'} com sucesso`,
      });

      closeModal();
      load();
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <MainLoading loading={loading}>
        <h3>Processando Informações</h3>
        <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
      </MainLoading>

      <Modal
        onRequestClose={closeModal}
        isOpen={openModal}
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <ModalButton onClick={closeModal}>X</ModalButton>
        <ModalContent>
          <Form ref={formRef} style={{ width: '100%' }}>
            {dataModal?.email ? (
              <h2>Edição de {dataModal?.user_personal_data?.name}</h2>
            ) : (
              <h2>Adicionar usuário</h2>
            )}

            <Wrap>
              <Input
                type="text"
                name="name"
                required
                label="Nome"
                onChange={e => {
                  handleChange(e.target.name, e.target.value);
                }}
              />
            </Wrap>
            <Wrap>
              <Select
                name="role_uuid"
                label="Nível de Acesso"
                placeholder="Selecione"
                required
                options={roles}
                style={selectCustomStyles}
                onChange={value => {
                  handleChange('role_uuid', value?.value);
                }}
              />
            </Wrap>
            {!dataModal && (
              <>
                <Wrap>
                  <Input
                    type="text"
                    name="email"
                    required
                    label="E-mail"
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                  />
                </Wrap>
                <Wrap>
                  <Input
                    type="password"
                    name="password"
                    required
                    label="Senha"
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                  />
                  <Input
                    type="password"
                    name="confirm_password"
                    required
                    label="Confirme a senha"
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                  />
                </Wrap>
              </>
            )}
            <WrapButtons>
              <Button
                type="submit"
                btType="#26A2B1"
                label="Salvar"
                action={handleSubmit}
                full
                width="150px"
                loading={loading}
              />
            </WrapButtons>
          </Form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default withRouter(ModalGerenciamentoEquipe);
