import styled from "styled-components";

export const ContainerLoad = styled.div`
  position: fixed;
  z-index: 99999999;
  background: #000000a3;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
