import React from 'react';
import { FiLoader } from 'react-icons/fi';
import { Container } from './styles';

export default function Button({
  btType,
  label,
  action,
  type,
  icon,
  full,
  width,
  loading,
  ...rest
}) {
  return (
    <Container
      btType={btType}
      onClick={action}
      type={type || 'button'}
      icon={!!icon}
      full={full}
      width={width}
      className="defaultButton"
      {...rest}
    >
      {loading && <FiLoader size={20} className="icon-spin" />}
      {!loading && label}
      {icon && icon}
    </Container>
  );
}
