import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '~/services/notification';

import api, { makeOptions } from '~/services/api';
import ReminderActions from '../ducks/reminder';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));

export function* create(action) {
  try {
    const { data: post } = action;
    const { data } = yield call(
      api.post,
      '/reminder',
      post,
      makeOptions(dataLocal.auth.data),
    );

    yield put(ReminderActions.createSuccess(data));

    showToastMessage({
      type: 'success',
      text: 'Lembrete cadastrado com sucesso',
    });
  } catch (err) {
    yield put(ReminderActions.createFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* edit(action) {
  try {
    // const authData = yield select(getAuthData);
    const { data: post } = action;
    const { data } = yield call(
      api.patch,
      `/reminder/${post.uuid}`,
      post,
      makeOptions(dataLocal.auth.data),
    );

    yield put(ReminderActions.editSuccess(data));
    showToastMessage({
      type: 'success',
      text: 'Informações atualizadas com sucesso',
    });
  } catch (err) {
    yield put(ReminderActions.editFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* fetchSelect() {
  try {
    // const authData = yield select(getAuthData);
    const url = `/reminder?paginate=false`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data } = responseData;

    yield put(ReminderActions.showSuccess(data));
  } catch (err) {
    yield put(ReminderActions.showFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}
