import { call, put } from 'redux-saga/effects';

import api, { makeOptions } from '../../services/api';
import GetByGaleriasListActions from '../ducks/get-by-galeria';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));
// const getAuthData = (state) => state.auth.data;

export function* fetchSelect(action) {
  try {
    // const authData = yield select(getAuthData);
    const { params } = action;
    const url = `ld-ponto-fixo-galerias/get-by-galeria/${params.uuid}`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data } = responseData;
    const list = data.map(({ uuid, name }) => ({
      value: uuid,
      label: name,
    }));
    yield put(GetByGaleriasListActions.showSuccess(list));
  } catch (err) {
    yield put(
      GetByGaleriasListActions.showFailure(err.response.data.error.message),
    );
  }
}
