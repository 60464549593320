import React, { useState, useCallback } from 'react';

import { withRouter, Link } from 'react-router-dom';
import { BsFillEyeFill } from 'react-icons/bs';
import { FaLink, FaPen } from 'react-icons/fa';
import { format } from 'date-fns';
import { GoClippy } from 'react-icons/go';

import { ModalStatusLocacao } from '~/components/Admin';
import Documents from '~/components/Documents/Documents';
import { TdEllipsis } from '~/styles/components';

function ListaPageLocacoes({ data, loadLocacoesObra }) {
  const [openModalStatusObra, setOpenModalStatusObra] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModalStatusObra(false);
  }, []);

  const renderEspacoLocacao = () => {
    const espacos = {
      'Locação Acesso Calçadões':
        data?.address_filmagem || data?.address_calcadao,
      'Locação Autódromo': data?.ld_lista_autodromo?.name,
      'Locação Feira Livre': data?.address,
      'Locação Cemitério':
        data?.ld_cemiterio?.nome === 'Outro'
          ? data?.outro_cemiterio
          : data?.ld_cemiterio?.nome,
      'Locação Galeria': data?.ld_galeria?.name,
      'Locação Filmagem Mercado':
        data?.l_mercados?.name === 'Outro'
          ? data?.outro_mercado
          : data?.l_mercados?.name,
      'Locação Filmagem Parque':
        data?.l_parques?.name === 'Outro'
          ? data?.outro_parque
          : data?.l_parques?.name ?? 'Não informado',
      'Locação Planetário': data?.ld_planetario?.name,
      'Locação Teatro': data?.ld_teatro?.name,
      'Locação Terminal': data?.ld_terminais?.name,
      'Locação Praça Pública': data?.street,
      'Locação Camera Car': data?.endereco_filmagem ?? 'Não informado',
      'Locação Projeção Pública': `${data?.address_local}, ${data?.number_local}, ${data?.neighborhood_local}`,
      'Locação Outros Espaços': data?.address,
      'Locação Espaços Cultura': data?.endereco_local,
      'Locação Espaços SMAD': data?.address,
      'Locação Utilização de Via':
        data?.address_temp ||
        `${data?.address}, ${data?.number}, ${data?.neighborhood}, ${data?.ld_complemento_numero?.name} ${data?.via_sentido}`,
      'Locação Reserva de Vaga': `${data?.street}, ${data?.number}, ${data?.neighborhood}. CEP: ${data?.cep}`,
    };

    return <TdEllipsis>{espacos[data?.name_view]}</TdEllipsis> || <td> </td>;
  };

  return (
    <>
      <tr>
        <td>
          {data && format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm')}
        </td>
        <td>{data && data?.locacaoNumber}</td>
        <td>{data && data?.locacaoName}</td>
        {renderEspacoLocacao()}
        <td>{data && data?.obraName}</td>
        <td>{data && data?.produtoraName}</td>
        <td>{data && data?.obraId}</td>
        <td>{data && data?.responsavelAtendimento}</td>
        <td className="buttons" style={{ justifyContent: 'space-evenly' }}>
          <span tooltip="Mudar status...">
            <button type="button" onClick={() => setOpenModalStatusObra(true)}>
              <GoClippy color="#4f4f4f" size={22} />
            </button>
          </span>
          <span tooltip="Mais detalhes...">
            <Link
              to={{
                pathname: `/solicitacao-nova-locacao/${data?.endpoint}`,
                state: { viewData: data, isAdminRequest: true },
              }}
              style={{ margin: 2 }}
              // onClick={() => setOpen(true)}
            >
              <BsFillEyeFill color="#4f4f4f" size={24} />
            </Link>
          </span>
          <span tooltip="Editar">
            <Link
              to={{
                pathname: `/solicitacao-nova-locacao/${data?.endpoint}/edit`,
                state: { viewData: data, isAdminRequest: true },
              }}
              style={{ margin: 2 }}
              // onClick={() => setOpen(true)}
            >
              <FaPen color="#4f4f4f" size={20} />
            </Link>
          </span>

          {data && data?.utilizacao_locacoes != null ?
            <span tooltip={`Vinculada ${data.utilizacao_locacoes.locacao.protocol}`}>
              <Link
                to={{
                  pathname: `/solicitacao-nova-locacao/${data.utilizacao_locacoes.locacao.endpoint}`,
                  state: { viewData: data.utilizacao_locacoes.locacao, isAdminRequest: true },
                }}
                style={{ margin: 2 }}
              >
                <FaLink color="#4f4f4f" size={20} />
              </Link>
            </span> :
            <span>
              <FaLink color="#f0f0f0" size={20} />
            </span>
          }
        </td>
      </tr>
      <ModalStatusLocacao
        locacao={data}
        openModal={openModalStatusObra}
        closeModal={handleCloseModal}
        loadLocacoesObra={loadLocacoesObra}
      />
    </>
  );
}

export default withRouter(ListaPageLocacoes);
