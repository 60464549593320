import styled from 'styled-components';

export const Container = styled.div`
  visibility: hidden;
  display: none;
  background-color: #fff;

  box-shadow: -1px 1.6px 9px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  right: 5px;
  top: 25px;
  z-index: 1182329137921;

  width: 700px;
  min-height: 200px;
  height: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  & > h3 {
    margin-bottom: 15px;
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    color: #26a2b1;
  }

  & > table {
    width: 100%;

    & tr,
    td {
      font-family: 'InterBold', sans-serif;
      font-size: 14px;
      color: #828282;
    }

    & td,
    th {
      padding: 5px;
    }

    & td {
      word-break: break-all;
    }
  }
`;

export const Icon = styled.button`
  position: relative;
  margin-left: 5px;

  &:hover {
    & > ${Container} {
      visibility: visible;
      display: block;
    }
  }
`;
