import React, { useState } from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { useLocation } from 'react-router';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import { dataAccordion } from '~/data/solicitanteFilmes';
import Button from '~/components/Button';
import { PageContainer } from '~/styles/components';

import { NavBarHeader } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  BoxHeader,
  PageContent,
  ButtonBox,
  PageFooter,
} from '~/pages/Usuario/styles';

import { Wrap } from '../PessoaFisica/styles';
import { CollapseContents } from './styles';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export default function SolicitanteFilm() {
  const [accordionItem, setAccordionItem] = useState([]);

  const user = {
    id: 10,
    name: ``,
    avatar: `https://i.pinimg.com/564x/ec/49/f2/ec49f2ab11ef3e48ffce105318309a48.jpg`,
  };

  function accordionChange(uuid) {
    setAccordionItem(uuid);
  }
  return (
    <>
      <Header>
        <LogoIcon disabled />
        <NavBarHeader data={user} />
      </Header>
      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Solicitante de autorização de filmagem</h1>
            <span>Home | Solicitante da autorização de filmagem</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>
      <PageContainer>
        <PageContent>
          <WhiteBox column>
            <BoxHeader>
              <span className="boxText">O que você gostaria de solicitar?</span>
              <span tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui hac a scelerisque auctor">
                <AiFillExclamationCircle />
              </span>
            </BoxHeader>
            <Wrap>
              <CollapseContents>
                <Accordion
                  allowZeroExpanded
                  onChange={uuid => accordionChange(uuid)}
                >
                  {dataAccordion.map(data => {
                    return (
                      <AccordionItem
                        key={data.id}
                        uuid={data.id}
                        style={
                          accordionItem[0] === data.id
                            ? {
                                background: '#26A2B1',
                              }
                            : {}
                        }
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {data.title}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{data.description}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </CollapseContents>
            </Wrap>
          </WhiteBox>
          <ButtonBox>
            <Button btType="#4F4F4F" label="Voltar" full width="150px" />
            <Button
              type="submit"
              btType="#26A2B1"
              label="Concluir"
              full
              width="157px"
            />
          </ButtonBox>
        </PageContent>
      </PageContainer>
      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
