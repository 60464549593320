import React from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import { Container, CheckButtons, CheckButton } from './styles';

export default function ChoiceButtonLocacao({
  label,
  disableCursor,
  selected,
  onClickTrue,
  onClickFalse,
  marginTop,
  isDisabled,
  error,
  required,
  questionTooltip,
  yesLabelValue,
  noLabelValue,
}) {
  return (
    <Container marginTop={marginTop}>
      <label style={{ display: 'flex' }}>
        <p>
          {label}
          {required && <span className="danger">*</span>}
          {questionTooltip && (
            <span tooltip={questionTooltip} style={{ marginLeft: 5, cursor: 'pointer' }}>          
              <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
            </span>
          )}
        </p>
      </label>
      <CheckButtons>
        <CheckButton
          type="button"
          onClick={onClickTrue}
          disableCursor={disableCursor}
          disabled={isDisabled}
          selected={selected === true}
          style={{cursor: isDisabled ? 'default' : 'pointer'}}
        >
          {yesLabelValue || 'Sim'}
        </CheckButton>
        <CheckButton
          type="button"
          onClick={onClickFalse}
          disableCursor={disableCursor}
          disabled={isDisabled}
          selected={selected === false}
          style={{cursor: isDisabled ? 'default' : 'pointer'}}
        >
          {noLabelValue || 'Não'}
        </CheckButton>
      </CheckButtons>
      {error && <span className="error">{error}</span>}
    </Container>
  );
}
