import styled from 'styled-components';
import { WhiteBox } from '~/components/WhiteBox/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-x: none;
  background-color: #f2f2f2;
  padding: 25px 25px 0 45px;
  margin: 0 auto;

  ${WhiteBox} {
    justify-content: flex-start;
    border-radius: 0 10px 10px 10px;

    img {
      height: 72px;
    }
  }

  form {
    width: 100%;

    input,
    textarea {
      background-color: #f2f2f2;
      border-radius: 5px;
      border: 1px solid #e2e2e2;

      &.w50 {
        width: 49%;
      }

      &.w25 {
        width: 24%;
      }
    }

    label {
      font-family: 'InterBold', sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #828282;
    }

    textarea {
      resize: none;
    }
  }

  table {
    width: 100%;

    thead {
      width: 100%;
      padding-right: 40px;
      padding-left: 40px;
    }

    th,
    td {
      display: flex;
      &:last-child {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        max-width: 120px;
      }
    }
  }

  [tooltip]::after {
    /*
    Tamanho deifinido auto.
      */
    width: 100px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 32px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const TitleForm = styled.div`
  display: flex;
  width: 100%;

  h4 {
    font-family: 'PoppinsBold', sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #323c47;
    margin-top: 18px;
    margin-bottom: 8px;
  }
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /*padding-top: 24px;*/
  width: 100%;

  button {
    font-family: 'InterSemiBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-left: 8px;
  }
`;

export const MainLoading = styled.div`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;
