import React, { useRef, useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useSelector, useDispatch } from 'react-redux';
import { FiLoader } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import UserListActions from '~/store/ducks/users-list';

// import { format } from "date-fns";
// import pt from "date-fns/locale/pt";

import { PageContainer, TableHeader } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, WrapButtons, TitleForm } from './styles';

import {
  ModalGerenciamentoEquipe,
  ListPageGerenciamentoEquipes,
} from '~/components/Admin';
import WhiteBox from '~/components/WhiteBox';
import { useAuth } from '~/hooks/auth';

const Aviso = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const {
    data: usersListAdmins,
    pagination,
    loading,
  } = useSelector(state => state.usersList);
  const [openModalGerenEquipe, setOpenModalGerenEquipe] = useState(false);
  const formRef = useRef();
  const [formData, setFormData] = useState({});
  const { user: userAdm } = useAuth();

  const userAdmins = useCallback(() => {
    dispatch(
      UserListActions.userRequest({
        pagina: page,
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    userAdmins();
  }, [userAdmins]);

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  function PagePagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={pagination?.meta?.pagination?.count}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleSubmit() {
    try {
      formRef.current.setErrors({});
      // dispatch(CompanyActions.createRequest(formData));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function renderList() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    const usersAdms = usersListAdmins.filter(
      ({ uuid }) => uuid !== userAdm.uuid,
    );

    if (!loading && !usersAdms.length) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {usersAdms?.map(lista => (
          <ListPageGerenciamentoEquipes
            key={lista.uuid}
            data={lista}
            load={userAdmins}
            // onDelete={() => handleDelete(lista.id)}
          />
        ))}
      </>
    );
  }
  return (
    <PageContainer>
      <Aside />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrap>
            <TitleForm>
              <h4>Gerenciamento de equipe</h4>
            </TitleForm>
          </Wrap>
          <Wrap>
          <WrapButtons>
            <Button
              type="submit"
              btType="#26A2B1"
              action={() => setOpenModalGerenEquipe(true)}
              label="Novo Usuário"
              full
              width="170px"
            />
          </WrapButtons>
          </Wrap>
        </Form>

        <WhiteBox column maxHeight>
          <table>
            <TableHeader>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Nível Acesso</th>
                <th style={{ justifyContent: 'center' }}>Ações</th>
              </tr>
            </TableHeader>
            <WhiteBox column aCenter>
              <tbody>{renderList()}</tbody>
              <PagePagination />
            </WhiteBox>
          </table>
        </WhiteBox>
      </Container>
      <ModalGerenciamentoEquipe
        openModal={openModalGerenEquipe}
        closeModal={() => setOpenModalGerenEquipe(false)}
        load={userAdmins}
      />
    </PageContainer>
  );
};

export default withRouter(Aviso);
