import styled from "styled-components";

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  width: 100%;

  button {
    font-family: "InterSemiBold", sans-serif;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
  width: 100%;
  overflow: auto;

  a {
    font-family: 'RobotoBold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
  }

  h1 {
    font-family: 'RobotoBold', sans-serif;
    font-size: 24px;
    color: #000;
    text-align: left;
    width: 100%;
  }
`;

export const WrapModal = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  & + div {
    margin-top: 8px;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      font-family: 'RobotoBold', sans-serif;
      font-size: 16px;
      color: #000;
    }

    p {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      color: #828282;
    }
  }
`;

export const ModalButton = styled.button`
  display: flex;
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  color: #99a6b7;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const ChoiceButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-top: 60px !important;
`;

export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: 40,
  borderRadius: 10,
  top: 0,
  left: 0,
  minHeight: 150,
  width: '90%',
  height: 'auto',
  maxWidth: 700,
  maxHeight: 600,
  border: 'none',
};
