export const langs = {
  BR: {
    tituloLabel: 'Catálogo de profissionais e serviços',
    perfilTitleLabel: 'Perfil do Catálogo',
    cadastroLabel: 'Cadastro',
    listaVaziaLabel: 'Nenhum profissional encontrado com os requisitos acima!',
    cargoLabel: 'Cargo',
    servicoLabel: 'Serviço',
    servicosOfertadosLabel: 'Serviços Ofertados',
    idiomaLabel: 'Idioma',
    buscarLabel: 'Buscar',
    limparLabel: 'Limpar',
    editarLabel: 'Editar',
    deletarLabel: 'Excluir',
    ouLabel: 'OU',
    selectLabel: 'Selecione...',
    verPerfilLabel: 'VER PERFIL',

    simLabel: 'Sim',
    naoLabel: 'Não',
    dadosLabel: 'Dados',
    razaoSocialLabel: 'Razão Social',
    nomeFantasiaLabel: 'Nome Fantasia',
    cnpjLabel: 'CNPJ',
    emailLabel: 'Email',
    phoneLabel: 'Telefone',
    enderecoLabel: 'Endereço',
    logradouroLabel: 'Logradouro',
    numeroLabel: 'Número',
    bairroLabel: 'Bairro',
    cidadeLabel: 'Cidade',
    miniBioLabel: 'Mini Bio',
    miniBioInglesLabel: 'Mini Bio em Inglês',
    miniBioEspanholLabel: 'Mini Bio em Espanhol',
    idiomasLabel: 'Idiomas',
    rdSocialLabel: 'Redes Sociais',
    siteLabel: 'Site',
    linkedinLabel: 'Linkedin',
    instagramLabel: 'Instagram',
    facebookLabel: 'Facebook',
    xpPreviaLabel: 'Experiências Prévias com filmagens?',
    xpInternacionalLabel: 'Experiências em projetos internacionais',
    idiomaProjetoLabel: 'Quais idiomas?',
    sustentabilidadeLabel:
      'A empresa possui práticas de sustentabilidade na oferta de serviços?',
    qualSustentabilLabel: 'Quais?',
    premiosLabel:
      'Prêmios, Certificações e/ou Reconhecimentos dentro daárea de atuação',
    informacaoContratoLabel: 'Deseja compartilhar informações de contrato?',

    dadosPessoaisLabel: 'Dados Pessoais',
    nomeLabel: 'Nome',
    nmLabel: 'Nome',
    drtLabel: 'DRT',
    filiacoesLabel: 'Filiações (Associações/Sindicatos)',
    creditosLabel: 'Créditos (Algumas obras que participou)',
    imagemLabel: 'Imagem',
    relacoesLabel: 'Relações',
    imdbLabel: 'Perfil IMDB',
    nomeacoesLabel: 'Prêmios/Nomeações',
    regimeTrabalhoLabel: 'Regime de Trabalho',
    vinculosLabel: 'Vínculo(s) Institucional(is) (dentro do Setor Audiovisual)',
    atuacaoLabel: 'Atuação',
  },
  EN: {
    tituloLabel: 'Catalog of professionals and services',
    perfilTitleLabel: 'Catalog Profile',
    cadastroLabel: 'Register',
    listaVaziaLabel: 'No professionals found with the above requirements!',
    cargoLabel: 'Role',
    servicoLabel: 'Service',
    servicosOfertadosLabel: 'Provided Services',
    idiomaLabel: 'Language',
    buscarLabel: 'Search',
    limparLabel: 'Clear',
    editarLabel: 'Edit',
    deletarLabel: 'Delete',
    ouLabel: 'OR',
    selectLabel: 'Select...',
    verPerfilLabel: 'VIEW PROFILE',

    simLabel: 'Yes',
    naoLabel: 'No',
    dadosLabel: 'Information',
    razaoSocialLabel: 'Corporate Name',
    nomeFantasiaLabel: 'Trade Name',
    cnpjLabel: 'Tax ID/Company Registration Number',
    emailLabel: 'Email',
    phoneLabel: 'Phone Number',
    enderecoLabel: 'Adress',
    logradouroLabel: 'Street',
    numeroLabel: 'Nº - Adress',
    bairroLabel: 'District ',
    cidadeLabel: 'City',
    miniBioLabel: 'Mini Bio',
    miniBioInglesLabel: 'Mini Bio in English',
    miniBioEspanholLabel: 'Mini Bio in Spanish',
    idiomasLabel: 'Languages',
    rdSocialLabel: 'Social Media',
    siteLabel: 'Site',
    linkedinLabel: 'Linkedin',
    instagramLabel: 'Instagram',
    facebookLabel: 'Facebook',
    xpPreviaLabel: 'Previous Experiences with Filming?',
    xpInternacionalLabel: 'Experience with international projects',
    idiomaProjetoLabel: 'In which languages?',
    sustentabilidadeLabel:
      'Does the company have sustainability practices in offering services?',
    qualSustentabilLabel: 'Which one?',
    premiosLabel:
      'Awards, Certifications and/or Recognitions within the area of activity',
    informacaoContratoLabel: 'Do you wish to share contract infos?',

    dadosPessoaisLabel: 'Personal Data',
    nomeLabel: 'Name and surname',
    nmLabel: 'Name',
    drtLabel: 'Brazilian Technical Registration Document',
    filiacoesLabel: 'Affiliations Associations/Trade Unions)',
    creditosLabel: 'Credits',
    imagemLabel: 'Picture',
    relacoesLabel: 'Relations',
    imdbLabel: 'Profile IMDB',
    nomeacoesLabel: 'Awards/Nominations',
    regimeTrabalhoLabel: 'Work Regime',
    vinculosLabel: 'Institutional Link(s) (within the Audiovisual Sector)',
    atuacaoLabel: 'Atuação',
  },
  ES: {
    tituloLabel: 'Catálogo de profesionales y servicios',
    cadastroLabel: 'Catastro',
    perfilTitleLabel: 'Perfil del Catálogo',
    listaVaziaLabel:
      '¡No se han encontrado profesionales con los requisitos anteriores!',
    cargoLabel: 'Cargo',
    servicoLabel: 'Servicio',
    servicosOfertadosLabel: 'Servicios Ofrecidos',
    idiomaLabel: 'Lengua',
    buscarLabel: 'Buscar',
    limparLabel: 'Limpiar',
    editarLabel: 'Editar',
    deletarLabel: 'Eliminar',
    ouLabel: 'O',
    selectLabel: 'Seleccione...',
    verPerfilLabel: 'VER PERFIL',

    simLabel: 'Sí',
    naoLabel: 'No',
    dadosLabel: 'Informaciones',
    razaoSocialLabel: 'Razón Social',
    nomeFantasiaLabel: 'Nombre Comercial',
    cnpjLabel: 'CNPJ',
    emailLabel: 'Correo Electrónico',
    phoneLabel: 'Teléfono',
    enderecoLabel: 'Dirección',
    logradouroLabel: 'Calle',
    numeroLabel: 'Número',
    bairroLabel: 'Barrio',
    cidadeLabel: 'Ciudad',
    miniBioLabel: 'Resumen',
    miniBioInglesLabel: 'Resumen en Inglés',
    miniBioEspanholLabel: 'Resumen en Español',
    idiomasLabel: 'Lenguas',
    rdSocialLabel: 'Redes Sociales',
    siteLabel: 'Sitio Web',
    linkedinLabel: 'Linkedin',
    instagramLabel: 'Instagram',
    facebookLabel: 'Facebook',
    xpPreviaLabel: '¿Experiencias previas de rodaje?',
    xpInternacionalLabel: 'Experiencias con proyectos internacionales:',
    idiomaProjetoLabel: '¿En qué idiomas?',
    sustentabilLabel:
      '¿La empresa tiene prácticas de sostenibilidad en la oferta de servicios?',
    qualSustentabilLabel: '¿Quales',
    premiosLabel:
      'Premios, Certificaciones y/o Reconocimientos dentro del área de actuación',
    informacaoContratoLabel: '¿Desea compartir información del contrato?',

    dadosPessoaisLabel: 'Datos Personales',
    nomeLabel: 'Nombre y apellido',
    nmLabel: 'Nombre',
    drtLabel: 'Documento Brasileño de Registro Técnico',
    filiacoesLabel: 'Afiliaciones (asociaciones/sindicatos)',
    creditosLabel: 'Créditos',
    imagemLabel: 'Fotografía',
    imdbLabel: 'Perfil IMDB',
    relacoesLabel: 'Relaciones',
    nomeacoesLabel: 'Premios/Nominaciones',
    regimeTrabalhoLabel: 'Régimen de Trabajo',
    vinculosLabel:
      'Vínculo(s) Institucional(es) (dentro del Sector Audiovisual)',
    atuacaoLabel: 'Atuação',
  },
};
