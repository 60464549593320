/* eslint-disable no-nested-ternary */
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  Fragment,
} from 'react';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { FiEdit, FiLoader } from 'react-icons/fi';
import { format } from 'date-fns';
import { Form } from '@unform/web';
import { Input, Textarea, ChoiceButton, Select } from '~/components/Forms';

import {
  PageContainer,
  TableHeader,
  LimiterInputWidth,
  ApprovalContainer,
  GridContainer,
} from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';
import { BoxHeader } from '~/pages/Usuario/styles';
import ModalRepresentanteEdit from '~/components/Admin/ModalRepresentanteEdit';
import {
  Content,
  TitleForm,
  Wrap,
  Options,
  ContentImageDocument,
  ImageDocument,
  ContentPDFDocument,
  Container,
} from './styles';

import ListaLocacaoObra from '~/components/Admin/Home/ListaLocacaoObra';

import ApiClient from '~/utils/ApiClient';
import { showModalMessage, showToastMessage } from '~/services/notification';
import Chat from '~/components/Chat/chat';
import WorkStatusSwitch from '~/components/Admin/WorkStatusSwitch';
import validateDate from '~/functions/validateDate';
import Load from '~/components/Load';
import maskString from '~/functions/maskString';
import { ObservacoesChat } from '~/components/Admin';
import WhiteBox from '~/components/WhiteBox';
import Option from '~/components/Option';
import clientResources from '~/data/clientResources';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

function DetalheObra() {
  const formRef = useRef();
  const { state } = useLocation();
  const { data } = state;

  const history = useHistory();

  const [item, setItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [locacaoList, setLocacaoList] = useState([]);
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [responseObra, setResponseObra] = useState({});
  const [userAddress, setUserAddress] = useState({});
  const [joinedFiles, setJoinedFiles] = useState([]);
  const [changeDate, setChangeDate] = useState(null);
  const [isOpenRepresentanteEdit, setOpenRepresentanteEdit] = useState(false);

  const [hasApprovedFieldsFormato, setHasApprovedFieldsFormato] =
    useState(false);

  const [hasApprovedFieldsVeiculacao, setHasApprovedFieldsVeiculacao] =
    useState(false);

  const handleSubmitValorNotaFiscal = useCallback(async () => {
    const valorNotaFiscal = formRef?.current.getFieldValue('valor_nota_fiscal');

    if (!valorNotaFiscal || valorNotaFiscal <= 0) {
      await showModalMessage({
        type: 'error',
        text: 'Informe um valor válido de nota fiscal!',
      });
      return;
    }

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: `work/${data.uuid}`,
        method: ApiClient.PATCH,
        expectedStatus: 201,
        body: {
          valor: valorNotaFiscal,
          ld_veiculacao_inicial: [],
          ld_streaming: [],
          ld_canais_abertos: [],
          ld_canais_filme: [],
          ld_canais_infantil: [],
          ld_canais_noticia: [],
          ld_canais_documentarios: [],
          ld_canais_variedade: [],
          ld_canais_esporte: [],
        },
      });

      await showModalMessage({
        type: 'success',
        text: 'Valor nota fiscal salvo com sucesso!',
      });

      history.replace('/obras');
    } catch (error) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao tentar salvar o valor nota fiscal da obra!',
      });
    } finally {
      setLoading(false);
    }
  }, [data?.uuid]);

  const formatCurrency = value => {
    if (typeof value === 'number') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    }
    return '';
  };

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  const fetchLastStatusChangeDate = useCallback(async () => {
    try {
      const result = await ApiClient.doRequest({
        uri: `work-status-history?filters[][work.uuid@equal]=${data.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const changes = result?.data?.data;

      if (changes?.length !== 0) {
        const lastChangeDate =
          changes?.length > 1
            ? changes[changes?.length - 1]?.created_at
            : changes[0]?.created_at;
        setChangeDate(lastChangeDate);
      }
    } catch (error) {
      console.error(
        'Erro ao buscar a data da última alteração do status:',
        error,
      );
    }
  }, [data.uuid]);

  const loadLocacao = useCallback(async () => {
    try {
      setLoading(true);

      const resultLBW = await ApiClient.doRequest({
        uri: `work/locacoes-by-work/${data.uuid}?page=${page}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const obraLocacoes = resultLBW?.data?.data;

      setTotPage(resultLBW.data.last_page);

      const obraLocacoesList = obraLocacoes?.map(ilocacao => {
        return {
          uuid: ilocacao.uuid,
          locacaoDefaultUuid: ilocacao.locacao_default?.uuid,
          locacaoNumber: ilocacao.protocol,
          name: ilocacao.name_view,
          obraName: ilocacao?.work?.titulo_obra,
          produtoraName: ilocacao?.work?.branch?.fantasy_name,
          obraId: ilocacao?.work?.protocol,
          status:
            ilocacao?.locacao_default?.locacao_status?.name ||
            ilocacao?.locacao_status?.name,
          responsavelObra:
            ilocacao?.work?.proprietario_user?.user_personal_data?.name,
          responsavelAtendimento:
            ilocacao?.work?.responsavel_user?.user_personal_data?.name,
          endpoint: ilocacao.endpoint,
        };
      });

      setLocacaoList(obraLocacoesList);
    } catch (e) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao buscar locações de Obra',
      });
    } finally {
      setLoading(false);
    }
  }, [page]);

  function renderLocacao() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && !locacaoList?.length) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {locacaoList?.map(lista => (
          <ListaLocacaoObra
            key={lista.uuid}
            data={lista}
            loadLocacoesObra={loadLocacao}
          // onDelete={() => handleDelete(lista.id)}
          />
        ))}
      </>
    );
  }

  const loadObra = useCallback(async () => {
    try {
      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri: `work/${data.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respObra = resp?.data?.data;

      // render selects veiculacao inicial
      formRef?.current?.setFieldValue(
        'ld_veiculacao_inicial',
        respObra?.ld_veiculacao_inicial?.value?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_abertos',
        respObra?.ld_canais_abertos?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_filme',
        respObra?.ld_canais_filme?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_infantil',
        respObra?.ld_canais_infantil?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_noticia',
        respObra?.ld_canais_noticia?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_documentarios',
        respObra?.ld_canais_documentarios?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_variedade',
        respObra?.ld_canais_variedade?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_esporte',
        respObra?.ld_canais_esporte?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_streaming',
        respObra?.ld_streaming?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );

      // verify if has fields to approve
      const hasChangesFormato = Object.keys(respObra?.work_type).concat(
        Object.keys(respObra?.visit_date),
        Object.keys(respObra?.visit_date_fim),
        Object.keys(respObra?.previsao_estreia),
      );

      const hasChangesVeiculacao = Object.keys(respObra?.ld_veiculacao_inicial);

      setHasApprovedFieldsFormato(
        hasChangesFormato.includes('changes_to_approve'),
      );
      setHasApprovedFieldsVeiculacao(
        hasChangesVeiculacao.includes('changes_to_approve'),
      );

      // join files
      const branchFiles = respObra?.branch?.files || [];
      const pessoaFisicaFiles = respObra?.pessoa_fisica_user?.files || [];
      const workFiles = respObra?.files || [];

      setJoinedFiles(branchFiles.concat(pessoaFisicaFiles, workFiles));
      setUserAddress(
        respObra?.branch
          ? {
            cep: respObra?.branch?.cep?.toString().padStart(8, '0'),
            street: respObra?.branch?.street,
            number: respObra?.branch?.number,
            complement: respObra?.branch?.complement,
            neighborhood: respObra?.branch?.neighborhood?.name,
            city: respObra?.branch?.neighborhood?.city?.name,
          }
          : respObra?.pessoa_fisica_user?.user_address
            ? {
              cep: respObra?.pessoa_fisica_user?.user_address?.cep
                ?.toString()
                .padStart(8, '0'),
              street: respObra?.pessoa_fisica_user?.user_address?.street,
              number: respObra?.pessoa_fisica_user?.user_address?.number,
              complement:
                respObra?.pessoa_fisica_user?.user_address?.complement,
              neighborhood:
                respObra?.pessoa_fisica_user?.user_address?.neighborhood?.name,
              city: respObra?.pessoa_fisica_user?.user_address?.neighborhood
                ?.city?.name,
            }
            : {},
      );

      setResponseObra(respObra);
    } catch (error) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao visualizar detalhes da Obra',
      });
    } finally {
      setLoading(false);
    }
  }, [data.uuid]);

  useEffect(() => {
    switch (item) {
      case 0:
        loadObra();
        break;
      case 1:
        loadLocacao();
        break;
      default:
        break;
    }
    fetchLastStatusChangeDate();
  }, [fetchLastStatusChangeDate, item, loadLocacao, loadObra]);

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Content>
          <Options>
            <Option active={item === 0} onClick={() => setItem(0)}>
              Obra
            </Option>
            <Option active={item === 1} onClick={() => setItem(1)}>
              Locação
            </Option>
            <Option active={item === 2} onClick={() => setItem(2)}>
              Documentos
            </Option>
          </Options>
          <WhiteBox column maxHeight>
            {item === 0 && (
              <>
                <Load inLoad={loading} />
                <Form ref={formRef}>
                  <Wrap>
                    <Input
                      type="datetime-local"
                      name="created_at"
                      label="Data de cadastro"
                      value={
                        responseObra?.created_at
                          ? `${format(
                            new Date(`${responseObra?.created_at}`),
                            'yyyy-MM-dd',
                          )}T${format(
                            new Date(`${responseObra?.created_at}`),
                            'HH:mm',
                          )}`
                          : ''
                      }
                      disabled
                    />
                    <Input
                      type="text"
                      name="numero_obra"
                      label="Nº da Obra"
                      value={responseObra && responseObra?.protocol}
                      disabled
                    />
                    <Input
                      type="text"
                      name="titulo_obra"
                      label="Nome da Obra"
                      value={responseObra && responseObra?.titulo_obra}
                      disabled
                    />
                    <Input
                      type="text"
                      name="nome_produtora"
                      label="Nome da Produtora"
                      value={
                        responseObra &&
                        (responseObra?.branch?.fantasy_name ||
                          responseObra?.pessoa_fisica_user?.user_personal_data
                            ?.name)
                      }
                      disabled
                    />
                  </Wrap>

                  <ApprovalContainer
                    hasApprovalFields={hasApprovedFieldsFormato}
                  >
                    {hasApprovedFieldsFormato && (
                      <BoxHeader>
                        <span className="boxText">
                          Alterações a serem analisadas pela SPFilm
                        </span>
                      </BoxHeader>
                    )}
                    <Wrap>
                      <Input
                        type="text"
                        name="titulo"
                        label="Formato da Obra"
                        value={
                          responseObra && responseObra?.work_type?.value?.name
                        }
                        disabled
                      />

                      <Input
                        type="date"
                        name="visit_date"
                        label="Data ou previsão de início das filmagens"
                        value={
                          responseObra &&
                            validateDate(
                              responseObra?.visit_date?.value,
                              'yyyy-MM-dd HH:mm:ss',
                            )
                            ? format(
                              new Date(
                                `${responseObra?.visit_date?.value.replace(
                                  ' ',
                                  'T',
                                )}`,
                              ),
                              'yyyy-MM-dd',
                            )
                            : ''
                        }
                        disabled
                      />

                      <Input
                        type="date"
                        name="visit_date_fim"
                        label="Data ou previsão de fim das filmagens"
                        value={
                          responseObra &&
                            validateDate(
                              responseObra?.visit_date_fim?.value,
                              'yyyy-MM-dd HH:mm:ss',
                            )
                            ? format(
                              new Date(
                                `${responseObra?.visit_date_fim?.value.replace(
                                  ' ',
                                  'T',
                                )}`,
                              ),
                              'yyyy-MM-dd',
                            )
                            : ''
                        }
                        disabled
                      />

                      <Input
                        type="month"
                        name="previsao_estreia"
                        label="Previsão de estreia"
                        value={
                          responseObra?.previsao_estreia?.value
                            ? format(
                              new Date(
                                `${responseObra?.previsao_estreia?.value}T00:00:00`,
                              ),
                              'yyyy-MM',
                            )
                            : ''
                        }
                        disabled
                      />
                    </Wrap>
                  </ApprovalContainer>

                  <ApprovalContainer
                    hasApprovalFields={hasApprovedFieldsVeiculacao}
                  >
                    {hasApprovedFieldsVeiculacao && (
                      <BoxHeader>
                        <span className="boxText">
                          Alterações a serem analisadas pela SPFilm
                        </span>
                      </BoxHeader>
                    )}
                    <Wrap>
                      <Select
                        name="ld_veiculacao_inicial"
                        label="Veiculação inicial prevista"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                      <Select
                        name="ld_canais_abertos"
                        label="Televisão - Canais Abertos"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                      <Select
                        name="ld_canais_filme"
                        label="Filmes/Séries"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                    </Wrap>

                    <Wrap>
                      <Select
                        name="ld_canais_infantil"
                        label="Infantis"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                      <Select
                        name="ld_canais_noticia"
                        label="Notícias"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                      <Select
                        name="ld_canais_documentarios"
                        label="Documentários"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                    </Wrap>

                    <Wrap>
                      <Select
                        name="ld_canais_variedade"
                        label="Variedades"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                      <Select
                        name="ld_canais_esporte"
                        label="Esportes"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                      <Select
                        name="ld_streaming"
                        label="Plataforma de Streaming"
                        placeholder=""
                        style={selectCustomStyles}
                        isMulti
                        isDisabled
                      />
                    </Wrap>

                    <Wrap>
                      <Input
                        name="outro_veiculacao"
                        disabled
                        label="Outros"
                        value={responseObra?.outro_veiculacao}
                      />
                    </Wrap>
                  </ApprovalContainer>
                  <Wrap>
                    <ChoiceButton
                      label="Produção Independente"
                      selected={!!(responseObra && responseObra?.is_indie)}
                      disableCursor
                    />
                    <ChoiceButton
                      label="Production Service"
                      selected={
                        !!(responseObra && responseObra?.is_production_service)
                      }
                      disableCursor
                    />
                    <ChoiceButton
                      label="Cooprodução"
                      selected={!!responseObra?.nome_segunda_empresa}
                      disableCursor
                    />
                    <ChoiceButton
                      label="Incentivo"
                      selected={
                        !!(responseObra && responseObra?.is_program_incentive)
                      }
                      disableCursor
                    />
                  </Wrap>
                  <Wrap>
                    <ChoiceButton
                      label="Produção Estudantil?"
                      selected={
                        !!(responseObra && responseObra?.is_student_production)
                      }
                      disableCursor
                    />
                  </Wrap>
                  <Wrap>
                    <Input
                      type="text"
                      name="segunda_produtora"
                      label="Segunda Produtora"
                      value={responseObra && responseObra?.nome_segunda_empresa}
                      disabled
                    />
                    <Input
                      type="text"
                      name="titulo"
                      label="Estado de origem"
                      value={responseObra && responseObra?.state?.name}
                      disabled
                    />
                    <Input
                      type="text"
                      name="titulo"
                      label="Nome e nº do incentivo"
                      value={responseObra && responseObra?.number_incentive}
                      disabled
                    />
                  </Wrap>
                  <Wrap>
                    <Input
                      type="text"
                      name="status"
                      label="Status"
                      value={responseObra && responseObra?.work_status?.name}
                      disabled
                    />
                    <Input
                      type="text"
                      name="updated_at"
                      label="Data de Alteração"
                      value={
                        changeDate && changeDate.length > 0 ? changeDate : ''
                      }
                      disabled
                    />
                    <Input
                      type="text"
                      name="titulo"
                      label="País de origem"
                      value={responseObra && responseObra?.country?.name}
                      disabled
                    />
                    <Input
                      type="text"
                      name="titulo"
                      label="Responsável pelo Atendimento"
                      value={
                        responseObra &&
                        responseObra?.responsavel_user?.user_personal_data?.name
                      }
                      disabled
                    />
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="nota"
                      label="Sinopse da Obra"
                      value={responseObra && responseObra?.synopsis}
                      rows="5"
                      disabled
                    />
                  </Wrap>

                  <TitleForm>
                    <h4>Direção</h4>
                  </TitleForm>
                  <Wrap>
                    <Input
                      className="w50"
                      type="text"
                      name="titulo"
                      label="Nome / Nome Social"
                      value={responseObra?.director?.name}
                      disabled
                    />
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="nota"
                      label="Mini currículo"
                      value={responseObra?.director?.curriculum}
                      rows="5"
                      disabled
                    />
                  </Wrap>

                  <TitleForm>
                    <h4>
                      {
                        clientResources.labels.obra
                          .contatoResponsavelSpFilmComission
                      }
                    </h4>
                  </TitleForm>

                  {responseObra &&
                    responseObra?.work_contacts?.map(contact => (
                      <Wrap key={contact.uuid}>
                        <GridContainer cols={4}>
                          <Input
                            type="text"
                            name="nome_contato"
                            label="Nome Civil / Nome Social"
                            value={contact?.user?.user_personal_data?.name}
                            disabled
                          />
                          <Input
                            type="text"
                            name="email_contato"
                            label="E-mail"
                            value={contact?.user?.email}
                            disabled
                          />
                          <Input
                            type="text"
                            name="cpf_contato"
                            label="CPF"
                            value={contact?.user?.user_personal_data?.cpf}
                            disabled
                          />
                          <Input
                            type="text"
                            name="rg_contato"
                            label="RG"
                            value={contact?.user?.user_personal_data?.rg}
                            disabled
                          />
                          <Input
                            type="text"
                            name="phone_mobile_contato"
                            label="Telefone"
                            value={
                              contact?.user?.user_personal_data?.phone_mobile
                            }
                            disabled
                          />
                        </GridContainer>
                      </Wrap>
                    ))}

                  <TitleForm>
                    <h4>Coordenação</h4>
                  </TitleForm>

                  {responseObra &&
                    responseObra?.work_production_coordinator?.map(
                      coordinator => (
                        <Wrap key={coordinator.uuid}>
                          <GridContainer cols={4}>
                            <Input
                              type="text"
                              name="nome_coordenacao"
                              label="Nome Civil / Nome Social"
                              value={
                                coordinator?.user?.user_personal_data?.name
                              }
                              disabled
                            />
                            <Input
                              type="text"
                              name="email_coordenacao"
                              label="E-mail"
                              value={coordinator?.user?.email}
                              disabled
                            />
                            <Input
                              type="text"
                              name="cpf_coordenacao"
                              label="CPF"
                              value={coordinator?.user?.user_personal_data?.cpf}
                              disabled
                            />
                            <Input
                              type="text"
                              name="rg_coordenacao"
                              label="RG"
                              value={coordinator?.user?.user_personal_data?.rg}
                              disabled
                            />
                            <Input
                              type="text"
                              name="phone_mobile_coordenacao"
                              label="Telefone"
                              value={
                                coordinator?.user?.user_personal_data
                                  ?.phone_mobile
                              }
                              disabled
                            />
                          </GridContainer>
                        </Wrap>
                      ),
                    )}

                  <TitleForm>
                    <h4>Direção de Produção</h4>
                  </TitleForm>

                  {responseObra &&
                    responseObra?.work_directors?.map(director => (
                      <Wrap key={director.uuid}>
                        <GridContainer cols={4}>
                          <Input
                            type="text"
                            name="nome_diretor"
                            label="Nome Civil / Nome Social"
                            value={director?.user?.user_personal_data?.name}
                            disabled
                          />
                          <Input
                            type="text"
                            name="email_diretor"
                            label="E-mail"
                            value={director?.user?.email}
                            disabled
                          />
                          <Input
                            type="text"
                            name="cpf_diretor"
                            label="CPF"
                            value={director?.user?.user_personal_data?.cpf}
                            disabled
                          />
                          <Input
                            type="text"
                            name="rg_diretor"
                            label="RG"
                            value={director?.user?.user_personal_data?.rg}
                            disabled
                          />
                          <Input
                            type="text"
                            name="phone_mobile_diretor"
                            label="Telefone"
                            value={
                              director?.user?.user_personal_data?.phone_mobile
                            }
                            disabled
                          />
                        </GridContainer>
                      </Wrap>
                    ))}

                  <TitleForm>
                    <h4>Produção Executiva</h4>
                  </TitleForm>

                  {responseObra &&
                    responseObra?.work_executive_producer?.map(executive => (
                      <Wrap key={executive.uuid}>
                        <GridContainer cols={4}>
                          <Input
                            type="text"
                            name="nome_executivo"
                            label="Nome Civil / Nome Social"
                            value={executive?.user?.user_personal_data?.name}
                            disabled
                          />
                          <Input
                            type="text"
                            name="email_executivo"
                            label="E-mail"
                            value={executive?.user?.email}
                            disabled
                          />
                          <Input
                            type="text"
                            name="cpf_executivo"
                            label="CPF"
                            value={executive?.user?.user_personal_data?.cpf}
                            disabled
                          />
                          <Input
                            type="text"
                            name="rg_executivo"
                            label="RG"
                            value={executive?.user?.user_personal_data?.rg}
                            disabled
                          />
                          <Input
                            type="text"
                            name="phone_mobile_executivo"
                            label="Telefone"
                            value={
                              executive?.user?.user_personal_data?.phone_mobile
                            }
                            disabled
                          />
                        </GridContainer>
                      </Wrap>
                    ))}

                  {responseObra?.work_status?.name === 'Finalizado' && (
                    <>
                      <TitleForm>
                        <h4>Nota Fiscal</h4>
                      </TitleForm>
                      <Wrap style={{ alignItems: 'flex-end' }}>
                        <LimiterInputWidth w25>
                            <Input
                              name="valor_nota_fiscal"
                              type="text"
                              label="Valor referente aos serviços prestados pela São Paulo Film Commission, estabelecidos nos termos da Portaria nº 03/2019/Spcine."
                              defaultValue={formatCurrency(responseObra.valor)}
                            />
                        </LimiterInputWidth>
                          <Button
                            btType="#26A2B1"
                            onClick={handleSubmitValorNotaFiscal}
                            label="Salvar"
                            disabled={loading}
                            loading={loading}
                            full
                            width="250px"
                          />
                      </Wrap>
                    </>
                  )}
                </Form>
                <Chat uuid={data.uuid} chatKeyFilter="work" isAdminRequest />
                <WorkStatusSwitch data={{ entity: 'work', uuid: data.uuid }} />
                {responseObra && <ObservacoesChat workUuid={data.uuid} />}
              </>
            )}
            {item === 1 && (
              <table>
                <TableHeader>
                  <tr>
                    <th>Nº da Locação</th>
                    <th>Nome da Locação</th>
                    <th>Status</th>
                    <th>Resp. Obra</th>
                    <th>Resp. Atendimento</th>
                    <th>Ações</th>
                  </tr>
                </TableHeader>
                <WhiteBox column aCenter>
                  <tbody>{renderLocacao()}</tbody>
                  <BasicPagination />
                </WhiteBox>
              </table>
            )}
            {item === 2 && (
              <Form ref={formRef}>
                <TitleForm>
                  <h4>Dados</h4>
                </TitleForm>
                <Wrap>
                  <Input
                    type="text"
                    name="name"
                    label="Nome / Nome Social"
                    value={
                      responseObra && !responseObra?.branch
                        ? responseObra?.pessoa_fisica_user?.user_personal_data
                          ?.name
                        : responseObra?.branch?.company_name
                    }
                    disabled
                  />
                  {!responseObra?.branch && (
                    <Input
                      type="text"
                      name="email"
                      label="E-mail"
                      value={
                        responseObra && responseObra?.pessoa_fisica_user?.email
                      }
                      disabled
                    />
                  )}
                  <Input
                    type="text"
                    name="cpf_cnpj"
                    label="CPF / CNPJ"
                    value={
                      responseObra && !responseObra?.branch
                        ? responseObra?.pessoa_fisica_user?.user_personal_data
                          ?.cpf
                        : responseObra?.branch?.cnpj
                    }
                    disabled
                  />
                  {!responseObra?.branch && (
                    <Input
                      type="text"
                      name="rg"
                      label="RG"
                      value={
                        responseObra &&
                        responseObra?.pessoa_fisica_user?.user_personal_data?.rg
                      }
                      disabled
                    />
                  )}
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="phone_mobile"
                      label="Telefone"
                      value={
                        responseObra &&
                        (responseObra?.pessoa_fisica_user?.user_personal_data
                          ?.phone_mobile ??
                          responseObra?.pessoa_fisica_user?.user_personal_data
                            ?.phone)
                      }
                      disabled
                    />
                  </LimiterInputWidth>
                </Wrap>
                {Object.values(userAddress).length > 0 && (
                  <>
                    <BoxHeader>
                      <span className="boxText">Endereço</span>
                    </BoxHeader>
                    <Wrap>
                      <Input
                        type="text"
                        name="cep"
                        label="CEP"
                        value={
                          userAddress &&
                          maskString(
                            userAddress?.cep,

                            '#####-###',
                          )
                        }
                        disabled
                      />
                      <Input
                        type="text"
                        name="street"
                        label="Logradouro"
                        value={userAddress && userAddress?.street}
                        disabled
                      />
                      <Input
                        type="text"
                        name="number"
                        label="Número"
                        value={userAddress && userAddress?.number}
                        disabled
                      />
                      <Input
                        type="text"
                        name="complement"
                        label="Complemento"
                        value={userAddress && userAddress?.complement}
                        disabled
                      />
                    </Wrap>
                    <Wrap>
                      <Input
                        type="text"
                        name="neighborhood"
                        label="Bairro"
                        value={userAddress && userAddress?.neighborhood}
                        disabled
                      />
                      <Input
                        type="text"
                        name="city"
                        label="Cidade"
                        value={userAddress && userAddress?.city}
                        disabled
                      />
                    </Wrap>
                  </>
                )}

                {Object.values(responseObra?.branch?.representante || {})
                  .length > 0 && (
                    <div style={{ flexDirection: 'row' }}>
                      <BoxHeader>
                        <span className="boxText">
                          Dados Representante
                          <button type='button' onClick={() => setOpenRepresentanteEdit(true)} style={{ marginLeft: 8 }}>
                            <FiEdit size={20} color='#323c47' />
                          </button>
                          <ModalRepresentanteEdit
                            isOpen={isOpenRepresentanteEdit}
                            onClose={() => { setOpenRepresentanteEdit(false) }}
                            representante={responseObra?.branch?.representante}
                            onRefresh={loadObra}
                          />
                        </span>

                      </BoxHeader>

                      {responseObra?.branch?.representante?.map(representante => (
                        <Wrap key={representante.uuid}>
                          <GridContainer cols={3}>
                            <Input
                              type="text"
                              name="name_rep"
                              label="Nome"
                              value={representante.name}
                              disabled
                            />
                            <Input
                              type="text"
                              name="corporate_email_rep"
                              label="Email corporativo"
                              value={representante.corporate_email}
                              disabled
                            />
                            <Input
                              type="text"
                              name="cpf_rep"
                              label="CPF"
                              value={representante.cpf}
                              disabled
                            />
                            <Input
                              type="text"
                              name="rg_rne_rep"
                              label="RG / RNE"
                              value={representante.rg}
                              disabled
                            />
                            <Input
                              type="text"
                              name="mobile_phone_rep"
                              label="Telefone"
                              value={representante.phone_mobile}
                              disabled
                            />
                            <Input
                              type="text"
                              name="cargo_rep"
                              label="Cargo"
                              value={representante.cargo}
                              disabled
                            />
                          </GridContainer>
                        </Wrap>
                      ))}
                    </div>
                  )}

                {joinedFiles?.length > 0 && (
                  <>
                    <BoxHeader>
                      <span className="boxText">Arquivos Imagem</span>
                    </BoxHeader>
                    <ContentImageDocument>
                      {joinedFiles
                        .filter(ifile => ifile.extension === '.png')
                        .map(ifile => (
                          <ImageDocument key={ifile.uuid}>
                            <p>{ifile.name}</p>
                            <a
                              href={ifile.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={ifile.url} alt={ifile.name} />
                            </a>
                          </ImageDocument>
                        ))}
                    </ContentImageDocument>

                    <BoxHeader style={{ marginTop: 20 }}>
                      <span className="boxText">Arquivos PDF</span>
                    </BoxHeader>
                    <ContentPDFDocument>
                      {joinedFiles
                        .filter(ifile => ifile.extension === '.pdf')
                        .map(ifile => (
                          <a
                            key={ifile.uuid}
                            href={ifile.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {ifile.name}
                          </a>
                        ))}
                    </ContentPDFDocument>
                  </>
                )}
              </Form>
            )}
          </WhiteBox>
        </Content>
      </Container>
    </PageContainer>
  );
}

export default withRouter(DetalheObra);
