/* eslint-disable no-underscore-dangle */
import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    createRequest: ["data", "history"],
    createSuccess: ["data"],
    createFailure: ["error"],
    reset: [],
  },
  {
    prefix: "CREATE_PASSWORD_",
  }
);

export const CreatePasswordTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

/* Reducers */

// create
export const _createRequest = (state) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

export const _createSuccess = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

export const _createFailure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

export const _reset = () => INITIAL_STATE;

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_REQUEST]: _createRequest,
  [Types.CREATE_SUCCESS]: _createSuccess,
  [Types.CREATE_FAILURE]: _createFailure,
  [Types.RESET]: _reset,
});
