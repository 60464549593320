import styled from 'styled-components';

export const Header = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 129px;
  background-color: ${({ bgTheme }) => bgTheme || '#3f3f3f'};
  padding: 20px 70px 20px 36px;
`;
