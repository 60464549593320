/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { AiFillExclamationCircle } from 'react-icons/ai';

import { BoxBody, CardChoice, ChoiceBox, LogOut } from './styles';

import {
  BannerContent,
  TextBanner,
  BoxHeader,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';

import { PageContainer } from '~/styles/components';

import userIcon from '~/assets/images/acesso/person.png';
import cameraIcon from '~/assets/images/acesso/camera.png';
import movieIcon from '~/assets/images/acesso/movie.png';
import settingsIcon from '~/assets/images/acesso/settings.png';

import { useAuth } from '~/hooks/auth';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

const Escolha = () => {
  const { user, signOut } = useAuth();

  function logOut() {
    signOut();
  }

  const ADM_MOD = user?.roles?.some(
    role => role === 'Administrador' || role === 'Moderador',
  );

  return (
    <>
      <Header>
        <LogoIcon disabled />
        <LogOut onClick={() => logOut()}>
          <FiLogOut />
        </LogOut>
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Escolha seu acesso</h1>
          </TextBanner>
        </BannerContent>
      </BannerHeader>
      <PageContainer>
        <PageContent>
          <WhiteBox column aCenter>
            <BoxBody>
              <BoxHeader>
                <span className="boxText" />
                <span
                  tooltip="
                  “Cadastro Pessoa Física”: registro individual de profissionais/estudantes com ou sem CNPJ.
                  Podem ser responsáveis por uma produção ou somente fazer parte dela.

                  “Cadastro Pessoa Jurídica” registro de uma produtora/empresa responsável por alguma
                  obra.
                  "
                >
                  <AiFillExclamationCircle />
                </span>
              </BoxHeader>
              <ChoiceBox>
                <CardChoice href="/catalogo/cadastro">
                  <span>
                    <img src={userIcon} alt="user ico" />
                  </span>
                  <h1>Catálogo de Profissionais e Serviços</h1>
                </CardChoice>
                <CardChoice href="/cadastro-escolha">
                  <span>
                    <img src={cameraIcon} alt="paper ico" />
                  </span>
                  <h1>Autorizações de filmagem</h1>
                </CardChoice>
                {clientResources.tenant !== 'ilhacine' && (
                  <CardChoice href="/ciencia-filmagem-interna">
                    <span>
                      <img src={movieIcon} alt="icone filme" />
                    </span>
                    <h1>Ciência de Filmagem Interna</h1>
                  </CardChoice>
                )}
                {ADM_MOD && (
                  <CardChoice href="/" blue>
                    <span>
                      <img src={settingsIcon} alt="paper ico" />
                    </span>
                    <h1>Administrador</h1>
                  </CardChoice>
                )}
              </ChoiceBox>
            </BoxBody>
          </WhiteBox>
        </PageContent>
      </PageContainer>
      <PageFooter>
      <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
};

export default Escolha;
