import diff from 'diff-arrays-of-objects';

export default function _({
  montEstrutLocacao,
  montEstrutInput,
}) {
  // se opção não foi gravada no banco antes
  if (Object.values(montEstrutLocacao || []).length <= 0 &&
    Object.values(montEstrutInput || []).length <= 0) {
    return []
  }

  // se a opção não for mais selecionada
  if (Object.values(montEstrutInput || []).length <= 0) {
    return montEstrutLocacao.map(({uuid}) => {
      return { uuid, placa: '', modelo_veiculo: '' }
    });
  }

  // cria listas temporarias dos cards
  const montEstrutLocacaoTmp = montEstrutLocacao?.map(({uuid, placa, modelo_veiculo, ld_finalidade_acesso}) => 
    ({ uuid, placa, modelo_veiculo, finalidade_acesso_uuid: ld_finalidade_acesso?.uuid }));
  const montEstrutInputTmp = montEstrutInput.map(({uuid, placa, modelo_veiculo, finalidade_acesso_uuid}) => 
    ({ uuid: uuid || '', placa, modelo_veiculo, finalidade_acesso_uuid }));  

  // ve se tem diferença entre os cards
  const results = diff(montEstrutLocacaoTmp, montEstrutInputTmp, 'uuid');
  const { updated, added, removed } = results;
  
  const montEstrutDiff = added.concat(updated, removed?.map(({uuid}) => ({ uuid, placa: '', modelo_veiculo: '' })));
  return montEstrutDiff;
}
