import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 24px;
  /* margin-top: 16px; */
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: 'MuseoSans500', sans-serif;
    font-size: 15px;
    color: #000000;
  }

  input {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px #e2e2e2 solid;
    padding: 16px;
    font-size: 14px;
    font-family: 'MuseoSans500', sans-serif;
    color: #586365;
    background-color: #fafafa;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.075);

    &[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &[disabled] {
      background-color: #ededed;
      &::placeholder {
        content: '';
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0;
    top: 8px;

    svg {
      color: #fff;
    }
  }

  span {
    font-size: 14px;
    font-family: 'InterBold', sans-serif;
    color: #ff0000;
    margin: 4px 0;
  }
`;

export const Arquivo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;

  span {
    width: 78%;
    min-height: 49px;
    margin-top: 8px;
    padding: 16px;
    border-radius: 4px;
    border: 1px #e2e2e2 solid;
    font-size: 14px;
    line-height: 18px;
    font-family: 'PoppinsSemiBold', sans-serif;
    color: #586365;
    background-color: #f2f2f2;
    opacity: 0.65;
  }

  input.arquivo {
    background-color: #000;
    position: absolute;
    width: 0;
    height: 0;
    z-index: 9999;
    opacity: 0;
    cursor: pointer;
  }

  input.arquivo::before {
    content: 'Escolha aqui';
    display: none;
  }

  button {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    background-color: #26a2b1;
    min-height: 51px;
    color: #ffffff;
    border-radius: 3px;

    position: initial;
    width: 150px;
    height: 30px;
    margin-top: 8px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    background-color: #26a2b1;
    color: #ffffff;
    border-radius: 3px;

    position: initial;
    width: 150px;
    height: 51px;
    margin-top: 8px;
  }

  @media only screen and (max-width: 767px) {
    span {
      font-size: 14px;
    }
  }
`;

export const ButtonsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FileNotExistsLabel = styled.p`
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  align-self: center;
  opacity: 0.8;
`;
