import { useParams, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';

import ApiClient from '~/utils/ApiClient';

import { PageContainer } from './styles';

const Redirect = () => {
  const { hashed } = useParams();
  const location = useLocation();
  const history = useHistory();

  const config = useMemo(() => {
    return {
      'pesquisa-socioeconomica': async ({ obra_uuid }) => {
        const {
          data: { data },
        } = await ApiClient.doRequest({
          uri: `work/${obra_uuid}`,
          method: ApiClient.GET,
          expectedStatus: 200,
        });

        history.push({
          pathname: '/pesquisas/pesquisa-socioeconomica',
          state: { dados: data, nome_projeto: data?.director?.name },
        });
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLoggedStatus = () => {
    const dataString = localStorage.getItem('persist:Spcine');

    if (!dataString) return false;

    const { auth } = JSON.parse(dataString);

    return auth.logado ?? false;
  };

  useEffect(() => {
    if (!hashed) return;

    try {
      const dataString = Buffer.from(hashed, 'base64').toString('utf-8');
      const parsedData = JSON.parse(dataString);

      const { pathname, type, params, ...state } = parsedData;

      const isLogged = getLoggedStatus();

      if (!isLogged) {
        history.push({
          pathname: '/login',
          search: `?redirectLink=${encodeURIComponent(location.pathname)}`,
        });

        return;
      }

      if (type) {
        config[type].call(null, params);
      } else {
        if (!pathname) throw new Error('No pathname provided');

        history.push({ pathname, state });
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageContainer>
        <div>Redirecionando...</div>
      </PageContainer>
    </>
  );
};

export default Redirect;
