import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WhiteBox from '~/components/WhiteBox';

import { PageContainer, TableHeader } from '~/styles/components';

import { Container, TableTopContent } from './styles';

export default function PrintTableLocacao() {
  const { state } = useLocation();

  const { data } = state;

  useEffect(() => {
    window.print();
  }, []);

  return (
    <PageContainer>
      <Container>
        <table>
          <TableTopContent>
            <h2>Novas locações {data?.dataFormatada}</h2>
          </TableTopContent>
          <TableHeader>
            <tr>
              <th>Data/Hora Criação</th>
              <th>Nº da Locação</th>
              <th>Nome da Locação</th>
              <th>Nome da Obra</th>
              <th>Produtora</th>
              <th>ID da Obra</th>
              <th>Status</th>
              <th>Resp. Obra</th>
            </tr>
          </TableHeader>
          <WhiteBox column aCenter>
            <tbody>
              {data &&
                data?.locacoesList.map(lista => (
                  <tr key={lista.uuid}>
                    <td>{lista && lista?.created_at}</td>
                    <td>{lista && lista?.locacaoNumber}</td>
                    <td>{lista && lista?.name}</td>
                    <td>{lista && lista?.obraName}</td>
                    <td>{lista && lista?.produtoraName}</td>
                    <td>{lista && lista?.obraId}</td>
                    <td>{lista && lista?.status}</td>
                    <td>{lista && lista?.responsavelObra}</td>
                  </tr>
                ))}
            </tbody>
          </WhiteBox>
        </table>
      </Container>
    </PageContainer>
  );
}
