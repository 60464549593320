/* eslint-disable no-case-declarations */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

import { format } from 'date-fns';
import ApiClient from '~/utils/ApiClient';

import {
  ChoiceButtonLocacao,
  Input,
  Select,
  Textarea,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  LimiterInputWidth,
  ActionInputMulti,
  MultiInputContainer,
  ContainerMultiInputActions,
} from '~/styles/components';

import { NavBarHeader } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import { useList } from '~/hooks/useFunction';

import { useCurrentUser } from '~/hooks/currentUser';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import {
  getBrowserTransformDate,
  validDateArray,
} from '../Locacoes/functions/date-hour-utils';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';

const cartaDeApoioTypeList = {
  obra: [
    {
      value: 'Apoio Geral para Projetos e Obras',
      label: 'Apoio Geral para Projetos e Obras',
    },
  ],
  locacao: [
    {
      value: 'Apoio Juizado/Alvarás',
      label: 'Apoio Juizado/Alvarás',
    },
  ],
  menu_header: [
    {
      value: 'Produções de Baixo Impacto',
      label: 'Produções de Baixo Impacto',
    },
  ],
};

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function CartaApoio() {
  const location = useLocation();
  const history = useHistory();
  const { user } = useCurrentUser();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseCarta, setResponseCarta] = useState();
  const [errorsForm, setErrorsForm] = useState({});

  // states multi input
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states choices
  const [cartaUsuarioLogado, setCartaUsuarioLogado] = useState(null);
  const [utilizacaoTripe, setUtilizacaoTripe] = useState(null);
  const [intervencaoFluxo, setIntervencaoFluxo] = useState(null);

  // lists
  const workTypeSelectOptions = useList({
    url: 'work-type?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  const getCartaApoioBaixoImpacto = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `carta-apoio-tipo1/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respCarta = resp?.data?.data;

      // set temp variables
      const cartaUsuarioLogadoTmp =
        typeof respCarta?.produtora !== 'string' &&
        typeof respCarta?.cnpj_cnpj !== 'number';

      // set multi input values
      setDatasFilmagemInputs(
        respCarta?.dates_carta_apoios?.map(({ date }) => ({
          data_filmagem: getBrowserTransformDate(
            format(new Date(`${date} 00:00:00`), 'yyyy-MM-dd'),
            true,
          ),
        })),
      );

      // set choices buttons
      setCartaUsuarioLogado(cartaUsuarioLogadoTmp);
      setUtilizacaoTripe(typeof respCarta?.estruturas === 'string');
      setIntervencaoFluxo(
        respCarta?.fluxo_intervencao === 1 ||
          typeof respCarta?.intervencoes === 'string',
      );

      // set select default values
      formRef.current?.setFieldValue(
        'tipo_doc',
        cartaDeApoioTypeList['menu_header'].find(e => true),
      );
      formRef.current?.setFieldValue('work_type_uuid', {
        value: respCarta?.work_type?.uuid,
        label: respCarta?.work_type?.name,
      });

      setResponseCarta(respCarta);
      setLoading(false);
    }
  }, [dadoState?.viewData]);

  useEffect(() => getCartaApoioBaixoImpacto(), [getCartaApoioBaixoImpacto]);

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const cartaDeApoioSchema = Yup.object().shape({
        tipo_doc: Yup.string().typeError(defaultMsg).required(defaultMsg),
      });

      await cartaDeApoioSchema.validate(data, {
        abortEarly: false,
      });

      if (data?.tipo_doc !== 'Produções de Baixo Impacto') {
        return true;
      }

      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (cartaUsuarioLogado === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Carta de Apoio de usuário logado' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          cartaUsuarioLogado: true,
        }));
        return false;
      }

      const cartaDeApoioTipoUmSemObraSchema = Yup.object().shape({
        produtora: Yup.string()
          .typeError(defaultMsg)
          .test(
            'usuario-nao-logado-selecionado',
            defaultMsg,
            value =>
              !(cartaUsuarioLogado === false && (value?.length <= 0 || !value)),
          ),
        cnpj_cpf: Yup.string()
          .typeError(defaultMsg)
          .test(
            'usuario-nao-logado-selecionado',
            defaultMsg,
            value =>
              !(cartaUsuarioLogado === false && (value?.length <= 0 || !value)),
          ),
        titulo_obra: Yup.string().typeError(defaultMsg).required(defaultMsg),
        work_type_uuid: Yup.string().typeError(defaultMsg).required(defaultMsg),
        inicio: Yup.string().typeError(defaultMsg).required(defaultMsg),
        fim: Yup.string().typeError(defaultMsg).required(defaultMsg),
        address: Yup.string().typeError(defaultMsg).required(defaultMsg),
        number: Yup.string().typeError(defaultMsg).required(defaultMsg),
        neighborhood: Yup.string().typeError(defaultMsg).required(defaultMsg),
        numero_equipe: Yup.string().typeError(defaultMsg).required(defaultMsg),
        estruturas: Yup.string()
          .typeError(defaultMsg)
          .test(
            'utilizacao-tripe-selecionado',
            defaultMsg,
            value =>
              !(utilizacaoTripe === true && (value?.length <= 0 || !value)),
          ),
        intervencoes: Yup.string()
          .typeError(defaultMsg)
          .test(
            'intervencao-fluxo-selecionado',
            defaultMsg,
            value =>
              !(intervencaoFluxo === true && (value?.length <= 0 || !value)),
          ),
      });

      await cartaDeApoioTipoUmSemObraSchema.validate(data, {
        abortEarly: false,
      });

      if (utilizacaoTripe === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Utilização de Tripé' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          utilizacaoTripe: true,
        }));
        return false;
      }

      if (intervencaoFluxo === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Intervenção no fluxo' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          intervencaoFluxo: true,
        }));
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  function formatCartaDeApoioBaixoImpactoData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicionalCartaUm = omitEmpty({
      titulo_obra: data?.titulo_obra,
      work_type_uuid: data?.work_type_uuid,
      inicio: data?.inicio,
      fim: data?.fim,
      address: data?.address,
      number: data?.number,
      neighborhood: data?.neighborhood,
      numero_equipe: data?.numero_equipe,
      branch_uuid: user.uuid,
    });

    // dados de choices
    const dataCartaUsuarioNaoLogado = cartaUsuarioLogado
      ? { produtora: null, cnpj_cpf: null }
      : {
          produtora: data?.produtora,
          cnpj_cpf: data?.cnpj_cpf,
        };

    const dataUtilizacaoTripe = {
      estruturas: utilizacaoTripe ? data?.estruturas : null,
    };

    const dataIntervencaoFluxo = {
      fluxo_intervencao: intervencaoFluxo,
      ...{
        intervencoes: intervencaoFluxo ? data?.intervencoes : null,
      },
    };

    const datasFilmagem = datasFilmagemInputs
      .filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_filmagem));

    const dadosSemObra = Object.assign(
      dataNaoCondicionalCartaUm,
      { dates: datasFilmagem },
      dataUtilizacaoTripe,
      dataIntervencaoFluxo,
    );

    return Object.assign(dataCartaUsuarioNaoLogado, dadosSemObra);
  }

  function formatCartaDeApoioData(data) {
    const dataCartaDeApoio = {
      tipo_doc: data?.tipo_doc,
      cpf_cnpj: user.cpf,
      email: user.email,
    };

    /* --> dados da carta de apoio tipo 3 */
    if (data?.tipo_doc === 'Apoio Geral para Projetos e Obras') {
      return Object.assign(dataCartaDeApoio, {
        name_view: 'Work',
        reference_uuid: dadoState?.work_uuid,
        solicitante: user.name,
      });
    }

    /* --> dados da carta de apoio tipo 2 */
    if (data?.tipo_doc === 'Apoio Juizado/Alvarás') {
      return Object.assign(dataCartaDeApoio, {
        name_view: dadoState?.name_view,
        reference_uuid: dadoState?.locacao_uuid,
        solicitante: user.name,
      });
    }

    /* --> dados da carta de apoio tipo 1 */
    return Object.assign(dataCartaDeApoio, {
      name_view: 'Carta Tipo 1',
      solicitante: cartaUsuarioLogado ? user.name : data?.produtora,
    });
  }

  async function handleSubmit(data) {
    if (!(await handleValidationFields(data))) {
      return;
    }

    const cartaDeApoioData = formatCartaDeApoioData(data);

    setLoading(true);

    try {
      let referenceUuid = {};

      if (data?.tipo_doc === 'Produções de Baixo Impacto') {
        const cartaDeApoioBaixoImpactoData =
          formatCartaDeApoioBaixoImpactoData(data);

        const responseCartaTipoUm = await ApiClient.doRequest({
          uri: 'carta-apoio-tipo1',
          method: ApiClient.POST,
          body: cartaDeApoioBaixoImpactoData,
          expectedStatus: 201,
        });

        referenceUuid = { reference_uuid: responseCartaTipoUm.data.data.uuid };
      }

      await ApiClient.doRequest({
        uri: 'carta-apoio-request',
        method: ApiClient.POST,
        body: Object.assign(cartaDeApoioData, referenceUuid),
        expectedStatus: 201,
      });

      await showModalMessage({
        type: 'success',
        title: 'Carta de Apoio',
        text: 'Cadastro realizado com sucesso!!',
      });
      history.push('/home-user');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      await showModalMessage({
        type: 'error',
        text: 'Ocorreu um erro ao tentar gravar Carta de Apoio. Verifique os dados.',
      });
    } finally {
      setLoading(false);
    }
  }

  // handle true/false
  function handleCartaUsuarioLogadoTrue(e) {
    e.preventDefault();
    setCartaUsuarioLogado(true);
    setErrorsForm(state => ({
      ...state,
      cartaUsuarioLogado: false,
    }));
  }

  function handleCartaUsuarioLogadoFalse(e) {
    e.preventDefault();
    setCartaUsuarioLogado(false);
    setErrorsForm(state => ({
      ...state,
      cartaUsuarioLogado: false,
    }));
  }

  function handleUtilizacaoTripeTrue(e) {
    e.preventDefault();
    setUtilizacaoTripe(true);
    setErrorsForm(state => ({
      ...state,
      utilizacaoTripe: false,
    }));
  }

  function handleUtilizacaoTripeFalse(e) {
    e.preventDefault();
    setUtilizacaoTripe(false);
    setErrorsForm(state => ({
      ...state,
      utilizacaoTripe: false,
    }));
  }

  function handleIntervencaoFluxoTrue(e) {
    e.preventDefault();
    setIntervencaoFluxo(true);
    setErrorsForm(state => ({
      ...state,
      intervencaoFluxo: false,
    }));
  }

  function handleIntervencaoFluxoFalse(e) {
    e.preventDefault();
    setIntervencaoFluxo(false);
    setErrorsForm(state => ({
      ...state,
      intervencaoFluxo: false,
    }));
  }

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseCarta) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>
                Nenhuma Carta de Apoio encontrada
              </h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="tipo_doc"
                      label="Carta de Apoio"
                      placeholder="Selecione"
                      options={cartaDeApoioTypeList[dadoState?.trigger]}
                      onChange={value => {
                        handleSelectChange('tipo_doc', value);
                      }}
                      required
                      style={selectCustomStyles}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                {formData?.tipo_doc === 'Produções de Baixo Impacto' && clientResources.tenant !== 'ilhacine' && (
                  <Wrap>
                    <Textarea
                      name="info_carta_um"
                      rows="12"
                      value="Essa carta de apoio é exclusivamente para produções que filmem em áreas públicas municipais do Município de São Paulo com as restrições abaixo elencadas:&#13;&#13;- Sem a ocupação do solo por tripés e demais estruturas&#13;- Sem intervenção no fluxo de vias e/ou passeio (calçada)&#13;- Com equipe reduzida&#13;&#13;Não trata-se de uma autorização, mas sim de uma previsão na Lei do Artista de Rua (Lei 15.776/2013) demanifestações artísticas ocorrerem com ressalvas. A Carta de Apoio não é uma autorização, e sim uma formalização de que aquela filmagem, nos moldes solicitados, já pode acontecer sem autorização uma vez que está prevista em Lei. Caso a produção apresente as características que se enquadrem na Lei, essa carta será emitida. Caso a produção não se enquadre nesses moldes, é necessário que haja o trâmite formal de autorização."
                      disabled
                    />
                  </Wrap>
                )}

                {formData?.tipo_doc === 'Produções de Baixo Impacto' && clientResources.tenant === 'ilhacine' && (
                  <Wrap>
                    <Textarea
                      name="info_carta_um"
                      rows="12"
                      value=""
                      disabled
                    />
                  </Wrap>
                )}

                {formData?.tipo_doc === 'Apoio Juizado/Alvarás' && (
                  <Wrap>
                    <Textarea
                      name="info_carta_dois"
                      rows="5"
                      value="A carta de apoio solicitada são exclusivamente para as produções que necessitam de alguma documentação da Prefeitura para o auxílio na obtenção de alvará nos casos em que há menores de idade no elenco das filmagens. Nela são previstas produções que já possuem cadastro na São Paulo Film Commission e estão com as filmagens em andamento.&#13;&#13;Trata-se de um apoio, e não correspondem a autorização prévia."
                      disabled
                    />
                  </Wrap>
                )}

                {formData?.tipo_doc === 'Apoio Geral para Projetos e Obras' && (
                  <Wrap>
                    <Textarea
                      name="info_carta_tres"
                      rows="2"
                      value="Trata-se de modelos gerais que demonstram o apoio da Prefeitura ao projeto. Podem ser
                      utilizadas, por exemplo, na negociação de locações não-municipais."
                      disabled
                    />
                  </Wrap>
                )}

                {formData?.tipo_doc === 'Produções de Baixo Impacto' && (
                  <>
                    <Wrap style={{ marginTop: 20 }}>
                      <LimiterInputWidth w50>
                        <ChoiceButtonLocacao
                          label="Deseja solicitar a carta de apoio com os dados do usuário logado?"
                          onClickTrue={handleCartaUsuarioLogadoTrue}
                          onClickFalse={handleCartaUsuarioLogadoFalse}
                          selected={cartaUsuarioLogado}
                          required
                          error={
                            errorsForm.cartaUsuarioLogado === true &&
                            'Obrigatório'
                          }
                          isDisabled={dadoState?.viewData && true}
                        />
                      </LimiterInputWidth>
                    </Wrap>

                    {cartaUsuarioLogado === false && (
                      <>
                        <Wrap>
                          <Input
                            type="text"
                            name="produtora"
                            label="Razão Social ou Nome Completo responsável pela obra"
                            onChange={handleChange}
                            required
                            defaultValue={
                              dadoState?.viewData && responseCarta?.produtora
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </Wrap>

                        <Wrap>
                          <LimiterInputWidth w25>
                            <Input
                              type="number"
                              name="cnpj_cpf"
                              label="CNPJ ou CPF responsável"
                              onChange={handleChange}
                              required
                              defaultValue={
                                dadoState?.viewData && responseCarta?.cnpj_cpf
                              }
                              disabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>
                        </Wrap>
                      </>
                    )}

                    {formData?.tipo_doc === 'Produções de Baixo Impacto' && (
                      <>
                        <Wrap>
                          <Input
                            type="text"
                            name="titulo_obra"
                            label="Nome da Obra"
                            onChange={handleChange}
                            required
                            defaultValue={
                              dadoState?.viewData && responseCarta?.titulo_obra
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </Wrap>

                        <Wrap>
                          <LimiterInputWidth w50>
                            <Select
                              name="work_type_uuid"
                              label="Formato"
                              placeholder="Selecione"
                              required
                              style={selectCustomStyles}
                              options={workTypeSelectOptions}
                              onChange={value => {
                                handleSelectChange('work_type_uuid', value);
                              }}
                              isDisabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>
                        </Wrap>

                        <Wrap
                          style={{ display: 'flex', alignItems: 'flex-start' }}
                        >
                          <DateFormMultiInput
                            label="Data da Filmagem"
                            dates={datasFilmagemInputs}
                            setDates={setDatasFilmagemInputs}
                            setIsLoading={setLoading}
                            isViewMode={dadoState?.viewData}
                            dateKeyName="data_filmagem"
                            isAdminRequest={isAdminRequest}
                            isRequired
                          />

                          <MultiInputContainer>
                            <BoxHeader style={{ justifyContent: 'normal' }}>
                              <span
                                className="boxText"
                                style={{ color: '#828282' }}
                              >
                                Horário de Filmagem
                              </span>
                            </BoxHeader>

                            <Wrap>
                              <LimiterInputWidth w25>
                                <Input
                                  type="time"
                                  name="inicio"
                                  label="Das"
                                  onChange={handleChange}
                                  required
                                  defaultValue={
                                    dadoState?.viewData && responseCarta?.inicio
                                  }
                                  disabled={dadoState?.viewData && true}
                                />
                              </LimiterInputWidth>

                              <LimiterInputWidth w25>
                                <Input
                                  type="time"
                                  name="fim"
                                  label="às"
                                  onChange={handleChange}
                                  required
                                  defaultValue={
                                    dadoState?.viewData && responseCarta?.fim
                                  }
                                  disabled={dadoState?.viewData && true}
                                />
                              </LimiterInputWidth>
                            </Wrap>
                          </MultiInputContainer>
                        </Wrap>

                        <Wrap>
                          <Textarea
                            type="text"
                            name="address"
                            label="Endereço(s) da Filmagem"
                            onChange={handleChange}
                            required
                            rows="2"
                            defaultValue={
                              dadoState?.viewData && responseCarta?.address
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </Wrap>

                        <Wrap>
                          <LimiterInputWidth w50>
                            <Input
                              type="number"
                              name="number"
                              label="Complemento - Número"
                              onChange={handleChange}
                              required
                              defaultValue={
                                dadoState?.viewData && responseCarta?.number
                              }
                              disabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>

                          <LimiterInputWidth w50>
                            <Input
                              type="text"
                              name="neighborhood"
                              label="Bairro"
                              onChange={handleChange}
                              required
                              defaultValue={
                                dadoState?.viewData &&
                                responseCarta?.neighborhood
                              }
                              disabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>
                        </Wrap>

                        <Wrap>
                          <LimiterInputWidth w25>
                            <Input
                              type="number"
                              name="numero_equipe"
                              label="Número Total de Equipe"
                              onChange={handleChange}
                              required
                              defaultValue={
                                dadoState?.viewData &&
                                responseCarta?.numero_equipe
                              }
                              disabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>
                        </Wrap>

                        <Wrap>
                          <LimiterInputWidth w50>
                            <ChoiceButtonLocacao
                              label="Haverá utilização de tripé(s) ou outra(s) estrutura(s) de apoio?"
                              onClickTrue={handleUtilizacaoTripeTrue}
                              onClickFalse={handleUtilizacaoTripeFalse}
                              selected={utilizacaoTripe}
                              required
                              error={
                                errorsForm.utilizacaoTripe === true &&
                                'Obrigatório'
                              }
                              isDisabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>
                        </Wrap>

                        {utilizacaoTripe && (
                          <Wrap>
                            <Textarea
                              type="text"
                              name="estruturas"
                              label="Quais estruturas serão montadas? "
                              onChange={handleChange}
                              required
                              rows="2"
                              defaultValue={
                                dadoState?.viewData && responseCarta?.estruturas
                              }
                              disabled={dadoState?.viewData && true}
                            />
                          </Wrap>
                        )}

                        <Wrap>
                          <LimiterInputWidth w50>
                            <ChoiceButtonLocacao
                              label="Haverá intervenção no fluxo de pedestres/veículos?"
                              onClickTrue={handleIntervencaoFluxoTrue}
                              onClickFalse={handleIntervencaoFluxoFalse}
                              selected={intervencaoFluxo}
                              required
                              error={
                                errorsForm.intervencaoFluxo === true &&
                                'Obrigatório'
                              }
                              isDisabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>
                        </Wrap>

                        {intervencaoFluxo && (
                          <Wrap>
                            <Textarea
                              type="text"
                              name="intervencoes"
                              label="Quais intervenções serão realizadas?"
                              onChange={handleChange}
                              required
                              rows="2"
                              defaultValue={
                                dadoState?.viewData &&
                                responseCarta?.intervencoes
                              }
                              disabled={dadoState?.viewData && true}
                            />
                          </Wrap>
                        )}
                      </>
                    )}
                  </>
                )}
              </WhiteBox>

              {!dadoState.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  disabled={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon disabled />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Carta de Apoio</h1>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
      <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
