import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import ReactInputMask from 'react-input-mask';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import CurrencyInput from 'react-currency-input-field';
import { Container } from './styles';

export default function InputNumber({
  name,
  label,
  required,
  icon,
  questionTooltip,
  questionOnClick,
  onChange,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label htmlFor={name}>
        {label}
        {label && required && <span className="danger">*</span>}
        {questionTooltip && (
          <button
            type="button"
            tooltip={questionTooltip}
            onClick={questionOnClick}
            style={{ height: 0 }}
          >
            <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
          </button>
        )}
      </label>
      <CurrencyInput
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        decimalsLimit={2}
        {...rest}
      />
      {icon && <button type="submit">{icon}</button>}
      {error && <span className="error">{error}</span>}
    </Container>
  );
}
