import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { WhiteBox } from '~/components/WhiteBox/styles';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-x: none;
  background-color: #f2f2f2;
  padding: 25px 25px 0 45px;
  margin: 0 auto;

  table {
    width: 100%;
    margin-top: 32px;

    thead {
      width: 100%;
      padding-right: 40px;
      padding-left: 40px;
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 105%;

  margin: 100px auto 32px auto;

  ${WhiteBox} {
    justify-content: center;

    .swiper-slide {
      width: 300px !important;
    }

    img {
      height: 72px;
    }
  }
`;

export const CardImage = styled.div`
  display: flex;
  position: relative;
  margin-right: 62px;
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #eb5757;
  font-family: 'InterBold', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -15px;
  right: -30px;
`;

export const CardTitle = styled.div`
  font-family: 'InterBold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #4f4f4f;
  text-transform: uppercase;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 80px;

  h2 {
    font-family: 'InterSemiBold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #011f3b;
  }

  &.modalScrollBar {
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
`;

export const Lembretes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 440px;
  overflow: auto;
  overflow-x: none;
  border-left: 1px solid #bdbdbd;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 440px;
  overflow: auto;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    overflow-y: auto;
  }

  > h2 {
    /*margin-left: 90px;
    margin-bottom: 10px;
    margin-top: 10px;*/

    margin: 10px 0 10px 90px;

    width: 100%;

    font-family: 'InterSemiBold', sans-serif;
    font-size: 20px;
    line-height: 24px;

    color: #011f3b;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const CardLembrete = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 24px 48px 48px 24px;
  margin-bottom: 48px;

  &:last-child {
    margin: 0;
  }
`;

export const EmptyListTest = styled.h5`
  color: black;
  font-size: 20px;
`;

export const DateAtividade = styled.h5`
  margin-left: 45px;
  color: black;
  font-size: 25px;
`;

export const ViewLinkWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'PoppinsSemiBold', sans-serif;
  color: #4f4f4f;

  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px;

  & > * + * {
    margin-left: 5px;
  }
`;

export const CardAtividade = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 30px;
  margin: 10px 100px 10px 40px;
  padding-right: 100px;
  width: 100%;

  &::before {
    content: '';
    display: block;
    background: ${props => props.tagColor};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0;
    position: absolute;
    left: 5px;
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 31px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #f2f2f2;
  }
`;

export const DateLembrete = styled.h5`
  font-family: 'PoppinsBold', sans-serif;
  font-size: 13px;
  line-height: 17px;
  color: #334d6e;
  opacity: 0.5;
  margin-bottom: 8px;
`;

export const DescriptionLembrete = styled.p`
  font-family: 'PoppinsSemiBold', sans-serif;
  font-size: 13px;
  line-height: 17px;
  color: #333333;
  opacity: 0.5;
`;

export const TableTopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0 24px 0;

  h2 {
    font-family: 'PoppinsBold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #323c47;
  }
`;

export const TableButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button,
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #323c47;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      margin-right: 12px;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;

  a {
    font-family: 'RobotoBold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
  }

  label {
    color: #000;
    font-size: 13px;
    font-family: 'InterRegular', sans-serif;
  }

  .calendario {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 0;

    .react-calendar__month-view__days {
      display: flex;
      flex-wrap: wrap;
      height: 350px;
    }

    .react-calendar__navigation__label__labelText {
      color: #000;
      text-transform: capitalize;
      font-size: 25px;
    }

    .react-calendar__month-view__days__day {
      border-radius: 50%;
    }

    .react-calendar__tile--active {
      background-color: #5fae37;
    }

    .react-calendar__tile--now {
      border: 1px solid #26a2b1;
      color: #fff;
      background: #26a2b1;
    }
    .react-calendar__month-view__weekdays {
      color: #000;
    }
  }
`;

export const ButtonModal = styled.button`
  display: flex;
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  color: #99a6b7;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const RegAtivSideLeft = styled.aside`
  flex: 1;
  padding: 20px 50px 0 0;

  > h2 {
    font-family: 'InterSemiBold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #011f3b;

    margin-bottom: 20px;
  }
`;

export const Separator = styled.hr`
  display: flex;
  border-left: 1px solid #bdbdbd;
  margin-right: -16px;
  height: 420px;
`;

export const RegAtivSideRight = styled.main`
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;

  > h2 {
    font-family: 'InterSemiBold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #011f3b;
  }
`;

export const CardAtivField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  > h5 {
    font-family: 'PoppinsBold', sans-serif;
    font-size: 13px;
    line-height: 17px;
    color: #334d6e;
    opacity: 0.5;
  }

  > p {
    margin-left: 10px;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 13px;
    line-height: 17px;
    color: #333333;
    opacity: 0.5;
  }
`;

export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: 40,
  borderRadius: 10,
  top: 0,
  left: 0,
  minHeight: 150,
  width: '90%',
  height: '600px',
  maxWidth: 600,
  border: 'none',
};

export const ObraTypeFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  margin-top: 20px;
`;

export const ObraTypeFilter = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: left;
  align-items: center;

  padding: 15px 0;

  border-bottom: 1px solid rgba(189, 189, 189, 0.5);

  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  line-height: 24px;

  color: #011f3b;

  &::before {
    content: '';
    display: block;
    background: ${props => props.tagColor};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin-right: 15px;
  }

  &:hover {
    opacity: 0.5;
  }

  &.filter-selected {
    opacity: 0.5;
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 45px;
  height: 45px;
  background-color: #ecebff;
  border-radius: 45px;
  border: none;
  top: -2%;
  z-index: 2;
  font-size: 3.2rem;
  color: #000;

  &:hover {
    animation-duration: 1s;
    animation-iteration-count: 1;
  }

  ${props =>
    props.direction === 'left'
      ? css`
          left: -92%;
        `
      : css`
          right: 7%;
        `}

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const RegistroContainer = styled.div`
  display: flex;
`;
