import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  width: 100%;
  height: 100%;
`;

export const CloseIcon = styled(AiOutlineClose)`
  font-weight: bold;
  font-size: 26px;
  color: #000;
  cursor: pointer;

  position: absolute;
  top: -30px;
  right: -30px;
`;
