import React, { useCallback, useEffect, useState } from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { RiArrowGoBackLine } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import MultiRef from 'react-multi-ref';

import { AiOutlineReload } from 'react-icons/ai';
import {
  Content,
  InnerHeader,
  ItemVideo,
  LinkBack,
  MainLoading,
  SearchContainer,
} from './styles';
import { PageContent, PageFooter } from '~/pages/Usuario/styles';
import { GridContainer, PageContainer } from '~/styles/components';
import { showToastMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';

import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import { InputNoForm } from '~/components/Forms';
import { Button } from '~/components/global';
import VideoPopUp from '~/components/Usuario/VideoPopUp';
import clientResources from '~/data/clientResources';

const Tutorial = () => {
  const playersRef = new MultiRef();
  const [loading, setLoading] = useState(false);

  const [palavraChave, setPalavraChave] = useState('');
  const [filters, setFilters] = useState('');

  const [openVideo, setOpenVideo] = useState(false);
  const [videosTutoriais, setVideosTutoriais] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState('');

  const [page, setPage] = useState(1);
  const [totPages, setTotPages] = useState(1);

  const getVideos = useCallback(
    async _ => {
      try {
        setLoading(true);

        const resp = await ApiClient.doRequest({
          uri: `video?page=${page}${filters}`,
          method: ApiClient.GET,
          token: '',
          expectedStatus: 200,
        });

        setVideosTutoriais(resp?.data?.data);
        setTotPages(resp?.data?.meta?.pagination?.total_pages);

        setLoading(false);
      } catch (e) {
        setLoading(false);
        showToastMessage({
          type: 'error',
          text: 'Erro ao buscar vídeos',
        });
      }
    },
    [page, filters],
  );

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  const onOpenVideoModal = () => {
    setOpenVideo(!openVideo);
  };

  return (
    <>
      {loading && (
        <MainLoading loading={loading}>
          <h3>Processando Informações</h3>
          <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
        </MainLoading>
      )}

      <Header>
        <LogoIcon disabled />
      </Header>

      <PageContainer style={{ backgroundColor: '#fbfbfb' }}>
        <PageContent>
          <Content>
            <InnerHeader>
              <SearchContainer>
                <InputNoForm
                  name="palavraChave"
                  type="text"
                  placeholder="Pesquisar por palavra-chave"
                  value={palavraChave}
                  onChange={e => setPalavraChave(e.target.value)}
                />
                <Button
                  btType="#26A2B1"
                  action={() => {
                    setPage(1);
                    setFilters(
                      palavraChave
                        ? `&filters[][title@like]=${palavraChave}`
                        : palavraChave,
                    );
                  }}
                  label="Buscar"
                  full
                  loading={loading}
                  width="170px"
                />
              </SearchContainer>

              <LinkBack to="/login">
                <RiArrowGoBackLine color="#000" size={25} />
              </LinkBack>
            </InnerHeader>

            {videosTutoriais?.length <= 0 && (
              <span style={{ color: '#000', textAlign: 'center' }}>
                Nenhum vídeo cadastrado ainda
              </span>
            )}

            <GridContainer
              cols={3}
              gap={20}
              style={{
                alignItems: 'flex-start',
                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                justifyItems: 'center',
              }}
            >
              {videosTutoriais?.map(video => (
                <ItemVideo>
                  <ReactPlayer
                    ref={playersRef.ref(video.uuid)}
                    key={video.uuid}
                    url={video.url}
                    width="100%"
                    height={200}
                    light
                    onClickPreview={() => {
                      playersRef.map.get(video.uuid).showPreview();
                      setSelectedVideo(video.url);
                      onOpenVideoModal();
                    }}
                  />
                  <span>{video.title}</span>
                </ItemVideo>
              ))}
            </GridContainer>

            {page !== totPages && (
              <Button
                btType="#26A2B1"
                action={() => {
                  setPage(prevState => prevState + 1);
                }}
                full
                loading={loading}
                width="200px"
                label="Carregar mais"
                style={{ marginTop: 20, alignSelf: 'center' }}
                icon={<AiOutlineReload size={26} />}
              />
            )}
          </Content>
        </PageContent>
      </PageContainer>
      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
      <VideoPopUp
        url={selectedVideo}
        open={openVideo}
        toggleModal={onOpenVideoModal}
      />
    </>
  );
};

export default Tutorial;
