import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';

import Modal from 'react-modal';
import ReminderActions from '~/store/ducks/reminder';

import { Input, Textarea, Select } from '~/components/Forms';

import { Button } from '~/components/global';

import {
  ModalContent,
  overlayStyle,
  contentStyle,
  ModalButton,
  Wrap,
  WrapButtons,
} from './styles';
import Load from '~/components/Load';

import ApiClient from '~/utils/ApiClient';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    width: '250px',
    padding: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    color: '#828282',
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

function ModalLembrete({ editData, openModal, closeModal, loadReminder }) {
  const [formData, setFormData] = useState(editData || {});
  const [load, setLoad] = useState(false);
  const [reminderTypeList, setReminderTypeList] = useState([]);

  const formRef = useRef();
  const dispatch = useDispatch();

  async function renderReminderTypeSelect() {
    const result = await ApiClient.doRequest({
      uri: 'reminder-type',
      method: ApiClient.GET,
      expectedStatus: 200,
    });

    setReminderTypeList(
      result.data.data.map(item => ({ value: item.uuid, label: item.name })),
    );
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleSubmit(data) {
    try {
      setLoad(true);

      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      formData.place = 'lugar'; // temp
      formData.starts = data?.starts;
      formData.ends = data?.ends;
      formData.starts_time = data?.starts_time;
      formData.ends_time = data?.ends_time;
      formData.reminder_type_uuid = data?.reminder_type_uuid;

      const schema = Yup.object().shape({
        title: Yup.string().typeError(defaultMsg).required(defaultMsg),
        place: Yup.string().typeError(defaultMsg).required(defaultMsg),
        starts: Yup.string().typeError(defaultMsg).required(defaultMsg),
        starts_time: Yup.string().typeError(defaultMsg).required(defaultMsg),
        ends: Yup.string().typeError(defaultMsg).required(defaultMsg),
        ends_time: Yup.string().typeError(defaultMsg).required(defaultMsg),
        text: Yup.string().typeError(defaultMsg).required(defaultMsg),
        reminder_type_uuid: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      const formDataAux = JSON.parse(JSON.stringify(formData));

      formDataAux.starts = formDataAux.starts
        .toString()
        .concat(' ')
        .concat(formDataAux.starts_time.toString());

      formDataAux.ends = formDataAux.ends
        .toString()
        .concat(' ')
        .concat(formDataAux.ends_time.toString());

      if (!editData) {
        dispatch(ReminderActions.createRequest(formDataAux));
      } else {
        delete formDataAux.reminder_type;
        dispatch(ReminderActions.editRequest(formDataAux));
      }

      setTimeout(function () {
        loadReminder({filterAtividadeDate: ''});
        setFormData({});
        setLoad(false);
        closeModal();
      }, 2000);
    } catch (err) {
      setLoad(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal
      onAfterOpen={renderReminderTypeSelect}
      onRequestClose={closeModal}
      isOpen={openModal}
      style={{
        overlay: overlayStyle,
        content: contentStyle,
      }}
    >
      <Load inLoad={load} />
      <ModalButton onClick={closeModal}>X</ModalButton>
      <ModalContent>
        {formData && (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>Adicionar Lembrete</h2>
            <Wrap>
              <Input
                type="text"
                name="title"
                label="Título "
                onChange={handleChange}
                defaultValue={editData?.title}
                required
              />
              <Select
                name="reminder_type_uuid"
                label="Tipo"
                placeholder="Selecione"
                classError="error"
                required
                defaultValue={{
                  label: editData?.reminder_type.name,
                  value: editData?.reminder_type.uuid,
                }}
                options={reminderTypeList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('reminder_type_uuid', value);
                }}
              />
            </Wrap>
            <Wrap>
              <Input
                type="date"
                name="starts"
                label="Começa "
                onChange={handleChange}
                defaultValue={editData ? editData.starts.split(' ')[0] : ''}
                required
              />
              <Input
                type="time"
                name="starts_time"
                onChange={handleChange}
                defaultValue={editData ? editData.starts.split(' ')[1] : ''}
                required
              />
            </Wrap>
            <Wrap>
              <Input
                type="date"
                name="ends"
                label="Termina"
                onChange={handleChange}
                defaultValue={editData ? editData.ends.split(' ')[0] : ''}
                required
              />
              <Input
                type="time"
                name="ends_time"
                onChange={handleChange}
                defaultValue={editData ? editData.ends.split(' ')[1] : ''}
                required
              />
            </Wrap>
            <Textarea
              type="text"
              name="text"
              label="Nota"
              onChange={handleChange}
              defaultValue={editData?.text}
              rows="5"
              required
            />
            <WrapButtons>
              <button type="button" onClick={() => closeModal()}>
                Cancelar
              </button>
              <Button
                type="submit"
                btType="#26A2B1"
                onClick={() => handleSubmit()}
                label="Salvar"
                full
                loading={load}
                width="150px"
              />
            </WrapButtons>
          </Form>
        )}
      </ModalContent>
    </Modal>
  );
}

export default withRouter(ModalLembrete);
