import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-family: 'InterSemiBold';
  font-size: 16px;
  line-height: 20px;
  max-height: 54px;

  width: ${({ full }) => (full ? '100%' : 'max-content')};
  max-width: ${({ width }) => (width ? [width] : 'none')};
  padding: ${({ icon }) => (icon ? '16px 24px' : '16px 0')};
  background-color: ${({ btType }) => (btType ? [btType] : '#f2f2f2')};
  color: ${({ btType }) => (btType === 'disable' ? '#f2f2f2' : '#ffffff')};
  transition: 300ms ease;

  &:hover {
    transform: scale(0.98);
  }

  svg {
    margin-left: 8px;
    color: ${({ btType }) => (btType === 'disable' ? '#f2f2f2' : '#ffffff')};
  }

  img {
    margin-right: 8px;
  }

  .icon-spin {
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;
