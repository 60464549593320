import { all, takeLatest } from 'redux-saga/effects';

// register
import { RegisterTypes } from '~/store/ducks/register';
import { register as registerRequest } from './register';

// auth
import { AuthTypes } from '~/store/ducks/auth';
import { login, logout, refreshToken } from './auth';

// users-list
import { UsersListTypes } from '../ducks/users-list';
import {
  fetch as usersListFetch,
  fetchSelect as usersListFetchSelect,
  fetchUsersAdminMod as usersAdminMod,
} from './users-list';

// create-password
import { CreatePasswordTypes } from '~/store/ducks/create-password';
import { create as passwordRequest } from './create-password';

// userME
import { UserMeTypes } from '../ducks/userMe';
import { fetchShow as usersShowFetch } from './userMe';

// work
import { WorkTypes } from '~/store/ducks/work';
import {
  create as workCreate,
  show as workShow,
  edit as workEdit,
  destroy as workDelete,
} from './work';

// work-list
import { WorkListTypes } from '../ducks/work-list';
import {
  fetch as workListFetch,
  fetchSelect as workListFetchSelect,
  fetchFilter as workFetchFilter,
} from './work-list';

// work-status-list
import { WorkStatusListTypes } from '../ducks/work-status-list';
import {
  fetch as workStatusListFetch,
  fetchSelect as workStatusListFetchSelect,
} from './work-status-list';

// work-type-list
import { WorkTypeListTypes } from '../ducks/work-type-list';
import {
  fetch as workTypeListFetch,
  fetchSelect as workTypeListFetchSelect,
} from './work-type-list';

// countries-list
import { CountriesListTypes } from '../ducks/countries-list';
import {
  fetch as countriesListFetch,
  fetchSelect as countriesListFetchSelect,
} from './countries-list';

// states-list
import { StatesListTypes } from '../ducks/states-list';
import {
  fetch as statesListFetch,
  fetchSelect as statesListFetchSelect,
} from './states-list';

// countries-list
import { CitiesListTypes } from '../ducks/cities-list';
import {
  fetch as citiesListFetch,
  fetchSelect as citiesListFetchSelect,
} from './cities-list';

// neighborhood
import { NeighborhoodTypes } from '~/store/ducks/neighborhood';
import {
  create as neighborhoodCreate,
  show as neighborhoodShow,
  edit as neighborhoodEdit,
  destroy as neighborhoodDelete,
} from './neighborhood';

// neightborhood-list
import { NeighborhoodListTypes } from '../ducks/neighborhood-list';
import {
  fetch as neighborhoodListFetch,
  fetchSelect as neighborhoodListFetchSelect,
} from './neighborhood-list';

// local-gerador-list
import { LocalGeradorListTypes } from '../ducks/local-gerador-list';
import {
  fetch as localGeradorListFetch,
  fetchSelect as localGeradorListFetchSelect,
} from './local-gerador-list';

// pontos-fixos-terminais-list
import { PontosFixosTerminaisListTypes } from '../ducks/pontos-fixos-terminais-list';
import {
  fetch as pontosFixosTerminaisListFetch,
  fetchSelect as pontosFixosTerminaisListFetchSelect,
} from './pontos-fixos-terminais-list';

// opcoes-trajeto-list
import { TrajetoTerminalListTypes } from '../ducks/trajeto-terminal-list';
import {
  fetch as trajetoTerminalListFetch,
  fetchSelect as trajetoTerminalListFetchSelect,
} from './trajeto-terminal-list';

// local-gerador-list
import { TerminaisOnibusListTypes } from '../ducks/terminais-onibus-list';
import {
  fetch as terminaisOnibusListFetch,
  fetchSelect as terminaisOnibusListFetchSelect,
} from './terminais-onibus-list';

// finalidade-acesso-list
import { FinalidadeAcessoListTypes } from '../ducks/finalidade-acesso-list';
import {
  fetch as finalidadeAcessoListFetch,
  fetchSelect as finalidadeAcessoListFetchSelect,
} from './finalidade-acesso-list';

// users-address
import { UserAddressTypes } from '~/store/ducks/users-address';
import {
  create as userAddressCreate,
  show as userAddressShow,
  edit as userAddressEdit,
  destroy as userAddressDelete,
} from './users-address';

// users-personal-data
import { UserPersonalDataTypes } from '~/store/ducks/users-personal-data';
import {
  create as userPersonalDataCreate,
  show as userPersonalDataShow,
  edit as userPersonalDataEdit,
  destroy as userPersonalDataDelete,
} from './users-personal-data';

// user-personal-data-list
import { UserPersonalDataListTypes } from '../ducks/users-personal-data-list';
import {
  fetch as userPersonalDataListFetch,
  fetchSelect as userPersonalDataListFetchSelect,
  fetchCompare as userPersonalDataListFetchCompare,
} from './users-personal-data-list';

// branch-list
import { BranchListTypes } from '../ducks/branch-list';
import {
  fetch as branchListFetch,
  fetchSelect as branchListFetchSelect,
} from './branch-list';

// complemento-numero-list
import { ComplementoNumeroListTypes } from '../ducks/complemento-numero-list';
import {
  fetch as complementoNumeroListFetch,
  fetchSelect as complementoNumeroListFetchSelect,
} from './complemento-numero-list';

// equipamento-filmagem-list
import { EquipamentoFilmagemListTypes } from '../ducks/equipamento-filmagem-list';
import {
  fetch as equipamentosFilmagemListFetch,
  fetchSelect as equipamentosFilmagemFetchSelect,
} from './equipamento-filmagem-list';

// praia-list
import { PraiaListTypes } from '../ducks/praia-list';
import {
  fetch as praiaListFetch,
  fetchSelect as praiaFetchSelect,
} from './praia-list';

// instalacoes
import { InstalacoesListTypes } from '../ducks/instalacoes';
import { fetchSelect as instalacoesFetchSelect } from './instalacoes';

// ld-galerias
import { LdGaleriasListTypes } from '../ducks/ld-galerias';
import { fetchSelect as ldGaleriasFetchSelect } from './ld-galerias';

// ld-grua-opcoes
import { LdGruaOpcoesListTypes } from '../ducks/ld-grua-opcoes';
import { fetchSelect as ldGruaOpcoesListFetchSelect } from './ld-grua-opcoes';

// get by galerias
import { GetByGaleriasListTypes } from '../ducks/get-by-galeria';
import { fetchSelect as getByGaleriasFetchSelect } from './get-by-galeria';

// ld edificios pontos fixos
import { LdEdificiosListTypes } from '../ducks/ld-ponto-fixo-edificio';
import { fetchSelect as ldEdificiosFetchSelect } from './ld-ponto-fixo-edificio';

// ld educacao pontos fixos
import { LdPontoFixoEducacaoListTypes } from '../ducks/ld-ponto-fixo-educacao';
import { fetchSelect as ldPontoFixoEducacaoFetchSelect } from './ld-ponto-fixo-educacao';

// ld-tipo-camera-car
import { LdCameraCarListTypes } from '../ducks/ld-tipo-camera-car';
import { fetchSelect as ldCameraCaretchSelect } from './ld-tipo-camera-car';

// ld equipamentos smc
import { LdEquipamentosSmcListTypes } from '../ducks/ld-equipamentos-smc';
import { fetchSelect as ldEquipamentosSmcFetchSelect } from './ld-equipamentos-smc';

// instalacoes
import { LdInstacoesEsportivasListTypes } from '../ducks/ld-instalacoes-esportivas';
import { fetchSelect as instacoesEsportivasFetchSelect } from './ld-instalacoes-esportivas';

// ld tipo comboio
import { LdTipoComboioListTypes } from '../ducks/ld-tipo-comboio';
import { fetchSelect as ldTipoComboioFetchSelect } from './ld-tipo-comboio';

// pavimentada
import { PavimentacaoListTypes } from '../ducks/ld-area-pavimentada';
import { fetchSelect as pavimentadaFetchSelect } from './ld-area-pavimentada';

// ld-biblioteca
import { LdBibliotecaListTypes } from '../ducks/ld-biblioteca';
import { fetchSelect as ldBibliotecaFetchSelect } from './ld-biblioteca';

// ld-museu
import { LdMuseuListTypes } from '../ducks/ld-museu';
import { fetchSelect as ldMuseuFetchSelect } from './ld-museu';

// ld-casas-cultura
import { LdCasasCulturalListTypes } from '../ducks/ld-casas-cultura';
import { fetchSelect as ldCasasCulturaFetchSelect } from './ld-casas-cultura';

// ld-centro-cultural
import { LdCentroCulturalListTypes } from '../ducks/ld-centro-cultural';
import { fetchSelect as ldCentroCulturalFetchSelect } from './ld-centro-cultural';

// ld-projeto-pesquisa-socioeconomica
import { LdProjetoPesquisaEconomicaListTypes } from '../ducks/ld-projeto-pesquisa-socioeconomica';
import { fetchSelect as ldProjetoPesquisaEconomicaFetchSelect } from './ld-projeto-pesquisa-socioeconomica';

// ld-razao-pesquisa-socioeconomica
import { LdRazaoPesquisaSocioeconomicaListTypes } from '../ducks/ld-razao-pesquisa-socioeconomica';
import { fetchSelect as ldRazaoPesquisaSocioeconomicaFetchSelect } from './ld-razao-pesquisa-socioeconomica';

// ld-gastos-estimativa
import { LdGastosEstimativaListTypes } from '../ducks/ld-gastos-estimativa';
import { fetchSelect as ldGastosEstimativaFetchSelect } from './ld-gastos-estimativa';

// ld-orcamento-obra
import { LdOrcamentoObraListTypes } from '../ducks/ld-orcamento-obra';
import { fetchSelect as ldOrcamentoObraFetchSelect } from './ld-orcamento-obra';

// impacto-list
import { ImpactoListTypes } from '../ducks/impacto-list';
import {
  fetch as impactoListFetch,
  fetchSelect as impactoFetchSelect,
} from './impacto-list';

// finalidade-base-apoio-list
import { FinalidadeBaseApoioListTypes } from '../ducks/finalidade-base-apoio-list';
import {
  fetch as finalidadeBaseApoioListFetch,
  fetchSelect as finalidadeBaseApoioListFetchSelect,
} from './finalidade-base-apoio-list';

// finalidade-reserva-list
import { FinalidadeReservaListTypes } from '../ducks/finalidade-reserva-list';
import {
  fetch as finalidadeReservaListFetch,
  fetchSelect as finalidadeReservaListFetchSelect,
} from './finalidade-reserva-list';

// som-filmagem-list
import { SomFilmagemListTypes } from '../ducks/som-filmagem-list';
import {
  fetch as somFilmagemListFetch,
  fetchSelect as somFilmagemListFetchSelect,
} from './som-filmagem-list';

// efeito-especial-list
import { EfeitoEspecialListTypes } from '../ducks/efeito-especial-list';
import {
  fetch as efeitoEspecialListFetch,
  fetchSelect as efeitoEspecialListFetchSelect,
} from './efeito-especial-list';

// filmagem-publica-list
import { FilmagemPublicaListTypes } from '../ducks/filmagem-publica-list';
import {
  fetch as filmagemPublicaListFetch,
  fetchSelect as filmagemPublicaListFetchSelect,
} from './filmagem-publica-list';

// bloqueio-agua-list
import { BloqueioAguaListTypes } from '../ducks/bloqueio-agua-list';
import {
  fetch as bloqueioAguaListFetch,
  fetchSelect as bloqueioAguaListFetchSelect,
} from './bloqueio-agua-list';

// parque-list
import { ParqueListTypes } from '../ducks/parque-list';
import {
  fetch as parqueListFetch,
  fetchSelect as parqueListFetchSelect,
} from './parque-list';

// mercado-list
import { MercadoListTypes } from '../ducks/mercado-list';
import {
  fetch as mercadoListFetch,
  fetchSelect as mercadoListFetchSelect,
} from './mercado-list';

// tipo-filmagem-mercado-list
import { TipoFilmagemMercadoListTypes } from '../ducks/tipo-filmagem-mercado-list';
import {
  fetch as tipoFilmagemMercadoListFetch,
  fetchSelect as tipoFilmagemMercadoListFetchSelect,
} from './tipo-filmagem-mercado-list';

// equipamento-esportivo-list
import { EquipamentoEsportivoListTypes } from '../ducks/equipamento-esportivo-list';
import {
  fetch as equipamentoEsportivoListFetch,
  fetchSelect as equipamentoEsportivoListFetchSelect,
} from './equipamento-esportivo-list';

// Reminder
import reminder, { ReminderTypes } from '~/store/ducks/reminder';
import { create as reminderCreate, edit as reminderEdit } from './reminder';

// reminder-list
import { ReminderListTypes } from '../ducks/reminder-list';
import {
  fetch as reminderListFetch,
  fetchSelect as reminderListFetchSelect,
} from './reminder-list';

import { types as ParqueTypes } from '../parque/types';
import { fetch as ParqueFetch } from '../parque/sagas';

// logs
import { LogsTypes } from '~/store/ducks/logs';
import { fetch as fetchLogs } from './logs';

import { RolesTypes } from '~/store/ducks/userRoles';
import {
  fetch as fetchRoles,
  fetchListRoles as userFetchListRoles,
} from './userRoles';

import { UserAdminTypes } from '~/store/ducks/userAdmin';
import { fetchStore as usersAdminCreate } from './userAdmin';

// cemiterio-list
import { CemiterioListTypes } from '../ducks/cemiterio-list';
import {
  fetch as cemiterioListFetch,
  fetchSelect as cemiterioListFetchSelect,
} from './cemiterio-list';

export default function* rootSaga() {
  yield all([
    // register
    takeLatest(RegisterTypes.REGISTER_REQUEST, registerRequest),

    // auth
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logout),
    takeLatest(AuthTypes.REQUEST_TOKEN, refreshToken),

    // users-list
    takeLatest(UsersListTypes.REQUEST, usersListFetch),
    takeLatest(UsersListTypes.SHOW_REQUEST, usersListFetchSelect),
    takeLatest(UsersListTypes.USER_REQUEST, usersAdminMod),

    // create-password
    takeLatest(CreatePasswordTypes.CREATE_REQUEST, passwordRequest),

    // work
    takeLatest(WorkTypes.CREATE_REQUEST, workCreate),

    takeLatest(WorkTypes.SHOW_REQUEST, workShow),
    takeLatest(WorkTypes.EDIT_REQUEST, workEdit),
    takeLatest(WorkTypes.DELETE_REQUEST, workDelete),

    // work-list
    takeLatest(WorkListTypes.REQUEST, workListFetch),
    takeLatest(WorkListTypes.SHOW_REQUEST, workListFetchSelect),
    takeLatest(WorkListTypes.FILTER_REQUEST, workFetchFilter),

    // work-status-list
    takeLatest(WorkStatusListTypes.REQUEST, workStatusListFetch),
    takeLatest(WorkStatusListTypes.SHOW_REQUEST, workStatusListFetchSelect),

    // work-type-list
    takeLatest(WorkTypeListTypes.REQUEST, workTypeListFetch),
    takeLatest(WorkTypeListTypes.SHOW_REQUEST, workTypeListFetchSelect),

    // countries-list
    takeLatest(CountriesListTypes.REQUEST, countriesListFetch),
    takeLatest(CountriesListTypes.SHOW_REQUEST, countriesListFetchSelect),

    // states-list
    takeLatest(StatesListTypes.REQUEST, statesListFetch),
    takeLatest(StatesListTypes.SHOW_REQUEST, statesListFetchSelect),

    // cities-list
    takeLatest(CitiesListTypes.REQUEST, citiesListFetch),
    takeLatest(CitiesListTypes.SHOW_REQUEST, citiesListFetchSelect),

    // neighborhood
    takeLatest(NeighborhoodTypes.CREATE_REQUEST, neighborhoodCreate),
    takeLatest(NeighborhoodTypes.SHOW_REQUEST, neighborhoodShow),
    takeLatest(NeighborhoodTypes.EDIT_REQUEST, neighborhoodEdit),
    takeLatest(NeighborhoodTypes.DELETE_REQUEST, neighborhoodDelete),

    // neighborhood-list
    takeLatest(NeighborhoodListTypes.REQUEST, neighborhoodListFetch),
    takeLatest(NeighborhoodListTypes.SHOW_REQUEST, neighborhoodListFetchSelect),

    // local-gerador-list
    takeLatest(LocalGeradorListTypes.REQUEST, localGeradorListFetch),
    takeLatest(LocalGeradorListTypes.SHOW_REQUEST, localGeradorListFetchSelect),

    // pontos-fixos-terminais-list
    takeLatest(
      PontosFixosTerminaisListTypes.REQUEST,
      pontosFixosTerminaisListFetch,
    ),
    takeLatest(
      PontosFixosTerminaisListTypes.SHOW_REQUEST,
      pontosFixosTerminaisListFetchSelect,
    ),

    // trajeto-terminal-list
    takeLatest(TrajetoTerminalListTypes.REQUEST, trajetoTerminalListFetch),
    takeLatest(
      TrajetoTerminalListTypes.SHOW_REQUEST,
      trajetoTerminalListFetchSelect,
    ),

    // terminais-onibus-list
    takeLatest(TerminaisOnibusListTypes.REQUEST, terminaisOnibusListFetch),
    takeLatest(
      TerminaisOnibusListTypes.SHOW_REQUEST,
      terminaisOnibusListFetchSelect,
    ),

    // finalidade-acesso-list
    takeLatest(FinalidadeAcessoListTypes.REQUEST, finalidadeAcessoListFetch),
    takeLatest(
      FinalidadeAcessoListTypes.SHOW_REQUEST,
      finalidadeAcessoListFetchSelect,
    ),

    // ld-grua-opcoes
    takeLatest(LdGruaOpcoesListTypes.SHOW_REQUEST, ldGruaOpcoesListFetchSelect),

    // users-address
    takeLatest(UserAddressTypes.CREATE_REQUEST, userAddressCreate),
    takeLatest(UserAddressTypes.SHOW_REQUEST, userAddressShow),
    takeLatest(UserAddressTypes.EDIT_REQUEST, userAddressEdit),
    takeLatest(UserAddressTypes.DELETE_REQUEST, userAddressDelete),

    // users-personal-data
    takeLatest(UserPersonalDataTypes.CREATE_REQUEST, userPersonalDataCreate),
    takeLatest(UserPersonalDataTypes.SHOW_REQUEST, userPersonalDataShow),
    takeLatest(UserPersonalDataTypes.EDIT_REQUEST, userPersonalDataEdit),
    takeLatest(UserPersonalDataTypes.DELETE_REQUEST, userPersonalDataDelete),

    // user-me
    takeLatest(UserMeTypes.SHOW_REQUEST, usersShowFetch),

    // user-personal-data-list
    takeLatest(UserPersonalDataListTypes.REQUEST, userPersonalDataListFetch),
    takeLatest(
      UserPersonalDataListTypes.SHOW_REQUEST,
      userPersonalDataListFetchSelect,
    ),
    takeLatest(
      UserPersonalDataListTypes.LIST_REQUEST,
      userPersonalDataListFetchCompare,
    ),

    // branch-list
    takeLatest(BranchListTypes.REQUEST, branchListFetch),
    takeLatest(BranchListTypes.SHOW_REQUEST, branchListFetchSelect),

    // equipamento-filmagem-list
    takeLatest(
      EquipamentoFilmagemListTypes.REQUEST,
      equipamentosFilmagemListFetch,
    ),

    takeLatest(InstalacoesListTypes.SHOW_REQUEST, instalacoesFetchSelect),
    takeLatest(InstalacoesListTypes.SHOW_REQUEST, instalacoesFetchSelect),
    // praia-list
    takeLatest(PraiaListTypes.REQUEST, praiaListFetch),
    // ld galerias
    takeLatest(LdGaleriasListTypes.SHOW_REQUEST, ldGaleriasFetchSelect),
    // get by galerias
    takeLatest(GetByGaleriasListTypes.SHOW_REQUEST, getByGaleriasFetchSelect),
    // ld edificios pontos fixos
    takeLatest(LdEdificiosListTypes.SHOW_REQUEST, ldEdificiosFetchSelect),
    // ld educacao pontos fixos
    takeLatest(
      LdPontoFixoEducacaoListTypes.SHOW_REQUEST,
      ldPontoFixoEducacaoFetchSelect,
    ),
    // ld-tipo-camera-car
    takeLatest(LdCameraCarListTypes.SHOW_REQUEST, ldCameraCaretchSelect),
    // ld equipamentos smc
    takeLatest(
      LdEquipamentosSmcListTypes.SHOW_REQUEST,
      ldEquipamentosSmcFetchSelect,
    ),

    // instalacoes esportivas
    takeLatest(
      LdInstacoesEsportivasListTypes.SHOW_REQUEST,
      instacoesEsportivasFetchSelect,
    ),
    // ld tipo comboio
    takeLatest(LdTipoComboioListTypes.SHOW_REQUEST, ldTipoComboioFetchSelect),
    // instacoes
    // equipamento filmagem
    takeLatest(
      EquipamentoFilmagemListTypes.SHOW_REQUEST,
      equipamentosFilmagemFetchSelect,
    ),
    // praias
    takeLatest(PraiaListTypes.SHOW_REQUEST, praiaFetchSelect),
    // pavimentada
    takeLatest(PavimentacaoListTypes.SHOW_REQUEST, pavimentadaFetchSelect),

    // ld-biblioteca
    takeLatest(LdBibliotecaListTypes.SHOW_REQUEST, ldBibliotecaFetchSelect),

    // ld-museu
    takeLatest(LdMuseuListTypes.SHOW_REQUEST, ldMuseuFetchSelect),

    // ld-casas-cultura
    takeLatest(
      LdCasasCulturalListTypes.SHOW_REQUEST,
      ldCasasCulturaFetchSelect,
    ),

    // ld-centro-cultural
    takeLatest(
      LdCentroCulturalListTypes.SHOW_REQUEST,
      ldCentroCulturalFetchSelect,
    ),

    // impacto-list
    takeLatest(ImpactoListTypes.REQUEST, impactoListFetch),
    takeLatest(ImpactoListTypes.SHOW_REQUEST, impactoFetchSelect),

    // ld-projeto-pesquisa-economica
    takeLatest(
      LdProjetoPesquisaEconomicaListTypes.SHOW_REQUEST,
      ldProjetoPesquisaEconomicaFetchSelect,
    ),

    // ld-razao-pesquisa-economica
    takeLatest(
      LdRazaoPesquisaSocioeconomicaListTypes.SHOW_REQUEST,
      ldRazaoPesquisaSocioeconomicaFetchSelect,
    ),

    // ld-orcamento-obra
    takeLatest(
      LdOrcamentoObraListTypes.SHOW_REQUEST,
      ldOrcamentoObraFetchSelect,
    ),

    // ld-gastos-estimativa
    takeLatest(
      LdGastosEstimativaListTypes.SHOW_REQUEST,
      ldGastosEstimativaFetchSelect,
    ),

    // finalidade-base-apoio-list
    takeLatest(
      FinalidadeBaseApoioListTypes.REQUEST,
      finalidadeBaseApoioListFetch,
    ),
    takeLatest(
      FinalidadeBaseApoioListTypes.SHOW_REQUEST,
      finalidadeBaseApoioListFetchSelect,
    ),

    // finalidade-reserva-list
    takeLatest(FinalidadeReservaListTypes.REQUEST, finalidadeReservaListFetch),
    takeLatest(
      FinalidadeReservaListTypes.SHOW_REQUEST,
      finalidadeReservaListFetchSelect,
    ),

    // som-filmagem-list
    takeLatest(SomFilmagemListTypes.REQUEST, somFilmagemListFetch),
    takeLatest(SomFilmagemListTypes.SHOW_REQUEST, somFilmagemListFetchSelect),

    // efeito-especial-list
    takeLatest(EfeitoEspecialListTypes.REQUEST, efeitoEspecialListFetch),
    takeLatest(
      EfeitoEspecialListTypes.SHOW_REQUEST,
      efeitoEspecialListFetchSelect,
    ),
    // filmagem-publica-list
    takeLatest(FilmagemPublicaListTypes.REQUEST, filmagemPublicaListFetch),
    takeLatest(
      FilmagemPublicaListTypes.SHOW_REQUEST,
      filmagemPublicaListFetchSelect,
    ),

    // bloqueio-agua-list
    takeLatest(BloqueioAguaListTypes.REQUEST, bloqueioAguaListFetch),
    takeLatest(BloqueioAguaListTypes.SHOW_REQUEST, bloqueioAguaListFetchSelect),

    // parque-list
    takeLatest(ParqueListTypes.REQUEST, parqueListFetch),
    takeLatest(ParqueListTypes.SHOW_REQUEST, parqueListFetchSelect),

    // reminder-list
    takeLatest(ReminderListTypes.REQUEST, reminderListFetch),
    takeLatest(ReminderListTypes.SHOW_REQUEST, reminderListFetchSelect),

    // equipamento-esportivo-list
    takeLatest(
      EquipamentoEsportivoListTypes.REQUEST,
      equipamentoEsportivoListFetch,
    ),
    takeLatest(
      EquipamentoEsportivoListTypes.SHOW_REQUEST,
      equipamentoEsportivoListFetchSelect,
    ),

    // mercado-list
    takeLatest(MercadoListTypes.REQUEST, mercadoListFetch),
    takeLatest(MercadoListTypes.SHOW_REQUEST, mercadoListFetchSelect),

    // tipo-filmagem-mercado-list
    takeLatest(
      TipoFilmagemMercadoListTypes.REQUEST,
      tipoFilmagemMercadoListFetch,
    ),
    takeLatest(
      TipoFilmagemMercadoListTypes.SHOW_REQUEST,
      tipoFilmagemMercadoListFetchSelect,
    ),

    // complemento-numero-list
    takeLatest(ComplementoNumeroListTypes.REQUEST, complementoNumeroListFetch),
    takeLatest(
      ComplementoNumeroListTypes.SHOW_REQUEST,
      complementoNumeroListFetchSelect,
    ),

    // takeLatest(ChildToParentTypes.SUCCESS, childToParentFetch),

    // reminder
    takeLatest(ReminderTypes.CREATE_REQUEST, reminderCreate),
    takeLatest(ReminderTypes.EDIT_REQUEST, reminderEdit),
    // takeLatest(ReminderTypes.RESET, reminderReset),

    takeLatest(ParqueTypes.REQUEST, ParqueFetch),
    takeLatest(LogsTypes.REQUEST, fetchLogs),

    //  roles
    takeLatest(RolesTypes.REQUEST, fetchRoles),
    takeLatest(RolesTypes.ROLE_REQUEST, userFetchListRoles),

    // users admins
    takeLatest(UserAdminTypes.CREATE_REQUEST, usersAdminCreate),

    // cemiterio-list
    takeLatest(CemiterioListTypes.REQUEST, cemiterioListFetch),
    takeLatest(CemiterioListTypes.SHOW_REQUEST, cemiterioListFetchSelect),
  ]);
}
