import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { format } from 'date-fns';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineCheck,
  AiOutlineClose,
} from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import omitEmpty from 'omit-empty';
import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  Textarea,
  ChoiceButtonLocacao,
  File,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ActionInputMulti,
  MultiInputContainer,
  ConditionalContainer,
  LimiterInputWidth,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import EfeitoEspecialListActions from '~/store/ducks/efeito-especial-list';
import SomFilmagemListActions from '~/store/ducks/som-filmagem-list';
import EquipamentoFilmagemListActions from '~/store/ducks/equipamento-filmagem-list';
import ImpactoListActions from '~/store/ducks/impacto-list';
import FinalidadeBaseListActions from '~/store/ducks/finalidade-base-apoio-list';

import { showModalMessage } from '~/services/notification';

import Button from '~/components/Button';
import Chat from '~/components/Chat/chat';
import { useList } from '~/hooks/useFunction';
import diffDatasFilmagem from '~/utils/functions/diffDatasFilmagem';
import diffHoursFilmagem from '~/utils/functions/diffHoursFilmagem';
import LogoIcon from '~/components/LogoIcon';
import {
  confirmEditDateHour,
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function EspacosSmadEdit() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef(null);
  const croquiFileRef = useRef(null);
  const relatorioArteRef = useRef(null);
  const conteudoProjecaoRef = useRef(null);
  const iluminacaoFileRef = useRef(null);

  // states
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseLocation, setResponseLocation] = useState();
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});

  // state choices
  const [localFilmagem, setLocalFilmagem] = useState(null);
  const [isolarLocal, setIsolarLocal] = useState(null);
  const [intervencaoArte, setIntervencaoArte] = useState(null);
  const [gerador, setGerador] = useState(null);
  const [blimpado, setBlimpado] = useState(null);
  const [geradorPortatil, setGeradorPortatil] = useState(null);
  const [baseLocal, setBaseLocal] = useState(null);
  const [captacaoSomDireto, setCaptacaoSomDireto] = useState(null);
  const [somFilmagem, setSomFilmagem] = useState(null);
  const [drone, setDrone] = useState(null);
  const [efeitosEspeciais, setEfeitosEspeciais] = useState(null);
  const [haveraAnimais, setHaveraAnimais] = useState(null);
  const [projecao, setProjecao] = useState(null);
  const [projecaoLogo, setProjecaoLogo] = useState(null);
  const [desligarLuz, setDesligarLuz] = useState(null);
  const [publicoPrivado, setPublicoPrivado] = useState(null);
  const [reservasVagas, setReservasVagas] = useState(null);

  // states multi input
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // state files
  const [croquiFile, setCroquiFile] = useState(null);
  const [dataCroquiFile, setDataCroquiFile] = useState(null);
  const [relatorioArteFile, setRelatorioArteFile] = useState(null);
  const [dataRelatorioArteFile, setDataRelatorioArteFile] = useState(null);
  const [iluminacaoFile, setIluminacaoFile] = useState(null);
  const [dataIluminacaoFile, setDataIluminacaoFile] = useState(null);
  const [conteudoProjecaoFile, setConteudoProjecaoFile] = useState(null);
  const [dataConteudoProjecaoFile, setDataConteudoProjecaoFile] =
    useState(null);

  // state outro fields
  const [showOutroTipoEspacoField, setShowOutroTipoEspacoField] =
    useState(false);
  const [showOutroEquipamentoField, setShowOutroEquipamentoField] =
    useState(false);
  const [showOutroSomFilmagemField, setShowOutroSomFilmagemField] =
    useState(false);
  const [showOutroFinalidadeField, setShowOutroFinalidadeField] =
    useState(false);
  const [showDescrAltoImpactoLocalField, setShowDescrAltoImpactoLocalField] =
    useState(false);
  const [
    showDescrEquipamentoFilmagemField,
    setShowDescrEquipamentoFilmagemField,
  ] = useState(false);

  // selectors
  const { data: equipamentosSelectList } = useSelector(
    state => state.equipamentoFilmagemList,
  );
  const { data: somFilmagemSelectList } = useSelector(
    state => state.somFilmagemList,
  );
  const { data: efeitoEspecialSelectList } = useSelector(
    state => state.efeitoEspecialList,
  );
  const { data: impactoSelectList } = useSelector(state => state.impactoList);

  const { data: finalidadeSelectList } = useSelector(
    state => state.finalidadeBaseApoioList,
  );

  // lists
  const tipoEspacoList = useList({
    url: '/ld-tipo-espaco-smad?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  // Busca dados da locação
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-espacos-smad/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set temp variables
      const isolarLocalTmp =
        typeof respLoc?.locacao_default.fluxo_area === 'string';

      const blimpadoTmp =
        respLoc?.locacao_default.blimpado === null
          ? null
          : respLoc?.locacao_default.blimpado === 1;

      const geradorPortatilTmp =
        respLoc?.locacao_default.gerador_portatil === null
          ? null
          : respLoc?.locacao_default.gerador_portatil === 1;

      const projecaoLogoTmp =
        typeof respLoc?.locacao_default.porcentagem_projecao === 'number' ||
        typeof respLoc?.locacao_default.area_tempo_projeção === 'string';

      const publicoPrivadoTmp =
        respLoc?.locacao_default?.imovel_publico_privado === null
          ? null
          : respLoc?.locacao_default?.imovel_publico_privado === 1;

      const fileCroqui = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Croqui',
      );
      const fileArte = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Relatório de Arte',
      );
      const fileProjecao = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Projeção',
      );
      const fileLuz = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Iluminação',
      );

      // set campos 'multi'
      setDatasFilmagemInputs(
        respLoc?.locacao_default?.dates_locacao_default?.map(
          ({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      setHorasFilmagemInputs(
        respLoc?.locacao_default?.hours_locacao_default?.map(
          ({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      // set choices buttons
      setLocalFilmagem(
        typeof respLoc?.descricao_espaco === 'string',
        isolarLocalTmp || fileCroqui?.length > 0,
      );
      setIsolarLocal(isolarLocalTmp);
      setIntervencaoArte(
        typeof respLoc?.locacao_default.desc_intervencao_arte === 'string' ||
          fileArte?.length > 0,
      );
      setBlimpado(blimpadoTmp);
      setGeradorPortatil(geradorPortatilTmp);
      setGerador(blimpadoTmp !== null || geradorPortatilTmp !== null);
      setBaseLocal(
        Object.values(respLoc?.locacao_default.ld_finalidade_base_apoio || [])
          .length > 0 ||
          typeof respLoc?.locacao_default.local_base_apoio === 'string' ||
          typeof respLoc?.locacao_default.dimensao_base_apoio === 'string' ||
          typeof respLoc?.locacao_default.quantidade_barracas === 'string',
      );
      setCaptacaoSomDireto(respLoc?.locacao_default.captação_som_direto === 1);
      setSomFilmagem(
        Object.values(respLoc?.locacao_default.ld_som_filmagem || []).length >
          0,
      );
      setDrone(respLoc?.locacao_default.drone === 1);
      setEfeitosEspeciais(
        Object.values(respLoc?.locacao_default.ld_efeitos_especiais || {})
          .length > 0,
      );
      setHaveraAnimais(
        typeof respLoc?.locacao_default.animais_em_cena === 'string',
      );
      setProjecao(
        fileProjecao?.length > 0 ||
          typeof respLoc?.locacao_default.estrutura_projecao === 'string' ||
          typeof respLoc?.locacao_default.instalacao_estrutura_projecao ===
            'string' ||
          publicoPrivadoTmp ||
          projecaoLogoTmp,
      );
      setPublicoPrivado(publicoPrivadoTmp);
      setProjecaoLogo(projecaoLogoTmp);
      setDesligarLuz(fileLuz?.length > 0);
      setReservasVagas(
        Object.values(respLoc?.locacao_via_externa || []).length > 0,
      );

      // set campos 'Outro'
      setShowOutroTipoEspacoField(
        typeof respLoc?.outro_tipo_espaco === 'string',
      );
      setShowOutroEquipamentoField(
        respLoc?.locacao_default?.ld_equipamento_filmagem.find(
          equip => equip.name === 'Outro',
        ),
      );
      setShowOutroFinalidadeField(
        respLoc?.locacao_default?.ld_finalidade_base_apoio.find(
          equip => equip.name === 'outro, descreva',
        ),
      );
      setShowOutroSomFilmagemField(
        respLoc?.locacao_default?.ld_som_filmagem.find(
          equip => equip.name === 'outro',
        ),
      );
      setShowDescrAltoImpactoLocalField(
        typeof respLoc?.locacao_default?.descr_alto_impacto_local === 'string',
      );
      setShowDescrEquipamentoFilmagemField(
        typeof respLoc?.locacao_default?.descricoes_equipamento_filmagem ===
          'string',
      );

      // set select default values
      formRef.current?.setFieldValue('ld_impacto_uuid', {
        label: respLoc?.locacao_default?.ld_impacto?.name,
        value: respLoc?.locacao_default?.ld_impacto?.uuid,
      });

      formRef.current?.setFieldValue('ld_tipo_espaco_smad_uuid', {
        label: respLoc?.ld_tipo_espaco_smad?.name,
        value: respLoc?.ld_tipo_espaco_smad?.uuid,
      });

      formRef.current?.setFieldValue(
        'ld_equipamento_filmagem',
        respLoc?.locacao_default?.ld_equipamento_filmagem.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'ld_finalidade_base_apoio_uuid',
        respLoc?.locacao_default?.ld_finalidade_base_apoio?.map(lfba => {
          return {
            label: lfba.name,
            value: lfba.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'ld_som_filmagem',
        respLoc?.locacao_default?.ld_som_filmagem?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_efeitos_especiais_uuid', {
        label: respLoc?.locacao_default?.ld_efeitos_especiais?.name,
        value: respLoc?.locacao_default?.ld_efeitos_especiais?.uuid,
      });

      setResponseLocation(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState]);

  // effects
  useEffect(() => {
    dispatch(ImpactoListActions.showRequest());
    dispatch(EquipamentoFilmagemListActions.showRequest());
    dispatch(SomFilmagemListActions.showRequest());
    dispatch(EfeitoEspecialListActions.showRequest());
    dispatch(FinalidadeBaseListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  // Verification files
  let fileCroqui = [];
  let fileArte = [];
  let fileProjecao = [];
  let fileLuz = [];

  // Filter files
  fileCroqui = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Arquivo Croqui',
  );
  fileArte = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Relatório de Arte',
  );
  fileProjecao = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Arquivo Projeção',
  );
  fileLuz = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Arquivo Iluminação',
  );

  // handles files
  function handleCroquiFile() {
    return croquiFileRef.current.click();
  }

  function handleRelatorioArteFile() {
    return relatorioArteRef.current.click();
  }

  function handleConteudoProjecaoFile() {
    return conteudoProjecaoRef.current.click();
  }

  function handleIluminacaoFile() {
    return iluminacaoFileRef.current.click();
  }

  // handles multi select
  function handleMultiSelectEquipamentos(e) {
    setShowOutroEquipamentoField(!!e?.find(x => x.label === 'Outro'));
    setShowDescrEquipamentoFilmagemField(
      e?.some(equip => equip.label?.includes(':')) || false,
    );

    setFormData(state => ({
      ...state,
      ld_equipamento_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectSomFilmagem(e) {
    setShowOutroSomFilmagemField(!!e?.find(x => x.label === 'outro'));
    setFormData(state => ({
      ...state,
      ld_som_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectFinalidades(e) {
    setShowOutroFinalidadeField(!!e?.find(x => x.label === 'outro, descreva'));

    setFormData(state => ({
      ...state,
      ld_finalidade_base_apoio: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  // handles true/false
  function handleProjecaoLogoTrue(e) {
    e.preventDefault();
    setProjecaoLogo(true);
    setErrorsForm(state => ({
      ...state,
      projecaoLogo: false,
    }));
  }

  function handleProjecaoLogoFalse(e) {
    e.preventDefault();
    setProjecaoLogo(false);
    setErrorsForm(state => ({
      ...state,
      projecaoLogo: false,
    }));
  }

  function handleDesligarLuzTrue(e) {
    e.preventDefault(e);
    setDesligarLuz(true);
    setErrorsForm(state => ({
      ...state,
      desligarLuz: false,
    }));
  }

  function handleDesligarLuzFalse(e) {
    e.preventDefault(e);
    setDesligarLuz(false);
    setErrorsForm(state => ({
      ...state,
      desligarLuz: false,
    }));
  }

  function handleReservasVagasTrue(e) {
    e.preventDefault(e);
    setReservasVagas(true);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleReservasVagasFalse(e) {
    e.preventDefault(e);
    setReservasVagas(false);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleHaveraAnimaisTrue(e) {
    e.preventDefault(e);
    setHaveraAnimais(true);
    setErrorsForm(state => ({
      ...state,
      haveraAnimais: false,
    }));
  }

  function handleHaveraAnimaisFalse(e) {
    e.preventDefault(e);
    setHaveraAnimais(false);
    setErrorsForm(state => ({
      ...state,
      haveraAnimais: false,
    }));
  }

  function handlePublicoPrivadoTrue(e) {
    e.preventDefault();
    setPublicoPrivado(true);
    setErrorsForm(state => ({
      ...state,
      publicoPrivado: false,
    }));
  }

  function handlePublicoPrivadoFalse(e) {
    e.preventDefault();
    setPublicoPrivado(false);
    setErrorsForm(state => ({
      ...state,
      publicoPrivado: false,
    }));
  }

  function handleDroneTue(e) {
    e.preventDefault();
    setDrone(true);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleDroneFalse(e) {
    e.preventDefault();
    setDrone(false);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleGeradorPortatilTrue(e) {
    e.preventDefault();
    setGeradorPortatil(true);
    setErrorsForm(state => ({
      ...state,
      geradorPortatil: false,
    }));
  }

  function handleGeradorPortatilFalse(e) {
    e.preventDefault();
    setGeradorPortatil(false);
    setErrorsForm(state => ({
      ...state,
      geradorPortatil: false,
    }));
  }

  function handleCaptacaoSomDiretoTrue(e) {
    e.preventDefault();
    setCaptacaoSomDireto(true);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleCaptacaoSomDiretoFalse(e) {
    e.preventDefault();
    setCaptacaoSomDireto(false);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleLocalFilmagemTrue(e) {
    e.preventDefault();
    setLocalFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleLocalFilmagemFalse(e) {
    e.preventDefault();
    setLocalFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleIsolarLocalTrue(e) {
    e.preventDefault();
    setIsolarLocal(true);
    setErrorsForm(state => ({
      ...state,
      isolarLocal: false,
    }));
  }

  function handleIsolarLocalFalse(e) {
    e.preventDefault();
    setIsolarLocal(false);
    setErrorsForm(state => ({
      ...state,
      isolarLocal: false,
    }));
  }

  function handleIntervencaoArteTrue(e) {
    e.preventDefault();
    setIntervencaoArte(true);
    setErrorsForm(state => ({
      ...state,
      intervencaoArte: false,
    }));
  }

  function handleIntervencaoArteFalse(e) {
    e.preventDefault();
    setIntervencaoArte(false);
    setErrorsForm(state => ({
      ...state,
      intervencaoArte: false,
    }));
  }

  function handleGeradorTrue(e) {
    e.preventDefault();
    setGerador(true);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorFalse(e) {
    e.preventDefault();
    setGerador(false);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleBlimpadorTrue(e) {
    e.preventDefault();
    setBlimpado(true);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBlimpadorFalse(e) {
    e.preventDefault();
    setBlimpado(false);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBaseLocalTrue(e) {
    e.preventDefault();
    setBaseLocal(true);
    setErrorsForm(state => ({
      ...state,
      baseLocal: false,
    }));
  }

  function handleBaseLocalFalse(e) {
    e.preventDefault();
    setBaseLocal(false);
    setErrorsForm(state => ({
      ...state,
      baseLocal: false,
    }));
  }

  function handleSomFilmagemTrue(e) {
    e.preventDefault(e);
    setSomFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleSomFilmagemFalse(e) {
    e.preventDefault(e);
    setSomFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleEfeitosEspeciaisTrue(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(true);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleEfeitosEspeciaisFalse(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(false);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleProjecaoTrue(e) {
    e.preventDefault();
    setProjecao(true);
    setErrorsForm(state => ({
      ...state,
      projecao: false,
    }));
  }

  function handleProjecaoFalse(e) {
    e.preventDefault();
    setProjecao(false);
    setErrorsForm(state => ({
      ...state,
      projecao: false,
    }));
  }

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;

    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    if (name === 'croqui_file') {
      setCroquiFile(base64.split('base64,'));
      setDataCroquiFile(file);
      setErrorsForm(state => ({
        ...state,
        croquiFile: false,
      }));
    }

    if (name === 'relatorio_arte_file') {
      setRelatorioArteFile(base64.split('base64,'));
      setDataRelatorioArteFile(file);
      setErrorsForm(state => ({
        ...state,
        relatorioArteFile: false,
      }));
    }

    if (name === 'conteudo_projecao_file') {
      setConteudoProjecaoFile(base64.split('base64,'));
      setDataConteudoProjecaoFile(file);
      setErrorsForm(state => ({
        ...state,
        conteudoProjecaoFile: false,
      }));
    }

    if (name === 'iluminacao_file') {
      setIluminacaoFile(base64.split('base64,'));
      setDataIluminacaoFile(file);
      setErrorsForm(state => ({
        ...state,
        iluminacaoFile: false,
      }));
    }

    setFormData(state => ({ ...state, [name]: base64.split('base64,')[1] }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function formatDefaultData(data) {
    // files uuids
    const fileCroquiUuid = fileCroqui?.length > 0 ? fileCroqui[0].uuid : null;
    const fileArteUuid = fileArte?.length > 0 ? fileArte[0].uuid : null;
    const fileProjecaoUuid =
      fileProjecao?.length > 0 ? fileProjecao[0].uuid : null;
    const fileLuzUuid = fileLuz?.length > 0 ? fileLuz[0].uuid : null;

    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      ld_impacto_uuid: data?.ld_impacto_uuid,
      tamanho_equipe_tecnica: data?.tamanho_equipe_tecnica,
      tamanho_do_elenco: data?.tamanho_do_elenco,
      descricao_cena: data?.descricao_cena,
      ld_equipamento_filmagem: data?.ld_equipamento_filmagem,
      especificidades: data?.especificidades,
    });

    // dados de choices
    const dataLocalFilmagem = localFilmagem
      ? {
          croqui_base64: data?.croqui_file ? data?.croqui_file : null,
          croqui_base64_uuid: data?.croqui_file ? fileCroquiUuid : null,
        }
      : { croqui_base64: null, croqui_base64_uuid: fileCroquiUuid };

    const dataIsolarLocal = {
      fluxo_area: localFilmagem && isolarLocal ? data?.fluxo_area : null,
    };

    const dataIntervencaoArte = intervencaoArte
      ? {
          desc_intervencao_arte: data?.desc_intervencao_arte,
          relat_arte_base64: data?.relatorio_arte_file
            ? data?.relatorio_arte_file
            : null,
          relat_arte_base64_uuid: data?.relatorio_arte_file
            ? fileArteUuid
            : null,
        }
      : {
          desc_intervencao_arte: null,
          relat_arte_base64: null,
          relat_arte_base64_uuid: fileArteUuid,
        };

    const dataGerador = gerador
      ? { blimpado, gerador_portatil: geradorPortatil }
      : { blimpado: null, gerador_portatil: null };

    const dataBaseLocal = baseLocal
      ? {
          ld_finalidade_base_apoio: data?.ld_finalidade_base_apoio,
          local_base_apoio: data?.local_base_apoio,
          dimensao_base_apoio: data?.dimensao_base_apoio,
          quantidade_barracas: data?.quantidade_barracas,
        }
      : {
          ld_finalidade_base_apoio: null,
          local_base_apoio: null,
          dimensao_base_apoio: null,
          quantidade_barracas: null,
        };

    const dataCaptacaoSomDireto = { captação_som_direto: captacaoSomDireto };

    const dataSomFilmagem = {
      ld_som_filmagem: somFilmagem ? data?.ld_som_filmagem : null,
    };

    const dataDrone = { drone };

    const dataEfeitosEspeciais = {
      ld_efeitos_especiais_uuid: efeitosEspeciais
        ? data?.ld_efeitos_especiais_uuid
        : null,
    };

    const dataHaveraAnimais = {
      animais_em_cena: haveraAnimais ? data?.descricao_animais : null,
    };

    const dataProjecao = projecao
      ? {
          estrutura_projecao: data?.estrutura_projecao,
          instalacao_estrutura_projecao: data?.instalacao_estrutura_projecao,
          imovel_publico_privado: publicoPrivado,
          projecao_base64: data?.conteudo_projecao_file
            ? data?.conteudo_projecao_file
            : null,
          projecao_base64_uuid: data?.conteudo_projecao_file
            ? fileProjecaoUuid
            : null,
        }
      : {
          estrutura_projecao: null,
          instalacao_estrutura_projecao: null,
          imovel_publico_privado: null,
          projecao_base64: null,
          projecao_base64_uuid: fileProjecaoUuid,
        };

    const dataProjecaoLogo =
      projecao && projecaoLogo
        ? {
            porcentagem_projecao: +data?.porcentagem_projecao || undefined,
            area_tempo_projeção: data?.area_tempo_projeção,
          }
        : { porcentagem_projecao: null, area_tempo_projeção: null };

    const dataIluminacao = desligarLuz
      ? {
          iluminacao_base64: data?.iluminacao_file
            ? data?.iluminacao_file
            : null,
          iluminacao_base64_uuid: data?.iluminacao_file ? fileLuzUuid : null,
        }
      : { iluminacao_base64: null, iluminacao_base64_uuid: fileLuzUuid };

    // dados de campos exibidos condicionalmente
    const dataDescrAltoImpactoLocal = {
      descr_alto_impacto_local: showDescrAltoImpactoLocalField
        ? data?.descr_alto_impacto_local
        : null,
    };

    const dataOutroEquipamento = {
      outro_equipamento: showOutroEquipamentoField
        ? data?.outro_equipamento
        : null,
    };

    const dataOutroFinalidade = {
      outro_finalidade:
        baseLocal && showOutroFinalidadeField ? data?.outro_finalidade : null,
    };

    const dataOutroSomFilmagem = {
      outro_som_filmagem:
        somFilmagem && showOutroSomFilmagemField
          ? data?.outro_som_filmagem
          : null,
    };

    const dataDescrEquipamentoFilmagem = {
      descricoes_equipamento_filmagem: showDescrEquipamentoFilmagemField
        ? data?.descricoes_equipamento_filmagem
        : null,
    };

    // diff dates e hours
    const diffDatas = diffDatasFilmagem({
      datasFilmagemLocacao:
        responseLocation.locacao_default.dates_locacao_default.map(
          ({ uuid, date, changes_to_approve }) =>
            omitEmpty({
              uuid,
              data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
            }),
        ),
      datasFilmagemInput: datasFilmagemInputs?.map(
        ({ uuid, data_filmagem, changes_to_approve }) =>
          omitEmpty({
            uuid,
            data_filmagem,
            changes_to_approve,
          }),
      ),
      dateKeyName: 'data_filmagem',
    });

    const diffHours = diffHoursFilmagem({
      hoursFilmagemLocacao:
        responseLocation?.locacao_default?.hours_locacao_default?.map(
          ({ uuid, inicio, fim, changes_to_approve }) =>
            omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
            }),
        ),
      hoursFilmagemInput: horasFilmagemInputs?.map(
        ({ uuid, inicio, fim, changes_to_approve }) =>
          omitEmpty({
            uuid,
            inicio,
            fim,
            changes_to_approve,
          }),
      ),
      hourBeginKeyName: 'inicio',
      hourEndKeyName: 'fim',
    });

    return Object.assign(
      dataNaoCondicional,
      { dates: diffDatas },
      { hours: diffHours },
      dataLocalFilmagem,
      dataIsolarLocal,
      dataIntervencaoArte,
      dataGerador,
      dataBaseLocal,
      dataCaptacaoSomDireto,
      dataSomFilmagem,
      dataDrone,
      dataEfeitosEspeciais,
      dataHaveraAnimais,
      dataProjecao,
      dataProjecaoLogo,
      dataIluminacao,
      dataDescrAltoImpactoLocal,
      dataDescrEquipamentoFilmagem,
      dataOutroEquipamento,
      dataOutroFinalidade,
      dataOutroSomFilmagem,
    );
  }

  function formatEspecificaData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      ld_tipo_espaco_smad_uuid: data?.ld_tipo_espaco_smad_uuid,
      address_unity: data?.address_unity,
      address: data?.address,
    });

    // dados de choices
    const dataDescricaoEspaco = {
      descricao_espaco: localFilmagem ? data?.descricao_espaco : null,
    };

    // dados de campos exibidos condicionalmente
    const dataOutroEquipamento = {
      outro_tipo_espaco: showOutroTipoEspacoField
        ? data?.outro_tipo_espaco
        : null,
    };

    return Object.assign(
      dataNaoCondicional,
      dataDescricaoEspaco,
      dataOutroEquipamento,
    );
  }

  async function handleValidationFields(data) {
    try {
      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: err.message,
      });

      return false;
    }

    return true;
  }

  async function handleSubmit(data) {
    // files
    data.croqui_file = formData?.croqui_file;
    data.relatorio_arte_file = formData?.relatorio_arte_file;
    data.iluminacao_file = formData?.iluminacao_file;
    data.conteudo_projecao_file = formData?.conteudo_projecao_file;

    // selects
    data.ld_equipamento_filmagem = formData?.ld_equipamento_filmagem;
    data.ld_finalidade_base_apoio = formData?.ld_finalidade_base_apoio;
    data.ld_som_filmagem = formData?.ld_som_filmagem;

    try {
      if (!(await handleValidationFields(data))) {
        return;
      }

      const defaultData = formatDefaultData(data);
      const especificaData = formatEspecificaData(data);

      setLoading(true);

      if (isAdminRequest) {
        await ApiClient.doRequest({
          uri: `locacao-default/${responseLocation?.locacao_default?.uuid}`,
          method: ApiClient.PATCH,
          body: defaultData,
          expectedStatus: 201,
        });

        await ApiClient.doRequest({
          uri: `locacao-espacos-smad/${responseLocation?.uuid}`,
          method: ApiClient.PATCH,
          body: especificaData,
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title:
            'Filmagem em Espaços da Secretaria de Assistência e Desenvolvimento Social',
          text: 'Alteração enviada com sucesso',
        });

        history.push('/locacoes');
      } else {
        if (!defaultData?.dates && !defaultData?.hours) {
          setLoading(false);
          await showModalMessage({
            type: 'info',
            title:
              'Filmagem em Espaços da Secretaria de Assistência e Desenvolvimento Social',
            text: 'Não houve alteração em Data/Hora a ser salva',
          });
          return;
        }

        await ApiClient.doRequest({
          uri: `locacao-default/common-user/${responseLocation?.locacao_default?.uuid}`,
          method: ApiClient.PATCH,
          body: {
            dates: defaultData?.dates,
            hours: defaultData?.hours,
          },
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title:
            'Filmagem em Espaços da Secretaria de Assistência e Desenvolvimento Social',
          text: 'As alterações foram enviadas para avaliação da SPFilm',
        });

        history.push('/home-user');
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title:
          'Filmagem em Espaços da Secretaria de Assistência e Desenvolvimento Social',
        text: err.message,
      });

      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function renderLocacao() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={!isAdminRequest}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_impacto_uuid"
                      label="Você classifica sua filmagem como sendo:"
                      placeholder="Selecione"
                      options={impactoSelectList}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange('ld_impacto_uuid', value);
                        setShowDescrAltoImpactoLocalField(
                          value.label === 'alto impacto no local',
                        );
                      }}
                      required
                      questionTooltip="Clique aqui para saber mais"
                      questionOnClick={() => setOpenModalPorteFilmagem(true)}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>

                  <LimiterInputWidth w50>
                    <Select
                      name="ld_tipo_espaco_smad_uuid"
                      label="Tipo de Espaço"
                      placeholder="Selecione"
                      options={tipoEspacoList}
                      style={selectCustomStyles}
                      required
                      onChange={value => {
                        handleSelectChange('ld_tipo_espaco_smad_uuid', value);
                        setShowOutroTipoEspacoField(value.label === 'Outro');
                      }}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={showOutroTipoEspacoField}>
                  <Wrap>
                    <Input
                      type="text"
                      name="outro_tipo_espaco"
                      label="Outro Tipo Espaço"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.outro_tipo_espaco
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <ConditionalContainer show={showDescrAltoImpactoLocalField}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descr_alto_impacto_local"
                      label="Comente qual o desafio da sua produção para auxiliarmos em sua viabilização"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descr_alto_impacto_local
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="address_unity"
                    label="Caso haja mais de uma unidade desse espaço, especificar qual (Ex: CRAS Casa Verde, Centro
                      de Referência Especializado para População em Situação de Rua “Centro Pop” – Bela Vista)"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData && responseLocation?.address_unity
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="text"
                    name="address"
                    label="Endereço do local"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData && responseLocation?.address
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap>
                  <DateFormMultiInput
                    label="Data da Filmagem"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    dateKeyName="data_filmagem"
                    tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                    isAdminRequest={isAdminRequest}
                    acceptPast
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Hora da Filmagem (Início - Fim)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="Incluir horário de montagem/desmontagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="number"
                    name="tamanho_equipe_tecnica"
                    label="Tamanho da Equipe Técnica"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.tamanho_equipe_tecnica
                    }
                    disabled={!isAdminRequest}
                  />
                  <Input
                    type="number"
                    name="tamanho_do_elenco"
                    label="Tamanho do elenco"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.tamanho_do_elenco
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>
                <Wrap>
                  <Textarea
                    type="text"
                    name="descricao_cena"
                    label="Descrição da Cena"
                    onChange={handleChange}
                    required
                    rows="3"
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.descricao_cena
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Já possui local definido para filmagem?"
                      onClickTrue={handleLocalFilmagemTrue}
                      onClickFalse={handleLocalFilmagemFalse}
                      required
                      selected={localFilmagem}
                      error={errorsForm.localFilmagem === true && 'Obrigatório'}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={localFilmagem}>
                  <Wrap>
                    <Textarea
                      type="text"
                      rows={3}
                      name="descricao_espaco"
                      label="Descreva o espaço"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.descricao_espaco
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>

                  <Wrap>
                    <File
                      label="Croqui demarcando a Ocupação pretendida do Local:"
                      attach={dataCroquiFile}
                      textFile="Nenhum arquivo selecionado"
                      name="croqui_file"
                      required
                      inputRef={croquiFileRef}
                      onChange={handleFileChange}
                      onClick={handleCroquiFile}
                      error={errorsForm.croquiFile === true && 'Obrigatório'}
                      link={fileCroqui?.length > 0 && fileCroqui[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </Wrap>

                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Você precisa isolar alguma área e/ou interferir no fluxo dos frequentadores?"
                        onClickTrue={handleIsolarLocalTrue}
                        onClickFalse={handleIsolarLocalFalse}
                        selected={isolarLocal}
                        required
                        error={
                          errorsForm?.isolarLocal === true && 'Obrigatório'
                        }
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  <ConditionalContainer show={isolarLocal}>
                    <Wrap>
                      <Textarea
                        type="text"
                        name="fluxo_area"
                        label="Descrição do local"
                        onChange={handleChange}
                        rows="3"
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.descricao_cena
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá alguma intervenção de arte no local?"
                      onClickTrue={handleIntervencaoArteTrue}
                      onClickFalse={handleIntervencaoArteFalse}
                      required
                      selected={intervencaoArte}
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm?.intervencaoArte === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={intervencaoArte}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="desc_intervencao_arte"
                      label="Descreva a(s) intervenção(ões)"
                      onChange={handleChange}
                      required
                      rows="2"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.desc_intervencao_arte
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>

                  <Wrap>
                    <File
                      label="Relatório de Arte:"
                      attach={dataRelatorioArteFile}
                      textFile="Nenhum arquivo selecionado"
                      name="relatorio_arte_file"
                      inputRef={relatorioArteRef}
                      onChange={handleFileChange}
                      onClick={handleRelatorioArteFile}
                      required
                      error={
                        errorsForm.relatorioArteFile === true && 'Obrigatório'
                      }
                      link={fileArte?.length > 0 && fileArte[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_equipamento_filmagem"
                      label="Qual a sua estrutura e equipamentos de filmagem:"
                      placeholder="Selecione"
                      options={equipamentosSelectList}
                      style={selectCustomStyles}
                      onChange={handleMultiSelectEquipamentos}
                      required
                      isMulti
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showOutroEquipamentoField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="outro_equipamento"
                        label="Outro Equipamento"
                        onChange={handleChange}
                        defaultValue={
                          responseLocation?.locacao_default?.outro_equipamento
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={showDescrEquipamentoFilmagemField}>
                  <Wrap>
                    <Textarea
                      label="Descreva a(s) extensão(ões)"
                      required
                      name="descricoes_equipamento_filmagem"
                      onChange={handleChange}
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descricoes_equipamento_filmagem
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Haverá gerador?"
                      onClickTrue={handleGeradorTrue}
                      onClickFalse={handleGeradorFalse}
                      selected={gerador}
                      isDisabled={!isAdminRequest}
                      error={errorsForm?.gerador === true && 'Obrigatório'}
                      required
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={gerador}>
                    <LimiterInputWidth w25>
                      <ChoiceButtonLocacao
                        label="Blimpado/Silencioso ou Não-Blimpado?"
                        onClickTrue={handleBlimpadorTrue}
                        onClickFalse={handleBlimpadorFalse}
                        selected={blimpado}
                        isDisabled={!isAdminRequest}
                        error={errorsForm?.blimpado === true && 'Obrigatório'}
                        yesLabelValue="Blimpado"
                        noLabelValue="Não-Blimpado"
                        required
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={gerador}>
                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Gerador portátil ou ficará sobre um veículo?"
                        onClickTrue={handleGeradorPortatilTrue}
                        onClickFalse={handleGeradorPortatilFalse}
                        selected={geradorPortatil}
                        isDisabled={!isAdminRequest}
                        error={
                          errorsForm?.geradorPortatil === true && 'Obrigatório'
                        }
                        yesLabelValue="Gerador portátil"
                        noLabelValue="Sobre um veículo"
                        required
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Precisará montar Base de Apoio no local?"
                      onClickTrue={handleBaseLocalTrue}
                      onClickFalse={handleBaseLocalFalse}
                      selected={baseLocal}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.baseLocal === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={baseLocal}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_finalidade_base_apoio"
                        label="Selecione as Finalidades"
                        placeholder="Selecione"
                        options={finalidadeSelectList}
                        style={selectCustomStyles}
                        required
                        onChange={handleMultiSelectFinalidades}
                        isMulti
                        isDisabled={!isAdminRequest}
                      />
                      <ConditionalContainer show={showOutroFinalidadeField}>
                        <Input
                          type="text"
                          name="outro_finalidade"
                          label="Outra Finalidade"
                          onChange={handleChange}
                          required
                          defaultValue={
                            dadoState?.viewData &&
                            responseLocation?.locacao_default.outro_finalidade
                          }
                          disabled={!isAdminRequest}
                        />
                      </ConditionalContainer>
                    </LimiterInputWidth>
                  </Wrap>

                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="local_base_apoio"
                        label="Descreva local da base de apoio"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.local_base_apoio
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>

                    <LimiterInputWidth w25 style={{ marginRight: 0 }}>
                      <Input
                        type="number"
                        step="0.01"
                        name="dimensao_base_apoio"
                        label="Descreva a dimensão estimada da área"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.dimensao_base_apoio
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>

                    <BoxHeader style={{ justifyContent: 'center', width: 50 }}>
                      <span className="boxText" style={{ color: '#828282' }}>
                        ou
                      </span>
                    </BoxHeader>

                    <LimiterInputWidth w25>
                      <Input
                        type="number"
                        name="quantidade_barracas"
                        label="Descreva a quantidade de barracas a serem montadas"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.quantidade_barracas
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Captação de som direto?"
                      onClickTrue={handleCaptacaoSomDiretoTrue}
                      onClickFalse={handleCaptacaoSomDiretoFalse}
                      selected={captacaoSomDireto}
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm?.captacaoSomDireto === true && 'Obrigatório'
                      }
                      required
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá som na filmagem?"
                      onClickTrue={handleSomFilmagemTrue}
                      onClickFalse={handleSomFilmagemFalse}
                      selected={somFilmagem}
                      required
                      error={errorsForm?.somFilmagem === true && 'Obrigatório'}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={somFilmagem}>
                  <Wrap>
                    <LimiterInputWidth w75>
                      <Select
                        name="ld_som_filmagem"
                        label="Sons de Filmagem"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        required
                        options={somFilmagemSelectList}
                        onChange={handleMultiSelectSomFilmagem}
                        isMulti
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <ConditionalContainer show={showOutroSomFilmagemField}>
                    <Wrap>
                      <Input
                        type="text"
                        name="outro_som_filmagem"
                        label="Outro Som de Filmagem"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.outro_som_filmagem
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Drone"
                      onClickTrue={handleDroneTue}
                      onClickFalse={handleDroneFalse}
                      selected={drone}
                      isDisabled={!isAdminRequest}
                      error={errorsForm?.drone === true && 'Obrigatório'}
                      required
                    />
                  </LimiterInputWidth>
                </Wrap>

                {drone === true && (
                  <Wrap>
                    <Textarea
                      name="autorizacao_drones"
                      onChange={handleChange}
                      rows="4"
                      value="Para filmagens com drones: o Espaço Aéreo não é de administração municipal, mas sim da Federação/União, portanto, não faz parte do campo de atuação da SPFilm. A autorização para o uso de drones deve ser solicitada diretamente à Agência Nacional de Aviação Civil (ANAC), mediante cadastro no Sistema SARPAS, disponibilizado no site do Departamento de Controle do Espaço Aéreo (DECEA)."
                      disabled
                    />
                  </Wrap>
                )}
                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Efeitos Especiais"
                      onClickTrue={handleEfeitosEspeciaisTrue}
                      onClickFalse={handleEfeitosEspeciaisFalse}
                      selected={efeitosEspeciais}
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm?.efeitosEspeciais === true && 'Obrigatório'
                      }
                      required
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={efeitosEspeciais}>
                    <Select
                      name="ld_efeitos_especiais_uuid"
                      label="Efeitos Especiais"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={efeitoEspecialSelectList}
                      onChange={value => {
                        handleSelectChange('ld_efeitos_especiais_uuid', value);
                      }}
                      required
                      isDisabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá animais de cena?"
                      onClickTrue={handleHaveraAnimaisTrue}
                      onClickFalse={handleHaveraAnimaisFalse}
                      selected={haveraAnimais}
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm?.haveraAnimais === true && 'Obrigatório'
                      }
                      required
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={haveraAnimais}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descricao_animais"
                      label="Descreva os animais"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.animais_em_cena
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá projeção visível a partir de logradouro público?"
                      onClickTrue={handleProjecaoTrue}
                      onClickFalse={handleProjecaoFalse}
                      selected={projecao}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.projecao === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={projecao}>
                  <Wrap>
                    <File
                      label="Conteúdo da Projeção:"
                      attach={dataConteudoProjecaoFile}
                      textFile="Nenhum arquivo selecionado"
                      name="conteudo_projecao_file"
                      inputRef={conteudoProjecaoRef}
                      onChange={handleFileChange}
                      required
                      onClick={handleConteudoProjecaoFile}
                      error={
                        errorsForm.conteudoProjecaoFile === true &&
                        'Obrigatório'
                      }
                      link={fileProjecao?.length > 0 && fileProjecao[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </Wrap>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Será projetado em imóvel público ou privado?"
                        onClickTrue={handlePublicoPrivadoTrue}
                        onClickFalse={handlePublicoPrivadoFalse}
                        selected={publicoPrivado}
                        required
                        isDisabled={!isAdminRequest}
                        error={
                          errorsForm.publicoPrivado === true && 'Obrigatório'
                        }
                        yesLabelValue="Público"
                        noLabelValue="Privado"
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="estrutura_projecao"
                      label="Descreva a Estrutura para a Projeção"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.estrutura_projecao
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="instalacao_estrutura_projecao"
                      label="Descreva local de instalação para estrutura de projeção"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.instalacao_estrutura_projecao
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Haverá exibição de logomarca na projeção? Se sim, qual porcentagem ocupará da área total da projeção e por quanto tempo será projetada?"
                        onClickTrue={handleProjecaoLogoTrue}
                        onClickFalse={handleProjecaoLogoFalse}
                        selected={projecaoLogo}
                        required
                        isDisabled={!isAdminRequest}
                        error={
                          errorsForm.projecaoLogo === true && 'Obrigatório'
                        }
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  <ConditionalContainer show={projecaoLogo}>
                    <Wrap>
                      <Input
                        type="number"
                        step="0.01"
                        name="porcentagem_projecao"
                        required
                        label="Porcentagem que a projeção irá ocupar"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default
                            ?.porcentagem_projecao
                        }
                        disabled={!isAdminRequest}
                      />
                      <Input
                        type="time"
                        name="area_tempo_projeção"
                        required
                        label="Tempo de Projeção"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.area_tempo_projeção
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá desligamento de iluminação no local?"
                      onClickTrue={handleDesligarLuzTrue}
                      onClickFalse={handleDesligarLuzFalse}
                      selected={desligarLuz}
                      isDisabled={!isAdminRequest}
                      error={errorsForm?.desligarLuz === true && 'Obrigatório'}
                      required
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={desligarLuz}>
                  <Wrap>
                    <File
                      label="Arquivo do local:"
                      attach={dataIluminacaoFile}
                      textFile="Nenhum arquivo selecionado"
                      name="iluminacao_file"
                      inputRef={iluminacaoFileRef}
                      onChange={handleFileChange}
                      onClick={handleIluminacaoFile}
                      error={
                        errorsForm?.iluminacaoFile === true && 'Obrigatório'
                      }
                      link={fileLuz?.length > 0 && fileLuz[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label="Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.especificidades
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && reservasVagas ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="reservas_vagas_locacao"
                          label="Reservas de Vagas"
                          placeholder="Selecione"
                          options={responseLocation?.locacao_via_externa?.map(
                            ({
                              uuid,
                              address,
                              number,
                              neighborhood,
                              endpoint,
                            }) => ({
                              label: `${address}, ${number}, ${neighborhood}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('reservas_vagas_locacao', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {formData?.reservas_vagas_locacao && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            editData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.reservas_vagas_locacao?.uuid,
                              endpoint: 'reservas-vagas-vinculado',
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de reserva de vagas em via próxima?"
                      onClickTrue={handleReservasVagasTrue}
                      onClickFalse={handleReservasVagasFalse}
                      selected={reservasVagas}
                      required
                      isDisabled
                      error={errorsForm.reservasVagas === true && 'Obrigatório'}
                    />
                  )}
                </Wrap>
              </WhiteBox>

              <Chat
                uuid={responseLocation?.locacao_default?.uuid}
                chatKeyFilter="locacao_default"
                locacaoNameView={responseLocation?.name_view}
              />

              <Button
                type="submit"
                btType="#26A2B1"
                label="Enviar"
                full
                width="140px"
                loading={loading}
                style={{ position: 'relative', bottom: '35px' }}
                disabled={loading}
              />
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>
              Filmagem em Espaços da Secretaria de Assistência e Desenvolvimento
              Social (Centro de Acolhida, etc)
            </h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Filmagem
                em Espaços da Secretaria de Assistência e Desenvolvimento Social
                (Centro de Acolhida, etc)
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderLocacao()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
