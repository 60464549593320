import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  ChoiceButtonLocacao,
  Textarea,
} from '~/components/Forms';

import { PageContainer, LimiterInputWidth } from '~/styles/components';

import { NavBarHeader } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import { useList } from '~/hooks/useFunction';

import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import LdProjetoPesquisaSocioeconomicaListActions from '~/store/ducks/ld-projeto-pesquisa-socioeconomica';
import LdRazaoPesquisaSocioeconomicaListActions from '~/store/ducks/ld-razao-pesquisa-socioeconomica';
import LdGastosEstimativaListActions from '~/store/ducks/ld-gastos-estimativa';
import LdOrcamentoObraListActions from '~/store/ducks/ld-orcamento-obra';
import Documents from '~/components/Documents/Documents';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const produtoraJovemMercadoOptionsList = [
  {
    value: 'ja_realizamos_programas',
    label: 'Já realizamos programas',
  },
  {
    value: 'possuimos_interesse',
    label: 'Possuímos interesse',
  },
];

const dadosTypeOptionsList = [
  {
    value: 0,
    label: 'Estimativa',
  },
  {
    value: 1,
    label: 'Dados reais',
  },
];

export default function PesquisaSocioeconomica() {
  const { state: dadoState } = useLocation();

  const dispatch = useDispatch();
  const history = useHistory();

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responsePesquisa, setResponsePesquisa] = useState();
  const [errorsData, setErrorsData] = useState({});
  const [dadosEconomicosType, setDadosEconomicosType] = useState(null);

  // states choices
  const [produtoraJovemMercado, setProdutoraJovemMercado] = useState(null);
  const [produtoraSustentavel, setProdutoraSustentavel] = useState(null);
  const [editaisSpcine, setEditaisSpcine] = useState(null);

  // states show fields
  const [
    showEspecProdutoraJovemMercadoField,
    setShowEspecProdutoraJovemMercadoField,
  ] = useState(false);
  const [
    showSelectProjetosInteresseField,
    setShowSelectProjetosInteresseField,
  ] = useState(false);
  const [
    showOutroRazaoFaltaInteresseField,
    setShowOutroRazaoFaltaInteresseField,
  ] = useState(false);
  const [showDadosComunsForm, setShowDadosComunsForm] = useState(false);

  // lists
  const editaisSpcineList = useList({ url: `ld-edital?paginate=false` })?.map(
    ({ uuid, name }) => ({ label: name, value: uuid }),
  );

  // selectors
  const { data: ldProjetoPesquisaSocioeconomicaList } = useSelector(
    state => state.ldProjetoPesquisaSocioeconomicaList,
  );
  const { data: ldRazaoPesquisaSocioeconomicaList } = useSelector(
    state => state.ldRazaoPesquisaSocioeconomicaList,
  );
  const { data: ldGastosEstimativaList } = useSelector(
    state => state.ldGastosEstimativaList,
  );
  const { data: ldOrcamentoObraList } = useSelector(
    state => state.ldOrcamentoObraList,
  );

  // Busca dados da Pesquisa
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `pesquisa-socioeconomica/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respPesquisa = resp?.data?.data;

      // tmp
      const realizamosProgramasOptTmp =
        respPesquisa?.pesquisa_dados_comuns?.descr_projetos_acessos_jovem ===
        'string';
      const possuimosInteresseOptTmp =
        Object.keys(respPesquisa?.pesquisa_dados_comuns?.ld_projetos || [])
          .length > 0;

      // set choices buttons
      setEditaisSpcine(
        Object.keys(respPesquisa?.pesquisa_dados_comuns?.ld_edital || [])
          .length > 0,
      );
      setProdutoraJovemMercado(
        realizamosProgramasOptTmp || possuimosInteresseOptTmp,
      );
      setProdutoraSustentavel(
        typeof respPesquisa?.pesquisa_dados_comuns
          ?.politica_sustentabilidade === 'string',
      );

      // set select default values
      formRef.current?.setFieldValue(
        'dados_type_option',
        dadosTypeOptionsList.find(({ label }) =>
          Object.keys(
            respPesquisa?.pesquisa_dados_comuns?.dados_estimativa || {},
          ).length > 0
            ? label === 'Estimativa'
            : label === 'Dados reais',
        ),
      );

      formRef.current?.setFieldValue(
        'ld_editais_spcine',
        respPesquisa?.pesquisa_dados_comuns?.ld_edital?.map(
          ({ name, uuid }) => ({
            label: name,
            value: uuid,
          }),
        ),
      );

      formRef.current?.setFieldValue(
        'produtora_jovem_mercado_option',
        produtoraJovemMercadoOptionsList.find(({ label }) =>
          // eslint-disable-next-line no-nested-ternary
          realizamosProgramasOptTmp
            ? label === 'Já realizamos programas'
            : possuimosInteresseOptTmp
            ? label === 'Possuímos interesse'
            : label === undefined,
        ),
      );

      formRef.current?.setFieldValue(
        'projetos',
        respPesquisa?.pesquisa_dados_comuns?.ld_projetos?.map(
          ({ name, uuid }) => ({
            label: name,
            value: uuid,
          }),
        ),
      );

      formRef.current?.setFieldValue(
        'razoes',
        respPesquisa?.pesquisa_dados_comuns?.ld_razao?.map(
          ({ name, uuid }) => ({
            label: name,
            value: uuid,
          }),
        ),
      );

      // estimativa
      formRef.current?.setFieldValue('ld_orcamento_obra_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_orcamento_obra?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_orcamento_obra?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_alimentacao_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_alimentacao?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_alimentacao?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_transporte_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_transporte?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_transporte?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_hospedagem_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_hospedagem?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_hospedagem?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_locacao_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_locacao?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_locacao?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_elenco_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_gasto_elenco
            ?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_gasto_elenco
            ?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_equipamento_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_equipamento?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_equipamento?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_arte_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_gasto_arte
            ?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_gasto_arte
            ?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_finalizacao_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_finalizacao?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_finalizacao?.uuid,
      });

      formRef.current?.setFieldValue('ld_gasto_impostos_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_impostos?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_gasto_impostos?.uuid,
      });

      formRef.current?.setFieldValue('ld_outros_apoio_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_outros_apoio
            ?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_outros_apoio
            ?.uuid,
      });

      formRef.current?.setFieldValue('ld_outros_seguros_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_outros_seguros?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_outros_seguros?.uuid,
      });

      formRef.current?.setFieldValue('ld_outros_administrativos_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_outros_administrativos?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_outros_administrativos?.uuid,
      });

      formRef.current?.setFieldValue('ld_outros_saude_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_outros_saude
            ?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_outros_saude
            ?.uuid,
      });

      formRef.current?.setFieldValue('ld_outros_covid_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_outros_covid
            ?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa?.ld_outros_covid
            ?.uuid,
      });

      formRef.current?.setFieldValue('ld_outros_investimento_formacao_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_outros_investimento_formacao?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_outros_investimento_formacao?.uuid,
      });

      formRef.current?.setFieldValue('ld_outros_nao_contemplados_uuid', {
        label:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_outros_nao_contemplados?.name,
        value:
          respPesquisa?.pesquisa_dados_comuns?.dados_estimativa
            ?.ld_outros_nao_contemplados?.uuid,
      });

      setResponsePesquisa(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState]);

  // effects
  useEffect(() => {
    dispatch(LdRazaoPesquisaSocioeconomicaListActions.showRequest());
    dispatch(LdProjetoPesquisaSocioeconomicaListActions.showRequest());
    dispatch(LdGastosEstimativaListActions.showRequest());
    dispatch(LdOrcamentoObraListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  // handles true/false
  function handleProdutoraJovemMercadoTrue(e) {
    e.preventDefault();
    setProdutoraJovemMercado(true);
    setShowEspecProdutoraJovemMercadoField(false);
    setShowOutroRazaoFaltaInteresseField(false);
    setShowSelectProjetosInteresseField(false);
    setErrorsData(state => ({
      ...state,
      produtoraJovemMercado: false,
    }));
  }

  function handleProdutoraJovemMercadoFalse(e) {
    e.preventDefault();
    setProdutoraJovemMercado(false);
    setShowEspecProdutoraJovemMercadoField(false);
    setShowOutroRazaoFaltaInteresseField(false);
    setShowSelectProjetosInteresseField(false);
    setErrorsData(state => ({
      ...state,
      produtoraJovemMercado: false,
    }));
  }

  function handleProdutoraSustentavelTrue(e) {
    e.preventDefault();
    setProdutoraSustentavel(true);
    setErrorsData(state => ({
      ...state,
      produtoraSustentavel: false,
    }));
  }

  function handleProdutoraSustentavelFalse(e) {
    e.preventDefault();
    setProdutoraSustentavel(false);
    setErrorsData(state => ({
      ...state,
      produtoraSustentavel: false,
    }));
  }

  function handleEditaisSpcineTrue(e) {
    e.preventDefault();
    setEditaisSpcine(true);
    setErrorsData(state => ({
      ...state,
      editaisSpcine: false,
    }));
  }

  function handleEditaisSpcineFalse(e) {
    e.preventDefault();
    setEditaisSpcine(false);
    setErrorsData(state => ({
      ...state,
      editaisSpcine: false,
    }));
  }

  // handles multi select
  function handleMultiSelectChange(value, event) {
    setFormData(state => ({
      ...state,
      [event.name]: value ? value.map(x => x.value) : [],
    }));

    setShowOutroRazaoFaltaInteresseField(
      event.name === 'razoes' && value?.some(x => x.label === 'Outros'),
    );
  }

  // handles change
  function handleOnlyText(event) {
    if (!/[a-zA-Z]/.test(event.key)) {
      event.preventDefault();
    }
  }
  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schemaDadosType = Yup.object().shape({
        dados_type_option: Yup.string()
          .typeError(defaultMsg)
          .test(
            'dados-type-nao-selecionado',
            defaultMsg,
            value =>
              !(
                showDadosComunsForm === false &&
                (value?.length <= 0 || !value)
              ),
          ),
        ld_editais_spcine: Yup.string()
          .typeError(defaultMsg)
          .test(
            'editais-spcine-selecionado',
            defaultMsg,
            value => !(editaisSpcine && (value?.length <= 0 || !value)),
          ),
      });

      await schemaDadosType.validate(data, {
        abortEarly: false,
      });

      if (editaisSpcine === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Editais SPCine' não foi informado!",
        });
        setErrorsData(state => ({
          ...state,
          editaisSpcine: true,
        }));
        return false;
      }

      // Campos 'choice button' não selecionados
      if (showDadosComunsForm && produtoraJovemMercado === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Produtora conduzindo projetos a jovens' não foi informado!",
        });
        setErrorsData(state => ({
          ...state,
          produtoraJovemMercado: true,
        }));
        return false;
      }

      if (showDadosComunsForm && produtoraSustentavel === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Produtora Sustentável' não foi informado!",
        });
        setErrorsData(state => ({
          ...state,
          produtoraSustentavel: true,
        }));
        return false;
      }

      const schemaDadosComuns = Yup.object().shape({
        dados_type_option: Yup.string()
          .typeError(defaultMsg)
          .test(
            'dados-type-nao-selecionado',
            defaultMsg,
            value =>
              !(
                showDadosComunsForm === false &&
                (value?.length <= 0 || !value)
              ),
          ),
        total_profissionais: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        total_equipe_tecnica_artistica: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tecnica_homens: Yup.string().typeError(defaultMsg).required(defaultMsg),
        tecnica_homens_minorias: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tecnica_homens_trans: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tecnica_mulheres: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tecnica_mulheres_minorias: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tecnica_mulheres_trans: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tecnica_nao_binarios: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tecnica_nao_binarios_minorias: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        total_elenco: Yup.string().typeError(defaultMsg).required(defaultMsg),
        elenco_homens: Yup.string().typeError(defaultMsg).required(defaultMsg),
        elenco_homens_minorias: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        elenco_homens_trans: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        elenco_mulheres: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        elenco_mulheres_minorias: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        elenco_mulheres_trans: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        elenco_nao_binario: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        elenco_nao_binario_minorias: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        total_profissionais_freelancers: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        total_profissionais_formais: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        total_estagiarios_formais: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        produtora_jovem_mercado_option: Yup.string()
          .typeError(defaultMsg)
          .test(
            'produtora-jovem-mercado-selecionado',
            defaultMsg,
            value =>
              !(
                produtoraJovemMercado === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        descr_projetos_acessos_jovem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'descr-produtora-jovem-mercado',
            defaultMsg,
            value =>
              !(
                showEspecProdutoraJovemMercadoField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        projetos: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'produtora-possui-interesse-selecionado',
            defaultMsg,
            value =>
              !(
                showSelectProjetosInteresseField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        razoes: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'produtora-jovem-mercado-selecionado-nao',
            defaultMsg,
            value =>
              !(
                produtoraJovemMercado === false &&
                (value?.length <= 0 || !value)
              ),
          ),
        outra_razao: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-razao-falta-interesse',
            defaultMsg,
            value =>
              !(
                showOutroRazaoFaltaInteresseField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        diarias_sp: Yup.string().typeError(defaultMsg).required(defaultMsg),
        diarias_outros: Yup.string().typeError(defaultMsg).required(defaultMsg),
        locacoes_publicas: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        locacoes_privadas: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        politica_sustentabilidade: Yup.string()
          .typeError(defaultMsg)
          .test(
            'politica-sustentabilidade-selecionada',
            defaultMsg,
            value =>
              !(
                produtoraSustentavel === true &&
                (value?.length <= 0 || !value)
              ),
          ),
      });

      await schemaDadosComuns.validate(data, {
        abortEarly: false,
      });

      // estimativa
      if (dadosEconomicosType === 0) {
        const schemaDadosEconomicosEstimativa = Yup.object().shape({
          ld_orcamento_obra_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_alimentacao_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_transporte_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_hospedagem_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_locacao_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_elenco_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_equipamento_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_arte_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_finalizacao_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_gasto_impostos_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_outros_apoio_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_outros_seguros_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_outros_administrativos_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_outros_saude_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          ld_outros_investimento_formacao_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
        });

        await schemaDadosEconomicosEstimativa.validate(data, {
          abortEarly: false,
        });
      }

      // reais
      if (dadosEconomicosType === 1) {
        const schemaDadosEconomicosReais = Yup.object().shape({
          orcamento: Yup.string().typeError(defaultMsg).required(defaultMsg),
          gastos_gerais_equipe_tecnica: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_alimentacao: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_transporte: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_hospedagem: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_locacao: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_elenco: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_equipamento: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_arte: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_finalizacao: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          gastos_gerais_impostos: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          outros_gastos_seguranca_apoio: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          outros_gastos_seguros: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          outros_gastos_administrativos: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          outros_gastos_saude_cuidados: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          outros_gastos_investimento_formacao: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
        });

        await schemaDadosEconomicosReais.validate(data, {
          abortEarly: false,
        });
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  async function handleSubmit(data) {
    data.projetos = formData?.projetos;
    data.razoes = formData?.razoes;
    data.ld_editais_spcine = formData?.ld_editais_spcine;

    if (!(await handleValidationFields(data))) {
      return;
    }

    const dataPesquisa = {
      real_data: data?.dados_type_option,
      total_profissionais: data?.total_profissionais,
      total_equipe_tecnica_artistica: data?.total_equipe_tecnica_artistica,
      tecnica_homens: data?.tecnica_homens,
      tecnica_homens_minorias: data?.tecnica_homens_minorias,
      tecnica_homens_trans: data?.tecnica_homens_trans,
      tecnica_mulheres: data?.tecnica_mulheres,
      tecnica_mulheres_minorias: data?.tecnica_mulheres_minorias,
      tecnica_mulheres_trans: data?.tecnica_mulheres_trans,
      tecnica_nao_binarios: data?.tecnica_nao_binarios,
      tecnica_nao_binarios_minorias: data?.tecnica_nao_binarios_minorias,
      total_elenco: data?.total_elenco,
      elenco_homens: data?.elenco_homens,
      elenco_homens_minorias: data?.elenco_homens_minorias,
      elenco_homens_trans: data?.elenco_homens_trans,
      elenco_mulheres: data?.elenco_mulheres,
      elenco_mulheres_minorias: data?.elenco_mulheres_minorias,
      elenco_mulheres_trans: data?.elenco_mulheres_trans,
      elenco_nao_binario: data?.elenco_nao_binario,
      elenco_nao_binario_minorias: data?.elenco_nao_binario_minorias,
      total_profissionais_freelancers: data?.total_profissionais_freelancers,
      total_profissionais_formais: data?.total_profissionais_formais,
      total_estagiarios_formais: data?.total_estagiarios_formais,
      descr_projetos_acessos_jovem: data?.descr_projetos_acessos_jovem,
      projetos: data?.projetos,
      razoes: data?.razoes,
      editais: data.ld_editais_spcine,
      outra_razao: data?.outra_razao,
      diarias_sp: data?.diarias_sp,
      diarias_outros: data?.diarias_outros,
      locacoes_publicas: data?.locacoes_publicas,
      locacoes_privadas: data?.locacoes_privadas,
      politica_sustentabilidade: data?.politica_sustentabilidade,
      orcamento: data?.orcamento,
      gastos_gerais_equipe_tecnica: data?.gastos_gerais_equipe_tecnica,
      gastos_gerais_alimentacao: data?.gastos_gerais_alimentacao,
      gastos_gerais_transporte: data?.gastos_gerais_transporte,
      gastos_gerais_hospedagem: data?.gastos_gerais_hospedagem,
      gastos_gerais_locacao: data?.gastos_gerais_locacao,
      gastos_gerais_elenco: data?.gastos_gerais_elenco,
      gastos_gerais_equipamento: data?.gastos_gerais_equipamento,
      gastos_gerais_arte: data?.gastos_gerais_arte,
      gastos_gerais_finalizacao: data?.gastos_gerais_finalizacao,
      gastos_gerais_impostos: data?.gastos_gerais_impostos,
      outros_gastos_seguranca_apoio: data?.outros_gastos_seguranca_apoio,
      outros_gastos_seguros: data?.outros_gastos_seguros,
      outros_gastos_administrativos: data?.outros_gastos_administrativos,
      outros_gastos_saude_cuidados: data?.outros_gastos_saude_cuidados,
      outros_gastos_investimento_formacao:
        data?.outros_gastos_investimento_formacao,
      outros_gastos_protocolo_covid: data?.outros_gastos_protocolo_covid,
      outros_gastos_nao_contemplados: data?.outros_gastos_nao_contemplados,
      tipo_gasto: data?.tipo_gasto,
      ld_orcamento_obra_uuid: data?.ld_orcamento_obra_uuid,
      ld_gasto_alimentacao_uuid: data?.ld_gasto_alimentacao_uuid,
      ld_gasto_transporte_uuid: data?.ld_gasto_transporte_uuid,
      ld_gasto_hospedagem_uuid: data?.ld_gasto_hospedagem_uuid,
      ld_gasto_locacao_uuid: data?.ld_gasto_locacao_uuid,
      ld_gasto_elenco_uuid: data?.ld_gasto_elenco_uuid,
      ld_gasto_equipamento_uuid: data?.ld_gasto_equipamento_uuid,
      ld_gasto_arte_uuid: data?.ld_gasto_arte_uuid,
      ld_gasto_finalizacao_uuid: data?.ld_gasto_finalizacao_uuid,
      ld_gasto_impostos_uuid: data?.ld_gasto_impostos_uuid,
      ld_outros_apoio_uuid: data?.ld_outros_apoio_uuid,
      ld_outros_seguros_uuid: data?.ld_outros_seguros_uuid,
      ld_outros_administrativos_uuid: data?.ld_outros_administrativos_uuid,
      ld_outros_saude_uuid: data?.ld_outros_saude_uuid,
      ld_outros_covid_uuid: data?.ld_outros_covid_uuid,
      ld_outros_investimento_formacao_uuid:
        data?.ld_outros_investimento_formacao_uuid,
      ld_outros_nao_contemplados_uuid: data?.ld_outros_nao_contemplados_uuid,
      work_uuid: dadoState.dados.uuid,
    };

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: 'pesquisa-socioeconomica',
        method: ApiClient.POST,
        body: dataPesquisa,
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Pesquisa Socioeconômica',
        text: 'Gravação efetuada com sucesso',
      });

      history.push('/home-user');
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Pesquisa Socioeconômica',
        text: 'Ocorreu um erro ao tentar gravar Pesquisa Socioeconômica. Verifique os dados.',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function renderDadosComuns() {
    return (
      <>
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">Dados Sociais</span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="total_profissionais"
                label="Estimativa do nº total de profissionais envolvidos na produção desta obra"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.total_profissionais
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">Equipe Técnica e Artística</span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="total_equipe_tecnica_artistica"
                label="Total Equipe"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.total_equipe_tecnica_artistica
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w25>
              <Input
                type="number"
                name="tecnica_homens"
                label="Homens"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.tecnica_homens
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="tecnica_homens_minorias"
                label="Autodeclarados Pretos, Pardos ou Indígenas"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.tecnica_homens_minorias
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="tecnica_homens_trans"
                label="Autodeclarados Transgêneros"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.tecnica_homens_trans
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w25>
              <Input
                type="number"
                name="tecnica_mulheres"
                label="Mulheres"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.tecnica_mulheres
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="tecnica_mulheres_minorias"
                label="Autodeclaradas Pretas, Pardas ou Indígenas"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.tecnica_mulheres_minorias
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="tecnica_mulheres_trans"
                label="Autodeclaradas Transgêneros"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.tecnica_mulheres_trans
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w25>
              <Input
                type="number"
                name="tecnica_nao_binarios"
                label="Não-binários"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.tecnica_nao_binarios
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="tecnica_nao_binarios_minorias"
                label="Autodeclaradas Pretas, Pardas ou Indígenas"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.tecnica_nao_binarios_minorias
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">Elenco</span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="total_elenco"
                label="Total Elenco"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.total_elenco
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w25>
              <Input
                type="number"
                name="elenco_homens"
                label="Homens"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.elenco_homens
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="elenco_homens_minorias"
                label="Autodeclarados Pretos, Pardos ou Indígenas"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.elenco_homens_minorias
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="elenco_homens_trans"
                label="Autodeclarados Transgêneros"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.elenco_homens_trans
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w25>
              <Input
                type="number"
                name="elenco_mulheres"
                label="Mulheres"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.elenco_mulheres
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="elenco_mulheres_minorias"
                label="Autodeclaradas Pretas, Pardas ou Indígenas"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.elenco_mulheres_minorias
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="elenco_mulheres_trans"
                label="Autodeclaradas Transgêneros"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.elenco_mulheres_trans
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w25>
              <Input
                type="number"
                name="elenco_nao_binario"
                label="Não-binários"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.elenco_nao_binario
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="elenco_nao_binario_minorias"
                label="Autodeclaradas Pretas, Pardas ou Indígenas"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.elenco_nao_binario_minorias
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">
              Número de Profissionais contratados temporariamente (freelancers)
              durante o período de produção
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="total_profissionais_freelancers"
                label="Total Profissionais freelancers"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.total_profissionais_freelancers
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">
              Número de Profissionais formalmente ligados à produtora(CLT, PJ ou
              sócios) trabalhando nessa obra durante o período de produção
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="total_profissionais_formais"
                label="Total Profissionais formais"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.total_profissionais_formais
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">
              Número de Estagiários com vínculo formal com uma instituição de
              Ensino, Jovens Aprendizes ou Trainees trabalhando nessa obra
              durante o período de produção
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="total_estagiarios_formais"
                label="Total Estagiários formais"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.total_estagiarios_formais
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
        <>
          <Wrap>
            <LimiterInputWidth w50>
              <ChoiceButtonLocacao
                label="A produtora faz ou está aberta a conduzir projetos de acesso de jovens ao mercado de trabalho?"
                onClickTrue={handleProdutoraJovemMercadoTrue}
                onClickFalse={handleProdutoraJovemMercadoFalse}
                selected={produtoraJovemMercado}
                required
                isDisabled={dadoState?.viewData && true}
                error={
                  errorsData.produtoraJovemMercado === true && 'Obrigatório'
                }
              />
            </LimiterInputWidth>
          </Wrap>
          {produtoraJovemMercado && (
            <Wrap>
              <LimiterInputWidth w35 style={{ alignSelf: 'flex-end' }}>
                <Select
                  name="produtora_jovem_mercado_option"
                  label="A produtora já realiza programas ou possui interesse?"
                  placeholder="Selecione"
                  required
                  options={produtoraJovemMercadoOptionsList}
                  style={selectCustomStyles}
                  onChange={value => {
                    handleSelectChange('produtora_jovem_mercado_option', value);
                    setShowEspecProdutoraJovemMercadoField(
                      value.label === 'Já realizamos programas',
                    );
                    setShowSelectProjetosInteresseField(
                      value.label === 'Possuímos interesse',
                    );
                    formRef.current.clearField('projetos');
                    setFormData(state => ({
                      ...state,
                      projetos: [],
                    }));
                  }}
                  isDisabled={dadoState?.viewData && true}
                />
              </LimiterInputWidth>
              {showEspecProdutoraJovemMercadoField && (
                <LimiterInputWidth w50 style={{ alignSelf: 'center' }}>
                  <Input
                    type="text"
                    name="descr_projetos_acessos_jovem"
                    label="Especifique quais são os projetos para acesso aos jovens"
                    required
                    onChange={handleChange}
                    onKeyPress={handleOnlyText}
                    defaultValue={
                      responsePesquisa?.pesquisa_dados_comuns
                        ?.descr_projetos_acessos_jovem
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </LimiterInputWidth>
              )}
              {showSelectProjetosInteresseField && (
                <LimiterInputWidth w50>
                  <Select
                    name="projetos"
                    label="Especifique quais são os projetos de interesse de adesão por parte da produtora"
                    placeholder="Selecione"
                    required
                    isMulti
                    options={ldProjetoPesquisaSocioeconomicaList}
                    style={selectCustomStyles}
                    onChange={handleMultiSelectChange}
                    isDisabled={dadoState?.viewData && true}
                  />
                </LimiterInputWidth>
              )}
            </Wrap>
          )}

          {produtoraJovemMercado === false && (
            <Wrap style={{ alignItems: 'center' }}>
              <LimiterInputWidth w50>
                <Select
                  name="razoes"
                  label="Especifique a razão que motiva a falta de interesse em adesão a projetos de inclusão de jovens"
                  placeholder="Selecione"
                  required
                  isMulti
                  options={ldRazaoPesquisaSocioeconomicaList}
                  style={selectCustomStyles}
                  onChange={handleMultiSelectChange}
                  isDisabled={dadoState?.viewData && true}
                />
              </LimiterInputWidth>
              {showOutroRazaoFaltaInteresseField && (
                <LimiterInputWidth w50>
                  <Input
                    type="text"
                    name="outra_razao"
                    label="Outra razão"
                    required
                    onChange={handleChange}
                    onKeyPress={handleOnlyText}
                    defaultValue={
                      responsePesquisa?.pesquisa_dados_comuns?.outra_razao
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </LimiterInputWidth>
              )}
            </Wrap>
          )}
        </>
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">Total de Diárias</span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="diarias_sp"
                label="Na cidade de São Paulo"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.diarias_sp
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="diarias_outros"
                label="Em outras localidades"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.diarias_outros
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">
              Total de Locações na cidade de São Paulo
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="locacoes_publicas"
                label="Públicas"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.locacoes_publicas
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                name="locacoes_privadas"
                label="Privadas"
                required
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.locacoes_privadas
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
        <>
          <BoxHeader style={{ marginTop: 20, marginBottom: 5 }}>
            <span className="boxText">
              A produtora possui alguma política de sustentabilidade para essa
              filmagem? Se sim, por favor explique
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <ChoiceButtonLocacao
                onClickTrue={handleProdutoraSustentavelTrue}
                onClickFalse={handleProdutoraSustentavelFalse}
                selected={produtoraSustentavel}
                isDisabled={dadoState?.viewData && true}
                error={
                  errorsData.produtoraSustentavel === true && 'Obrigatório'
                }
              />
            </LimiterInputWidth>
          </Wrap>
          {produtoraSustentavel && (
            <Wrap>
              <Textarea
                type="text"
                name="politica_sustentabilidade"
                label="Descrição"
                onChange={handleChange}
                rows="3"
                required
                defaultValue={
                  dadoState?.viewData &&
                  responsePesquisa?.pesquisa_dados_comuns
                    ?.politica_sustentabilidade
                }
                disabled={dadoState?.viewData && true}
              />
            </Wrap>
          )}
        </>
      </>
    );
  }

  function renderDadosEconomicos() {
    if (dadosEconomicosType === 0) {
      // estimativa
      return (
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">Dados Econômicos</span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_orcamento_obra_uuid"
                label="Estimativa de Orçamento Total da Obra Audiovisual gasto na cidade de São Paulo (R$)"
                placeholder="Selecione"
                required
                options={ldOrcamentoObraList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_orcamento_obra_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText" style={{ color: '#828282' }}>
              Gastos gerais - Equipe Técnica
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_alimentacao_uuid"
                label="Alimentação"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_alimentacao_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_transporte_uuid"
                label="Transporte"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_transporte_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_hospedagem_uuid"
                label="Hospedagem"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_hospedagem_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_locacao_uuid"
                label="Locação"
                placeholder="Selecione"
                required
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_locacao_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_elenco_uuid"
                label="Elenco"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_elenco_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_equipamento_uuid"
                label="Equipamento"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_equipamento_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_arte_uuid"
                label="Arte"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_arte_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_finalizacao_uuid"
                label="Finalização"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_finalizacao_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_gasto_impostos_uuid"
                label="Impostos"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_gasto_impostos_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText" style={{ color: '#828282' }}>
              Outros
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_outros_apoio_uuid"
                label="Segurança e Equipe Apoio"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_outros_apoio_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Select
                name="ld_outros_seguros_uuid"
                label="Seguros"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_outros_seguros_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_outros_administrativos_uuid"
                label="Gastos Administrativos"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_outros_administrativos_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Select
                name="ld_outros_saude_uuid"
                label="Saúde e Cuidados Médicos"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_outros_saude_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_outros_covid_uuid"
                label="Protocolos de COVID, Higienização e EPIs"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_outros_covid_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Select
                name="ld_outros_investimento_formacao_uuid"
                label="Investimento em Formação Profissional"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange(
                    'ld_outros_investimento_formacao_uuid',
                    value,
                  );
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Select
                name="ld_outros_nao_contemplados_uuid"
                label="Outros gastos não-contemplados acima"
                placeholder="Selecione"
                options={ldGastosEstimativaList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('ld_outros_nao_contemplados_uuid', value);
                }}
                isDisabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
      );
    }

    if (dadosEconomicosType === 1) {
      // reais
      return (
        <>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText">Dados Econômicos</span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="orcamento"
                label="Orçamento Total da Obra Audiovisual gasto na cidade de São Paulo (R$)"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.orcamento
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText" style={{ color: '#828282' }}>
              Gastos gerais
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_equipe_tecnica"
                label="Equipe Técnica"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_equipe_tecnica
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_alimentacao"
                label="Alimentação"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_alimentacao
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_transporte"
                label="Transporte"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_transporte
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_hospedagem"
                label="Hospedagem"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_hospedagem
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_locacao"
                label="Locação"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_locacao
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_elenco"
                label="Elenco"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_elenco
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_equipamento"
                label="Equipamento"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_equipamento
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_arte"
                label="Arte"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_arte
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_finalizacao"
                label="Finalização"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_finalizacao
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="gastos_gerais_impostos"
                label="Impostos"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.gastos_gerais_impostos
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <BoxHeader style={{ margin: '20px 0' }}>
            <span className="boxText" style={{ color: '#828282' }}>
              Outros
            </span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="outros_gastos_seguranca_apoio"
                label="Segurança e Equipe Apoio"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.outros_gastos_seguranca_apoio
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="outros_gastos_seguros"
                label="Seguros"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.outros_gastos_seguros
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="outros_gastos_administrativos"
                label="Gastos Administrativos"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.outros_gastos_administrativos
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="outros_gastos_saude_cuidados"
                label="Saúde e Cuidados Médicos"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.outros_gastos_saude_cuidados
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="outros_gastos_protocolo_covid"
                label="Protocolos de COVID, Higienização e EPIs"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.outros_gastos_protocolo_covid
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="outros_gastos_investimento_formacao"
                label="Investimento em Formação Profissional"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.outros_gastos_investimento_formacao
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="number"
                step="0.01"
                name="outros_gastos_nao_contemplados"
                label="Outros gastos não-contemplados acima"
                onChange={handleChange}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.outros_gastos_nao_contemplados
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
            <LimiterInputWidth w50>
              <Input
                type="text"
                name="tipo_gasto"
                label="Tipo de gasto"
                onChange={handleChange}
                onKeyPress={handleOnlyText}
                defaultValue={
                  responsePesquisa?.pesquisa_dados_comuns?.dados_reais
                    ?.tipo_gasto
                }
                disabled={dadoState?.viewData && true}
              />
            </LimiterInputWidth>
          </Wrap>
        </>
      );
    }

    return <></>;
  }

  function renderForm() {
    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit} no>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <>
                  <BoxHeader>
                    <span className="boxText">Informações gerais</span>
                  </BoxHeader>
                  <Wrap>
                    <Textarea
                      name="form_info"
                      rows="4"
                      value="Essa pesquisa tem como objetivo reunir dados das produções audiovisuais que filmam na cidade de São Paulo de maneira a fortalecer e garantir a continuidade da política pública de uma cidade amigável às filmagens, bem como compreender melhor as potencialidades e fragilidades do setor.&#13;&#13;Os dados preenchidos são referentes à obra como um todo, não somente das diárias requisitadas para a SPFilm.&#13;&#13;As pesquisas de cada produção são sigilosas e só terão seus dados divulgados de maneira global, em conjunto com outras, sem a identificação da obra/cliente/responsáveis/produtora e afins. É de extrema importância o preenchimento da informações de maneira mais precisa possível."
                      disabled
                    />
                  </Wrap>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="dados_type_option"
                        label="Você fornecerá dados reais da obra ou somente uma estimativa?"
                        placeholder="Selecione"
                        options={dadosTypeOptionsList}
                        style={selectCustomStyles}
                        onChange={value => {
                          setDadosEconomicosType(value.value);
                          setShowDadosComunsForm(Object.keys(value).length > 0);
                          handleSelectChange('dados_type_option', value);
                        }}
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        onClickTrue={handleEditaisSpcineTrue}
                        onClickFalse={handleEditaisSpcineFalse}
                        label="O projeto cadastrado foi contemplado por algum edital da Spcine entre 2015 e 2021?"
                        selected={editaisSpcine}
                        isDisabled={dadoState?.viewData && true}
                        error={
                          errorsData.editaisSpcine === true && 'Obrigatório'
                        }
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  {editaisSpcine && (
                    <Wrap>
                      <Select
                        name="ld_editais_spcine"
                        placeholder="Selecione"
                        options={editaisSpcineList}
                        isMulti
                        style={selectCustomStyles}
                        onChange={handleMultiSelectChange}
                        isDisabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  )}
                </>
                {showDadosComunsForm && (
                  <>
                    {renderDadosComuns()}
                    {renderDadosEconomicos()}
                  </>
                )}
              </WhiteBox>
              {!dadoState?.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        <NavBarHeader />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Pesquisa Socioeconômica</h1>
            <span>
              Home | Obras | {dadoState?.nome_projeto} | Pesquisa Socioeconômica
            </span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
        <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
