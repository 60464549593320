import { call, put, select } from 'redux-saga/effects';
import api, { makeOptions } from '../../services/api';
import UserMeActions from '../ducks/userMe';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));
// const getAuthData = (state) => state.auth.data;

export function* fetchShow() {
  try {
    // const authData = yield select(getAuthData);
    const { data } = yield call(
      api.get,
      `/me`,
      makeOptions(dataLocal.auth.data),
    );
    const roles = data?.data?.roles;
    if (roles) {
      const roleNames = [];
      roles.forEach((item, index) => {
        roleNames.push(item.name);
        switch (item.name) {
          case 'Pessoa Fisica':
            localStorage.setItem('@roleP', JSON.stringify('Pessoa Fisica'));
            break;
          case 'Pessoa Jurídico':
            localStorage.setItem('@roleJ', JSON.stringify('Pessoa Jurídico'));
            break;
          case 'Administrador':
            localStorage.setItem('@roleAd', JSON.stringify('Administrador'));
            break;
          case 'Moderador':
            localStorage.setItem('@roleMd', JSON.stringify('Moderador'));
            break;
          default:
        }
      });
    }

    yield put(UserMeActions.showSuccess(data));
  } catch (err) {
    yield put(UserMeActions.showFailure(err.response.data.error.message));
  }
}
