import styled from 'styled-components';

export const Content = styled.div`
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;

  color: #1e1e1e;
  h1 {
    font-family: 'InterSemiBold', sans-serif;
  }

  a {
    color: #1e1e1e;
    text-decoration: underline;
  }
`;
