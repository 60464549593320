import React, { useCallback, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { BsFillEyeFill } from 'react-icons/bs';
import { format } from 'date-fns';
import { GoClippy } from 'react-icons/go';
import { ModalStatusCienciaFilmagem } from '~/components/Admin';

function ListaPageCienciaFilmagem({ data, cienciaStatusList, loadCiencia }) {
  const [openModalStatusCiencia, setOpenModalStatusCiencia] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModalStatusCiencia(false);
  }, []);

  return (
    <>
      <tr>
        <td>
          {data && format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm')}
        </td>
        <td>{data && data?.titulo_obra}</td>
        {/*  <td>{data && data?.protocol}</td> */}
        <td
          style={{
            justifyContent: 'center',
          }}
        >
          {data && data?.produtora}
        </td>
        <td
          style={{
            justifyContent: 'center',
          }}
        >
          {data && data?.cpf_cnpj}
        </td>
        <td
          style={{
            justifyContent: 'center',
          }}
        >
          {data && data?.user?.user_personal_data?.name}
        </td>
        <td
          style={{
            justifyContent: 'center',
          }}
        >
          {data && data?.user?.email}
        </td>
        <td
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
          className="buttons"
        >
          <span tooltip="Mudar status..." style={{ cursor: 'pointer' }}>
            <button
              type="button"
              onClick={() => setOpenModalStatusCiencia(true)}
            >
              <GoClippy color="#4f4f4f" size={22} />
            </button>
          </span>
          <span tooltip="Detalhes...">
            <Link
              to={{
                pathname: `/ciencia-filmagem-interna`,
                state: { viewData: data, isAdminRequest: true },
              }}
            >
              <BsFillEyeFill color="#4f4f4f" size={24} />
            </Link>
          </span>
        </td>
      </tr>

      <ModalStatusCienciaFilmagem
        cienciaStatusList={cienciaStatusList}
        cienciaFilmagem={data}
        openModal={openModalStatusCiencia}
        closeModal={handleCloseModal}
        loadCiencia={loadCiencia}
      />
    </>
  );
}

export default withRouter(ListaPageCienciaFilmagem);
