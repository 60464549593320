/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { format, parse } from 'date-fns';
import omitEmpty from 'omit-empty';
import { FiLoader } from 'react-icons/fi';
import { GridContainer, Line, Wrap } from '~/styles/components';
import { Input } from '~/components/Forms';
import Button from '~/components/Button';
import MultiDateInput from '~/components/Forms/MultiDateInput';
import { showModalMessage } from '~/services/notification';
import { VirtualTable } from '~/components/Forms/VirtualTable';
import { AccordionItem } from '~/components/Forms/AccordionItem';
import { Text } from '~/components/Forms/Text';
import ApiClient from '~/utils/ApiClient';
import { SplitButton } from '~/components/SplitButton';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const uri = 'report-internal-footage';

export const CienciaFilmagemInternaForm = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});

  const handleChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleResetForm = () => {
    setFormData({
      datas_cadastro: [],
      endereco: '',
    });
  };

  const handleValidationFields = useCallback(async () => {
    try {
      formRef.current.setErrors({});

      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        datas_cadastro: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'datas-cadastro-nao-informadas',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }, [formData]);

  const bodyBuilder = useCallback(async () => {
    if (!(await handleValidationFields())) return null;

    try {
      // format dates
      const datasCadastro = formData?.datas_cadastro?.map(date =>
        format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      );

      const body = omitEmpty({
        start_date: datasCadastro[0],
        end_date:
          datasCadastro.length === 1 ? datasCadastro[0] : datasCadastro[1],
        address: formData?.endereco,
      });

      return body;
    } catch (error) {
      showModalMessage({
        type: 'error',
        text: 'Erro ao formatar dados para requisição',
      });
      return null;
    }
  }, [formData?.datas_cadastro, formData?.endereco, handleValidationFields]);

  const handleClickGerar = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri,
        method: ApiClient.POST,
        body,
        expectedStatus: 200,
      });

      const data = resp.data.footages.map(footage => {
        return {
          ...footage,
          date_ciencia_filmagem_sorted: footage.date_ciencia_filmagem.sort(
            (a, b) => {
              if (a.date < b.date) {
                return -1;
              }
              if (a.date > b.date) {
                return 1;
              }
              return 0;
            },
          ),
        };
      });

      setResponseData({ list: data, statistics: resp.data.statistics });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  const handleClickExportarExcel = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      await ApiClient.doRequest({
        uri: `${uri}/xls`,
        method: ApiClient.POST,
        body: { ...body, report_name: 'getInternalFootageReport' },
        expectedStatus: 200,
        isBinary: true,
        fileName: 'relatorio.xlsx',
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  const renderFiltros = () => {
    return (
      <>
        <Wrap>
          <GridContainer cols={4} gap={30} style={{ alignItems: 'flex-end' }}>
            <MultiDateInput
              range
              name="datas_cadastro"
              label="Data de Cadastro"
              required
              value={formData?.datas_cadastro}
              onChange={e => {
                handleChange(
                  'datas_cadastro',
                  e?.map(
                    ({ day, month, year }) =>
                      `${String(day).padStart(2, '0')}/${String(month).padStart(
                        2,
                        '0',
                      )}/${year}`,
                  ),
                );
              }}
            />

            {Object.values(formData?.datas_cadastro || []).length > 1 && (
              <>
                <Input
                  type="text"
                  label="Endereço"
                  name="endereco"
                  value={formData?.endereco}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
              </>
            )}
          </GridContainer>
        </Wrap>

        <Wrap style={{ justifyContent: 'flex-end' }}>
          <SplitButton
            buttons={[
              {
                label: 'Gerar',
                onClick: handleClickGerar,
              },
              {
                label: 'Exportar Excel...',
                onClick: handleClickExportarExcel,
              },
            ]}
            isLoading={loading}
            disableAllButtons={loading}
          />

          <Button
            type="button"
            btType="#828282"
            onClick={handleResetForm}
            label="Limpar campos"
            full
            loading={loading}
            disabled={loading}
            width="150px"
            style={{ marginLeft: 5 }}
          />
        </Wrap>
        <Line />
      </>
    );
  };

  const renderQuantitativo = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData).length <= 0 ||
      responseData?.statistics?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    return (
      <>
        <GridContainer cols={5} style={{ marginTop: 15 }}>
          {responseData.statistics?.map(({ label, value }) => (
            <Text label={label} value={value} />
          ))}
        </GridContainer>
        <Line />
      </>
    );
  };

  const renderListagemBaseNova = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (Object.keys(responseData).length <= 0 || responseData?.length <= 0)
      return <span>Nenhum registro encontrado</span>;

    const columnsNames = [
      'Data/Hora Criação',
      'Data Filmagem',
      'Nome',
      'E-mail',
      'Endereço',
    ];

    const tableData = responseData.list.map(data => ({
      created_at: format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm'),
      data_filmagem: `${format(
        new Date(data?.date_ciencia_filmagem_sorted[0].date),
        'dd/MM/yyyy',
      )}
        -
        ${format(
          new Date(
            data?.date_ciencia_filmagem_sorted[
              data?.date_ciencia_filmagem_sorted?.length - 1
            ].date,
          ),
          'dd/MM/yyyy',
        )}`,
      nome: data?.production_coordinator_name,
      email: data?.production_coordinator_email,
      endereco: data?.endereco_ciencia_filmagem[0].address,
    }));

    return (
      <VirtualTable
        title="Impressão da listagem de Ciência Filmagem Interna"
        height={500}
        width="100%"
        columnsNames={columnsNames}
        itemCount={tableData.length}
        itemSize={40}
        tableData={tableData}
        showPrinter
        header={
          <thead>
            <tr>
              {columnsNames.map(columnName => (
                <th>{columnName}</th>
              ))}
            </tr>
          </thead>
        }
        row={({ index, style }) => (
          <tr>
            <td>{tableData[index]?.created_at}</td>
            <td>{tableData[index]?.data_filmagem}</td>
            <td>{tableData[index]?.nome}</td>
            <td>{tableData[index]?.email}</td>
            <td>{tableData[index]?.endereco}</td>
          </tr>
        )}
        footer={
          <tfoot>
            <tr>
              <td colSpan={3}>Fim de listagem</td>
            </tr>
          </tfoot>
        }
      />
    );
  };

  return (
    <Form ref={formRef}>
      <Wrap>
        <AccordionItem opened label="Filtros">
          {renderFiltros()}
        </AccordionItem>
      </Wrap>

      <Wrap>
        <AccordionItem label="Quantitativos">
          {renderQuantitativo()}
        </AccordionItem>
      </Wrap>

      <Wrap>
        <AccordionItem label="Listagem (Base nova)">
          {renderListagemBaseNova()}
        </AccordionItem>
      </Wrap>
    </Form>
  );
};
