import styled from "styled-components";
import clientResources from '~/data/clientResources';


export const PageContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: url(${props => props.background}) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100vh;
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 467px;
  margin: 0 auto;
  padding: 48px 32px 30px 32px;
  border: none;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: Column;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;

  h1 {
    font-family: 'InterBold', sans-serif;
    font-size: 30px;
    line-height: 36px;
    color: #3f3f3f;
    margin-bottom: 40px;
  }

  form {
    width: 100%;

    button {
      margin-top: 60px;
      margin-bottom: 24px;
    }

    & + input {
      margin-bottom: 16px;
    }
  }

  &.recovery {
    h1 {
      margin-bottom: 16px;
    }

    p {
      color: #3f3f3f;
      margin-bottom: 40px;
    }

    .inputRow {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  margin-bottom: 16px;
  position: relative;

  button.eye {
    position: absolute;
    right: 16px;
    bottom: 10px;
    margin: 0;
    z-index: 1;
  }

  &.forgot-password {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      margin: 0;
    }
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Logotips = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 0 35px 0 35px;

  img {
    &:first-child {
      margin-right: 8px;
    }
  }
`;
