import React from 'react';
// import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { BsFillFlagFill, BsFillEyeFill } from 'react-icons/bs';

// import { Modal } from "~/components/globals";

// import Check from "~/assets/images/icons/branch/check.png";
// import Delete from "~/assets/images/icons/branch/delete.png";

function ListaPageEquipesAbaAtiv({ data }) {
  // const [open, setOpen] = useState(false);

  // const handleCloseModal = useCallback(() => {
  //   setOpen(false);
  // }, []);

  return (
    <>
    <tr>
        <td>{data && data?.protocol}</td>
        <td>{data && data?.title}</td>
        <td>{data && data?.novasLocacoes}</td>
        <td>{data && data?.alteracoes}</td>
        <td>{data && data?.alertasPedidos}</td>
        <td>{data && data?.alertasAutorizacao}</td>
        <td className="buttons">
          <button
            type="button"
            // onClick={() => setOpen(true)}
          >
            <BsFillEyeFill color="#4f4f4f" size={24} />
          </button>
        </td>
      </tr>

      {/* <Modal
        openModal={open}
        closeModal={handleCloseModal}
        inputs={FormularioHistory}
      /> */}
    </>
  );
}

export default withRouter(ListaPageEquipesAbaAtiv);
