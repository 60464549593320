import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const BoxHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .boxText {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #26a2b1;
  }

  span {
    color: #828282;
    font-size: 24px;
  }
`;

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const ContentPDFDocument = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > a {
    color: #828282;
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    max-width: fit-content;
    padding: 5px;

    &:hover {
      border-radius: 5px;
      border: 1px solid #828282;
    }
  }
`;

export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  padding: 16,
  borderRadius: 10,
  top: 0,
  left: 0,
  width: '90%',
  maxWidth: 780,
  minHeight: 150,
  maxHeight: '80vh',
  border: 'none',
  overflowY: 'auto',
};
