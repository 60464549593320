/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { isSafari } from 'react-device-detect';

import { Input, InputMask } from '~/components/Forms';

import { Container, Wrap } from './styles';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage } from '~/services/notification';
import Load from '~/components/Load';

export default function BuscaDocumento({
  formRef,
  change,
  cpf,
  cnpj,
  nameCpf,
  nameEmail,
  name,
  namePhone,
  namePhoneMobile,
  nameRg,
  nameBirthDate,
  isNovaObra,
  pfExistsFunc,
  pjExistsFunc,
  hideEmail,
}) {
  const [searchDocument, setSearchDocument] = useState(null);
  const [inLoad, setInload] = useState(false);

  async function handleEmailExists(evt) {
    const { value } = evt.target;

    if (!value) return;

    setInload(true);

    try {
      await ApiClient.doRequest({
        uri: `user/exist-by-email/${value}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setInload(false);

      await showModalMessage({
        type: 'error',
        text: `Email '${value}' já cadastrado. Por favor, informe outro.`,
      });

      formRef?.current?.clearField(nameEmail);
      formRef?.current?.clearField('email_pf');
    } catch (err) {
      setInload(false);
    }
  }

  async function handleSearchDocument(evt) {
    const { value } = evt.target;

    const search = value.replace(/[^0-9]/g, '');

    try {
      if (!search) {
        throw new Error();
      }

      setInload(true);
      const branchExists = await ApiClient.doRequest({
        uri: `/branch/find/${search}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setInload(false);

      setSearchDocument(branchExists?.data?.data);

      if (pjExistsFunc) {
        pjExistsFunc(
          branchExists?.data?.data ? branchExists?.data?.data.uuid : undefined,
        );
      }
    } catch (err) {
      setInload(false);
      setSearchDocument({});

      formRef?.current?.clearField('rate_social_pj');
      formRef?.current?.clearField('fantasy_name_pj');
      formRef?.current?.clearField('phone_pj');

      if (pjExistsFunc) {
        pjExistsFunc(undefined);
      }
    }
  }

  async function handleSearchPhysicalPerson(evt) {
    const { value } = evt.target;
    const search = value.replace(/[^0-9]/g, '');

    try {
      if (!search) {
        throw new Error();
      }

      setInload(true);
      const userExists = await ApiClient.doRequest({
        uri: `/user/find/${search}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setInload(false);

      setSearchDocument(userExists?.data?.data);

      if (pfExistsFunc) {
        pfExistsFunc(
          userExists?.data?.data ? userExists?.data?.data.uuid : undefined,
        );
      }
    } catch (err) {
      setInload(false);
      setSearchDocument({});

      formRef?.current?.clearField(name);
      formRef?.current?.clearField('name_pf');
      formRef?.current?.clearField(nameEmail);
      formRef?.current?.clearField('email_pf');
      formRef?.current?.clearField(namePhoneMobile);
      formRef?.current?.clearField('phone_mobile_pf');
      formRef?.current?.clearField(nameBirthDate);
      formRef?.current?.clearField('birth_date_pf');
      formRef?.current?.clearField(nameRg);
      formRef?.current?.clearField('rg_pf');

      if (pfExistsFunc) {
        pfExistsFunc(undefined);
      }
    }
  }

  return (
    <Container>
      <Load inLoad={inLoad} />
      {cpf && (
        <>
          <Wrap>
            <InputMask
              name={nameCpf || 'cpf_pf'}
              label="CPF"
              mask="999.999.999-99"
              maskChar=""
              onChange={change}
              onBlur={handleSearchPhysicalPerson}
              required
            />
            <Input
              name={name || 'name_pf'}
              label="Nome Civil/Nome Social"
              onChange={change}
              value={searchDocument?.user_personal_data?.name}
              disabled={
                searchDocument && Object.keys(searchDocument).length > 0
              }
              required
            />
            {!hideEmail && (
              <Input
                name={nameEmail || 'email_pf'}
                label="E-mail"
                onChange={change}
                value={searchDocument?.email}
                disabled={
                  searchDocument && Object.keys(searchDocument).length > 0
                }
                onBlur={handleEmailExists}
                required
              />
            )}
          </Wrap>
          <Wrap>
            <InputMask
              name={namePhoneMobile || 'phone_mobile_pf'}
              label="Celular"
              mask="(99) 99999-9999"
              maskChar=""
              onChange={change}
              value={searchDocument?.user_personal_data?.phone_mobile}
              disabled={
                searchDocument && Object.keys(searchDocument).length > 0
              }
              required
            />
            <Input
              name={nameRg || 'rg_pf'}
              label="RG/RNE"
              // mask="99.999.999-9"
              // maskChar=""
              onChange={change}
              value={searchDocument?.user_personal_data?.rg}
              disabled={
                searchDocument && Object.keys(searchDocument).length > 0
              }
              required
            />
            <Input
              name={nameBirthDate || 'birth_date_pf'}
              label="Data de nascimento"
              type="date"
              onChange={change}
              value={searchDocument?.user_birth_address?.birth_date}
              min='1930-01-01'
              disabled={
                // searchDocument && Object.keys(searchDocument).length > 0
                searchDocument && searchDocument?.user_birth_address?.birth_date
              }
              placeholder={isSafari && 'aaaa-mm-dd'}
              required
            />
          </Wrap>
        </>
      )}
      {cnpj && (
        <>
          <Wrap>
            <InputMask
              name="cnpj_pj"
              label="CNPJ"
              mask="99.999.999/9999-99"
              maskChar=""
              onChange={change}
              onBlur={handleSearchDocument}
              required
            />
            <Input
              name="rate_social_pj"
              label="Razão Social"
              onChange={change}
              value={searchDocument?.company_name}
              disabled={
                searchDocument && Object.keys(searchDocument).length > 0
              }
              required
            />
          </Wrap>
          <Wrap>
            <Input
              name="fantasy_name_pj"
              label="Nome Fantasia"
              onChange={change}
              value={searchDocument?.fantasy_name}
              disabled={
                searchDocument && Object.keys(searchDocument).length > 0
              }
              required
            />
            <InputMask
              name="phone_pj"
              label="Telefone"
              mask="(99) 9999-9999"
              maskChar=""
              onChange={change}
              value={searchDocument?.phone}
              disabled={
                searchDocument && Object.keys(searchDocument).length > 0
              }
              required
            />
          </Wrap>
        </>
      )}
    </Container>
  );
}
