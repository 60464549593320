import styled from 'styled-components';

export const MainPage = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 10px;
    }
  }
`;
export const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  .tooltipInput {
    position: absolute;
    left: 15%;

    > svg {
      color: #828282;
      font-size: 20px;
    }
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const LimiterInputWidth = styled.div`
  display: flex;
  margin-right: 16px;
  ${props => (props.w25 ? 'width: 24%;' : '')}
  ${props => (props.w50 ? 'width: 49%;' : '')}
  ${props => (props.marginNone ? 'margin: 0;' : '')}
  margin-bottom: ${({ marginBottom }) => (marginBottom ? [marginBottom] : '0')};

  &:nth-child(4) {
    margin-right: 0;
  }
`;

export const MainLoading = styled.div`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;
