import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  & > label {
    margin-left: 10px;
  }

  span {
    font-size: 14px;
    font-family: 'InterBold', sans-serif;
    color: #ff0000;
    margin: 4px 0;
  }

  span.danger {
    font-family: 'PoppinsSemiBold', sans-serif;
  }
`;

export const StyledInput = styled.input`
  ${({ size }) => css`
    width: 20px;
    height: ${size}px;
  `}
`;
