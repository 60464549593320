import React from 'react';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import { CloseIcon, Container } from './styles';

export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: 40,
  borderRadius: 10,
  top: 0,
  left: 0,
  minHeight: 150,
  width: '100%',
  height: 'calc(100vh - 100px)',
  maxWidth: 'calc(100vw - 200px)',
  border: 'none',
};

const VideoPopUp = ({ open, url, toggleModal }) => (
  <Modal
    onRequestClose={toggleModal}
    isOpen={open}
    style={{
      overlay: overlayStyle,
      content: contentStyle,
    }}
  >
    <Container>
      <CloseIcon onClick={toggleModal} />
      <ReactPlayer
        url={url}
        controls
        width="100%"
        height="calc(100vh - 100px)"
      />
    </Container>
  </Modal>
);

export default VideoPopUp;
