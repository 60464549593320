import omitEmpty from 'omit-empty';

const buildQueryFilters = searchData => {
  const arrayFilters = omitEmpty(
    Object.entries(searchData).map(([searchKey, searchValue]) => {
      const { value, filterName } = searchValue;

      if (!value) return '';

      return `${filterName || searchKey}=${value}`;
    }),
  );

  return arrayFilters ? `&${arrayFilters.join('&')}` : '';
};

export default buildQueryFilters;
