import React, { useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Modal from 'react-modal';

import { BsFillEyeFill } from 'react-icons/bs';

import {
  ModalContent,
  Wrap,
  overlayStyle,
  contentStyle,
  ModalButton,
  ChoiceButtons,
} from './styles';

import { PageContainer, TableHeader } from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';

import Button from '~/components/Button';

import { NavBarHeader } from '~/components/Usuario';
import { showModalMessage } from '~/services/notification';

import ApiClient from '~/utils/ApiClient';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export default function AprovacaoObras() {
  const history = useHistory();
  const { state: worksForApprove } = useLocation();

  const [openModal, setOpenModal] = useState(false);
  const [infos, setInfos] = useState(false);
  const [loadingTrue, setLoadingTrue] = useState(false);
  const [loadingFalse, setLoadingFalse] = useState(false);

  const handleApplyApprove = useCallback(
    async approve => {
      try {
        await ApiClient.doRequest({
          uri: `work-user-approved/${infos?.uuid}`,
          method: ApiClient.PATCH,
          body: { approved: approve },
          expectedStatus: 201,
        });

        await showModalMessage({
          type: 'success',
          text: 'Enviado com sucesso!',
        });

        setOpenModal(false);

        setTimeout(() => {
          history.goBack();
        }, 2000);
      } catch (err) {
        await showModalMessage({
          type: 'error',
          text: 'Erro ao enviar dados para aprovação!',
        });
      } finally {
        setLoadingTrue(false);
        setLoadingFalse(false);
      }
    },
    [infos, history],
  );

  function handleViewUser(data) {
    setInfos(data);
    setOpenModal(true);
  }

  return (
    <>
      <Header>
        <LogoIcon />
        <NavBarHeader />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Acompanhamento de pedidos de acesso</h1>
            <span>Home | Obras | Acompanhamento</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      <PageContainer>
        <PageContent>
          <WhiteBox column aCenter>
            <table style={{ width: '95%' }}>
              <TableHeader style={{ justifyContent: 'space-evenly' }}>
                <tr>
                  <th style={{ textAlign: 'center' }}>Nome/Nome fantasia</th>
                  <th style={{ textAlign: 'center' }}>CPF/CNPJ</th>
                  <th style={{ textAlign: 'center' }}>Telefone</th>
                  <th style={{ textAlign: 'center' }}>Ação</th>
                </tr>
              </TableHeader>
              <tbody style={{ width: '95%' }}>
                {worksForApprove &&
                  worksForApprove.map(
                    item =>
                      item.approved === null && (
                        <tr>
                          <td style={{ textAlign: 'center' }}>
                            {item?.user?.user_personal_data?.name ||
                              item?.branch?.fantasy_name}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {item?.user?.user_personal_data?.cpf ||
                              item?.branch?.cnpj}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {item?.user?.user_personal_data?.phone_mobile ||
                              item?.branch?.phone}
                          </td>
                          <td
                            className="buttons"
                            style={{
                              textAlign: 'unset',
                              justifyContent: 'space-evenly',
                              display: 'flex',
                            }}
                          >
                            {worksForApprove && (
                              <span
                                tooltip="Solicitar acesso!"
                                style={{ cursor: 'pointer' }}
                              >
                                <div>
                                  <BsFillEyeFill
                                    color="#4f4f4f"
                                    size={24}
                                    onClick={() => handleViewUser(item)}
                                  />
                                </div>
                              </span>
                            )}
                          </td>
                          {/* <Documents documents={data.documents} uuid={data.uuid} /> */}
                        </tr>
                      ),
                  )}
              </tbody>
            </table>
          </WhiteBox>
        </PageContent>
      </PageContainer>

      <Modal
        ariaHideApp={false}
        onRequestClose={() => setOpenModal(false)}
        isOpen={openModal}
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <ModalButton onClick={() => setOpenModal(false)}>X</ModalButton>
        <ModalContent>
          <h1 style={{ marginBottom: 10 }}>Aprovar acesso</h1>
          {infos?.user && (
            <>
              <Wrap>
                <div>
                  <span>CPF:</span>
                  <p>{infos?.user?.user_personal_data?.cpf}</p>
                </div>
                <div>
                  <span>RG:</span>
                  <p>{infos?.user?.user_personal_data?.rg}</p>
                </div>
              </Wrap>
              <Wrap>
                <div>
                  <span>Nome:</span>
                  <p>{infos?.user?.user_personal_data?.name}</p>
                </div>
                <div>
                  <span>Telefone:</span>
                  <p>{infos?.user?.user_personal_data?.phone_mobile}</p>
                </div>
              </Wrap>
            </>
          )}
          {infos?.branch && (
            <>
              <Wrap>
                <div>
                  <span>CNPJ:</span>
                  <p>{infos?.branch?.cnpj}</p>
                </div>
                <div>
                  <span>Telefone:</span>
                  <p>{infos?.branch?.phone}</p>
                </div>
              </Wrap>
              <Wrap>
                <div>
                  <span>Nome da compania:</span>
                  <p>{infos?.branch?.company_name}</p>
                </div>
                <div>
                  <span>Nome fantasia:</span>
                  <p>{infos?.branch?.fantasy_name}</p>
                </div>
              </Wrap>
            </>
          )}
          <ChoiceButtons>
            <Button
              btType="#4f4f4f"
              action={() => {
                handleApplyApprove(false);
                setLoadingFalse(true);
              }}
              label="Não"
              full
              loading={loadingFalse}
              width="170px"
            />
            <Button
              btType="#26A2B1"
              action={() => {
                handleApplyApprove(true);
                setLoadingTrue(true);
              }}
              label="Sim"
              full
              loading={loadingTrue}
              width="170px"
            />
          </ChoiceButtons>
        </ModalContent>
      </Modal>

      <PageFooter>
      <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
