import React from 'react';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { gregorian_pt_br } from '~/config/localeMultiPickerDate';
import Input from '../Input';

const CustomInput = ({
  name,
  label,
  value,
  openCalendar,
  handleValueChange,
  required,
}) => (
  <Input
    label={label}
    name={name}
    value={
      value.length > 0
        ? `${value.length} data(s) selecionada(s)`
        : 'Clique para selecionar...'
    }
    onChange={handleValueChange}
    onFocus={openCalendar}
    required={required}
    readOnly
  />
);

const MultiDateInput = ({ name, label, onChange, acceptFuture, ...rest }) => (
  <DatePicker
    maxDate={!acceptFuture && new Date()}
    locale={gregorian_pt_br}
    format="DD/MM/YYYY"
    plugins={[<DatePanel />]}
    onChange={onChange}
    render={<CustomInput name={name} label={label} required={rest.required} />}
    {...rest}
  />
);

export default MultiDateInput;
