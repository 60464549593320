/* eslint-disable no-underscore-dangle */
import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    request: ["params"],
    success: ["data", "pagination"],
    failure: ["error"],
    showRequest: ["params"],
    showSuccess: ["data"],
    showFailure: ["error"],
    reset: [],
  },
  { prefix: "FILMAGEM_PUBLICA_LIST_" }
);

export const FilmagemPublicaListTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = {
  data: [],
  pagination: null,
  loading: false,
  error: null,
};

/* Reducers */

export const _request = (state) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

export const _success = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
    pagination: { $set: action.pagination },
  });

export const _failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

// show
export const _showRequest = (state) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

export const _showSuccess = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

export const _showFailure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

export const _reset = () => INITIAL_STATE;

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: _request,
  [Types.SUCCESS]: _success,
  [Types.FAILURE]: _failure,
  [Types.SHOW_REQUEST]: _showRequest,
  [Types.SHOW_SUCCESS]: _showSuccess,
  [Types.SHOW_FAILURE]: _showFailure,
  [Types.RESET]: _reset,
});
