import React from 'react';
// import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { BsFillEyeFill } from 'react-icons/bs';
import { FaPen } from 'react-icons/fa';
import { VscChromeClose } from 'react-icons/vsc';




// import { Modal } from "~/components/globals";

// import Check from "~/assets/images/icons/branch/check.png";
// import Delete from "~/assets/images/icons/branch/delete.png";



function ListaPageRelatorioLogin({ data }) {
  // const [open, setOpen] = useState(false);

  // const handleCloseModal = useCallback(() => {
  //   setOpen(false);
  // }, []);
  return (
    <>
      <tr>
        <td>{data && data.user?.user_personal_data?.name}</td>
        <td>{data && data?.date}</td>
        <td>{data && data?.time}</td>
        <td>{data && data?.ip}</td>
      </tr>
      {/* <Modal
        openModal={open}
        closeModal={handleCloseModal}
        inputs={FormularioHistory}
      /> */}
    </>
  );
}

export default withRouter(ListaPageRelatorioLogin);
