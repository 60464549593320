import styled from "styled-components";

export const CollapseContents = styled.div`
  width: 100%;

  .accordion {
    background: transparent !important;
    border: none !important;
  }
  .accordion__item {
    border-radius: 10px;
    background: #f2f2f2;
    margin-bottom: 10px;
  }

  p {
    color: #fff;
    font-family: InterRegular;
    font-size: 16px;
    line-height: 22px;
  }

  .accordion__button {
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    color: #828282;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    font-family: InterRegular;
  }

  .accordion__button:before {
    transform: rotate(225deg) !important;
  }
  .accordion__button[aria-expanded="true"] {
    background-color: #26a2b1 !important;
    color: #fff;
    transition: width 1s;
  }
  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(45deg) !important;
  }

  /* .accordion__panel {
    transition: height 3s;
  } */
`;
