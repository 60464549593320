import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AiFillExclamationCircle } from 'react-icons/ai';

import { PageContainer } from '~/styles/components';

import { NavBarHeader, LocacaoCard } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap } from './styles';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import { getSubdomainName } from '~/utils/MultiTenancyUtil';
import clientResources from '~/data/clientResources';

export default function SolicitacaoLocacao() {
  const { state: dadoState } = useLocation();

  const locacaoCards = {
    spcine: [
      {
        label:
          'Filmagens (ou apoio) em ruas/calçadas/vias/viadutos/passarelas/túneis/correlatos',
        text: 'São solicitações de filmagens e/ou apoio à filmagem que realizem interferências em calçadas, vias, pontes, túneis, pontos de ônibus, praças, passarelas, calçadões, viadutos, largos, escadarias, alamedas, ligações, alças, estradas municipais devem ser solicitados à São Paulo Film Commission. Podem ser de diversos tipos: - Ocupação parcial da calçada - Ocupação total da calçada - Ocupação de faixa junto ao meio-fio - Bloqueio intermitente de alguma (s) faixa(s) - Bloqueio intermitente de todas as faixas - Bloqueio total da via Podendo ser para diversos fins: - Para cena - Para montagem de estrutura de apoio - Para canalização de pedestres - Para captação de áudio - Para campo de câmera Todas as solicitações de utilização de vias públicas municipais para filmagens e/ou apoio às filmagens devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-utilizacao-via',
      },
      {
        label: 'Reservas de Vagas para Filmagens Internas',
        text: 'Reserva de Vagas são trechos da via pública reservados junto ao meio-fio pelas produções mediante autorização prévia da SPFilm, cumprindo diferentes tipos de funções de apoio às filmagens que ocorrem em locações públicas, privadas, internas ou externas. Devem ser solicitadas nos casos em que a produção necessita garantir a disponibilidade de vagas, além de permitir a utilização de materiais de apoio e condições de estacionamento distintas da regulamentação usual do local, conforme descrito na autorização. Todas as Reservas de Vagas em espaços públicos municipais devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-reserva-vaga',
      },
      {
        label: 'Reservas de Vagas Vinculado',
        text: '',
        route: '/reservas-vagas-vinculado',
      },
      {
        label: 'Acesso ou Permanência de Veículos - Calçadões',
        text: 'Calçadões são vias pavimentadas de maneira a limitar o acesso e torná-las destinadas somente a livre circulação de pedestres e veículos previamente autorizados. É necessário que as produções preencham esse formulário para acesso de veículos para carga e descarga, permanência de gerador e/ou de veículo cênico. Não é necessária autorização para carga e descarga de veículos com menos de 7 toneladas de peso bruto total nos períodos abaixo: Dias úteis - das 20h às 07h Das 14h00 de sábado até 07h00 de segunda-feira Para a permanência de veículos, ou acesso em outros horários, a produção deve solicitar à São Paulo Film Commission.',
        route: '/locacao-acesso-calcadoes',
      },
      {
        label: 'Filmagens no Autódromo de Interlagos',
        text: 'Autódromo José Carlos Pace, mais conhecido como Autódromo de Interlagos, é um dos maiores espaços públicos da cidade. O equipamento é gerido pela São Paulo Turismo (“SPTuris”) e possui diversos locais que podem ser utilizados de cenário para as produções, como pista principal de corrida, kartódromo, parque perimetral, 23 boxes, sala de imprensa, salão nobre, quadras esportivas e Paddock.',
        route: '/locacao-autodromo',
      },
      {
        label: 'Trajeto de Câmera Car',
        text: 'Câmera car são veículos adaptados com infraestrutura específica para filmagens em movimento. As autorizações para filmagens de trajeto(s) de Câmera Car são referentes somente a utilização do espaço público municipal utilizado, cabendo à produção garantir que a circulação do veículos esteja de acordo as regulamentações do Código de Trânsito Brasileiro e do DETRAN (Departamento Estadual de Trânsito). A autorização deve ser solicitada em casos em que houver interferência no fluxo normal da via, como por exemplo com comboios/diminuição da velocidade de faixa(s) ou estruturas do veículo ultrapassem as dimensões máximas permitidas na via e/ou das faixas de circulação;',
        route: '/locacao-camera-car',
      },
      {
        label: 'Filmagens em Cemitérios',
        text: 'Cemitérios Municipais são as 22 necrópoles públicas do município que contam com locais destinados ao sepultamento, capelas e salas de velório. Todas as filmagens em Cemitérios Públicos Municipais devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-cemiterio',
      },
      {
        label:
          'Filmagens em Edifícios-Sede da Prefeitura (Ed. Matarazzo, Sampaio Moreira, etc)',
        text: 'Edifícios da Prefeitura são os espaços administrativos que são sede ou fazem parte dos órgãos e Secretarias vinculados à Prefeitura de São Paulo. Alguns desses espaços são: Edifício Sampaio Moreira (Sede da Secretaria Municipal de Cultura), Edifício Othon (Sede da Secretaria Municipal da Fazenda), Centro de Estudos Jurídicos (CEJUR), sendo que a maioria se concentra na região central da cidade. Todas as filmagens em Edifícios da Prefeitura devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-edificio',
      },
      {
        label:
          'Filmagens em Espaços de Cultura (Centros Culturais, Casas de Cultura, Bibliotecas, Arquivo Histórico, Museu da Cidade, etc)',
        text: 'Os Espaços Municipais de Cultura são locais criados para abrigar, fomentar e incentivar as mais diversas manifestações artísticas e culturais. A Cidade de São Paulo conta com 18 Casas de Cultura, 106 bibliotecas, entre outros equipamentos culturais espalhados pelo município e geridos pela Secretaria Municipal de Cultura. Todas as filmagens em Espaços Municipais de Cultura devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-espacos-cultura',
      },
      {
        label:
          'Filmagem em Espaços da Secretaria de Assistência e Desenvolvimento Social (Centro de Acolhida, etc)',
        text: 'Espaços da Secretaria Municipal de Assistência e Desenvolvimento Social (SMADS) são voltados ao atendimento e operação dos serviços sociais do município de São Paulo. Alguns desses espaços são: Centro de Acolhida, Núcleo de Convivência para Idosos, Centro para População em Situação de Rua, entre outros. Todas as filmagens em Espaços da Secretaria Municipal de Assistência e Desenvolvimento Social devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-espacos-smad',
      },
      {
        label: 'Filmagens em Equipamentos da Educação (CEU, EMEI, EMEF etc)',
        text: 'Espaços de Ensino Municipais são locais voltados para proporcionar a formação comum indispensável para o exercício da cidadania da população paulistana de diferentes níveis etários e de escolaridade. Fazem parte do sistema educacional locais como Escolas Municipais de Educação Infantil (EMEI), Escolas Municipais de Educação Fundamental (EMEF), Centros Educacionais Unificados (CEUs), Centros Integrados de Educação de Jovens e Adultos (CIEJA), entre outros.  Todas as filmagens em Espaços de Ensino Municipais devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-equipamento-educacao',
      },
      {
        label:
          'Filmagens em Equipamentos Esportivos (Clubes Escola, Centro de Esportes, etc)',
        text: 'Espaços Esportivos são as estruturas públicas que oferecem diversas atividades esportivas para a saúde, bem-estar, lazer da população de todas as regiões de São Paulo, bem como para treinamento e desenvolvimento de atletas. Entre eles estão estádios municipais, centros esportivos, clubes escolas, balneários e mini balneários, todos administrados pela Secretaria Municipal de Esportes e Lazer (SEME). Todas as filmagens no Autódromo devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-filmagem-esportivo',
      },
      {
        label: 'Filmagens em Equipamentos da Saúde (Hospitais, UBSs, etc)',
        text: 'Espaços Municipais de Saúde são os locais geridos pela Secretaria Municipal de Saúde que tem como objetivo a promoção e proteção da saúde à população. Fazem parte do complexo do sistema de saúde locais como Hospitais Municipais, Unidades de Pronto Atendimento, Centros de Atenção Psicossocial, entre outros. Todas as filmagens em Espaço Municipais de Saúde devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-equipamentos-saude',
      },
      {
        label: 'Filmagens em Feiras Livres',
        text: "Feiras Livres Municipais são locais de venda a céu aberto de alimentos 'in natura' de origem artesanal, naturais e agrícola. Possuem datas e locais fixos em vias, praças e ruas e atualmente somam mais de 800 no Município. Todas as filmagens em feiras livres municipais devem passar pelo processo de autorização da São Paulo Film Commission, porém, é recomendável que as produções solicitem autorização de filmagem também aos feirantes/comerciantes locais.",
        route: '/locacao-feira-livre',
      },
      {
        label: 'Galerias',
        text: 'Galerias públicas municipais são passagens com funções artístico-culturais que conectam dois ou mais espaços e que podem oferecer prestação de serviços e/ou comércio em suas dependências. Atualmente, a São Paulo Film Commission é responsável por autorizar filmagens nas Galerias: Olido, Prestes Maia e Formosa. Todas as filmagens em Galerias Públicas Municipais devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-galeria',
      },
      {
        label: 'Filmagens em Mercados e Sacolões',
        text: 'Sacolões e Mercados Municipais são centros de abastecimento especializados na comercialização de produtos alimentícios e utilitários para a população local. Contam com diversas infraestruturas comuns, e podem ter parte de seus espaços cedidos por contrato para comércio, lojas, restaurantes e boxes. A São Paulo Film Commission é responsável somente pela autorização de filmagens que sejam ou interfiram nos espaços comuns de gestão direta da Prefeitura; para o uso unicamente dos espaços concedidos, a produção deve solicitar autorização diretamente com os responsáveis.',
        route: '/locacao-filmagem-mercado',
      },
      {
        label: 'Filmagens em Parques',
        text: 'Parques Municipais são unidades de proteção da biodiversidade e promoção do lazer e prática esportiva dentro do perímetro urbano, normalmente circunscritos por gradis ou proteções, e com sistema próprio de administração por meio da Secretaria Municipal do Verde e Meio Ambiente (SVMA). Atualmente existem 108 Parques de administração da Prefeitura de São Paulo, sendo 1 deles localizado no município de Cotia (Parque Cemucam). Todas as filmagens em Parques públicos municipais devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-filmagem-parque',
      },
      {
        label: 'Filmagens em Planetários',
        text: 'Planetários Públicos Municipais são espaços projetados com o objetivo de estudos, observações e simulações de corpos celestes. Atualmente existe somente um planetário de administração pública municipal, que é o Planetário Prof. Acácio Riberi (localizado no Parque do Carmo).',
        route: '/locacao-planetario',
      },
      {
        label: 'Praças',
        text: 'As Praças públicas municipais são espaços públicos urbanos livres que promovem lazer, convivência e recreação para a população. Diferentemente dos parques, são administradas pelas próprias Subprefeituras e, em sua maioria, não possuem restrições físicas ou de funcionamento para seu acesso. Todas as filmagens em Praças públicas municipais devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-praca-publica',
      },
      {
        label: 'Projeções em Logradouro Público com Estrutura Interna',
        text: 'Projeções vísiveis a partir de logradouro público com estrutura interna são intervenções visuais que ocorrem sem montagem de estruturas em locais públicos, mas que os afetam diretamente pela inserção de elementos na paisagem urbana. Todas as Projeções visíveis a partir de logradouros públicos municipais devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-projecao',
      },
      {
        label: 'Teatros',
        text: '',
        route: '/locacao-teatro',
      },
      {
        label: 'Filmagens em Terminais de Ônibus',
        text: 'Terminais Urbanos Municipais são locais onde as linhas de ônibus têm seu ponto de chegada e partida. Em São Paulo há 32 terminais municipais onde há integração com estações de Metrô, trens da CPTM e ônibus intermunicipais. A São Paulo Film Commission realiza o processo de autorização das áreas comuns internas e externas dos Terminais. Solicitações para filmagens em locais de integração e/ou dentro de frota devem ser tratadas diretamente com os responsáveis.',
        route: '/locacao-terminal',
      },
      {
        label: 'Outros Espaços',
        text: 'Outros espaços são locais públicos municipais que fazem parte da infraestrutura da Prefeitura e não se enquadram em outras categorias de locações mas também integram o campo de atuação da São Paulo Film Commission. Podem ser aterros, pátios de serviços, piscinões, entre outros. Todas as filmagens em Outros Espaços Municipais devem passar pelo processo de autorização da São Paulo Film Commission.',
        route: '/locacao-outros-espacos',
      },
    ],
    ilhacine_homolog: [
      {
        label: 'Filmagens (ou apoio) em ruas/calçadas/vias/correlatos',
        text: 'São solicitações de filmagens e/ou apoio à filmagem que realizem interferências em calçadas, vias, pontos de ônibus, calçadões, largos, escadarias, alamedas, ligações, alças, estradas municipais devem ser solicitados à Ilhabela Film Commission. Podem ser de diversos tipos: - Ocupação parcial da calçada - Ocupação total da calçada - Ocupação de faixa junto ao meio-fio - Bloqueio intermitente de alguma (s) faixa(s) - Bloqueio intermitente de todas as faixas - Bloqueio total da via Podendo ser para diversos fins: - Para cena - Para montagem de estrutura de apoio - Para canalização de pedestres - Para captação de áudio - Para campo de câmera. Todas as solicitações de utilização de vias públicas municipais para filmagens e/ou apoio às filmagens devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-utilizacao-via',
      },
      // {
      //   label: 'Reservas de Vagas para Filmagens Internas',
      //   text: 'Reserva de Vagas são trechos da via pública reservados junto ao meio-fio pelas produções mediante autorização prévia da Ilhabela Film Commission, cumprindo diferentes tipos de funções de apoio às filmagens que ocorrem em locações públicas, privadas, internas ou externas. Devem ser solicitadas nos casos em que a produção necessita garantir a disponibilidade de vagas, além de permitir a utilização de materiais de apoio e condições de estacionamento distintas da regulamentação usual do local, conforme descrito na autorização. Todas as Reservas de Vagas em espaços públicos municipais devem passar pelo processo de autorização da Ilhabela Film Commission.',
      //   route: '/locacao-reserva-vaga',
      // },
      {
        label: 'Reservas de Vagas Vinculado',
        text: '',
        route: '/reservas-vagas-vinculado',
      },
      // {
      //   label: 'Trajeto de Câmera Car',
      //   text: 'Câmera car são veículos adaptados com infraestrutura específica para filmagens em movimento. As autorizações para filmagens de trajeto(s) de Câmera Car são referentes somente a utilização do espaço público municipal utilizado, cabendo à produção garantir que a circulação do veículos esteja de acordo as regulamentações do Código de Trânsito Brasileiro e do DETRAN (Departamento Estadual de Trânsito). A autorização deve ser solicitada em casos em que houver interferência no fluxo normal da via, como por exemplo com comboios/diminuição da velocidade de faixa(s) ou estruturas do veículo ultrapassem as dimensões máximas permitidas na via e/ou das faixas de circulação;',
      //   route: '/locacao-camera-car',
      // },
      {
        label: 'Filmagens em Cemitérios',
        text: 'Ilhabela possui atualmente 1 (um) cemitério público municipal, sendo que todas as filmagens no Cemitério Municipal devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-cemiterio',
      },
      // {
      //   label: 'Filmagens em Edifícios-Sede',
      //   text: 'Espaços-sede da Prefeitura são os espaços administrativos que são sede ou fazem parte dos órgãos e Secretarias vinculados à Prefeitura de Ilhabela. Todas as filmagens em Espaços-sede da Prefeitura devem passar pelo processo de autorização da Ilhabela Film Commission.',
      //   route: '/locacao-edificio',
      // },
      {
        label: 'Filmagens em Espaços de Cultura',
        text: 'Os Espaços Municipais de Cultura são locais criados para abrigar, fomentar e incentivar as mais diversas manifestações artísticas e culturais. Todas as filmagens em Espaços Municipais de Cultura devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-espacos-cultura',
      },
      {
        label:
          'Secretaria Municipal de Desenvolvimento e Inclusão Social',
        text: 'Espaços da Secretaria Municipal de Desenvolvimento e Inclusão Social são voltados ao atendimento e operação dos serviços sociais do município de Ilhabela. Todas as filmagens nesses espaços devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-espacos-smad',
      },
      {
        label: 'Filmagens em Equipamentos da Educação',
        text: 'Espaços de Ensino Municipais são locais voltados para proporcionar a formação comum indispensável para o exercício da cidadania da população. Todas as filmagens em Espaços de Ensino Municipais devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-equipamento-educacao',
      },
      {
        label: 'Filmagens em Equipamentos Esportivos',
        text: 'Espaços Esportivos são as estruturas públicas que oferecem diversas atividades esportivas para a saúde, bem-estar, lazer da população. Todas as filmagens nesses espaços devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-filmagem-esportivo',
      },
      {
        label: 'Filmagens em Equipamentos da Saúde',
        text: 'Espaços Municipais de Saúde são os locais geridos pela Secretaria Municipal de Saúde que tem como objetivo a promoção e proteção da saúde à população. Fazem parte do complexo do sistema de saúde locais como Hospitais Municipais, Unidades de Pronto Atendimento, entre outros. Todas as filmagens em Espaço  Municipais de Saúde devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-equipamentos-saude',
      },
      {
        label: 'Filmagens em Feiras Livres',
        text: "Feiras Livres Municipais são locais de venda a céu aberto de alimentos 'in natura' de origem artesanal, naturais e agrícola. Todas as filmagens em feiras livres municipais devem passar pelo processo de autorização da Ilhabela Film Commission, porém, é recomendável que as produções solicitem autorização de  filmagem também aos feirantes/comerciantes locais ",
        route: '/locacao-feira-livre',
      },
      {
        label: 'Filmagens em Parques',
        text: 'Parques Municipais são unidades de proteção da biodiversidade e promoção do lazer e prática esportiva. Atualmente existem 2 Parques de administração da Prefeitura de Ilhabela. Todas as filmagens em Parques públicos municipais devem passar pelo processo de autorização da Ilhabela Film Commission, e para filmagens no Parque Estadual de Ilhabela (PEIb), a produção deverá entrar em contato diretamente com a Fundação Florestal.',
        route: '/locacao-filmagem-parque',
      },
      {
        label: 'Praças',
        text: 'As Praças públicas municipais são espaços públicos urbanos livres que promovem lazer, convivência e recreação para a população. Todas as filmagens em Praças públicas municipais devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-praca-publica',
      },
      {
        label: 'Comunidades Tradicionais',
        text: '',
        route: '/locacao-com-tradicional',
      },
      {
        label: 'Paisagens Naturais',
        text: '',
        route: '/locacao-paisagem-natural',
      },
      {
        label: 'Praias',
        text: '',
        route: '/locacao-praia',
      },
      {
        label: 'Outros Espaços',
        text: 'Outros espaços são locais públicos municipais que fazem parte da infraestrutura da Prefeitura e não se enquadram em outras categorias de locações mas também integram o campo de atuação da Ilhabela Film Commission. Podem ser aterros, pátios de serviços, piscinões, entre outros. Todas as filmagens em Outros Espaços Municipais devem passar pelo processo de autorização da Ilhabela Film Commission.',
        route: '/locacao-outros-espacos',
      },
    ],
  };

  return (
    <>
      <Header>
        <LogoIcon />
        <NavBarHeader />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Nova Locação</h1>
            <span>Home | Obras | {dadoState?.nome_projeto} | Nova Locação</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      <PageContainer>
        <PageContent>
          <WhiteBox column jCenter aCenter marginBottom="30px">
            <BoxHeader>
              <span className="boxText">Locações</span>
              <span tooltip={clientResources.labels.tooltipNovaLocacao}>
                <AiFillExclamationCircle />
              </span>
            </BoxHeader>

            <Wrap style={{ flexDirection: 'column' }}>
              {(
                locacaoCards[getSubdomainName()] || locacaoCards['spcine']
              )?.map(locacaoCard => (
                <LocacaoCard
                  label={locacaoCard.label}
                  text={locacaoCard.text}
                  route={locacaoCard.route}
                  workUuid={dadoState?.uuid}
                  nomeProjeto={dadoState?.nome_projeto}
                />
              ))}
            </Wrap>
          </WhiteBox>
        </PageContent>
      </PageContainer>

      <PageFooter>
        <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
