import { useState, useEffect } from 'react';

import ApiClient from '~/utils/ApiClient';

import { showToastMessage } from '~/services/notification';

function useList({ url }) {
  const [result, setResult] = useState([]);

  try {
    useEffect(() => {
      async function fetchData() {
        await ApiClient.doRequest({
          uri: url,
          method: ApiClient.GET,
          expectedStatus: 200,
        })
          .then(response => setResult(response.data.data))
          .catch(err => setResult([]));
      }
      return fetchData();
    }, [url]);
  } catch (err) {
    showToastMessage({
      type: 'error',
      text: 'Erro ao buscar listagem!',
    });
  }

  return result;
}

export { useList };
