import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import ReactInputMask from 'react-input-mask';
import { Container } from './styles';

export default function InputMask({ name, label, required, icon, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label htmlFor={fieldName}>
        {label}
        {required && <span>*</span>}
      </label>
      <ReactInputMask
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        autoComplete="nope"
        {...rest}
      />
      {icon && <button type="submit">{icon}</button>}
      {error && <span className="error">{error}</span>}
    </Container>
  );
}
