import styled, { css } from 'styled-components';

export const Option = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PoppinsSemiBold', sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #828282;
  padding: 16px 32px;
  background-color: ${({ bgTheme }) => bgTheme || 'transparent'};
  transition: 300ms ease;
  position: relative;
  min-width: max-content;
  border-radius: 10px 10px 0 0;
  box-shadow: -1px -2.4px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: -1px -2.4px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px -2.4px 5px rgba(0, 0, 0, 0.15);

  ${({ active, bgTheme }) =>
    active &&
    css`
      background-color: ${bgTheme || '#ffffff'};
      color: ${'#323c47'};
    `};

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        background-color: ${({ bgTheme }) => bgTheme || '#ffffff'};
        opacity: 0.7;
      }
    `}

  @media only screen and (max-width: 1024px) {
    margin: 0 0 16px 0;
    width: 100%;

    &:after {
      display: none;
    }
  }
`;
