import React, { useContext } from 'react';
import { ThemeContext } from '~/styles/ThemeProvider';
import { WhiteBox as StyledWhiteBox } from './styles';

const WhiteBox = ({ children, ...rest }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <StyledWhiteBox bgTheme={theme.background_page_color} {...rest}>
      {children}
    </StyledWhiteBox>
  );
};

export default WhiteBox;
