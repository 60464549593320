import { format } from 'date-fns';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import Avatar from '~/assets/images/catalogo/avatar-profissional-servico.png';
import { Detail, Label } from './styles';

function HistoricoObra({ data, onDelete }) {
  return (
    <>
      <div style={{ width: '100%' }}>
        <div
          style={{
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'space-between',
            width: '100%',
            display: 'flex',
            marginBottom: 10,
            flexWrap: 'wrap',
          }}
        >
          <div>
            <img
              src={
                data.user.files?.find(({ name }) => name === 'foto_user')
                  ?.url || Avatar
              }
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
              }}
              alt="avatar"
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '85%',
              marginBottom: 5,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Label>
                  {data?.user?.user_personal_data?.name ||
                    'Usuário desconhecido'}
                </Label>
                <button
                  type="button"
                  title="Excluir documento..."
                  onClick={() =>
                    onDelete(
                      data.uuid,
                      data.file?.map(({ uuid }) => ({ uuid })),
                    )
                  }
                >
                  <FaTrash color="#828282" size={20} />
                </button>
              </div>
              <Label>Comentário: </Label>
              <Detail>{data.comments || data.comment}</Detail>

              {Object.values(data.file || []).length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 10,
                  }}
                >
                  <Label
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    Arquivo(s)
                  </Label>
                  {data.file?.map(fi => (
                    <a
                      href={fi.url}
                      download
                      target="_blank"
                      rel="noreferrer"
                      style={{ width: 'fit-content' }}
                    >
                      <span
                        style={{
                          color: '#26a2b1',
                        }}
                      >
                        {data.tipo_doc}
                      </span>
                    </a>
                  ))}
                </div>
              )}
              <div style={{ display: 'flex', marginTop: 10 }}>
                <Label>Criado em: </Label>
                <Detail style={{ marginLeft: 5 }}>
                  {data &&
                    format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm')}
                </Detail>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(HistoricoObra);
