import React, { useContext, useState, useRef, useEffect } from 'react';
import OnOutsiceClick from 'react-outclick';
import Flags from 'country-flag-icons/react/3x2';
import { ThemeContext } from '~/styles/ThemeProvider';

const FlagIcon = () => {
   const { theme } = useContext(ThemeContext); 
   const [open, setOpen] = useState(false);
   const [language, setLanguage] = useState('');
   const [languageList, setLanguageList] = useState(['BR', 'EN', 'ES']);
   const dropdownRef = useRef(null);

   useEffect(() => {
      const getFlag = localStorage.getItem("@language");
      if(getFlag){
        setLanguage(getFlag);
      }
      else {
        localStorage.setItem("@language", 'BR' )
        setLanguage('BR')
      }
      setLanguageList(['BR', 'EN', 'ES']);

    }, []);

   useEffect(() => {

        localStorage.setItem("@language", language )

        setLanguageList(['BR', 'EN', 'ES']);
        const newList = languageList.filter(lang => lang !== language);
        setLanguageList(newList)

    }, [language])

    const changeLanguage = (lg) => {
      setLanguageList(['BR', 'EN', 'ES']);
      setLanguage(lg)
      window.location.reload();
    }
  return (
    <nav className="navbar" style={{ marginRight: '30px' }}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <button type="button" onClick={() => {setOpen(!open)}}>
              {language && language === "BR" &&(
                <Flags.BR width={30} color="#fff" />
                )}
              {language && language === "EN" &&(
                <Flags.US width={30} color="#fff" />
                )}
              {language && language === "ES" &&(
                <Flags.ES width={30} color="#fff" />
                )}
              </button>

              {open && (
                <OnOutsiceClick onOutsideClick={() => setOpen(false)}>
                  <div
                    className="dropdown"
                    ref={dropdownRef}
                    style={{
                      backgroundColor: theme.horizontal_bar_color,
                      maxWidth: 'fit-content',
                      transform: 'translateX(-75%)',
                      top: '70px'
                    }}
                  >

                  {languageList && languageList?.map(lang =>(
                      <div className="menu" style={{ marginBottom: '10px' }}>
                        <button type="button" onClick={() => {setOpen(!open); changeLanguage(lang);}}>
                          {lang && lang === 'EN' &&(
                              <Flags.US width={30} color="#fff" />
                          )}
                          {lang && lang === 'BR' &&(
                              <Flags.BR width={30} color="#fff" />
                          )}
                          {lang && lang === 'ES' &&(
                              <Flags.ES width={30} color="#fff" />
                          )}
                          
                        </button>
                      </div>
                  ))}
                  </div>
                </OnOutsiceClick>
              )}
            </li>
          </ul>
        </nav>
  );
};

export default FlagIcon;
