import React from 'react';
import { ImUserTie } from 'react-icons/im';
import { Icon, Container, Content } from './styles';

const ResponsaveisInfoIcon = ({ responsaveisExistentes }) => {
  if (Object.values(responsaveisExistentes).length <= 0) {
    return <></>;
  }

  return (
    <Icon type="button">
      <ImUserTie color="#828282" size={24} />
      <Container>
        <Content>
          <h3>Responsáveis cadastrados no CNPJ</h3>
          <table>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>E-mail Corporativo</th>
              <th>Cargo</th>
            </tr>
            {responsaveisExistentes?.map(resp => (
              <tr key={resp.key}>
                <td>{resp.name}</td>
                <td>{resp.cpf}</td>
                <td>{resp.corporate_email}</td>
                <td>{resp.cargo}</td>
              </tr>
            ))}
          </table>
        </Content>
      </Container>
    </Icon>
  );
};

export default ResponsaveisInfoIcon;
