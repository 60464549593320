/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';
import { isSafari } from 'react-device-detect';

import { AiFillExclamationCircle } from 'react-icons/ai';

import { Form } from '@unform/web';
import { FiLoader } from 'react-icons/fi';
import * as Yup from 'yup';

import omitEmpty from 'omit-empty';
import { showModalConfirm, showModalMessage } from '~/services/notification';

import {
  Input,
  Textarea,
  Select,
  ChoiceButton,
  File,
} from '~/components/Forms';

import Button from '~/components/Button';

import BuscaDocumento from '~/components/Forms/BuscaDocumento';

import { MainPage, ButtonBox, Wrap, MainLoading } from './styles';

import {
  PageContainer,
  LimiterInputWidth,
  GridContainer,
} from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  BoxHeader,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';

import { NavBarHeader } from '~/components/Usuario';

import BuscaCEP from '~/components/Forms/BuscaCEP';
import ApiClient from '~/utils/ApiClient';
import { useList } from '~/hooks/useFunction';
import { useCurrentUser } from '~/hooks/currentUser';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import { ResponsavelLegal } from './components/ResponsavelLegal';
import { ContatoResponsavel } from './components/ContatoResponsavel';
import validateDate from '~/functions/validateDate';
import { clearMasks } from '~/utils/functions/clearMasks';
import { RadioNoForm } from '~/components/Forms/RadioNoForm';
import clientResources from '~/data/clientResources';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  menuList: provided => ({
    ...provided,
    height: '125px',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function SolicitacaoNovaObra() {
  const history = useHistory();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});
  const [responsaveisLegal, setResponsaveisLegal] = useState([{}]);
  const [responsaveisComission, setResponsaveisComission] = useState([{}]);
  const [responsaveisDiretor, setResponsaveisDiretor] = useState([{}]);
  const [responsaveisCoordenacao, setResponsaveisCoordenacao] = useState([{}]);
  const [responsaveisProdExecutiva, setResponsaveisProdExecutiva] = useState([
    {},
  ]);

  // acesso atual
  const { user } = useCurrentUser();

  // refs
  const formRef = useRef();
  const oficioUnivRef = useRef(null);
  const cpfRef = useRef(null);
  const rgRef = useRef(null);
  const cnpjRef = useRef(null);
  const contratoSocialRef = useRef(null);
  const respLegalRef = useRef(null);

  // states
  const [coproducao, setCoproducao] = useState(false);
  const [service, setService] = useState(false);
  const [indie, setIndie] = useState(false);
  const [student, setStudent] = useState(false);
  const [programaIncentivo, setProgramaIncentivo] = useState(false);
  const [pessoaPFisica, setPessoaPFisica] = useState(false);
  const [enderecoNacional, setEnderecoNacional] = useState(true);
  const [pfUuid, setPfUuid] = useState(undefined);
  const [pjUuid, setPjUuid] = useState(undefined);

  // states files
  const [dataOficioUnivFile, setDataOficioUnivFile] = useState(null);
  const [dataCpfFile, setDataCpfFile] = useState(null);
  const [dataRgFile, setDataRgFile] = useState(null);
  const [dataCnpjFile, setDataCnpjFile] = useState(null);
  const [dataContratoSocialFile, setDataContratoSocialFile] = useState(null);
  const [dataRespLegalFile, setDataRespLegalFile] = useState(null);

  // states show fields
  const [showCanaisAbertos, setShowCanaisAbertos] = useState(null);
  const [showCanaisPagos, setShowCanaisPagos] = useState(null);
  const [showPlataformaStreaming, setShowPlataformaStreaming] = useState(null);
  const [showOutraVeiculacao, setShowOutraVeiculacao] = useState(null);

  // selectors
  const stateSelectOptions = useList({ url: 'state?paginate=false' }).map(
    item => ({
      value: item.uuid,
      label: item.name,
      abbr: item.abbr,
    }),
  );

  const countrySelectOptions = useList({ url: 'country?paginate=false' }).map(
    item => ({
      value: item.uuid,
      label: item.name,
      code: item.code,
    }),
  );

  const workTypeSelectOptions = useList({
    url: 'work-type?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const veiculacaoInicialList = useList({
    url: 'ld-veiculo-inicial?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisAbertosList = useList({
    url: 'ld-canais-aberto?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosFilmeList = useList({
    url: 'ld-canais-pagos-filme?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosInfantilList = useList({
    url: 'ld-canais-pagos-infantil?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosNoticiaList = useList({
    url: 'l-canais-pagos-noticia?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosDocumentarioList = useList({
    url: 'ld-canais-pagos-documentario?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosVariedadeList = useList({
    url: 'ld-canais-pagos-variedade?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosEsporteList = useList({
    url: 'ld-canais-pagos-esporte?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const streamingList = useList({ url: 'ld-streaming?paginate=false' })?.map(
    ({ uuid, name }) => ({ value: uuid, label: name }),
  );

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleMultiSelectChange(name, event) {
    setFormData(state => ({
      ...state,
      [name]: event ? event.map(x => x.value) : [],
    }));
  }

  async function handleFileChange(event) {
    event.preventDefault();

    const { name } = event.target;

    const file = event.target.files[0];

    if (!file) return;

    if (file.type !== 'application/pdf') {
      await showModalMessage({
        type: 'error',
        text: `Tipo '${file.type}' não é um tipo de formato de arquivo aceitável para envio de Documentos`,
      });
      return;
    }

    const base64 = await convertBase64(file);
    file.base64 = base64.split('base64,');

    if (name === 'oficio_univ_file') {
      setDataOficioUnivFile(file);
      setErrorsForm(state => ({
        ...state,
        oficioUnivFile: false,
      }));
    }

    if (name === 'cpf_file') {
      setDataCpfFile(file);
      setErrorsForm(state => ({
        ...state,
        cpfFile: false,
      }));
    }

    if (name === 'rg_file') {
      setDataRgFile(file);
      setErrorsForm(state => ({
        ...state,
        rgFile: false,
      }));
    }

    if (name === 'cnpj_file') {
      setDataCnpjFile(file);
      setErrorsForm(state => ({
        ...state,
        cnpjFile: false,
      }));
    }

    if (name === 'contrato_social_file') {
      setDataContratoSocialFile(file);
      setErrorsForm(state => ({
        ...state,
        contratoSocialFile: false,
      }));
    }

    if (name === 'resp_legal_file') {
      setDataRespLegalFile(file);
      setErrorsForm(state => ({
        ...state,
        respLegalFile: false,
      }));
    }

    setFormData(state => ({ ...state, [name]: base64.split('base64,')[1] }));
  }

  const handleValidationFields = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const defaultMsg = 'Campo obrigatório';

        const schema = Yup.object().shape({
          cpf_pf: Yup.string()
            .typeError(defaultMsg)
            .test(
              'cpf',
              defaultMsg,
              value =>
                !(pessoaPFisica === true && (!value || value.length <= 0)),
            ),
          name_pf: Yup.string()
            .typeError(defaultMsg)
            .test(
              'name',
              defaultMsg,
              value =>
                !(pessoaPFisica === true && (!value || value.length <= 0)),
            ),
          email_pf: Yup.string()
            .typeError(defaultMsg)
            .test(
              'email',
              defaultMsg,
              value =>
                !(pessoaPFisica === true && (!value || value.length <= 0)),
            ),
          phone_mobile_pf: Yup.string()
            .typeError(defaultMsg)
            .test(
              'phone_mobile',
              defaultMsg,
              value =>
                !(pessoaPFisica === true && (!value || value.length <= 0)),
            ),
          rg_pf: Yup.string()
            .typeError(defaultMsg)
            .test(
              'rg',
              defaultMsg,
              value =>
                !(pessoaPFisica === true && (!value || value.length <= 0)),
            ),
          birth_date_pf: Yup.string()
            .typeError(defaultMsg)
            .test(
              'birth_date',
              defaultMsg,
              value =>
                !(pessoaPFisica === true && (!value || value.length <= 0)),
            ),
          cnpj_pj: Yup.string()
            .typeError(defaultMsg)
            .test(
              'cnpj',
              defaultMsg,
              value =>
                !(
                  pessoaPFisica === false &&
                  (!value || value.length <= 0) &&
                  pjUuid === undefined
                ),
            ),
          rate_social_pj: Yup.string()
            .typeError(defaultMsg)
            .test(
              'rate_social',
              defaultMsg,
              value =>
                !(
                  pessoaPFisica === false &&
                  (!value || value.length <= 0) &&
                  pjUuid === undefined
                ),
            ),
          fantasy_name_pj: Yup.string()
            .typeError(defaultMsg)
            .test(
              'fantasy_name',
              defaultMsg,
              value =>
                !(
                  pessoaPFisica === false &&
                  (!value || value.length <= 0) &&
                  pjUuid === undefined
                ),
            ),
          phone_pj: Yup.string()
            .typeError(defaultMsg)
            .test(
              'phone',
              defaultMsg,
              value =>
                !(
                  pessoaPFisica === false &&
                  (!value || value.length <= 0) &&
                  pjUuid === undefined
                ),
            ),
          drt_uf: Yup.string()
            .typeError(defaultMsg)
            .test(
              'drt_uf',
              'Ao informar o DRT, é obrigatório informar sua UF',
              value =>
                !(
                  pessoaPFisica === true &&
                  (!value || value.length <= 0) &&
                  formData?.drt_numero?.length > 0
                ),
            ),
          cep: Yup.string()
            .typeError(defaultMsg)
            .test(
              'endereco-nacional',
              defaultMsg,
              value =>
                !(
                  (!value || value.length <= 0) &&
                  ((pessoaPFisica && pfUuid === undefined) ||
                    (!pessoaPFisica && pjUuid === undefined)) &&
                  enderecoNacional
                ),
            ),
          number: Yup.string()
            .typeError(defaultMsg)
            .test(
              'endereco-nacional',
              defaultMsg,
              value =>
                !(
                  (!value || value.length <= 0) &&
                  ((pessoaPFisica && pfUuid === undefined) ||
                    (!pessoaPFisica && pjUuid === undefined)) &&
                  enderecoNacional
                ),
            ),
          endereco_internacional: Yup.string()
            .typeError(defaultMsg)
            .test(
              'endereco-internacional',
              defaultMsg,
              value =>
                !(
                  (!value || value.length <= 0) &&
                  ((pessoaPFisica && pfUuid === undefined) ||
                    (!pessoaPFisica && pjUuid === undefined)) &&
                  !enderecoNacional
                ),
            ),
          titulo_obra: Yup.string().typeError(defaultMsg).required(defaultMsg),
          formato_obra_uuid: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          state_uuid: Yup.string().typeError(defaultMsg).required(defaultMsg),
          visit_date: Yup.string()
            .typeError(defaultMsg)
            .test(
              'visit_date',
              'Informe uma data válida',
              value => !!validateDate(value, 'yyyy-MM-dd'),
            ),
          visit_date_fim: Yup.string()
            .typeError(defaultMsg)
            .test(
              'visit_date_fim',
              'Informe uma data válida',
              value => !!validateDate(value, 'yyyy-MM-dd'),
            ),
          previsao_estreia: Yup.string()
            .typeError(defaultMsg)
            .test(
              'previsao_estreia',
              'Informe uma data válida',
              value => !!validateDate(value, 'yyyy-MM-dd'),
            ),
          ld_veiculacao_inicial: Yup.array()
            .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
            .typeError(defaultMsg)
            .required(defaultMsg),
          nome_segunda_empresa: Yup.string()
            .typeError(defaultMsg)
            .test(
              'nome_segunda_empresa',
              defaultMsg,
              value => !(coproducao === true && (!value || value.length <= 0)),
            ),
          country_uuid: Yup.string()
            .typeError(defaultMsg)
            .test(
              'country_uuid',
              defaultMsg,
              value => !(coproducao === true && (!value || value.length <= 0)),
            ),
          incentivo: Yup.string()
            .typeError(defaultMsg)
            .test(
              'incentivo',
              defaultMsg,
              value =>
                !(programaIncentivo === true && (!value || value.length <= 0)),
            ),
          director_name: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          director_mini_cv: Yup.string()
            .typeError(defaultMsg)
            .required(defaultMsg),
          sinopse: Yup.string().typeError(defaultMsg).required(defaultMsg),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validação arquivos
        if (pessoaPFisica && !data?.cpf_base64_pdf && !pfUuid) {
          await showModalMessage({
            type: 'error',
            text: 'Arquivo CPF não foi informado',
          });
          setErrorsForm(state => ({
            ...state,
            cpfFile: true,
          }));
          return false;
        }

        if (pessoaPFisica && !data?.rg_base64_pdf && !pfUuid) {
          await showModalMessage({
            type: 'error',
            text: 'Arquivo RG não foi informado',
          });
          setErrorsForm(state => ({
            ...state,
            rgFile: true,
          }));
          return false;
        }

        if (pessoaPFisica === false && !data?.alvara_base64_pdf && !pjUuid) {
          await showModalMessage({
            type: 'error',
            text: 'Arquivo Contrato/Estatuto Social não foi informado',
          });
          setErrorsForm(state => ({
            ...state,
            contratoSocialFile: true,
          }));
          return false;
        }

        if (
          pessoaPFisica === false &&
          !data?.doc_responsavel_base64_pdf &&
          !pjUuid
        ) {
          await showModalMessage({
            type: 'error',
            text: 'Arquivo CPF/RG dos responsáveis legais pela produtora não foi informado',
          });
          setErrorsForm(state => ({
            ...state,
            respLegalFile: true,
          }));
          return false;
        }

        if (pessoaPFisica === false && !data?.cnpj_base64_pdf && !pjUuid) {
          await showModalMessage({
            type: 'error',
            text: 'Arquivo CNPJ não foi informado',
          });
          setErrorsForm(state => ({
            ...state,
            cnpjFile: true,
          }));
          return false;
        }

        if (student && !data?.ofício_universidade_base64_pdf) {
          await showModalMessage({
            type: 'error',
            text: 'Arquivo Ofício da universidade não foi informado',
          });
          setErrorsForm(state => ({
            ...state,
            oficioUnivFile: true,
          }));
          return false;
        }

        // validação responsaveis
        if (
          responsaveisLegal.some(
            resp =>
              resp.cpf &&
              resp.cpf.length <= 14 &&
              Object.values(omitEmpty(resp)).length < 10,
          )
        ) {
          await showModalMessage({
            type: 'error',
            text: 'Preencha todos os campos do Responsável Legal',
          });
          return false;
        }

        if (
          responsaveisComission.some(
            responsavel => responsavel.send_email === null,
          )
        ) {
          await showModalMessage({
            type: 'error',
            text: 'Preencha todos os campos do Responsável pelo contato junto a São Paulo',
          });
          return false;
        }

        if (
          responsaveisDiretor.some(
            responsavel => responsavel.send_email === null,
          )
        ) {
          await showModalMessage({
            type: 'error',
            text: 'Preencha todos os campos do Responsável pela Direção de Produção',
          });
          return false;
        }

        if (
          responsaveisCoordenacao.some(
            responsavel => responsavel.send_email === null,
          )
        ) {
          await showModalMessage({
            type: 'error',
            text: 'Preencha todos os campos do Responsável pela Coordenação de Produção',
          });
          return false;
        }

        if (
          responsaveisProdExecutiva.some(
            responsavel => responsavel.send_email === null,
          )
        ) {
          await showModalMessage({
            type: 'error',
            text: 'Preencha todos os campos do Responsável pela Produção Executiva',
          });
          return false;
        }

        return true;
      } catch (err) {
        await showModalMessage({
          type: 'error',
          text: 'Preencha os campos obrigatórios!',
        });
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
        return false;
      }
    },
    [
      coproducao,
      enderecoNacional,
      formData?.drt_numero?.length,
      pessoaPFisica,
      pfUuid,
      pjUuid,
      programaIncentivo,
      responsaveisComission,
      responsaveisCoordenacao,
      responsaveisDiretor,
      responsaveisLegal,
      responsaveisProdExecutiva,
      student,
    ],
  );

  const handleSubmit = useCallback(
    async data => {
      // files
      data.cpf_base64_pdf = formData?.cpf_file;
      data.rg_base64_pdf = formData?.rg_file;
      data.ofício_universidade_base64_pdf = formData?.oficio_univ_file;
      data.cnpj_base64_pdf = formData?.cnpj_file;
      data.alvara_base64_pdf = formData?.contrato_social_file;
      data.doc_responsavel_base64_pdf = formData?.resp_legal_file;

      // selects
      data.ld_veiculacao_inicial = formData?.ld_veiculacao_inicial;
      data.ld_canais_abertos = formData?.ld_canais_abertos;
      data.ld_canais_filme = formData?.ld_canais_filme;
      data.ld_canais_infantil = formData?.ld_canais_infantil;
      data.ld_canais_noticia = formData?.ld_canais_noticia;
      data.ld_canais_documentarios = formData?.ld_canais_documentarios;
      data.ld_canais_variedade = formData?.ld_canais_variedade;
      data.ld_canais_esporte = formData?.ld_canais_esporte;
      data.ld_streaming = formData?.ld_streaming;

      if (!(await handleValidationFields(data))) return;

      try {
        setLoading(true);

        const dataTmp = omitEmpty(
          clearMasks(data, [
            'cep',
            'phone_mobile_pf',
            'cpf_pf',
            'cnpj_pj',
            'phone_pj',
          ]),
        );

        let body;

        const directorData = {
          director: {
            name: dataTmp.director_name,
            curriculum: dataTmp.director_mini_cv,
          },
        };

        const addressData = enderecoNacional
          ? {
              address: {
                neighborhood: dataTmp.neighborhood,
                city: dataTmp.city,
                uf: dataTmp.state,
              },
              cep: dataTmp?.cep,
              street: dataTmp?.logradouro,
              number: dataTmp?.number,
              complement: dataTmp?.complement,
            }
          : {
              international_address: dataTmp?.endereco_internacional,
            };

        const workData = {
          work_type_uuid: dataTmp.formato_obra_uuid,
          visit_date: dataTmp.visit_date,
          visit_date_fim: dataTmp.visit_date_fim,
          is_indie: indie,
          is_student_production: student,
          is_production_service: service,
          is_program_incentive: programaIncentivo,
          number_incentive: dataTmp?.incentivo,
          synopsis: dataTmp.sinopse,
          titulo_obra: dataTmp.titulo_obra,
          nome_segunda_empresa: dataTmp?.nome_segunda_empresa,
          nome_projeto: dataTmp.titulo_obra,
          mini_cv: dataTmp.director_mini_cv,
          country_uuid: dataTmp.country_uuid,
          state_uuid: dataTmp.state_uuid,
          previsao_estreia: dataTmp.previsao_estreia,
          ofício_universidade_base64_pdf:
            dataTmp?.ofício_universidade_base64_pdf,
          ld_veiculacao_inicial: dataTmp.ld_veiculacao_inicial,
          outro_veiculacao: dataTmp?.outro_veiculacao,
          ld_streaming: dataTmp?.ld_streaming,
          ld_canais_abertos: dataTmp?.ld_canais_abertos,
          ld_canais_filme: dataTmp?.ld_canais_filme,
          ld_canais_infantil: dataTmp?.ld_canais_infantil,
          ld_canais_noticia: dataTmp?.ld_canais_noticia,
          ld_canais_documentarios: dataTmp?.ld_canais_documentarios,
          ld_canais_variedade: dataTmp?.ld_canais_variedade,
          ld_canais_esporte: dataTmp?.ld_canais_esporte,
          contacts: responsaveisComission.map(resp => {
            const { uuid, send_email, ...rest } = clearMasks(resp, [
              'cpf',
              'rg',
              'phone_mobile',
            ]);

            return !uuid
              ? { ...rest, send_email }
              : { user_uuid: uuid, send_email };
          }),
          production_director: responsaveisDiretor.map(resp => {
            const { uuid, send_email, ...rest } = clearMasks(resp, [
              'cpf',
              'rg',
              'phone_mobile',
            ]);

            return !uuid
              ? { ...rest, send_email }
              : { user_uuid: uuid, send_email };
          }),
          executive_producer: responsaveisProdExecutiva.map(resp => {
            const { uuid, send_email, ...rest } = clearMasks(resp, [
              'cpf',
              'rg',
              'phone_mobile',
            ]);

            return !uuid
              ? { ...rest, send_email }
              : { user_uuid: uuid, send_email };
          }),
          production_coordinator: responsaveisCoordenacao.map(resp => {
            const { uuid, send_email, ...rest } = clearMasks(resp, [
              'cpf',
              'rg',
              'phone_mobile',
            ]);

            return !uuid
              ? { ...rest, send_email }
              : { user_uuid: uuid, send_email };
          }),
        };

        if (!pessoaPFisica) {
          const branchData = !pjUuid
            ? {
                branch: {
                  cnpj: dataTmp.cnpj_pj,
                  company_name: dataTmp.rate_social_pj,
                  fantasy_name: dataTmp.fantasy_name_pj,
                  phone: dataTmp.phone_pj,
                  cnpj_base64_pdf: dataTmp.cnpj_base64_pdf,
                  alvara_base64_pdf: dataTmp.alvara_base64_pdf,
                  doc_responsavel_base64_pdf:
                    dataTmp.doc_responsavel_base64_pdf,
                  representantes: responsaveisLegal.map(resp =>
                    omitEmpty(clearMasks(resp, ['cpf', 'phone_mobile'])),
                  ),
                  ...addressData,
                },
              }
            : {
                branch_uuid: pjUuid,
                representantes: responsaveisLegal.map(resp =>
                  omitEmpty(clearMasks(resp, ['cpf', 'phone_mobile'])),
                ),
              };

          body = omitEmpty({
            ...directorData,
            ...branchData,
            ...workData,
          });
        } else {
          const pfData = !pfUuid
            ? {
                pf: {
                  cpf: dataTmp.cpf_pf,
                  name: dataTmp.name_pf,
                  email: dataTmp.email_pf,
                  phone_mobile: dataTmp.phone_mobile_pf,
                  rg: dataTmp.rg_pf,
                  birth_date: dataTmp.birth_date_pf,
                  cpf_base64_pdf: dataTmp.cpf_base64_pdf,
                  rg_base64_pdf: dataTmp.rg_base64_pdf,
                  drt_numero: dataTmp?.drt_numero,
                  uf: dataTmp?.drt_uf,
                  ...addressData,
                },
              }
            : { user_uuid: pfUuid };

          body = omitEmpty({ ...directorData, ...pfData, ...workData });
        }

        const respWork = await ApiClient.doRequest({
          uri: `work`,
          method: ApiClient.POST,
          body,
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title: 'Obra',
          text: 'Obra cadastrada com sucesso!',
        });

        let confirm = false;
        await showModalConfirm({
          text: 'Deseja cadastrar uma Locação?',
          callback: confirmed => {
            confirm = confirmed;
          },
        });

        if (confirm) {
          history.push({
            pathname: '/solicitacao-nova-locacao',
            state: { uuid: respWork?.data?.data?.uuid },
          });
          return;
        }

        history.push('/home-user');
      } catch (error) {
        setLoading(false);

        await showModalMessage({
          type: 'error',
          title: 'Obra',
          text: error.message,
        });

        const validationErrors = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            validationErrors[err.path] = err.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [
      enderecoNacional,
      formData?.cnpj_file,
      formData?.contrato_social_file,
      formData?.cpf_file,
      formData?.ld_canais_abertos,
      formData?.ld_canais_documentarios,
      formData?.ld_canais_esporte,
      formData?.ld_canais_filme,
      formData?.ld_canais_infantil,
      formData?.ld_canais_noticia,
      formData?.ld_canais_variedade,
      formData?.ld_streaming,
      formData?.ld_veiculacao_inicial,
      formData?.oficio_univ_file,
      formData?.resp_legal_file,
      formData?.rg_file,
      handleValidationFields,
      history,
      indie,
      pessoaPFisica,
      pfUuid,
      pjUuid,
      programaIncentivo,
      responsaveisComission,
      responsaveisCoordenacao,
      responsaveisDiretor,
      responsaveisLegal,
      responsaveisProdExecutiva,
      service,
      student,
    ],
  );

  function handleCooproducao(e) {
    e.preventDefault();
    setCoproducao(!coproducao);
  }

  function handleService(e) {
    e.preventDefault();
    setService(!service);
  }

  function handleStudent(e) {
    e.preventDefault();
    setStudent(!student);
  }

  function handleIndie(e) {
    e.preventDefault();
    setIndie(!indie);
  }

  function handleProgramaIncentivo(e) {
    e.preventDefault();
    setProgramaIncentivo(!programaIncentivo);
  }

  function handlePfExists(value) {
    setPfUuid(value);
  }

  function handlePjExists(value) {
    setPjUuid(value);
  }

  function handleRenderStateSelectOptions() {
    if (stateSelectOptions?.length <= 0) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    return (
      <Select
        name="state_uuid"
        label="Estado de origem"
        placeholder="Selecione"
        style={selectCustomStyles}
        options={stateSelectOptions}
        onChange={value => {
          handleSelectChange('state_uuid', value);
        }}
        required
      />
    );
  }

  function handleRenderCountrySelectOptions() {
    if (countrySelectOptions?.length <= 0) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    return (
      <Select
        name="country_uuid"
        label="País de origem da coprodução"
        placeholder=""
        style={selectCustomStyles}
        options={countrySelectOptions}
        onChange={value => {
          handleSelectChange('country_uuid', value);
        }}
        defaultValue={(({ value, label }) => ({ value, label }))(
          countrySelectOptions?.find(state => state.label === 'Brasil'),
        )}
      />
    );
  }

  return (
    <MainPage>
      <Header>
        <LogoIcon />
        <NavBarHeader />
      </Header>
      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Solicitação de autorização de nova obra</h1>
            <span>Home | Solicitação de autorização de nova obra</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>
      <PageContainer>
        <PageContent>
          {formData && (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Dados da Produtora:</span>
                  <span tooltip="Neste campo deverão ser preenchidas as informações a respeito da pessoa jurídica responsável pela obra, isto é, a produtora que responde legalmente pela obra que está sendo cadastrada">
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <Wrap>
                  <RadioNoForm
                    name="radio_tipo_pessoa"
                    label="Cadastrar Tipo Pessoa:"
                    yesLabelValue="Pessoa Física"
                    noLabelValue="Pessoa Jurídica"
                    value={Number(pessoaPFisica).toString()}
                    onChange={event => {
                      setPessoaPFisica(Boolean(Number(event.target.value)));
                    }}
                  />
                </Wrap>
                {!pessoaPFisica && (
                  <BuscaDocumento
                    formRef={formRef}
                    cnpj
                    change={handleChange}
                    pjExistsFunc={handlePjExists}
                  />
                )}
                {pessoaPFisica && (
                  <BuscaDocumento
                    formRef={formRef}
                    cpf
                    isNovaObra
                    change={handleChange}
                    pfExistsFunc={handlePfExists}
                  />
                )}
              </WhiteBox>

              {pessoaPFisica && pfUuid === undefined && (
                <WhiteBox column jCenter aCenter marginBottom="30px">
                  <BoxHeader>
                    <span className="boxText">Documentos Pessoa Física:</span>
                    <span tooltip="Informe os documentos">
                      <AiFillExclamationCircle />
                    </span>
                  </BoxHeader>
                  <Wrap>
                    <File
                      label="CPF"
                      attach={dataCpfFile}
                      textFile="Nenhum arquivo selecionado"
                      name="cpf_file"
                      inputRef={cpfRef}
                      onChange={handleFileChange}
                      required
                      onClick={() => cpfRef.current.click()}
                      error={errorsForm.cpfFile === true && 'Obrigatório'}
                      acceptFiles="application/pdf"
                    />
                    <File
                      label="RG"
                      attach={dataRgFile}
                      textFile="Nenhum arquivo selecionado"
                      name="rg_file"
                      inputRef={rgRef}
                      onChange={handleFileChange}
                      required
                      onClick={() => rgRef.current.click()}
                      error={errorsForm.rgFile === true && 'Obrigatório'}
                      acceptFiles="application/pdf"
                    />
                  </Wrap>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        name="drt_numero"
                        label="DRT-Número"
                        questionTooltip="DRT é o registro junto à Delegacia Regional do Trabalho/Ministério do Trabalho requerido para exercer
                        profissões regulamentadas por lei. Conforme Lei Nº 6.533, de 24 de maio de 1978, Art. 6º – O exercício
                        das profissões de Artista e de Técnico em espetáculos de Diversões requer prévio registro na Delegacia
                        Regional do Trabalho do Ministério do Trabalho, o qual terá validade em todo o território nacional."
                        onChange={handleChange}
                      />
                    </LimiterInputWidth>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="drt_uf"
                        label="UF"
                        onChange={handleChange}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </WhiteBox>
              )}

              {!pessoaPFisica && pjUuid === undefined && (
                <WhiteBox column jCenter aCenter marginBottom="30px">
                  <BoxHeader>
                    <span className="boxText">Documentos Pessoa Jurídica:</span>
                    <span tooltip="Informe os documentos">
                      <AiFillExclamationCircle />
                    </span>
                  </BoxHeader>
                  <Wrap>
                    <File
                      label="Contrato ou Estatuto Social"
                      attach={dataContratoSocialFile}
                      textFile="Nenhum arquivo selecionado"
                      name="contrato_social_file"
                      inputRef={contratoSocialRef}
                      onChange={handleFileChange}
                      required
                      onClick={() => contratoSocialRef.current.click()}
                      error={
                        errorsForm.contratoSocialFile === true && 'Obrigatório'
                      }
                      acceptFiles="application/pdf"
                    />
                    <File
                      label="CPF ou RG do(s) responsável(is) legal(is) pela produtora"
                      attach={dataRespLegalFile}
                      textFile="Nenhum arquivo selecionado"
                      name="resp_legal_file"
                      inputRef={respLegalRef}
                      onChange={handleFileChange}
                      required
                      onClick={() => respLegalRef.current.click()}
                      error={errorsForm.respLegalFile === true && 'Obrigatório'}
                      acceptFiles="application/pdf"
                    />
                  </Wrap>
                  <Wrap>
                    <File
                      label="Cartão CNPJ"
                      attach={dataCnpjFile}
                      textFile="Nenhum arquivo selecionado"
                      name="cnpj_file"
                      inputRef={cnpjRef}
                      onChange={handleFileChange}
                      required
                      onClick={() => cnpjRef.current.click()}
                      error={errorsForm.cnpjFile === true && 'Obrigatório'}
                      acceptFiles="application/pdf"
                    />
                  </Wrap>
                </WhiteBox>
              )}

              {!pessoaPFisica && formData?.cnpj_pj?.length === 18 && (
                <ResponsavelLegal
                  pjUuid={pjUuid}
                  responsaveisLegal={responsaveisLegal}
                  setResponsaveisLegal={setResponsaveisLegal}
                />
              )}

              {((pessoaPFisica && pfUuid === undefined) ||
                (!pessoaPFisica && pjUuid === undefined)) && (
                <WhiteBox column jCenter aCenter marginBottom="30px">
                  <BoxHeader>
                    <RadioNoForm
                      name="radio_tipo_endereco"
                      yesLabelValue="Endereço Nacional"
                      noLabelValue="Endereço Internacional"
                      value={Number(enderecoNacional).toString()}
                      onChange={event => {
                        setEnderecoNacional(
                          Boolean(Number(event.target.value)),
                        );
                      }}
                    />
                    <span tooltip="Preencha o Endereço">
                      <AiFillExclamationCircle />
                    </span>
                  </BoxHeader>
                  {enderecoNacional ? (
                    <BuscaCEP formRef={formRef} change={handleChange} />
                  ) : (
                    <Input
                      name="endereco_internacional"
                      onChange={handleChange}
                      required
                    />
                  )}
                </WhiteBox>
              )}

              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Dados:</span>
                  <span tooltip="Neste campo deverão ser preenchidas as informações a respeito da obra que será cadastrada">
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <Wrap>
                  <Input
                    name="titulo_obra"
                    label="Título da obra Audiovisual"
                    onChange={handleChange}
                    questionTooltip="Este campo deverá ser preenchido o título da obra conforme a necessidade da produção de
                    identificação do projeto, levando em conta a prestação de contas, identificação em projetos incentivados,
                    e sigilo, caso haja. O título preenchido nesse campo será utilizado em todas as documentações referentes
                    ao projeto (autorizações, notas fiscais, e afins), podendo ser diferente do título oficial da obra, bem
                    como conter informações necessárias para a produção."
                    required
                  />
                  <Select
                    name="formato_obra_uuid"
                    label="Formato da Obra Audiovisual"
                    placeholder="Selecione"
                    style={selectCustomStyles}
                    options={workTypeSelectOptions}
                    onChange={value => {
                      handleSelectChange('formato_obra_uuid', value);
                    }}
                    required
                  />
                  {handleRenderStateSelectOptions()}
                </Wrap>
                <Wrap>
                  <Input
                    type="date"
                    name="visit_date"
                    label="Data ou previsão de início das filmagens"
                    min={new Date().toISOString().split('T')[0]}
                    max="2999-12-31"
                    placeholder={isSafari && 'aaaa-mm-dd'}
                    onChange={handleChange}
                    required
                  />
                  <Input
                    type="date"
                    name="visit_date_fim"
                    label="Data ou previsão de fim das filmagens"
                    min={new Date().toISOString().split('T')[0]}
                    max="2999-12-31"
                    placeholder={isSafari && 'aaaa-mm-dd'}
                    onChange={handleChange}
                    required
                  />
                  <Input
                    type="date"
                    questionTooltip="Neste campo deve ser informado o período previsto para estreia da obra."
                    name="previsao_estreia"
                    label="Previsão de estreia"
                    min={new Date().toISOString().split('T')[0]}
                    max="2999-12-31"
                    placeholder={isSafari && 'aaaa-mm-dd'}
                    onChange={handleChange}
                    required
                  />
                </Wrap>
                <Wrap>
                  <GridContainer cols={2}>
                    <LimiterInputWidth w100>
                      <Select
                        name="ld_veiculacao_inicial"
                        label="Veiculação inicial prevista"
                        placeholder="Selecione"
                        questionTooltip={clientResources.labels.tooltipQuestionNovaObra}
                        style={selectCustomStyles}
                        required
                        options={veiculacaoInicialList}
                        onChange={e => {
                          handleMultiSelectChange('ld_veiculacao_inicial', e);
                          setShowCanaisAbertos(
                            e?.some(({ label }) => label === 'TV Aberta'),
                          );
                          setShowCanaisPagos(
                            e?.some(({ label }) => label === 'TV Paga'),
                          );
                          setShowPlataformaStreaming(
                            e?.some(
                              ({ label }) =>
                                label === 'Plataforma de Streaming',
                            ),
                          );
                          setShowOutraVeiculacao(
                            e?.some(({ label }) => label === 'Outros'),
                          );
                        }}
                        isMulti
                      />
                    </LimiterInputWidth>
                    {showCanaisAbertos && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_abertos"
                          label="Televisão - Canais Abertos"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisAbertosList}
                          onChange={e =>
                            handleMultiSelectChange('ld_canais_abertos', e)
                          }
                          isMulti
                        />
                      </LimiterInputWidth>
                    )}
                    {showCanaisPagos && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_filme"
                          label="Filmes/Séries"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosFilmeList}
                          onChange={e =>
                            handleMultiSelectChange('ld_canais_filme', e)
                          }
                          isMulti
                        />
                      </LimiterInputWidth>
                    )}
                    {showCanaisPagos && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_infantil"
                          label="Infantis"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosInfantilList}
                          onChange={e =>
                            handleMultiSelectChange('ld_canais_infantil', e)
                          }
                          isMulti
                        />
                      </LimiterInputWidth>
                    )}
                    {showCanaisPagos && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_noticia"
                          label="Notícias"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosNoticiaList}
                          onChange={e =>
                            handleMultiSelectChange('ld_canais_noticia', e)
                          }
                          isMulti
                        />
                      </LimiterInputWidth>
                    )}
                    {showCanaisPagos && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_documentarios"
                          label="Documentários"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosDocumentarioList}
                          onChange={e =>
                            handleMultiSelectChange(
                              'ld_canais_documentarios',
                              e,
                            )
                          }
                          isMulti
                        />
                      </LimiterInputWidth>
                    )}
                    {showCanaisPagos && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_variedade"
                          label="Variedades"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosVariedadeList}
                          onChange={e =>
                            handleMultiSelectChange('ld_canais_variedade', e)
                          }
                          isMulti
                        />
                      </LimiterInputWidth>
                    )}
                    {showCanaisPagos && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_esporte"
                          label="Esportes"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosEsporteList}
                          onChange={e =>
                            handleMultiSelectChange('ld_canais_esporte', e)
                          }
                          isMulti
                        />
                      </LimiterInputWidth>
                    )}
                    {showPlataformaStreaming && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_streaming"
                          label="Plataforma de Streaming"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={streamingList}
                          onChange={e =>
                            handleMultiSelectChange('ld_streaming', e)
                          }
                          isMulti
                        />
                      </LimiterInputWidth>
                    )}
                    {showOutraVeiculacao && (
                      <LimiterInputWidth w100>
                        <Input
                          name="outro_veiculacao"
                          label="Outros"
                          onChange={handleChange}
                        />
                      </LimiterInputWidth>
                    )}
                  </GridContainer>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w25 marginBottom="20px">
                    <ChoiceButton
                      label="É uma coprodução?"
                      onClick={handleCooproducao}
                      selected={coproducao}
                      questionTooltip="Coprodução: é a modalidade de produção de obra audiovisual realizada por agentes econômicos que
                      exerçam atividade de produção, podendo ser sediados no mesmo país ou não, que contemple o
                      compartilhamento tanto das responsabilidades pela organização econômica da obra, incluindo o
                      aporte de recursos financeiros, bens ou serviços, quanto do patrimônio da obra entre os
                      coprodutores"
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w25 marginBottom="20px">
                    <ChoiceButton
                      label="É production service?"
                      onClick={handleService}
                      selected={service}
                      questionTooltip="Production Service: É o serviço realizado por produtoras locais contratado por produções -
                        normalmente - estrangeiras, e que fornece estrutura e suporte necessário, possibilitando a execução
                        da filmagem"
                    />
                  </LimiterInputWidth>
                  {coproducao === true && (
                    <>
                      <LimiterInputWidth w25>
                        <Input
                          name="nome_segunda_empresa"
                          label="Nome da segunda produtora"
                          onChange={handleChange}
                        />
                      </LimiterInputWidth>
                      <LimiterInputWidth w25>
                        {handleRenderCountrySelectOptions()}
                      </LimiterInputWidth>
                    </>
                  )}
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w25 marginBottom="20px">
                    <ChoiceButton
                      label="Produção independente?"
                      onClick={handleIndie}
                      selected={indie}
                      questionTooltip="Produção Independente: São obras independentes aquelas cuja empresa produtora (isto é, a
                        detentora majoritária dos direitos patrimoniais sobre a obra) não tenha associação ou vínculo com
                        empresas de serviços de radiodifusão de sons e imagens ou operadoras de comunicação eletrônica de
                        massa por assinatura"
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w25 marginBottom="20px">
                    <ChoiceButton
                      label="Produção estudantil?"
                      onClick={handleStudent}
                      selected={student}
                      questionTooltip="Produção Estudantil: São filmagens de projetos comprovadamente vinculados à alguma instituição de
                      ensino com fins pedagógicos ou disciplinares"
                    />
                  </LimiterInputWidth>
                  {student === true && (
                    <>
                      <LimiterInputWidth w50>
                        <File
                          label="Anexar ofício da universidade:"
                          attach={dataOficioUnivFile}
                          textFile="Nenhum arquivo selecionado"
                          name="oficio_univ_file"
                          inputRef={oficioUnivRef}
                          onChange={handleFileChange}
                          required
                          onClick={() => oficioUnivRef.current.click()}
                          error={
                            errorsForm.oficioUnivFile === true && 'Obrigatório'
                          }
                          style={{ margin: 0 }}
                          acceptFiles="application/pdf"
                        />
                      </LimiterInputWidth>
                    </>
                  )}
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButton
                      label="Programas de incentivo?"
                      onClick={handleProgramaIncentivo}
                      selected={programaIncentivo}
                      questionTooltip="Programas de Incentivo (Cultura/Audiovisual): São diferentes tipos de ações de valorização e
                      estímulo à produção do setor cultural e/ou audiovisual a partir de incentivo via canalização de
                      recursos. Normalmente os projetos contemplados em programas de incentivo são identificados a
                      partir de uma sigla e/ou número. (Ex: ProAC, Lei Rouanet, PRONAC, entre outros)"
                    />
                  </LimiterInputWidth>
                  {programaIncentivo === true && (
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        name="incentivo"
                        label="Tipo de incentivo e número/identificação de registro"
                        onChange={handleChange}
                      />
                    </LimiterInputWidth>
                  )}
                </Wrap>
              </WhiteBox>

              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Projeto:</span>
                  <span tooltip="Nos campos de “Projeto” devem ser preenchidas as informações da obra que serão utilizadas para trâmites internos junto aos órgãos competentes. Informações sigilosas sobre o projeto não devem ser colocadas nesse campo pois ele será utilizado em todas as documentações referentes ao processo de autorização da obra">
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      name="director_name"
                      label="Nome do Diretor"
                      onChange={handleChange}
                      required
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <Textarea
                    type="text"
                    name="director_mini_cv"
                    label="Mini currículo do(a) diretor(a)"
                    onChange={handleChange}
                    rows="6"
                    maxLength={300}
                    required
                  />
                </Wrap>
                <Wrap>
                  <Textarea
                    type="text"
                    name="sinopse"
                    label="Sinopse da obra audiovisual"
                    onChange={handleChange}
                    rows="6"
                    maxLength={500}
                    required
                  />
                </Wrap>
              </WhiteBox>

              <ContatoResponsavel
                boxTitle={
                  clientResources.labels.solicitacaoNovaObra
                    .responsavelSpFilmContatoMulti
                }
                boxInfo="Nos campos de “Dados do Responsável do Projeto, tanto pra Coordenador, diretor e produtor em contato com a Film Commission” deverão ser preenchidos todos os campos, com os dados do respectivo responsável, as informações serão utilizadas para trâmites internos junto aos órgãos competentes"
                responsaveis={responsaveisComission}
                setResponsaveis={setResponsaveisComission}
              />

              <ContatoResponsavel
                boxTitle="Dados do responsável pela Direção de Produção:"
                boxInfo="Nos campos de “Dados do Responsável do Projeto, tanto pra Coordenador, diretor e produtor em
                contato com a Film Commission” deverão ser preenchidos todos os campos, com os dados do
                respectivo responsável, as informações serão utilizadas para trâmites internos junto aos órgãos
                competentes"
                responsaveis={responsaveisDiretor}
                setResponsaveis={setResponsaveisDiretor}
              />

              <ContatoResponsavel
                boxTitle="Dados do responsável pela Coordenação de Produção:"
                boxInfo="Nos campos de “Dados do Responsável do Projeto, tanto pra Coordenador, diretor e produtor em
                contato com a Film Commission” deverão ser preenchidos todos os campos, com os dados do
                respectivo responsável, as informações serão utilizadas para trâmites internos junto aos órgãos
                competentes"
                responsaveis={responsaveisCoordenacao}
                setResponsaveis={setResponsaveisCoordenacao}
              />

              <ContatoResponsavel
                boxTitle="Dados do responsável pela Produção Executiva:"
                boxInfo="Nos campos de “Dados do Responsável do Projeto, tanto pra Coordenador, diretor e produtor em
                contato com a Film Commission” deverão ser preenchidos todos os campos, com os dados do
                respectivo responsável, as informações serão utilizadas para trâmites internos junto aos órgãos
                competentes"
                responsaveis={responsaveisProdExecutiva}
                setResponsaveis={setResponsaveisProdExecutiva}
              />

              <ButtonBox>
                <Button btType="#4F4F4F" label="Voltar" full width="150px" />
                <Button
                  label="Concluir"
                  btType="#26A2B1"
                  type="submit"
                  full
                  width="157px"
                  loading={loading}
                />
              </ButtonBox>
            </Form>
          )}
        </PageContent>
      </PageContainer>

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <MainLoading loading={loading}>
        <h3>Processando Informações</h3>
        <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
      </MainLoading>
    </MainPage>
  );
}
