/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { format, parse } from 'date-fns';
import omitEmpty from 'omit-empty';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiLoader } from 'react-icons/fi';
import { GridContainer, Line, Wrap } from '~/styles/components';
import { CheckboxHtml, Input } from '~/components/Forms';
import Button from '~/components/Button';
import MultiDateInput from '~/components/Forms/MultiDateInput';
import { showModalMessage } from '~/services/notification';
import { VirtualTable } from '~/components/Forms/VirtualTable';
import { AccordionItem } from '~/components/Forms/AccordionItem';
import { Text } from '~/components/Forms/Text';
import ApiClient from '~/utils/ApiClient';
import { SplitButton } from '~/components/SplitButton';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const uri = 'report-user';

export const UsuarioForm = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleResetForm = () => {
    setFormData({
      datas_cadastro: [],
      cpf: '',
      nome: '',
      cnpj: '',
      email: '',
      company_name: '',
      drt: null,
    });
  };

  const handleValidationFields = useCallback(async () => {
    try {
      formRef.current.setErrors({});

      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        datas_cadastro: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'datas-cadastro-nao-informadas',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }, [formData]);

  const bodyBuilder = useCallback(async () => {
    if (!(await handleValidationFields())) return null;

    try {
      // format dates
      const datasCadastro = formData?.datas_cadastro?.map(date =>
        format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      );

      const pessoaBody =
        tabIndex === 0
          ? {
              cpf: formData?.cpf,
              nome: formData?.nome,
              email: formData?.email_pf,
              type: 'user',
            }
          : {
              cnpj: formData?.cnpj,
              company_name: formData?.company_name,
              email: formData?.email_pj,
              drt: formData?.drt,
              type: 'branch',
            };

      const body = omitEmpty({
        start_date: datasCadastro[0],
        end_date:
          datasCadastro.length === 1 ? datasCadastro[0] : datasCadastro[1],
        ...pessoaBody,
      });

      return body;
    } catch (error) {
      showModalMessage({
        type: 'error',
        text: 'Erro ao formatar dados para requisição',
      });
      return null;
    }
  }, [
    formData?.cnpj,
    formData?.cpf,
    formData?.datas_cadastro,
    formData?.drt,
    formData?.email_pf,
    formData?.email_pj,
    formData?.nome,
    formData?.company_name,
    handleValidationFields,
    tabIndex,
  ]);

  const handleClickGerar = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri,
        method: ApiClient.POST,
        body,
        expectedStatus: 200,
      });

      setResponseData(prevState => ({ ...prevState, [body.type]: resp.data }));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  const handleClickExportarExcel = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      await ApiClient.doRequest({
        uri: `${uri}/xls`,
        method: ApiClient.POST,
        body: { ...body, report_name: 'getUserReport' },
        expectedStatus: 200,
        isBinary: true,
        fileName: 'relatorio.xlsx',
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  // Pessoa Fisica

  const renderFiltrosPessoaFisica = () => {
    return (
      <Form ref={formRef}>
        <Wrap>
          <GridContainer cols={4} gap={30} style={{ alignItems: 'flex-end' }}>
            <MultiDateInput
              range
              name="datas_cadastro"
              label="Data de Cadastro"
              required
              value={formData?.datas_cadastro}
              onChange={e => {
                handleChange(
                  'datas_cadastro',
                  e?.map(
                    ({ day, month, year }) =>
                      `${String(day).padStart(2, '0')}/${String(month).padStart(
                        2,
                        '0',
                      )}/${year}`,
                  ),
                );
              }}
            />

            {Object.values(formData?.datas_cadastro || []).length > 1 && (
              <>
                <Input
                  type="text"
                  label="CPF"
                  name="cpf"
                  value={formData?.cpf}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <Input
                  type="text"
                  label="Nome"
                  name="nome"
                  value={formData?.nome}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <Input
                  type="text"
                  label="E-mail"
                  name="email_pf"
                  value={formData?.email_pf}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
              </>
            )}
          </GridContainer>
        </Wrap>

        <Wrap style={{ justifyContent: 'flex-end' }}>
          <SplitButton
            buttons={[
              {
                label: 'Gerar',
                onClick: handleClickGerar,
              },
              {
                label: 'Exportar Excel...',
                onClick: handleClickExportarExcel,
              },
            ]}
            isLoading={loading}
            disableAllButtons={loading}
          />

          <Button
            type="button"
            btType="#828282"
            onClick={handleResetForm}
            label="Limpar campos"
            full
            loading={loading}
            disabled={loading}
            width="150px"
            style={{ marginLeft: 5 }}
          />
        </Wrap>
        <Line />
      </Form>
    );
  };

  const renderQuantitativoPessoaFisica = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData?.user || {}).length <= 0 ||
      responseData?.user?.statistics?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    return (
      <>
        <GridContainer cols={5} style={{ marginTop: 15 }}>
          {responseData.user.statistics.map(({ label, value }) => (
            <Text label={label} value={value} />
          ))}
        </GridContainer>
        <Line />
      </>
    );
  };

  const renderListagemBaseNovaPessoaFisica = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData?.user || {}).length <= 0 ||
      responseData?.user?.users?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    const columnsNames = ['Data de Cadastro', 'CPF/CNPJ', 'Nome', 'Email'];

    const tableData = responseData.user.users.map(data => ({
      created_at: format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm'),
      cpf: data?.cpf,
      name: data?.name,
      email: data?.user.email,
    }));

    return (
      <VirtualTable
        title="Impressão da listagem de Usuários Pessoas Físicas"
        height={500}
        width="100%"
        itemCount={tableData?.length}
        itemSize={40}
        tableData={tableData}
        showPrinter
        columnsNames={columnsNames}
        header={
          <thead>
            <tr>
              {columnsNames.map(columnName => (
                <th>{columnName}</th>
              ))}
            </tr>
          </thead>
        }
        row={({ index, style }) => (
          <tr>
            <td>{tableData[index].created_at}</td>
            <td>{tableData[index].cpf}</td>
            <td>{tableData[index].name}</td>
            <td>{tableData[index].email}</td>
          </tr>
        )}
        footer={
          <tfoot>
            <tr>
              <td colSpan={3}>Fim de listagem</td>
            </tr>
          </tfoot>
        }
      />
    );
  };

  const renderPanelPessoaFisica = () => {
    return (
      <>
        <Wrap>
          <AccordionItem opened label="Filtros">
            {renderFiltrosPessoaFisica()}
          </AccordionItem>
        </Wrap>

        <Wrap>
          <AccordionItem label="Quantitativos">
            {renderQuantitativoPessoaFisica()}
          </AccordionItem>
        </Wrap>

        <Wrap>
          <AccordionItem label="Listagem (Base nova)">
            {renderListagemBaseNovaPessoaFisica()}
          </AccordionItem>
        </Wrap>
      </>
    );
  };

  // Pessoa Juridica

  const renderFiltrosPessoaJuridica = () => {
    return (
      <Form ref={formRef}>
        <Wrap>
          <GridContainer cols={4} gap={30} style={{ alignItems: 'flex-end' }}>
            <MultiDateInput
              range
              name="datas_cadastro"
              label="Data de Cadastro"
              required
              value={formData?.datas_cadastro}
              onChange={e => {
                handleChange(
                  'datas_cadastro',
                  e?.map(
                    ({ day, month, year }) =>
                      `${String(day).padStart(2, '0')}/${String(month).padStart(
                        2,
                        '0',
                      )}/${year}`,
                  ),
                );
              }}
            />

            {Object.values(formData?.datas_cadastro || []).length > 1 && (
              <>
                <Input
                  type="text"
                  label="CNPJ"
                  name="cnpj"
                  value={formData?.cnpj}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <Input
                  type="text"
                  label="Razão Social"
                  name="company_name"
                  value={formData?.company_name}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
                <Input
                  type="text"
                  label="E-mail"
                  name="email_pj"
                  value={formData?.email_pj}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <CheckboxHtml
                  name="drt"
                  label="Com DRT?"
                  value={formData?.drt}
                  checked={formData?.drt}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />
              </>
            )}
          </GridContainer>
        </Wrap>

        <Wrap style={{ justifyContent: 'flex-end' }}>
          <SplitButton
            buttons={[
              {
                label: 'Gerar',
                onClick: handleClickGerar,
              },
              {
                label: 'Exportar Excel...',
                onClick: handleClickExportarExcel,
              },
            ]}
            isLoading={loading}
            disableAllButtons={loading}
          />

          <Button
            type="button"
            btType="#828282"
            onClick={handleResetForm}
            label="Limpar campos"
            full
            loading={loading}
            disabled={loading}
            width="150px"
            style={{ marginLeft: 5 }}
          />
        </Wrap>
        <Line />
      </Form>
    );
  };

  const renderQuantitativoPessoaJuridica = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData?.branch || {}).length <= 0 ||
      responseData?.branch?.statistics?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    return (
      <>
        <GridContainer cols={5} style={{ marginTop: 15 }}>
          {responseData.branch.statistics.map(({ label, value }) => (
            <Text label={label} value={value} />
          ))}
        </GridContainer>
        <Line />
      </>
    );
  };

  const renderListagemBaseNovaPessoaJuridica = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData?.branch || {}).length <= 0 ||
      responseData?.branch?.branches?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    const columnsNames = [
      'Data de Cadastro',
      'CNPJ',
      'Razão Social',
      'Nome Fantasia',
      'Email do Representante',
    ];

    const tableData = responseData.branch.branches.map(data => ({
      created_at: format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm'),
      cnpj: data?.cnpj,
      company_name: data?.company_name,
      fantasy_name: data?.fantasy_name,
      email_representante: data?.user?.email,
    }));

    return (
      <VirtualTable
        title="Impressão da listagem de Usuários Pessoas Jurídicas"
        height={500}
        width="100%"
        itemCount={tableData.length}
        itemSize={40}
        tableData={tableData}
        columnsNames={columnsNames}
        showPrinter
        header={
          <thead>
            <tr>
              {columnsNames.map(columnName => (
                <th>{columnName}</th>
              ))}
            </tr>
          </thead>
        }
        row={({ index, style }) => (
          <tr>
            <td>{tableData[index].created_at}</td>
            <td>{tableData[index].cnpj}</td>
            <td>{tableData[index].company_name}</td>
            <td>{tableData[index].fantasy_name}</td>
            <td>{tableData[index].email_representante}</td>
          </tr>
        )}
        footer={
          <tfoot>
            <tr>
              <td colSpan={3}>Fim de listagem</td>
            </tr>
          </tfoot>
        }
      />
    );
  };

  const renderPanelPessoaJuridica = () => {
    return (
      <>
        <Wrap>
          <AccordionItem opened label="Filtros">
            {renderFiltrosPessoaJuridica()}
          </AccordionItem>
        </Wrap>

        <Wrap>
          <AccordionItem label="Quantitativos">
            {renderQuantitativoPessoaJuridica()}
          </AccordionItem>
        </Wrap>

        <Wrap>
          <AccordionItem label="Listagem (Base nova)">
            {renderListagemBaseNovaPessoaJuridica()}
          </AccordionItem>
        </Wrap>
      </>
    );
  };

  return (
    <Tabs
      style={{ width: '100%' }}
      selectedIndex={tabIndex}
      onSelect={index => {
        setTabIndex(index);
      }}
    >
      <TabList style={{ border: 0, marginBottom: 30 }}>
        <Tab>Pessoa Física</Tab>
        <Tab>Pessoa Jurídica</Tab>
      </TabList>
      <TabPanel>{renderPanelPessoaFisica()}</TabPanel>
      <TabPanel>{renderPanelPessoaJuridica()}</TabPanel>
    </Tabs>
  );
};
