import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import CryptoJS from 'crypto-js';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  ChoiceButtonLocacao,
  Textarea,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ConditionalContainer,
  LimiterInputWidth,
  ActionInputMulti,
  MultiInputContainer,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import ComplementoNumeroListActions from '~/store/ducks/complemento-numero-list';
import FinalidadeReservaListActions from '~/store/ducks/finalidade-reserva-list';
import LocalGeradorListActions from '~/store/ducks/local-gerador-list';
import { useList } from '~/hooks/useFunction';
import LocacaoDocs from '~/pages/Usuario/Locacoes/LocacaoDocs';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import Documents from '~/components/Documents/Documents';
import {
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import { ActionButton } from '~/components/Forms/FormChoiceButton/styles';
import tenancy_env from '~/utils/MultiTenancyUtil';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';
import { IntervalFormMultiInput } from '~/components/Forms/IntervalFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const selectExtensaoList = [
  { value: 'metragem', label: 'Metragem' },
  { value: 'intervalo', label: 'Numeração' },
];

export default function ReservasVagasVinculado() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseLocation, setResponseLocation] = useState();
  const [errorsForm, setErrorsForm] = useState({});
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);

  // states multi input
  const [intervaloInputs, setIntervaloInputs] = useState([
    { start: '', end: '' },
  ]);
  const [horasReservaInputs, setHorasReservaInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasReservaInputs, setDatasReservaInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states choices
  const [gerador, setGerador] = useState(null);
  const [blimpado, setBlimpado] = useState(null);
  const [drone, setDrone] = useState(null);
  const [outraReservaVaga, setOutraReservaVaga] = useState(null);
  const [reservaMesmaLocacao, setReservaMesmaLocacao] = useState(null);

  // state outro fields
  const [showOutroFinReservaField, setShowOutroFinReservaField] =
    useState(false);
  const [showExtensaoMetragemField, setShowExtensaoMetragemField] =
    useState(false);
  const [showExtensaoIntervalosField, setShowExtensaoIntervalosField] =
    useState(false);
  const [showNumberFields, setShowNumberFields] = useState(false);

  // selectors
  const reservasAssociacaoList = useList({
    url: `associacao-via-externa/${dadoState?.uuid}?paginate=false`,
  });

  const { data: complementoNumeroSelectList } = useSelector(
    state => state.complementoNumeroList,
  );
  const { data: finalidadeReservaSelectList } = useSelector(
    state => state.finalidadeReservaList,
  );
  const { data: localGeradorSelectList } = useSelector(
    state => state.localGeradorList,
  );

  // Busca dados da Reserva
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-via-externa/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set temp variables
      const blimpadoTmp =
        respLoc?.blimpado === null ? null : respLoc?.blimpado === 1;

      // set choices buttons
      setBlimpado(blimpadoTmp);

      setGerador(
        blimpadoTmp === true ||
          Object.values(respLoc?.ld_local_gerador || {}).length > 0,
      );

      setDrone(respLoc?.drone === 1);

      // set multi inputs
      setDatasReservaInputs(
        respLoc?.date_locacao_via_externa?.map(
          ({ date, changes_to_approve }) => ({
            data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
            changes_to_approve,
          }),
        ),
      );

      setHorasReservaInputs(
        respLoc?.hour_locacao_via_externa?.map(
          ({ inicio, fim, changes_to_approve }) => ({
            inicio: inicio.substring(0, 5),
            fim: fim.substring(0, 5),
            changes_to_approve,
          }),
        ),
      );

      setIntervaloInputs(
        respLoc?.reserva_intervalo
          ? respLoc?.reserva_intervalo?.map(({ start, end }) => {
              return { start, end };
            })
          : [{ start: '', end: '' }],
      );

      // set campos 'Outro'
      setShowOutroFinReservaField(
        respLoc?.finalidades?.find(finalidade => finalidade.name === 'Outros'),
      );

      // set select default values
      formRef.current?.setFieldValue('ld_complemento_numero_uuid', {
        label: respLoc?.ld_complemento_numero?.name,
        value: respLoc?.ld_complemento_numero?.uuid,
      });

      const filterExtensaoValue =
        Object.values(respLoc?.reserva_intervalos || []).length > 0
          ? 'intervalo'
          : 'metragem';
      formRef.current?.setFieldValue(
        'select_extensao',
        selectExtensaoList.filter(
          extensao => extensao.value === filterExtensaoValue,
        ),
      );

      formRef.current?.setFieldValue(
        'finalidades',
        respLoc?.finalidades?.map(ilfr => {
          return {
            label: ilfr.name,
            value: ilfr.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_local_gerador_uuid', {
        label: respLoc?.ld_local_gerador?.name,
        value: respLoc?.ld_local_gerador?.uuid,
      });

      // set show campos condicionais
      setShowNumberFields(typeof respLoc?.number === 'string');
      setShowExtensaoMetragemField(
        Object.values(respLoc?.reserva_intervalo || []).length <= 0,
      );
      setShowExtensaoIntervalosField(
        Object.values(respLoc?.reserva_intervalo || []).length > 0,
      );

      setResponseLocation(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState?.viewData]);

  // effects
  useEffect(() => {
    dispatch(ComplementoNumeroListActions.showRequest());
    dispatch(FinalidadeReservaListActions.showRequest());
    dispatch(LocalGeradorListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'number') {
      setShowNumberFields(value !== '');
    }
  }

  function handleMultiSelectFinalidades(e) {
    setShowOutroFinReservaField(!!e?.find(x => x.label === 'Outros'));

    setFormData(state => ({
      ...state,
      finalidades: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'select_extensao') {
      setShowExtensaoMetragemField(option.value === 'metragem');
      setShowExtensaoIntervalosField(option.value === 'intervalo');
    }
  }

  function formatEspecificaData(data) {
    const locacaoAssociada = reservasAssociacaoList?.find(
      ({ uuid }) => uuid === data?.reservas_associacao?.uuid,
    );

    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      address: data?.address,
      number: data?.number,
      neighborhood: data?.neighborhood,
      finalidades: data?.finalidades,
      especificidades: data?.especificidades,
    });

    const dataAssociarLocacao = {
      reference_uuid: locacaoAssociada?.uuid,
      name_view: locacaoAssociada?.name_view,
    };

    // dados de choices
    const dataGerador = {
      ld_local_gerador_uuid: gerador ? data?.ld_local_gerador_uuid : null,
      blimpado: gerador ? blimpado : null,
    };

    const dataDrone = { drone };

    // dados de campos exibidos condicionalmente
    const dataNumberFields = {
      ld_complemento_numero_uuid: showNumberFields
        ? data?.ld_complemento_numero_uuid
        : null,
    };

    const dataExtensaoMetragem = {
      metragem: showExtensaoMetragemField ? +data?.metragem : null,
    };

    const dataExtensaoIntervalos = {
      reserva_intervalo: showExtensaoIntervalosField ? intervaloInputs : null,
    };

    const dataOutroFinReserva = {
      outra_finalidade: showOutroFinReservaField
        ? data?.outra_finalidade
        : null,
    };

    // format datas reserva
    const datasReserva = datasReservaInputs
      .filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_filmagem));

    return Object.assign(
      dataNaoCondicional,
      dataAssociarLocacao,
      dataNumberFields,
      dataExtensaoMetragem,
      dataExtensaoIntervalos,
      { dates: datasReserva },
      { hours: horasReservaInputs },
      dataGerador,
      dataDrone,
      dataOutroFinReserva,
    );
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        reservas_associacao: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        address: Yup.string().typeError(defaultMsg).required(defaultMsg),
        number: Yup.string().typeError(defaultMsg).required(defaultMsg),
        neighborhood: Yup.string().typeError(defaultMsg).required(defaultMsg),
        select_extensao: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        finalidades: Yup.lazy(val =>
          Array.isArray(val)
            ? Yup.array().of(
                Yup.string().typeError(defaultMsg).required(defaultMsg),
              )
            : Yup.string().typeError(defaultMsg).required(defaultMsg),
        ),
        outra_finalidade: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outra-finalidade-selecionado',
            defaultMsg,
            value => !(showOutroFinReservaField === true && value.length <= 0),
          ),
        ld_complemento_numero_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'complemento-selecionado',
            defaultMsg,
            value => !(showNumberFields === true && value.length <= 0),
          ),
        metragem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'metragem-selecionado',
            defaultMsg,
            value => !(showExtensaoMetragemField === true && value.length <= 0),
          ),
        ld_local_gerador_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'gerador-selecionado',
            defaultMsg,
            value => !(gerador === true && value.length <= 0),
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!validDateArray(datasReservaInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasReservaInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }

      if (gerador === null) {
        await showModalMessage({
          type: 'error',
          text: 'Gerador não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          gerador: true,
        }));
        return false;
      }

      if (gerador && blimpado === null) {
        await showModalMessage({
          type: 'error',
          text: 'Blimpado não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          blimpado: true,
        }));
        return false;
      }

      if (drone === null) {
        await showModalMessage({
          type: 'error',
          text: 'Drone não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          drone: true,
        }));
        return false;
      }

      if (outraReservaVaga === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Outra Reserva' não foi informada!",
        });
        setErrorsForm(state => ({
          ...state,
          outraReservaVaga: true,
        }));
        return false;
      }

      if (outraReservaVaga && reservaMesmaLocacao === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Reserva para mesma Locação' não foi informada!",
        });
        setErrorsForm(state => ({
          ...state,
          reservaMesmaLocacao: true,
        }));
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  function handleResetReservaFields() {
    setResponseLocation({});
    setErrorsForm({});
    setFormData({});
    setShowNumberFields(false);
    setShowExtensaoMetragemField(false);
    setShowExtensaoIntervalosField(false);
    setShowOutroFinReservaField(false);
    setGerador(null);
    setDrone(null);
    setOutraReservaVaga(null);
    setDatasReservaInputs([{ data_filmagem: '' }]);
    setHorasReservaInputs([{ inicio: '', fim: '' }]);
    setIntervaloInputs([{ start: '', end: '' }]);
  }

  async function handleSubmit(data, { reset }) {
    data.finalidades = formData?.finalidades;

    if (!(await handleValidationFields(data))) {
      return;
    }

    const reservasData = formatEspecificaData(data);

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: 'locacao-via-externa',
        method: ApiClient.POST,
        body: omitEmpty(reservasData),
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Reservas de Vagas Vinculado',
        text: 'Locação gravada com sucesso',
      });

      reset();
      handleResetReservaFields();

      if (!(outraReservaVaga === true && reservaMesmaLocacao === true)) {
        setTimeout(function () {
          history.push('/home-user');
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Reservas de Vagas Vinculado',
        text: 'Ocorreu um erro ao tentar gravar Reservas de Vagas. Verifique os dados.',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  // handle true/false
  function handleReservaMesmaLocacaoTrue(e) {
    e.preventDefault(e);
    setReservaMesmaLocacao(true);
    setErrorsForm(state => ({
      ...state,
      reservaMesmaLocacao: false,
    }));
  }

  function handleReservaMesmaLocacaoFalse(e) {
    e.preventDefault(e);
    setReservaMesmaLocacao(false);
    setErrorsForm(state => ({
      ...state,
      reservaMesmaLocacao: false,
    }));
  }

  function handleOutraReservaVagaTrue(e) {
    e.preventDefault(e);
    setOutraReservaVaga(true);
    setErrorsForm(state => ({
      ...state,
      outraReservaVaga: false,
    }));
  }

  function handleOutraReservaVagaFalse(e) {
    e.preventDefault(e);
    setOutraReservaVaga(false);
    setErrorsForm(state => ({
      ...state,
      outraReservaVaga: false,
    }));
  }

  function handleGeradorTrue(e) {
    e.preventDefault();
    setGerador(true);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorFalse(e) {
    e.preventDefault();
    setGerador(false);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleBlimpadorTrue(e) {
    e.preventDefault();
    setBlimpado(true);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBlimpadorFalse(e) {
    e.preventDefault();
    setBlimpado(false);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleDroneTrue(e) {
    e.preventDefault();
    setDrone(true);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleDroneFalse(e) {
    e.preventDefault();
    setDrone(false);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                {!isAdminRequest && (
                  <>
                    <LocacaoDocs
                      excludes={['precosFilmagensEquipamentosPublicos']}
                    />
                  </>
                )}

                <BoxHeader>
                  <span className="boxText">Informações gerais</span>
                </BoxHeader>
                <Wrap>
                  <Textarea
                    name="form_info"
                    rows="3"
                    value="Este formulário é somente para solicitações de reserva de vagas de filmagens em
                      equipamentos públicos municipais. Solicitações que envolvam bloqueio(s) de via, ocupações de passeio
                      (calçada) para filmagem ou apoio, deverão ser solicitadas no formulário “Utilização de Via para
                      Filmagens ou Apoio."
                    disabled
                  />
                </Wrap>

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={dadoState?.viewData && true}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  {!dadoState?.viewData && (
                    <LimiterInputWidth w50 style={{ flexDirection: 'column' }}>
                      <Select
                        name="reservas_associacao"
                        label="Escolha a Locação a ser associada:"
                        placeholder="Selecione"
                        options={reservasAssociacaoList?.map(
                          ({ uuid, name_view, protocol, endpoint }) => ({
                            label: `${name_view} - ${protocol}`,
                            value: { uuid, endpoint, name_view },
                          }),
                        )}
                        style={selectCustomStyles}
                        onChange={value => {
                          handleSelectChange('reservas_associacao', value);
                        }}
                        required
                        isDisabled={dadoState?.viewData && true}
                      />
                      {formData?.reservas_associacao && (
                        <FormChoiceButton
                          nomeProjeto={dadoState?.nome_projeto}
                          viewData={{
                            is_admin: isAdminRequest,
                            uuid: formData?.reservas_associacao?.uuid,
                            endpoint: formData?.reservas_associacao?.endpoint,
                          }}
                        />
                      )}
                    </LimiterInputWidth>
                  )}
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="address"
                      label="Endereço da Via"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.address
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="number"
                      label="Número"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.number
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showNumberFields}>
                    <LimiterInputWidth>
                      <Select
                        name="ld_complemento_numero_uuid"
                        label="Complemento"
                        required
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={complementoNumeroSelectList}
                        onChange={value => {
                          handleSelectChange(
                            'ld_complemento_numero_uuid',
                            value,
                          );
                        }}
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.neighborhood
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Select
                      name="select_extensao"
                      label="Extensão"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={selectExtensaoList}
                      required
                      onChange={value => {
                        handleSelectChange('select_extensao', value);
                      }}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showExtensaoMetragemField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name="metragem"
                        label="Metragem"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData && responseLocation?.metragem
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={showExtensaoIntervalosField}>
                  <IntervalFormMultiInput
                    label="Intervalo de Numeração"
                    tooltip="De ___ até ___ (Pode adicionar mais de um intervalo)"
                    isRequired={showExtensaoIntervalosField}
                    beginKeyName="start"
                    endKeyName="end"
                    isViewMode={dadoState?.viewData && true}
                    intervals={intervaloInputs}
                    setIntervals={setIntervaloInputs}
                  />
                </ConditionalContainer>

                <Wrap>
                  <DateFormMultiInput
                    label="Data da Reserva"
                    dates={datasReservaInputs}
                    setDates={setDatasReservaInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    dateKeyName="data_filmagem"
                    tooltip="Possível adicionar mais de uma data"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Hora da Reserva (Início e Fim)"
                    hours={horasReservaInputs}
                    setHours={setHorasReservaInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="O horário informado deve ser referente ao início da operacionalização da reserva de vagas, e não da filmagem. Possível adicionar mais de um horário."
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="finalidades"
                      label="Finalidade da Reserva de Vagas"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={finalidadeReservaSelectList}
                      required
                      isMulti
                      onChange={handleMultiSelectFinalidades}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showOutroFinReservaField}>
                    <LimiterInputWidth>
                      <Input
                        type="text"
                        name="outra_finalidade"
                        label="Outro Finalidade Reserva"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.outra_finalidade
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Haverá gerador?"
                      onClickTrue={handleGeradorTrue}
                      onClickFalse={handleGeradorFalse}
                      selected={gerador}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.gerador === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={gerador === true}>
                    <LimiterInputWidth w25>
                      <ChoiceButtonLocacao
                        label="Blimpado/Silencioso ou Não-Blimpado?"
                        onClickTrue={handleBlimpadorTrue}
                        onClickFalse={handleBlimpadorFalse}
                        selected={blimpado}
                        required
                        isDisabled={dadoState?.viewData && true}
                        error={errorsForm.blimpado === true && 'Obrigatório'}
                        yesLabelValue="Blimpado"
                        noLabelValue="Não-Blimpado"
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <ConditionalContainer show={gerador === true}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_local_gerador_uuid"
                        label="O gerador ficará:"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={localGeradorSelectList}
                        required
                        onChange={value => {
                          handleSelectChange('ld_local_gerador_uuid', value);
                        }}
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Drone"
                      onClickTrue={handleDroneTrue}
                      onClickFalse={handleDroneFalse}
                      selected={drone}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.drone === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={drone === true}>
                  <Wrap>
                    <Textarea
                      name="autorizacao_drones"
                      rows="4"
                      value="Para filmagens com drones: o Espaço Aéreo não é de administração municipal, mas sim da
                    Federação/União, portanto, não faz parte do campo de atuação da Ilhacine. A autorização para o uso
                    de drones deve ser solicitada diretamente à Agência Nacional de Aviação Civil (ANAC), mediante
                    cadastro no Sistema SARPAS, disponibilizado no site do Departamento de Controle do Espaço Aéreo
                    (DECEA)."
                      disabled
                    />
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label=" Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData && responseLocation?.especificidades
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                {responseLocation?.documents?.length > 0 && isAdminRequest && (
                  <Wrap>
                    <Documents
                      documents={responseLocation?.documents}
                      uuid={responseLocation.uuid}
                    />
                  </Wrap>
                )}

                {!dadoState.viewData && (
                  <Wrap>
                    <ChoiceButtonLocacao
                      label="Deseja adicionar outra reserva de vaga?"
                      onClickTrue={handleOutraReservaVagaTrue}
                      onClickFalse={handleOutraReservaVagaFalse}
                      selected={outraReservaVaga}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={
                        errorsForm.outraReservaVaga === true && 'Obrigatório'
                      }
                    />
                  </Wrap>
                )}

                <ConditionalContainer show={outraReservaVaga}>
                  <Wrap>
                    <ChoiceButtonLocacao
                      label="A nova reserva de vagas é para a mesma locação?"
                      onClickTrue={handleReservaMesmaLocacaoTrue}
                      onClickFalse={handleReservaMesmaLocacaoFalse}
                      selected={reservaMesmaLocacao}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={
                        errorsForm.reservaMesmaLocacao === true && 'Obrigatório'
                      }
                    />
                  </Wrap>
                </ConditionalContainer>

                {responseLocation?.created_at && isAdminRequest && (
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="created_at"
                        label="Data de criação"
                        defaultValue={format(
                          new Date(responseLocation?.created_at),
                          'dd/MM/yyyy HH:mm',
                        )}
                        disabled
                      />
                    </LimiterInputWidth>
                  </Wrap>
                )}
              </WhiteBox>

              {!dadoState.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}

              {Object.keys(responseLocation || {}).length > 0 && (
                <Link
                  to={{
                    pathname: 'redireciona-endpoint',
                    search: `query=${encodeURIComponent(
                      CryptoJS.AES.encrypt(
                        JSON.stringify({
                          is_admin: isAdminRequest,
                          uuid: responseLocation?.uuid,
                          endpoint: 'reservas-vagas-vinculado',
                          mode: 'edit',
                          nome_projeto: responseLocation?.titulo_obra,
                        }),
                        tenancy_env('REACT_APP_API_BASE_URL'),
                      ).toString(),
                    )}`,
                  }}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    width: '100%',
                    maxWidth: '140px',
                    position: 'relative',
                    bottom: '35px',
                  }}
                >
                  <ActionButton
                    style={{
                      backgroundColor: '#828282',
                    }}
                  >
                    Editar
                  </ActionButton>
                </Link>
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Reservas de Vagas (Vinculado)</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Reservas
                de Vagas (Vinculado)
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
