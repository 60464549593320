import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AiFillPrinter } from 'react-icons/ai';
import { FiLoader } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BsFilterLeft, BsFilter } from 'react-icons/bs';
import { Input } from '~/components/Forms';

import { PageContainer, TableHeader } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, WrapButtons } from './styles';

import ApiClient from '~/utils/ApiClient';
import { showToastMessage } from '~/services/notification';
import ListaPageVisitasTecnicas from '~/components/Admin/Home/ListaPageVisitasTecnicas';
import WhiteBox from '~/components/WhiteBox';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#ffffff',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
  }),
};

function VisitasTecnicas() {
  const [formData, setFormData] = useState({});
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [visitasTecnicasList, setVisitasTecnicasList] = useState([]);
  const [visitaStatusList, setVisitaStatusList] = useState([
    { label: 'Carregando...', value: '' },
  ]);
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  const visitaStatusOrderedList = [
    { label: 'Análise', value: 'Análise' },
    { label: 'Aprovado', value: 'Aprovado' },
    { label: 'Negado', value: 'Negado' },
  ];

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  const renderVisitasTecnicas = useCallback(async () => {
    try {
      setLoading(true);

      const result = await ApiClient.doRequest({
        uri: `visita-tecnica?page=${page}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setTotPage(result.data.meta.pagination.total_pages);
      setVisitasTecnicasList(result?.data?.data);
    } catch (e) {
      showToastMessage({
        type: 'error',
        text: 'Não foi possível listar as Visitas Técnicas',
      });
    } finally {
      setLoading(false);
    }
  }, [page]);

  const renderStatus = useCallback(async () => {
    try {
      setLoading(true);

      const result = await ApiClient.doRequest({
        uri: `visita-tecnica-status`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const visitaStatusResp = result.data.data;
      // console.log('visitastatus',visitaStatusResp);
      const serializedVisitaStatusList = visitaStatusOrderedList.map(
        itemwso =>
          visitaStatusResp
            .map(itemws => {
              if ((itemwso?.value || itemwso.label) === itemws.name) {
                return {
                  value: itemwso?.value,
                  label: itemwso.label,
                  uuid: itemws.uuid,
                };
              }
              return null;
            })
            .filter(itemws => itemws !== null)[0],
      );

      // console.log('serializedVisitaStatusList',serializedVisitaStatusList);
      setVisitaStatusList(serializedVisitaStatusList);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        renderVisitasTecnicas();
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [renderVisitasTecnicas],
  );

  function renderList() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && visitasTecnicasList.length <= 0) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {visitasTecnicasList.map(lista => (
          <ListaPageVisitasTecnicas
            key={lista.uuid}
            data={lista}
            loadVisitasTecnicas={renderVisitasTecnicas}
            visitaStatusList={visitaStatusList}
            // onDelete={() => handleDelete(lista.id)}
          />
        ))}
      </>
    );
  }
  useEffect(() => {
    renderStatus();
  }, [renderStatus]);

  useEffect(() => {
    renderVisitasTecnicas();
  }, [renderVisitasTecnicas]);

  return (
    <PageContainer>
      <Aside />
      <Container>
        <WhiteBox column maxHeight>
          <>
            {hiddenButton === true && (
              <span tooltip="Exibir filtro!">
                <button
                  type="button"
                  onClick={() => {
                    setShow(true);
                    setHiddenButton(false);
                  }}
                >
                  <BsFilterLeft color="#000" size={24} />
                </button>
              </span>
            )}
            {show === true && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Wrap>
                  <Input type="date" name="date_from" label="De" />
                  <Input type="date" name="date_to" label="Até" />
                  <Input
                    type="text"
                    name="palavra_chave"
                    label="Palavra Chave"
                  />
                  <WrapButtons>
                    <Button
                      type="submit"
                      btType="#26A2B1"
                      label="Buscar"
                      full
                    />
                    <span tooltip="Ocultar filtro!">
                      <button
                        type="button"
                        onClick={() => {
                          setShow(false);
                          setHiddenButton(true);
                        }}
                      >
                        <BsFilter color="#000" size={24} />
                      </button>
                    </span>
                  </WrapButtons>
                </Wrap>
              </Form>
            )}
          </>
          <table>
            <TableHeader>
              <tr>
                <th>N.º Locação</th>
                <th>Nome Obra</th>
                <th>Data Visita</th>
                <th>Status</th>
                <th style={{ justifyContent: 'center' }}>Ações</th>
              </tr>
            </TableHeader>
            <WhiteBox column aCenter>
              <tbody>{renderList()}</tbody>
              <BasicPagination />
            </WhiteBox>
          </table>
        </WhiteBox>
      </Container>
    </PageContainer>
  );
}

export default withRouter(VisitasTecnicas);
