import React, { useState, useCallback } from 'react';
import { GrDocumentPdf } from 'react-icons/gr';
import { format } from 'date-fns';
import { BsFillEyeFill } from 'react-icons/bs';
import { RiUserAddFill } from 'react-icons/ri';
import { GoClippy } from 'react-icons/go';
import { Link, withRouter } from 'react-router-dom';
import { ModalStatusCarta } from '~/components/Admin';
import ApiClient from '~/utils/ApiClient';
import { showModalConfirm, showModalMessage } from '~/services/notification';

function ListaPageCartasDeApoio({ data, cartaStatusList, loadCartasDeApoio }) {
  const [openModalStatusCartas, setOpenModalStatusCartas] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModalStatusCartas(false);
  }, []);

  const associarAdmin = async cartaUuid => {
    let confirm = false;

    await showModalConfirm({
      text: 'Confirma a associação deste Usuário Administrador à Carta de Apoio',
      callback: confirmed => {
        confirm = confirmed;
      },
    });

    if (!confirm) {
      return;
    }

    try {
      await ApiClient.doRequest({
        uri: `responsavel-carta-apoio/${cartaUuid}`,
        method: ApiClient.PATCH,
        expectedStatus: 201,
      });

      await showModalMessage({
        type: 'success',
        title: 'Sucesso',
        text: 'Associação efetuada com sucesso!',
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao tentar associar Admin!',
      });
    }
  };

const addGerarPdf = async (link) => {
  try {
    //   if(data?.status?.name=== "Aprovadas"){
    //   await ApiClient.doRequest({
    //     uri: `counter-cartas`,
    //     method: ApiClient.GET,
    //     expectedStatus: 200,
    //   })
    //   .then(window.location.href=link)
    //   .then(
    //   await showModalMessage({
    //     type: 'success',
    //     title: 'Sucesso',
    //     text: 'Carta gerada com sucesso!',
    //   }));
    // }
    // else{
      await (window.location.href=link)
    // }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao tentar gerar carta!',
      });
    }
  
}

  return (
    <>
      <tr>
        <td>
          {data && format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm')}
        </td>
        <td>{data && data?.tipo_doc}</td>
        <td>{data && data?.name_view}</td>
        <td>{data && data?.protocol}</td>
        <td>{data && data?.solicitante}</td>
        <td>{data && data?.cpf_cnpj}</td>
        <td style={{ wordBreak: 'break-word' }}>{data && data?.email}</td>
        <td
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
          className="buttons"
        >
          {/* <button
            type="button"
            style={{ margin: 2 }}
            tooltip="Arquivos"
            // onClick={() => setOpen(true)}
          >
            <AiFillCopy color="#4f4f4f" size={20} />
          </button> */}
          {/* <button
            type="button"
            style={{ margin: 2 }}
            tooltip="Editar"
            // onClick={() => setOpen(true)}
          >
            <MdEdit color="#4f4f4f" size={20} />
          </button> */}
          <span tooltip="Associar Admin" style={{ cursor: 'pointer' }}>
            <RiUserAddFill
              color="#4f4f4f"
              size={20}
              onClick={() => associarAdmin(data?.uuid)}
            />
          </span>

          <span tooltip="Gerar carta" style={{ cursor: 'pointer' }}>
            <button type="button" onClick={() => addGerarPdf(data?.doc_link)} rel="noreferrer">
              <GrDocumentPdf color="#4f4f4f" size={20} />
            </button>
          </span>

          <span tooltip="Mudar status..." style={{ cursor: 'pointer' }}>
            <button
              type="button"
              onClick={() => setOpenModalStatusCartas(true)}
            >
              <GoClippy color="#4f4f4f" size={22} />
            </button>
          </span>

          {data?.tipo_doc === 'Produções de Baixo Impacto' && (
            <span tooltip="Visualizar...">
              <Link
                to={{
                  pathname: '/solicitacao-nova-carta-de-apoio',
                  state: {
                    viewData: { uuid: data?.uuid_relacionada },
                  },
                }}
              >
                <BsFillEyeFill color="#4f4f4f" size={24} />
              </Link>
            </span>
          )}
        </td>
      </tr>
      <ModalStatusCarta
        cartaStatusList={cartaStatusList}
        carta={data}
        openModal={openModalStatusCartas}
        closeModal={handleCloseModal}
        loadCartas={loadCartasDeApoio}
      />
    </>
  );
}

export default withRouter(ListaPageCartasDeApoio);
