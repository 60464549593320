import diff from 'diff-arrays-of-objects';
import diffInnerHorasFinalidade from './diffInnerHorasFinalidade';

export default function _({
  cargaDescargaLocacao,
  cargaDescargaInput,
}) {
  // se opção não foi gravada no banco antes
  if (Object.values(cargaDescargaLocacao || []).length <= 0 
    && Object.values(cargaDescargaInput || []).length <= 0) {
    return []
  }
  
  // se a opção não for mais selecionada
  if (Object.values(cargaDescargaInput || []).length <= 0) {
    return cargaDescargaLocacao.map(({uuid, hour_acesso_calcadoes}) => {
      const horas = hour_acesso_calcadoes
        .map(hora => ({ uuid: hora.uuid, inicio: '', fim: ''}));
      return { uuid, placa: '', modelo_veiculo: '', horas }
    });
  }

  // cria listas temporarias dos cards sem as horas
  const cargaDescargaLocacaoTmp = cargaDescargaLocacao?.map(({uuid, placa, modelo_veiculo, hour_acesso_calcadoes, ld_finalidade_acesso}) => 
    ({ uuid, placa, modelo_veiculo, horas: hour_acesso_calcadoes, finalidade_acesso_uuid: ld_finalidade_acesso?.uuid  }));
  const cargaDescargaInputTmp = cargaDescargaInput.map(({uuid, placa, modelo_veiculo, horas, finalidade_acesso_uuid}) => 
    ({ uuid: uuid || '', placa, modelo_veiculo, horas, finalidade_acesso_uuid }));  

  // ve se tem diferença entre os cards
  const results = diff(cargaDescargaLocacaoTmp, cargaDescargaInputTmp, 'uuid');
  const { updated, added, removed } = results;

  // verifica e faz uma nova saída adicionando as horas para cards que foram atualizados
  const cardsAtualizados = cargaDescargaLocacaoTmp?.map(cargaDescarga => {
    const cargaDescargaUpdated = updated.find(({uuid}) => cargaDescarga.uuid === uuid)

    if (!cargaDescargaUpdated) {
      return null;
    }
    
    const horas = diffInnerHorasFinalidade({
      innerHorasLocacao: cargaDescarga.horas,
      innerHorasInput: cargaDescargaUpdated.horas
    });

    return {...cargaDescargaUpdated, horas}
  });

  // verifica e faz uma nova saída adicionando as horas para cards que foram removidos
  const cardsRemovidos = removed.map(({uuid}) => {
    const horas = cargaDescargaLocacao
      ?.find(cargaDescarga => cargaDescarga.uuid === uuid)
      ?.hour_acesso_calcadoes
      ?.map(hora => ({ uuid: hora.uuid, inicio: '', fim: '' }));
  
    return { uuid, placa: '', modelo_veiculo: '', horas };
  });

  const cargaDescargaDiff = added.concat(cardsAtualizados, cardsRemovidos).filter(item => !!item);
  return cargaDescargaDiff;  
}
