import React, { useContext, useRef, useState } from 'react';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { FixedSizeList } from 'react-window';
import Button from '~/components/Button';
import { Wrap } from '~/styles/components';
import { showToastMessage } from '~/services/notification';
import './styles.css';

const VirtualTableContext = React.createContext({
  top: 0,
  setTop: value => {},
  header: <></>,
  footer: <></>,
});

// exemplo de footer
// <tfoot>
//   <tr>
//     <td>Footer 1</td>
//     <td>Footer 2</td>
//     <td>Footer 3</td>
//     <td>Footer 4</td>
//   </tr>
// </tfoot>

const Inner = React.forwardRef(function Inner({ children, ...rest }, ref) {
  const { header, footer, top } = useContext(VirtualTableContext);
  return (
    <div {...rest} ref={ref}>
      <table
        style={{
          top,
          position: 'absolute',
          width: '100%',
        }}
      >
        {header}
        <tbody>{children}</tbody>
        {footer}
      </table>
    </div>
  );
});

export const VirtualTable = ({
  title = 'Listagem',
  columnsNames,
  row,
  header,
  footer,
  showPrinter,
  tableData,
  ...rest
}) => {
  const listRef = useRef();
  const [top, setTop] = useState(0);

  const handlePrintData = () => {
    if (columnsNames.length <= 0) {
      showToastMessage({
        type: 'error',
        text: 'Nomes das colunas não informados',
      });
      return;
    }

    if (tableData.length <= 0) {
      showToastMessage({ type: 'error', text: 'Dados não informados' });
      return;
    }

    const doc = new JsPDF();

    doc.setFontSize(18);
    doc.text(title, 14, 22);

    autoTable(doc, {
      head: [columnsNames],
      body: Object.values(tableData).map(value => Object.values(value)),
      startY: 30,
      showHead: 'firstPage',
    });

    doc.save('relatorio.pdf');
  };

  return (
    <VirtualTableContext.Provider value={{ top, setTop, header, footer }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <FixedSizeList
          {...rest}
          innerElementType={Inner}
          onItemsRendered={props => {
            const style =
              listRef.current &&
              // eslint-disable-next-line no-underscore-dangle
              listRef.current._getItemStyle(props.overscanStartIndex);
            setTop((style && style.top) || 0);

            // Call the original callback
            // eslint-disable-next-line no-unused-expressions
            rest.onItemsRendered && rest.onItemsRendered(props);
          }}
          // eslint-disable-next-line no-return-assign
          ref={el => (listRef.current = el)}
        >
          {row}
        </FixedSizeList>

        {showPrinter && (
          <Wrap style={{ justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              type="button"
              btType="#26A2B1"
              onClick={handlePrintData}
              label="Imprimir"
              full
              width="150px"
            />
          </Wrap>
        )}
      </div>
    </VirtualTableContext.Provider>
  );
};
