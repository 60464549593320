import React, { useRef, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BsFilterLeft, BsFilter } from 'react-icons/bs';
import { Input } from '~/components/Forms';

import ApiClient from '~/utils/ApiClient';

import { PageContainer, TableHeader } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, WrapButtons, TitleForm, Options } from './styles';

import { ListPageUsuarios } from '~/components/Admin';

import { showToastMessage } from '~/services/notification';
import buildQueryPackageFilters from '~/functions/buildQueryPackageFilters';
import maskString from '~/functions/maskString';
import WhiteBox from '~/components/WhiteBox';
import Option from '~/components/Option';
import { useList } from '~/hooks/useFunction';

function Usuario({ data }) {
  const formRef = useRef();
  const cities = useList({ url: 'city?paginate=false' });
  const neighborhoods = useList({ url: 'neighborhood?paginate=false' });

  const tabType = [
    { label: 'Pessoa Física', value: 'user' },
    { label: 'Pessoa Jurídica', value: 'branch-admin' },
  ];

  const [hiddenButton, setHiddenButton] = useState(true);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [item, setItem] = useState(0);

  const [userList, setUserList] = useState([]);
  const [filters, setFilters] = useState('');
  const [searchFormData, setSearchFormData] = useState({});

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  const renderUsuarios = useCallback(async () => {
    try {
      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri: `${tabType[item].value}?order[id]=desc&page=${page}${filters}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      let userData = [];
      if (tabType[item].value === 'branch-admin') {
        userData = resp?.data?.data?.map(userPj => ({
          type: 'pj',
          uuid: userPj.uuid,
          created_at: userPj?.created_at,
          cpf_cnpj: maskString(
            userPj.cnpj?.toString().padStart(14, '0'),
            '##.###.###/####-##',
          ),
          name: userPj.company_name,
          files: userPj.files,
          fantasy_name: userPj.fantasy_name,
          phone: userPj.phone,
          cep: userPj?.cep,
          street: userPj?.street,
          neighborhood: userPj?.neighborhood?.name,
          number: userPj?.number,
          complement: userPj?.complement,
          city: userPj?.neighborhood?.city?.name,
          state: userPj?.neighborhood?.city?.state?.name,
          state_abbr: userPj?.neighborhood?.city?.state?.abbr,
        }));
      } else {
        userData = resp?.data?.data?.map(userPf => ({
          type: 'pf',
          uuid: userPf.uuid,
          created_at: userPf.user_personal_data?.created_at,
          cpf_cnpj: maskString(
            userPf.user_personal_data?.cpf?.toString().padStart(11, '0'),
            '###.###.###-##',
          ),
          name: userPf.user_personal_data?.name,
          files: userPf.files,
          email: userPf.email,
          rg: userPf.user_personal_data?.rg,
          phone_mobile: userPf.user_personal_data?.phone_mobile,
          drt: userPf.user_personal_data?.drt,
          drt_uf: userPf.user_personal_data?.uf,
          birth_date: userPf.user_birth_address?.birth_date,
          cep: userPf.user_address?.cep,
          street: userPf.user_address?.street,
          neighborhood: userPf.user_address?.neighborhood?.name,
          number: userPf.user_address?.number,
          complement: userPf.user_address?.complement,
          city: userPf.user_address?.neighborhood?.city?.name,
          state: userPf.user_address?.neighborhood?.city?.state?.name,
          state_abbr: userPf.user_address?.neighborhood?.city?.state?.abbr,
        }));
      }

      setTotPage(resp.data.meta.pagination.total_pages);
      setUserList(userData);
    } catch {
      showToastMessage({
        type: 'error',
        text: `Não foi possível listar os usuários de ${tabType[item].label}`,
      });
    } finally {
      setLoading(false);
    }
  }, [filters, item, page]);

  useEffect(() => {
    renderUsuarios();
  }, [renderUsuarios]);

  const handleSubmit = useCallback(
    async _ => {
      try {
        formRef.current.setErrors({});

        setPage(1);
        setFilters(buildQueryPackageFilters(searchFormData));
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [searchFormData],
  );

  const renderList = useCallback(() => {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && !userList.length) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {userList.map(user => (
          <ListPageUsuarios key={user.id} data={user} onClose={renderUsuarios} cities={cities} neighborhoods={neighborhoods} />
        ))}
      </>
    );
  }, [loading, userList, cities, neighborhoods]);

  function handleResetSearchFields() {
    setPage(1);
    setFilters('');
    setSearchFormData({
      created_at: { value: '' },
      name: { value: '' },
      cpf: { value: '' },
      cnpj: { value: '' },
      company_name: { value: '' },
      fantasy_name: { value: '' },
    });
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    setSearchFormData(state => ({
      ...state,
      [name]: {
        value: type === 'checkbox' ? checked : value,
        filterName: e.target?.getAttribute('filterName'),
        filterOperator: e.target?.getAttribute('filterOperator'),
      },
    }));
  }

  function handleOnlyNumber(event) {
    if (!/^[0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Wrap>
          <TitleForm>
            <h4>Usuários</h4>
          </TitleForm>
        </Wrap>
        <Options>
          {tabType.map(type => (
            <Option
              active={item === tabType.indexOf(type)}
              onClick={() => {
                setItem(tabType.indexOf(type));
                handleResetSearchFields();
              }}
            >
              {type.label}
            </Option>
          ))}
        </Options>
        <WhiteBox column maxHeight>
          {hiddenButton === true && (
            <span tooltip="Exibir filtro!">
              <button
                type="button"
                onClick={() => {
                  setShow(true);
                  setHiddenButton(false);
                }}
              >
                <BsFilterLeft color="#000" size={24} />
              </button>
            </span>
          )}
          {show === true && (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Wrap>
                <Input
                  type="date"
                  name="created_at"
                  label="Data de Cadastro"
                  value={searchFormData?.created_at?.value}
                  onChange={handleChange}
                  filterName={
                    tabType[item].value === 'user'
                      ? 'user_personal_data.created_at'
                      : 'created_at'
                  }
                />
                {tabType[item].value === 'user' && (
                  <>
                    <Input
                      type="text"
                      name="cpf"
                      label="CPF (somente números)"
                      value={searchFormData?.cpf?.value}
                      maxLength={11}
                      onKeyPress={handleOnlyNumber}
                      onChange={handleChange}
                      filterName="user_personal_data.cpf"
                      filterOperator="equal"
                    />
                    <Input
                      type="text"
                      name="name"
                      label="Nome"
                      value={searchFormData?.name?.value}
                      onChange={handleChange}
                      filterName="user_personal_data.name"
                    />
                  </>
                )}

                {tabType[item].value === 'branch-admin' && (
                  <>
                    <Input
                      type="text"
                      name="cnpj"
                      label="CNPJ (somente números)"
                      value={searchFormData?.cnpj?.value}
                      maxLength={14}
                      onKeyPress={handleOnlyNumber}
                      onChange={handleChange}
                      filterOperator="equal"
                    />
                    <Input
                      type="text"
                      name="company_name"
                      label="Razão Social"
                      value={searchFormData?.company_name?.value}
                      onChange={handleChange}
                    />
                    <Input
                      type="text"
                      name="fantasy_name"
                      label="Nome Fantasia"
                      value={searchFormData?.fantasy_name?.value}
                      onChange={handleChange}
                    />
                  </>
                )}
              </Wrap>
              <Wrap>
                <WrapButtons>
                  <Button
                    type="submit"
                    btType="#26A2B1"
                    label="Buscar"
                    full
                    width="170px"
                  />
                  <Button
                    btType="#707683"
                    onClick={handleResetSearchFields}
                    label="Limpar"
                    full
                    width="100px"
                  />
                  <span tooltip="Ocultar filtro">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false);
                        setHiddenButton(true);
                      }}
                    >
                      <BsFilter color="#000" size={24} />
                    </button>
                  </span>
                </WrapButtons>
              </Wrap>
            </Form>
          )}
          <Wrap>
            <table>
              <TableHeader>
                <tr>
                  <th>Data de Cadastro</th>
                  <th>{tabType[item].value === 'user' ? 'CPF' : 'CNPJ'}</th>
                  <th>
                    {tabType[item].value === 'user' ? 'Nome' : 'Razão Social'}
                  </th>
                  {tabType[item].value === 'branch-admin' && (
                    <th>Nome Fantasia</th>
                  )}
                  <th>Ações</th>
                </tr>
              </TableHeader>
              <WhiteBox column aCenter>
                <tbody>{renderList()}</tbody>
                <BasicPagination />
              </WhiteBox>
            </table>
          </Wrap>
        </WhiteBox>
      </Container>
    </PageContainer>
  );
}

export default withRouter(Usuario);
