import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 15px;
    color: rgb(130, 130, 130);
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px #d6d6d6 solid;
    padding: 16px;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: rgb(130, 130, 130);
    background-color: transparent;
    /* box-shadow: 0 3px 12px rgba(0, 0, 0, 0.075); */

    &[disabled] {
      background-color: #ededed;
      &::placeholder {
        content: '';
      }
    }

    &::-webkit-input-placeholder {
      opacity: 0.65;
    }

    &:-ms-input-placeholder {
      opacity: 0.65;
    }

    &:-moz-placeholder {
      opacity: 0.65;
    }

    &::-moz-placeholder {
      opacity: 0.65;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0;
    top: 8px;

    svg {
      color: #f2f2f2;
    }
  }

  span {
    font-size: 14px;
    font-family: 'InterBold', sans-serif;
    color: #ff0000;
    margin: 4px 0;
  }
`;
