import diff from 'diff-arrays-of-objects';

export default function _({
  innerHorasLocacao,
  innerHorasInput
}) {
  if (
    JSON.stringify(innerHorasLocacao) ===
    JSON.stringify(innerHorasInput)
  ) {
    return [];
  }

  const results = diff(innerHorasLocacao, innerHorasInput, 'uuid');
  const { updated, added, removed } = results;

  const innerHorasDiff = added.concat(updated, removed?.map(hora => ({ uuid: hora.uuid, inicio: '', fim: '' })));

  return innerHorasDiff;
}