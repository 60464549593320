import jwtDecoded from 'jwt-decode';
import moment from "moment";
// eslint-disable-next-line import/no-cycle
import AuthService from "./AuthService";

const STORAGE_KEY_TOKEN = '@meuapp/token'

class ApiToken {

    authService;

    constructor() {
        this.token = localStorage.getItem(STORAGE_KEY_TOKEN);
    }

    static get() {
        return new ApiToken();
    }

    static saveToken(token) {
        localStorage.setItem(STORAGE_KEY_TOKEN, token);
    }

    static logout() {
        localStorage.removeItem(STORAGE_KEY_TOKEN);
    }

  // eslint-disable-next-line class-methods-use-this
    formatToken(token) {
        return `Bearer ${token}`
    }

    async verifyToken() {
        const {exp} = jwtDecoded(this.token);
        const currentTime = moment().unix();

        if ((currentTime + 20) >= exp) {
            return this.refreshToken();
        }

        return this.formatToken(this.token);

    }

    async refreshToken() {

      // eslint-disable-next-line no-useless-catch
        try {

            this.authService = new AuthService();
            const token = this.formatToken(this.token);
            const response = await this.authService.doRefreshToken({token});

            ApiToken.saveToken(response.data.token);
            return this.formatToken(response.data.token);

        } catch (e) {
            throw  e;
        }

    }

    static tokenDecode() {
      // eslint-disable-next-line no-useless-catch
        try {

            const token = localStorage.getItem(STORAGE_KEY_TOKEN);
            if (token === null) {
                return {};
            }
            return jwtDecoded(token);

        } catch (e) {
            throw e;
        }
    }

}

export default ApiToken;
