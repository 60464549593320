import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { FiLoader } from 'react-icons/fi';
import { IoMdSend } from 'react-icons/io';
import WhiteBox from '~/components/WhiteBox';
import { useAuth } from '~/hooks/auth';
import { BoxHeader } from '~/pages/Usuario/styles';
import {
  showModalConfirm,
  showModalMessage,
  showToastMessage,
} from '~/services/notification';
import ApiClient from '~/utils/ApiClient';
import {
  ButtonMessage,
  ChatContainer,
  ChatMessage,
  ChatMessageBody,
  ChatMessageInfo,
  Container,
  EmptyMessage,
  InputContainer,
  InputMessage,
} from './styles';

const ObservacoesChat = ({ workUuid }) => {
  const messagesRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { user: userAdmin } = useAuth();

  const getMessages = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `work/${workUuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const messagesAux = resp?.data?.data?.comments_admin;
      setMessages(
        Object.values(messagesAux || []).length > 0 ? messagesAux : [],
      );
    } catch (error) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao buscar observações de Admins',
      });
    } finally {
      setLoading(false);
    }
  }, [workUuid]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const handleCommentDelete = useCallback(
    async commentUuid => {
      let confirm = false;

      await showModalConfirm({
        type: 'info',
        text: 'Deseja excluir o comentário?',
        callback: confirmed => {
          confirm = confirmed;
        },
      });

      if (!confirm) {
        return;
      }

      try {
        setLoading(true);

        await ApiClient.doRequest({
          uri: `comments-admin/${commentUuid}`,
          method: ApiClient.DELETE,
          expectedStatus: 201,
        });

        getMessages();
      } catch (error) {
        await showModalMessage({
          type: 'error',
          text: 'Erro ao tentar excluir comentário',
        });
      } finally {
        setLoading(false);
      }
    },
    [getMessages],
  );

  const handleSendMessage = useCallback(
    async e => {
      e.preventDefault();
      try {
        setLoading(true);
        await ApiClient.doRequest({
          uri: `comments-admin`,
          method: ApiClient.POST,
          expectedStatus: 201,
          body: {
            comment: message,
            work_uuid: workUuid,
          },
        });

        getMessages();
        setMessage('');
      } catch (error) {
        await showModalMessage({
          type: 'error',
          text: 'Erro ao tentar salvar observações',
        });
      } finally {
        setLoading(false);
      }
    },
    [getMessages, message, workUuid],
  );

  useEffect(() => {
    if (!loading) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [loading, messages]);

  return (
    <WhiteBox column jCenter aCenter marginBottom="30px">
      <Container>
        <BoxHeader>
          <span className="boxText">Observações de Admins</span>
        </BoxHeader>
        <ChatContainer
          ref={messagesRef}
          hasMessages={messages.length > 0}
          loading={loading}
        >
          {loading ? (
            <FiLoader size={20} className="icon-spin" />
          ) : (
            <>
              {messages?.length <= 0 ? (
                <EmptyMessage>Nenhuma observação até o momento</EmptyMessage>
              ) : (
                messages.map(msg => (
                  <ChatMessage
                    key={msg.uuid}
                    ownMessage={msg.user.uuid === userAdmin.uuid}
                  >
                    <ChatMessageBody>
                      <span>{msg.comment}</span>
                      <ChatMessageInfo>
                        {msg.user.uuid !== userAdmin.uuid && (
                          <span>{msg.user?.user_personal_data?.name}</span>
                        )}
                        {msg.user.uuid === userAdmin.uuid && (
                          <FaTrash
                            color="#fff"
                            size={14}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleCommentDelete(msg.uuid)}
                          />
                        )}
                        <small>
                          {format(new Date(msg.created_at), 'dd/MM/yyyy HH:mm')}
                        </small>
                      </ChatMessageInfo>
                    </ChatMessageBody>
                  </ChatMessage>
                ))
              )}
            </>
          )}
        </ChatContainer>

        <InputContainer>
          <InputMessage
            rows={2}
            disabled={loading}
            placeholder="Escreva aqui sua mensagem..."
            onChange={e => setMessage(e.target.value)}
            value={message}
          />
          <ButtonMessage onClick={handleSendMessage} disabled={loading}>
            <IoMdSend size={24} />
          </ButtonMessage>
        </InputContainer>
      </Container>
    </WhiteBox>
  );
};

export default ObservacoesChat;
