import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { BsFillEyeFill, BsFillChatDotsFill } from 'react-icons/bs';

import { FaPen } from 'react-icons/fa';
import { GrUserWorker } from 'react-icons/gr';
import { IoMdMail } from 'react-icons/io';

function ListaPageLocacoes({ data, nomeProjeto }) {
  return (
    <>
      <tr>
        <td>
          {data && data?.locacaoNumber}
          {data?.hasUnreadDocChat && (
            <span
              tooltip="Consta documentos não lidos no Chat"
              style={{ cursor: 'help', marginLeft: 5 }}
            >
              <BsFillChatDotsFill size={14} color="#26A2B1" />
            </span>
          )}
        </td>
        <td>{data && data?.name}</td>
        <td>{data && data?.type}</td>
        <td>{data && data?.status}</td>
        <td
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="buttons"
        >
          {/* <button
            type="button"
            style={{ margin: 2 }}
            tooltip="Arquivos"
            // onClick={() => setOpen(true)}
          >
            <AiFillCopy color="#4f4f4f" size={20} />
          </button> */}
          {/* <button
            type="button"
            style={{ margin: 2 }}
            tooltip="Editar"
            // onClick={() => setOpen(true)}
          >
            <MdEdit color="#4f4f4f" size={20} />
          </button> */}
          <span tooltip="Mais detalhes...">
            <Link
              to={{
                pathname: `/solicitacao-nova-locacao/${data?.endpoint}`,
                state: { viewData: data, nome_projeto: nomeProjeto },
              }}
              style={{ margin: 6 }}
            >
              <BsFillEyeFill color="#4f4f4f" size={20} />
            </Link>
          </span>
          <span tooltip="Editar">
            <Link
              to={{
                pathname: `/solicitacao-nova-locacao/${data?.endpoint}/edit`,
                state: { viewData: data, nome_projeto: nomeProjeto },
              }}
              style={{ margin: 4 }}
            >
              <FaPen color="#4f4f4f" size={18} />
            </Link>
          </span>
          {!['Locação Utilização de Via', 'Locação Reserva de Vaga'].includes(
            data?.type,
          ) && (
            <span tooltip="Nova Visita Técnica...">
              <Link
                to={{
                  pathname: `/solicitacao-nova-locacao/${data?.endpoint}/visita-tecnica`,
                  state: {
                    uuid: data?.uuid,
                    name_view: data?.type,
                    nome_projeto: nomeProjeto,
                  },
                }}
                style={{ margin: 4 }}
              >
                <GrUserWorker color="#4f4f4f" size={18} />
              </Link>
            </span>
          )}
          <span tooltip="Nova Carta de Apoio...">
            <Link
              to={{
                pathname: 'solicitacao-nova-carta-de-apoio',
                state: {
                  trigger: 'locacao',
                  locacao_uuid: data?.uuid,
                  name_view: data?.type,
                },
              }}
              style={{ margin: 4 }}
            >
              <IoMdMail color="#4f4f4f" size={18} />
            </Link>
          </span>
        </td>
      </tr>
    </>
  );
}

export default withRouter(ListaPageLocacoes);
