import React, { useState, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FaPen } from 'react-icons/fa';
import CryptoJS from 'crypto-js';
import { GoClippy, GoLinkExternal } from 'react-icons/go';
import { ModalStatusLocacao } from '~/components/Admin';
import tenancy_env from '~/utils/MultiTenancyUtil';

function ListaLocacaoObra({ data, loadLocacoesObra }) {
  const [openModalStatusLocacao, setOpenModalStatusLocacao] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModalStatusLocacao(false);
  }, []);

  return (
    <>
      <tr>
        <td>{data && data?.locacaoNumber}</td>
        <td>{data && data?.name}</td>
        <td>{data && data?.status}</td>
        <td>{data && data?.responsavelObra}</td>
        <td>{data && data?.responsavelAtendimento}</td>
        <td
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-evenly',
          }}
        >
          <span tooltip="Mudar status...">
            <button
              type="button"
              onClick={() => setOpenModalStatusLocacao(true)}
            >
              <GoClippy color="#4f4f4f" size={22} />
            </button>
          </span>

          <span tooltip="Mais detalhes...">
            <Link
              to={{
                pathname: 'redireciona-endpoint',
                search: `query=${encodeURIComponent(
                  CryptoJS.AES.encrypt(
                    JSON.stringify({
                      is_admin: true,
                      uuid: data?.uuid,
                      endpoint: data?.endpoint,
                      mode: 'view',
                      nome_projeto: data?.work?.nome_projeto,
                    }),
                    tenancy_env('REACT_APP_API_BASE_URL'),
                  ).toString(),
                )}`,
              }}
              target="_blank"
            >
              <GoLinkExternal color="#4f4f4f" size={24} />
            </Link>
          </span>

          <span tooltip="Editar">
            <Link
              to={{
                pathname: `/solicitacao-nova-locacao/${data?.endpoint}/edit`,
                state: { viewData: data, isAdminRequest: true },
              }}
              style={{ margin: 2 }}
            >
              <FaPen color="#4f4f4f" size={20} />
            </Link>
          </span>
        </td>
      </tr>

      <ModalStatusLocacao
        locacao={data}
        openModal={openModalStatusLocacao}
        closeModal={handleCloseModal}
        loadLocacoesObra={loadLocacoesObra}
      />
    </>
  );
}

export default withRouter(ListaLocacaoObra);
