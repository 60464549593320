import styled from "styled-components";

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 10px;
    }
  }
`;
export const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const LimiterInputWidth = styled.div`
  display: flex;
  margin-right: 16px;
  ${(props) => (props.w25 ? "width: 24%;" : "")}
  ${(props) => (props.w50 ? "width: 49%;" : "")}
  ${(props) => (props.marginNone ? "margin: 0;" : "")}
  margin-bottom: ${({ marginBottom }) => (marginBottom ? [marginBottom] : "0")};

  &:nth-child(4) {
    margin-right: 0;
  }
`;
