/* eslint-disable */
import AuthService from "~/utils/AuthService";

class MeUtil {

  authService;

  constructor() {
    this.authService = new AuthService();
  }

  static async get() {
    const meUtil = new MeUtil();
    return await meUtil.get();
  }

  static async hasRole(...roles) {
    const meUtil = new MeUtil();
    const response = await meUtil.get();

    const result = response.data.data.roles.map(({name}) => name);

    const intersection = roles.filter(x => result.includes(x));

    return !(intersection.length === 0);

  }

  async get() {
    try {
      return this.authService.me();
    } catch (e) {
      throw e;
    }
  }

  static get ROLE_ADMINISTRADOR() {
    return 'Administrador';
  }

  static get ROLE_MODERADOR() {
    return 'Moderador';
  }

  static get ROLE_PESSOA_FISICA() {
    return 'Pessoa Fisica';
  }

  static get ROLE_PESSOA_JURIDICO() {
    return 'Pessoa Jurídico';
  }

}

export default MeUtil;
