import styled from "styled-components";

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 10px;
    }
  }
`;
export const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const LimiterInputWidth = styled.div`
  display: flex;
  margin-right: 16px;
  ${(props) => (props.w25 ? "width: 24%;" : "")}
  ${(props) => (props.w50 ? "width: 49%;" : "")}

  &:nth-child(4) {
    margin-right: 0;
  }
`;

export const InputFileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InputFile = styled.div`
  width: 50%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 5px;
  &:first-child {
    margin-right: 13px;
  }

  span {
    color: #828282;
    font-size: 16px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
`;
