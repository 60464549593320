import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AiFillPrinter } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { FiLoader } from 'react-icons/fi';
import { Input } from '~/components/Forms';
import LogsActions from '~/store/ducks/logs';

// import { format } from "date-fns";
// import pt from "date-fns/locale/pt";

import { PageContainer, TableHeader } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import {
  Container,
  Wrap,
  WrapButtons,
  TitleForm,
  TableTopContent,
  TableButtons,
} from './styles';

import { ListaPageRelatorioLogin } from '~/components/Admin';
import WhiteBox from '~/components/WhiteBox';

function RelatorioLogin() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [date, setDate] = useState('');
  const [dataListagem, setDataListagem] = useState([]);
  const formRef = useRef();
  const {
    data: userLogs,
    pagination,
    loading,
  } = useSelector(state => state.logs);

  const logs = useCallback(() => {
    dispatch(
      LogsActions.request({
        pagina: page,
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    logs();
  }, [logs]);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }
  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={pagination?.meta?.pagination?.count}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }

  const listagemData = useCallback(() => {
    const listagem = userLogs.map(({ uuid, ip, user }) => ({
      id: uuid,
      ip,
      name: user?.user_personal_data?.name,
    }));
    setDataListagem(listagem);
  }, [userLogs]);

  useEffect(() => {
    listagemData();
  }, [listagemData]);

  async function handleSubmit() {
    try {
      formRef.current.setErrors({});
      dispatch(LogsActions.request(formData));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function renderList() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (loading && !!userLogs) {
      return 'Nenhum registro encontrado';
    }
    return (
      <>
        {userLogs.map(lista => (
          <ListaPageRelatorioLogin
            key={lista.uuid}
            data={lista}
            // onDelete={() => handleDelete(lista.id)}
          />
        ))}
      </>
    );
  }

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrap>
            <TitleForm>
              <h4>Relatório de Login</h4>
            </TitleForm>
          </Wrap>
          <Wrap>
            <Input
              type="text"
              name="name"
              label="Nome"
              onChange={handleChange}
              value={formData.name}
            />
            <Input
              type="date"
              name="date"
              label="Data"
              onChange={handleChange}
              value={formData.date}
            />
            <WrapButtons>
              <Button
                type="submit"
                btType="#26A2B1"
                action={handleSubmit}
                label="Buscar"
                full
                width="170px"
                loading={loading}
              />
            </WrapButtons>
          </Wrap>
        </Form>

        <WhiteBox column>
          <table>
            <TableTopContent>
              <TableButtons>
                <Link
                  to={{
                    pathname: '/imprimir-relatorio-login',
                    state: { data: dataListagem },
                  }}
                >
                  <AiFillPrinter color="#4f4f4f" size={24} />
                  Imprimir Relatório
                </Link>
              </TableButtons>
            </TableTopContent>
            <TableHeader>
              <tr>
                <th>Nome</th>
                <th>Data</th>
                <th>Hora</th>
                <th>Ip</th>
              </tr>
            </TableHeader>
            <WhiteBox column aCenter>
              <tbody>{renderList()}</tbody>
              <BasicPagination />
            </WhiteBox>
          </table>
        </WhiteBox>
      </Container>
    </PageContainer>
  );
}

export default withRouter(RelatorioLogin);
