/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import Modal from 'react-modal';
import 'swiper/css/swiper.css';

import {
  AiOutlineCheckCircle,
  AiFillLock,
  AiOutlineUser,
  AiOutlineArrowLeft,
} from 'react-icons/ai';

import * as Yup from 'yup';
import { Form } from '@unform/web';

import { useSelector } from 'react-redux';

import {
  LandingContainer,
  LandingHeader,
  LandingContent,
  LandingBox,
  NavHeader,
  LandingSlide,
  LandingFooter,
  CardSlide,
  WrapModal,
  overlayStyle,
  contentStyle,
  Wrap,
  AddressFooter,
  HeaderActions,
  LogosProjeto,
  LogosSocial,
} from './styles';

import Button from '~/components/Button';
import infoBalls from '~/assets/images/info-balls.png';
import searchExample from '~/assets/images/acesso/search-form-image.png';

// social media
import FacebookLogoIcon from '~/assets/images/acesso/rodape-icons/facebook.png';
import InstagramLogoIcon from '~/assets/images/acesso/rodape-icons/instagram-logo.png';
import TwitterLogoIcon from '~/assets/images/acesso/rodape-icons/twitter.png';
import YoutubeLogoIcon from '~/assets/images/acesso/rodape-icons/youtube.png';
import TelegramLogoIcon from '~/assets/images/acesso/rodape-icons/telegram.png';
import LinkedinLogoIcon from '~/assets/images/acesso/rodape-icons/linkedin.png';

// endereco icons
import PhoneCallIcon from '~/assets/images/acesso/rodape-icons/phone-call.png';
import PointerMapIcon from '~/assets/images/acesso/rodape-icons/point-map.png';
import MailLogoIcon from '~/assets/images/acesso/rodape-icons/mail.png';

import { dataSlider, dataSliderIlhacine } from '~/data/catalogoSlide';
import { Input } from '~/components/Forms';

import api from '~/services/api';
import { useAuth } from '~/hooks/auth';
import { showModalMessage } from '~/services/notification';
import LogoIcon from '~/components/LogoIcon';
import CatalogoLocacao from '~/assets/documets/CatalogoLocacaoIlhaBela.pdf';
import { getSubdomainName } from '~/utils/MultiTenancyUtil';
import clientResources from '~/data/clientResources';

export default function Landing() {
  const formLogin = useRef();
  const formRegister = useRef();
  const formRecovery = useRef();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [emailRecovery, setEmailRecovery] = useState(null);
  const [captcha, setCaptcha] = useState(null);

  const { signIn } = useAuth();
  const { countError } = useSelector(state => state.auth);
  const history = useHistory();

  const [swiper, updateSwiper] = useState(null);

  let RenderSlider = dataSlider.map(slide => (
    <CardSlide key={slide.id}>
      <div className="card-left">
        <AiOutlineCheckCircle />
      </div>
      <div className="card-right">
        <div className="card-title">{slide.title}</div>
        <div className="card-body">
          <p>{slide.text}</p>
        </div>
      </div>
    </CardSlide>
  ));

  if (clientResources.tenant === 'ilhacine') {
    RenderSlider = dataSliderIlhacine.map(slide => (
      <CardSlide key={slide.id}>
        <div className="card-left">
          <AiOutlineCheckCircle />
        </div>
        <div className="card-right">
          <div className="card-title">{slide.title}</div>
          <div className="card-body">
            <p>{slide.text}</p>
          </div>
        </div>
      </CardSlide>
    ));
  }

  const params = {
    swiper,
    slidesPerView: 3,
    spaceBetween: 30,
    initialSlide: 0,
    loopFillGroupWithBlank: true,
    freeMode: true,
    getSwiper: updateSwiper,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  function closeModal() {
    setOpen(false);
  }

  function openModal(type) {
    setOpen(true);
    setModalType(type);
  }

  function handleChangeRecovery(event) {
    const { name, value } = event.target;
    setEmailRecovery(value);
  }

  function onChange(value) {
    setCaptcha(value);
  }

  async function handleSubmitRegister(data) {
    try {
      formRegister.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('E-mail inválido')
          .required('Digite um e-mail válido.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('user', data);

      await showModalMessage({
        type: 'success',
        text: 'Usuário cadastrado com sucesso, confira seu e-mail!',
        timer: 10,
      });

      closeModal();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRegister.current.setErrors(validationErrors);
      }

      if (err.response?.status === 400) {
        await showModalMessage({
          type: 'error',
          text: 'E-mail já cadastrado!',
        });
      }
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = useCallback(
    async data => {
      setLoading(true);
      try {
        formLogin.current.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('Digite seu e-mail de acesso'),
          password: Yup.string().required('A senha é obrigatória'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const resp = await signIn({
          email: data.email,
          password: data.password,
        });

        setLoading(false);
        await showModalMessage({
          type: 'success',
          text: 'Bem vindo',
        });
      } catch (err) {
        setLoading(false);
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formLogin.current.setErrors(validationErrors);
        }
        await showModalMessage({
          type: 'error',
          text: 'Erro ao efetuar o login, verifique os seus dados',
        });
      }
    },
    [signIn],
  );

  useEffect(() => {}, []);

  const doRequestResetPassword = useCallback(async data => {
    setLoading(true);
    try {
      formRecovery.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('E-mail inválido')
          .required('Digite seu e-mail de acesso'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.patch('user/request-reset', data);

      await showModalMessage({
        type: 'success',
        text: `Email de recuperação foi enviado para ${data.email}`,
      });

      closeModal();
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Email não foi encontrado!',
      });

      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRecovery.current.setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // PDF ilha bela

  const subdominio = getSubdomainName();
  const dadosSubdominio =
    clientResources[subdominio] || clientResources['spcine'];

  function handleCatalogoLocacao() {
    if (subdominio === 'ilhacine') {
      window.open(CatalogoLocacao, '_blank');
      return;
    }
    window.open('https://catalogo.filmesp.com', '_blank');
  }

  return (
    <>
      <LandingContainer>
        <LandingHeader>
          <NavHeader>
            <LogoIcon disabled />
            <HeaderActions>
              <Button
                btType="#26A2B1"
                full
                width="185px"
                label="Catálogo de Locações"
                title="Acessar o Catálogo de Locações"
                action={handleCatalogoLocacao}
                style={{ padding: 10, margin: 3 }}
              />
              <Button
                btType="#26A2B1"
                full
                width="185px"
                label="Profissionais e Serviços"
                title="Acessar o Catálogo de Profissionais e Serviços"
                action={() => history.push('catalogo/profissional-servico')}
                style={{ padding: 10, margin: 3 }}
              />
              {/* {subdominio === 'ilhabela_spcine' && (
                <Button
                  as={Link}
                  to={clientResources.login.urlDoc}
                  btType="#26A2B1"
                  full
                  width="185px"
                  label="Catálogo de Locações"
                  style={{ paddingLeft: 35, margin: 3 }}
                />
              )} */}
              <Button
                btType="#26A2B1"
                full
                width="185px"
                label="Acessar"
                action={() => openModal('login')}
                style={{ padding: 10, margin: 3 }}
              />
              <Button
                btType="#26A2B1"
                full
                width="185px"
                label="Perguntas Frequentes"
                action={() =>
                  history.push(clientResources.login.duvidasFrequentes)
                }
                style={{ padding: 10, margin: 3 }}
              />
            </HeaderActions>
          </NavHeader>
          <img src={clientResources.images.login.heroLoginBg} alt="Banner" />
          <p>
            O mais completo sistema de
            <br />
            <strong>cinema e audiovisual do Brasil</strong>
          </p>
        </LandingHeader>
        <img
          src={clientResources.images.login.infoBalls}
          alt="Informações"
          style={{ width: '100%' }}
        />
        <LandingContent>
          <LandingBox>
            <div className="divApresentation">
              {/* <img src={catalogoExample} alt="login-example" /> */}
              <p style={{ fontSize: 26 }}>
                Encontre e ofereça serviços em nosso Catálogo de Profissionais e
                Serviços, a ferramenta virtual de consulta e difusão de serviços
                e recursos humanos e técnicos dentro de uma produção
                audiovisual.
              </p>
            </div>
            <div className="divApresentation">
              <p>
                <ul className="check-list">
                  <li>Trâmite e tratativas online</li>
                  <li>Formulários por tipos de locação</li>
                  <li>Cadastros individuais</li>
                  <li>Acompanhamento da solicitação em tempo real</li>
                  <li>Campos informativos</li>
                </ul>
              </p>
              <img src={searchExample} alt="login-example" />
            </div>
          </LandingBox>
          <LandingBox>
            <span>{clientResources.labels.login.descriptionLogin}</span>

            <LandingSlide>
              <Swiper {...params}>{RenderSlider}</Swiper>
            </LandingSlide>
          </LandingBox>

          <LandingBox>
            <Button
              btType="#26A2B1"
              full
              width="260px"
              label="Acessar"
              action={() => openModal('login')}
              style={{ margin: '0 10px' }}
            />
          </LandingBox>
        </LandingContent>
        <LandingFooter>
          <LogosProjeto>
            <span>{clientResources.images.login.lafcnLogoFooter}</span>
            <span>
              {clientResources.images.login.associationFilmLogoFooter}
            </span>
            <span>{clientResources.images.system.spcineLogoNegativo}</span>
            <span>{clientResources.images.system.culturaSpLogoNegativo}</span>
          </LogosProjeto>

          <LogosSocial>
            <a>{clientResources.social.login.facebook}</a>
            <a>{clientResources.social.login.twitter}</a>
            <a>{clientResources.social.login.instagram}</a>
            <a>{clientResources.social.login.telegram}</a>
            <a>{clientResources.social.login.youtube}</a>
            <a>{clientResources.social.login.linkedin}</a>
          </LogosSocial>

          <AddressFooter>
            <p> {clientResources.infos.end} </p>
            <p>{clientResources.infos.tel}</p>
            <p>{clientResources.infos.email}</p>
            <p>{clientResources.infos.obs}</p>
          </AddressFooter>
        </LandingFooter>
      </LandingContainer>
      <Modal
        ariaHideApp={false}
        onRequestClose={closeModal}
        isOpen={open}
        contentLabel="Cadastro"
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <WrapModal>
          {modalType === 'login' && (
            <>
              <h1>Faça seu login</h1>
              <Form ref={formLogin} onSubmit={handleSubmit}>
                <Wrap>
                  <Input name="email" label="E-mail" />
                </Wrap>
                <Wrap>
                  <Input name="password" label="Password" type="password" />
                </Wrap>
                <Wrap className="forgot-password">
                  <div className="buttons">
                    <button type="button" onClick={() => openModal('register')}>
                      <AiOutlineUser />
                      Cadastre-se
                    </button>

                    <button type="button" onClick={() => openModal('recovery')}>
                      <AiFillLock />
                      Esqueci minha senha
                    </button>
                  </div>
                  <div className="button">
                    <Button
                      type="submit"
                      btType="#26A2B1"
                      label="Acessar"
                      full
                      width="140px"
                      loading={loading}
                      disabled={loading}
                    />
                  </div>
                </Wrap>
              </Form>
            </>
          )}

          {modalType === 'recovery' && (
            <>
              <h1>Recuperar senha</h1>
              <Form ref={formRecovery} onSubmit={doRequestResetPassword}>
                <Wrap>
                  <Input
                    name="email"
                    label="E-mail"
                    onChange={handleChangeRecovery}
                  />
                </Wrap>
                <Wrap className="forgot-password">
                  <div className="buttons">
                    <button type="button" onClick={() => openModal('login')}>
                      <AiOutlineArrowLeft />
                      Voltar para login
                    </button>
                  </div>
                  <Button
                    type="submit"
                    btType="#26A2B1"
                    label="Enviar"
                    full
                    width="167px"
                    loading={loading}
                    disabled={loading}
                  />
                </Wrap>
              </Form>
            </>
          )}

          {modalType === 'register' && (
            <>
              <h1>Cadastro</h1>
              <Form ref={formRegister} onSubmit={handleSubmitRegister}>
                <Wrap>
                  <Input name="email" label="E-mail" />
                </Wrap>
                <Wrap className="forgot-password">
                  <div className="buttons">
                    <button type="button" onClick={() => openModal('login')}>
                      <AiOutlineArrowLeft />
                      Voltar para login
                    </button>
                  </div>
                  <Button
                    type="submit"
                    btType="#26A2B1"
                    label="Cadastrar"
                    full
                    width="167px"
                    loading={loading}
                    disabled={loading}
                  />
                </Wrap>
              </Form>
            </>
          )}
        </WrapModal>
      </Modal>
    </>
  );
}
