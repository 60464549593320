/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { format, parse } from 'date-fns';
import omitEmpty from 'omit-empty';
import { FiLoader } from 'react-icons/fi';
import {
  GridContainer,
  LimiterInputWidth,
  Line,
  Wrap,
} from '~/styles/components';
import { useList } from '~/hooks/useFunction';
import { Input, Select } from '~/components/Forms';
import Button from '~/components/Button';
import MultiDateInput from '~/components/Forms/MultiDateInput';
import { showModalMessage } from '~/services/notification';
import { VirtualTable } from '~/components/Forms/VirtualTable';
import { AccordionItem } from '~/components/Forms/AccordionItem';
import { Text } from '~/components/Forms/Text';
import ApiClient from '~/utils/ApiClient';
import { RadioNoForm } from '~/components/Forms/RadioNoForm';
import { BoxHeader } from '~/pages/Usuario/styles';
import { SplitButton } from '~/components/SplitButton';
import InputNumber from '~/components/Forms/InputNumber';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const uri = 'report-socioeconomica';

const sustentabilidadeList = [
  { value: 'both', label: 'Todos os casos' },
  { value: null, label: 'Casos sem essa política' },
  { value: true, label: 'Apenas casos com essa política' },
];

export const PesquisaSocioeconomicaForm = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});

  // lists
  const estimativaOrcamentoObraList = useList({
    url: 'ld-orcamento-obra?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const razaoPesquisaSocioeconomicaList = useList({
    url: 'ld-razao?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  useEffect(() => {
    setFormData(state => ({
      ...state,
      sustentabilidade: sustentabilidadeList[0],
    }));
  }, []);

  const handleChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleResetForm = () => {
    setFormData({
      datas_cadastro: [],
      estimativa_inicio: '',
      estimativa_fim: '',
      orcamento_inicio: '',
      orcamento_fim: '',
      ld_orcamento_obra: '',
      acesso_jovens: null,
      produtora_interesse: null,
      razoes: [],
    });
  };

  const handleValidationFields = useCallback(async () => {
    try {
      formRef.current.setErrors({});

      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        datas_cadastro: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'datas-cadastro-nao-informadas',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
        tipo_busca: Yup.string().typeError(defaultMsg).required(defaultMsg),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }, [formData]);

  const bodyBuilder = useCallback(async () => {
    if (!(await handleValidationFields())) return null;

    try {
      const orcamento =
        formData?.tipo_busca === '1'
          ? formData?.ld_orcamento_obra?.value
          : omitEmpty({
              start: formData?.orcamento_inicio
                ? Number(formData?.orcamento_inicio?.replace(',', '.'))
                : undefined,
              end: formData?.orcamento_fim
                ? Number(formData?.orcamento_fim?.replace(',', '.'))
                : undefined,
            });

      // format dates
      const datasCadastro = formData?.datas_cadastro?.map(date =>
        format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      );

      const body = {
        ...omitEmpty({
          start_date: datasCadastro[0],
          end_date:
            datasCadastro.length === 1 ? datasCadastro[0] : datasCadastro[1],
          tipo: formData?.tipo_busca === '1' ? 'estimativa' : 'reais',
          professionals: {
            start: formData?.estimativa_inicio,
            end: formData?.estimativa_fim,
          },

          acesso_jovens:
            formData?.acesso_jovens === '1'
              ? formData?.produtora_interesse === '1'
              : null,
          razao:
            formData?.acesso_jovens === '0'
              ? formData?.razoes?.map(({ value }) => value)
              : null,
          orcamento,
        }),
        ...{
          sustentabilidade: formData?.sustentabilidade?.value,
        },
      };

      return body;
    } catch (error) {
      showModalMessage({
        type: 'error',
        text: 'Erro ao formatar dados para requisição',
      });
      return null;
    }
  }, [
    formData?.acesso_jovens,
    formData?.datas_cadastro,
    formData?.estimativa_fim,
    formData?.estimativa_inicio,
    formData?.ld_orcamento_obra?.value,
    formData?.orcamento_fim,
    formData?.orcamento_inicio,
    formData?.produtora_interesse,
    formData?.razoes,
    formData?.sustentabilidade?.value,
    formData?.tipo_busca,
    handleValidationFields,
  ]);

  const handleClickGerar = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri,
        method: ApiClient.POST,
        body,
        expectedStatus: 200,
      });

      setResponseData(resp.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  const handleClickExportarExcel = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      await ApiClient.doRequest({
        uri: `${uri}/xls`,
        method: ApiClient.POST,
        body: { ...body, report_name: 'getPesquisaReport' },
        expectedStatus: 200,
        isBinary: true,
        fileName: 'relatorio.xlsx',
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  const renderOrcamentoField = () => {
    switch (formData?.tipo_busca) {
      case '1':
        return (
          <Select
            name="ld_orcamento_obra"
            label="Orçamento"
            questionTooltip="Dados reais do orçamento total da obra audiovisual gasto na cidade de São Paulo (R$)"
            placeholder="Selecione"
            value={formData?.ld_orcamento_obra}
            options={estimativaOrcamentoObraList}
            style={selectCustomStyles}
            onChange={e => {
              handleChange('ld_orcamento_obra', e);
            }}
          />
        );

      case '0':
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-around',
            }}
          >
            <LimiterInputWidth w50 style={{ marginRight: 0 }}>
              <InputNumber
                type="text"
                label="Orçamento"
                questionTooltip="Estimativa de orçamento total da obra audiovisual gasto na cidade de São Paulo (R$)"
                name="orcamento_inicio"
                value={formData?.orcamento_inicio}
                onValueChange={(value, name) => handleChange(name, value)}
              />
            </LimiterInputWidth>
            <BoxHeader
              style={{
                width: '30px',
                justifyContent: 'center',
              }}
            >
              <span className="boxText" style={{ color: '#828282' }}>
                a
              </span>
            </BoxHeader>
            <LimiterInputWidth w50>
              <InputNumber
                type="text"
                name="orcamento_fim"
                value={formData?.orcamento_fim}
                onValueChange={(value, name) => handleChange(name, value)}
              />
            </LimiterInputWidth>
          </div>
        );

      default:
        return <></>;
    }
  };

  const renderFiltros = () => {
    return (
      <>
        <Wrap>
          <GridContainer cols={4} gap={30} style={{ alignItems: 'flex-end' }}>
            <MultiDateInput
              range
              name="datas_cadastro"
              label="Data de Cadastro"
              required
              value={formData?.datas_cadastro}
              onChange={e => {
                handleChange(
                  'datas_cadastro',
                  e?.map(
                    ({ day, month, year }) =>
                      `${String(day).padStart(2, '0')}/${String(month).padStart(
                        2,
                        '0',
                      )}/${year}`,
                  ),
                );
              }}
            />

            {Object.values(formData?.datas_cadastro || []).length > 1 && (
              <>
                <RadioNoForm
                  name="tipo_busca"
                  value={formData?.tipo_busca}
                  label="Você buscará por dados reais da obra ou somente uma estimativa?"
                  required
                  yesLabelValue="Estimativa"
                  noLabelValue="Dados Reais"
                  onChange={e => {
                    handleChange(e.target.name, e.target.value);
                  }}
                />

                {renderOrcamentoField()}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-around',
                  }}
                >
                  <LimiterInputWidth w50 style={{ marginRight: 0 }}>
                    <Input
                      type="number"
                      label="Estimativa Profissionais"
                      questionTooltip="Estimativa do nº total de profissionais envolvidos na produção desta obra"
                      name="estimativa_inicio"
                      value={formData?.estimativa_inicio}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </LimiterInputWidth>
                  <BoxHeader
                    style={{
                      width: '30px',
                      justifyContent: 'center',
                    }}
                  >
                    <span className="boxText" style={{ color: '#828282' }}>
                      a
                    </span>
                  </BoxHeader>
                  <LimiterInputWidth w50>
                    <Input
                      type="number"
                      name="estimativa_fim"
                      value={formData?.estimativa_fim}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                  </LimiterInputWidth>
                </div>

                <Select
                  name="sustentabilidade"
                  label="Política de sustentabilidade na filmagem"
                  placeholder="Selecione"
                  value={formData?.sustentabilidade}
                  options={sustentabilidadeList}
                  style={selectCustomStyles}
                  onChange={e => {
                    handleChange('sustentabilidade', e);
                  }}
                />

                <RadioNoForm
                  name="acesso_jovens"
                  value={formData?.acesso_jovens}
                  label="A produtora faz ou está aberta a conduzir projetos de acesso de jovens ao mercado de trabalho?"
                  onChange={e => {
                    handleChange(e.target.name, e.target.value);
                  }}
                />

                {formData?.acesso_jovens === '1' && (
                  <RadioNoForm
                    name="produtora_interesse"
                    value={formData?.produtora_interesse}
                    label="A produtora já realiza programas ou possui interesse?"
                    yesLabelValue="Já realiza programas"
                    noLabelValue="Possui interesse"
                    onChange={e => {
                      handleChange(e.target.name, e.target.value);
                    }}
                  />
                )}

                {formData?.acesso_jovens === '0' && (
                  <Select
                    name="razoes"
                    label="Especifique a razão que motiva a falta de interesse em adesão a projetos de inclusão de jovens"
                    placeholder="Selecione"
                    isMulti
                    value={formData?.razoes}
                    options={razaoPesquisaSocioeconomicaList}
                    style={selectCustomStyles}
                    onChange={e =>
                      handleChange(
                        'razoes',
                        e?.map(({ value, label }) => ({ value, label })),
                      )
                    }
                  />
                )}
              </>
            )}
          </GridContainer>
        </Wrap>

        <Wrap style={{ justifyContent: 'flex-end' }}>
          <SplitButton
            buttons={[
              {
                label: 'Gerar',
                onClick: handleClickGerar,
              },
              {
                label: 'Exportar Excel...',
                onClick: handleClickExportarExcel,
              },
            ]}
            isLoading={loading}
            disableAllButtons={loading}
          />

          <Button
            type="button"
            btType="#828282"
            onClick={handleResetForm}
            label="Limpar campos"
            full
            loading={loading}
            disabled={loading}
            width="150px"
            style={{ marginLeft: 5 }}
          />
        </Wrap>
        <Line />
      </>
    );
  };

  const renderQuantitativo = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData).length <= 0 ||
      responseData?.statistics?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    return (
      <>
        <GridContainer cols={5} style={{ marginTop: 15 }}>
          {responseData.statistics?.map(({ label, value }) => (
            <Text label={label} value={value} />
          ))}
        </GridContainer>
        <Line />
      </>
    );
  };

  const renderListagemBaseNova = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData).length <= 0 ||
      responseData?.list?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    const columnsNames = ['Data/Hora Criação', 'Protocolo da Obra'];

    const tableData = responseData.list.map(data => ({
      created_at: format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm'),
      protocol: data?.work?.protocol,
    }));

    return (
      <VirtualTable
        title="Impressão da listagem de Pesquisa Socioeconômica"
        height={500}
        width="100%"
        itemCount={tableData?.length}
        itemSize={40}
        tableData={tableData}
        columnsNames={columnsNames}
        showPrinter
        header={
          <thead>
            <tr>
              {columnsNames.map(columnName => (
                <th>{columnName}</th>
              ))}
            </tr>
          </thead>
        }
        row={({ index, style }) => (
          <tr>
            <td>{tableData[index].created_at}</td>
            <td>{tableData[index].protocol}</td>
          </tr>
        )}
        footer={
          <tfoot>
            <tr>
              <td colSpan={3}>Fim de listagem</td>
            </tr>
          </tfoot>
        }
      />
    );
  };

  return (
    <Form ref={formRef}>
      <Wrap>
        <AccordionItem opened label="Filtros">
          {renderFiltros()}
        </AccordionItem>
      </Wrap>

      <Wrap>
        <AccordionItem label="Quantitativos">
          {renderQuantitativo()}
        </AccordionItem>
      </Wrap>

      {formData?.sustentabilidade?.value !== null && (
        <Wrap>
          <AccordionItem label="Listagem (Base nova)">
            {renderListagemBaseNova()}
          </AccordionItem>
        </Wrap>
      )}
    </Form>
  );
};
