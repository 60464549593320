import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SearchContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  max-width: 500px;
`;

export const MainLoading = styled.div`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;

export const InnerHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
`;

export const LinkBack = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  &::after {
    content: 'Voltar';
    margin-left: 5px;
    color: #000;
    font-family: 'InterBold', sans-serif;
    font-size: 20px;
  }
`;

export const ItemVideo = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;

  width: 100%;
  max-width: 355px;

  & span {
    width: 100%;
    text-align: left;
    margin-top: 15px;
    color: #000;
    font-family: 'InterBold', sans-serif;
    font-size: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px 70px;
`;
