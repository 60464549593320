import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-right: 16px;
  margin-top: ${({ marginTop }) => (marginTop ? [marginTop] : '0')};

  p {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #828282;
    margin-bottom: 8px;
  }

  &:last-child {
    margin-right: 0;
  }

  span {
    font-size: 14px;
    font-family: 'InterBold', sans-serif;
    color: #ff0000;
    margin: 4px 0;
  }

  span {
    font-family: 'PoppinsSemiBold', sans-serif;
  }
`;

export const CheckButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  background-color: #f2f2f2;
  width: 100%;
  max-width: 200px;
  height: 48px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  padding: 4px;
  position: relative;
`;

export const CheckButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) => (selected ? '#26a2b1' : 'transparent')};
  font-family: 'InterBold', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: ${({ selected }) => (selected ? '#ffffff' : '#828282')};
  width: 100%;
  max-width: 94px;
  border-radius: 4px;
  cursor: ${({ disableCursor }) => (disableCursor ? 'unset' : 'pointer')};
`;
