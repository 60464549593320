import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '../../services/notification';

import api, { makeOptions } from '../../services/api';
import WorkListActions from '../ducks/work-list';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));
// const getAuthData = (state) => state.auth.data;

export function* fetch(action) {
  try {
    const { params = {} } = action;
    // const authData = yield select(getAuthData);
    const initialDate =
      params.uuid !== undefined
        ? `&filters[][responsavel_user.uuid@equal]=${params.uuid}`
        : '';

    const workData =
      params.date !== undefined
        ? `&filters[][visit_date@equal]=${params.date}`
        : '';

    const workStatus =
      params.name !== undefined
        ? `&filters[][work_status.name@equal]=${params.name}`
        : '';

    const userUuid =
      params.userUuid !== undefined
        ? `&filters[][proprietario_user.uuid@equal]=${params.userUuid}`
        : '';

    const url = `/work?${initialDate}${workData}${workStatus}${userUuid}`;

    const { data: response } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );

    const { data, ...pagination } = response;

    yield put(WorkListActions.success(data, pagination));
  } catch (err) {
    yield put(WorkListActions.showFailure('Erro ao se comunicar com a API.'));
  }
}

export function* fetchSelect() {
  try {
    // const authData = yield select(getAuthData);
    const url = `/work`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data } = responseData;
    const list = data.map(({ uuid, protocol, titulo_obra: title }) => ({
      uuid,
      title,
      protocol,
    }));

    yield put(WorkListActions.showSuccess(list));
  } catch (err) {
    yield put(WorkListActions.showFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* fetchFilter(action) {
  try {
    const { params = {} } = action;
    // const authData = yield select(getAuthData);
    const initialDate =
      params.uuid !== undefined
        ? `filters[][responsavel_user.uuid@equal]=${params.uuid}`
        : '';
    const workData =
      params.date !== undefined
        ? `&filters[][visit_date@equal]=${params.date}`
        : '';
    const workStatus =
      params.name !== undefined
        ? `&filters[][work_status.name@equal]=${params.name}`
        : '';
    const url = `/work?${initialDate}${workData}${workStatus}`;
    const { data: response } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    // eslint-disable-next-line camelcase
    const { data, ...pagination } = response;
    const list = data.map(
      ({
        uuid,
        // eslint-disable-next-line camelcase
        protocol,
        visit_date,
        titulo_obra: title,
        nome_projeto,
        contact_user,
        work_status,
        branch,
      }) => ({
        uuid,
        title,
        protocol,
        date: visit_date,
        nome_projeto,
        productorResp: contact_user.user_personal_data.name,
        status: work_status.name,
        company: branch.fantasy_name,
      }),
    );
    yield put(WorkListActions.filterSuccess(list, pagination));
  } catch (err) {
    yield put(WorkListActions.filterFailure('Erro ao se comunicar com a API.'));
  }
}
