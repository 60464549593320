import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  label {
    font-family: 'MuseoSans500', sans-serif;
    font-size: 15px;
    color: #000000;
    margin-bottom: 8px;
  }
  textarea {
    width: 100%;
    border-radius: 2px;
    border: 1px #d0d0d0 solid;
    padding: 16px;
    font-size: 16px;
    font-family: 'InterSemiBold', sans-serif;
    color: #828282;
    background-color: #fafafa;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.075);
  }
  span {
    font-size: 14px;
    font-family: 'InterBold', sans-serif;
    color: #ff0000;
    margin: 4px 0;
  }
  span.danger {
    font-family: 'PoppinsSemiBold', sans-serif;
  }
`;
