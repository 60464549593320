import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Container } from "~/components/Forms/Checkbox/styles";

export const BoxBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .boxText {
    font-family: InterRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #26a2b1;
    margin-bottom: 20px;
  }
`;

export const MainLoading = styled.div`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;

export const ChoiceBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardChoice = styled.div`
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
  height: 350px;
  display: flex;
  background: #3f3f3f;
  justify-content: center;
  // align-items: center;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  /* ${props => props.disabled && 'pointer-events: none;'}
  ${props => props.disabled && 'opacity: 0.7;'}
  ${props => props.disabled && 'cursor: not-allowed;'}
  ${props => !props.disabled && 'cursor: pointer;'}
*/
  padding: 15px;
  &:first-child {
    margin-right: 10px;
  }

  div {
    display: flex;

  }

  img {
    width: 60%;
    max-width: 100px;

  }

  h1 {
    font-family: InterRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #ffff;
  }

  p {
    margin-top: 30px;
    text-align: center;
    max-width: 380px;
    min-height: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const CardButton = styled(Link)`
    background-color: #26a2b1;
    border-radius: 50px;
    padding: 10px 20px;
    margin-top: 30px;
    font-weight: 600;

    ${props => props.disabled && 'pointer-events: none;'}
    ${props => props.disabled && 'opacity: 0.7;'}
    ${props => props.disabled && 'cursor: not-allowed;'}
    ${props => !props.disabled && 'cursor: pointer;'}
`

export const ProfilePhoto = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #000;
`;

export const RadioContainer = styled.div`
  width: 100%;
  height: 73px;
  background: #f2f2f2;
  //color: #26a2b1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
  cursor: pointer;

  h4 {
    padding: 9px 0 0 10px;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    font-family: InterRegular;
    color: #828282;
  }

  span {
    border-radius: 50px;
    background-color: #e0e0e0;
    width: 33px;
    height: 33px;

    &::after {
      left: 10px;
      top: 10px;
      width: 10px;
      height: 10px;
      background: #e0e0e0;
      border-radius: 50px;
      border-width: none;
    }
  }
`;

export const LogOut = styled.div`
  cursor: pointer;
`;
export const Access = styled(Link)`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 23px;
  & svg {
    margin-right: 4px;
  }
`;
