import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '../../services/notification';

import api, { makeOptions } from '../../services/api';
import UsersListActions from '../ducks/users-list';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));
// const getAuthData = (state) => state.auth.data;

export function* fetch(action) {
  try {
    const { params = {} } = action;
    // const authData = yield select(getAuthData);
    const initialDate =
      params.roles !== undefined
        ? `?filters[0][roles.name@equal]=${params.roles}`
        : '';
    const url = `/user${initialDate}`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    // eslint-disable-next-line camelcase
    const list = responseData.data.map(({ uuid, user_personal_data }) => ({
      id: uuid,
      label: user_personal_data?.name,
    }));

    yield put(UsersListActions.success(list));
  } catch (err) {
    yield put(UsersListActions.showFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* fetchSelect() {
  try {
    // const authData = yield select(getAuthData);
    const url = `/user?paginate=false`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data } = responseData;
    // eslint-disable-next-line camelcase
    const list = data.map(({ uuid, user_personal_data }) => ({
      id: uuid,
      label: user_personal_data?.name,
    }));

    yield put(UsersListActions.showSuccess(list));
  } catch (err) {
    yield put(UsersListActions.showFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}
export function* fetchUsersAdminMod(action) {
  try {
    // const authData = yield select(getAuthData);
    const { params = {} } = action;
    const page = params.pagina !== undefined ? `?page=${params.pagina}` : '';

    const url = `/users-admin-mod${page}`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data, ...pagination } = responseData;
    yield put(UsersListActions.success(data, pagination));
  } catch (err) {
    yield put(UsersListActions.failure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}
