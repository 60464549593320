import diff from 'diff-arrays-of-objects';
import diffInnerHorasFinalidade from './diffInnerHorasFinalidade';

export default function _({
  permGeradorLocacao,
  permGeradorInput,
}) {
  // se opção não foi gravada no banco antes
  if (Object.values(permGeradorLocacao || []).length <= 0 &&
    Object.values(permGeradorInput || []).length <= 0) {
    return []
  }

  // se a opção não for mais selecionada
  if (Object.values(permGeradorInput || []).length <= 0) {
    return permGeradorLocacao?.map(({uuid, hour_acesso_calcadoes}) => {
      const horas = hour_acesso_calcadoes
        .map(hora => ({ uuid: hora.uuid, inicio: '', fim: ''}));
      return { uuid, placa: '', modelo_veiculo: '', horas }
    });
  }

  // cria listas temporarias dos cards sem as horas
  const permGeradorLocacaoTmp = permGeradorLocacao?.map(({uuid, placa, modelo_veiculo, hour_acesso_calcadoes, ld_finalidade_acesso}) => 
    ({ uuid, placa, modelo_veiculo, horas: hour_acesso_calcadoes, finalidade_acesso_uuid: ld_finalidade_acesso?.uuid }));
  const permGeradorInputTmp = permGeradorInput.map(({uuid, placa, modelo_veiculo, horas, finalidade_acesso_uuid}) => 
    ({ uuid: uuid || '', placa, modelo_veiculo, horas, finalidade_acesso_uuid }));  

  // ve se tem diferença entre os cards
  const results = diff(permGeradorLocacaoTmp, permGeradorInputTmp, 'uuid');
  const { updated, added, removed } = results;

  // verifica e faz uma nova saída adicionando as horas para cards que foram atualizados
  const cardsAtualizados = permGeradorLocacaoTmp?.map(permGera => {
    const permGeraUpdated = updated.find(({uuid}) => permGera.uuid === uuid)

    if (!permGeraUpdated) {
      return null;
    }
    
    const horas = diffInnerHorasFinalidade({
      innerHorasLocacao: permGera.horas,
      innerHorasInput: permGeraUpdated.horas
    });

    return {...permGeraUpdated, horas}
  });

  // verifica e faz uma nova saída adicionando as horas para cards que foram removidos
  const cardsRemovidos = removed.map(({uuid}) => {
    const horas = permGeradorLocacao
      ?.find(permGera => permGera.uuid === uuid)
      ?.hour_acesso_calcadoes
      ?.map(hora => ({ uuid: hora.uuid, inicio: '', fim: '' }));
  
    return { uuid, placa: '', modelo_veiculo: '', horas };
  });

  const permGeradorDiff = added.concat(cardsAtualizados, cardsRemovidos).filter(item => !!item);
  return permGeradorDiff;  
}
