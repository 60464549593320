import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { format } from 'date-fns';
import CryptoJS from 'crypto-js';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  Textarea,
  ChoiceButtonLocacao,
  File,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ActionInputMulti,
  MultiInputContainer,
  ConditionalContainer,
  LimiterInputWidth,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import LocacaoDocs from '../../LocacaoDocs/index';

import { Wrap, MainLoading } from './styles';

import EfeitoEspecialListActions from '~/store/ducks/efeito-especial-list';
import SomFilmagemListActions from '~/store/ducks/som-filmagem-list';
import EquipamentoFilmagemListActions from '~/store/ducks/equipamento-filmagem-list';
import ImpactoListActions from '~/store/ducks/impacto-list';
import FinalidadeBaseListActions from '~/store/ducks/finalidade-base-apoio-list';

import { showModalMessage } from '~/services/notification';

import Button from '~/components/Button';
import Chat from '~/components/Chat/chat';
import Documents from '~/components/Documents/Documents';
import {
  ModalidadePagamento,
  ObservacoesChat,
  ValorLocacao,
} from '~/components/Admin';
import { useList } from '~/hooks/useFunction';
import LogoIcon from '~/components/LogoIcon';
import WorkStatusSwitch from '~/components/Admin/WorkStatusSwitch';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import {
  validDateArray,
  validHourArray,
  getBrowserTransformDate,
} from '../../functions/date-hour-utils';
import { ActionButton } from '~/components/Forms/FormChoiceButton/styles';
import tenancy_env from '~/utils/MultiTenancyUtil';
import clientResources from '~/data/clientResources';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function Teatro() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();
  const croquiFileRef = useRef(null);
  const conteudoProjecaoRef = useRef(null);
  const iluminacaoFileRef = useRef(null);
  const relatorioArteRef = useRef(null);

  // states
  const [responseLocation, setResponseLocation] = useState();
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [errorsForm, setErrorsForm] = useState({});

  // states multi input
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states choices
  const [localFilmagem, setLocalFilmagem] = useState(null);
  const [pontoFixoTrajeto, setPontoFixoTrajeto] = useState(null);
  const [isolarLocal, setIsolarLocal] = useState(null);
  const [intervencaoArte, setIntervencaoArte] = useState(null);
  const [gerador, setGerador] = useState(null);
  const [blimpado, setBlimpado] = useState(null);
  const [geradorPortatil, setGeradorPortatil] = useState(null);
  const [baseLocal, setBaseLocal] = useState(null);
  const [captacaoSomDireto, setCaptacaoSomDireto] = useState(null);
  const [somFilmagem, setSomFilmagem] = useState(null);
  const [drone, setDrone] = useState(null);
  const [efeitosEspeciais, setEfeitosEspeciais] = useState(null);
  const [haveraAnimais, setHaveraAnimais] = useState(null);
  const [projecao, setProjecao] = useState(null);
  const [projecaoLogo, setProjecaoLogo] = useState(null);
  const [desligarLuz, setDesligarLuz] = useState(null);
  const [reservasVagas, setReservasVagas] = useState(null);
  const [intervencoesVia, setIntervencoesVia] = useState(null);
  const [publicoPrivado, setPublicoPrivado] = useState(null);

  // state files
  const [croquiFile, setCroquiFile] = useState(null);
  const [dataCroquiFile, setDataCroquiFile] = useState(null);
  const [iluminacaoFile, setIluminacaoFile] = useState(null);
  const [dataIluminacaoFile, setDataIluminacaoFile] = useState(null);
  const [conteudoProjecaoFile, setConteudoProjecaoFile] = useState(null);
  const [dataConteudoProjecaoFile, setDataConteudoProjecaoFile] =
    useState(null);
  const [relatorioArteFile, setRelatorioArteFile] = useState(null);
  const [dataRelatorioArteFile, setDataRelatorioArteFile] = useState(null);

  // state outro fields
  const [showOutroEquipamentoField, setShowOutroEquipamentoField] =
    useState(false);
  const [showOutroSomFilmagemField, setShowOutroSomFilmagemField] =
    useState(false);
  const [showOutroFinalidadeField, setShowOutroFinalidadeField] =
    useState(false);
  const [showOutroPontoFixoField, setShowOutroPontoFixoField] = useState(false);
  const [showDescrAltoImpactoLocalField, setShowDescrAltoImpactoLocalField] =
    useState(false);
  const [
    showDescrEquipamentoFilmagemField,
    setShowDescrEquipamentoFilmagemField,
  ] = useState(false);
  const [showDescrSomFilmagemField, setShowDescrSomFilmagemField] =
    useState(false);

  // selectors
  const { data: equipamentosSelectList } = useSelector(
    state => state.equipamentoFilmagemList,
  );
  const { data: somFilmagemSelectList } = useSelector(
    state => state.somFilmagemList,
  );
  const { data: efeitoEspecialSelectList } = useSelector(
    state => state.efeitoEspecialList,
  );
  const { data: impactoSelectList } = useSelector(state => state.impactoList);

  const { data: finalidadeSelectList } = useSelector(
    state => state.finalidadeBaseApoioList,
  );

  // lists
  const teatrosList = useList({ url: 'ld-teatro' })?.map(({ uuid, name }) => ({
    value: uuid,
    label: name,
  }));

  const pontosFixosByTeatroList = useList({
    url: `ld-ponto-fixo-teatro/get-by-teatro/${formData?.ld_teatro_uuid}?paginate=false`,
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  // Busca dados da locação
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-teatro/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set temp variables
      const isolarLocalTmp =
        typeof respLoc?.locacao_default.fluxo_area === 'string';

      const blimpadoTmp =
        respLoc?.locacao_default.blimpado === null
          ? null
          : respLoc?.locacao_default.blimpado === 1;

      const geradorPortatilTmp =
        respLoc?.locacao_default.gerador_portatil === null
          ? null
          : respLoc?.locacao_default.gerador_portatil === 1;

      const projecaoLogoTmp =
        typeof respLoc?.locacao_default.porcentagem_projecao === 'number' ||
        typeof respLoc?.locacao_default.area_tempo_projeção === 'string';

      const publicoPrivadoTmp =
        respLoc?.locacao_default?.imovel_publico_privado === null
          ? null
          : respLoc?.locacao_default?.imovel_publico_privado === 1;

      const pontoFixoTmp =
        Object.values(respLoc?.ld_ponto_fixo_teatro || []).length > 0;
      const trajetoTmp = typeof respLoc?.locacao_default?.trajeto === 'string';

      const fileCroqui = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Croqui',
      );
      const fileArte = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Relatório de Arte',
      );
      const fileProjecao = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Projeção',
      );
      const fileLuz = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Iluminação',
      );

      // set multi input
      setDatasFilmagemInputs(
        respLoc?.locacao_default?.dates_locacao_default?.map(
          ({ date, changes_to_approve }) => ({
            data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
            changes_to_approve,
          }),
        ),
      );

      setHorasFilmagemInputs(
        respLoc?.locacao_default?.hours_locacao_default?.map(
          ({ inicio, fim, changes_to_approve }) => ({
            inicio: inicio.substring(0, 5),
            fim: fim.substring(0, 5),
            changes_to_approve,
          }),
        ),
      );

      // set choices buttons
      setLocalFilmagem(
        pontoFixoTmp || trajetoTmp || isolarLocalTmp || fileCroqui?.length > 0,
      );
      setPontoFixoTrajeto(
        pontoFixoTmp === true || trajetoTmp === true ? pontoFixoTmp : null,
      );
      setIsolarLocal(isolarLocalTmp);
      setIntervencaoArte(
        typeof respLoc?.locacao_default.desc_intervencao_arte === 'string' ||
          fileArte?.length > 0,
      );
      setBlimpado(blimpadoTmp);
      setGeradorPortatil(geradorPortatilTmp);
      setGerador(blimpadoTmp !== null || geradorPortatilTmp !== null);
      setBaseLocal(
        Object.values(respLoc?.locacao_default.ld_finalidade_base_apoio || [])
          .length > 0 ||
          typeof respLoc?.locacao_default.local_base_apoio === 'string' ||
          typeof respLoc?.locacao_default.dimensao_base_apoio === 'string' ||
          typeof respLoc?.locacao_default.quantidade_barracas === 'string',
      );
      setCaptacaoSomDireto(respLoc?.locacao_default.captação_som_direto === 1);
      setSomFilmagem(
        Object.values(respLoc?.locacao_default.ld_som_filmagem || []).length >
          0,
      );
      setDrone(respLoc?.locacao_default.drone === 1);
      setEfeitosEspeciais(
        Object.values(respLoc?.locacao_default.ld_efeitos_especiais || {})
          .length > 0,
      );
      setHaveraAnimais(
        typeof respLoc?.locacao_default.animais_em_cena === 'string',
      );
      setProjecao(
        fileProjecao?.length > 0 ||
          typeof respLoc?.locacao_default.estrutura_projecao === 'string' ||
          typeof respLoc?.locacao_default.instalacao_estrutura_projecao ===
            'string' ||
          publicoPrivadoTmp ||
          projecaoLogoTmp,
      );
      setPublicoPrivado(publicoPrivadoTmp);
      setProjecaoLogo(projecaoLogoTmp);
      setDesligarLuz(fileLuz?.length > 0);
      setIntervencoesVia(
        Object.values(respLoc?.utilizacao_associadas || []).length > 0,
      );
      setReservasVagas(
        Object.values(respLoc?.locacao_via_externa || []).length > 0,
      );

      // set campos 'Outro'
      setShowOutroPontoFixoField(typeof respLoc?.outro_ponto_fixo === 'string');
      setShowOutroEquipamentoField(
        respLoc?.locacao_default?.ld_equipamento_filmagem.find(
          equip => equip.name === 'Outro',
        ),
      );
      setShowOutroFinalidadeField(
        respLoc?.locacao_default?.ld_finalidade_base_apoio.find(
          equip => equip.name === 'outro, descreva',
        ),
      );
      setShowOutroSomFilmagemField(
        respLoc?.locacao_default?.ld_som_filmagem.find(
          equip => equip.name === 'outro',
        ),
      );
      setShowDescrAltoImpactoLocalField(
        typeof respLoc?.locacao_default?.descr_alto_impacto_local === 'string',
      );
      setShowDescrEquipamentoFilmagemField(
        typeof respLoc?.locacao_default?.descricoes_equipamento_filmagem ===
          'string',
      );
      setShowDescrSomFilmagemField(
        typeof respLoc?.locacao_default?.descricoes_som_filmagem === 'string',
      );

      // set select default values
      formRef.current?.setFieldValue('ld_teatro_uuid', {
        label: respLoc?.ld_teatros_uuid?.name,
        value: respLoc?.ld_teatros_uuid?.uuid,
      });

      formRef.current?.setFieldValue('ld_impacto_uuid', {
        label: respLoc?.locacao_default?.ld_impacto?.name,
        value: respLoc?.locacao_default?.ld_impacto?.uuid,
      });

      formRef.current?.setFieldValue(
        'pontos_fixos',
        respLoc?.ld_ponto_fixo_teatro?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'outro_ponto_fixo',
        respLoc?.outro_ponto_fixo,
      );

      formRef.current?.setFieldValue(
        'ld_equipamento_filmagem',
        respLoc?.locacao_default?.ld_equipamento_filmagem?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'ld_finalidade_base_apoio',
        respLoc?.locacao_default?.ld_finalidade_base_apoio?.map(lfba => {
          return {
            label: lfba.name,
            value: lfba.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'ld_som_filmagem',
        respLoc?.locacao_default?.ld_som_filmagem?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_efeitos_especiais_uuid', {
        label: respLoc?.locacao_default?.ld_efeitos_especiais?.name,
        value: respLoc?.locacao_default?.ld_efeitos_especiais?.uuid,
      });

      setResponseLocation(respLoc);
      setLoading(false);
    }
  }, [dadoState]);

  useEffect(() => {
    dispatch(EfeitoEspecialListActions.showRequest());
    dispatch(ImpactoListActions.showRequest());
    dispatch(EquipamentoFilmagemListActions.showRequest());
    dispatch(SomFilmagemListActions.showRequest());
    dispatch(FinalidadeBaseListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  // Verification files
  let fileCroqui = [];
  let fileArte = [];
  let fileProjecao = [];
  let fileLuz = [];

  // Filter files
  fileCroqui = responseLocation?.locacao_default?.files?.filter(
    file => file.name === 'Arquivo Croqui',
  );
  fileArte = responseLocation?.locacao_default?.files?.filter(
    file => file.name === 'Relatório de Arte',
  );
  fileProjecao = responseLocation?.locacao_default?.files?.filter(
    file => file.name === 'Arquivo Projeção',
  );
  fileLuz = responseLocation?.locacao_default?.files?.filter(
    file => file.name === 'Arquivo Iluminação',
  );

  // handles files
  function handleIluminacaoFile() {
    return iluminacaoFileRef.current.click();
  }

  function handleConteudoProjecaoFile() {
    return conteudoProjecaoRef.current.click();
  }

  function handleCroquiFile() {
    return croquiFileRef.current.click();
  }

  function handleRelatorioArteFile() {
    return relatorioArteRef.current.click();
  }

  // handles multi select
  function handleMultiSelectPontoFixo(e) {
    setFormData(state => ({
      ...state,
      pontos_fixos: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectEquipamentos(e) {
    setShowOutroEquipamentoField(!!e?.find(x => x.label === 'Outro'));
    setShowDescrEquipamentoFilmagemField(
      e?.some(equip => equip.label?.includes(':')) || false,
    );

    setFormData(state => ({
      ...state,
      ld_equipamento_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectSomFilmagem(e) {
    setShowOutroSomFilmagemField(!!e?.find(x => x.label === 'outro'));
    setShowDescrSomFilmagemField(
      e?.some(equip => equip.label?.includes('descrever')) || false,
    );
    setFormData(state => ({
      ...state,
      ld_som_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectFinalidades(e) {
    setShowOutroFinalidadeField(!!e?.find(x => x.label === 'outro, descreva'));

    setFormData(state => ({
      ...state,
      ld_finalidade_base_apoio: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  // handles true/false
  function handleProjecaoLogoTrue(e) {
    e.preventDefault();
    setProjecaoLogo(true);
    setErrorsForm(state => ({
      ...state,
      projecaoLogo: false,
    }));
  }

  function handleProjecaoLogoFalse(e) {
    e.preventDefault();
    setProjecaoLogo(false);
    setErrorsForm(state => ({
      ...state,
      projecaoLogo: false,
    }));
  }

  function handleDesligarLuzTrue(e) {
    e.preventDefault(e);
    setDesligarLuz(true);
    setErrorsForm(state => ({
      ...state,
      desligarLuz: false,
    }));
  }

  function handleDesligarLuzFalse(e) {
    e.preventDefault(e);
    setDesligarLuz(false);
    setErrorsForm(state => ({
      ...state,
      desligarLuz: false,
    }));
  }

  function handleIntervencoesViaTrue(e) {
    e.preventDefault(e);
    setIntervencoesVia(true);
    setErrorsForm(state => ({
      ...state,
      intervencoesVia: false,
    }));
  }

  function handleIntervencoesViaFalse(e) {
    e.preventDefault(e);
    setIntervencoesVia(false);
    setErrorsForm(state => ({
      ...state,
      intervencoesVia: false,
    }));
  }

  function handleReservasVagasTrue(e) {
    e.preventDefault(e);
    setReservasVagas(true);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleReservasVagasFalse(e) {
    e.preventDefault(e);
    setReservasVagas(false);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleHaveraAnimaisTrue(e) {
    e.preventDefault(e);
    setHaveraAnimais(true);
    setErrorsForm(state => ({
      ...state,
      haveraAnimais: false,
    }));
  }

  function handleHaveraAnimaisFalse(e) {
    e.preventDefault(e);
    setHaveraAnimais(false);
    setErrorsForm(state => ({
      ...state,
      haveraAnimais: false,
    }));
  }

  function handlePublicoPrivadoTrue(e) {
    e.preventDefault();
    setPublicoPrivado(true);
    setErrorsForm(state => ({
      ...state,
      publicoPrivado: false,
    }));
  }

  function handlePublicoPrivadoFalse(e) {
    e.preventDefault();
    setPublicoPrivado(false);
    setErrorsForm(state => ({
      ...state,
      publicoPrivado: false,
    }));
  }

  function handleDroneTue(e) {
    e.preventDefault();
    setDrone(true);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleDroneFalse(e) {
    e.preventDefault();
    setDrone(false);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleGeradorPortatilTrue(e) {
    e.preventDefault();
    setGeradorPortatil(true);
    setErrorsForm(state => ({
      ...state,
      geradorPortatil: false,
    }));
  }

  function handleGeradorPortatilFalse(e) {
    e.preventDefault();
    setGeradorPortatil(false);
    setErrorsForm(state => ({
      ...state,
      geradorPortatil: false,
    }));
  }

  function handleCaptacaoSomDiretoTrue(e) {
    e.preventDefault();
    setCaptacaoSomDireto(true);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleCaptacaoSomDiretoFalse(e) {
    e.preventDefault();
    setCaptacaoSomDireto(false);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleLocalFilmagemTrue(e) {
    e.preventDefault();
    setLocalFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleLocalFilmagemFalse(e) {
    e.preventDefault();
    setLocalFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handlePontoFixoTrajetoTrue(e) {
    e.preventDefault(e);
    setPontoFixoTrajeto(true);
    setErrorsForm(state => ({
      ...state,
      pontoFixoTrajeto: false,
    }));
  }

  function handlePontoFixoTrajetoFalse(e) {
    e.preventDefault(e);
    setPontoFixoTrajeto(false);
    setErrorsForm(state => ({
      ...state,
      pontoFixoTrajeto: false,
    }));
  }

  function handleIsolarLocalTrue(e) {
    e.preventDefault();
    setIsolarLocal(true);
    setErrorsForm(state => ({
      ...state,
      isolarLocal: false,
    }));
  }

  function handleIsolarLocalFalse(e) {
    e.preventDefault();
    setIsolarLocal(false);
    setErrorsForm(state => ({
      ...state,
      isolarLocal: false,
    }));
  }

  function handleIntervencaoArteTrue(e) {
    e.preventDefault();
    setIntervencaoArte(true);
    setErrorsForm(state => ({
      ...state,
      intervencaoArte: false,
    }));
  }

  function handleIntervencaoArteFalse(e) {
    e.preventDefault();
    setIntervencaoArte(false);
    setErrorsForm(state => ({
      ...state,
      intervencaoArte: false,
    }));
  }

  function handleGeradorTrue(e) {
    e.preventDefault();
    setGerador(true);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorFalse(e) {
    e.preventDefault();
    setGerador(false);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleBlimpadorTrue(e) {
    e.preventDefault();
    setBlimpado(true);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBlimpadorFalse(e) {
    e.preventDefault();
    setBlimpado(false);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBaseLocalTrue(e) {
    e.preventDefault();
    setBaseLocal(true);
    setErrorsForm(state => ({
      ...state,
      baseLocal: false,
    }));
  }

  function handleBaseLocalFalse(e) {
    e.preventDefault();
    setBaseLocal(false);
    setErrorsForm(state => ({
      ...state,
      baseLocal: false,
    }));
  }

  function handleSomFilmagemTrue(e) {
    e.preventDefault(e);
    setSomFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleSomFilmagemFalse(e) {
    e.preventDefault(e);
    setSomFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleEfeitosEspeciaisTrue(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(true);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleEfeitosEspeciaisFalse(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(false);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleProjecaoTrue(e) {
    e.preventDefault();
    setProjecao(true);
    setErrorsForm(state => ({
      ...state,
      projecao: false,
    }));
  }

  function handleProjecaoFalse(e) {
    e.preventDefault();
    setProjecao(false);
    setErrorsForm(state => ({
      ...state,
      projecao: false,
    }));
  }

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;

    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    if (name === 'croqui_file') {
      setCroquiFile(base64.split('base64,'));
      setDataCroquiFile(file);
      setErrorsForm(state => ({
        ...state,
        croquiFile: false,
      }));
    }

    if (name === 'relatorio_arte_file') {
      setRelatorioArteFile(base64.split('base64,'));
      setDataRelatorioArteFile(file);
      setErrorsForm(state => ({
        ...state,
        relatorioArteFile: false,
      }));
    }

    if (name === 'conteudo_projecao_file') {
      setConteudoProjecaoFile(base64.split('base64,'));
      setDataConteudoProjecaoFile(file);
      setErrorsForm(state => ({
        ...state,
        conteudoProjecaoFile: false,
      }));
    }

    if (name === 'iluminacao_file') {
      setIluminacaoFile(base64.split('base64,'));
      setDataIluminacaoFile(file);
      setErrorsForm(state => ({
        ...state,
        iluminacaoFile: false,
      }));
    }

    setFormData(state => ({ ...state, [name]: base64.split('base64,')[1] }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        ld_teatro_uuid: Yup.string().typeError(defaultMsg).required(defaultMsg),
        ld_impacto_uuid: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        descr_alto_impacto_local: Yup.string()
          .typeError(defaultMsg)
          .test(
            'alto-impacto-local-selecionado',
            defaultMsg,
            value =>
              !(
                showDescrAltoImpactoLocalField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        descricoes_equipamento_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'descricoes-equipamento-filmagem-selecionado',
            defaultMsg,
            value =>
              !(
                showDescrEquipamentoFilmagemField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        descricoes_som_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'descricoes-som-filmagem-selecionado',
            defaultMsg,
            value =>
              !(
                somFilmagem &&
                showDescrSomFilmagemField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        pontos_fixos: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'pontos-fixos-selecionado',
            defaultMsg,
            value =>
              !(
                localFilmagem &&
                pontoFixoTrajeto === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        outro_ponto_fixo: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-ponto-fixo-selecionado',
            defaultMsg,
            value =>
              !(
                localFilmagem &&
                pontoFixoTrajeto === true &&
                showOutroPontoFixoField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        trajeto: Yup.string()
          .typeError(defaultMsg)
          .test(
            'trajeto-selecionado',
            defaultMsg,
            value =>
              !(
                localFilmagem &&
                pontoFixoTrajeto === false &&
                (value?.length <= 0 || !value)
              ),
          ),
        fluxo_area: Yup.string()
          .typeError(defaultMsg)
          .test(
            'isolar-local-selecionado',
            defaultMsg,
            value =>
              !(
                localFilmagem &&
                isolarLocal === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        desc_intervencao_arte: Yup.string()
          .typeError(defaultMsg)
          .test(
            'intervencao-arte-selecionado',
            defaultMsg,
            value =>
              !(intervencaoArte === true && (value?.length <= 0 || !value)),
          ),
        ld_finalidade_base_apoio: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'base-local-selecionado',
            defaultMsg,
            value => !(baseLocal === true && (value?.length <= 0 || !value)),
          ),
        local_base_apoio: Yup.string()
          .typeError(defaultMsg)
          .test(
            'base-local-selecionado',
            defaultMsg,
            value => !(baseLocal === true && (value?.length <= 0 || !value)),
          ),
        ld_som_filmagem: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'som-filmagem-selecionado',
            defaultMsg,
            value => !(somFilmagem === true && (value?.length <= 0 || !value)),
          ),
        ld_efeitos_especiais_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'efeitos-especiais-selecionado',
            defaultMsg,
            value =>
              !(efeitosEspeciais === true && (value?.length <= 0 || !value)),
          ),
        descricao_animais: Yup.string()
          .typeError(defaultMsg)
          .test(
            'havera-animais-selecionado',
            defaultMsg,
            value =>
              !(haveraAnimais === true && (value?.length <= 0 || !value)),
          ),
        estrutura_projecao: Yup.string()
          .typeError(defaultMsg)
          .test(
            'projecao-selecionado',
            defaultMsg,
            value => !(projecao === true && (value?.length <= 0 || !value)),
          ),
        instalacao_estrutura_projecao: Yup.string()
          .typeError(defaultMsg)
          .test(
            'projecao-selecionado',
            defaultMsg,
            value => !(projecao === true && (value?.length <= 0 || !value)),
          ),
        porcentagem_projecao: Yup.string()
          .typeError(defaultMsg)
          .test(
            'projecao-logo-selecionado',
            defaultMsg,
            value =>
              !(
                projecao &&
                projecaoLogo === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        area_tempo_projeção: Yup.string()
          .typeError(defaultMsg)
          .test(
            'projecao-logo-selecionado',
            defaultMsg,
            value =>
              !(
                projecao &&
                projecaoLogo === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        outro_equipamento: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-equipamento-selecionado',
            defaultMsg,
            value =>
              !(
                showOutroEquipamentoField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        outro_som_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-som-filmagem-selecionado',
            defaultMsg,
            value =>
              !(
                somFilmagem &&
                showOutroSomFilmagemField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        outro_finalidade: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-finalidade-selecionado',
            defaultMsg,
            value =>
              !(
                baseLocal &&
                showOutroFinalidadeField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        tamanho_equipe_tecnica: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tamanho_do_elenco: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        descricao_cena: Yup.string().typeError(defaultMsg).required(defaultMsg),
        ld_equipamento_filmagem: Yup.lazy(val =>
          Array.isArray(val)
            ? Yup.array().of(
                Yup.string().typeError(defaultMsg).required(defaultMsg),
              )
            : Yup.string().typeError(defaultMsg).required(defaultMsg),
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }

      // validação arquivos
      if (localFilmagem && !data?.croqui_file) {
        await showModalMessage({
          type: 'error',
          text: 'Arquivo Croqui não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          croquiFile: true,
        }));
        return false;
      }

      if (intervencaoArte && !data?.relatorio_arte_file) {
        await showModalMessage({
          type: 'error',
          text: 'Arquivo Arte não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          relatorioArteFile: true,
        }));
        return false;
      }

      if (projecao && !data?.conteudo_projecao_file) {
        await showModalMessage({
          type: 'error',
          text: 'Arquivo Projeção não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          conteudoProjecaoFile: true,
        }));
        return false;
      }

      if (desligarLuz && !data?.iluminacao_file) {
        await showModalMessage({
          type: 'error',
          text: 'Arquivo Iluminação não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          iluminacaoFile: true,
        }));
        return false;
      }

      // Campos 'choice button' não selecionados
      if (localFilmagem === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Local da Filmagem' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          localFilmagem: true,
        }));
        return false;
      }

      if (localFilmagem && pontoFixoTrajeto === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Ponto Fixo/Trajeto' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          pontoFixoTrajeto: true,
        }));
        return false;
      }

      if (localFilmagem && isolarLocal === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Isolar Local' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          isolarLocal: true,
        }));
        return false;
      }

      if (intervencaoArte === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Intervenção de Arte' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          intervencaoArte: true,
        }));
        return false;
      }

      if (gerador === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Gerador' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          gerador: true,
        }));
        return false;
      }

      if (gerador && blimpado === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Blimpado' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          blimpado: true,
        }));
        return false;
      }

      if (gerador && geradorPortatil === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Gerador Portátil' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          geradorPortatil: true,
        }));
        return false;
      }

      if (baseLocal === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Base de Apoio no local' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          baseLocal: true,
        }));
        return false;
      }

      if (captacaoSomDireto === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Captação de Som Direto' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          captacaoSomDireto: true,
        }));
        return false;
      }

      if (somFilmagem === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Som na Filmagem' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          somFilmagem: true,
        }));
        return false;
      }

      if (drone === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Drone' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          drone: true,
        }));
        return false;
      }

      if (efeitosEspeciais === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Efeitos Especiais' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          efeitosEspeciais: true,
        }));
        return false;
      }

      if (haveraAnimais === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Animais em Cena' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          haveraAnimais: true,
        }));
        return false;
      }

      if (projecao === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Projeção Visível' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          projecao: true,
        }));
        return false;
      }

      if (projecao && publicoPrivado === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Imóvel Público/Privado' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          publicoPrivado: true,
        }));
        return false;
      }

      if (projecao && projecaoLogo === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Logomarca na Projeção' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          projecaoLogo: true,
        }));
        return false;
      }

      if (desligarLuz === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Desligamento de iluminação' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          desligarLuz: true,
        }));
        return false;
      }

      if (intervencoesVia === null) {
        await showModalMessage({
          type: 'error',
          text: 'Intervenções na Via não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          intervencoesVia: true,
        }));
        return false;
      }

      if (reservasVagas === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Reservas de Vagas' não foi informada!",
        });
        setErrorsForm(state => ({
          ...state,
          reservasVagas: true,
        }));
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }
    return true;
  }

  function formatDefaultData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      ld_impacto_uuid: data?.ld_impacto_uuid,
      tamanho_equipe_tecnica: data?.tamanho_equipe_tecnica,
      tamanho_do_elenco: data?.tamanho_do_elenco,
      descricao_cena: data?.descricao_cena,
      ld_equipamento_filmagem: data?.ld_equipamento_filmagem,
      especificidades: data?.especificidades,
    });

    // dados de choices
    const dataLocalFilmagem = {
      croqui_base64: localFilmagem ? data?.croqui_file : null,
    };

    const dataTrajeto = {
      trajeto:
        localFilmagem && pontoFixoTrajeto === false ? data?.trajeto : null,
    };

    const dataIsolarLocal = {
      fluxo_area: localFilmagem && isolarLocal ? data?.fluxo_area : null,
    };

    const dataIntervencaoArte = intervencaoArte
      ? {
          desc_intervencao_arte: data?.desc_intervencao_arte,
          relat_arte_base64: data?.relatorio_arte_file,
        }
      : { desc_intervencao_arte: null, relat_arte_base64: null };

    const dataGerador = gerador
      ? { blimpado, gerador_portatil: geradorPortatil }
      : { blimpado: null, gerador_portatil: null };

    const dataBaseLocal = baseLocal
      ? {
          ld_finalidade_base_apoio: data?.ld_finalidade_base_apoio,
          local_base_apoio: data?.local_base_apoio,
          dimensao_base_apoio: data?.dimensao_base_apoio,
          quantidade_barracas: data?.quantidade_barracas,
        }
      : {
          ld_finalidade_base_apoio: null,
          local_base_apoio: null,
          dimensao_base_apoio: null,
          quantidade_barracas: null,
        };

    const dataCaptacaoSomDireto = { captação_som_direto: captacaoSomDireto };

    const dataSomFilmagem = {
      ld_som_filmagem: somFilmagem ? data?.ld_som_filmagem : null,
    };

    const dataDrone = { drone };

    const dataEfeitosEspeciais = {
      ld_efeitos_especiais_uuid: efeitosEspeciais
        ? data?.ld_efeitos_especiais_uuid
        : null,
    };

    const dataHaveraAnimais = {
      animais_em_cena: haveraAnimais ? data?.descricao_animais : null,
    };

    const dataProjecao = projecao
      ? {
          estrutura_projecao: data?.estrutura_projecao,
          instalacao_estrutura_projecao: data?.instalacao_estrutura_projecao,
          imovel_publico_privado: publicoPrivado,
          projecao_base64: data?.conteudo_projecao_file,
        }
      : {
          estrutura_projecao: null,
          instalacao_estrutura_projecao: null,
          imovel_publico_privado: null,
          projecao_base64: null,
        };

    const dataProjecaoLogo =
      projecao && projecaoLogo
        ? {
            porcentagem_projecao: +data?.porcentagem_projecao || undefined,
            area_tempo_projeção: data?.area_tempo_projeção,
          }
        : { porcentagem_projecao: null, area_tempo_projeção: null };

    const dataIluminacao = {
      iluminacao_base64: desligarLuz ? data?.iluminacao_file : null,
    };

    // dados de campos exibidos condicionalmente
    const dataDescrAltoImpactoLocal = {
      descr_alto_impacto_local: showDescrAltoImpactoLocalField
        ? data?.descr_alto_impacto_local
        : null,
    };

    const dataOutroEquipamento = {
      outro_equipamento: showOutroEquipamentoField
        ? data?.outro_equipamento
        : null,
    };

    const dataOutroFinalidade = {
      outro_finalidade:
        baseLocal && showOutroFinalidadeField ? data?.outro_finalidade : null,
    };

    const dataOutroSomFilmagem = {
      outro_som_filmagem:
        somFilmagem && showOutroSomFilmagemField
          ? data?.outro_som_filmagem
          : null,
    };

    const dataDescrEquipamentoFilmagem = {
      descricoes_equipamento_filmagem: showDescrEquipamentoFilmagemField
        ? data?.descricoes_equipamento_filmagem
        : null,
    };

    const dataDescrSomFilmagem = {
      descricoes_som_filmagem:
        somFilmagem && showDescrSomFilmagemField
          ? data?.descricoes_som_filmagem
          : null,
    };

    // format datas filmagem
    const datasFilmagem = datasFilmagemInputs
      .filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_filmagem));

    return Object.assign(
      dataNaoCondicional,
      { dates: datasFilmagem },
      { hours: horasFilmagemInputs },
      dataLocalFilmagem,
      dataTrajeto,
      dataIsolarLocal,
      dataIntervencaoArte,
      dataGerador,
      dataBaseLocal,
      dataCaptacaoSomDireto,
      dataSomFilmagem,
      dataDrone,
      dataEfeitosEspeciais,
      dataHaveraAnimais,
      dataProjecao,
      dataProjecaoLogo,
      dataIluminacao,
      dataDescrAltoImpactoLocal,
      dataDescrEquipamentoFilmagem,
      dataDescrSomFilmagem,
      dataOutroEquipamento,
      dataOutroFinalidade,
      dataOutroSomFilmagem,
    );
  }

  function formatEspecificaData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      ld_teatro_uuid: data?.ld_teatro_uuid,
    });

    // dados de choices
    const dataPontoFixo = {
      pontos_fixos:
        localFilmagem && pontoFixoTrajeto === true ? data?.pontos_fixos : null,
    };

    // dados de campos exibidos condicionalmente
    const dataOutroPontoFixo = {
      outro_ponto_fixo:
        localFilmagem && pontoFixoTrajeto === true && showOutroPontoFixoField
          ? data?.outro_ponto_fixo
          : null,
    };

    return Object.assign(dataNaoCondicional, dataPontoFixo, dataOutroPontoFixo);
  }

  async function handleSubmit(data) {
    // files
    data.croqui_file = formData?.croqui_file;
    data.relatorio_arte_file = formData?.relatorio_arte_file;
    data.iluminacao_file = formData?.iluminacao_file;
    data.conteudo_projecao_file = formData?.conteudo_projecao_file;

    // selects
    data.ld_equipamento_filmagem = formData?.ld_equipamento_filmagem;
    data.ld_finalidade_base_apoio = formData?.ld_finalidade_base_apoio;
    data.ld_som_filmagem = formData?.ld_som_filmagem;
    data.pontos_fixos = formData?.pontos_fixos;

    try {
      if (!(await handleValidationFields(data))) {
        return;
      }

      const defaultData = formatDefaultData(data);
      const especificaData = formatEspecificaData(data);

      setLoading(true);

      const responseDefault = await ApiClient.doRequest({
        uri: 'locacao-default',
        method: ApiClient.POST,
        body: defaultData,
        expectedStatus: 201,
      });

      await ApiClient.doRequest({
        uri: 'locacao-teatro',
        method: ApiClient.POST,
        body: Object.assign(
          especificaData,
          { work_uuid: dadoState?.uuid },
          { locacao_default_uuid: responseDefault?.data?.data?.uuid },
        ),
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Teatros',
        text: 'Locação gravada com sucesso!!',
      });

      // conclusao
      const redirections = [
        {
          url: '/solicitacao-nova-locacao/locacao-utilizacao-via',
          next: intervencoesVia,
        },
        {
          url: '/solicitacao-nova-locacao/reservas-vagas-vinculado',
          next: reservasVagas,
        },
      ].filter(({ next }) => !!next);

      const [nextRedirect] = redirections;

      if (nextRedirect) {
        history.replace({
          pathname: nextRedirect.url,
          state: {
            uuid: dadoState?.uuid,
            redirections: redirections.filter(
              ({ url }) => url !== nextRedirect.url,
            ),
          },
        });
      } else {
        history.push('/home-user');
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Teatros',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function renderLocacao() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                {!isAdminRequest && (
                  <>
                    <LocacaoDocs excludes={['precosFilmagensVia']} />
                  </>
                )}

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={dadoState?.viewData && true}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <Select
                    name="ld_teatro_uuid"
                    label="Teatro"
                    placeholder="Selecione"
                    options={teatrosList}
                    style={selectCustomStyles}
                    onChange={value => {
                      handleSelectChange('ld_teatro_uuid', value);
                      setPontoFixoTrajeto(null);
                      formRef.current.clearField('pontos_fixos');
                      formRef.current.clearField('outro_ponto_fixo');
                      setShowOutroPontoFixoField(false);
                      setFormData(state => ({
                        ...state,
                        pontos_fixos: [],
                        outro_ponto_fixo: '',
                      }));
                      setTimeout(() => setPontoFixoTrajeto(true), 2000);
                    }}
                    required
                    isDisabled={dadoState?.viewData && true}
                  />

                  <Select
                    name="ld_impacto_uuid"
                    label="Você classifica sua filmagem como sendo:"
                    placeholder="Selecione"
                    options={impactoSelectList}
                    style={selectCustomStyles}
                    onChange={value => {
                      handleSelectChange('ld_impacto_uuid', value);
                      setShowDescrAltoImpactoLocalField(
                        value.label === 'alto impacto no local',
                      );
                    }}
                    questionTooltip="Clique aqui para saber mais"
                    questionOnClick={() => setOpenModalPorteFilmagem(true)}
                    required
                    isDisabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <ConditionalContainer show={showDescrAltoImpactoLocalField}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descr_alto_impacto_local"
                      label="Comente qual o desafio da sua produção para auxiliarmos em sua viabilização"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descr_alto_impacto_local
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <DateFormMultiInput
                    label="Data da Filmagem"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    dateKeyName="data_filmagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Hora da Filmagem (Início - Fim)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="Incluir horário de montagem/desmontagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="number"
                    name="tamanho_equipe_tecnica"
                    label="Tamanho da Equipe Técnica"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.tamanho_equipe_tecnica
                    }
                    disabled={dadoState?.viewData && true}
                  />
                  <Input
                    type="number"
                    name="tamanho_do_elenco"
                    label="Tamanho do elenco"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.tamanho_do_elenco
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>
                <Wrap>
                  <Textarea
                    type="text"
                    name="descricao_cena"
                    label="Descrição da Cena"
                    onChange={handleChange}
                    required
                    rows="3"
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.descricao_cena
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Já possui local definido para filmagem?"
                      onClickTrue={handleLocalFilmagemTrue}
                      onClickFalse={handleLocalFilmagemFalse}
                      selected={localFilmagem}
                      required
                      error={errorsForm.localFilmagem === true && 'Obrigatório'}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={localFilmagem}>
                  <Wrap>
                    <LimiterInputWidth w25>
                      <ChoiceButtonLocacao
                        label="Selecione:"
                        onClickTrue={handlePontoFixoTrajetoTrue}
                        onClickFalse={handlePontoFixoTrajetoFalse}
                        selected={pontoFixoTrajeto}
                        required
                        isDisabled={dadoState?.viewData && true}
                        error={
                          errorsForm.pontoFixoTrajeto === true && 'Obrigatório'
                        }
                        yesLabelValue="Ponto Fixo"
                        noLabelValue="Trajeto"
                      />
                    </LimiterInputWidth>

                    <ConditionalContainer
                      show={pontoFixoTrajeto === true}
                      style={{ flexDirection: 'row' }}
                    >
                      <LimiterInputWidth w50>
                        <Select
                          name="pontos_fixos"
                          label="Ponto(s) fixo(s)"
                          placeholder="Selecione"
                          options={pontosFixosByTeatroList}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleMultiSelectPontoFixo(value);
                            setShowOutroPontoFixoField(
                              !!value?.some(({ label }) => label === 'Outros'),
                            );
                          }}
                          required
                          isMulti
                          isDisabled={dadoState?.viewData && true}
                        />
                      </LimiterInputWidth>

                      <ConditionalContainer show={showOutroPontoFixoField}>
                        <Input
                          type="text"
                          name="outro_ponto_fixo"
                          label="Outro Ponto Fixo"
                          onChange={handleChange}
                          required
                          defaultValue={
                            dadoState?.viewData &&
                            responseLocation?.outro_ponto_fixo
                          }
                          disabled={dadoState?.viewData && true}
                        />
                      </ConditionalContainer>
                    </ConditionalContainer>

                    <ConditionalContainer show={pontoFixoTrajeto === false}>
                      <LimiterInputWidth>
                        <Input
                          type="text"
                          name="trajeto"
                          label="Trajeto"
                          onChange={handleChange}
                          required
                          defaultValue={
                            dadoState?.viewData &&
                            responseLocation?.locacao_default?.trajeto
                          }
                          disabled={dadoState?.viewData && true}
                        />
                      </LimiterInputWidth>
                    </ConditionalContainer>
                  </Wrap>

                  <Wrap>
                    <File
                      label="Croqui demarcando a Ocupação pretendida do Local:"
                      attach={dataCroquiFile}
                      textFile="Nenhum arquivo selecionado"
                      name="croqui_file"
                      required
                      inputRef={croquiFileRef}
                      onChange={handleFileChange}
                      onClick={handleCroquiFile}
                      error={errorsForm.croquiFile === true && 'Obrigatório'}
                      link={fileCroqui?.length > 0 && fileCroqui[0].url}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </Wrap>

                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Você precisa isolar alguma área e/ou interferir no fluxo dos frequentadores?"
                        onClickTrue={handleIsolarLocalTrue}
                        onClickFalse={handleIsolarLocalFalse}
                        selected={isolarLocal}
                        required
                        error={
                          errorsForm?.isolarLocal === true && 'Obrigatório'
                        }
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <ConditionalContainer show={isolarLocal}>
                    <Wrap>
                      <Textarea
                        type="text"
                        name="fluxo_area"
                        label="Descrição do local"
                        onChange={handleChange}
                        rows="3"
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.fluxo_area
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá alguma intervenção de arte no local?"
                      onClickTrue={handleIntervencaoArteTrue}
                      onClickFalse={handleIntervencaoArteFalse}
                      selected={intervencaoArte}
                      required
                      error={
                        errorsForm?.intervencaoArte === true && 'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={intervencaoArte}>
                  <Wrap>
                    <File
                      label="Relatório de Arte:"
                      attach={dataRelatorioArteFile}
                      textFile="Nenhum arquivo selecionado"
                      name="relatorio_arte_file"
                      inputRef={relatorioArteRef}
                      onChange={handleFileChange}
                      onClick={handleRelatorioArteFile}
                      required
                      error={
                        errorsForm.relatorioArteFile === true && 'Obrigatório'
                      }
                      link={fileArte?.length > 0 && fileArte[0].url}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="desc_intervencao_arte"
                      label="Descreva a(s) intervenção(ões)"
                      onChange={handleChange}
                      required
                      rows="2"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.desc_intervencao_arte
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_equipamento_filmagem"
                      label="Qual a sua estrutura e equipamentos de filmagem:"
                      placeholder="Selecione"
                      options={equipamentosSelectList}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleMultiSelectEquipamentos(value);
                      }}
                      required
                      isMulti
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={showOutroEquipamentoField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="outro_equipamento"
                        label="Outro Equipamento"
                        onChange={handleChange}
                        required
                        defaultValue={
                          responseLocation?.locacao_default?.outro_equipamento
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <ConditionalContainer show={showDescrEquipamentoFilmagemField}>
                  <Wrap>
                    <Textarea
                      label="Descreva a(s) extensão(ões)"
                      required
                      name="descricoes_equipamento_filmagem"
                      onChange={handleChange}
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descricoes_equipamento_filmagem
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Haverá gerador?"
                      onClickTrue={handleGeradorTrue}
                      onClickFalse={handleGeradorFalse}
                      selected={gerador}
                      required
                      error={errorsForm?.gerador === true && 'Obrigatório'}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={gerador}>
                    <LimiterInputWidth w25>
                      <ChoiceButtonLocacao
                        label="Blimpado/Silencioso ou Não-Blimpado?"
                        onClickTrue={handleBlimpadorTrue}
                        onClickFalse={handleBlimpadorFalse}
                        selected={blimpado}
                        required
                        error={errorsForm?.blimpado === true && 'Obrigatório'}
                        isDisabled={dadoState?.viewData && true}
                        yesLabelValue="Blimpado"
                        noLabelValue="Não-Blimpado"
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={gerador}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Gerador portátil ou ficará sobre um veículo?"
                        onClickTrue={handleGeradorPortatilTrue}
                        onClickFalse={handleGeradorPortatilFalse}
                        selected={geradorPortatil}
                        required
                        error={
                          errorsForm?.geradorPortatil === true && 'Obrigatório'
                        }
                        isDisabled={dadoState?.viewData && true}
                        yesLabelValue="Gerador portátil"
                        noLabelValue="Sobre um veículo"
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Precisará montar Base de Apoio no local?"
                      onClickTrue={handleBaseLocalTrue}
                      onClickFalse={handleBaseLocalFalse}
                      selected={baseLocal}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.baseLocal === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={baseLocal}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_finalidade_base_apoio"
                        label="Selecione as Finalidades"
                        placeholder="Selecione"
                        options={finalidadeSelectList}
                        style={selectCustomStyles}
                        required
                        onChange={handleMultiSelectFinalidades}
                        isMulti
                        isDisabled={dadoState?.viewData && true}
                      />
                      <ConditionalContainer show={showOutroFinalidadeField}>
                        <Input
                          type="text"
                          name="outro_finalidade"
                          label="Outra Finalidade"
                          onChange={handleChange}
                          required
                          defaultValue={
                            dadoState?.viewData &&
                            responseLocation?.locacao_default.outro_finalidade
                          }
                          disabled={dadoState?.viewData && true}
                        />
                      </ConditionalContainer>
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <ConditionalContainer show={baseLocal}>
                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="local_base_apoio"
                        label="Descreva local da base de apoio"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.local_base_apoio
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>

                    <LimiterInputWidth w25 style={{ marginRight: 0 }}>
                      <Input
                        type="number"
                        step="0.01"
                        name="dimensao_base_apoio"
                        label="Descreva a dimensão estimada da área"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.dimensao_base_apoio
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>

                    <BoxHeader style={{ justifyContent: 'center', width: 50 }}>
                      <span className="boxText" style={{ color: '#828282' }}>
                        ou
                      </span>
                    </BoxHeader>

                    <LimiterInputWidth w25>
                      <Input
                        type="number"
                        name="quantidade_barracas"
                        label="Descreva a quantidade de barracas a serem montadas"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.quantidade_barracas
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Captação de som direto?"
                      onClickTrue={handleCaptacaoSomDiretoTrue}
                      onClickFalse={handleCaptacaoSomDiretoFalse}
                      selected={captacaoSomDireto}
                      required
                      error={
                        errorsForm?.captacaoSomDireto === true && 'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá som na filmagem?"
                      onClickTrue={handleSomFilmagemTrue}
                      onClickFalse={handleSomFilmagemFalse}
                      selected={somFilmagem}
                      required
                      error={errorsForm?.somFilmagem === true && 'Obrigatório'}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={somFilmagem}>
                  <Wrap>
                    <LimiterInputWidth w75>
                      <Select
                        name="ld_som_filmagem"
                        label="Sons de Filmagem"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        required
                        options={somFilmagemSelectList}
                        onChange={handleMultiSelectSomFilmagem}
                        isMulti
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <ConditionalContainer show={showOutroSomFilmagemField}>
                    <Wrap>
                      <Input
                        type="text"
                        name="outro_som_filmagem"
                        label="Outro Som de Filmagem"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.outro_som_filmagem
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </ConditionalContainer>

                  <ConditionalContainer show={showDescrSomFilmagemField}>
                    <Wrap>
                      <Textarea
                        label="Descreva a(s) característica(s)"
                        required
                        name="descricoes_som_filmagem"
                        onChange={handleChange}
                        rows="3"
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default
                            ?.descricoes_som_filmagem
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Drone"
                      onClickTrue={handleDroneTue}
                      onClickFalse={handleDroneFalse}
                      selected={drone}
                      required
                      error={errorsForm?.drone === true && 'Obrigatório'}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                {drone === true && (
                  <Wrap>
                    <Textarea
                      name="autorizacao_drones"
                      onChange={handleChange}
                      rows="4"
                      value="Para filmagens com drones: o Espaço Aéreo não é de administração municipal, mas sim da Federação/União, portanto, não faz parte do campo de atuação da SPFilm. A autorização para o uso de drones deve ser solicitada diretamente à Agência Nacional de Aviação Civil (ANAC), mediante cadastro no Sistema SARPAS, disponibilizado no site do Departamento de Controle do Espaço Aéreo (DECEA)."
                      disabled
                    />
                  </Wrap>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Efeitos Especiais"
                      onClickTrue={handleEfeitosEspeciaisTrue}
                      onClickFalse={handleEfeitosEspeciaisFalse}
                      selected={efeitosEspeciais}
                      required
                      error={
                        errorsForm?.efeitosEspeciais === true && 'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={efeitosEspeciais}>
                    <Select
                      name="ld_efeitos_especiais_uuid"
                      label="Efeitos Especiais"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={efeitoEspecialSelectList}
                      onChange={value => {
                        handleSelectChange('ld_efeitos_especiais_uuid', value);
                      }}
                      required
                      isDisabled={dadoState?.viewData && true}
                    />
                  </ConditionalContainer>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá animais de cena?"
                      onClickTrue={handleHaveraAnimaisTrue}
                      onClickFalse={handleHaveraAnimaisFalse}
                      selected={haveraAnimais}
                      required
                      error={
                        errorsForm?.haveraAnimais === true && 'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={haveraAnimais}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descricao_animais"
                      label="Descreva os animais"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.animais_em_cena
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá projeção visível a partir de logradouro público?"
                      onClickTrue={handleProjecaoTrue}
                      onClickFalse={handleProjecaoFalse}
                      selected={projecao}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.projecao === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={projecao}>
                  <Wrap>
                    <File
                      label="Conteúdo da Projeção:"
                      attach={dataConteudoProjecaoFile}
                      textFile="Nenhum arquivo selecionado"
                      name="conteudo_projecao_file"
                      inputRef={conteudoProjecaoRef}
                      onChange={handleFileChange}
                      required
                      onClick={handleConteudoProjecaoFile}
                      error={
                        errorsForm.conteudoProjecaoFile === true &&
                        'Obrigatório'
                      }
                      link={fileProjecao?.length > 0 && fileProjecao[0].url}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Será projetado em imóvel público ou privado?"
                        onClickTrue={handlePublicoPrivadoTrue}
                        onClickFalse={handlePublicoPrivadoFalse}
                        selected={publicoPrivado}
                        required
                        isDisabled={dadoState?.viewData && true}
                        error={
                          errorsForm.publicoPrivado === true && 'Obrigatório'
                        }
                        yesLabelValue="Público"
                        noLabelValue="Privado"
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="estrutura_projecao"
                      label="Descreva a Estrutura para a Projeção"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.estrutura_projecao
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="instalacao_estrutura_projecao"
                      label="Descreva local de instalação para estrutura de projeção"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.instalacao_estrutura_projecao
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Haverá exibição de logomarca na projeção? Se sim, qual porcentagem ocupará da área total da projeção e por quanto tempo será projetada?"
                        onClickTrue={handleProjecaoLogoTrue}
                        onClickFalse={handleProjecaoLogoFalse}
                        selected={projecaoLogo}
                        required
                        isDisabled={dadoState?.viewData && true}
                        error={
                          errorsForm.projecaoLogo === true && 'Obrigatório'
                        }
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  <ConditionalContainer show={projecaoLogo}>
                    <Wrap>
                      <Input
                        type="number"
                        step="0.01"
                        name="porcentagem_projecao"
                        required
                        label="Porcentagem que a projeção irá ocupar"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default
                            ?.porcentagem_projecao
                        }
                        disabled={dadoState?.viewData && true}
                      />
                      <Input
                        type="time"
                        name="area_tempo_projeção"
                        required
                        label="Tempo de Projeção"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.area_tempo_projeção
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá desligamento de iluminação no local?"
                      onClickTrue={handleDesligarLuzTrue}
                      onClickFalse={handleDesligarLuzFalse}
                      selected={desligarLuz}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.desligarLuz === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={desligarLuz}>
                  <Wrap>
                    <File
                      label="Arquivo do local:"
                      attach={dataIluminacaoFile}
                      textFile="Nenhum arquivo selecionado"
                      name="iluminacao_file"
                      inputRef={iluminacaoFileRef}
                      required
                      onChange={handleFileChange}
                      onClick={handleIluminacaoFile}
                      error={
                        errorsForm.iluminacaoFile === true && 'Obrigatório'
                      }
                      link={fileLuz?.length > 0 && fileLuz[0].url}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && intervencoesVia ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="utilizacao_associadas"
                          label="Intervenções na via"
                          placeholder="Selecione"
                          options={responseLocation?.utilizacao_associadas?.map(
                            ({ uuid, name_view, protocol, endpoint }) => ({
                              label: `${name_view} - ${protocol}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('utilizacao_associadas', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {Object.values(formData?.utilizacao_associadas || {})
                        .length > 0 && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            viewData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.utilizacao_associadas?.uuid,
                              endpoint:
                                formData?.utilizacao_associadas?.endpoint,
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de intervenções na via?"
                      onClickTrue={handleIntervencoesViaTrue}
                      onClickFalse={handleIntervencoesViaFalse}
                      selected={intervencoesVia}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={
                        errorsForm.intervencoesVia === true && 'Obrigatório'
                      }
                    />
                  )}
                </Wrap>

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label="Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.especificidades
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && reservasVagas ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="reservas_vagas_locacao"
                          label="Reservas de Vagas"
                          placeholder="Selecione"
                          options={responseLocation?.locacao_via_externa?.map(
                            ({
                              uuid,
                              address,
                              number,
                              neighborhood,
                              endpoint,
                            }) => ({
                              label: `${address}, ${number}, ${neighborhood}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('reservas_vagas_locacao', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {formData?.reservas_vagas_locacao && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            viewData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.reservas_vagas_locacao?.uuid,
                              endpoint: 'reservas-vagas-vinculado',
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de reserva de vagas em via próxima?"
                      onClickTrue={handleReservasVagasTrue}
                      onClickFalse={handleReservasVagasFalse}
                      selected={reservasVagas}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.reservasVagas === true && 'Obrigatório'}
                    />
                  )}
                </Wrap>

                {responseLocation?.documents?.length > 0 && isAdminRequest && (
                  <Wrap>
                    <Documents
                      documents={responseLocation?.documents}
                      uuid={responseLocation.uuid}
                    />
                  </Wrap>
                )}

                {responseLocation?.created_at && isAdminRequest && (
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="created_at"
                        label="Data de criação"
                        defaultValue={format(
                          new Date(responseLocation?.created_at),
                          'dd/MM/yyyy HH:mm',
                        )}
                        disabled
                      />
                    </LimiterInputWidth>
                  </Wrap>
                )}
              </WhiteBox>

              <Chat
                uuid={responseLocation?.locacao_default?.uuid}
                chatKeyFilter="locacao_default"
                locacaoNameView={responseLocation?.name_view}
              />

              {responseLocation?.valor_locacao && isAdminRequest && (
                <ValorLocacao
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.valor_locacao}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ModalidadePagamento
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.modalidade_pagamento}
                />
              )}

              {responseLocation && isAdminRequest && (
                <WorkStatusSwitch
                  data={{
                    entity: 'locacao-default',
                    uuid: responseLocation?.locacao_default?.uuid,
                  }}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ObservacoesChat workUuid={responseLocation?.work_uuid} />
              )}

              {dadoState?.uuid && !dadoState.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}

              {Object.keys(responseLocation || {}).length > 0 && (
                <Link
                  to={{
                    pathname: 'redireciona-endpoint',
                    search: `query=${encodeURIComponent(
                      CryptoJS.AES.encrypt(
                        JSON.stringify({
                          is_admin: isAdminRequest,
                          uuid: responseLocation?.uuid,
                          endpoint: responseLocation?.endpoint,
                          mode: 'edit',
                          nome_projeto: responseLocation?.titulo_obra,
                        }),
                        tenancy_env('REACT_APP_API_BASE_URL'),
                      ).toString(),
                    )}`,
                  }}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    width: '100%',
                    maxWidth: '140px',
                    position: 'relative',
                    bottom: '35px',
                  }}
                >
                  <ActionButton
                    style={{
                      backgroundColor: '#828282',
                    }}
                  >
                    Editar
                  </ActionButton>
                </Link>
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Locação Teatro</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Teatro
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderLocacao()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
