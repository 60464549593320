/* eslint-disable no-case-declarations */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

import ApiClient from '~/utils/ApiClient';

import { ChoiceButtonLocacao, Input, Textarea } from '~/components/Forms';

import {
  PageContainer,
  ConditionalContainer,
  LimiterInputWidth,
  MultiInputContainer,
  ActionInputMulti,
  ContainerMultiInputActions,
} from '~/styles/components';

import { NavBarHeader } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';
import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import CheckboxHtml from '~/components/Forms/CheckboxHtml';
import LogoIcon from '~/components/LogoIcon';
import InputNoForm from '~/components/Forms/InputNoForm';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import {
  getBrowserTransformDate,
  validDateArray,
} from '../Locacoes/functions/date-hour-utils';
import CalendarioInfoIcon from './components/CalendarioInfoIcon';
import clientResources from '~/data/clientResources';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function VisitaTecnica() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseLocation, setResponseLocation] = useState();
  const [errorsForm, setErrorsForm] = useState({});

  // states multi input
  const [calendarioInputs, setCalendarioInputs] = useState([
    {
      calendario_data: '',
      calendario_periodo_entre_nove_doze: false,
      calendario_periodo_entre_quatorze_dezoito: false,
    },
  ]);

  const [dadosEquipeInputs, setDadosEquipeInputs] = useState([
    {
      name: '',
      documento_identificacao: '',
    },
  ]);

  // states choices
  const [acessoVeiculosLocal, setAcessoVeiculosLocal] = useState(null);

  // Busca dados da Reserva
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `visita-tecnica/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set choice buttons
      setAcessoVeiculosLocal(typeof respLoc?.placas_veiculos === 'string');

      // set multi inputs values
      setCalendarioInputs(
        respLoc?.calendario?.map(
          ({
            calendario_data,
            calendario_periodo_entre_nove_doze,
            calendario_periodo_entre_quatorze_dezoito,
          }) => ({
            calendario_data: getBrowserTransformDate(
              calendario_data.split(' ')[0],
              true,
            ),
            calendario_periodo_entre_nove_doze:
              calendario_periodo_entre_nove_doze === 1,
            calendario_periodo_entre_quatorze_dezoito:
              calendario_periodo_entre_quatorze_dezoito === 1,
          }),
        ),
      );

      setDadosEquipeInputs(respLoc?.dados_responsaveis);

      setResponseLocation(respLoc);
      setLoading(false);
    }
  }, [dadoState]);

  // effects
  useEffect(() => {
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        previsao_quantidade_membros_equipe: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        placas_veiculos: Yup.string()
          .typeError(defaultMsg)
          .test(
            'acessp-veiculos-local-selecionado',
            defaultMsg,
            value =>
              !(acessoVeiculosLocal === true && (value?.length <= 0 || !value)),
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (
        !validDateArray(
          calendarioInputs.map(({ calendario_data }) => ({
            data_filmagem: calendario_data,
          })),
          'data_filmagem',
        )
      ) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (acessoVeiculosLocal === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Acesso de veículos no local' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          acessoVeiculosLocal: true,
        }));
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  function formatVisitaTecnicaData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      previsao_quantidade_membros_equipe:
        data?.previsao_quantidade_membros_equipe,
      nome_equipe: data?.nome_equipe,
      documento_identificacao: data?.documento_identificacao,
    });

    // dados de choices
    const dataAcessoVeiculosLocal = omitEmpty({
      placas_veiculos: acessoVeiculosLocal ? data?.placas_veiculos : null,
    });

    const calendario = calendarioInputs
      .filter(item => typeof item === 'object')
      .map(item => ({
        ...item,
        [item.calendario_data]: getBrowserTransformDate(item.calendario_data),
      }));

    return Object.assign(
      dataNaoCondicional,
      { calendario },
      { dados_responsaveis: dadosEquipeInputs },
      dataAcessoVeiculosLocal,
    );
  }

  async function handleSubmit(data) {
    if (!(await handleValidationFields(data))) {
      return;
    }

    const visitaTecnicaData = formatVisitaTecnicaData(data);

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: 'visita-tecnica',
        method: ApiClient.POST,
        body: Object.assign(visitaTecnicaData, {
          reference_uuid: dadoState?.uuid,
          name_view: dadoState?.name_view,
        }),
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Visita Técnica',
        text: 'Gravação efetuada com sucesso',
      });

      history.push('/home-user');
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Visita Técnica',
        text: 'Ocorreu um erro ao tentar gravar Visita Técnica. Verifique os dados.',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  // handle true/false
  function handleAcessoVeiculosLocalTrue(e) {
    e.preventDefault();
    setAcessoVeiculosLocal(true);
    setErrorsForm(state => ({
      ...state,
      acessoVeiculosLocal: false,
    }));
  }

  function handleAcessoVeiculosLocalFalse(e) {
    e.preventDefault();
    setAcessoVeiculosLocal(false);
    setErrorsForm(state => ({
      ...state,
      acessoVeiculosLocal: false,
    }));
  }

  // handle multi input
  const handleMultiInputAddField = multiInputType => {
    if (multiInputType === 'calendario') {
      setCalendarioInputs(prevState => [
        ...prevState,
        {
          calendario_data: '',
          calendario_periodo_entre_nove_doze: false,
          calendario_periodo_entre_quatorze_dezoito: false,
        },
      ]);
    } else if (multiInputType === 'dados_equipe') {
      setDadosEquipeInputs(prevState => [
        ...prevState,
        {
          name: '',
          documento_identificacao: '',
        },
      ]);
    }
  };

  const handleMultiInputRemoveField = (index, multiInputType) => {
    if (multiInputType === 'calendario') {
      setCalendarioInputs(calendarioInputs.filter((_, idx) => idx !== index));
    } else if (multiInputType === 'dados_equipe') {
      setDadosEquipeInputs(dadosEquipeInputs.filter((_, idx) => idx !== index));
    }
  };

  const handleMultiInputChange = (index, event, multiInputType) => {
    if (multiInputType === 'data') {
      const values = [...calendarioInputs];
      values[index][event.target.name] = event.target.value;
      setCalendarioInputs(values);
    } else if (multiInputType === 'periodo') {
      const values = [...calendarioInputs];
      values[index][event.target.name] = event.target.checked;
      setCalendarioInputs(values);
    } else if (multiInputType === 'dados_equipe') {
      const values = [...dadosEquipeInputs];
      values[index][event.target.name] = event.target.value;
      setDadosEquipeInputs(values);
    }
  };

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>
                Nenhuma Visita Técnica encontrada
              </h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Informações gerais</span>
                </BoxHeader>
                <Wrap>
                  <Textarea
                    name="form_info"
                    rows="3"
                    value="Visita Técnica (VT), também chamada de Tech Scout, é realizada após a aprovação da locação
                    em questão por parte da equipe do projeto e só deve ocorrer mediante agendamento. Dela participam os
                    produtores, a equipe de direção e os técnicos do projeto, a fim de determinarem como ocorrerá tecnicamente
                    a filmagem"
                    disabled
                  />
                </Wrap>

                <BoxHeader style={{ justifyContent: 'flex-start' }}>
                  <span className="boxText">Calendário</span>
                  <CalendarioInfoIcon />
                </BoxHeader>

                <Wrap>
                  <MultiInputContainer style={{ margin: '0' }}>
                    <BoxHeader style={{ justifyContent: 'normal' }}>
                      <span className="boxText" style={{ color: '#828282' }}>
                        Informe Data(s) e Período(s)
                      </span>
                      <span className="boxText" style={{ color: 'red' }}>
                        *
                      </span>
                    </BoxHeader>
                    {calendarioInputs.map((inputField, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Wrap key={`${inputField}~${index}`}>
                        <LimiterInputWidth>
                          <InputNoForm
                            type="date"
                            name="calendario_data"
                            required
                            value={inputField?.calendario_data}
                            onChange={event =>
                              handleMultiInputChange(index, event, 'data')
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>

                        <LimiterInputWidth
                          jstfyCenter
                          style={{ flexDirection: 'column' }}
                        >
                          <LimiterInputWidth algCenter>
                            <CheckboxHtml
                              name="calendario_periodo_entre_nove_doze"
                              label="Entre 9h e 12h"
                              value={
                                inputField?.calendario_periodo_entre_nove_doze
                              }
                              checked={
                                inputField?.calendario_periodo_entre_nove_doze
                              }
                              onChange={event =>
                                handleMultiInputChange(index, event, 'periodo')
                              }
                              disabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>

                          <LimiterInputWidth algCenter>
                            <CheckboxHtml
                              label="Entre 14h e 18h"
                              name="calendario_periodo_entre_quatorze_dezoito"
                              value={
                                inputField?.calendario_periodo_entre_quatorze_dezoito
                              }
                              checked={
                                inputField?.calendario_periodo_entre_quatorze_dezoito
                              }
                              onChange={event =>
                                handleMultiInputChange(index, event, 'periodo')
                              }
                              disabled={dadoState?.viewData && true}
                            />
                          </LimiterInputWidth>
                        </LimiterInputWidth>

                        {!dadoState?.viewData && (
                          <ContainerMultiInputActions>
                            {calendarioInputs.length !== 1 && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleMultiInputRemoveField(
                                    index,
                                    'calendario',
                                  )
                                }
                              >
                                <AiOutlineMinus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}
                            {calendarioInputs.length - 1 === index && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleMultiInputAddField('calendario')
                                }
                              >
                                <AiOutlinePlus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}
                          </ContainerMultiInputActions>
                        )}
                      </Wrap>
                    ))}
                  </MultiInputContainer>
                </Wrap>

                <BoxHeader>
                  <span className="boxText">Dados da Visita Técnica</span>
                </BoxHeader>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="number"
                      name="previsao_quantidade_membros_equipe"
                      label="Previsão de quantidade de membros da equipe"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.previsao_quantidade_membros_equipe
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <MultiInputContainer style={{ margin: '0' }}>
                    <BoxHeader style={{ justifyContent: 'normal' }}>
                      <span className="boxText">Dados da Equipe</span>
                      <span className="boxText" style={{ color: 'red' }}>
                        *
                      </span>
                    </BoxHeader>
                    {dadosEquipeInputs.map((inputField, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Wrap key={`${inputField}~${index}`}>
                        <LimiterInputWidth>
                          <InputNoForm
                            type="text"
                            name="name"
                            placeholder="Nome"
                            required
                            value={inputField?.name}
                            onChange={event =>
                              handleMultiInputChange(
                                index,
                                event,
                                'dados_equipe',
                              )
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>

                        <LimiterInputWidth>
                          <InputNoForm
                            type="text"
                            name="documento_identificacao"
                            placeholder="Documento de Identificação"
                            required
                            value={inputField?.documento_identificacao}
                            onChange={event =>
                              handleMultiInputChange(
                                index,
                                event,
                                'dados_equipe',
                              )
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>

                        {!dadoState?.viewData && (
                          <ContainerMultiInputActions>
                            {dadosEquipeInputs.length !== 1 && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleMultiInputRemoveField(
                                    index,
                                    'dados_equipe',
                                  )
                                }
                              >
                                <AiOutlineMinus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}
                            {dadosEquipeInputs.length - 1 === index && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleMultiInputAddField('dados_equipe')
                                }
                              >
                                <AiOutlinePlus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}
                          </ContainerMultiInputActions>
                        )}
                      </Wrap>
                    ))}
                  </MultiInputContainer>
                </Wrap>

                <BoxHeader>
                  <span className="boxText">Informações complementares</span>
                </BoxHeader>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá acesso de veículos no local, caso possível?"
                      onClickTrue={handleAcessoVeiculosLocalTrue}
                      onClickFalse={handleAcessoVeiculosLocalFalse}
                      selected={acessoVeiculosLocal}
                      required
                      error={
                        errorsForm.acessoVeiculosLocal === true && 'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={acessoVeiculosLocal}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="placas_veiculos"
                      label="Informar Placa(s) do(s) veículo(s)"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData && responseLocation?.placas_veiculos
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>
              </WhiteBox>

              {!dadoState?.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  disabled={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Visita Técnica</h1>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
