import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { format } from 'date-fns';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineCheck,
  AiOutlineClose,
} from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  ChoiceButtonLocacao,
  Textarea,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ConditionalContainer,
  LimiterInputWidth,
  ActionInputMulti,
  MultiInputContainer,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import ComplementoNumeroListActions from '~/store/ducks/complemento-numero-list';
import FinalidadeReservaListActions from '~/store/ducks/finalidade-reserva-list';
import LocalGeradorListActions from '~/store/ducks/local-gerador-list';
import diffDatasFilmagem from '~/utils/functions/diffDatasFilmagem';
import diffHoursFilmagem from '~/utils/functions/diffHoursFilmagem';
import LogoIcon from '~/components/LogoIcon';
import {
  confirmEditDateHour,
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';
import { IntervalFormMultiInput } from '~/components/Forms/IntervalFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const selectExtensaoList = [
  { value: 'metragem', label: 'Metragem' },
  { value: 'intervalo', label: 'Numeração' },
];

export default function ReservasVagasVinculadoEdit() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseLocation, setResponseLocation] = useState();
  const [errorsForm, setErrorsForm] = useState({});
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);

  // states multi input
  const [intervaloInputs, setIntervaloInputs] = useState([
    { start: '', end: '' },
  ]);
  const [horasReservaInputs, setHorasReservaInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasReservaInputs, setDatasReservaInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states choices
  const [gerador, setGerador] = useState(null);
  const [blimpado, setBlimpado] = useState(null);
  const [drone, setDrone] = useState(null);
  const [outraReservaVaga, setOutraReservaVaga] = useState(null);
  const [reservaMesmaLocacao, setReservaMesmaLocacao] = useState(null);

  // state outro fields
  const [showOutroFinReservaField, setShowOutroFinReservaField] =
    useState(false);
  const [showExtensaoMetragemField, setShowExtensaoMetragemField] =
    useState(false);
  const [showExtensaoIntervalosField, setShowExtensaoIntervalosField] =
    useState(false);
  const [showNumberFields, setShowNumberFields] = useState(false);

  // selectors
  const { data: complementoNumeroSelectList } = useSelector(
    state => state.complementoNumeroList,
  );
  const { data: finalidadeReservaSelectList } = useSelector(
    state => state.finalidadeReservaList,
  );
  const { data: localGeradorSelectList } = useSelector(
    state => state.localGeradorList,
  );

  // Busca dados da Reserva
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-via-externa/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respReserva = resp?.data?.data;

      // set temp variables
      const blimpadoTmp =
        respReserva?.blimpado === null ? null : respReserva?.blimpado === 1;

      // set choices buttons
      setBlimpado(blimpadoTmp);

      setGerador(
        blimpadoTmp === true ||
          Object.values(respReserva?.ld_local_gerador || {}).length > 0,
      );

      setDrone(respReserva?.drone === 1);

      // set campos 'multi'
      setDatasReservaInputs(
        respReserva?.date_locacao_via_externa?.map(
          ({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      setHorasReservaInputs(
        respReserva?.hour_locacao_via_externa?.map(
          ({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      setIntervaloInputs(
        respReserva?.reserva_intervalo
          ? respReserva?.reserva_intervalo?.map(({ start, end }) => {
              return { start, end };
            })
          : [{ start: '', end: '' }],
      );

      // set campos 'Outro'
      setShowOutroFinReservaField(
        respReserva?.finalidades?.find(
          finalidade => finalidade.name === 'Outros',
        ),
      );

      // set select default values
      formRef.current?.setFieldValue('ld_complemento_numero_uuid', {
        label: respReserva?.ld_complemento_numero?.name,
        value: respReserva?.ld_complemento_numero?.uuid,
      });

      const filterExtensaoValue = respReserva?.reserva_intervalo
        ? 'intervalo'
        : 'metragem';
      formRef.current?.setFieldValue(
        'select_extensao',
        selectExtensaoList.filter(
          extensao => extensao.value === filterExtensaoValue,
        ),
      );

      formRef.current?.setFieldValue(
        'finalidades',
        respReserva?.finalidades?.map(ilfr => {
          return {
            label: ilfr.name,
            value: ilfr.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_local_gerador_uuid', {
        label: respReserva?.ld_local_gerador?.name,
        value: respReserva?.ld_local_gerador?.uuid,
      });

      // set show campos condicionais
      setShowNumberFields(typeof respReserva?.number === 'string');
      setShowExtensaoMetragemField(
        Object.values(respReserva?.reserva_intervalo || []).length <= 0,
      );
      setShowExtensaoIntervalosField(
        Object.values(respReserva?.reserva_intervalo || []).length > 0,
      );

      setResponseLocation(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState?.viewData]);

  // effects
  useEffect(() => {
    dispatch(ComplementoNumeroListActions.showRequest());
    dispatch(FinalidadeReservaListActions.showRequest());
    dispatch(LocalGeradorListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'number') {
      setShowNumberFields(value !== '');
    }
  }

  function handleMultiSelectFinalidades(e) {
    setShowOutroFinReservaField(!!e?.find(x => x.label === 'Outros'));

    setFormData(state => ({
      ...state,
      finalidades: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'select_extensao') {
      setShowExtensaoMetragemField(option.value === 'metragem');
      setShowExtensaoIntervalosField(option.value === 'intervalo');
    }
  }

  function formatEspecificaData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      address: data?.address,
      number: data?.number,
      neighborhood: data?.neighborhood,
      finalidades: data?.finalidades,
      especificidades: data?.especificidades,
    });

    // dados de choices
    const dataGerador = {
      ld_local_gerador_uuid: gerador ? data?.ld_local_gerador_uuid : null,
      blimpado: gerador ? blimpado : null,
    };

    const dataDrone = { drone };

    // dados de campos exibidos condicionalmente
    const dataNumberFields = {
      ld_complemento_numero_uuid: showNumberFields
        ? data?.ld_complemento_numero_uuid
        : null,
    };

    const dataExtensaoMetragem = {
      metragem: showExtensaoMetragemField ? +data?.metragem : null,
    };

    const dataExtensaoIntervalos = {
      reserva_intervalo: showExtensaoIntervalosField ? intervaloInputs : null,
    };

    const dataOutroFinReserva = {
      outra_finalidade: showOutroFinReservaField
        ? data?.outra_finalidade
        : null,
    };

    // diff dates e hours
    const diffDatas = diffDatasFilmagem({
      datasFilmagemLocacao: responseLocation?.date_locacao_via_externa?.map(
        ({ uuid, date, changes_to_approve }) =>
          omitEmpty({
            uuid,
            data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
            changes_to_approve,
          }),
      ),
      datasFilmagemInput: datasReservaInputs?.map(
        ({ uuid, data_filmagem, changes_to_approve }) =>
          omitEmpty({
            uuid,
            data_filmagem,
            changes_to_approve,
          }),
      ),
      dateKeyName: 'data_filmagem',
    });

    const diffHours = diffHoursFilmagem({
      hoursFilmagemLocacao: responseLocation?.hour_locacao_via_externa?.map(
        ({ uuid, inicio, fim, changes_to_approve }) =>
          omitEmpty({
            uuid,
            inicio: inicio.substring(0, 5),
            fim: fim.substring(0, 5),
            changes_to_approve,
          }),
      ),
      hoursFilmagemInput: horasReservaInputs?.map(
        ({ uuid, inicio, fim, changes_to_approve }) =>
          omitEmpty({
            uuid,
            inicio,
            fim,
            changes_to_approve,
          }),
      ),
      hourBeginKeyName: 'inicio',
      hourEndKeyName: 'fim',
    });

    return Object.assign(
      dataNaoCondicional,
      dataNumberFields,
      dataExtensaoMetragem,
      dataExtensaoIntervalos,
      { dates: diffDatas },
      { hours: diffHours },
      dataGerador,
      dataDrone,
      dataOutroFinReserva,
    );
  }

  async function handleValidationFields(data) {
    try {
      if (!validDateArray(datasReservaInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasReservaInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      return false;
    }

    return true;
  }

  async function handleSubmit(data) {
    data.finalidades = formData?.finalidades;

    try {
      if (!(await handleValidationFields(data))) {
        return;
      }

      const reservasData = formatEspecificaData(data);

      setLoading(true);

      if (isAdminRequest) {
        await ApiClient.doRequest({
          uri: `locacao-via-externa/${dadoState?.viewData?.uuid}`,
          method: ApiClient.PATCH,
          body: reservasData,
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title: 'Reservas de Vagas Vinculado',
          text: 'Alteração enviada com sucesso',
        });

        history.push('/locacoes');
      } else {
        if (!reservasData?.dates && !reservasData?.hours) {
          setLoading(false);
          await showModalMessage({
            type: 'info',
            title: 'Reservas de Vagas Vinculado',
            text: 'Não houve alteração em Data/Hora a ser salva',
          });
          return;
        }

        await ApiClient.doRequest({
          uri: `locacao-via-externa/common-user/${dadoState?.viewData?.uuid}`,
          method: ApiClient.PATCH,
          body: {
            dates: reservasData?.dates,
            hours: reservasData?.hours,
          },
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title: 'Reservas de Vagas Vinculado',
          text: 'As alterações foram enviadas para avaliação da Ilhacine',
        });

        history.push('/home-user');
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Reservas de Vagas Vinculado',
        text: 'Ocorreu um erro ao tentar gravar Reservas de Vagas. Verifique os dados.',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  // handle true/false
  function handleReservaMesmaLocacaoTrue(e) {
    e.preventDefault(e);
    setReservaMesmaLocacao(true);
    setErrorsForm(state => ({
      ...state,
      reservaMesmaLocacao: false,
    }));
  }

  function handleReservaMesmaLocacaoFalse(e) {
    e.preventDefault(e);
    setReservaMesmaLocacao(false);
    setErrorsForm(state => ({
      ...state,
      reservaMesmaLocacao: false,
    }));
  }

  function handleOutraReservaVagaTrue(e) {
    e.preventDefault(e);
    setOutraReservaVaga(true);
    setErrorsForm(state => ({
      ...state,
      outraReservaVaga: false,
    }));
  }

  function handleOutraReservaVagaFalse(e) {
    e.preventDefault(e);
    setOutraReservaVaga(false);
    setErrorsForm(state => ({
      ...state,
      outraReservaVaga: false,
    }));
  }

  function handleGeradorTrue(e) {
    e.preventDefault();
    setGerador(true);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorFalse(e) {
    e.preventDefault();
    setGerador(false);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleBlimpadorTrue(e) {
    e.preventDefault();
    setBlimpado(true);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBlimpadorFalse(e) {
    e.preventDefault();
    setBlimpado(false);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleDroneTrue(e) {
    e.preventDefault();
    setDrone(true);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleDroneFalse(e) {
    e.preventDefault();
    setDrone(false);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Informações gerais</span>
                </BoxHeader>
                <Wrap>
                  <Textarea
                    name="form_info"
                    rows="3"
                    value="Este formulário é somente para solicitações de reserva de vagas de filmagens em
                      equipamentos públicos municipais. Solicitações que envolvam bloqueio(s) de via, ocupações de passeio
                      (calçada) para filmagem ou apoio, deverão ser solicitadas no formulário “Utilização de Via para
                      Filmagens ou Apoio."
                    disabled
                  />
                </Wrap>

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={!isAdminRequest}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="address"
                      label="Endereço da Via"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.address
                      }
                      disabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="number"
                      label="Número"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.number
                      }
                      disabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showNumberFields}>
                    <LimiterInputWidth>
                      <Select
                        name="ld_complemento_numero_uuid"
                        label="Complemento"
                        required
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={complementoNumeroSelectList}
                        onChange={value => {
                          handleSelectChange(
                            'ld_complemento_numero_uuid',
                            value,
                          );
                        }}
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.neighborhood
                      }
                      disabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Select
                      name="select_extensao"
                      label="Extensão"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={selectExtensaoList}
                      required
                      onChange={value => {
                        handleSelectChange('select_extensao', value);
                      }}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showExtensaoMetragemField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name="metragem"
                        label="Metragem"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData && responseLocation?.metragem
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={showExtensaoIntervalosField}>
                  <IntervalFormMultiInput
                    label="Intervalo de Numeração"
                    tooltip="De ___ até ___ (Pode adicionar mais de um intervalo)"
                    isRequired={showExtensaoIntervalosField}
                    beginKeyName="start"
                    endKeyName="end"
                    isViewMode={!isAdminRequest}
                    intervals={intervaloInputs}
                    setIntervals={setIntervaloInputs}
                  />
                </ConditionalContainer>

                <Wrap>
                  <DateFormMultiInput
                    label="Data da Reserva"
                    dates={datasReservaInputs}
                    setDates={setDatasReservaInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    dateKeyName="data_filmagem"
                    tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                    isAdminRequest={isAdminRequest}
                    acceptPast
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Hora da Reserva (Início - Fim)"
                    hours={horasReservaInputs}
                    setHours={setHorasReservaInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="O horário informado deve ser referente ao início da operacionalização da reserva de vagas, e não da filmagem. Possível adicionar mais de um horário."
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="finalidades"
                      label="Finalidade da Reserva de Vagas"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={finalidadeReservaSelectList}
                      required
                      isMulti
                      onChange={handleMultiSelectFinalidades}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showOutroFinReservaField}>
                    <LimiterInputWidth>
                      <Input
                        type="text"
                        name="outra_finalidade"
                        label="Outro Finalidade Reserva"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.outra_finalidade
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Haverá gerador?"
                      onClickTrue={handleGeradorTrue}
                      onClickFalse={handleGeradorFalse}
                      selected={gerador}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.gerador === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={gerador === true}>
                    <LimiterInputWidth w25>
                      <ChoiceButtonLocacao
                        label="Blimpado/Silencioso ou Não-Blimpado?"
                        onClickTrue={handleBlimpadorTrue}
                        onClickFalse={handleBlimpadorFalse}
                        selected={blimpado}
                        required
                        isDisabled={!isAdminRequest}
                        error={errorsForm.blimpado === true && 'Obrigatório'}
                        yesLabelValue="Blimpado"
                        noLabelValue="Não-Blimpado"
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <ConditionalContainer show={gerador === true}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_local_gerador_uuid"
                        label="O gerador ficará:"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={localGeradorSelectList}
                        required
                        onChange={value => {
                          handleSelectChange('ld_local_gerador_uuid', value);
                        }}
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Drone"
                      onClickTrue={handleDroneTrue}
                      onClickFalse={handleDroneFalse}
                      selected={drone}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.drone === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={drone === true}>
                  <Wrap>
                    <Textarea
                      name="autorizacao_drones"
                      rows="4"
                      value="Para filmagens com drones: o Espaço Aéreo não é de administração municipal, mas sim da
                    Federação/União, portanto, não faz parte do campo de atuação da Ilhacine. A autorização para o uso
                    de drones deve ser solicitada diretamente à Agência Nacional de Aviação Civil (ANAC), mediante
                    cadastro no Sistema SARPAS, disponibilizado no site do Departamento de Controle do Espaço Aéreo
                    (DECEA)."
                      disabled
                    />
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label=" Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData && responseLocation?.especificidades
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>
              </WhiteBox>
              <Button
                type="submit"
                btType="#26A2B1"
                label="Enviar"
                full
                width="140px"
                loading={loading}
                style={{ position: 'relative', bottom: '35px' }}
              />
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Reservas de Vagas (Vinculado)</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Reservas
                de Vagas (Vinculado)
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
