import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '../../services/notification';

import api, { makeOptions } from '../../services/api';
import RolesActions from '../ducks/userRoles';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));
// const getAuthData = (state) => state.auth.data;

export function* fetch() {
  try {
    // const authData = yield select(getAuthData);
    const url = `/me`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data } = responseData;
    // eslint-disable-next-line camelcase
    const userRole = data?.roles?.map(roles => ({
      name: roles.name,
    }));

    yield put(RolesActions.success(userRole));
  } catch (err) {
    yield put(RolesActions.failure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* fetchListRoles() {
  try {
    // const authData = yield select(getAuthData);
    const url = `/role`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data } = responseData;
    // eslint-disable-next-line camelcase

    const userRole = [
      {
        value: data[0].uuid,
        label: data[0].name,
      },
      {
        value: data[1].uuid,
        label: data[1].name,
      },
    ];

    yield put(RolesActions.roleSuccess(userRole));
  } catch (err) {
    yield put(RolesActions.roleFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}
