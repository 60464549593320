import React, { useCallback, useEffect, useRef, useState } from 'react';

// Libs
import * as Yup from 'yup';
import { Form } from '@unform/web';

import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Input } from '~/components/Forms';
import { Button } from '~/components/global';
import { PageContainer, Container, Content, Wrap, Logotips } from './styles';

import api from '~/services/api';
import { showModalMessage } from '~/services/notification';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import clientResources from '~/data/clientResources';

export default function RecoveryPassword() {
  const formLogin = useRef();
  const [qtdError, setQtdError] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { countError } = useSelector(state => state.auth);

  const token = useLocation().search?.split('token=')[1]?.split('&')[0];

  const handleSubmit = useCallback(
    async data => {
      try {
        const defaultMsg = 'Obrigatório';

        formLogin.current.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          password_confirmation: Yup.string()
            .required(defaultMsg)
            .test('password-match', 'Senhas estão diferente', function (value) {
              // eslint-disable-next-line react/no-this-in-sfc
              return this.parent.password === value;
            }),
        });

        if (!token) {
          await showModalMessage({
            type: 'error',
            text: 'Token não informado',
          });

          return;
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        if (countError === true) {
          setQtdError(qtdError + 1);
        }

        setLoading(true);

        const form = {
          password: data.password,
          password_confirmation: data.password,
          token,
        };

        await api.patch('user/reset-password', form);

        await showModalMessage({
          type: 'success',
          text: 'Sua senha foi atualizada com sucesso!',
        });

        setTimeout(() => {
          history.push('/login');
        }, 800);
      } catch (err) {
        await showModalMessage({
          type: 'error',
          text: err.response?.message,
        });

        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formLogin.current.setErrors(validationErrors);
        }
      } finally {
        setLoading(false);
      }
    },
    [countError, history, qtdError, token],
  );

  useEffect(() => {}, [countError, qtdError]);

  return (
    <>
      <PageContainer background={clientResources.images.login.heroLoginBg}>
        <Header>
          <LogoIcon disabled />
        </Header>
        <Container>
          <Content className="recovery">
            <h1>Nova Senha</h1>
            <p>A senha deve conter no mínimo 6 dígitos!</p>
            <Form ref={formLogin} onSubmit={handleSubmit}>
              <Wrap>
                <Input type="password" name="password" label="Nova Senha" />
                <Input
                  type="password"
                  name="password_confirmation"
                  label="Repetir Senha"
                />
              </Wrap>
              <Wrap className="forgot-password">
                <div />
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  disabled={loading}
                />
              </Wrap>
            </Form>
          </Content>
        </Container>
        <Logotips>
        <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
        </Logotips>
      </PageContainer>
    </>
  );
}
