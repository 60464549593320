import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import { MdRateReview } from 'react-icons/md';

function ListaPageAlteracoesSolicitadas({ data }) {
  return (
    <>
      <tr>
        <td>
          {data && format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm')}
        </td>
        <td>{data && data?.protocol}</td>
        <td>{data && data?.name}</td>

        {data?.endpoint !== 'work' && (
          <>
            <td>{data && data?.workProtocol}</td>
            <td>{data && data?.workName}</td>
          </>
        )}

        <td
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="buttons"
        >
          <span tooltip="Avaliar edição...">
            {data?.endpoint === 'work' ? (
              <Link
                to={{
                  pathname: '/obra/edit',
                  state: { data },
                }}
              >
                <MdRateReview color="#4f4f4f" size={24} />
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/solicitacao-nova-locacao/${data?.endpoint}/edit`,
                  state: { viewData: data, isAdminRequest: true },
                }}
              >
                <MdRateReview color="#4f4f4f" size={24} />
              </Link>
            )}
          </span>
        </td>
      </tr>
    </>
  );
}

export default withRouter(ListaPageAlteracoesSolicitadas);
