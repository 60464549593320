import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-family: 'InterRegular', sans-serif;
  font-size: 16px;
  color: #828282;

  & span.label {
    margin-right: 5px;
  }

  & span.value {
    font-family: 'InterBold', sans-serif;
  }
`;
