import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import ApiToken from './ApiToken';
import tenancy_env from '~/utils/MultiTenancyUtil';

const GET = 'get';
const POST = 'post';
const PATCH = 'patch';
const DELETE = 'delete';

class ApiClient {
  constructor(version = null) {
    this.axiosInstance = null;
    this.baseURL = tenancy_env('REACT_APP_API_BASE_URL');
    this.prefix = tenancy_env('REACT_APP_API_PREFIX');
    this.version =
      version === null ? tenancy_env('REACT_APP_API_VERSION') : version;
    this.makeInstance();
  }

  makeInstance() {
    const baseURL = `${this.baseURL}/${this.prefix}/${this.version}/`;
    this.axiosInstance = axios.create({
      baseURL,
      validateStatus: false,
    });
  }

  getInstance() {
    return this.axiosInstance;
  }

  static async doRequest({
    uri,
    method = ApiClient.GET,
    auth = true,
    body = {},
    token = null,
    expectedStatus = null,
    isBinary = false,
    fileName = 'arquivo',
  }) {
    const apiClient = new ApiClient();

    return apiClient.doRequest({
      uri,
      method,
      auth,
      body,
      token,
      expectedStatus,
      isBinary,
      fileName,
    });
  }

  async doRequest({
    uri,
    method = ApiClient.GET,
    auth = true,
    body = {},
    token = null,
    expectedStatus = null,
    isBinary = false,
    fileName = 'arquivo',
  }) {
    // eslint-disable-next-line no-useless-catch
    try {
      if (auth === true && token === null) {
        token = await ApiToken.get().verifyToken();
        this.axiosInstance.defaults.headers.common.Authorization = token;
      }

      if (token !== null) {
        this.axiosInstance.defaults.headers.common.Authorization = token;
      }

      this.axiosInstance.defaults.headers = {
        ...this.axiosInstance.defaults.headers,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      };

      const response = await this.axiosInstance.request({
        url: uri,
        method,
        data: body,
        responseType: isBinary && 'blob',
      });

      if (expectedStatus !== null) {
        if (response.status !== expectedStatus) {
          throw new Error(
            JSON.stringify({
              error: response.data,
              status: response.status,
            }),
          );
        }
      }

      if (isBinary) {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      return response;
    } catch (e) {
      throw e;
    }
  }

  static get GET() {
    return GET;
  }

  static get POST() {
    return POST;
  }

  static get PATCH() {
    return PATCH;
  }

  static get DELETE() {
    return DELETE;
  }
}

export default ApiClient;
