import { combineReducers } from 'redux';
import { reducer as auth } from './auth';
import { reducer as register } from './register';
import { reducer as createPassword } from './create-password';
import { reducer as work } from './work';
import { reducer as countriesList } from './countries-list';
import { reducer as statesList } from './states-list';
import { reducer as citiesList } from './cities-list';
import { reducer as neighborhood } from './neighborhood';
import { reducer as neighborhoodList } from './neighborhood-list';
import { reducer as ldInstacoesEsportivasList } from './ld-instalacoes-esportivas';
import { reducer as ldGaleriasList } from './ld-galerias';
import { reducer as userAddress } from './users-address';
import { reducer as userPersonalData } from './users-personal-data';
import { reducer as userMe } from './userMe';
import { reducer as userPersonalDataList } from './users-personal-data-list';
import { reducer as usersList } from './users-list';
import { reducer as branchList } from './branch-list';
import { reducer as workStatusList } from './work-status-list';
import { reducer as workTypeList } from './work-type-list';
import { reducer as equipamentoFilmagemList } from './equipamento-filmagem-list';
import { reducer as praiaList } from './praia-list';
import { reducer as instalacoesList } from './instalacoes';
import { reducer as pavimentadaList } from './ld-area-pavimentada';
import { reducer as impactoList } from './impacto-list';
import { reducer as finalidadeBaseApoioList } from './finalidade-base-apoio-list';
import { reducer as somFilmagemList } from './som-filmagem-list';
import { reducer as efeitoEspecialList } from './efeito-especial-list';
import { reducer as filmagemPublicaList } from './filmagem-publica-list';
import { reducer as bloqueioAguaList } from './bloqueio-agua-list';
import { reducer as parqueList } from './parque-list';
import { reducer as equipamentoEsportivoList } from './equipamento-esportivo-list';
import { reducer as reminder } from './reminder';
import { reducer as parque } from '../parque';
import { reducer as workList } from './work-list';
import { reducer as reminderList } from './reminder-list';
import { reducer as locacaoDefault } from './locacao-default';
import { reducer as logs } from './logs';
import { reducer as roles } from './userRoles';
import { reducer as usersAdmin } from './userAdmin';
import { reducer as cemiterioList } from './cemiterio-list';
import { reducer as mercadoList } from './mercado-list';
import { reducer as tipoFilmagemMercadoList } from './tipo-filmagem-mercado-list';
import { reducer as finalidadeReservaList } from './finalidade-reserva-list';
import { reducer as complementoNumeroList } from './complemento-numero-list';
import { reducer as localGeradorList } from './local-gerador-list';
import { reducer as getByGaleriasList } from './get-by-galeria';
import { reducer as ldEdificiosList } from './ld-ponto-fixo-edificio';
import { reducer as ldEquimentosSmcList } from './ld-equipamentos-smc';
import { reducer as terminaisOnibusList } from './terminais-onibus-list';
import { reducer as pontosFixosTerminaisList } from './pontos-fixos-terminais-list';
import { reducer as trajetoTerminalList } from './trajeto-terminal-list';
import { reducer as finalidadeAcessoList } from './finalidade-acesso-list';
import { reducer as pontosFixosEducacaoList } from './ld-ponto-fixo-educacao';

import { reducer as ldCameraCarList } from './ld-tipo-camera-car';
import { reducer as ldTipoComboio } from './ld-tipo-comboio';
import { reducer as ldBibliotecaList } from './ld-biblioteca';
import { reducer as ldMuseuList } from './ld-museu';
import { reducer as ldCasasCulturaList } from './ld-casas-cultura';
import { reducer as ldCentroCulturalList } from './ld-centro-cultural';
import { reducer as ldProjetoPesquisaSocioeconomicaList } from './ld-projeto-pesquisa-socioeconomica';
import { reducer as ldRazaoPesquisaSocioeconomicaList } from './ld-razao-pesquisa-socioeconomica';
import { reducer as ldGastosEstimativaList } from './ld-gastos-estimativa';
import { reducer as ldOrcamentoObraList } from './ld-orcamento-obra';
import { reducer as ldGruaOpcoesList } from './ld-grua-opcoes';

const reducers = combineReducers({
  auth,
  register,
  createPassword,
  work,
  workList,
  countriesList,
  statesList,
  citiesList,
  neighborhood,
  neighborhoodList,
  userAddress,
  userPersonalData,
  userMe,
  userPersonalDataList,
  usersList,
  branchList,
  workStatusList,
  workTypeList,
  equipamentoFilmagemList,
  praiaList,
  instalacoesList,
  pavimentadaList,
  impactoList,
  finalidadeBaseApoioList,
  somFilmagemList,
  efeitoEspecialList,
  filmagemPublicaList,
  bloqueioAguaList,
  parqueList,
  equipamentoEsportivoList,
  reminder,
  reminderList,
  locacaoDefault,
  logs,
  roles,
  usersAdmin,
  parque,
  cemiterioList,
  mercadoList,
  tipoFilmagemMercadoList,
  finalidadeReservaList,
  complementoNumeroList,
  localGeradorList,
  ldInstacoesEsportivasList,
  ldGaleriasList,
  getByGaleriasList,
  ldEdificiosList,
  ldEquimentosSmcList,
  terminaisOnibusList,
  pontosFixosTerminaisList,
  trajetoTerminalList,
  finalidadeAcessoList,
  pontosFixosEducacaoList,
  ldCameraCarList,
  ldTipoComboio,
  ldBibliotecaList,
  ldMuseuList,
  ldCasasCulturaList,
  ldCentroCulturalList,
  ldProjetoPesquisaSocioeconomicaList,
  ldRazaoPesquisaSocioeconomicaList,
  ldGastosEstimativaList,
  ldOrcamentoObraList,
  ldGruaOpcoesList,
});

export default reducers;
