import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { format, parse } from 'date-fns';
import omitEmpty from 'omit-empty';
import { FiLoader } from 'react-icons/fi';
import { GridContainer, Line, Wrap } from '~/styles/components';
import { useList } from '~/hooks/useFunction';
import { CheckboxHtml, Input, Select } from '~/components/Forms';
import Button from '~/components/Button';
import MultiDateInput from '~/components/Forms/MultiDateInput';
import { showModalMessage } from '~/services/notification';
import { VirtualTable } from '~/components/Forms/VirtualTable';
import { AccordionItem } from '~/components/Forms/AccordionItem';
import { Text } from '~/components/Forms/Text';
import ApiClient from '~/utils/ApiClient';
import { SplitButton } from '~/components/SplitButton';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const uri = 'report-obra';

export const ObrasGeralForm = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});

  // lists
  const workStatusList = useList({
    url: 'work-status?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const veiculacaoInicialList = useList({
    url: 'ld-veiculo-inicial?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  const workTypeList = useList({
    url: 'work-type?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const estadosList = useList({ url: 'state?paginate=false' }).map(item => ({
    value: item.uuid,
    label: item.name,
    abbr: item.abbr,
  }));

  const paisesList = useList({ url: 'country?paginate=false' }).map(item => ({
    value: item.uuid,
    label: item.name,
    code: item.code,
  }));

  const canaisAbertosList = useList({
    url: 'ld-canais-aberto?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  const canaisPagosFilmeList = useList({
    url: 'ld-canais-pagos-filme?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  const canaisPagosInfantilList = useList({
    url: 'ld-canais-pagos-infantil?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  const canaisPagosNoticiaList = useList({
    url: 'l-canais-pagos-noticia?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  const canaisPagosDocumentarioList = useList({
    url: 'ld-canais-pagos-documentario?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  const canaisPagosVariedadeList = useList({
    url: 'ld-canais-pagos-variedade?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  const canaisPagosEsporteList = useList({
    url: 'ld-canais-pagos-esporte?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));

  const streamingList = useList({ url: 'ld-streaming?paginate=false' })?.map(
    ({ uuid, name }) => ({ value: uuid, label: name }),
  );

  const handleChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleResetForm = () => {
    setFormData({
      datas_cadastro: [],
      previsao_estreia: [],
      inicio_filmagem: [],
      final_filmagem: [],
      ld_veiculacao_inicial: [],
      work_type: [],
      work_status: [],
      state: '',
      country: '',
      director: '',
      spfilm_contact: '',
      production_coordination: '',
      production_director: '',
      production_executive: '',
    });
  };

  const handleValidationFields = useCallback(async () => {
    try {
      formRef.current.setErrors({});

      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        datas_cadastro: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'datas-cadastro-nao-informadas',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }, [formData]);

  const bodyBuilder = useCallback(async () => {
    if (!(await handleValidationFields())) return null;

    try {
      // format dates
      const datasCadastro = formData?.datas_cadastro?.map(date =>
        format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      );

      const datasPrevisaoEstreia = formData?.previsao_estreia?.map(date =>
        format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      );

      const datasInicioFilmagem = formData?.inicio_filmagem?.map(date =>
        format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      );

      const datasFimFilmagem = formData?.final_filmagem?.map(date =>
        format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      );

      const body = omitEmpty({
        start_date: datasCadastro[0],
        end_date:
          datasCadastro.length === 1 ? datasCadastro[0] : datasCadastro[1],
        status: formData?.work_status?.map(({ value }) => value),
        ldVeiculacaoInicial: formData?.ld_veiculacao_inicial?.map(
          ({ value }) => value,
        ),
        ldCanaisAbertos: formData?.ld_canais_abertos?.map(({ value }) => value),
        ldCanaisDocumentarios: formData?.ld_canais_documentarios?.map(
          ({ value }) => value,
        ),
        ldCanaisEsporte: formData?.ld_canais_esporte?.map(({ value }) => value),
        ldCanaisFilme: formData?.ld_canais_filme?.map(({ value }) => value),
        ldCanaisInfantil: formData?.ld_canais_infantil?.map(
          ({ value }) => value,
        ),
        ldCanaisNoticia: formData?.ld_canais_noticia?.map(({ value }) => value),
        ldCanaisVariedade: formData?.ld_canais_variedade?.map(
          ({ value }) => value,
        ),
        ldStreaming: formData?.ld_streaming?.map(({ value }) => value),
        work_type: formData?.work_type?.map(({ value }) => value),
        is_indie: formData?.producao_independente,
        cooproduction: formData?.coproducao,
        state: formData?.state?.value,
        // created_at: filtrar uma data cadastro especifica dentro do periodo informado,
        previsao_estreia: datasPrevisaoEstreia,
        visit_date: datasInicioFilmagem,
        visit_date_fim: datasFimFilmagem,
        is_program_incentive: formData?.incentivo,
        is_production_service: formData?.production_service,
        country: formData?.country?.value,
        director: formData?.director,
        contact_user: formData?.spfilm_contact,
        production_coordinator: formData?.production_coordination,
        production_drector: formData?.production_director,
        executive_producer: formData?.production_executive,
      });

      return body;
    } catch (error) {
      showModalMessage({
        type: 'error',
        text: 'Erro ao formatar dados para requisição',
      });
      return null;
    }
  }, [
    formData?.coproducao,
    formData?.country?.value,
    formData?.datas_cadastro,
    formData?.director,
    formData?.final_filmagem,
    formData?.incentivo,
    formData?.inicio_filmagem,
    formData?.ld_canais_abertos,
    formData?.ld_canais_documentarios,
    formData?.ld_canais_esporte,
    formData?.ld_canais_filme,
    formData?.ld_canais_infantil,
    formData?.ld_canais_noticia,
    formData?.ld_canais_variedade,
    formData?.ld_streaming,
    formData?.ld_veiculacao_inicial,
    formData?.previsao_estreia,
    formData?.producao_independente,
    formData?.production_coordination,
    formData?.production_director,
    formData?.production_executive,
    formData?.spfilm_contact,
    formData?.state?.value,
    formData?.work_status,
    formData?.work_type,
    handleValidationFields,
  ]);

  const handleClickGerar = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri,
        method: ApiClient.POST,
        body,
        expectedStatus: 200,
      });

      setResponseData(resp.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  const handleClickExportarExcel = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      await ApiClient.doRequest({
        uri: `${uri}/xls`,
        method: ApiClient.POST,
        body: { ...body, report_name: 'getWorkReport' },
        expectedStatus: 200,
        isBinary: true,
        fileName: 'relatorio.xlsx',
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  const renderVeiculacaoInicialInputs = () => {
    return (
      <>
        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'TV Aberta',
        ) && (
          <Select
            name="ld_canais_abertos"
            label="Televisão - Canais Abertos"
            placeholder="Selecione"
            style={selectCustomStyles}
            options={canaisAbertosList}
            onChange={e => {
              handleChange(
                'ld_canais_abertos',
                e?.map(({ value, label }) => ({ value, label })),
              );
            }}
            isMulti
          />
        )}

        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'TV Paga',
        ) && (
          <Select
            name="ld_canais_filme"
            label="Filmes/Séries"
            placeholder="Selecione"
            style={selectCustomStyles}
            options={canaisPagosFilmeList}
            onChange={e => {
              handleChange(
                'ld_canais_filme',
                e?.map(({ value, label }) => ({ value, label })),
              );
            }}
            isMulti
          />
        )}
        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'TV Paga',
        ) && (
          <Select
            name="ld_canais_infantil"
            label="Infantis"
            placeholder="Selecione"
            style={selectCustomStyles}
            options={canaisPagosInfantilList}
            onChange={e => {
              handleChange(
                'ld_canais_infantil',
                e?.map(({ value, label }) => ({ value, label })),
              );
            }}
            isMulti
          />
        )}
        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'TV Paga',
        ) && (
          <Select
            name="ld_canais_noticia"
            label="Notícias"
            placeholder="Selecione"
            style={selectCustomStyles}
            options={canaisPagosNoticiaList}
            onChange={e => {
              handleChange(
                'ld_canais_noticia',
                e?.map(({ value, label }) => ({ value, label })),
              );
            }}
            isMulti
          />
        )}
        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'TV Paga',
        ) && (
          <Select
            name="ld_canais_documentarios"
            label="Documentários"
            placeholder="Selecione"
            style={selectCustomStyles}
            options={canaisPagosDocumentarioList}
            onChange={e => {
              handleChange(
                'ld_canais_documentarios',
                e?.map(({ value, label }) => ({ value, label })),
              );
            }}
            isMulti
          />
        )}
        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'TV Paga',
        ) && (
          <Select
            name="ld_canais_variedade"
            label="Variedades"
            placeholder="Selecione"
            style={selectCustomStyles}
            options={canaisPagosVariedadeList}
            onChange={e => {
              handleChange(
                'ld_canais_variedade',
                e?.map(({ value, label }) => ({ value, label })),
              );
            }}
            isMulti
          />
        )}
        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'TV Paga',
        ) && (
          <Select
            name="ld_canais_esporte"
            label="Esportes"
            placeholder="Selecione"
            style={selectCustomStyles}
            options={canaisPagosEsporteList}
            onChange={e => {
              handleChange(
                'ld_canais_esporte',
                e?.map(({ value, label }) => ({ value, label })),
              );
            }}
            isMulti
          />
        )}
        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'Plataforma de Streaming',
        ) && (
          <Select
            name="ld_streaming"
            label="Plataforma de Streaming"
            placeholder="Selecione"
            style={selectCustomStyles}
            options={streamingList}
            onChange={e => {
              handleChange(
                'ld_streaming',
                e?.map(({ value, label }) => ({ value, label })),
              );
            }}
            isMulti
          />
        )}
        {formData?.ld_veiculacao_inicial?.some(
          ({ label }) => label === 'Outros',
        ) && (
          <Input
            name="outro_veiculacao"
            label="Outros (Veiculação Inicial)"
            onChange={event => {
              handleChange('outro_veiculacao', event.target.value);
            }}
          />
        )}
      </>
    );
  };

  const renderFiltros = () => {
    return (
      <>
        <Wrap>
          <GridContainer cols={4} gap={30}>
            <MultiDateInput
              range
              name="datas_cadastro"
              label="Data de Cadastro"
              required
              value={formData?.datas_cadastro}
              onChange={e => {
                handleChange(
                  'datas_cadastro',
                  e?.map(
                    ({ day, month, year }) =>
                      `${String(day).padStart(2, '0')}/${String(month).padStart(
                        2,
                        '0',
                      )}/${year}`,
                  ),
                );
              }}
            />

            {Object.values(formData?.datas_cadastro || []).length > 1 && (
              <>
                <MultiDateInput
                  multiple
                  acceptFuture
                  name="previsao_estreia"
                  label="Previsão de Estreia"
                  value={formData?.previsao_estreia}
                  onChange={e => {
                    handleChange(
                      'previsao_estreia',
                      e?.map(
                        ({ day, month, year }) =>
                          `${String(day).padStart(2, '0')}/${String(
                            month,
                          ).padStart(2, '0')}/${year}`,
                      ),
                    );
                  }}
                />

                <MultiDateInput
                  multiple
                  acceptFuture
                  name="inicio_filmagem"
                  label="Início de Filmagem"
                  value={formData?.inicio_filmagem}
                  onChange={e => {
                    handleChange(
                      'inicio_filmagem',
                      e?.map(
                        ({ day, month, year }) =>
                          `${String(day).padStart(2, '0')}/${String(
                            month,
                          ).padStart(2, '0')}/${year}`,
                      ),
                    );
                  }}
                />

                <MultiDateInput
                  multiple
                  acceptFuture
                  name="final_filmagem"
                  label="Final de Filmagem"
                  value={formData?.final_filmagem}
                  onChange={e => {
                    handleChange(
                      'final_filmagem',
                      e?.map(
                        ({ day, month, year }) =>
                          `${String(day).padStart(2, '0')}/${String(
                            month,
                          ).padStart(2, '0')}/${year}`,
                      ),
                    );
                  }}
                />

                <CheckboxHtml
                  name="producao_independente"
                  label="É uma produção independente?"
                  value={formData?.producao_independente}
                  checked={formData?.producao_independente}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <CheckboxHtml
                  name="production_service"
                  label="É production service?"
                  value={formData?.production_service}
                  checked={formData?.production_service}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <CheckboxHtml
                  name="coproducao"
                  label="É coprodução?"
                  value={formData?.coproducao}
                  checked={formData?.coproducao}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                    handleChange('country', !e.target.checked && []);
                  }}
                />

                <CheckboxHtml
                  name="incentivo"
                  label="Incentivo?"
                  value={formData?.incentivo}
                  checked={formData?.incentivo}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <CheckboxHtml
                  name="producao_estudantil"
                  label="É uma produção estudantil?"
                  value={formData?.producao_estudantil}
                  checked={formData?.producao_estudantil}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <CheckboxHtml
                  name="prioridade"
                  label="Prioridade?"
                  value={formData?.prioridade}
                  checked={formData?.prioridade}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <Select
                  name="work_status"
                  label="Status"
                  isMulti
                  value={formData?.work_status}
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={workStatusList}
                  onChange={e => {
                    handleChange(
                      'work_status',
                      e?.map(({ value, label }) => ({ value, label })),
                    );
                  }}
                />

                <Select
                  name="work_type"
                  label="Formato da Obra Audiovisual"
                  isMulti
                  value={formData?.work_type}
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={workTypeList}
                  onChange={e => {
                    handleChange(
                      'work_type',
                      e?.map(({ value, label }) => ({ value, label })),
                    );
                  }}
                />

                <Select
                  name="ld_veiculacao_inicial"
                  label="Veiculação inicial prevista"
                  isMulti
                  value={formData?.ld_veiculacao_inicial}
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={veiculacaoInicialList}
                  onChange={e => {
                    handleChange(
                      'ld_veiculacao_inicial',
                      e?.map(({ value, label }) => ({ value, label })),
                    );

                    const listsVeicIni = [
                      {
                        label: 'TV Aberta',
                        key_name: 'ld_canais_abertos',
                      },
                      {
                        label: 'Plataforma de Streaming',
                        key_name: 'ld_streaming',
                      },
                      {
                        label: 'TV Paga',
                        key_name: 'ld_canais_filme',
                      },
                      {
                        label: 'TV Paga',
                        key_name: 'ld_canais_infantil',
                      },
                      {
                        label: 'TV Paga',
                        key_name: 'ld_canais_noticia',
                      },
                      {
                        label: 'TV Paga',
                        key_name: 'ld_canais_documentarios',
                      },
                      {
                        label: 'TV Paga',
                        key_name: 'ld_canais_variedade',
                      },
                      {
                        label: 'TV Paga',
                        key_name: 'ld_canais_esporte',
                      },
                      {
                        label: 'Outros',
                        key_name: 'outro_veiculacao',
                      },
                    ];

                    listsVeicIni.forEach((key, _) =>
                      setFormData(state => ({
                        ...state,
                        [key.key_name]: e?.some(
                          ({ label }) => label === key.label,
                        )
                          ? state[key.key_name]
                          : null,
                      })),
                    );
                  }}
                />

                {renderVeiculacaoInicialInputs()}

                <Select
                  name="state"
                  label="Estado de origem"
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={estadosList}
                  value={formData?.state}
                  onChange={e => {
                    handleChange('state', e);
                  }}
                />

                {formData?.coproducao && (
                  <Select
                    name="country"
                    label="País de origem"
                    placeholder="Selecione"
                    style={selectCustomStyles}
                    options={paisesList}
                    value={formData?.country}
                    onChange={e => {
                      handleChange('country', e);
                    }}
                  />
                )}

                <Input
                  type="text"
                  name="director"
                  label="Diretor"
                  value={formData?.director}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <Input
                  type="email"
                  name="spfilm_contact"
                  label="Contato junto a SPFilm"
                  value={formData?.spfilm_contact}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <Input
                  type="email"
                  name="production_coordination"
                  label="Coordenação de Produção"
                  value={formData?.production_coordination}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <Input
                  type="email"
                  name="production_director"
                  label="Direção de Produção"
                  value={formData?.production_director}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <Input
                  type="email"
                  name="production_executive"
                  label="Produção Executiva"
                  value={formData?.production_executive}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
              </>
            )}
          </GridContainer>
        </Wrap>

        <Wrap style={{ justifyContent: 'flex-end' }}>
          <SplitButton
            buttons={[
              {
                label: 'Gerar',
                onClick: handleClickGerar,
              },
              {
                label: 'Exportar Excel...',
                onClick: handleClickExportarExcel,
              },
            ]}
            isLoading={loading}
            disableAllButtons={loading}
          />

          <Button
            type="button"
            btType="#828282"
            onClick={handleResetForm}
            label="Limpar campos"
            full
            loading={loading}
            disabled={loading}
            width="150px"
            style={{ marginLeft: 5 }}
          />
        </Wrap>
        <Line />
      </>
    );
  };

  const renderQuantitativo = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData).length <= 0 ||
      responseData?.statistics?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    return (
      <>
        <GridContainer cols={5} style={{ marginTop: 15 }}>
          {responseData.statistics?.map(({ label, value }) => (
            <Text label={label} value={value} />
          ))}
        </GridContainer>
        <Line />
      </>
    );
  };

  const renderListagemBaseNova = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData).length <= 0 ||
      responseData?.works?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    const columnsNames = ['Número do Protocolo', 'Nome da Obra', 'Produtor(a)'];
    const tableData = responseData.works.map(data => ({
      protocol: data?.protocol,
      titulo_obra: data?.titulo_obra,
      nome_produtor: data?.branch
        ? data?.branch?.company_name
        : data?.user?.user_personal_data?.name,
    }));

    return (
      <VirtualTable
        title="Impressão da listagem de Obras"
        height={500}
        width="100%"
        itemCount={tableData?.length}
        itemSize={40}
        tableData={tableData}
        showPrinter
        columnsNames={columnsNames}
        header={
          <thead>
            <tr>
              {columnsNames.map(columnName => (
                <th>{columnName}</th>
              ))}
            </tr>
          </thead>
        }
        row={({ index, style }) => (
          <tr>
            <td>{tableData[index].protocol}</td>
            <td>{tableData[index].titulo_obra}</td>
            <td>{tableData[index].nome_produtor}</td>
          </tr>
        )}
        footer={
          <tfoot>
            <tr>
              <td colSpan={3}>Fim de listagem</td>
            </tr>
          </tfoot>
        }
      />
    );
  };

  const renderListagemBaseAntiga = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData).length <= 0 ||
      responseData?.legacy?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    return (
      <VirtualTable
        height={500}
        width="100%"
        itemCount={responseData?.legacy?.length}
        itemSize={40}
        tableData={responseData?.legacy}
        showPrinter
        header={
          <thead>
            <tr>
              <th>Número do Protocolo</th>
              <th>Nome da Obra</th>
              <th>Produtor(a)</th>
            </tr>
          </thead>
        }
        row={({ index, style }) => (
          <tr>
            <td>{responseData?.legacy[index].cod_obra}</td>
            <td>{responseData?.legacy[index].titulo_obra}</td>
            <td>{responseData?.legacy[index].prod_nome}</td>
          </tr>
        )}
        footer={
          <tfoot>
            <tr>
              <td colSpan={3}>Fim de listagem</td>
            </tr>
          </tfoot>
        }
      />
    );
  };

  return (
    <Form ref={formRef}>
      <Wrap>
        <AccordionItem opened label="Filtros">
          {renderFiltros()}
        </AccordionItem>
      </Wrap>

      <Wrap>
        <AccordionItem label="Quantitativos">
          {renderQuantitativo()}
        </AccordionItem>
      </Wrap>

      <Wrap>
        <AccordionItem label="Listagem (Base nova)">
          {renderListagemBaseNova()}
        </AccordionItem>
      </Wrap>

      <Wrap>
        <AccordionItem label="Listagem (Base antiga)">
          {renderListagemBaseAntiga()}
        </AccordionItem>
      </Wrap>
    </Form>
  );
};
