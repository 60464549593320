/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AiFillExclamationCircle } from 'react-icons/ai';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import Modal from 'react-modal';
import Button from '~/components/Button';
import BuscaCEP from '~/components/Forms/BuscaCEP';
import BuscaDocumento from '~/components/Forms/BuscaDocumento';

import userMeAction from '../../../store/ducks/userMe';

import {
  ButtonBox,
  Wrap,
  ModalContent,
  ModalButton,
  ChoiceButtons,
  ActionButton,
  overlayStyle,
  contentStyle,
} from './styles';

import {
  PageContainer,
  ContainerInputFile,
  Arquivo,
} from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  BoxHeader,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';
import { showModalMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import { Input } from '~/components/Forms';
import { RadioNoForm } from '~/components/Forms/RadioNoForm';
import clientResources from '~/data/clientResources';

const PessoaJuridica = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const dadoState = location.state;
  const [loading, setLoading] = useState(false);
  const [formDados, setFormDados] = useState({});
  const [alvaraFile, setAlvaraFile] = useState(undefined);
  const [dataAlvaraFile, setdataAlvaraFile] = useState(undefined);
  const [file1, setFile1] = useState(undefined);
  const [file2, setFile2] = useState(undefined);
  const [dataFile1, setDataFile1] = useState(undefined);
  const [dataFile2, setDataFile2] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [pjUuid, setPjUuid] = useState(undefined);
  const [enderecoNacional, setEnderecoNacional] = useState(true);

  const formRef = useRef();
  const alvaraFileRef = useRef(null);
  const file1Ref = useRef(null);
  const file2Ref = useRef(null);

  const { data: userData } = useSelector(state => state.userMe);

  function handleCloseModal() {
    setOpen(false);
    // window.location.reload();
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormDados(state => ({ ...state, [name]: value }));
  }

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;
    const file = event.target.files[0];

    if (!file) return;

    if (file.type !== 'application/pdf') {
      await showModalMessage({
        type: 'error',
        text: 'Somente arquivos PDF estão permitidos para gravação!',
      });
      return;
    }

    const base64 = await convertBase64(file);

    if (name === 'fileAlvara') {
      setAlvaraFile(base64.split('base64,'));
      setdataAlvaraFile(file);
    }

    if (name === 'file1') {
      setFile1(base64.split('base64,'));
      setDataFile1(file);
    }

    if (name === 'file2') {
      setFile2(base64.split('base64,'));
      setDataFile2(file);
    }
  }

  function handleAlvaraFile() {
    return alvaraFileRef.current.click();
  }

  function handleFile1() {
    return file1Ref.current.click();
  }

  function handleFile2() {
    return file2Ref.current.click();
  }

  async function handleValidationFields(data) {
    const defaultMsg = 'Obrigatório';

    try {
      const schema = Yup.object().shape({
        cnpj_pj: Yup.string().typeError(defaultMsg).required(defaultMsg),
        fantasy_name_pj: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        phone_pj: Yup.string().typeError(defaultMsg).required(defaultMsg),
        rate_social_pj: Yup.string().typeError(defaultMsg).required(defaultMsg),
        cep: Yup.string()
          .typeError(defaultMsg)
          .test(
            'endereco-nacional',
            defaultMsg,
            value =>
              !(
                (!value || value.length <= 0) &&
                pjUuid === undefined &&
                enderecoNacional
              ),
          ),
        number: Yup.string()
          .typeError(defaultMsg)
          .test(
            'endereco-nacional',
            defaultMsg,
            value =>
              !(
                (!value || value.length <= 0) &&
                pjUuid === undefined &&
                enderecoNacional
              ),
          ),
        endereco_internacional: Yup.string()
          .typeError(defaultMsg)
          .test(
            'endereco-internacional',
            defaultMsg,
            value =>
              !(
                (!value || value.length <= 0) &&
                pjUuid === undefined &&
                !enderecoNacional
              ),
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }
  }

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      if (pjUuid) {
        await showModalMessage({
          type: 'error',
          text: 'Pessoa Jurídica já existe!',
        });
        return;
      }

      if (!(await handleValidationFields(data))) {
        return;
      }

      setLoading(true);

      try {
        formRef.current.setErrors({});

        const fileAlvaraName = alvaraFile[1];
        const file1Name = file1[1];
        const file2Name = file2[1];

        formDados.alvara_base64 = fileAlvaraName;
        formDados.doc_responsavel_base64 = file1Name;
        formDados.cnpj_base64 = file2Name;

        formDados.cep = data.cep?.replace(/[^0-9]/g, '');
        formDados.phone = data.phone_pj?.replace(/[^0-9]/g, '');
        formDados.cnpj = data.cnpj_pj?.replace(/[^0-9]/g, '');

        const addressData = enderecoNacional
          ? {
              address: {
                neighborhood: data?.neighborhood,
                city: data?.city,
                uf: data?.state,
              },
              cep: formDados?.cep,
              street: data?.logradouro,
              number: formDados?.number,
              complement: data?.complement,
            }
          : {
              international_address: formDados?.endereco_internacional,
            };

        const dataJuridico = {
          cnpj: formDados?.cnpj,
          fantasy_name: data?.fantasy_name_pj,
          company_name: data?.rate_social_pj,
          phone: formDados?.phone,
          alvara_base64: formDados?.alvara_base64,
          doc_responsavel_base64: formDados?.doc_responsavel_base64,
          cnpj_base64: formDados?.cnpj_base64,
          ...addressData,
        };

        // criação de user pessoa juridica
        try {
          await ApiClient.doRequest({
            uri: 'user/set/juridica',
            method: ApiClient.POST,
            body: dataJuridico,
            expectedStatus: 201,
          });
        } catch (error) {
          setLoading(false);
          await showModalMessage({
            type: 'error',
            title: 'Pessoa Jurídica',
            text: 'Erro ao tentar gravar',
          });
          return;
        }

        await showModalMessage({
          type: 'success',
          title: 'Pessoa Jurídica',
          text: 'Cadastro feito com sucesso',
        });

        setLoading(false);
        setFile1(undefined);
        setFile2(undefined);
        setAlvaraFile(undefined);
        reset();

        if (dadoState?.trigger === 'catalogo') {
          history.replace('/catalogo/cadastro');
        } else {
          setOpen(true);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        await showModalMessage({
          type: 'error',
          title: 'Pessoa Jurídica',
          text: 'Erro ao efetuar cadastro',
        });
        const validationErrors = {};
      }
    },
    [alvaraFile, formDados, file1, file2],
  );

  // userMe
  const user = useCallback(() => {
    dispatch(userMeAction.showRequest());
  }, [dispatch]);

  useEffect(() => {
    user();
  }, [user]);

  function handlePjExists(value) {
    setPjUuid(value);
  }

  return (
    <>
      <Header>
        <LogoIcon disabled />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Cadastro de Pessoa Jurídica</h1>
            <span>Pessoa Jurídica</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      <PageContainer>
        <PageContent>
          {formDados && (
            <Form ref={formRef} autoComplete="off" onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Dados da empresa:</span>
                  <span tooltip="Preencha todos os dados">
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <BuscaDocumento
                  cnpj
                  change={handleChange}
                  pjExistsFunc={handlePjExists}
                />
              </WhiteBox>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <RadioNoForm
                    name="radio_tipo_endereco"
                    yesLabelValue="Endereço Nacional"
                    noLabelValue="Endereço Internacional"
                    value={Number(enderecoNacional).toString()}
                    onChange={event => {
                      setEnderecoNacional(Boolean(Number(event.target.value)));
                    }}
                  />
                  <span
                    tooltip="Neste campo devem ser preenchidas as informações da produtora que podem constar em
documentos futuros das obras vinculadas a ela, tais como autorizações, boletos, entre outros.
Portanto, caso a produtora tenha mais de um endereço e/ou CNPJ/Razão Social, deverá cadastrar
ambos ou o que será vinculado à solicitação em questão"
                  >
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                {enderecoNacional ? (
                  <BuscaCEP formRef={formRef} change={handleChange} />
                ) : (
                  <Input
                    name="endereco_internacional"
                    onChange={handleChange}
                    required
                  />
                )}
              </WhiteBox>

              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Documentos:</span>
                  <span tooltip="Neste campo devem ser inseridas as documentações atualizadas vinculadas à produtora cadastrada">
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <Wrap>
                  <ContainerInputFile style={{ marginTop: 0 }}>
                    <label htmlFor="fileAlvara">
                      Contrato ou Estatuto Social
                    </label>
                    <Arquivo>
                      <span>
                        {dataAlvaraFile
                          ? dataAlvaraFile?.name
                          : 'Nenhum arquivo selecionado'}
                      </span>
                      <input
                        className="arquivo"
                        type="file"
                        name="fileAlvara"
                        ref={alvaraFileRef}
                        defaultValue=""
                        onChange={handleFileChange}
                        required
                        accept="application/pdf"
                      />
                      <button type="button" onClick={handleAlvaraFile}>
                        Selecionar
                      </button>
                    </Arquivo>
                  </ContainerInputFile>

                  <ContainerInputFile style={{ marginTop: 0 }}>
                    <label htmlFor="file1">
                      CPF ou RG do(s) responsável(is) legal(is) pela produtora
                    </label>
                    <Arquivo>
                      <span>
                        {dataFile1
                          ? dataFile1?.name
                          : 'Nenhum arquivo selecionado'}
                      </span>
                      <input
                        className="arquivo"
                        type="file"
                        name="file1"
                        ref={file1Ref}
                        required
                        defaultValue=""
                        onChange={handleFileChange}
                        accept="application/pdf"
                      />
                      <button type="button" onClick={handleFile1}>
                        Selecionar
                      </button>
                    </Arquivo>
                  </ContainerInputFile>
                </Wrap>
                <Wrap>
                  <ContainerInputFile style={{ marginTop: 0 }}>
                    <label htmlFor="file2">Cartão CNPJ</label>
                    <Arquivo>
                      <span>
                        {dataFile2
                          ? dataFile2?.name
                          : 'Nenhum arquivo selecionado'}
                      </span>
                      <input
                        className="arquivo"
                        type="file"
                        name="file2"
                        ref={file2Ref}
                        required
                        defaultValue=""
                        onChange={handleFileChange}
                        accept="application/pdf"
                      />
                      <button type="button" onClick={handleFile2}>
                        Selecionar
                      </button>
                    </Arquivo>
                  </ContainerInputFile>
                </Wrap>
              </WhiteBox>
              <ButtonBox>
                <Button
                  btType="#4F4F4F"
                  label="Voltar"
                  full
                  width="150px"
                  onClick={history.back}
                />
                <Button
                  label="Concluir"
                  btType="#26A2B1"
                  type="submit"
                  full
                  width="157px"
                  loading={loading}
                />
              </ButtonBox>
            </Form>
          )}
        </PageContent>
      </PageContainer>
      <PageFooter>
      <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
      <Modal
        onRequestClose={handleCloseModal}
        isOpen={open}
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <ModalButton onClick={handleCloseModal}>X</ModalButton>
        <ModalContent>
          <h1>
            <span>Deseja cadastrar</span>
            <br />
            uma nova obra?
          </h1>
          <ChoiceButtons>
            <Link
              to={{
                pathname: '/home-user',
                state: {
                  name: userData?.data?.user_personal_data?.name,
                },
              }}
              style={{ width: ' 100%', maxWidth: 160 }}
            >
              <ActionButton href="/home-user">não</ActionButton>
            </Link>
            <ActionButton blue href="/solicitacao-nova-obra">
              sim
            </ActionButton>
          </ChoiceButtons>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PessoaJuridica;
