import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';

import { LimiterInputWidth, PageContainer } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, TitleForm, MainLoading } from './styles';

import WhiteBox from '~/components/WhiteBox';
import { Input } from '~/components/Forms';
import { showModalMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';

const ManutencaoVideosTutoriais = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  function handleChange(name, value) {
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleValidationFields() {
    try {
      formRef.current.setErrors({});

      const defaultMsg = 'Obrigatório';
      const schema = Yup.object().shape({
        title: Yup.string().typeError(defaultMsg).required(defaultMsg),
        url: Yup.string().typeError(defaultMsg).required(defaultMsg),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }
  }

  async function handleSubmit(data, { reset }) {
    if (!(await handleValidationFields())) {
      return;
    }

    try {
      setLoading(true);

      await ApiClient.doRequest({
        uri: `video`,
        method: ApiClient.POST,
        body: formData,
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        text: 'Vídeo tutorial gravado com sucesso!',
      });

      reset();
      setFormData({});
    } catch (error) {
      setLoading(false);

      const errorBody = JSON.parse(error.message);
      const errorMessage = errorBody.error.error.message.includes(
        'Duplicate entry',
      )
        ? 'URL já cadastrada!'
        : errorBody.error.error.message;

      await showModalMessage({
        type: 'error',
        text: `Erro: ${errorMessage}`,
      });
    }
  }

  return (
    <>
      {loading && (
        <MainLoading loading={loading}>
          <h3>Processando Informações</h3>
          <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
        </MainLoading>
      )}

      <PageContainer>
        <Aside />
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Wrap>
              <TitleForm>
                <h4>Vídeos Tutoriais</h4>
              </TitleForm>
            </Wrap>

            <WhiteBox column>
              <Wrap>
                <LimiterInputWidth w50>
                  <Input
                    type="text"
                    name="title"
                    label="Título do vídeo"
                    required
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                  />
                </LimiterInputWidth>
              </Wrap>

              <Wrap>
                <LimiterInputWidth w50>
                  <Input
                    type="url"
                    name="url"
                    label="URL do vídeo"
                    required
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                  />
                </LimiterInputWidth>
              </Wrap>

              <Button
                type="submit"
                btType="#26A2B1"
                label="Enviar"
                full
                width="140px"
                loading={loading}
              />
            </WhiteBox>
          </Form>
        </Container>
      </PageContainer>
    </>
  );
};

export default withRouter(ManutencaoVideosTutoriais);
