/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { Input, InputMask } from '~/components/Forms';
import { showModalMessage } from '~/services/notification';

import { Container, Wrap, LimiterInputWidth } from './styles';

export default function BuscaCEP({
  formRef,
  change,
  hideComplemento,
  defaultValues,
}) {
  const [dadosCep, setDadosCep] = useState(null);

  const clearFields = () => {
    formRef?.current?.setFieldValue('cep', '');
    formRef?.current?.setFieldValue('state', '');
    formRef?.current?.setFieldValue('city', '');
    formRef?.current?.setFieldValue('logradouro', '');
    formRef?.current?.setFieldValue('neighborhood', '');
    formRef?.current?.setFieldValue('uf', '');
    formRef?.current?.setFieldValue('number', '');
    formRef?.current?.setFieldValue('complement', '');
    setDadosCep({});
  };

  function handleChangeCep(evt) {
    const { value } = evt.target;

    if (!value) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${value}/json/`)
      .then(res => res.json())
      .then(async data => {
        if (data?.erro === 'true') {
          await showModalMessage({
            type: 'error',
            text: 'CEP inválido',
          });
          clearFields();
          return;
        }
        formRef?.current?.setFieldValue('neighborhood', data?.bairro);
        formRef?.current?.setFieldValue('logradouro', data?.logradouro);
        setDadosCep(data);
      })
      .catch(async error => {
        await showModalMessage({
          type: 'error',
          text: `Erro grave ao buscar CEP: ${error.message}`,
        });
        clearFields();
      });
  }

  return (
    <Container>
      <Wrap>
        <InputMask
          name="cep"
          label="CEP"
          mask="99999-999"
          maskChar=""
          onBlur={handleChangeCep}
          onChange={change}
          defaultValue={
            typeof defaultValues?.cep?.value === 'string'
              ? defaultValues?.cep?.value
              : ''
          }
          readOnly={defaultValues?.cep?.disabled}
          required
        />
        <Input
          name="state"
          label="Estado"
          onChange={change}
          value={
            // eslint-disable-next-line no-nested-ternary
            dadosCep === null
              ? typeof defaultValues?.state?.value === 'string'
                ? defaultValues?.state?.value
                : ''
              : dadosCep?.uf
          }
          readOnly
        />
        <Input
          name="city"
          label="Cidade"
          onChange={change}
          value={
            // eslint-disable-next-line no-nested-ternary
            dadosCep === null
              ? typeof defaultValues?.city?.value === 'string'
                ? defaultValues?.city?.value
                : ''
              : dadosCep?.localidade
          }
          readOnly
        />
        <Input
          name="neighborhood"
          label="Bairro"
          onChange={change}
          defaultValue={
            // eslint-disable-next-line no-nested-ternary
            dadosCep === null
              ? typeof defaultValues?.neighborhood?.value === 'string'
                ? defaultValues?.neighborhood?.value
                : ''
              : dadosCep?.bairro
          }
          readOnly={dadosCep?.bairro !== ''}
        />
      </Wrap>
      <Wrap>
        <LimiterInputWidth w25>
          <Input
            name="logradouro"
            label="Rua"
            onChange={change}
            defaultValue={
              // eslint-disable-next-line no-nested-ternary
              dadosCep === null
                ? typeof defaultValues?.logradouro?.value === 'string'
                  ? defaultValues?.logradouro?.value
                  : ''
                : dadosCep?.logradouro
            }
            readOnly={dadosCep?.logradouro !== ''}
          />
        </LimiterInputWidth>

        <LimiterInputWidth w25>
          <Input
            autoComplete="on"
            name="number"
            label="Número"
            onChange={change}
            value={defaultValues?.number?.value}
            readOnly={defaultValues?.number?.disabled}
            required
          />
        </LimiterInputWidth>

        {!hideComplemento && (
          <LimiterInputWidth w25>
            <Input
              name="complement"
              label="Complemento"
              onChange={change}
              defaultValue={defaultValues?.complement?.value}
              readOnly={defaultValues?.complement?.disabled}
            />
          </LimiterInputWidth>
        )}
      </Wrap>
    </Container>
  );
}
