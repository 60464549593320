import styled from 'styled-components';

export const MainLoading = styled.div`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  a {
    font-family: 'RobotoBold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
  }

  label {
    color: #000;
    font-size: 13px;
    font-family: 'InterRegular', sans-serif;
  }

  .calendario {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 0;

    .react-calendar__month-view__days {
      display: flex;
      flex-wrap: wrap;
      height: 350px;
    }

    .react-calendar__navigation__label__labelText {
      color: #000;
      text-transform: capitalize;
      font-size: 25px;
    }

    .react-calendar__month-view__days__day {
      border-radius: 50%;
    }

    .react-calendar__tile--active {
      background-color: #5fae37;
    }

    .react-calendar__tile--now {
      border: 1px solid #26a2b1;
      color: #fff;
      background: #26a2b1;
    }
    .react-calendar__month-view__weekdays {
      color: #000;
    }
  }

  form {
    h2 {
      font-family: 'InterBold', sans-serif;
      font-size: 30px;
      line-height: 34px;
      color: #4f4f4f;
      margin-bottom: 50px;
    }

    input,
    textarea {
      background-color: #f2f2f2;
      border-radius: 5px;
      border: 1px solid #e2e2e2;
    }

    label {
      font-family: 'InterBold', sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #828282;
    }

    textarea {
      resize: none;
    }
  }

  width: 100%;
`;

export const ModalButton = styled.button`
  display: flex;
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  color: #99a6b7;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: 40,
  borderRadius: 10,
  top: 0,
  left: 0,
  minHeight: 150,
  width: '90%',
  height: 'auto',
  maxWidth: 600,
  border: 'none',
};

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  button.eye {
    position: absolute;
    right: 55px;
    bottom: 145px;
    margin: 0;
    z-index: 1;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const WrapRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    span {
      color: #ff0000;
    }
  }
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 42px;

  button {
    font-family: 'InterSemiBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
  }
`;
