import React from 'react';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import { Container, ActionButton } from './styles';
import tenancy_env from '~/utils/MultiTenancyUtil';

export default function FormChoiceButton({
  label,
  createData,
  editData,
  viewData,
  nomeProjeto,
}) {
  return (
    <Container>
      {label && <label>{label}</label>}

      <div style={{ display: 'flex' }}>
        {createData && (
          <Link
            to={{
              pathname: 'redireciona-endpoint',
              search: `query=${encodeURIComponent(
                CryptoJS.AES.encrypt(
                  JSON.stringify(
                    Object.assign(createData, {
                      mode: 'create',
                      nome_projeto: nomeProjeto,
                    }),
                  ),
                  tenancy_env('REACT_APP_API_BASE_URL'),
                ).toString(),
              )}`,
            }}
            target={createData?.in_same_tab ? '_parent' : '_blank'}
            style={{
              textDecoration: 'none',
              width: '100%',
              maxWidth: '160px',
            }}
          >
            <ActionButton>Criar</ActionButton>
          </Link>
        )}

        {editData && (
          <Link
            to={{
              pathname: 'redireciona-endpoint',
              search: `query=${encodeURIComponent(
                CryptoJS.AES.encrypt(
                  JSON.stringify(
                    Object.assign(editData, {
                      mode: 'edit',
                      nome_projeto: nomeProjeto,
                    }),
                  ),
                  tenancy_env('REACT_APP_API_BASE_URL'),
                ).toString(),
              )}`,
            }}
            target={editData?.in_same_tab ? '_parent' : '_blank'}
            style={{
              textDecoration: 'none',
              width: '100%',
              maxWidth: '160px',
            }}
          >
            <ActionButton>Editar</ActionButton>
          </Link>
        )}

        {viewData && (
          <Link
            to={{
              pathname: 'redireciona-endpoint',
              search: `query=${encodeURIComponent(
                CryptoJS.AES.encrypt(
                  JSON.stringify(
                    Object.assign(viewData, {
                      mode: 'view',
                      nome_projeto: nomeProjeto,
                    }),
                  ),
                  tenancy_env('REACT_APP_API_BASE_URL'),
                ).toString(),
              )}`,
            }}
            target={viewData?.in_same_tab ? '_parent' : '_blank'}
            style={{
              textDecoration: 'none',
              width: '100%',
              maxWidth: '160px',
            }}
          >
            <ActionButton>Visualizar</ActionButton>
          </Link>
        )}
      </div>
    </Container>
  );
}
