import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineCheck,
  AiOutlineClose,
} from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import * as Yup from 'yup';
import { format } from 'date-fns';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';

import omitEmpty from 'omit-empty';
import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  ChoiceButtonLocacao,
  Textarea,
  File,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ConditionalContainer,
  LimiterInputWidth,
  MultiInputContainer,
  ActionInputMulti,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import ImpactoListActions from '~/store/ducks/impacto-list';
import EquipamentoFilmagemListActions from '~/store/ducks/equipamento-filmagem-list';
import EfeitoEspecialListActions from '~/store/ducks/efeito-especial-list';
import FinalidadeBaseApoioListActions from '~/store/ducks/finalidade-base-apoio-list';
import LdCameraCarListActions from '~/store/ducks/ld-tipo-camera-car';
import LdTipoComboioListActions from '~/store/ducks/ld-tipo-comboio';
import LdGruaOpcoesListActions from '~/store/ducks/ld-grua-opcoes';

import { Wrap, MainLoading } from './styles';
import Button from '~/components/Button';
import { showModalMessage } from '~/services/notification';
import Chat from '~/components/Chat/chat';
import diffDatasFilmagem from '~/utils/functions/diffDatasFilmagem';
import diffHoursFilmagem from '~/utils/functions/diffHoursFilmagem';
import LogoIcon from '~/components/LogoIcon';
import {
  confirmEditDateHour,
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const CameraCarEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();
  const conteudoProjecaoRef = useRef(null);
  const iluminacaoFileRef = useRef(null);

  // states
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseLocation, setResponseLocation] = useState();
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});

  // state choices
  const [drone, setDrone] = useState(null);
  const [efeitosEspeciais, setEfeitosEspeciais] = useState(null);
  const [projecao, setProjecao] = useState(null);
  const [projecaoLogo, setProjecaoLogo] = useState(null);
  const [desligarLuz, setDesligarLuz] = useState(null);
  const [publicoPrivado, setPublicoPrivado] = useState(null);
  const [alteracaoVelocidadeFluxo, setAlteracaoVelocidadeFluxo] =
    useState(null);
  const [veiculosComboio, setVeiculosComboio] = useState(null);
  const [estruturaCameraCar, setEstruturaCameraCar] = useState(null);
  const [reservasVagas, setReservasVagas] = useState(null);

  // state files
  const [iluminacaoFile, setIluminacaoFile] = useState(null);
  const [dataIluminacaoFile, setDataIluminacaoFile] = useState(null);
  const [conteudoProjecaoFile, setConteudoProjecaoFile] = useState(null);
  const [dataConteudoProjecaoFile, setDataConteudoProjecaoFile] =
    useState(null);

  // state outro fields
  const [showOutroTipoCameraField, setShowOutroTipoCameraField] =
    useState(false);
  const [showDescrAltoImpactoLocalField, setShowDescrAltoImpactoLocalField] =
    useState(false);
  const [
    showDescrEquipamentoFilmagemField,
    setShowDescrEquipamentoFilmagemField,
  ] = useState(false);
  const [showSelectOpcaoGruaField, setShowSelectOpcaoGruaField] =
    useState(false);
  const [showOutroEquipamentoField, setShowOutroEquipamentoField] =
    useState(false);

  // states multi input
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // selectors
  const { data: impactoSelectList } = useSelector(state => state.impactoList);
  const { data: equipamentosSelectList } = useSelector(
    state => state.equipamentoFilmagemList,
  );
  const { data: efeitoEspecialSelectList } = useSelector(
    state => state.efeitoEspecialList,
  );
  const { data: ldCameraCarList } = useSelector(state => state.ldCameraCarList);
  const { data: ldTipoComboioList } = useSelector(state => state.ldTipoComboio);
  const { data: ldGruaOpcoesList } = useSelector(
    state => state.ldGruaOpcoesList,
  );

  useEffect(() => {
    dispatch(ImpactoListActions.showRequest());
    dispatch(EquipamentoFilmagemListActions.showRequest());
    dispatch(EfeitoEspecialListActions.showRequest());
    dispatch(FinalidadeBaseApoioListActions.showRequest());
    dispatch(LdCameraCarListActions.showRequest());
    dispatch(LdTipoComboioListActions.showRequest());
    dispatch(LdGruaOpcoesListActions.showRequest());
  }, [dispatch]);

  // Busca dados da locação
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-camera-car/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set temp variables
      const projecaoLogoTmp =
        typeof respLoc?.locacao_default.porcentagem_projecao === 'number' ||
        typeof respLoc?.locacao_default.area_tempo_projeção === 'string';

      const publicoPrivadoTmp =
        respLoc?.locacao_default?.imovel_publico_privado === null
          ? null
          : respLoc?.locacao_default?.imovel_publico_privado === 1;

      // set campos 'multi'
      setDatasFilmagemInputs(
        respLoc?.locacao_default?.dates_locacao_default?.map(
          ({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      setHorasFilmagemInputs(
        respLoc?.locacao_default?.hours_locacao_default?.map(
          ({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      // set choices buttons
      setEstruturaCameraCar(typeof respLoc?.faixas_ocupadas === 'number');
      setVeiculosComboio(respLoc?.comboio_camera_car?.length > 0);
      setAlteracaoVelocidadeFluxo(
        typeof respLoc?.descr_outra_alteracao_velocidade === 'string',
      );
      setDrone(respLoc?.locacao_default.drone === 1);
      setEfeitosEspeciais(
        Object.values(respLoc?.locacao_default.ld_efeitos_especiais || {})
          .length > 0,
      );
      setProjecao(
        typeof respLoc?.locacao_default?.estrutura_projecao === 'string' ||
          typeof respLoc?.locacao_default?.instalacao_estrutura_projecao ===
            'string' ||
          publicoPrivadoTmp ||
          projecaoLogoTmp ||
          typeof respLoc?.locacao_default.files?.find(
            ifile => ifile.name === 'Arquivo Projeção',
          ) === 'object',
      );
      setPublicoPrivado(publicoPrivadoTmp);
      setProjecaoLogo(projecaoLogoTmp);
      setDesligarLuz(
        typeof respLoc?.locacao_default.files?.find(
          ifile => ifile.name === 'Arquivo Iluminação',
        ) === 'object',
      );

      setReservasVagas(
        Object.values(respLoc?.locacao_via_externa || []).length > 0,
      );

      // set show fields
      setShowDescrAltoImpactoLocalField(
        typeof respLoc?.locacao_default?.descr_alto_impacto_local === 'string',
      );
      setShowOutroTipoCameraField(
        typeof respLoc?.outro_tipo_camera === 'string',
      );
      setShowDescrEquipamentoFilmagemField(
        typeof respLoc?.locacao_default?.descricoes_equipamento_filmagem ===
          'string',
      );
      setShowOutroEquipamentoField(
        respLoc?.locacao_default?.ld_equipamento_filmagem.find(
          equip => equip.name === 'Outro',
        ),
      );

      setShowSelectOpcaoGruaField(respLoc?.grua_camera_car?.length > 0);

      // set select default values
      formRef.current?.setFieldValue('ld_impacto_uuid', {
        label: respLoc?.locacao_default?.ld_impacto?.name,
        value: respLoc?.locacao_default?.ld_impacto?.uuid,
      });

      formRef.current?.setFieldValue('ld_tipo_camera_car_uuid', {
        label: respLoc?.ld_tipo_camera_car?.name,
        value: respLoc?.ld_tipo_camera_car?.uuid,
      });

      formRef.current?.setFieldValue(
        'grua_camera_car',
        respLoc?.grua_camera_car?.map(igcc => {
          return {
            label: igcc.name,
            value: igcc.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'comboio_camera_car',
        respLoc?.comboio_camera_car?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'ld_equipamento_filmagem',
        respLoc?.locacao_default?.ld_equipamento_filmagem?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_efeitos_especiais_uuid', {
        label: respLoc?.locacao_default?.ld_efeitos_especiais?.name,
        value: respLoc?.locacao_default?.ld_efeitos_especiais?.uuid,
      });

      setResponseLocation(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState]);

  useEffect(() => {
    visualizacaoDadosCB();
  }, [visualizacaoDadosCB]);

  // handles true/false
  function handleEfeitosEspeciaisTrue(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(true);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleEfeitosEspeciaisFalse(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(false);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleDroneTrue(e) {
    e.preventDefault();
    setDrone(true);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleDroneFalse(e) {
    e.preventDefault();
    setDrone(false);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleProjecaoTrue(e) {
    e.preventDefault();
    setProjecao(true);
    setProjecaoLogo(null);
    setPublicoPrivado(null);
    setErrorsForm(state => ({
      ...state,
      projecao: false,
    }));
  }

  function handleProjecaoFalse(e) {
    e.preventDefault();
    setProjecao(false);
    setProjecaoLogo(null);
    setPublicoPrivado(null);
    setErrorsForm(state => ({
      ...state,
      projecao: false,
    }));
  }

  function handlePublicoPrivadoTrue(e) {
    e.preventDefault();
    setPublicoPrivado(true);
    setErrorsForm(state => ({
      ...state,
      publicoPrivado: false,
    }));
  }

  function handlePublicoPrivadoFalse(e) {
    e.preventDefault();
    setPublicoPrivado(false);
    setErrorsForm(state => ({
      ...state,
      publicoPrivado: false,
    }));
  }

  function handleDesligarLuzTrue(e) {
    e.preventDefault(e);
    setDesligarLuz(true);
    setErrorsForm(state => ({
      ...state,
      desligarLuz: false,
    }));
  }

  function handleDesligarLuzFalse(e) {
    e.preventDefault(e);
    setDesligarLuz(false);
    setErrorsForm(state => ({
      ...state,
      desligarLuz: false,
    }));
  }

  function handleProjecaoLogoTrue(e) {
    e.preventDefault();
    setProjecaoLogo(true);
    setErrorsForm(state => ({
      ...state,
      projecaoLogo: false,
    }));
  }

  function handleProjecaoLogoFalse(e) {
    e.preventDefault();
    setProjecaoLogo(false);
    setErrorsForm(state => ({
      ...state,
      projecaoLogo: false,
    }));
  }

  function handleChooseVelocidadeFluxoTrue(e) {
    e.preventDefault();
    setAlteracaoVelocidadeFluxo(true);
    setErrorsForm(state => ({
      ...state,
      alteracaoVelocidadeFluxo: false,
    }));
  }

  function handleChooseVelocidadeFluxoFalse(e) {
    e.preventDefault();
    setAlteracaoVelocidadeFluxo(false);
    setErrorsForm(state => ({
      ...state,
      alteracaoVelocidadeFluxo: false,
    }));
  }

  function handleComboioVeiculosTrue(e) {
    e.preventDefault();
    setVeiculosComboio(true);
    setErrorsForm(state => ({
      ...state,
      veiculosComboio: false,
    }));
  }

  function handleComboioVeiculosFalse(e) {
    e.preventDefault();
    setVeiculosComboio(false);
    setErrorsForm(state => ({
      ...state,
      veiculosComboio: false,
    }));
  }

  function handleEstruturaCameraCarTrue(e) {
    e.preventDefault();
    setEstruturaCameraCar(true);
    setErrorsForm(state => ({
      ...state,
      estruturaCameraCar: false,
    }));
  }

  function handleEstruturaCameraCarFalse(e) {
    e.preventDefault();
    setEstruturaCameraCar(false);
    setErrorsForm(state => ({
      ...state,
      estruturaCameraCar: false,
    }));
  }

  function handleReservasVagasTrue(e) {
    e.preventDefault(e);
    setReservasVagas(true);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleReservasVagasFalse(e) {
    e.preventDefault(e);
    setReservasVagas(false);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  // handles files
  function handleConteudoProjecaoFile() {
    return conteudoProjecaoRef.current.click();
  }

  function handleIluminacaoFile() {
    return iluminacaoFileRef.current.click();
  }

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };
  // Verification files
  let fileProjecao = [];
  let fileLuz = [];

  // Filter files
  fileProjecao = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Arquivo Projeção',
  );
  fileLuz = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Arquivo Iluminação',
  );

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;

    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    if (name === 'conteudo_projecao_file') {
      setConteudoProjecaoFile(base64.split('base64,'));
      setDataConteudoProjecaoFile(file);
      setErrorsForm(state => ({
        ...state,
        conteudoProjecaoFile: false,
      }));
    }

    if (name === 'iluminacao_file') {
      setIluminacaoFile(base64.split('base64,'));
      setDataIluminacaoFile(file);
      setErrorsForm(state => ({
        ...state,
        iluminacaoFile: false,
      }));
    }

    setFormData(state => ({ ...state, [name]: base64.split('base64,')[1] }));
  }

  // handles multi select
  function handleMultiSelectEquipamentos(e) {
    setShowDescrEquipamentoFilmagemField(
      e?.some(equip => equip.label?.includes(':')) || false,
    );
    setShowOutroEquipamentoField(!!e?.find(x => x.label === 'Outro'));
    setFormData(state => ({
      ...state,
      ld_equipamento_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectTipoGrua(e) {
    setFormData(state => ({
      ...state,
      grua_camera_car: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChangeTipoComboio(e) {
    setFormData(state => ({
      ...state,
      comboio_camera_car: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleSelectChangeTipoCameraCar(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'ld_tipo_camera_car_uuid') {
      setShowOutroTipoCameraField(option.label === 'Outro');
    }
  }

  function formatDefaultData(data) {
    // files uuids
    const fileProjecaoUuid =
      fileProjecao?.length > 0 ? fileProjecao[0].uuid : null;
    const fileLuzUuid = fileLuz?.length > 0 ? fileLuz[0].uuid : null;

    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      ld_impacto_uuid: data?.ld_impacto_uuid,
      ld_equipamento_filmagem: data?.ld_equipamento_filmagem,
      tamanho_equipe_tecnica: data?.tamanho_equipe_tecnica,
      tamanho_do_elenco: data?.tamanho_do_elenco,
      descricao_cena: data?.descricao_cena,
      especificidades: data?.especificidades,
    });

    // dados de choices
    const dataDrone = { drone };

    const dataEfeitosEspeciais = {
      ld_efeitos_especiais_uuid: efeitosEspeciais
        ? data?.ld_efeitos_especiais_uuid
        : null,
    };

    const dataProjecao = projecao
      ? {
          estrutura_projecao: data?.estrutura_projecao,
          instalacao_estrutura_projecao: data?.instalacao_estrutura_projecao,
          imovel_publico_privado: publicoPrivado,
          projecao_base64: data?.conteudo_projecao_file
            ? data?.conteudo_projecao_file
            : null,
          projecao_base64_uuid: data?.conteudo_projecao_file
            ? fileProjecaoUuid
            : null,
        }
      : {
          estrutura_projecao: null,
          instalacao_estrutura_projecao: null,
          imovel_publico_privado: null,
          projecao_base64: null,
          projecao_base64_uuid: fileProjecaoUuid,
        };

    const dataProjecaoLogo =
      projecao && projecaoLogo
        ? {
            porcentagem_projecao: +data?.porcentagem_projecao || undefined,
            area_tempo_projeção: data?.area_tempo_projeção,
          }
        : { porcentagem_projecao: null, area_tempo_projeção: null };

    const dataIluminacao = desligarLuz
      ? {
          iluminacao_base64: data?.iluminacao_file
            ? data?.iluminacao_file
            : null,
          iluminacao_base64_uuid: data?.iluminacao_file ? fileLuzUuid : null,
        }
      : { iluminacao_base64: null, iluminacao_base64_uuid: fileLuzUuid };

    // dados de campos exibidos condicionalmente
    const dataDescrAltoImpactoLocal = {
      descr_alto_impacto_local: showDescrAltoImpactoLocalField
        ? data?.descr_alto_impacto_local
        : null,
    };

    const dataDescrEquipamentoFilmagem = {
      descricoes_equipamento_filmagem: showDescrEquipamentoFilmagemField
        ? data?.descricoes_equipamento_filmagem
        : null,
    };

    const dataOutroEquipamento = {
      outro_equipamento: showOutroEquipamentoField
        ? data?.outro_equipamento
        : null,
    };

    // diff dates e hours
    const diffDatas = diffDatasFilmagem({
      datasFilmagemLocacao:
        responseLocation.locacao_default.dates_locacao_default.map(
          ({ uuid, date, changes_to_approve }) =>
            omitEmpty({
              uuid,
              data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
            }),
        ),
      datasFilmagemInput: datasFilmagemInputs?.map(
        ({ uuid, data_filmagem, changes_to_approve }) =>
          omitEmpty({
            uuid,
            data_filmagem,
            changes_to_approve,
          }),
      ),
      dateKeyName: 'data_filmagem',
    });

    const diffHours = diffHoursFilmagem({
      hoursFilmagemLocacao:
        responseLocation?.locacao_default?.hours_locacao_default?.map(
          ({ uuid, inicio, fim, changes_to_approve }) =>
            omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
            }),
        ),
      hoursFilmagemInput: horasFilmagemInputs?.map(
        ({ uuid, inicio, fim, changes_to_approve }) =>
          omitEmpty({
            uuid,
            inicio,
            fim,
            changes_to_approve,
          }),
      ),
      hourBeginKeyName: 'inicio',
      hourEndKeyName: 'fim',
    });

    return Object.assign(
      dataNaoCondicional,
      { dates: diffDatas },
      { hours: diffHours },
      dataDrone,
      dataEfeitosEspeciais,
      dataProjecao,
      dataProjecaoLogo,
      dataIluminacao,
      dataDescrAltoImpactoLocal,
      dataDescrEquipamentoFilmagem,
      dataOutroEquipamento,
    );
  }

  function formatEspecificaData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      endereco_filmagem: data?.endereco_filmagem,
      ld_tipo_camera_car_uuid: data?.ld_tipo_camera_car_uuid,
      quantidade_total_veiculos: data?.quantidade_total_veiculos,
    });

    // dados de choices
    const dataEstruturaCameraCar = {
      faixas_ocupadas: estruturaCameraCar ? data?.faixas_ocupadas : null,
    };

    const dataVeiculosComboio = {
      comboio_camera_car: veiculosComboio ? data?.comboio_camera_car : null,
    };

    const dataAlteracaoVelocidadeFluxo = {
      descr_outra_alteracao_velocidade: alteracaoVelocidadeFluxo
        ? data?.descr_outra_alteracao_velocidade
        : null,
    };

    // dados de campos exibidos condicionalmente
    const dataOutroTipoCameraField = {
      outro_tipo_camera: showOutroTipoCameraField
        ? data?.outro_tipo_camera
        : null,
    };

    const dataSelectOpcaoGruaField = {
      grua_camera_car: showSelectOpcaoGruaField ? data?.grua_camera_car : null,
    };

    return Object.assign(
      dataNaoCondicional,
      dataEstruturaCameraCar,
      dataVeiculosComboio,
      dataAlteracaoVelocidadeFluxo,
      dataSelectOpcaoGruaField,
      dataOutroTipoCameraField,
    );
  }

  async function handleValidationFields(data) {
    try {
      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      return false;
    }

    return true;
  }

  async function handleSubmit(data) {
    try {
      // files
      data.ld_equipamento_filmagem = formData?.ld_equipamento_filmagem;
      data.iluminacao_file = formData?.iluminacao_file;
      data.conteudo_projecao_file = formData?.conteudo_projecao_file;

      // selects
      data.grua_camera_car = formData?.grua_camera_car;
      data.comboio_camera_car = formData?.comboio_camera_car;

      if (!(await handleValidationFields(data))) {
        return;
      }

      const defaultData = formatDefaultData(data);
      const especificaData = formatEspecificaData(data);

      setLoading(true);

      if (isAdminRequest) {
        await ApiClient.doRequest({
          uri: `locacao-default/${responseLocation?.locacao_default?.uuid}`,
          method: ApiClient.PATCH,
          body: defaultData,
          expectedStatus: 201,
        });

        await ApiClient.doRequest({
          uri: `locacao-camera-car/${responseLocation?.uuid}`,
          method: ApiClient.PATCH,
          body: especificaData,
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title: 'Trajeto de Câmera Car',
          text: 'Alteração enviada com sucesso',
        });

        history.push('/locacoes');
      } else {
        if (!defaultData?.dates && !defaultData?.hours) {
          setLoading(false);
          await showModalMessage({
            type: 'info',
            title: 'Trajeto de Câmera Car',
            text: 'Não houve alteração em Data/Hora a ser salva',
          });
          return;
        }

        await ApiClient.doRequest({
          uri: `locacao-default/common-user/${responseLocation?.locacao_default?.uuid}`,
          method: ApiClient.PATCH,
          body: {
            dates: defaultData?.dates,
            hours: defaultData?.hours,
          },
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title: 'Trajeto de Câmera Car',
          text: 'As alterações foram enviadas para avaliação da Ilhacine',
        });

        history.push('/home-user');
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Trajeto de Câmera Car',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function renderLocacao() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Informações</span>
                </BoxHeader>
                <Wrap style={{ marginBottom: 30 }}>
                  <Textarea
                    name="form_info"
                    rows="4"
                    value="As autorizações para filmagens de trajeto(s) de Câmera Car são referentes somente a
                    utilização do espaço público municipal utilizado, cabendo à produção garantir que a circulação
                    do veículos esteja de acordo as regulamentações do Código de Trânsito Brasileiro e do DETRAN
                    (Departamento Estadual de Trânsito).&#13;&#13;Este formulário é somente para solicitações de trajeto(s) e passagem do veículo. Solicitações
                    de Camera Car que envolvam bloqueio(s) de via, ocupações de passeio (calçada) para
                    filmagem ou apoio, deverão ser solicitadas no formulário “Utilização de Via para Filmagens
                    ou Apoio."
                    disabled
                  />
                </Wrap>

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={!isAdminRequest}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_impacto_uuid"
                      label="Você classifica sua filmagem como sendo"
                      placeholder="Selecione"
                      required
                      questionTooltip="Clique aqui para saber mais"
                      questionOnClick={() => setOpenModalPorteFilmagem(true)}
                      options={impactoSelectList}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange('ld_impacto_uuid', value);
                        setShowDescrAltoImpactoLocalField(
                          value.label === 'alto impacto no local',
                        );
                      }}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={showDescrAltoImpactoLocalField}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descr_alto_impacto_local"
                      label="Comente qual o desafio da sua produção para auxiliarmos em sua viabilização"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descr_alto_impacto_local
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <Textarea
                    type="text"
                    questionTooltip="A produção deverá cadastrar TODAS as vias que o veículo irá percorrer"
                    rows="3"
                    required
                    name="endereco_filmagem"
                    label="Endereço da Filmagem"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData && responseLocation?.endereco_filmagem
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>
                <Wrap>
                  <DateFormMultiInput
                    label="Data da Filmagem"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    dateKeyName="data_filmagem"
                    tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                    isAdminRequest={isAdminRequest}
                    acceptPast
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Hora da Filmagem (Início - Fim)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="Incluir horário de montagem/desmontagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>
                <Wrap>
                  <Input
                    type="number"
                    name="tamanho_equipe_tecnica"
                    label="Tamanho da Equipe Técnica"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState.viewData &&
                      responseLocation?.locacao_default?.tamanho_equipe_tecnica
                    }
                    disabled={!isAdminRequest}
                  />
                  <Input
                    type="number"
                    name="tamanho_do_elenco"
                    label="Tamanho do elenco"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState.viewData &&
                      responseLocation?.locacao_default?.tamanho_do_elenco
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>
                <Wrap>
                  <Textarea
                    type="text"
                    name="descricao_cena"
                    label="Descrição da Cena"
                    onChange={handleChange}
                    rows="3"
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.descricao_cena
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_tipo_camera_car_uuid"
                      label="Tipo de Camera Car"
                      placeholder="Selecione"
                      required
                      options={ldCameraCarList}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChangeTipoCameraCar(
                          'ld_tipo_camera_car_uuid',
                          value,
                        );
                        setShowSelectOpcaoGruaField(value.label === 'Grua');
                      }}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showSelectOpcaoGruaField}>
                    <Select
                      name="grua_camera_car"
                      label="Tipo Grua"
                      isMulti
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      required
                      options={ldGruaOpcoesList}
                      onChange={handleMultiSelectTipoGrua}
                      isDisabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                  <ConditionalContainer show={showOutroTipoCameraField}>
                    <Input
                      type="text"
                      name="outro_tipo_camera"
                      label="Outro Tipo de Camera Car"
                      required
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.outro_tipo_camera
                      }
                      disabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá ocupação da estrutura do Camera Car em outra(s) faixa(s) lateral(is)?"
                      onClickTrue={handleEstruturaCameraCarTrue}
                      onClickFalse={handleEstruturaCameraCarFalse}
                      selected={estruturaCameraCar}
                      required
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm.estruturaCameraCar === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={estruturaCameraCar}>
                    <Input
                      type="number"
                      name="faixas_ocupadas"
                      label="Quantas faixas serão ocupadas?"
                      required
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.faixas_ocupadas
                      }
                      disabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá comboio de veículos de apoio para passagem do Camera Car?"
                      onClickTrue={handleComboioVeiculosTrue}
                      onClickFalse={handleComboioVeiculosFalse}
                      selected={veiculosComboio}
                      required
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm.veiculosComboio === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={veiculosComboio}>
                    <Select
                      name="comboio_camera_car"
                      label="Qual o tipo de comboio"
                      placeholder="Selecione"
                      required
                      isMulti
                      options={ldTipoComboioList}
                      style={selectCustomStyles}
                      onChange={handleSelectChangeTipoComboio}
                      isDisabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá outra alteração na velocidade/fluxo da via para passagem do Camera Car?"
                      onClickTrue={handleChooseVelocidadeFluxoTrue}
                      onClickFalse={handleChooseVelocidadeFluxoFalse}
                      selected={alteracaoVelocidadeFluxo}
                      required
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm.alteracaoVelocidadeFluxo === true &&
                        'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={alteracaoVelocidadeFluxo}>
                    <Input
                      type="text"
                      name="descr_outra_alteracao_velocidade"
                      label="Descreva"
                      required
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.descr_outra_alteracao_velocidade
                      }
                      disabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="number"
                      name="quantidade_total_veiculos"
                      label="Qual a quantidade total de veículos (apoio, cênico, etc)?"
                      required
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.quantidade_total_veiculos
                      }
                      disabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_equipamento_filmagem"
                      label="Qual a sua estrutura e equipamentos de filmagem para além do Camera Car:"
                      placeholder="Selecione"
                      options={equipamentosSelectList}
                      style={selectCustomStyles}
                      onChange={handleMultiSelectEquipamentos}
                      isMulti
                      required
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showOutroEquipamentoField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="outro_equipamento"
                        label="Outro Equipamento"
                        required
                        onChange={handleChange}
                        defaultValue={
                          responseLocation?.locacao_default?.outro_equipamento
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={showDescrEquipamentoFilmagemField}>
                  <Wrap>
                    <Textarea
                      label="Descreva a(s) extensão(ões)"
                      required
                      name="descricoes_equipamento_filmagem"
                      onChange={handleChange}
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descricoes_equipamento_filmagem
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Drone"
                      onClickTrue={handleDroneTrue}
                      onClickFalse={handleDroneFalse}
                      selected={drone}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.drone === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>
                {drone === true && (
                  <Wrap>
                    <Textarea
                      name="autorizacao_drones"
                      onChange={handleChange}
                      rows="4"
                      value="Para filmagens com drones: o Espaço Aéreo não é de administração municipal, mas sim da
                    Federação/União, portanto, não faz parte do campo de atuação da Ilhacine. A autorização para o uso
                    de drones deve ser solicitada diretamente à Agência Nacional de Aviação Civil (ANAC), mediante
                    cadastro no Sistema SARPAS, disponibilizado no site do Departamento de Controle do Espaço Aéreo
                    (DECEA)."
                      disabled
                    />
                  </Wrap>
                )}
                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Efeitos Especiais"
                      onClickTrue={handleEfeitosEspeciaisTrue}
                      onClickFalse={handleEfeitosEspeciaisFalse}
                      selected={efeitosEspeciais}
                      required
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm.efeitosEspeciais === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={efeitosEspeciais === true}>
                    <Select
                      name="ld_efeitos_especiais_uuid"
                      label="Efeitos Especiais"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      required
                      options={efeitoEspecialSelectList}
                      onChange={value => {
                        handleSelectChange('ld_efeitos_especiais_uuid', value);
                      }}
                      isDisabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá projeção visível a partir de logradouro público?"
                      onClickTrue={handleProjecaoTrue}
                      onClickFalse={handleProjecaoFalse}
                      selected={projecao}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.projecao === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={projecao}>
                  <Wrap>
                    <File
                      label="Conteúdo da Projeção:"
                      attach={dataConteudoProjecaoFile}
                      textFile="Nenhum arquivo selecionado"
                      name="conteudo_projecao_file"
                      inputRef={conteudoProjecaoRef}
                      onChange={handleFileChange}
                      required
                      onClick={handleConteudoProjecaoFile}
                      error={
                        errorsForm.conteudoProjecaoFile === true &&
                        'Obrigatório'
                      }
                      link={fileProjecao?.length > 0 && fileProjecao[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </Wrap>

                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Será projetado em imóvel público ou privado?"
                        onClickTrue={handlePublicoPrivadoTrue}
                        onClickFalse={handlePublicoPrivadoFalse}
                        selected={publicoPrivado}
                        required
                        isDisabled={!isAdminRequest}
                        error={
                          errorsForm.publicoPrivado === true && 'Obrigatório'
                        }
                        yesLabelValue="Público"
                        noLabelValue="Privado"
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <Wrap>
                    <Textarea
                      type="text"
                      name="estrutura_projecao"
                      label="Descreva a Estrutura para a Projeção"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.estrutura_projecao
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>

                  <Wrap>
                    <Textarea
                      type="text"
                      name="instalacao_estrutura_projecao"
                      label="Descreva local de instalação para estrutura de projeção"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.instalacao_estrutura_projecao
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>

                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Haverá exibição de logomarca na projeção? Se sim, qual porcentagem ocupará da área total da projeção e por quanto tempo será projetada?"
                        onClickTrue={handleProjecaoLogoTrue}
                        onClickFalse={handleProjecaoLogoFalse}
                        selected={projecaoLogo}
                        required
                        isDisabled={!isAdminRequest}
                        error={
                          errorsForm.projecaoLogo === true && 'Obrigatório'
                        }
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <ConditionalContainer show={projecaoLogo}>
                    <Wrap>
                      <Input
                        type="number"
                        step="0.01"
                        name="porcentagem_projecao"
                        label="Porcentagem que a projeção irá ocupar"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default
                            ?.porcentagem_projecao
                        }
                        disabled={!isAdminRequest}
                      />
                      <Input
                        type="time"
                        name="area_tempo_projeção"
                        label="Tempo de Projeção"
                        required
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.area_tempo_projeção
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá desligamento de iluminação no local?"
                      onClickTrue={handleDesligarLuzTrue}
                      onClickFalse={handleDesligarLuzFalse}
                      selected={desligarLuz}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.desligarLuz === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={desligarLuz}>
                  <Wrap>
                    <File
                      label="Arquivo do local:"
                      attach={dataIluminacaoFile}
                      textFile="Nenhum arquivo selecionado"
                      name="iluminacao_file"
                      inputRef={iluminacaoFileRef}
                      required
                      onChange={handleFileChange}
                      onClick={handleIluminacaoFile}
                      error={
                        errorsForm.iluminacaoFile === true && 'Obrigatório'
                      }
                      link={fileLuz?.length > 0 && fileLuz[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label=" Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.especificidades
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && reservasVagas ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="reservas_vagas_locacao"
                          label="Reservas de Vagas"
                          placeholder="Selecione"
                          options={responseLocation?.locacao_via_externa?.map(
                            ({
                              uuid,
                              address,
                              number,
                              neighborhood,
                              endpoint,
                            }) => ({
                              label: `${address}, ${number}, ${neighborhood}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('reservas_vagas_locacao', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {formData?.reservas_vagas_locacao && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            editData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.reservas_vagas_locacao?.uuid,
                              endpoint: 'reservas-vagas-vinculado',
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de reserva de vagas em via próxima?"
                      onClickTrue={handleReservasVagasTrue}
                      onClickFalse={handleReservasVagasFalse}
                      selected={reservasVagas}
                      required
                      isDisabled
                      error={errorsForm.reservasVagas === true && 'Obrigatório'}
                    />
                  )}
                </Wrap>
              </WhiteBox>

              <Chat
                uuid={responseLocation?.locacao_default?.uuid}
                chatKeyFilter="locacao_default"
                locacaoNameView={responseLocation?.name_view}
              />

              <Button
                type="submit"
                btType="#26A2B1"
                label="Enviar"
                full
                width="140px"
                loading={loading}
                style={{ position: 'relative', bottom: '35px' }}
              />
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Locação Câmera Car</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Câmera Car
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderLocacao()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
};

export default CameraCarEdit;
