// eslint-disable-next-line import/no-cycle
import ApiClient from "./ApiClient";
// eslint-disable-next-line import/no-cycle
import ApiToken from "./ApiToken";

class AuthService {

    constructor() {
        this.apiClient = new ApiClient('v1');
    }

    async doLogin(form) {
      // eslint-disable-next-line no-useless-catch
        try {

            const response = await this.apiClient.doRequest({
                method: ApiClient.POST,
                uri: 'login',
                auth: false,
                expectedStatus: 200,
                body: form
            });

            const token = response.data.access_token;
            ApiToken.saveToken(token);

            return response;

        } catch (e) {
            throw e
        }
    }

    async doRefreshToken({token}) {
      // eslint-disable-next-line no-useless-catch
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'login/refresh-token',
                token
            });

        } catch (e) {
            throw e;
        }
    }

    async me() {
      // eslint-disable-next-line no-useless-catch
        try {

            return await this.apiClient.doRequest({
                method: ApiClient.GET,
                uri: 'me',
                auth: true,
            });

        } catch (e) {
            throw e;
        }

    }

}

export default AuthService;
