/* eslint-disable no-underscore-dangle */
import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

/* Types & Action Creators */

const { Types, Creators } = createActions(
  {
    registerRequest: ["data", "history"],
    registerSuccess: ["data"],
    registerFailure: ["error"],
    reset: [],
  },
  {
    prefix: "CREATE_PASSWORD_",
  }
);

export const RegisterTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = {
  data: null,
  loadingRegister: false,
  error: null,
};

/* Reducers */
export const _registerRequest = (state) =>
  update(state, {
    loadingRegister: { $set: true },
    error: { $set: null },
  });

export const _registerSuccess = (state, action) =>
  update(state, {
    loadingRegister: { $set: false },
    data: { $set: action.data },
  });

export const _registerFailure = (state, action) =>
  update(state, {
    loadingRegister: { $set: false },
    error: { $set: action.error },
  });

export const _reset = () => INITIAL_STATE;
/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REGISTER_REQUEST]: _registerRequest,
  [Types.REGISTER_SUCCESS]: _registerSuccess,
  [Types.REGISTER_FAILURE]: _registerFailure,
  [Types.RESET]: _reset,
});
