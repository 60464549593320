import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChatContainer = styled.div`
  overflow-y: auto;
  height: 210px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f2f2f2;

  ${props =>
    (!props.hasMessages || props.loading) &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

export const ChatMessageInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  font-family: 'InterRegular', sans-serif;
  font-size: 14px;
  margin-top: 10px;

  & span {
    margin: 0;
    flex: 1;
  }

  & small {
    text-align: end;
    flex: 1;
  }
`;

export const ChatMessageBody = styled.div`
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  border: 1px solid #e2e2e2;
  padding: 10px;
  width: auto;
  min-width: 220px;
  max-width: 520px;
  margin-bottom: 2px;
  background-color: #828282;
  color: #fff;
  font-family: 'PoppinsSemiBold', sans-serif;

  & > span {
    word-break: break-all;
  }
`;

export const ChatMessage = styled.div`
  display: flex;
  justify-content: flex-start;

  ${props =>
    props.ownMessage &&
    css`
      justify-content: flex-end;

      ${ChatMessageBody} {
        background-color: #26a2b1;
        border-radius: 1.125rem 1.125rem 0 1.125rem;
      }
    `};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-end;

  & > * + * {
    margin-left: 10px;
  }
`;

export const InputMessage = styled.textarea`
  resize: auto !important;
  min-height: 50px;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  color: #828282;
  font-family: 'PoppinsSemiBold', sans-serif;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #e2e2e2;

  &::placeholder {
    opacity: 0.5;
  }
`;

export const ButtonMessage = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50px;
  max-height: 50px;
  padding: 10px;
  background-color: #26a2b1;
  color: #fff;

  border-radius: 50%;
  transition: 300ms ease;

  &:enabled:hover {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.7;
  }
`;

export const EmptyMessage = styled.span`
  padding: 10px;
  height: auto;
  width: fit-content;
  border-radius: 5px;
  color: #828282;
  font-family: 'PoppinsSemiBold', sans-serif;
  font-size: 14px;
`;
