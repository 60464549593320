import React from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { BoxHeader } from '~/pages/Usuario/styles';
import {
  ActionInputMulti,
  ContainerMultiInputActions,
  LimiterInputWidth,
  MultiInputContainer,
  Wrap,
} from '~/styles/components';
import InputNoForm from '../InputNoForm';

export const TextFormMultiInput = ({
  tooltip,
  label = 'Textos',
  textKeyName = 'text',
  texts,
  setTexts,
  isViewMode = false,
  isRequired = false,
}) => {
  // constante criada por não estar renderizando
  // os dados corretamente na tela ao remover
  const thisTextKeyName = textKeyName;

  const handleAddField = _ => {
    setTexts(prevState => [...prevState, { [thisTextKeyName]: '' }]);
  };

  const handleChangeField = (index, event) => {
    const values = [...texts];
    values[index][event.target.name] = event.target.value;
    setTexts(values);
  };

  const handleRemoveField = index => {
    setTexts(texts.filter((_, idx) => idx !== index));
  };

  return (
    <MultiInputContainer>
      <BoxHeader style={{ justifyContent: 'normal' }}>
        <span className="boxText" style={{ color: '#828282' }}>
          {label}
        </span>
        {isRequired && (
          <span className="boxText" style={{ color: 'red' }}>
            *
          </span>
        )}
        {tooltip && (
          <button type="button" tooltip={tooltip} style={{ marginLeft: 5 }}>
            <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
          </button>
        )}
      </BoxHeader>

      {texts.map((inputField, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Wrap key={`${inputField}~${index}`}>
          <LimiterInputWidth w100>
            <InputNoForm
              type="text"
              name={thisTextKeyName}
              value={inputField[thisTextKeyName]}
              required={isRequired}
              onChange={event => handleChangeField(index, event)}
              disabled={isViewMode}
            />
          </LimiterInputWidth>

          <ContainerMultiInputActions>
            {!isViewMode && (
              <>
                {texts.length !== 1 && (
                  <ActionInputMulti
                    type="button"
                    onClick={() => handleRemoveField(index)}
                  >
                    <AiOutlineMinus color="#AAA" size={24} />
                  </ActionInputMulti>
                )}

                {texts.length - 1 === index && (
                  <ActionInputMulti type="button" onClick={handleAddField}>
                    <AiOutlinePlus color="#AAA" size={24} />
                  </ActionInputMulti>
                )}
              </>
            )}
          </ContainerMultiInputActions>
        </Wrap>
      ))}
    </MultiInputContainer>
  );
};
