import React from 'react';
import Carousel from 'react-elastic-carousel';
import { Documento } from '~/components/Usuario/index';
import clientResources from '~/data/clientResources';

import { BoxHeader } from '~/pages/Usuario/styles';

import { Wrap } from './styles';

function LocacaoDocs({ excludes }) {
  const documentos = clientResources.documents.system.locacaoDocs;

  if (documentos.every(pdf => excludes?.includes(pdf.keyword))) {
    return <></>;
  }

  return (
    <>
      <BoxHeader>
        <span className="boxText">Preço Público</span>
      </BoxHeader>
      <Wrap>
        <Carousel style={{ widht: '100%' }} showArrows={false} itemsToShow={3}>
          {documentos.map(
            pdf =>
              !excludes?.includes(pdf.keyword) && (
                <Documento key={pdf.id} data={pdf} />
              ),
          )}
        </Carousel>
      </Wrap>
    </>
  );
}

export default LocacaoDocs;
