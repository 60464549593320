import { put } from 'redux-saga/effects';

import { childToParentSuccess } from './actions';

export function* fetch(action) {
  try {
    const { payload: data } = action;

    yield put(childToParentSuccess(data));
  } catch (err) {
    console.log('FAIL');
  }
}
