import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { Form } from '@unform/web';
import { ModalContent, ModalButton, Wrap, contentStyle, overlayStyle } from './styles';
import { Input, InputMask } from '~/components/Forms';
import Button from '~/components/Button';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage } from '~/services/notification';

export const selectCustomStyles = {
    option: provided => ({
        ...provided,
        borderBottom: `1px #ddd solid`,
        color: '#828282',
        backgroundColor: '#f2f2f2',
        padding: 16,
        fontFamily: 'PoppinsSemiBold',
        fontSize: 14,
        boxSizing: 'border-box',
        outline: 'none',
    }),
    input: provided => ({
        ...provided,
        padding: 16,
        fontSize: 14,
        margin: 0,
    }),
    container: provided => ({
        ...provided,
        padding: 0,
        marginBottom: 15,
    }),
    valueContainer: provided => ({
        ...provided,
        padding: 0,
    }),
    placeholder: provided => ({
        ...provided,
        marginLeft: 16,
        fontSize: 14,
        padding: 0,
        fontFamily: 'PoppinsSemiBold',
    }),
    control: provided => ({
        ...provided,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#d0d0d0',
        borderRadius: 2,
        boxSizing: 'border-box',
        backgroundColor: '#f2f2f2',
        boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
        height: 48,
        outline: 'none',
    }),
    singleValue: provided => ({
        ...provided,
        marginLeft: 16,
        fontSize: 14,
        color: '#828282',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        fontSize: 10,
        color: '#828282',
    }),
};

export default function ModalRepresentanteEdit({ representante, isOpen, onClose, onRefresh }) {
    const [loading, setLoading] = useState(false);
    const [cpfSearch, setCpfSearch] = useState('');
    const [formData, setFormData] = useState({
        uuid: '',
        user_uuid: '',
        name: '',
        corporate_email: '',
        corporate_phone: '',
        cargo: '',
    });

    const handleSubmit = async () => {
        setLoading(true);
        console.log(formData);
        await ApiClient.doRequest({
            uri: `/representante/${formData.uuid}`,
            method: ApiClient.PATCH,
            body: formData,
            expectedStatus: 201,
        });
        onRefresh();
        setLoading(false);
        onClose();
    }

    useEffect(() => {
        if (!isOpen) {
            setFormData({
                uuid: '',
                user_uuid: '',
                name: '',
                corporate_email: '',
                corporate_phone: '',
                cargo: '',
            });
            return;
        }

        const data = representante[0];
        setFormData({
            uuid: data.uuid,
            user_uuid: data.user_uuid,
            name: data.name,
            corporate_email: data.corporate_email,
            corporate_phone: data.phone_mobile,
            cargo: data.cargo,
        });
    }, [representante, isOpen]);

    const handleFetchUser = async (e) => {
        setCpfSearch(e.target.value);

        const cpf = e.target.value.replace(/\D/g, '');
        if (cpf.length < 11) {
            return;
        }
        setCpfSearch(cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'));

        try {
            const response = await ApiClient.doRequest({
                uri: `/user/find/${cpf}`,
                method: ApiClient.GET,
                expectedStatus: 200,
            });

            const userData = response.data.data

            setFormData({
                uuid: representante[0].uuid,
                user_uuid: userData.uuid,
                corporate_email: userData.email,
                corporate_phone: userData.user_personal_data.phone_mobile,
                cargo: userData.cargo,
            });
        } catch (error) {
            showModalMessage({
                type: 'error',
                text: `Usuário não encontrado.`,
            });
        }
    }

    return (
        <Modal
            // onAfterOpen={renderStatusSelect}
            // onRequestClose={closeModal}
            isOpen={isOpen}
            style={{
                overlay: overlayStyle,
                content: {
                    ...contentStyle,
                    overflow: 'visible',
                    height: '600px',
                    width: '900px',
                },
            }}
        >
            <ModalButton onClick={onClose}>X</ModalButton>
            <ModalContent>
                <Form>
                    <Wrap>
                        <InputMask
                            type="text"
                            mask="999.999.999-99"
                            maskChar=""
                            name="cpf"
                            label='CPF'
                            value={cpfSearch}
                            onChange={handleFetchUser}
                        />
                    </Wrap>
                    <Wrap style={{ flexDirection: 'column' }}>
                        <span style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '20px', color: '#828282', fontFamily: 'InterBold, sans-serif' }}>
                            Usuário:
                        </span>
                        <span style={{ fontSize: 16, lineHeight: '20px', color: '#828282' }}>
                            {representante[0].name}
                        </span>
                    </Wrap>
                    <Wrap>
                        <Input
                            type="text"
                            name="corporate_email"
                            label='Email corporativo'
                            value={formData.corporate_email}
                            onChange={(e) => { setFormData({ ...formData, corporate_email: e.target.value }) }}
                        />
                    </Wrap>
                    <Wrap>
                        <Input
                            type="text"
                            name="corporate_phone"
                            label='Telefone'
                            value={formData.corporate_phone}
                            onChange={(e) => { setFormData({ ...formData, corporate_phone: e.target.value }) }}
                        />
                        <Input
                            type="text"
                            name="cargo"
                            label='Cargo'
                            value={formData.cargo}
                            onChange={(e) => { setFormData({ ...formData, cargo: e.target.value }) }}
                        />
                    </Wrap>
                    <Wrap>
                        <Button
                            type="submit"
                            btType="#26A2B1"
                            label="Salvar"
                            onClick={handleSubmit}
                            full
                            width="150px"
                            loading={loading}
                            disabled={loading}
                        />
                    </Wrap>
                </Form>
            </ModalContent>
        </Modal >
    );
}