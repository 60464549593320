/* eslint-disable no-case-declarations */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiFillExclamationCircle,
} from 'react-icons/ai';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  Textarea,
  ChoiceButtonLocacao,
  InputNoForm,
  CheckboxHtml,
} from '~/components/Forms';

import {
  PageContainer,
  LimiterInputWidth,
  ConditionalContainer,
  GridContainer,
  ActionInputMulti,
  MultiInputContainer,
  ContainerMultiInputActions,
} from '~/styles/components';

import { NavBarHeader } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import { useList } from '~/hooks/useFunction';

import { useCurrentUser } from '~/hooks/currentUser';
import LogoIcon from '~/components/LogoIcon';
import { useAuth } from '~/hooks/auth';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import {
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '../Locacoes/functions/date-hour-utils';

import procedimentoFilmagem from '~/assets/documets/Procedimento_de_Filmagens_ZER.pdf';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';
import { TextFormMultiInput } from '~/components/Forms/TextFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function CienciaFilmagemInterna() {
  const { user: authUser } = useAuth();
  const hasAdminRole = authUser?.roles?.some(role => role === 'Administrador');

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useCurrentUser();

  const dadoState = location.state;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseCiencia, setResponseCiencia] = useState({});
  const [baseApoio, setBaseApoio] = useState(null);
  const [errorsForm, setErrorsForm] = useState({});

  // states multi input
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);
  const [enderecosInput, setEnderecosInput] = useState([{}]);
  const [horasEntradaInputs, setHorasEntradaInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasEntradaInputs, setDatasEntradaInputs] = useState([
    { data_entrada: '' },
  ]);
  const [horasSaidaInputs, setHorasSaidaInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasSaidaInputs, setDatasSaidaInputs] = useState([
    { data_saida: '' },
  ]);

  // lists
  const workTypeList = useList({
    url: 'work-type?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));
  const veiculacaoInicialList = useList({
    url: 'ld-veiculo-inicial?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisAbertosList = useList({
    url: 'ld-canais-aberto?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosFilmeList = useList({
    url: 'ld-canais-pagos-filme?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosInfantilList = useList({
    url: 'ld-canais-pagos-infantil?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosNoticiaList = useList({
    url: 'l-canais-pagos-noticia?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosDocumentarioList = useList({
    url: 'ld-canais-pagos-documentario?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosVariedadeList = useList({
    url: 'ld-canais-pagos-variedade?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosEsporteList = useList({
    url: 'ld-canais-pagos-esporte?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const streamingList = useList({ url: 'ld-streaming?paginate=false' })?.map(
    ({ uuid, name }) => ({ value: uuid, label: name }),
  );

  // Busca dados da Ciencia
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `ciencia-filmagem-interna/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respCiencia = resp?.data?.data;

      setBaseApoio(
        typeof respCiencia?.base_address === 'string' &&
          typeof respCiencia?.base_num === 'string',
      );

      // set multi inputs values

      setDatasFilmagemInputs(
        respCiencia?.date_ciencia_filmagem?.map(({ date }) => ({
          data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
        })),
      );

      setEnderecosInput(
        respCiencia?.endereco_ciencia_filmagem?.map(({ uuid, address }) => ({
          uuid,
          enderecos: address,
        })),
      );

      setHorasFilmagemInputs(
        respCiencia?.hour_ciencia_filmagem?.map(({ inicio, fim }) => ({
          inicio: inicio.substring(0, 5),
          fim: fim.substring(0, 5),
        })),
      );

      setDatasEntradaInputs(
        respCiencia?.base_dates
          ?.filter(({ date_input }) => date_input === 'Entrada')
          ?.map(({ date }) => ({
            data_entrada: getBrowserTransformDate(date.split(' ')[0], true),
          })),
      );

      setDatasSaidaInputs(
        respCiencia?.base_dates
          ?.filter(({ date_input }) => date_input === 'Saída')
          ?.map(({ date }) => ({
            data_saida: getBrowserTransformDate(date.split(' ')[0], true),
          })),
      );

      setHorasEntradaInputs(
        respCiencia?.base_hours
          ?.filter(({ hour_input }) => hour_input === 'Entrada')
          ?.map(({ inicio, fim }) => ({
            inicio: inicio.substring(0, 5),
            fim: fim.substring(0, 5),
          })),
      );

      setHorasSaidaInputs(
        respCiencia?.base_hours
          ?.filter(({ hour_input }) => hour_input === 'Saída')
          ?.map(({ inicio, fim }) => ({
            inicio: inicio.substring(0, 5),
            fim: fim.substring(0, 5),
          })),
      );

      // set select default values
      formRef.current?.setFieldValue('work_type_uuid', {
        label: respCiencia?.work_type?.name,
        value: respCiencia?.work_type?.uuid,
      });

      formRef?.current?.setFieldValue(
        'ld_veiculacao_inicial',
        respCiencia?.ld_veiculacao_incial?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );

      formRef?.current?.setFieldValue(
        'ld_canais_abertos',
        respCiencia?.ld_canais_abertos?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_pagos_filmes',
        respCiencia?.ld_canais_filme?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_pagos_infantis',
        respCiencia?.ld_canais_infantil?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_pagos_noticias',
        respCiencia?.ld_canais_noticia?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_pagos_documentarios',
        respCiencia?.ld_canais_documentarios?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_pagos_variedades',
        respCiencia?.ld_canais_variedade?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_esporte',
        respCiencia?.ld_canais_esporte?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_streaming',
        respCiencia?.ld_streaming?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );

      setResponseCiencia(respCiencia);
      setLoading(false);
    }
  }, [dadoState]);

  // effects
  useEffect(() => {
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB, dadoState?.viewData]);

  function handleChange(name, value) {
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleOnlyNumber(event) {
    if (!/^[0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
  }

  const handleBaseApoioTrue = e => {
    e.preventDefault(e);
    setBaseApoio(true);
    setErrorsForm(state => ({
      ...state,
      baseApoio: false,
    }));
  };

  const handleBaseApoioFalse = e => {
    e.preventDefault(e);
    setBaseApoio(false);
    setErrorsForm(state => ({
      ...state,
      baseApoio: false,
    }));
  };

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const cienciaFilmagemInternaSchema = Yup.object().shape({
        titulo_obra: Yup.string().typeError(defaultMsg).required(defaultMsg),
        work_type_uuid: Yup.string().typeError(defaultMsg).required(defaultMsg),
        produtora: Yup.string().typeError(defaultMsg).required(defaultMsg),
        cpf_cnpj: Yup.string().typeError(defaultMsg).required(defaultMsg),
        sinopse: Yup.string().typeError(defaultMsg).required(defaultMsg),
        ld_veiculacao_inicial: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'veiculacao-inicial-selecionada',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
        base_address: Yup.string()
          .typeError(defaultMsg)
          .test(
            'base-apoio-selecionado',
            defaultMsg,
            value => !(baseApoio === true && (value?.length <= 0 || !value)),
          ),
        base_num: Yup.string()
          .typeError(defaultMsg)
          .test(
            'base-apoio-selecionado',
            defaultMsg,
            value => !(baseApoio === true && (value?.length <= 0 || !value)),
          ),
        production_coordinator_name: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        production_coordinator_phone: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        production_coordinator_email: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        production_director_name: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        production_director_phone: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        production_director_email: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        producer_executive_name: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        producer_executive_phone: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        producer_executive_email: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
      });

      await cienciaFilmagemInternaSchema.validate(data, {
        abortEarly: false,
      });

      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }

      if (baseApoio === null) {
        await showModalMessage({
          type: 'error',
          text: 'Base de Apoio não foi preenchido!',
        });
        setErrorsForm(state => ({
          ...state,
          baseApoio: true,
        }));
        return false;
      }

      if (!data.ciencia_obrigatoriedade) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, marque a ciência de Procedimentos de Filmagens em ZERs',
        });
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  function formatDados(data) {
    // dados já exibidos sem condicoes
    const dados = omitEmpty({
      ...data,
    });
    // format endereços filmagem
    const enderecosFilmagem = enderecosInput
      .filter(item => typeof item === 'object')
      .map(item => item.enderecos);
    // format datas filmagem
    const datasFilmagem = datasFilmagemInputs
      .filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_filmagem));

    let dataBaseApoio = {};
    if (baseApoio) {
      const datasBaseEntrada = datasEntradaInputs
        ?.filter(item => typeof item === 'object')
        .map(item => ({
          date: getBrowserTransformDate(item.data_entrada),
          date_input: 'Entrada',
        }));

      const datasBaseSaida = datasSaidaInputs
        ?.filter(item => typeof item === 'object')
        .map(item => ({
          date: getBrowserTransformDate(item.data_saida),
          date_input: 'Saída',
        }));

      const horasBaseEntrada = horasEntradaInputs.map(item => ({
        ...item,
        hour_input: 'Entrada',
      }));
      const horasBaseSaida = horasSaidaInputs.map(item => ({
        ...item,
        hour_input: 'Saída',
      }));

      dataBaseApoio = {
        base_address: data?.base_address,
        base_num: data?.base_num,
        base_dates: [...datasBaseEntrada, ...datasBaseSaida],
        base_hours: [...horasBaseEntrada, ...horasBaseSaida],
      };
    }

    return Object.assign(
      dados,
      dataBaseApoio,
      { user_id: authUser?.uuid },
      { dates: datasFilmagem },
      { hours: horasFilmagemInputs },
      { enderecos: enderecosFilmagem },
    );
  }

  async function handleSubmit(data) {
    const ldVeiculacaoInicialUuids = formData?.ld_veiculacao_inicial?.map(
      ({ value }) => value,
    );
    const ldStreamingUuids = formData?.ld_streaming?.map(({ value }) => value);
    const ldCanaisAbertosUuids = formData?.ld_canais_abertos?.map(
      ({ value }) => value,
    );
    const ldCanaisFilmeUuids = formData?.ld_canais_filme?.map(
      ({ value }) => value,
    );
    const ldCanaisInfantilUuids = formData?.ld_canais_infantil?.map(
      ({ value }) => value,
    );
    const ldCanaisNoticiaUuids = formData?.ld_canais_noticia?.map(
      ({ value }) => value,
    );
    const ldCanaisDocumentariosUuids = formData?.ld_canais_documentarios?.map(
      ({ value }) => value,
    );
    const ldCanaisVariedadeUuids = formData?.ld_canais_variedade?.map(
      ({ value }) => value,
    );
    const ldCanaisEsporteUuids = formData?.ld_canais_esporte?.map(
      ({ value }) => value,
    );

    data.ld_veiculacao_inicial = ldVeiculacaoInicialUuids;
    data.ld_streaming = ldStreamingUuids;
    data.ld_canais_abertos = ldCanaisAbertosUuids;
    data.ld_canais_filme = ldCanaisFilmeUuids;
    data.ld_canais_infantil = ldCanaisInfantilUuids;
    data.ld_canais_noticia = ldCanaisNoticiaUuids;
    data.ld_canais_documentarios = ldCanaisDocumentariosUuids;
    data.ld_canais_variedade = ldCanaisVariedadeUuids;
    data.ld_canais_esporte = ldCanaisEsporteUuids;
    data.ciencia_obrigatoriedade = formData?.ciencia_obrigatoriedade;

    if (!(await handleValidationFields(data))) {
      return;
    }

    const cienciaFilmagemInternaData = formatDados(data);

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: 'ciencia-filmagem-interna',
        method: ApiClient.POST,
        body: Object.assign(cienciaFilmagemInternaData),
        expectedStatus: 201,
      });

      await showModalMessage({
        type: 'success',
        title: 'Ciência Filmagem Interna',
        text: 'Cadastro realizado com sucesso!',
      });
      history.push('/escolha-acesso');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      await showModalMessage({
        type: 'error',
        text: 'Ocorreu um erro ao tentar gravar Ciência de Filmagem Interna. Verifique os dados.',
      });
    } finally {
      setLoading(false);
    }
  }

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseCiencia) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>
                Nenhuma Ciência de Filmagem Interna encontrada
              </h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader style={{ justifyContent: 'flex-end' }}>
                  <span
                    tooltip={clientResources.cienciaFilmagemInterna.dicaForm}
                  >
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <Wrap>
                  <Input
                    type="text"
                    name="titulo_obra"
                    label="Nome da Obra"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData && responseCiencia?.titulo_obra
                    }
                    disabled={dadoState?.viewData}
                  />
                  <Select
                    name="work_type_uuid"
                    label="Formato da Obra Audiovisual"
                    placeholder="Selecione"
                    style={selectCustomStyles}
                    required
                    options={workTypeList}
                    isDisabled={dadoState?.viewData && true}
                    onChange={value => handleChange('work_type_uuid', value)}
                  />
                </Wrap>
                <Wrap>
                  <Input
                    type="text"
                    name="produtora"
                    label="Produtora"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData && responseCiencia?.produtora
                    }
                    disabled={dadoState?.viewData}
                  />
                  <Input
                    type="text"
                    name="cpf_cnpj"
                    label="CNPJ/CPF (somente números)"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    maxLength={14}
                    onKeyPress={handleOnlyNumber}
                    required
                    defaultValue={
                      dadoState?.viewData && responseCiencia?.cpf_cnpj
                    }
                    disabled={dadoState?.viewData}
                  />
                </Wrap>
                <Wrap>
                  <Textarea
                    type="text"
                    rows={3}
                    name="sinopse"
                    label="Sinopse"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData && responseCiencia?.sinopse
                    }
                    disabled={dadoState?.viewData}
                  />
                </Wrap>
                <Wrap>
                  <GridContainer cols={2}>
                    <LimiterInputWidth w100>
                      <Select
                        name="ld_veiculacao_inicial"
                        label="Veiculação inicial prevista"
                        placeholder="Selecione"
                        questionTooltip={
                          clientResources.cienciaFilmagemInterna.dicaData
                        }
                        style={selectCustomStyles}
                        required
                        options={veiculacaoInicialList}
                        isDisabled={dadoState?.viewData}
                        onChange={e => {
                          handleChange('ld_veiculacao_inicial', e);

                          const listsVeicIni = [
                            {
                              label: 'TV Aberta',
                              key_name: 'ld_canais_abertos',
                            },
                            {
                              label: 'Plataforma de Streaming',
                              key_name: 'ld_streaming',
                            },
                            {
                              label: 'TV Paga',
                              key_name: 'ld_canais_filme',
                            },
                            {
                              label: 'TV Paga',
                              key_name: 'ld_canais_infantil',
                            },
                            {
                              label: 'TV Paga',
                              key_name: 'ld_canais_noticia',
                            },
                            {
                              label: 'TV Paga',
                              key_name: 'ld_canais_documentarios',
                            },
                            {
                              label: 'TV Paga',
                              key_name: 'ld_canais_variedade',
                            },
                            {
                              label: 'TV Paga',
                              key_name: 'ld_canais_esporte',
                            },
                            {
                              label: 'Outros',
                              key_name: 'outro_veiculacao',
                            },
                          ];

                          listsVeicIni.forEach((key, _) =>
                            setFormData(state => ({
                              ...state,
                              [key.key_name]: e?.some(
                                ({ label }) => label === key.label,
                              )
                                ? state[key.key_name]
                                : null,
                            })),
                          );
                        }}
                        isMulti
                      />
                    </LimiterInputWidth>

                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'TV Aberta',
                    ) && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_abertos"
                          label="Televisão - Canais Abertos"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisAbertosList}
                          onChange={e => handleChange('ld_canais_abertos', e)}
                          isMulti
                          isDisabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}

                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'TV Paga',
                    ) && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_filme"
                          label="Filmes/Séries"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosFilmeList}
                          onChange={e => handleChange('ld_canais_filme', e)}
                          isMulti
                          isDisabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}
                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'TV Paga',
                    ) && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_infantil"
                          label="Infantis"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosInfantilList}
                          onChange={e => handleChange('ld_canais_infantil', e)}
                          isMulti
                          isDisabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}
                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'TV Paga',
                    ) && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_noticia"
                          label="Notícias"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosNoticiaList}
                          onChange={e => handleChange('ld_canais_noticia', e)}
                          isMulti
                          isDisabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}
                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'TV Paga',
                    ) && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_documentarios"
                          label="Documentários"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosDocumentarioList}
                          onChange={e =>
                            handleChange('ld_canais_documentarios', e)
                          }
                          isMulti
                          isDisabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}
                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'TV Paga',
                    ) && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_variedade"
                          label="Variedades"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosVariedadeList}
                          onChange={e => handleChange('ld_canais_variedade', e)}
                          isMulti
                          isDisabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}
                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'TV Paga',
                    ) && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_canais_esporte"
                          label="Esportes"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={canaisPagosEsporteList}
                          onChange={e => handleChange('ld_canais_esporte', e)}
                          isMulti
                          isDisabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}
                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'Plataforma de Streaming',
                    ) && (
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_streaming"
                          label="Plataforma de Streaming"
                          placeholder="Selecione"
                          style={selectCustomStyles}
                          options={streamingList}
                          onChange={e => handleChange('ld_streaming', e)}
                          isMulti
                          isDisabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}
                    {formData?.ld_veiculacao_inicial?.some(
                      ({ label }) => label === 'Outros',
                    ) && (
                      <LimiterInputWidth w100>
                        <Input
                          name="outro_veiculacao"
                          label="Outros"
                          onChange={event =>
                            handleChange('outro_veiculacao', event.target.value)
                          }
                          disabled={dadoState?.viewData}
                        />
                      </LimiterInputWidth>
                    )}
                  </GridContainer>
                </Wrap>

                <BoxHeader>
                  <span className="boxText">Coordenador(a) de Produção</span>
                </BoxHeader>
                <Wrap>
                  <Input
                    type="text"
                    name="production_coordinator_name"
                    label="Nome"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.production_coordinator_name
                    }
                    disabled={dadoState?.viewData}
                  />
                  <Input
                    type="text"
                    name="production_coordinator_phone"
                    label="Telefone (somente números)"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    maxLength={11}
                    onKeyPress={handleOnlyNumber}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.production_coordinator_phone
                    }
                    disabled={dadoState?.viewData}
                  />
                  <Input
                    type="text"
                    name="production_coordinator_email"
                    label="E-mail"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.production_coordinator_email
                    }
                    disabled={dadoState?.viewData}
                  />
                </Wrap>

                <BoxHeader>
                  <span className="boxText">Diretor(a) de Produção</span>
                </BoxHeader>
                <Wrap>
                  <Input
                    type="text"
                    name="production_director_name"
                    label="Nome"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.production_director_name
                    }
                    disabled={dadoState?.viewData}
                  />
                  <Input
                    type="text"
                    name="production_director_phone"
                    label="Telefone (somente números)"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    maxLength={11}
                    onKeyPress={handleOnlyNumber}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.production_director_phone
                    }
                    disabled={dadoState?.viewData}
                  />
                  <Input
                    type="text"
                    name="production_director_email"
                    label="E-mail"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.production_director_email
                    }
                    disabled={dadoState?.viewData}
                  />
                </Wrap>

                <BoxHeader>
                  <span className="boxText">Produtor(a) Executivo(a)</span>
                </BoxHeader>
                <Wrap>
                  <Input
                    type="text"
                    name="producer_executive_name"
                    label="Nome"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.producer_executive_name
                    }
                    disabled={dadoState?.viewData}
                  />
                  <Input
                    type="text"
                    name="producer_executive_phone"
                    label="Telefone (somente números)"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    maxLength={11}
                    onKeyPress={handleOnlyNumber}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.producer_executive_phone
                    }
                    disabled={dadoState?.viewData}
                  />
                  <Input
                    type="text"
                    name="producer_executive_email"
                    label="E-mail"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseCiencia?.producer_executive_email
                    }
                    disabled={dadoState?.viewData}
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Já possui local definido para a base de apoio?"
                      onClickTrue={handleBaseApoioTrue}
                      onClickFalse={handleBaseApoioFalse}
                      selected={baseApoio}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.baseApoio === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={baseApoio}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="base_address"
                        label="Endereço"
                        onChange={e => {
                          const { name, value } = e.target;
                          handleChange(name, value);
                        }}
                        defaultValue={
                          dadoState?.viewData && responseCiencia?.base_address
                        }
                        disabled={dadoState?.viewData}
                        required={baseApoio}
                      />
                    </LimiterInputWidth>
                    <LimiterInputWidth w25>
                      <Input
                        type="number"
                        name="base_num"
                        label="Número"
                        onChange={e => {
                          const { name, value } = e.target;
                          handleChange(name, value);
                        }}
                        maxLength={11}
                        onKeyPress={handleOnlyNumber}
                        required={baseApoio}
                        defaultValue={
                          dadoState?.viewData && responseCiencia?.base_num
                        }
                        disabled={dadoState?.viewData}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <BoxHeader>
                    <span className="boxText">Entrada da Base</span>
                  </BoxHeader>
                  <Wrap>
                    <DateFormMultiInput
                      label="Data(s)"
                      dates={datasEntradaInputs}
                      setDates={setDatasEntradaInputs}
                      setIsLoading={setLoading}
                      isViewMode={dadoState?.viewData}
                      dateKeyName="data_entrada"
                      isRequired={baseApoio}
                    />

                    <HourFormMultiInput
                      label="Horário(s)"
                      hours={horasEntradaInputs}
                      setHours={setHorasEntradaInputs}
                      setIsLoading={setLoading}
                      isViewMode={dadoState?.viewData}
                      hourBeginKeyName="inicio"
                      hourEndKeyName="fim"
                      isRequired={baseApoio}
                    />
                  </Wrap>

                  <BoxHeader>
                    <span className="boxText">Saída da Base</span>
                  </BoxHeader>
                  <Wrap>
                    <DateFormMultiInput
                      label="Data(s)"
                      dates={datasSaidaInputs}
                      setDates={setDatasSaidaInputs}
                      setIsLoading={setLoading}
                      isViewMode={dadoState?.viewData}
                      dateKeyName="data_saida"
                      isRequired={baseApoio}
                    />

                    <HourFormMultiInput
                      label="Horário(s)"
                      hours={horasSaidaInputs}
                      setHours={setHorasSaidaInputs}
                      setIsLoading={setLoading}
                      isViewMode={dadoState?.viewData}
                      hourBeginKeyName="inicio"
                      hourEndKeyName="fim"
                      isRequired={baseApoio}
                    />
                  </Wrap>
                </ConditionalContainer>

                <TextFormMultiInput
                  texts={enderecosInput}
                  setTexts={setEnderecosInput}
                  isRequired
                  isViewMode={dadoState?.viewData}
                  label="Endereço(s) da Filmagem"
                  textKeyName="enderecos"
                />
                <Wrap>
                  <DateFormMultiInput
                    label="Data(s)"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    dateKeyName="data_filmagem"
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Horário(s)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    isRequired
                  />
                </Wrap>

                <Wrap
                  style={{
                    display: clientResources.cienciaFilmagemInterna.display,
                  }}
                >
                  <CheckboxHtml
                    name="ciencia_obrigatoriedade"
                    required
                    label="No caso de filmagens em Zonas Exclusivamente Residenciais, declaro estar ciente da obrigatoriedade de seguir integralmente o disposto em Procedimentos de Filmagens em ZERs."
                    checked={
                      dadoState?.viewData &&
                      responseCiencia?.ciencia_obrigatoriedade === 1
                    }
                    onChange={e =>
                      handleChange(e.target.name, e.target.checked)
                    }
                    disabled={dadoState?.viewData}
                  />
                </Wrap>
              </WhiteBox>

              <Wrap>
                {!dadoState?.viewData && (
                  <Button
                    type="submit"
                    btType="#26A2B1"
                    label="Enviar"
                    full
                    width="140px"
                    loading={loading}
                    disabled={loading}
                    style={{ position: 'relative', bottom: '35px' }}
                  />
                )}

                <Button
                  as={Link}
                  to={`${process.env.PUBLIC_URL}${procedimentoFilmagem}`}
                  target="_blank"
                  type="button"
                  btType="#828282"
                  label="Baixar documento dos procedimentos para filmagem em ZER"
                  title="Baixar o documento de Procedimento de Filmagens - ZER"
                  full
                  width="550px"
                  style={{
                    position: 'relative',
                    bottom: '35px',
                    marginLeft: 5,
                    display: clientResources.cienciaFilmagemInterna.display,
                  }}
                />
              </Wrap>
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon disabled />
        {!hasAdminRole && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Ciência de Filmagem Interna</h1>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
        <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
