import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { showModalMessage } from '~/services/notification';

import { Input, InputMask, FilePerfil, File } from '~/components/Forms';

import Button from '~/components/Button';

import { MainPage, ButtonBox, Wrap, LimiterInputWidth } from './styles';

import { PageContainer } from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  BoxHeader,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';

import { NavBarHeader } from '~/components/Usuario';

import ApiClient from '~/utils/ApiClient';
import Load from '~/components/Load';
import { useList } from '~/hooks/useFunction';
import { useCurrentUser } from '~/hooks/currentUser';
import LogoIcon from '~/components/LogoIcon';
import maskString from '~/functions/maskString';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export default function Perfil() {
  const history = useHistory();
  const { addUser, user } = useCurrentUser();

  // refs
  const formRef = useRef();
  const avatarFileRef = useRef(null);
  const cpfFileRef = useRef(null);
  const rgFileRef = useRef(null);

  // states
  const [responsePerfil, setResponsePerfil] = useState({});
  const [loading, setLoading] = useState(false);
  const [dadosCep, setDadosCep] = useState();

  // states files
  const [dataCpfFile, setDataCpfFile] = useState(null);
  const [dataRgFile, setDataRgFile] = useState(null);

  const cities = useList({ url: 'city?paginate=false' });
  const neighborhoods = useList({ url: 'neighborhood?paginate=false' });

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  function handleCpfFile() {
    return cpfFileRef.current.click();
  }

  function handleRgFile() {
    return rgFileRef.current.click();
  }

  useEffect(() => {
    if (user && !user?.cnpj) {
      ApiClient.doRequest({
        uri: `me`,
        method: ApiClient.GET,
        expectedStatus: 200,
      }).then(response => {
        setResponsePerfil({
          ...response.data.data.user_address,
          ...response.data.data.user_birth_address,
          ...response.data.data.user_personal_data,
          cep: response.data.data?.user_address?.cep
            ?.toString()
            .padStart(8, '0'),
          email: response.data.data.email,
          uuid: response.data.data.uuid,
          files: response.data.data.files,
        });
      });
    } else {
      setResponsePerfil(user);
    }
  }, [user]);

  const handleChangeCep = useCallback(evt => {
    const { value } = evt.target;

    if (!value) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${value}/json/`)
      .then(res => res.json())
      .then(data => setDadosCep(data));
  }, []);

  const handleValidationFiles = async () => {
    const avatarFile = avatarFileRef?.current?.files[0];

    if (
      avatarFile &&
      !['image/jpeg', 'image/png', 'image/jpg'].includes(avatarFile?.type)
    ) {
      await showModalMessage({
        type: 'error',
        text: `Tipo '${avatarFile?.type}' não é um tipo de formato de arquivo aceitável para envio de foto de perfil`,
      });
      return false;
    }

    if (
      dataCpfFile &&
      !['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'].includes(
        dataCpfFile?.type,
      )
    ) {
      await showModalMessage({
        type: 'error',
        text: `Tipo '${dataCpfFile?.type}' não é um tipo de formato de arquivo aceitável para envio de CPF`,
      });
      return false;
    }

    if (
      dataRgFile &&
      !['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'].includes(
        dataRgFile?.type,
      )
    ) {
      await showModalMessage({
        type: 'error',
        text: `Tipo '${dataRgFile?.type}' não é um tipo de formato de arquivo aceitável para envio de RG`,
      });
      return false;
    }

    return true;
  };

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});

      // valid files
      if (!handleValidationFiles()) return false;

      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        name: Yup.string()
          .typeError(defaultMsg)
          .test(
            'perfil-cpf-selecionado',
            defaultMsg,
            value => !(!user?.cnpj && (value?.length <= 0 || !value)),
          ),
        phone_mobile: Yup.string()
          .typeError(defaultMsg)
          .test(
            'perfil-cpf-selecionado',
            defaultMsg,
            value => !(!user?.cnpj && (value?.length <= 0 || !value)),
          ),
        rg: Yup.string()
          .typeError(defaultMsg)
          .test(
            'perfil-cpf-selecionado',
            defaultMsg,
            value => !(!user?.cnpj && (value?.length <= 0 || !value)),
          ),
        birth_date: Yup.string()
          .typeError(defaultMsg)
          .test(
            'perfil-cpf-selecionado',
            defaultMsg,
            value => !(!user?.cnpj && (value?.length <= 0 || !value)),
          ),

        cnpj: Yup.string()
          .typeError(defaultMsg)
          .test(
            'perfil-cnpj-selecionado',
            defaultMsg,
            value => !(user?.cnpj && (value?.length <= 0 || !value)),
          ),
        phone: Yup.string()
          .typeError(defaultMsg)
          .test(
            'perfil-cnpj-selecionado',
            defaultMsg,
            value => !(user?.cnpj && (value?.length <= 0 || !value)),
          ),
        rate_social: Yup.string()
          .typeError(defaultMsg)
          .test(
            'perfil-cnpj-selecionado',
            defaultMsg,
            value => !(user?.cnpj && (value?.length <= 0 || !value)),
          ),
        fantasy_name: Yup.string()
          .typeError(defaultMsg)
          .test(
            'perfil-cnpj-selecionado',
            defaultMsg,
            value => !(user?.cnpj && (value?.length <= 0 || !value)),
          ),
        cep: Yup.string().typeError(defaultMsg).required(defaultMsg),
        state: Yup.string().typeError(defaultMsg).required(defaultMsg),
        city_uuid: Yup.string().typeError(defaultMsg).required(defaultMsg),
        neighborhood_uuid: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        street: Yup.string().typeError(defaultMsg).required(defaultMsg),
        number: Yup.string().typeError(defaultMsg).required(defaultMsg),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      return false;
    }
  }

  const getBase64FromFile = async file => {
    if (!file) {
      return null;
    }
    const base64 = await convertBase64(file);
    // eslint-disable-next-line prefer-destructuring
    return base64.split('base64,')[1];
  };

  const handleSubmit = useCallback(
    async data => {
      if (!(await handleValidationFields(data))) {
        return;
      }

      setLoading(true);

      const avatarFileBase64 = await getBase64FromFile(
        avatarFileRef?.current?.files[0],
      );

      const cpfFileBase64 = await getBase64FromFile(dataCpfFile);
      const rgFileBase64 = await getBase64FromFile(dataRgFile);

      const cpf = data?.cpf?.replace(/[^0-9]/g, '');
      const cnpj = data?.cnpj?.replace(/[^0-9]/g, '');
      const phone = data?.phone?.replace(/[^0-9]/g, '');
      const phone_mobile = data?.phone_mobile?.replace(/[^0-9]/g, '');
      const cep = data?.cep?.replace(/[^0-9]/g, '');

      try {
        let neighborhoodUuid;
        let cityUuid;
        if (dadosCep) {
          const selectedCity = cities.filter(
            city => dadosCep?.localidade === city?.name,
          );

          // filtrar bairro preenchido com os já existentes em tabela
          const selectedNeighborhood = neighborhoods.filter(
            neighborhood => dadosCep?.bairro === neighborhood?.name,
          );

          // filtrar bairro existente em tabela
          const dataNeighborhood = {
            name: dadosCep?.bairro,
            city_uuid: selectedCity[0]?.uuid,
          };

          // se o barro não existir na base de dados, criar bairro
          if (selectedNeighborhood?.length === 0) {
            await ApiClient.doRequest({
              uri: 'neighborhood',
              method: ApiClient.POST,
              expectedStatus: 201,
              body: dataNeighborhood,
            });
          }

          // nova listagem de bairros em tabela
          const createdNeighborhood = await ApiClient.doRequest({
            uri: 'neighborhood?paginate=false',
            method: ApiClient.GET,
            expectedStatus: 200,
          });

          // filtrar bairros
          const neighborhoodCreated = createdNeighborhood?.data?.data.filter(
            neighborhood => dadosCep?.bairro === neighborhood?.name,
          );

          neighborhoodUuid =
            selectedNeighborhood?.length === 0
              ? neighborhoodCreated[0]?.uuid
              : selectedNeighborhood[0]?.uuid;

          cityUuid =
            selectedNeighborhood?.length === 0
              ? neighborhoodCreated[0]?.city?.uuid
              : selectedNeighborhood[0]?.city?.uuid;
        } else {
          const selectedNeighborhood = neighborhoods.filter(
            neighborhood => data?.neighborhood_uuid === neighborhood?.name,
          );

          neighborhoodUuid = selectedNeighborhood[0]?.uuid;
          cityUuid = selectedNeighborhood[0]?.city?.uuid;
        }

        const photoFileUuidKey = {
          photo_base64_uuid: avatarFileBase64
            ? responsePerfil?.files?.find(({ name }) => name === 'foto_user')
                ?.uuid
            : null,
        };
        const cpfFileBase64Key = {
          [dataCpfFile?.type?.startsWith('application')
            ? 'cpf_base64_pdf'
            : 'cpf_base64']: cpfFileBase64,
        };
        const cpfFileUuidKey = {
          [dataCpfFile?.type?.startsWith('application')
            ? 'cpf_base64_pdf_uuid'
            : 'cpf_base64_uuid']: responsePerfil?.files?.find(
            ({ name }) => name === 'cpf',
          )?.uuid,
        };
        const rgFileBase64Key = {
          [dataRgFile?.type?.startsWith('application')
            ? 'rg_base64_pdf'
            : 'rg_base64']: rgFileBase64,
        };
        const rgFileUuidKey = {
          [dataRgFile?.type?.startsWith('application')
            ? 'rg_base64_pdf_uuid'
            : 'rg_base64_uuid']: responsePerfil?.files?.find(
            ({ name }) => name === 'rg',
          )?.uuid,
        };

        const userPF = {
          name: data?.name,
          cpf,
          rg: data?.rg,
          phone_mobile,
          drt: data?.drt,
          cep,
          street: data?.street,
          number: data?.number,
          complement: data?.complement,
          birth_date: data?.birth_date,
          city_uuid: cityUuid,
          neighborhood_uuid: neighborhoodUuid,
          photo_base64: avatarFileBase64 || null,
          ...photoFileUuidKey,
          ...cpfFileBase64Key,
          ...cpfFileUuidKey,
          ...rgFileBase64Key,
          ...rgFileUuidKey,
        };

        const userPJ = {
          cnpj,
          company_name: data?.rate_social,
          fantasy_name: data?.fantasy_name,
          phone,
          cep,
          city_uuid: cityUuid,
          neighborhood_uuid: neighborhoodUuid,
          street: data?.street,
          number: data?.number,
          complement: data?.complement,
          // representante_uuid: responseRepresentacaoLegal?.data?.data?.uuid,
          // cpf,
          // name: data?.name,
          // phone_mobile,
          // rg: data?.rg_rne,
          // drt: data?.drt,
          // birth_date: data?.birth_date,
          photo_base64: avatarFileBase64 || null,
          photo_base64_uuid: avatarFileBase64
            ? user?.files?.find(({ name }) => name === 'foto_branch')?.uuid
            : null,
        };

        if (data?.cnpj) {
          const response = await ApiClient.doRequest({
            uri: `branch/${user?.uuid}`,
            method: ApiClient.PATCH,
            expectedStatus: 201,
            body: userPJ,
          });

          await addUser(response?.data?.data);
        } else {
          await ApiClient.doRequest({
            uri: `me`,
            method: ApiClient.PATCH,
            expectedStatus: 201,
            body: userPF,
          });
        }

        await showModalMessage({
          type: 'success',
          title: 'Perfil',
          text: 'Dados modificados com sucesso',
        });
      } catch (err) {
        await showModalMessage({
          type: 'error',
          title: 'Perfil',
          text: 'Erro ao tentar modificar dados!',
        });
      } finally {
        setLoading(false);
      }
    },
    [cities, neighborhoods, dadosCep, responsePerfil, dataCpfFile, dataRgFile],
  );

  return (
    <MainPage>
      <Load inLoad={loading} />
      <Header>
        <LogoIcon />
        <NavBarHeader />
      </Header>
      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Perfil do usuário</h1>
            <span>Home | Perfil</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>
      <PageContainer>
        <PageContent>
          <Form ref={formRef} onSubmit={handleSubmit}>
            {user && !user?.cnpj ? (
              <>
                <WhiteBox column jCenter aCenter marginBottom="30px">
                  <div style={{ marginBottom: '48px' }}>
                    <FilePerfil
                      inputRef={avatarFileRef}
                      name="avatar"
                      link={
                        responsePerfil?.files?.find(
                          ({ name }) => name === 'foto_user',
                        )?.url
                      }
                    />
                  </div>
                  <Wrap>
                    <Input
                      name="cpf"
                      label="CPF"
                      defaultValue={maskString(
                        responsePerfil?.cpf,
                        '###.###.###-##',
                      )}
                      required
                      disabled
                    />
                    <Input
                      name="name"
                      label="Nome Civil/Nome Social"
                      defaultValue={responsePerfil?.name}
                      required
                    />
                    <Input
                      name="email"
                      label="E-mail"
                      defaultValue={responsePerfil?.email}
                      required
                      disabled
                    />
                  </Wrap>
                  <Wrap>
                    <Input
                      name="phone_mobile"
                      label="Celular"
                      defaultValue={maskString(
                        responsePerfil?.phone_mobile,
                        '(##) #####-####',
                      )}
                      required
                    />
                    <Input
                      name="rg"
                      label="RG/RNE"
                      defaultValue={responsePerfil?.rg}
                      required
                    />
                    <Input
                      name="birth_date"
                      label="Data de nascimento"
                      type="date"
                      defaultValue={responsePerfil?.birth_date}
                      required
                    />
                  </Wrap>
                  <Wrap>
                    <Input
                      name="cep"
                      label="CEP"
                      defaultValue={maskString(
                        responsePerfil?.cep,
                        '#####-###',
                      )}
                      onBlur={handleChangeCep}
                      required
                    />
                    <Input
                      name="state"
                      label="Estado"
                      defaultValue={
                        dadosCep
                          ? dadosCep?.uf
                          : responsePerfil?.city?.state?.name
                      }
                      disabled
                      required
                    />
                    <Input
                      name="city_uuid"
                      label="Cidade"
                      defaultValue={
                        dadosCep
                          ? dadosCep?.localidade
                          : responsePerfil?.city?.name
                      }
                      disabled
                      required
                    />
                  </Wrap>
                  <Wrap>
                    <Input
                      name="neighborhood_uuid"
                      label="Bairro"
                      defaultValue={
                        dadosCep
                          ? dadosCep?.bairro
                          : responsePerfil?.neighborhood?.name
                      }
                      disabled
                      required
                    />
                    <Input
                      name="street"
                      label="Rua"
                      defaultValue={
                        dadosCep ? dadosCep?.logradouro : responsePerfil?.street
                      }
                      disabled
                      required
                    />
                    <Input
                      autoComplete="on"
                      name="number"
                      label="Número"
                      defaultValue={responsePerfil?.number}
                      required
                    />
                  </Wrap>
                  <Wrap>
                    <LimiterInputWidth w33>
                      <Input
                        name="complement"
                        label="Complemento"
                        defaultValue={responsePerfil?.complement}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  <Wrap style={{ marginTop: 15 }}>
                    <BoxHeader style={{ marginBottom: 0 }}>
                      <span className="boxText">Documentos</span>
                    </BoxHeader>
                  </Wrap>
                  <Wrap>
                    <File
                      label="CPF"
                      attach={dataCpfFile}
                      textFile="Nenhum arquivo selecionado"
                      name="cpf_file"
                      inputRef={cpfFileRef}
                      onClick={handleCpfFile}
                      onChange={event => setDataCpfFile(event.target.files[0])}
                      link={
                        responsePerfil?.files?.find(
                          ({ name }) => name === 'cpf',
                        )?.url
                      }
                    />
                  </Wrap>
                  <Wrap>
                    <File
                      label="RG"
                      attach={dataRgFile}
                      textFile="Nenhum arquivo selecionado"
                      name="rg_file"
                      inputRef={rgFileRef}
                      onClick={handleRgFile}
                      onChange={event => setDataRgFile(event.target.files[0])}
                      link={
                        responsePerfil?.files?.find(({ name }) => name === 'rg')
                          ?.url
                      }
                    />
                  </Wrap>
                </WhiteBox>
                <ButtonBox>
                  <Button
                    btType="#4F4F4F"
                    action={() => history.goBack()}
                    label="Voltar"
                    full
                    width="150px"
                  />
                  <Button
                    label="Concluir"
                    btType="#26A2B1"
                    type="submit"
                    full
                    width="157px"
                    loading={loading}
                  />
                </ButtonBox>
              </>
            ) : (
              <>
                <WhiteBox column jCenter aCenter marginBottom="30px">
                  <div style={{ marginBottom: '48px' }}>
                    <FilePerfil
                      inputRef={avatarFileRef}
                      name="avatar"
                      link={
                        user?.files?.find(({ name }) => name === 'foto_branch')
                          ?.url
                      }
                    />
                  </div>
                  <Wrap>
                    <InputMask
                      name="cnpj"
                      label="CNPJ"
                      mask="99.999.999/9999-99"
                      maskChar=""
                      defaultValue={responsePerfil?.cnpj}
                      required
                      disabled
                    />
                    <Input
                      name="rate_social"
                      label="Razão Social"
                      defaultValue={responsePerfil?.company_name}
                      required
                    />
                    <Input
                      name="fantasy_name"
                      label="Nome Fantasia"
                      defaultValue={responsePerfil?.fantasy_name}
                      required
                    />
                  </Wrap>
                  <Wrap>
                    <InputMask
                      name="phone"
                      label="Telefone"
                      mask="(99) 9999-9999"
                      maskChar=""
                      defaultValue={responsePerfil?.phone}
                      required
                    />
                    <InputMask
                      name="cep"
                      label="CEP"
                      mask="99999-999"
                      maskChar=""
                      defaultValue={
                        responsePerfil?.cep ? responsePerfil?.cep : user?.cep
                      }
                      onBlur={handleChangeCep}
                      required
                    />
                    <Input
                      name="state"
                      label="Estado"
                      defaultValue={
                        dadosCep
                          ? dadosCep?.uf
                          : responsePerfil?.neighborhood?.city?.state?.name
                      }
                      readOnly
                      required
                      disabled
                    />
                  </Wrap>
                  <Wrap>
                    <Input
                      name="city_uuid"
                      label="Cidade"
                      defaultValue={
                        dadosCep
                          ? dadosCep?.localidade
                          : responsePerfil?.neighborhood?.city?.name
                      }
                      readOnly
                      required
                      disabled
                    />
                    <Input
                      name="neighborhood_uuid"
                      label="Bairro"
                      defaultValue={
                        dadosCep
                          ? dadosCep?.bairro
                          : responsePerfil?.neighborhood?.name
                      }
                      readOnly
                      required
                      disabled
                    />
                    <Input
                      name="street"
                      label="Rua"
                      defaultValue={
                        dadosCep ? dadosCep?.logradouro : responsePerfil?.street
                      }
                      readOnly
                      required
                      disabled
                    />
                  </Wrap>
                  <Wrap>
                    <LimiterInputWidth w33>
                      <Input
                        autoComplete="on"
                        name="number"
                        label="Número"
                        defaultValue={user?.number}
                        required
                      />
                    </LimiterInputWidth>
                    <LimiterInputWidth w33>
                      <Input
                        name="complement"
                        label="Complemento"
                        defaultValue={user?.complement}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  {/* <BoxHeader>
                    <span className="boxText">Dados do representante:</span>
                  </BoxHeader>
                  <Wrap>
                    <InputMask
                      name='cpf'
                      label="CPF"
                      mask="999.999.999-99"
                      maskChar=""
                      defaultValue={user?.representante?.cpf}
                      required
                    />
                    <Input
                      name='name'
                      label="Nome Civil/Nome Social"
                      defaultValue={user?.representante?.name}
                      required
                    />
                    <Input
                      name='email'
                      label="E-mail"
                      defaultValue={user?.representante?.email}
                      readOnly
                    />
                  </Wrap>
                  <Wrap>
                    <InputMask
                      name='phone_mobile'
                      label="Celular"
                      mask="(99) 99999-9999"
                      maskChar=""
                      defaultValue={user?.representante?.mobile_phone}
                      required
                    />
                    <Input
                      name='rg_rne'
                      label="RG/RNE"
                      defaultValue={user?.representante?.rg_rne}
                      required
                    />
                    <Input
                      name='birth_date'
                      label="Data de nascimento"
                      type="date"
                      defaultValue={user?.representante?.birth_date}
                      required
                    />
                  </Wrap>
                  <Wrap>
                    <LimiterInputWidth w33>
                      <Input
                        name="cargo"
                        label="Cargo"
                        defaultValue={user?.representante?.cargo}
                        // required
                        readOnly
                      />
                    </LimiterInputWidth>
                  </Wrap> */}
                </WhiteBox>
                <ButtonBox>
                  <Button
                    btType="#4F4F4F"
                    action={() => history.goBack()}
                    label="Voltar"
                    full
                    width="150px"
                  />
                  <Button
                    label="Concluir"
                    btType="#26A2B1"
                    type="submit"
                    full
                    width="157px"
                    loading={loading}
                  />
                </ButtonBox>
              </>
            )}
          </Form>
        </PageContent>
      </PageContainer>
      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </MainPage>
  );
}
