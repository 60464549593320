import React from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { Container, StyledInput } from './styles';

export default function CheckboxHtml({
  name,
  label,
  required,
  error,
  size = 15,
  questionTooltip,
  ...rest
}) {
  return (
    <Container>
      <StyledInput size={size} type="checkbox" name={name} {...rest} />
      <div style={{ marginLeft: 5 }}>
        {label && <label htmlFor={name}>{label}</label>}
        {required && <span className="danger">*</span>}
        {questionTooltip && (
          <button
            type="button"
            tooltip={questionTooltip}
            style={{ marginLeft: 5 }}
          >
            <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
          </button>
        )}
      </div>
    </Container>
  );
}
