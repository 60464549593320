import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '~/services/notification';
import api from '~/services/api';
import AuthActions from '../ducks/auth';
import ApiToken from '~/utils/ApiToken';

// const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));
// const getAuthData = (state) => state.auth.data;

export function* login(action) {
  try {
    const { data: post } = action;
    const { data } = yield call(api.post, '/login', post);

    ApiToken.saveToken(data.access_token);

    yield put(AuthActions.loginSuccess(data));

    showToastMessage({
      type: 'success',
      text: 'Olá seja bem-vindo!',
    });
  } catch (err) {
    yield put(AuthActions.loginFailure(err.response.data.error.message));
    if (
      err.response.data.error.message ===
      'Seu IP está bloqueado por exceder o limite de tentativas'
    ) {
      showToastMessage({
        type: 'error',
        text: 'Seu IP está bloqueado por exceder o limite de tentativas, tente novamente depois de 15 minutos!',
      });
    } else {
      showToastMessage({
        type: 'error',
        text: 'Erro ao logar',
      });
    }
  }
}

export function* refreshToken(action) {
  try {
    // const authData = yield select(getAuthData);
    const { data: token } = action;

    const { data } = yield call(api.post, '/refresh', null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    yield put(AuthActions.successToken(data));
  } catch (err) {
    yield put(AuthActions.failureToken(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: 'Erro ao logar',
    });
  }
}

export function* logout(action) {
  try {
    const { history } = action;
    yield put(AuthActions.logoutSuccess());
    history.push('/login');
    showToastMessage({
      type: 'success',
      text: 'Tchau! Até mais tarde!',
    });
  } catch (err) {
    yield put(
      AuthActions.logoutFailure('Ops! algo deu errado, tente novamente'),
    );
    showToastMessage({
      type: 'error',
      text: 'Ops! algo deu errado',
    });
  }
}
