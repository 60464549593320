import React, { useEffect, useRef } from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { useField } from '@unform/core';
import { Container } from './styles';

export default function Textarea({
  name,
  maxLength,
  label,
  required,
  margin,
  isRequired,
  requiredMessage,
  questionTooltip,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <Container margin={margin}>
      <label htmlFor={fieldName}>
        {label}
        {required && <span className="danger">*</span>}
        {questionTooltip && (
          <button
            type="button"
            tooltip={questionTooltip}
            style={{ marginLeft: 5 }}
          >
            <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
          </button>
        )}
      </label>
      <textarea
        name={fieldName}
        id={fieldName}
        ref={inputRef}
        maxLength={maxLength || undefined}
        defaultValue={defaultValue}
        required={isRequired}
        {...rest}
      />
      {isRequired && <span className="error">{requiredMessage}</span>}
      {!isRequired && error && <span className="error">{error}</span>}
    </Container>
  );
}
