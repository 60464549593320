import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import omitEmpty from 'omit-empty';
import { FiLoader } from 'react-icons/fi';
import { Container } from './styles';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage, showToastMessage } from '~/services/notification';
import { Wrap } from '~/pages/Usuario/Obra/styles';
import Button from '~/components/Button';
import { BoxHeader } from '~/pages/Usuario/styles';
import { Select } from '~/components/Forms';
import WhiteBox from '~/components/WhiteBox';

const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function ModalidadePagamento({
  locacaoEndpoint,
  locacaoUuid,
  viewData,
  indexesUndefined,
}) {
  const [modalidadePgtoList, setModalidadePgtoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [modalidadePgtoData, setModalidadePgtoData] = useState(
    !viewData ? null : omitEmpty(viewData || {}),
  );

  // refs
  const formRef = useRef();

  const getModalidadePagamentoList = useCallback(async () => {
    setLoading(true);
    try {
      const lista = await ApiClient.doRequest({
        uri: `modalidade-pagamento?paginate=false`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setModalidadePgtoList(
        lista?.data?.data?.map(({ uuid, name }) => ({
          value: uuid,
          label: name,
        })),
      );
    } catch (err) {
      showToastMessage({
        type: 'error',
        text: 'Falha ao buscar lista de Modalidade de Pagamento. Tente mais tarde!',
      });
      setModalidadePgtoList([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getModalidadePagamentoList();
  }, [getModalidadePagamentoList]);

  useEffect(() => {
    if (modalidadePgtoData) {
      formRef.current?.setFieldValue('modalidade_pagamento_uuid', {
        value: modalidadePgtoData?.uuid,
        label: modalidadePgtoData?.name,
      });
    }
  }, [loading, modalidadePgtoData]);

  async function handleSubmit() {
    try {
      if (!formData?.modalidade_pagamento_uuid) {
        await showModalMessage({
          type: 'error',
          text: 'Preencha os campos para salvar as informações',
        });
        return;
      }

      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri: `${locacaoEndpoint}/${locacaoUuid}`,
        method: ApiClient.PATCH,
        body: Object.assign(formData, indexesUndefined),
        expectedStatus: 201,
      });

      setModalidadePgtoData(resp?.data?.data?.modalidade_pagamento);
      await showModalMessage({
        type: 'success',
        text: 'Modalidade de Pagamento gravada com sucesso',
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao tentar salvar o Modalidade de Pagamento',
      });
    } finally {
      setLoading(false);
    }
  }

  function renderForm() {
    if (loading) {
      return (
        <WhiteBox column jCenter aCenter marginBottom="30px">
          <Wrap style={{ justifyContent: 'center' }}>
            <FiLoader size={20} className="icon-spin" />
          </Wrap>
        </WhiteBox>
      );
    }

    return (
      <WhiteBox column jCenter aCenter marginBottom="30px">
        <Wrap>
          <Container>
            <BoxHeader>
              <span className="boxText">Modalidade de Pagamento</span>
            </BoxHeader>

            <Form ref={formRef}>
              <Wrap style={{ alignItems: 'flex-end' }}>
                <Select
                  name="modalidade_pagamento_uuid"
                  label=""
                  placeholder="Selecione"
                  options={modalidadePgtoList}
                  style={selectCustomStyles}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      modalidade_pagamento_uuid: e?.value,
                    }))
                  }
                  required
                />
                <Button
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  onClick={() => handleSubmit()}
                />
              </Wrap>
            </Form>
          </Container>
        </Wrap>
      </WhiteBox>
    );
  }

  return renderForm();
}
