import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
import { format } from 'date-fns';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';

import omitEmpty from 'omit-empty';
import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  ChoiceButtonLocacao,
  Textarea,
  File,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ConditionalContainer,
  LimiterInputWidth,
  MultiInputContainer,
  ActionInputMulti,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import LocacaoDocs from '~/pages/Usuario/Locacoes/LocacaoDocs';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import ImpactoListActions from '~/store/ducks/impacto-list';
import EquipamentoFilmagemListActions from '~/store/ducks/equipamento-filmagem-list';
import SomFilmagemListActions from '~/store/ducks/som-filmagem-list';
import EfeitoEspecialListActions from '~/store/ducks/efeito-especial-list';
import FinalidadeBaseApoioListActions from '~/store/ducks/finalidade-base-apoio-list';
import LdEquipamentosSmcListActions from '~/store/ducks/ld-equipamentos-smc';
import LdBibliotecaListActions from '~/store/ducks/ld-biblioteca';
import LdMuseuListActions from '~/store/ducks/ld-museu';
import LdCasasCulturaListActions from '~/store/ducks/ld-casas-cultura';
import LdCentroCulturalListActions from '~/store/ducks/ld-centro-cultural';

import { Wrap, MainLoading } from './styles';

import Button from '~/components/Button';
import { showModalMessage } from '~/services/notification';
import Chat from '~/components/Chat/chat';
import Documents from '~/components/Documents/Documents';
import { useList } from '~/hooks/useFunction';
import {
  ModalidadePagamento,
  ObservacoesChat,
  ValorLocacao,
} from '~/components/Admin';
import LogoIcon from '~/components/LogoIcon';
import WorkStatusSwitch from '~/components/Admin/WorkStatusSwitch';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import {
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import { ActionButton } from '~/components/Forms/FormChoiceButton/styles';
import tenancy_env from '~/utils/MultiTenancyUtil';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const itensLocalFilmagemChoice = [
  'Arquivo Histórico Municipal',
  'Biblioteca Mário Andrade',
  'Casas de Cultura',
  'Central de Serviços do Pari',
  'Centro de Referência da Dança',
  'Centros Culturais',
  'Bibliotecas',
];

const EspacosDeCultura = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();
  const relatorioArteRef = useRef(null);

  // states
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseLocation, setResponseLocation] = useState();
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});

  // state choices
  const [localFilmagem, setLocalFilmagem] = useState(null);
  const [isolarLocal, setIsolarLocal] = useState(null);
  const [intervencaoArte, setIntervencaoArte] = useState(null);
  const [gerador, setGerador] = useState(null);
  const [blimpado, setBlimpado] = useState(null);
  const [geradorPortatil, setGeradorPortatil] = useState(null);
  const [baseLocal, setBaseLocal] = useState(null);
  const [captacaoSomDireto, setCaptacaoSomDireto] = useState(null);
  const [somFilmagem, setSomFilmagem] = useState(null);
  const [efeitosEspeciais, setEfeitosEspeciais] = useState(null);
  const [haveraAnimais, setHaveraAnimais] = useState(null);
  const [reservasVagas, setReservasVagas] = useState(null);

  // state files
  const [relatorioArteFile, setRelatorioArteFile] = useState(null);
  const [dataRelatorioArteFile, setDataRelatorioArteFile] = useState(null);

  // state outro fields
  const [showOutroEspacosField, setShowOutroEspacosField] = useState(false);
  const [showOutroEquipamentoField, setShowOutroEquipamentoField] =
    useState(false);
  const [showOutroFinalidadeField, setShowOutroFinalidadeField] =
    useState(false);
  const [showOutroSomFilmagemField, setShowOutroSomFilmagemField] =
    useState(false);

  // state show fields
  const [showDescrAltoImpactoLocalField, setShowDescrAltoImpactoLocalField] =
    useState(false);
  const [
    showDescrEquipamentoFilmagemField,
    setShowDescrEquipamentoFilmagemField,
  ] = useState(false);
  const [showDescrSomFilmagemField, setShowDescrSomFilmagemField] =
    useState(false);
  const [showSelectLdEspacos, setShowSelectLdEspacos] = useState(false);

  // states multi input
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // selectors
  const { data: impactoSelectList } = useSelector(state => state.impactoList);
  const { data: equipamentosSelectList } = useSelector(
    state => state.equipamentoFilmagemList,
  );
  const { data: finalidadeSelectList } = useSelector(
    state => state.finalidadeBaseApoioList,
  );
  const { data: somFilmagemSelectList } = useSelector(
    state => state.somFilmagemList,
  );
  const { data: efeitoEspecialSelectList } = useSelector(
    state => state.efeitoEspecialList,
  );
  const { data: ldEquipamentosSmcList } = useSelector(
    state => state.ldEquimentosSmcList,
  );
  const { data: ldBibliotecaList } = useSelector(
    state => state.ldBibliotecaList,
  );
  const { data: ldMuseuList } = useSelector(state => state.ldMuseuList);
  const { data: ldCentroCulturalList } = useSelector(
    state => state.ldCentroCulturalList,
  );
  const { data: ldCasasCulturaList } = useSelector(
    state => state.ldCasasCulturaList,
  );

  // effects
  useEffect(() => {
    dispatch(ImpactoListActions.showRequest());
    dispatch(EquipamentoFilmagemListActions.showRequest());
    dispatch(SomFilmagemListActions.showRequest());
    dispatch(EfeitoEspecialListActions.showRequest());
    dispatch(FinalidadeBaseApoioListActions.showRequest());
    dispatch(LdEquipamentosSmcListActions.showRequest());
    dispatch(LdBibliotecaListActions.showRequest());
    dispatch(LdMuseuListActions.showRequest());
    dispatch(LdCasasCulturaListActions.showRequest());
    dispatch(LdCentroCulturalListActions.showRequest());
  }, [dispatch]);

  const ldEspacosEquipamentosList = useList({
    url: `ld-espacos-equipamentos/equipamento/${formData?.ld_equipamentos_smc?.value}?paginate=false`,
  }).map(iee => ({ value: iee.uuid, label: iee.name }));

  // Busca dados da locação
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-espacos-cultura/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set temp variables
      const localFilmagemTmp = itensLocalFilmagemChoice.includes(
        respLoc?.ld_equipamentos_smc?.name,
      );
      const blimpadoTmp =
        respLoc?.locacao_default.blimpado === null
          ? null
          : respLoc?.locacao_default.blimpado === 1;

      const geradorPortatilTmp =
        respLoc?.locacao_default.gerador_portatil === null
          ? null
          : respLoc?.locacao_default.gerador_portatil === 1;

      const fileArte = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Relatório de Arte',
      );

      // set multi input
      setDatasFilmagemInputs(
        respLoc?.locacao_default?.dates_locacao_default?.map(
          ({ date, changes_to_approve }) => ({
            data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
            changes_to_approve,
          }),
        ),
      );

      setHorasFilmagemInputs(
        respLoc?.locacao_default?.hours_locacao_default?.map(
          ({ inicio, fim, changes_to_approve }) => ({
            inicio: inicio.substring(0, 5),
            fim: fim.substring(0, 5),
            changes_to_approve,
          }),
        ),
      );

      // set choices buttons
      setLocalFilmagem(localFilmagemTmp);
      setIsolarLocal(typeof respLoc?.locacao_default.fluxo_area === 'string');

      setIntervencaoArte(
        typeof respLoc?.locacao_default.desc_intervencao_arte === 'string' ||
          fileArte?.length > 0,
      );
      setBlimpado(blimpadoTmp);
      setGeradorPortatil(geradorPortatilTmp);
      setGerador(blimpadoTmp !== null || geradorPortatilTmp !== null);

      setBaseLocal(
        Object.values(respLoc?.locacao_default.ld_finalidade_base_apoio || [])
          .length > 0 ||
          typeof respLoc?.locacao_default.local_base_apoio === 'string' ||
          typeof respLoc?.locacao_default.dimensao_base_apoio === 'string' ||
          typeof respLoc?.locacao_default.quantidade_barracas === 'string',
      );
      setCaptacaoSomDireto(respLoc?.locacao_default?.captação_som_direto === 1);
      setSomFilmagem(
        Object.values(respLoc?.locacao_default.ld_som_filmagem || []).length >
          0,
      );
      setEfeitosEspeciais(
        Object.values(respLoc?.locacao_default.ld_efeitos_especiais || {})
          .length > 0,
      );
      setHaveraAnimais(
        typeof respLoc?.locacao_default.animais_em_cena === 'string',
      );

      setReservasVagas(
        Object.values(respLoc?.locacao_via_externa || []).length > 0,
      );

      // set campos 'Outro'
      setShowDescrAltoImpactoLocalField(
        typeof respLoc?.locacao_default?.descr_alto_impacto_local === 'string',
      );
      setShowOutroEspacosField(typeof respLoc?.outros_espacos === 'string');
      setShowOutroSomFilmagemField(
        typeof respLoc?.locacao_default?.outro_som_filmagem === 'string',
      );
      setShowOutroFinalidadeField(
        typeof respLoc?.locacao_default?.outro_finalidade === 'string',
      );
      setShowOutroEquipamentoField(
        typeof respLoc?.locacao_default?.outro_equipamento === 'string',
      );

      // set select default values
      formRef.current?.setFieldValue('ld_impacto_uuid', {
        label: respLoc?.locacao_default?.ld_impacto?.name,
        value: respLoc?.locacao_default?.ld_impacto?.uuid,
      });

      formRef.current?.setFieldValue('ld_equipamentos_smc', {
        label: respLoc?.ld_equipamentos_smc?.name,
        value: respLoc?.ld_equipamentos_smc?.uuid,
      });

      formRef.current?.setFieldValue('ld_equipamento_museu_cidade_sp_uuid', {
        label: respLoc?.ld_museu?.name,
        value: respLoc?.ld_museu?.uuid,
      });

      formRef.current?.setFieldValue('ld_equipamento_biblioteca_uuid', {
        label: respLoc?.ld_bibliotecas?.name,
        value: respLoc?.ld_bibliotecas?.uuid,
      });

      formRef.current?.setFieldValue('ld_equipamento_central_cultura_uuid', {
        label: respLoc?.ld_centros_culturais?.name,
        value: respLoc?.ld_centros_culturais?.uuid,
      });

      formRef.current?.setFieldValue('ld_equipamento_casa_cultura_uuid', {
        label: respLoc?.ld_casas_cultura?.name,
        value: respLoc?.ld_casas_cultura?.uuid,
      });

      formRef.current?.setFieldValue(
        'ld_espacos',
        respLoc?.ld_espacos?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('outros_espacos', respLoc?.outros_espacos);

      formRef.current?.setFieldValue(
        'ld_equipamento_filmagem',
        respLoc?.locacao_default?.ld_equipamento_filmagem.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'ld_finalidade_base_apoio',
        respLoc?.locacao_default?.ld_finalidade_base_apoio?.map(lfba => {
          return {
            label: lfba.name,
            value: lfba.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'ld_som_filmagem',
        respLoc?.locacao_default?.ld_som_filmagem?.map(lfba => {
          return {
            label: lfba.name,
            value: lfba.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_efeitos_especiais_uuid', {
        label: respLoc?.locacao_default?.ld_efeitos_especiais?.name,
        value: respLoc?.locacao_default?.ld_efeitos_especiais?.uuid,
      });

      setResponseLocation(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState]);

  useEffect(() => {
    visualizacaoDadosCB();
  }, [visualizacaoDadosCB]);

  // handles true/false
  function handleLocalFilmagemTrue(e) {
    e.preventDefault();
    setLocalFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleLocalFilmagemFalse(e) {
    e.preventDefault();
    setLocalFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleIsolarLocalTrue(e) {
    e.preventDefault();
    setIsolarLocal(true);
    setErrorsForm(state => ({
      ...state,
      isolarLocal: false,
    }));
  }

  function handleIsolarLocalFalse(e) {
    e.preventDefault();
    setIsolarLocal(false);
    setErrorsForm(state => ({
      ...state,
      isolarLocal: false,
    }));
  }

  function handleIntervencaoArteTrue(e) {
    e.preventDefault();
    setIntervencaoArte(true);
    setErrorsForm(state => ({
      ...state,
      intervencaoArte: false,
    }));
  }

  function handleIntervencaoArteFalse(e) {
    e.preventDefault();
    setIntervencaoArte(false);
    setErrorsForm(state => ({
      ...state,
      intervencaoArte: false,
    }));
  }

  function handleGeradorTrue(e) {
    e.preventDefault();
    setGerador(true);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorFalse(e) {
    e.preventDefault();
    setGerador(false);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorPortatilTrue(e) {
    e.preventDefault();
    setGeradorPortatil(true);
    setErrorsForm(state => ({
      ...state,
      geradorPortatil: false,
    }));
  }

  function handleGeradorPortatilFalse(e) {
    e.preventDefault();
    setGeradorPortatil(false);
    setErrorsForm(state => ({
      ...state,
      geradorPortatil: false,
    }));
  }

  function handleBlimpadorTrue(e) {
    e.preventDefault();
    setBlimpado(true);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBlimpadorFalse(e) {
    e.preventDefault();
    setBlimpado(false);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBaseLocalTrue(e) {
    e.preventDefault(e);
    setBaseLocal(true);
    setErrorsForm(state => ({
      ...state,
      baseLocal: false,
    }));
  }

  function handleBaseLocalFalse(e) {
    e.preventDefault(e);
    setBaseLocal(false);
    setErrorsForm(state => ({
      ...state,
      baseLocal: false,
    }));
  }

  function handleCaptacaoSomDiretoTrue(e) {
    e.preventDefault();
    setCaptacaoSomDireto(true);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleCaptacaoSomDiretoFalse(e) {
    e.preventDefault();
    setCaptacaoSomDireto(false);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleSomFilmagemTrue(e) {
    e.preventDefault(e);
    setSomFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleSomFilmagemFalse(e) {
    e.preventDefault(e);
    setSomFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleEfeitosEspeciaisTrue(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(true);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleEfeitosEspeciaisFalse(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(false);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleHaveraAnimaisTrue(e) {
    e.preventDefault(e);
    setHaveraAnimais(true);
    setErrorsForm(state => ({
      ...state,
      haveraAnimais: false,
    }));
  }

  function handleHaveraAnimaisFalse(e) {
    e.preventDefault(e);
    setHaveraAnimais(false);
    setErrorsForm(state => ({
      ...state,
      haveraAnimais: false,
    }));
  }

  function handleReservasVagasTrue(e) {
    e.preventDefault(e);
    setReservasVagas(true);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleReservasVagasFalse(e) {
    e.preventDefault(e);
    setReservasVagas(false);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  // handles files
  function handleRelatorioArteFile() {
    return relatorioArteRef.current.click();
  }

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  // Verification & Filter files
  let fileArte = [];
  fileArte = responseLocation?.locacao_default?.files?.filter(
    file => file.name === 'Relatório de Arte',
  );

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;

    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    if (name === 'relatorio_arte_file') {
      setRelatorioArteFile(base64.split('base64,'));
      setDataRelatorioArteFile(file);
      setErrorsForm(state => ({
        ...state,
        relatorioArteFile: false,
      }));
    }

    setFormData(state => ({ ...state, [name]: base64.split('base64,')[1] }));
  }

  // handles multi select
  function handleMultiSelectEspacos(e) {
    setShowOutroEspacosField(!!e?.find(x => x.label.startsWith('Outro(s)')));

    setFormData(state => ({
      ...state,
      ld_espacos: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectEquipamentos(e) {
    setShowOutroEquipamentoField(!!e?.find(x => x.label === 'Outro'));
    setShowDescrEquipamentoFilmagemField(
      e?.some(equip => equip.label?.includes(':')) || false,
    );

    setFormData(state => ({
      ...state,
      ld_equipamento_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectFinalidades(e) {
    setShowOutroFinalidadeField(!!e?.find(x => x.label === 'outro, descreva'));

    setFormData(state => ({
      ...state,
      ld_finalidade_base_apoio: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectSomFilmagem(e) {
    setShowOutroSomFilmagemField(!!e?.find(x => x.label === 'outro'));
    setShowDescrSomFilmagemField(
      e?.some(equip => equip.label?.includes('descrever')) || false,
    );

    setFormData(state => ({
      ...state,
      ld_som_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  // handles changes
  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        ld_impacto_uuid: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        // ld_equipamentos_smc: Yup.string()
        //   .typeError(defaultMsg)
        //   .required(defaultMsg),
        outros_equipamentos_smc: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outros-equipamentos-smc-selecionado',
            defaultMsg,
            value =>
              !(
                data?.ld_equipamentos_smc?.label === 'Outros' &&
                (value?.length <= 0 || !value)
              ),
          ),
        outros_espacos_equipamentos_smc: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outros-equipamentos-smc-selecionado',
            defaultMsg,
            value =>
              !(
                data?.ld_equipamentos_smc?.label === 'Outros' &&
                (value?.length <= 0 || !value)
              ),
          ),
        ld_equipamento_museu_cidade_sp_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'espacos-museu-cidade-sp-selecionado',
            defaultMsg,
            value =>
              !(
                data?.ld_equipamentos_smc?.label ===
                  'Espaços do Museu da cidade de São Paulo' &&
                (value?.length <= 0 || !value)
              ),
          ),
        ld_equipamento_biblioteca_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'bibliotecas-selecionado',
            defaultMsg,
            value =>
              !(
                data?.ld_equipamentos_smc?.label === 'Bibliotecas' &&
                (value?.length <= 0 || !value)
              ),
          ),
        ld_equipamento_central_cultura_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'central-cultura-selecionado',
            defaultMsg,
            value =>
              !(
                data?.ld_equipamentos_smc?.label === 'Centros Culturais' &&
                (value?.length <= 0 || !value)
              ),
          ),
        ld_equipamento_casa_cultura_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'casas-cultura-selecionado',
            defaultMsg,
            value =>
              !(
                data?.ld_equipamentos_smc?.label === 'Casas de Cultura' &&
                (value?.length <= 0 || !value)
              ),
          ),
        // ld_espacos: Yup.string()
        //   .typeError(defaultMsg)
        //   .test(
        //     'espacos-museu-cidade-sp-selecionado',
        //     defaultMsg,
        //     value =>
        //       !(
        //         (localFilmagem ||
        //           !itensLocalFilmagemChoice.includes(
        //             formData?.ld_equipamentos_smc?.label,
        //           )) &&
        //         formData?.ld_equipamentos_smc?.label !== 'Outros' &&
        //         (value?.length <= 0 || !value)
        //       ),
        //   ),
        // outros_espacos: Yup.string()
        //   .typeError(defaultMsg)
        //   .test(
        //     'outros-espacos-selecionado',
        //     defaultMsg,
        //     value =>
        //       !(
        //         (localFilmagem ||
        //           !itensLocalFilmagemChoice.includes(
        //             formData?.ld_equipamentos_smc?.label,
        //           )) &&
        //         formData?.ld_equipamentos_smc?.label !== 'Outros' &&
        //         showOutroEspacosField &&
        //         (value?.length <= 0 || !value)
        //       ),
        //   ),
        descr_alto_impacto_local: Yup.string()
          .typeError(defaultMsg)
          .test(
            'alto-impacto-local-selecionado',
            defaultMsg,
            value =>
              !(
                showDescrAltoImpactoLocalField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        endereco_local: Yup.string().typeError(defaultMsg).required(defaultMsg),
        tamanho_equipe_tecnica: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tamanho_do_elenco: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        descricao_cena: Yup.string().typeError(defaultMsg).required(defaultMsg),
        fluxo_area: Yup.string()
          .typeError(defaultMsg)
          .test(
            'isolar-local-selecionado',
            defaultMsg,
            value =>
              !(
                localFilmagem &&
                isolarLocal === true &&
                (!value || value?.length <= 0)
              ),
          ),
        desc_intervencao_arte: Yup.string()
          .typeError(defaultMsg)
          .test(
            'intervencao-arte-selecionado',
            defaultMsg,
            value =>
              !(intervencaoArte === true && (value?.length <= 0 || !value)),
          ),
        ld_equipamento_filmagem: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'ld-equipamento-filmagem-selecionado',
            defaultMsg,
            value => !(!value || value?.length <= 0),
          ),
        outro_equipamento: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-equipamento-selecionado',
            defaultMsg,
            value =>
              !(
                showOutroEquipamentoField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        descricoes_equipamento_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'descricoes-equipamento-filmagem-selecionado',
            defaultMsg,
            value =>
              !(
                showDescrEquipamentoFilmagemField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        ld_finalidade_base_apoio: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'base-local-selecionado',
            defaultMsg,
            value => !(baseLocal === true && (value?.length <= 0 || !value)),
          ),
        outro_finalidade: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-finalidade-selecionado',
            defaultMsg,
            value =>
              !(
                baseLocal === true &&
                showOutroFinalidadeField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        local_base_apoio: Yup.string()
          .typeError(defaultMsg)
          .test(
            'base-local-selecionado',
            defaultMsg,
            value => !(baseLocal === true && (value?.length <= 0 || !value)),
          ),
        ld_som_filmagem: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'som-filmagem-selecionado',
            defaultMsg,
            value => !(somFilmagem === true && (value?.length <= 0 || !value)),
          ),
        descricoes_som_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'descricoes-som-filmagem-selecionado',
            defaultMsg,
            value =>
              !(
                somFilmagem &&
                showDescrSomFilmagemField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        outro_som_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-som-filmagem-selecionado',
            defaultMsg,
            value =>
              !(
                somFilmagem &&
                showOutroSomFilmagemField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        ld_efeitos_especiais_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'efeitos-especiais-selecionado',
            defaultMsg,
            value =>
              !(efeitosEspeciais === true && (value?.length <= 0 || !value)),
          ),
        descricao_animais: Yup.string()
          .typeError(defaultMsg)
          .test(
            'havera-animais-selecionado',
            defaultMsg,
            value =>
              !(haveraAnimais === true && (value?.length <= 0 || !value)),
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }

      // Campos 'choice button' não selecionados
      if (
        itensLocalFilmagemChoice.includes(
          formData?.ld_equipamentos_smc?.label,
        ) &&
        localFilmagem === null
      ) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Local da filmagem' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          localFilmagem: true,
        }));
        return false;
      }

      if (isolarLocal === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Isolar Local' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          isolarLocal: true,
        }));
        return false;
      }

      if (intervencaoArte === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Intervenção de arte' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          intervencaoArte: true,
        }));
        return false;
      }

      if (intervencaoArte && !data?.relatorio_arte_file) {
        await showModalMessage({
          type: 'error',
          text: 'Arquivo Arte não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          relatorioArteFile: true,
        }));
        return false;
      }

      if (gerador === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Gerador' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          gerador: true,
        }));
        return false;
      }

      if (gerador && blimpado === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Blimpado' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          blimpado: true,
        }));
        return false;
      }

      if (gerador && geradorPortatil === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Gerador portátil' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          geradorPortatil: true,
        }));
        return false;
      }

      if (baseLocal === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Base de Apoio' no local não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          baseLocal: true,
        }));
        return false;
      }

      if (captacaoSomDireto === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Captação de Som direto' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          captacaoSomDireto: true,
        }));
        return false;
      }

      if (somFilmagem === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Som na filmagem' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          somFilmagem: true,
        }));
        return false;
      }

      if (efeitosEspeciais === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Efeitos Especiais' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          efeitosEspeciais: true,
        }));
        return false;
      }

      if (haveraAnimais === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Animais de cena' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          haveraAnimais: true,
        }));
        return false;
      }

      if (reservasVagas === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Reservas de vagas' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          reservasVagas: true,
        }));
        return false;
      }
    } catch (err) {
      console.log(err)
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }
    return true;
  }

  function formatDefaultData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      ld_impacto_uuid: data?.ld_impacto_uuid,
      ld_equipamento_filmagem: data?.ld_equipamento_filmagem,
      tamanho_equipe_tecnica: data?.tamanho_equipe_tecnica,
      tamanho_do_elenco: data?.tamanho_do_elenco,
      descricao_cena: data?.descricao_cena,
      especificidades: data?.especificidades,
    });

    // dados de choices
    const dataIsolarLocal = {
      fluxo_area: localFilmagem && isolarLocal ? data?.fluxo_area : null,
    };

    const dataIntervencaoArte = intervencaoArte
      ? {
          desc_intervencao_arte: data?.desc_intervencao_arte,
          relat_arte_base64: data?.relatorio_arte_file,
        }
      : { desc_intervencao_arte: null, relat_arte_base64: null };

    const dataGerador = gerador
      ? { blimpado, gerador_portatil: geradorPortatil }
      : { blimpado: null, gerador_portatil: null };

    const dataBaseLocal = baseLocal
      ? {
          ld_finalidade_base_apoio: data?.ld_finalidade_base_apoio,
          local_base_apoio: data?.local_base_apoio,
          dimensao_base_apoio: data?.dimensao_base_apoio,
          quantidade_barracas: data?.quantidade_barracas,
        }
      : {
          ld_finalidade_base_apoio: null,
          local_base_apoio: null,
          dimensao_base_apoio: null,
          quantidade_barracas: null,
        };

    const dataCaptacaoSomDireto = { captação_som_direto: captacaoSomDireto };

    const dataSomFilmagem = {
      ld_som_filmagem: somFilmagem ? data?.ld_som_filmagem : null,
    };

    const dataEfeitosEspeciais = {
      ld_efeitos_especiais_uuid: efeitosEspeciais
        ? data?.ld_efeitos_especiais_uuid
        : null,
    };

    const dataHaveraAnimais = {
      animais_em_cena: haveraAnimais ? data?.descricao_animais : null,
    };

    // dados de campos exibidos condicionalmente
    const dataDescrAltoImpactoLocal = {
      descr_alto_impacto_local: showDescrAltoImpactoLocalField
        ? data?.descr_alto_impacto_local
        : null,
    };

    const dataDescrEquipamentoFilmagem = {
      descricoes_equipamento_filmagem: showDescrEquipamentoFilmagemField
        ? data?.descricoes_equipamento_filmagem
        : null,
    };

    const dataDescrSomFilmagem = {
      descricoes_som_filmagem:
        somFilmagem && showDescrSomFilmagemField
          ? data?.descricoes_som_filmagem
          : null,
    };

    const dataOutroEquipamento = {
      outro_equipamento: showOutroEquipamentoField
        ? data?.outro_equipamento
        : null,
    };

    const dataOutroFinalidade = {
      outro_finalidade:
        baseLocal && showOutroFinalidadeField ? data?.outro_finalidade : null,
    };

    const dataOutroSomFilmagem = {
      outro_som_filmagem:
        somFilmagem && showOutroSomFilmagemField
          ? data?.outro_som_filmagem
          : null,
    };

    // format datas filmagem
    const datasFilmagem = datasFilmagemInputs
      .filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_filmagem));

    return Object.assign(
      dataNaoCondicional,
      { dates: datasFilmagem },
      { hours: horasFilmagemInputs },
      dataIsolarLocal,
      dataIntervencaoArte,
      dataGerador,
      dataBaseLocal,
      dataCaptacaoSomDireto,
      dataSomFilmagem,
      dataEfeitosEspeciais,
      dataHaveraAnimais,
      dataDescrAltoImpactoLocal,
      dataDescrEquipamentoFilmagem,
      dataOutroEquipamento,
      dataOutroFinalidade,
      dataDescrSomFilmagem,
      dataOutroSomFilmagem,
    );
  }

  function formatEspecificaData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      ld_equipamentos_smc_uuid: data?.ld_equipamentos_smc?.value,
      endereco_local: data?.endereco_local,
    });

    // dados de campos exibidos condicionalmente
    const dataOutrosEquipamentosSMC =
      data?.ld_equipamentos_smc?.label === 'Outros'
        ? {
            outros_equipamentos_smc: data?.outros_equipamentos_smc,
            outros_espacos_equipamentos_smc:
              data?.outros_espacos_equipamentos_smc,
          }
        : {
            outros_equipamentos_smc: null,
            outros_espacos_equipamentos_smc: null,
          };

    const dataEspacosMuseuCidadeSaoPaulo = {
      ld_equipamento_museu_cidade_sp_uuid:
        data?.ld_equipamentos_smc?.label ===
        'Espaços do Museu da cidade de São Paulo'
          ? data?.ld_equipamento_museu_cidade_sp_uuid
          : null,
    };

    const dataBibliotecas = {
      ld_equipamento_biblioteca_uuid:
        data?.ld_equipamentos_smc?.label === 'Bibliotecas'
          ? data?.ld_equipamento_biblioteca_uuid
          : null,
    };

    const dataCentrosCulturais = {
      ld_equipamento_central_cultura_uuid:
        data?.ld_equipamentos_smc?.label === 'Centros Culturais'
          ? data?.ld_equipamento_central_cultura_uuid
          : null,
    };

    const dataCasasCultura = {
      ld_equipamento_casa_cultura_uuid:
        data?.ld_equipamentos_smc?.label === 'Casas de Cultura'
          ? data?.ld_equipamento_casa_cultura_uuid
          : null,
    };

    const dataLdEspacos = {
      ld_espacos:
        (localFilmagem ||
          !itensLocalFilmagemChoice.includes(
            formData?.ld_equipamentos_smc?.label,
          )) &&
        formData?.ld_equipamentos_smc?.label !== 'Outros'
          ? data?.ld_espacos
          : null,
    };

    const dataOutrosEspacos = {
      outros_espacos:
        (localFilmagem ||
          !itensLocalFilmagemChoice.includes(
            formData?.ld_equipamentos_smc?.label,
          )) &&
        formData?.ld_equipamentos_smc?.label !== 'Outros' &&
        showOutroEspacosField
          ? data?.outros_espacos
          : null,
    };

    return Object.assign(
      dataNaoCondicional,
      dataOutrosEquipamentosSMC,
      dataEspacosMuseuCidadeSaoPaulo,
      dataBibliotecas,
      dataCentrosCulturais,
      dataCasasCultura,
      dataLdEspacos,
      dataOutrosEspacos,
    );
  }

  async function handleSubmit(data) {
    try {
      // files
      data.relatorio_arte_file = formData?.relatorio_arte_file;

      // selects
      data.ld_equipamento_filmagem = formData?.ld_equipamento_filmagem;
      data.ld_espacos = formData?.ld_espacos;
      data.ld_som_filmagem = formData?.ld_som_filmagem;
      data.ld_finalidade_base_apoio = formData?.ld_finalidade_base_apoio;
      data.ld_equipamentos_smc = formData?.ld_equipamentos_smc;

      if (!(await handleValidationFields(data))) {
        return;
      }

      const defaultData = formatDefaultData(data);
      const especificaData = formatEspecificaData(data);

      setLoading(true);

      const responseDefault = await ApiClient.doRequest({
        uri: 'locacao-default',
        method: ApiClient.POST,
        body: defaultData,
        expectedStatus: 201,
      });

      await ApiClient.doRequest({
        uri: 'locacao-espacos-cultura',
        method: ApiClient.POST,
        body: Object.assign(
          especificaData,
          { work_uuid: dadoState?.uuid },
          { locacao_default_uuid: responseDefault?.data?.data?.uuid },
        ),
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Filmagens em Espaços de Cultura',
        text: 'Locação gravada com sucesso',
      });

      // conclusao
      const redirections = [
        {
          url: '/solicitacao-nova-locacao/reservas-vagas-vinculado',
          next: reservasVagas,
        },
      ].filter(({ next }) => !!next);

      const [nextRedirect] = redirections;

      if (nextRedirect) {
        history.replace({
          pathname: nextRedirect.url,
          state: {
            uuid: dadoState?.uuid,
            redirections: redirections.filter(
              ({ url }) => url !== nextRedirect.url,
            ),
          },
        });
      } else {
        history.push('/home-user');
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Filmagens em Espaços de Cultura',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function renderLocacao() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                {!isAdminRequest && (
                  <>
                    <LocacaoDocs excludes={['precosFilmagensVia']} />
                  </>
                )}

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={dadoState?.viewData && true}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_impacto_uuid"
                      label="Você classifica sua filmagem como sendo:"
                      placeholder="Selecione"
                      required
                      questionTooltip="Clique aqui para saber mais"
                      questionOnClick={() => setOpenModalPorteFilmagem(true)}
                      options={impactoSelectList}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange('ld_impacto_uuid', value);
                        setShowDescrAltoImpactoLocalField(
                          value.label === 'alto impacto no local',
                        );
                      }}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                {/* <Wrap>
                   */}

                  {/* <Select
                    name="ld_equipamentos_smc"
                    label={
                      clientResources.labels
                        .solicitacaoNovaLocacaoLocacaoEspacosCultura
                        .equipamentosDaSmc
                    }
                    placeholder="Selecione"
                    required
                    options={ldEquipamentosSmcList}
                    style={selectCustomStyles}
                    onChange={value => {
                      setFormData(state => ({
                        ...state,
                        ld_equipamentos_smc: {
                          label: value.label,
                          value: value.value,
                        },
                      }));

                      setShowSelectLdEspacos(null);
                      formRef.current.clearField('ld_espacos');
                      formRef.current.clearField('outros_espacos');
                      setShowOutroEspacosField(false);
                      setFormData(state => ({
                        ...state,
                        ld_espacos: [],
                        outros_espacos: '',
                      }));
                      setTimeout(() => setShowSelectLdEspacos(true), 3000);
                    }}
                    isDisabled={dadoState?.viewData && true}
                  /> */}
                {/* </Wrap> */}

                <ConditionalContainer
                  show={formData?.ld_equipamentos_smc?.label === 'Outros'}
                >
                  <Wrap>
                    <Input
                      type="text"
                      name="outros_equipamentos_smc"
                      label="Outros Equipamentos SMC"
                      required
                      onChange={handleChange}
                      defaultValue={responseLocation?.outros_equipamentos_smc}
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>

                  <Wrap>
                    <Textarea
                      rows={3}
                      type="text"
                      name="outros_espacos_equipamentos_smc"
                      label="Quais espaços serão utilizados?"
                      required
                      onChange={handleChange}
                      defaultValue={
                        responseLocation?.outros_espacos_equipamentos_smc
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <ConditionalContainer
                  show={
                    formData?.ld_equipamentos_smc?.label ===
                    'Espaços do Museu da cidade de São Paulo'
                  }
                >
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_equipamento_museu_cidade_sp_uuid"
                        label="Equipamentos"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        required
                        options={ldMuseuList}
                        onChange={value => {
                          handleSelectChange(
                            'ld_equipamento_museu_cidade_sp_uuid',
                            value,
                          );
                        }}
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <ConditionalContainer
                  show={formData?.ld_equipamentos_smc?.label === 'Bibliotecas'}
                >
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_equipamento_biblioteca_uuid"
                        label="Equipamentos"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        required
                        options={ldBibliotecaList}
                        onChange={value => {
                          handleSelectChange(
                            'ld_equipamento_biblioteca_uuid',
                            value,
                          );
                        }}
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <ConditionalContainer
                  show={
                    formData?.ld_equipamentos_smc?.label === 'Centros Culturais'
                  }
                >
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_equipamento_central_cultura_uuid"
                      label="Equipamentos"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      required
                      options={ldCentroCulturalList}
                      onChange={value => {
                        handleSelectChange(
                          'ld_equipamento_central_cultura_uuid',
                          value,
                        );
                      }}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </ConditionalContainer>

                <ConditionalContainer
                  show={
                    formData?.ld_equipamentos_smc?.label === 'Casas de Cultura'
                  }
                >
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_equipamento_casa_cultura_uuid"
                      label="Equipamentos"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      required
                      options={ldCasasCulturaList}
                      onChange={value => {
                        handleSelectChange(
                          'ld_equipamento_casa_cultura_uuid',
                          value,
                        );
                      }}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </ConditionalContainer>

                <ConditionalContainer
                  show={itensLocalFilmagemChoice.includes(
                    formData?.ld_equipamentos_smc?.label,
                  )}
                >
                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Já possui local definido para filmagem?"
                        onClickTrue={handleLocalFilmagemTrue}
                        onClickFalse={handleLocalFilmagemFalse}
                        selected={localFilmagem}
                        required
                        isDisabled={dadoState?.viewData && true}
                        error={
                          errorsForm.localFilmagem === true && 'Obrigatório'
                        }
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                {/* <ConditionalContainer
                  show={
                    (localFilmagem ||
                      !itensLocalFilmagemChoice.includes(
                        formData?.ld_equipamentos_smc?.label,
                      )) &&
                    formData?.ld_equipamentos_smc?.label !== 'Outros'
                  }
                >
                  <ConditionalContainer show={showSelectLdEspacos}>
                    <Wrap>
                      <LimiterInputWidth w50>
                        <Select
                          name="ld_espacos"
                          label="Quais espaços serão utilizados?"
                          placeholder="Selecione"
                          options={ldEspacosEquipamentosList}
                          style={selectCustomStyles}
                          onChange={handleMultiSelectEspacos}
                          isMulti
                          required
                          isDisabled={dadoState?.viewData && true}
                        />
                      </LimiterInputWidth>

                      <ConditionalContainer show={showOutroEspacosField}>
                        <LimiterInputWidth>
                          <Input
                            type="text"
                            name="outros_espacos"
                            label="Descreva"
                            required
                            onChange={handleChange}
                            defaultValue={responseLocation?.outros_espacos}
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>
                      </ConditionalContainer>
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer> */}

                <ConditionalContainer show={showDescrAltoImpactoLocalField}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descr_alto_impacto_local"
                      label="Comente qual o desafio da sua produção para auxiliarmos em sua viabilização"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descr_alto_impacto_local
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="endereco_local"
                    label="Nome do Espaço"
                    required
                    onChange={handleChange}
                    defaultValue={responseLocation?.endereco_local}
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <DateFormMultiInput
                    label="Data da Filmagem"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    dateKeyName="data_filmagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Hora da Filmagem (Início - Fim)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="Incluir horário de montagem/desmontagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="number"
                    name="tamanho_equipe_tecnica"
                    label="Tamanho da Equipe Técnica"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.tamanho_equipe_tecnica
                    }
                    disabled={dadoState?.viewData && true}
                  />

                  <Input
                    type="number"
                    name="tamanho_do_elenco"
                    label="Tamanho do elenco"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.tamanho_do_elenco
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <Textarea
                    type="text"
                    name="descricao_cena"
                    label="Descrição da Cena"
                    onChange={handleChange}
                    rows="3"
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.descricao_cena
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Você precisa isolar alguma área e/ou interferir no fluxo dos frequentadores?"
                      onClickTrue={handleIsolarLocalTrue}
                      onClickFalse={handleIsolarLocalFalse}
                      selected={isolarLocal}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.isolarLocal === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={isolarLocal}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="fluxo_area"
                      label="Descrição do Local"
                      onChange={handleChange}
                      rows="2"
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default.fluxo_area
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Haverá alguma intervenção de arte no local?"
                      onClickTrue={handleIntervencaoArteTrue}
                      onClickFalse={handleIntervencaoArteFalse}
                      selected={intervencaoArte}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={
                        errorsForm.intervencaoArte === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={intervencaoArte}>
                  <Wrap>
                    <File
                      label="Relatório de Arte:"
                      attach={dataRelatorioArteFile}
                      textFile="Nenhum arquivo selecionado"
                      name="relatorio_arte_file"
                      inputRef={relatorioArteRef}
                      onChange={handleFileChange}
                      onClick={handleRelatorioArteFile}
                      required
                      error={
                        errorsForm.relatorioArteFile === true && 'Obrigatório'
                      }
                      link={fileArte?.length > 0 && fileArte[0].url}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </Wrap>

                  <Wrap>
                    <Textarea
                      type="text"
                      name="desc_intervencao_arte"
                      label="Descreva a(s) intervenção(ões)"
                      onChange={handleChange}
                      rows="2"
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default.desc_intervencao_arte
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_equipamento_filmagem"
                      label="Qual a sua estrutura e equipamentos de filmagem:"
                      placeholder="Selecione"
                      options={equipamentosSelectList}
                      style={selectCustomStyles}
                      onChange={handleMultiSelectEquipamentos}
                      isMulti
                      required
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={showOutroEquipamentoField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="outro_equipamento"
                        label="Outro Equipamento"
                        required
                        onChange={handleChange}
                        defaultValue={
                          responseLocation?.locacao_default?.outro_equipamento
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={showDescrEquipamentoFilmagemField}>
                  <Wrap>
                    <Textarea
                      label="Descreva a(s) extensão(ões)"
                      required
                      name="descricoes_equipamento_filmagem"
                      onChange={handleChange}
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descricoes_equipamento_filmagem
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Haverá gerador?"
                      onClickTrue={handleGeradorTrue}
                      onClickFalse={handleGeradorFalse}
                      selected={gerador}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.gerador === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={gerador}>
                    <LimiterInputWidth w25>
                      <ChoiceButtonLocacao
                        label="Blimpado/Silencioso ou Não-Blimpado?"
                        onClickTrue={handleBlimpadorTrue}
                        onClickFalse={handleBlimpadorFalse}
                        selected={blimpado}
                        required
                        isDisabled={dadoState?.viewData && true}
                        error={errorsForm.blimpado === true && 'Obrigatório'}
                        yesLabelValue="Blimpado"
                        noLabelValue="Não-Blimpado"
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={gerador}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Gerador portátil ou ficará sobre um veículo?"
                        onClickTrue={handleGeradorPortatilTrue}
                        onClickFalse={handleGeradorPortatilFalse}
                        selected={geradorPortatil}
                        required
                        isDisabled={dadoState?.viewData && true}
                        error={
                          errorsForm.geradorPortatil === true && 'Obrigatório'
                        }
                        yesLabelValue="Gerador portátil"
                        noLabelValue="Sobre um veículo"
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Precisará montar Base de Apoio no local?"
                      onClickTrue={handleBaseLocalTrue}
                      onClickFalse={handleBaseLocalFalse}
                      selected={baseLocal}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.baseLocal === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={baseLocal}>
                  <Wrap style={{ marginBottom: 0 }}>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_finalidade_base_apoio"
                        label="Selecione as Finalidades"
                        placeholder="Selecione"
                        options={finalidadeSelectList}
                        style={selectCustomStyles}
                        required
                        onChange={handleMultiSelectFinalidades}
                        isMulti
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                    <ConditionalContainer show={showOutroFinalidadeField}>
                      <Input
                        type="text"
                        name="outro_finalidade"
                        label="Outra Finalidade"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.outro_finalidade
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </ConditionalContainer>
                  </Wrap>

                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="local_base_apoio"
                        label="Descreva local da base de apoio"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.local_base_apoio
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>

                    <LimiterInputWidth w25 style={{ marginRight: 0 }}>
                      <Input
                        type="number"
                        step="0.01"
                        name="dimensao_base_apoio"
                        label="Descreva a dimensão estimada da área"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.dimensao_base_apoio
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>

                    <BoxHeader style={{ justifyContent: 'center', width: 50 }}>
                      <span className="boxText" style={{ color: '#828282' }}>
                        ou
                      </span>
                    </BoxHeader>

                    <LimiterInputWidth w25>
                      <Input
                        type="number"
                        name="quantidade_barracas"
                        label="Descreva a quantidade de barracas a serem montadas"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.quantidade_barracas
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Captação de som direto?"
                      onClickTrue={handleCaptacaoSomDiretoTrue}
                      onClickFalse={handleCaptacaoSomDiretoFalse}
                      selected={captacaoSomDireto}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={
                        errorsForm.captacaoSomDireto === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá som na filmagem?"
                      onClickTrue={handleSomFilmagemTrue}
                      onClickFalse={handleSomFilmagemFalse}
                      selected={somFilmagem}
                      required
                      error={errorsForm?.somFilmagem === true && 'Obrigatório'}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={somFilmagem}>
                  <Wrap>
                    <LimiterInputWidth w75>
                      <Select
                        name="ld_som_filmagem"
                        label="Sons de Filmagem"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        required
                        options={somFilmagemSelectList}
                        onChange={handleMultiSelectSomFilmagem}
                        isMulti
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <ConditionalContainer show={showOutroSomFilmagemField}>
                    <Wrap>
                      <Input
                        type="text"
                        name="outro_som_filmagem"
                        label="Outro Som de Filmagem"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.outro_som_filmagem
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </ConditionalContainer>

                  <ConditionalContainer show={showDescrSomFilmagemField}>
                    <Wrap>
                      <Textarea
                        label="Descreva a(s) característica(s)"
                        required
                        name="descricoes_som_filmagem"
                        onChange={handleChange}
                        rows="3"
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default
                            ?.descricoes_som_filmagem
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Efeitos Especiais"
                      onClickTrue={handleEfeitosEspeciaisTrue}
                      onClickFalse={handleEfeitosEspeciaisFalse}
                      selected={efeitosEspeciais}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={
                        errorsForm.efeitosEspeciais === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={efeitosEspeciais}>
                    <Select
                      name="ld_efeitos_especiais_uuid"
                      label="Efeitos Especiais"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      required
                      options={efeitoEspecialSelectList}
                      onChange={value => {
                        handleSelectChange('ld_efeitos_especiais_uuid', value);
                      }}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </ConditionalContainer>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá animais de cena?"
                      onClickTrue={handleHaveraAnimaisTrue}
                      onClickFalse={handleHaveraAnimaisFalse}
                      selected={haveraAnimais}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.haveraAnimais === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={haveraAnimais}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descricao_animais"
                      label="Descreva os animais"
                      onChange={handleChange}
                      rows="2"
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.animais_em_cena
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label=" Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.especificidades
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && reservasVagas ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="reservas_vagas_locacao"
                          label="Reservas de Vagas"
                          placeholder="Selecione"
                          options={responseLocation?.locacao_via_externa?.map(
                            ({
                              uuid,
                              address,
                              number,
                              neighborhood,
                              endpoint,
                            }) => ({
                              label: `${address}, ${number}, ${neighborhood}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('reservas_vagas_locacao', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {formData?.reservas_vagas_locacao && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            viewData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.reservas_vagas_locacao?.uuid,
                              endpoint: 'reservas-vagas-vinculado',
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de reserva de vagas em via próxima?"
                      onClickTrue={handleReservasVagasTrue}
                      onClickFalse={handleReservasVagasFalse}
                      selected={reservasVagas}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.reservasVagas === true && 'Obrigatório'}
                    />
                  )}
                </Wrap>

                {responseLocation?.documets?.length > 0 && isAdminRequest && (
                  <Wrap>
                    <Documents
                      documents={responseLocation?.documets}
                      uuid={responseLocation.uuid}
                    />
                  </Wrap>
                )}

                {responseLocation?.created_at && isAdminRequest && (
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="created_at"
                        label="Data de criação"
                        defaultValue={format(
                          new Date(responseLocation?.created_at),
                          'dd/MM/yyyy HH:mm',
                        )}
                        disabled
                      />
                    </LimiterInputWidth>
                  </Wrap>
                )}
              </WhiteBox>

              <Chat
                uuid={responseLocation?.locacao_default?.uuid}
                chatKeyFilter="locacao_default"
                locacaoNameView={responseLocation?.name_view}
              />

              {responseLocation?.valor_locacao && isAdminRequest && (
                <ValorLocacao
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.valor_locacao}
                  indexesUndefined={{ ld_espacos: [] }}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ModalidadePagamento
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.modalidade_pagamento}
                  indexesUndefined={{ ld_espacos: [] }}
                />
              )}

              {responseLocation && isAdminRequest && (
                <WorkStatusSwitch
                  data={{
                    entity: 'locacao-default',
                    uuid: responseLocation?.locacao_default?.uuid,
                  }}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ObservacoesChat workUuid={responseLocation?.work_uuid} />
              )}

              {dadoState?.uuid && !dadoState.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  disable={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}

              {Object.keys(responseLocation || {}).length > 0 && (
                <Link
                  to={{
                    pathname: 'redireciona-endpoint',
                    search: `query=${encodeURIComponent(
                      CryptoJS.AES.encrypt(
                        JSON.stringify({
                          is_admin: isAdminRequest,
                          uuid: responseLocation?.uuid,
                          endpoint: responseLocation?.endpoint,
                          mode: 'edit',
                          nome_projeto: responseLocation?.titulo_obra,
                        }),
                        tenancy_env('REACT_APP_API_BASE_URL'),
                      ).toString(),
                    )}`,
                  }}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    width: '100%',
                    maxWidth: '140px',
                    position: 'relative',
                    bottom: '35px',
                  }}
                >
                  <ActionButton
                    style={{
                      backgroundColor: '#828282',
                    }}
                  >
                    Editar
                  </ActionButton>
                </Link>
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Filmagens em Espaços de Cultura</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Filmagens
                em Espaços de Cultura
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderLocacao()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
};

export default EspacosDeCultura;
