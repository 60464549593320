import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '../../services/notification';

import api, { makeOptions } from '../../services/api';
import WorkTypeListActions from '../ducks/work-type-list';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));
// const getAuthData = (state) => state.auth.data;

export function* fetch() {
  try {
    // const authData = yield select(getAuthData);
    const url = `/work-type?paginate=false`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data, ...pagination } = responseData;
    yield put(WorkTypeListActions.success(data, pagination));
  } catch (err) {
    yield put(WorkTypeListActions.failure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* fetchSelect() {
  try {
    // const authData = yield select(getAuthData);
    const url = `/work-type?paginate=false`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data } = responseData;
    const list = data.map(({ uuid, name }) => ({
      value: uuid,
      label: name,
    }));

    yield put(WorkTypeListActions.showSuccess(list));
  } catch (err) {
    yield put(WorkTypeListActions.showFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}
