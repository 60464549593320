import React, {Component} from 'react';
import ReactLoading from 'react-loading';
import {ContainerLoad} from "~/components/Load/styles";


export default function Load({inLoad = false}) {

  if (!inLoad) {
    return (
      <></>
    );
  }

  return (
    <ContainerLoad>
      <ReactLoading type='spinningBubbles'/>
    </ContainerLoad>
  );
}
