import React, { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { ItemAside } from '~/components/global';

// Styles
import {
  Logotipo,
  Items,
  LogOut,
  EscolhaAcesso,
  Aside as StyledAside,
} from './styles';

// Images svg
import { ReactComponent as Dashboard } from '~/assets/images/icons/aside/dashboard.svg';
import { ReactComponent as DashboardBlueberry } from '~/assets/images/icons/aside/dashboard-blueberry.svg';
import { ReactComponent as Usuarios } from '~/assets/images/icons/aside/usuarios.svg';
import { ReactComponent as UsuariosBlueberry } from '~/assets/images/icons/aside/usuarios-blueberry.svg';

// Icons

import { useAuth } from '~/hooks/auth';
import { ThemeContext } from '~/styles/ThemeProvider';
import { showToastMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';
import clientResources from '~/data/clientResources';

export default function MenuAside() {
  const url = useLocation();
  const { signOut } = useAuth();
  const [counters, setCounters] = useState(undefined);

  const pathnameUrl = url.pathname;
  const retorno = pathnameUrl.split('/');

  const { theme } = useContext(ThemeContext);

  function logOut() {
    signOut();
  }

  useEffect(() => {
    const fetchCounters = async () => {
      try {
        const result = await ApiClient.doRequest({
          uri: `counter-menu`,
          method: ApiClient.GET,
          expectedStatus: 200,
        });

        setCounters(result.data);
      } catch (error) {
        showToastMessage({
          type: 'error',
          text: error.message,
        });
      }
    };

    fetchCounters();
  }, []);

  return (
    <StyledAside bgTheme={theme.side_bar_color}>
      <Logotipo
        src={
          theme.system_logo.url ||
          clientResources.images.system.spFilmComissionLogo
        }
        alt="Logo do Sistema"
        className="logo"
      />
      <Items>
        <>
          <ItemAside
            active={retorno[1] === 'dashboard' ? '' : 'disable'}
            route="/"
            Icon={retorno[1] === 'dashboard' ? DashboardBlueberry : Dashboard}
            text="Home"
          />
          <ItemAside
            active={retorno[1] === 'filiais' ? '' : 'disable'}
            route="/obras"
            Icon={retorno[1] === 'filiais' ? DashboardBlueberry : Dashboard}
            text="Obras"
            counter={counters?.work}
          />
          <ItemAside
            active={retorno[1] === 'locacoes' ? '' : 'disable'}
            route="/locacoes"
            Icon={retorno[1] === 'locacoes' ? DashboardBlueberry : Dashboard}
            text="Locações"
            counter={counters?.locacao}
          />
          <ItemAside
            active={retorno[1] === 'visitas-tecnicas' ? '' : 'disable'}
            route="/visitas-tecnicas"
            Icon={
              retorno[1] === 'visitas-tecnicas' ? DashboardBlueberry : Dashboard
            }
            text="Visitas Técnicas"
            counter={counters?.visita_tecnica}
          />
          <ItemAside
            active={retorno[1] === 'documentos-chat' ? '' : 'disable'}
            route="/documentos-chat"
            Icon={
              retorno[1] === 'documentos-chat' ? DashboardBlueberry : Dashboard
            }
            counter={counters?.documentos_chat}
            text="Documentos Chat"
          />
          { clientResources.tenant !== 'ilhacine' && (
            <ItemAside
              active={retorno[1] === 'ciencia-filmagem' ? '' : 'disable'}
              route="/ciencia-filmagem"
              Icon={
                retorno[1] === 'ciencia-filmagem' ? DashboardBlueberry : Dashboard
              }
              text="Ciencia de Filmagem Interna"
              counter={counters?.ciencia_filmagem_interna}
            />
          )}
          <ItemAside
            active={retorno[1] === 'alteracoes-solicitadas' ? '' : 'disable'}
            route="/alteracoes-solicitadas"
            Icon={
              retorno[1] === 'alteracoes-solicitadas'
                ? DashboardBlueberry
                : Dashboard
            }
            text="Alterações Solicitadas"
            counter={counters?.changes_to_approve}
          />
          <ItemAside
            active={retorno[1] === 'cartas-de-apoio' ? '' : 'disable'}
            route="/cartas-de-apoio"
            Icon={
              retorno[1] === 'cartas-de-apoio' ? DashboardBlueberry : Dashboard
            }
            counter={counters?.carta_apoio}
            text="Cartas de Apoio"
          />
          <ItemAside
            active={retorno[1] === 'avisos' ? '' : 'disable'}
            route="/avisos"
            Icon={retorno[1] === 'avisos' ? DashboardBlueberry : Dashboard}
            text="Avisos"
          />
          <ItemAside
            active={retorno[1] === 'equipes' ? '' : 'disable'}
            route="/equipes"
            colorTheme={theme.text_color}
            Icon={retorno[1] === 'equipes' ? DashboardBlueberry : Dashboard}
            text="Equipes"
          />
          <ItemAside
            active={retorno[1] === 'relatorios' ? '' : 'disable'}
            route="/relatorios"
            Icon={retorno[1] === 'relatorios' ? DashboardBlueberry : Dashboard}
            text="Relatórios"
          />
          <ItemAside
            active={retorno[1] === 'usuarios' ? '' : 'disable'}
            route="/usuarios"
            Icon={retorno[1] === 'usuarios' ? UsuariosBlueberry : Usuarios}
            text="Usuários"
          />
          {/* <ItemAside
            active={retorno[1] === 'agenda' ? '' : 'disable'}
            route="/agenda"
            Icon={retorno[1] === 'agenda' ? UsuariosBlueberry : Usuarios}
            text="Agenda"
          /> */}
          {/* <ItemAside
            active={retorno[1] === 'ferramentas' ? '' : 'disable'}
            route="/ferramentas"
            Icon={retorno[1] === 'ferramentas' ? UsuariosBlueberry : Usuarios}
            text="Ferramentas"
          /> */}

          <ItemAside
            active={retorno[1] === 'gerenciar-equipe' ? '' : 'disable'}
            route="/gerenciamento-equipe"
            Icon={retorno[1] === 'ferramentas' ? UsuariosBlueberry : Usuarios}
            text="Gerenciar equipe"
          />
          <ItemAside
            active={retorno[1] === 'logs' ? '' : 'disable'}
            route="/relatorio-login"
            Icon={retorno[1] === 'ferramentas' ? UsuariosBlueberry : Usuarios}
            text="Logs"
          />
          <ItemAside
            active={retorno[1] === 'tema' ? '' : 'disable'}
            route="/tema"
            Icon={retorno[1] === 'ferramentas' ? UsuariosBlueberry : Usuarios}
            text="Tema"
          />
          <ItemAside
            active={
              retorno[1] === 'manutencao-videos-tutoriais' ? '' : 'disable'
            }
            route="/manutencao-videos-tutoriais"
            Icon={retorno[1] === 'ferramentas' ? UsuariosBlueberry : Usuarios}
            text="Vídeos Tutoriais"
          />

          <EscolhaAcesso to="/escolha-acesso">
            {/* <VscKey size={18} style={{ color: theme.text_color }} /> */}
            <span style={{ color: theme.text_color }}>Escolha Acesso</span>
          </EscolhaAcesso>

          <LogOut
            onClick={() => {
              logOut();
            }}
          >
            {/* <FiLogOut size={18} style={{ color: theme.text_color }} /> */}
            <span style={{ color: theme.text_color }}>Sair</span>
          </LogOut>
        </>
      </Items>
    </StyledAside>
  );
}
