import React, { useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-modal';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { format } from 'date-fns';

import { FiLoader } from 'react-icons/fi';
import { BsFillEyeFill, BsPlusCircleFill } from 'react-icons/bs';
import { FaClipboardCheck, FaClipboardList, FaPen } from 'react-icons/fa';
import './styles.css';

import { Input } from '~/components/Forms';

import { PageContainer, TableHeader } from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import {
  Wrap,
  WrapButtons,
  ModalContent,
  WrapModal,
  overlayStyle,
  contentStyle,
  ModalButton,
  ChoiceButtons,
} from './styles';

import Button from '~/components/Button';
import { showModalMessage } from '~/services/notification';

import ApiClient from '~/utils/ApiClient';

import { useCurrentUser } from '~/hooks/currentUser';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export default function SolicitacaoObras() {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [work, setWork] = useState([]);
  const [workApproved, setWorkApproved] = useState([]);
  const [workUuid, setWorkUuid] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [loadingTrue, setLoadingTrue] = useState(false);
  const [loadingFalse, setLoadingFalse] = useState(false);

  const { user } = useCurrentUser();

  const handleSubmit = useCallback(async (data, { reset }) => {
    setLoading(true);

    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        protocol: Yup.string()
          .typeError('Campo obrigatório')
          .required('Campo obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const resp = await ApiClient.doRequest({
        uri: `work-search/${data?.protocol} `,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setWork(resp.data.data);
      // reset();
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Favor preencha dos campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const handleApplyApprove = useCallback(async () => {
    try {
      if (user) {
        if (user?.cnpj) {
          await ApiClient.doRequest({
            uri: `work-user-approved`,
            method: ApiClient.POST,
            body: { work_uuid: workUuid, branch_uuid: user?.uuid },
            expectedStatus: 201,
          });
        } else {
          await ApiClient.doRequest({
            uri: `work-user-approved`,
            method: ApiClient.POST,
            body: { work_uuid: workUuid },
            expectedStatus: 201,
          });
        }
      }
      await showModalMessage({
        type: 'success',
        text: 'Enviado com sucesso!',
      });
      setOpenModal(false);
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao enviar dados para aprovação!',
      });
    } finally {
      setLoadingTrue(false);
    }
  }, [user, workUuid]);

  const handleWorksApproved = useCallback(async () => {
    setLoading(true);

    let resp;
    try {
      if (user) {
        if (user?.cnpj) {
          resp = await ApiClient.doRequest({
            uri: `approved-works-by-branch/${user?.uuid}`,
            method: ApiClient.GET,
            expectedStatus: 200,
          });
        } else {
          resp = await ApiClient.doRequest({
            uri: `approved-works-by-user`,
            method: ApiClient.GET,
            expectedStatus: 200,
          });
        }
      }

      setWorkApproved(resp.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [user]);

  return (
    <>
      {/* <Header>
        <LogoIcon />
        <NavBarHeader />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Acompanhamento de obras</h1>
            <span>Home | Obras</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      <PageContainer>
        <PageContent> */}
      <WhiteBox column aCenter>
        {/* <BoxHeader>
          <span className="boxText">Acompanhamento de obras</span>
        </BoxHeader> */}
        <Tabs style={{ width: '100%' }}>
          <TabList style={{ border: 0 }}>
            <Tab>Solicitação de obra</Tab>
            <Tab onClick={() => handleWorksApproved()}>Acessos aprovados</Tab>
          </TabList>

          <TabPanel>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Wrap>
                <Input name="protocol" label="Buscar obra" />
                <WrapButtons>
                  <Button
                    type="submit"
                    btType="#26A2B1"
                    label="Buscar"
                    full
                    width="170px"
                  />
                </WrapButtons>
              </Wrap>
            </Form>
            <table style={{ width: '100%' }}>
              <TableHeader style={{ justifyContent: 'space-evenly' }}>
                <tr>
                  <th style={{ paddingLeft: 10 }}>Data de Cadastro</th>
                  <th>Nº da Obra</th>
                  <th>Nome</th>
                  <th>Produtor</th>
                  <th>Status</th>
                  <th>
                    {
                      clientResources.labels.homeUser
                        .responsavelSpFilmColumnObrasTable
                    }
                  </th>
                  <th>Produtora</th>
                  <th>Solicitar</th>
                </tr>
              </TableHeader>
              <tbody style={{ width: '100%' }}>
                {!loading && Object.values(work || []).length <= 0 && (
                  <span>Nenhum registro encontrado</span>
                )}
                {loading ? (
                  <FiLoader size={20} className="icon-spin" />
                ) : (
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      {work[0] &&
                        format(
                          new Date(work[0]?.created_at?.replace(' ', 'T')),
                          'dd/MM/yyyy',
                        )}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {work[0] && work[0]?.protocol}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {work[0] && work[0]?.titulo_obra}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {work[0] && work[0]?.director?.name}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {work[0] && work[0]?.work_status?.name}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {work[0] &&
                        work[0]?.contact_user?.user_personal_data?.name}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {work[0] &&
                        work[0]?.production_coodination_user?.user_personal_data
                          ?.name}
                    </td>
                    {work[0] && (
                      <td
                        className="buttons"
                        style={{
                          textAlign: 'unset',
                          justifyContent: 'space-evenly',
                          display: 'flex',
                        }}
                      >
                        <span
                          tooltip="Solicitar acesso!"
                          style={{ cursor: 'pointer' }}
                        >
                          <div>
                            <BsPlusCircleFill
                              color="#4f4f4f"
                              size={24}
                              onClick={async () => {
                                if (
                                  work[0]?.approve_requests?.some(
                                    wrk => wrk?.user?.email === user?.email,
                                  )
                                ) {
                                  await showModalMessage({
                                    type: 'warning',
                                    title: 'Atenção',
                                    text: 'O acesso a esta obra já foi solicitado. Confira se ela foi aprovada clicando na aba "Acessos aprovados"',
                                  });
                                  return;
                                }

                                setWorkUuid(work[0]?.uuid);
                                setOpenModal(true);
                              }}
                            />
                          </div>
                        </span>
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </TabPanel>

          <TabPanel>
            <table style={{ width: '100%' }}>
              <TableHeader style={{ justifyContent: 'space-evenly' }}>
                <tr>
                  <th style={{ paddingLeft: 10 }}>Data de Cadastro</th>
                  <th>Nº da Obra</th>
                  <th>Nome</th>
                  <th>Produtor</th>
                  <th>Status</th>
                  <th>
                    {
                      clientResources.labels.homeUser
                        .responsavelSpFilmColumnObrasTable
                    }
                  </th>
                  <th>Produtora</th>
                  <th>Visualizar</th>
                </tr>
              </TableHeader>
              <tbody style={{ width: '100%' }}>
                {!loading && Object.values(workApproved || []).length <= 0 && (
                  <span>Nenhum registro encontrado</span>
                )}
                {loading ? (
                  <FiLoader size={20} className="icon-spin" />
                ) : (
                  <>
                    {workApproved &&
                      workApproved?.map(item => (
                        <tr>
                          <td style={{ textAlign: 'center' }}>
                            {format(
                              new Date(item?.created_at.replace(' ', 'T')),
                              'dd/MM/yyyy',
                            )}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {item?.protocol}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {item?.titulo_obra}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {item?.director?.name}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {item?.work_status?.name}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {item?.contact_user?.user_personal_data?.name}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {
                              item?.production_coodination_user
                                ?.user_personal_data?.name
                            }
                          </td>
                          <td
                            className="buttons"
                            style={{
                              textAlign: 'unset',
                              justifyContent: 'space-evenly',
                              display: 'flex',
                            }}
                          >
                            <span tooltip="Mais detalhes...">
                              <Link
                                to={{
                                  pathname: '/obra-detalhe',
                                  state: { dados: item },
                                }}
                              >
                                <BsFillEyeFill color="#4f4f4f" size={24} />
                              </Link>
                            </span>
                            <span tooltip="Editar">
                              <Link
                                to={{
                                  pathname: '/obra/edit',
                                  state: { item },
                                }}
                                style={{ margin: 2 }}
                              >
                                <FaPen color="#4f4f4f" size={20} />
                              </Link>
                            </span>
                            {item?.work_status?.name === 'Finalizado' &&
                              !item?.pesquisa_uuid && (
                                <span tooltip="Realizar Pesquisa Socioeconômica...">
                                  <Link
                                    to={{
                                      pathname:
                                        '/pesquisas/pesquisa-socioeconomica',
                                      state: {
                                        dados: item,
                                        nome_projeto: item?.director?.name,
                                      },
                                    }}
                                  >
                                    <FaClipboardList
                                      color="#4f4f4f"
                                      size={24}
                                    />
                                  </Link>
                                </span>
                              )}

                            {item?.pesquisa_uuid && (
                              <span tooltip="Visualizar Pesquisa Socioeconômica...">
                                <Link
                                  to={{
                                    pathname:
                                      '/pesquisas/pesquisa-socioeconomica',
                                    state: {
                                      viewData: { uuid: item?.pesquisa_uuid },
                                      nome_projeto: item?.director?.name,
                                    },
                                  }}
                                >
                                  <FaClipboardCheck color="#4f4f4f" size={24} />
                                </Link>
                              </span>
                            )}
                          </td>
                          {/* <Documents documents={data.documents} uuid={data.uuid} /> */}
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </WhiteBox>
      {/* </PageContent>
      </PageContainer> */}

      <Modal
        ariaHideApp={false}
        onRequestClose={() => setOpenModal(false)}
        isOpen={openModal}
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <ModalButton onClick={() => setOpenModal(false)}>X</ModalButton>
        <ModalContent>
          <h1 style={{ marginBottom: 10, textAlign: 'center' }}>
            Deseja solicitar acesso?
          </h1>
          <ChoiceButtons>
            <Button
              btType="#4f4f4f"
              action={() => {
                handleApplyApprove(false);
                setLoadingFalse(true);
              }}
              label="Não"
              full
              loading={loadingFalse}
              width="170px"
            />
            <Button
              btType="#26A2B1"
              action={() => {
                handleApplyApprove(true);
                setLoadingTrue(true);
              }}
              label="Sim"
              full
              loading={loadingTrue}
              width="170px"
            />
          </ChoiceButtons>
        </ModalContent>
      </Modal>

      {/* <PageFooter>
        <img src={logoSpcine} alt="logo spcine" />
        <img src={logosp} alt="logo sp" />
      </PageFooter> */}
    </>
  );
}
