import styled from 'styled-components';

export const WhiteBox = styled.div`
  color: #000;
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ aCenter }) => (aCenter ? 'center' : 'flex-start')};
  justify-content: ${({ jCenter }) => (jCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? [maxWidth] : '100%')};
  max-height: ${({ maxHeight }) => (maxHeight ? [maxHeight] : '100%')};
  padding: 40px;
  background-color: ${({ bgTheme }) => bgTheme || '#ffffff'};
  border-radius: 10px !important;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  ${props => props.marginBottom && 'margin-bottom: 16px;'};
  margin-top: ${({ marginTop }) => (marginTop ? [marginTop] : 'unset')};
  ${props => props.cursorPointer && 'cursor: pointer;'};
  ${({ bottom }) => bottom && `bottom: ${bottom}px !important;`};

  .icon-spin {
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;
