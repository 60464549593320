import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '~/services/notification';

import api, { makeOptions } from '~/services/api';
import UserAddressActions from '../ducks/users-address';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));

export function* create(action) {
  try {
    const { data: post } = action;
    const { data } = yield call(
      api.post,
      '/user-address',
      post,
      makeOptions(dataLocal.auth.data),
    );

    yield put(UserAddressActions.createSuccess(data));

    showToastMessage({
      type: 'success',
      text: 'Endereço de usuário cadastrado com sucesso',
    });
  } catch (err) {
    yield put(
      UserAddressActions.createFailure(err.response.data.error.message),
    );
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* show(action) {
  try {
    const { uuid } = action;
    const { data } = yield call(
      api.get,
      `/user-address/${uuid}`,
      makeOptions(dataLocal.auth.data),
    );
    yield put(UserAddressActions.showSuccess(data));
  } catch (err) {
    yield put(UserAddressActions.showFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* edit(action) {
  try {
    const { data: post } = action;
    const { data } = yield call(
      api.patch,
      `/user-address/${post.uuid}`,
      post,
      makeOptions(dataLocal.auth.data),
    );

    yield put(UserAddressActions.editSuccess(data));
    showToastMessage({
      type: 'success',
      text: 'Informações atualizadas com sucesso',
    });
  } catch (err) {
    yield put(UserAddressActions.editFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* destroy(action) {
  try {
    const { uuid } = action;

    const { data: response } = yield call(
      api.delete,
      `user-address/${uuid}`,
      makeOptions(dataLocal.auth.data),
    );

    showToastMessage({
      type: 'success',
      text: 'Endereço de usuário removido com sucesso',
    });
    yield put(UserAddressActions.deleteSuccess(response));
  } catch (err) {
    yield put(
      UserAddressActions.deleteFailure('Erro ao se comunicar com a API.'),
    );
  }
}
