export const dataSlider = [
  {
    id: 1,
    title: 'Catálogo de Profissionais e Serviços',
    text: 'Busque e ofereça serviços para obras audiovisuaisonline',
  },
  {
    id: 2,
    title: 'Solicitação de Filmagem',
    text: 'Solicite a autorização de filmagem para seu projeto audiovisual',
  },
  {
    id: 3,
    title: 'Catálogo de Locações',
    text: 'Aproximadamente 500 locais com imagens e informações a respeito do espaço para locação.',
  },
];

export const dataSliderIlhacine = [
  {
    id: 1,
    title: 'Catálogo de Profissionais e Serviços',
    text: 'Busque e ofereça serviços para obras audiovisuaisonline',
  },
  {
    id: 2,
    title: 'Solicitação de Filmagem',
    text: 'Solicite a autorização de filmagem para seu projeto audiovisual',
  },
  {
    id: 3,
    title: 'Catálogo de Locações',
    text: 'Imagens e informações a respeito do espaço para locação.',
  },
];
