import { format } from 'date-fns';
import React from 'react';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import clientResources from '~/data/clientResources';
import { confirmEditDateHour } from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import { BoxHeader } from '~/pages/Usuario/styles';
import { showModalMessage } from '~/services/notification';
import {
  ActionInputMulti,
  ContainerMultiInputActions,
  LimiterInputWidth,
  MultiInputContainer,
  TooltipContainer,
  Wrap,
} from '~/styles/components';
import InputNoForm from '../InputNoForm';

export const DateFormMultiInput = ({
  tooltip,
  label = 'Datas',
  dateKeyName = 'date',
  isAdminRequest = false,
  isViewMode = false,
  dates,
  setDates,
  setIsLoading,
  acceptPast = false,
  isRequired = false,
}) => {
  // constante criada por não estar renderizando
  // os dados corretamente na tela ao remover
  const thisDateKeyName = dateKeyName;

  const handleAddField = _ => {
    setDates(prevState => [...prevState, { [thisDateKeyName]: '' }]);
  };

  const handleChangeField = (index, event) => {
    const values = [...dates];
    values[index][event.target.name] = event.target.value;
    setDates(values);
  };

  const handleRemoveField = index => {
    setDates(dates?.filter((_, idx) => idx !== index));
  };

  async function handleSubmitChanges({ approved, index }) {
    setIsLoading(true);

    const values = [...dates];

    const resp = await confirmEditDateHour({
      approved,
      changesToApprove: values[index]?.changes_to_approve,
      multiInputType: 'data',
    });

    await showModalMessage({
      type: resp.message_type,
      text: resp.message_text,
    });

    if (resp.message_type !== 'success') {
      setIsLoading(false);
      return;
    }

    values[index].show_confirm_btns = false;

    if (approved === 0) {
      values.splice(index, 1);
      window.location.reload();
    }

    setDates(values);
    setIsLoading(false);
  }

  const InnerTooltipContainer = ({ inputField, children }) => {
    if (isAdminRequest) {
      return (
        <TooltipContainer
          value={
            inputField?.show_confirm_btns &&
            (inputField?.changes_to_approve?.find(i => true)?.old_value
              ? `Valor anterior: ${format(
                  new Date(
                    inputField?.changes_to_approve?.find(i => true)?.old_value,
                  ),
                  'dd/MM/yyyy',
                )}`
              : 'Não possui valor anterior')
          }
        >
          {children}
        </TooltipContainer>
      );
    }

    return (
      <TooltipContainer
        value={
          inputField?.changes_to_approve?.find(i => true)?.old_value &&
          clientResources.labels.locacao.dateHourEditTooltip
        }
      >
        {children}
      </TooltipContainer>
    );
  };

  return (
    <MultiInputContainer>
      <BoxHeader style={{ justifyContent: 'normal' }}>
        <span className="boxText" style={{ color: '#828282' }}>
          {label}
        </span>
        {isRequired && (
          <span className="boxText" style={{ color: 'red' }}>
            *
          </span>
        )}
        {tooltip && (
          <button type="button" tooltip={tooltip} style={{ marginLeft: 5 }}>
            <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
          </button>
        )}
      </BoxHeader>
      {dates?.map((inputField, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Wrap key={`${inputField}~${index}`}>
          <LimiterInputWidth>
            <InnerTooltipContainer inputField={inputField}>
              <InputNoForm
                type="date"
                name={thisDateKeyName}
                required={isRequired}
                value={inputField[thisDateKeyName]}
                onChange={event => handleChangeField(index, event)}
                disabled={inputField?.changes_to_approve || isViewMode}
                editedDate={inputField?.changes_to_approve}
                acceptPast={acceptPast}
              />
            </InnerTooltipContainer>
          </LimiterInputWidth>

          <ContainerMultiInputActions>
            {!isViewMode && (
              <>
                {!inputField?.show_confirm_btns && dates?.length !== 1 && (
                  <ActionInputMulti
                    type="button"
                    onClick={() => handleRemoveField(index)}
                  >
                    <AiOutlineMinus color="#AAA" size={24} />
                  </ActionInputMulti>
                )}

                {dates?.length - 1 === index && (
                  <ActionInputMulti type="button" onClick={handleAddField}>
                    <AiOutlinePlus color="#AAA" size={24} />
                  </ActionInputMulti>
                )}
              </>
            )}

            {isAdminRequest && inputField?.show_confirm_btns && (
              <>
                <ActionInputMulti
                  type="button"
                  onClick={() =>
                    handleSubmitChanges({
                      approved: 1,
                      index,
                    })
                  }
                  style={{ backgroundColor: '#00ff005c' }}
                >
                  <AiOutlineCheck color="#AAA" size={24} />
                </ActionInputMulti>

                <ActionInputMulti
                  type="button"
                  onClick={() =>
                    handleSubmitChanges({
                      approved: 0,
                      index,
                    })
                  }
                  style={{ backgroundColor: '#ff0000ad' }}
                >
                  <AiOutlineClose color="#AAA" size={24} />
                </ActionInputMulti>
              </>
            )}
          </ContainerMultiInputActions>
        </Wrap>
      ))}
    </MultiInputContainer>
  );
};
