/* eslint-disable */
import React, { Component } from 'react';
import { ModalDocumentsStyled } from '~/components/Documents/styled';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IoIosDocument } from 'react-icons/all';
import './styles.css';

class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  showModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  renderDocuments = () => {
    const list = [];
    const uuid = this.props.uuid;
    
    this.props.documents
      ?.filter(({ document }) => !document.name.startsWith('Carta de Apoio'))
      ?.map(function (value) {
        let link = value.link + uuid;
        list.push(
          <Grid item={true} xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <a href={link} target="_blank" style={{ width: '100%' }}>
                <Paper
                  elevation={3}
                  style={{
                    padding: '10px 20px',
                    borderRadius: 20,
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="h6">{value.document?.name}</Typography>
                </Paper>
              </a>
            </Box>
          </Grid>,
        );
      });

    if (this.props.documents === undefined) {
      list.push(this.emptyList());
    }

    return list;
  };

  emptyList() {
    return (
      <div style={{ width: '100%' }}>
        <h5 style={{ textAlign: 'center' }}>
          Não há documentos cadastrados para essa Obra ou Locação
        </h5>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.showModal && (
          <ModalDocumentsStyled>
            <Box display={'flex'} justifyContent={'flex-end'}>
              <IconButton onClick={this.showModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'center'}
              style={{ marginBottom: '50px' }}
            >
              <Typography style={{ color: '#26a2b1' }} variant={'h6'}>
                Documentos
              </Typography>
            </Box>
            <Grid
              container={true}
              spacing={3}
              style={{
                width: '100%',
                padding: '0 20px',
                maxHeight: '550px',
                overflow: 'hidden',
                overflowY: 'auto',
              }}
            >
              {this.renderDocuments()}
            </Grid>
          </ModalDocumentsStyled>
        )}
        {this.props.iconOnly ? (
          <span onClick={this.showModal}>
            <IoIosDocument
              color="#4f4f4f"
              size={20}
              style={{ cursor: 'pointer' }}
            />
          </span>
        ) : (
          <Button
            onClick={this.showModal}
            variant="contained"
            startIcon={<IoIosDocument />}
          >
            <span style={{ marginLeft: 5 }}>Documentos</span>
          </Button>
        )}
      </div>
    );
  }
}

export default Documents;
