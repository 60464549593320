import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import { Container } from './styles';

export default function Input({
  name,
  type,
  label,
  required,
  icon,
  isRequired,
  requiredMessage,
  questionTooltip,
  questionOnClick,
  marginLabel,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container className="inputRow" marginLabel={marginLabel}>
      {label && (
        <label htmlFor={fieldName}>
          {label}
          {required && <span className="danger">*</span>}
          {questionTooltip && (
            <button
              type="button"
              tooltip={questionTooltip}
              onClick={questionOnClick}
              style={{ height: 0 }}
            >
              <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
            </button>
          )}
        </label>
      )}
      <input
        id={fieldName}
        name={fieldName}
        type={type || 'text'}
        ref={inputRef}
        defaultValue={defaultValue}
        autoComplete="off"
        required={isRequired}
        onWheelCapture={e => type === 'number' && e.target.blur()}
        {...rest}
      />
      {icon && <button type="submit">{icon}</button>}
      {isRequired && <span className="error">Obrigatório!</span>}
      {!isRequired && error && <span className="error">{error}</span>}
    </Container>
  );
}
