import React, { useRef, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BsFilterLeft, BsFilter } from 'react-icons/bs';
import { Input } from '~/components/Forms';

import { PageContainer, TableHeader } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, WrapButtons, Options } from './styles';

import ApiClient from '~/utils/ApiClient';
import { showModalMessage } from '~/services/notification';
import buildQueryPackageFilters from '~/functions/buildQueryPackageFilters';
import ListaPageCartasDeApoio from '~/components/Admin/Home/ListaPageCartasDeApoio';
import WhiteBox from '~/components/WhiteBox';
import Option from '~/components/Option';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#ffffff',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
  }),
};

function CartasDeApoio() {
  const [searchFormData, setSearchFormData] = useState({});
  const [filters, setFilters] = useState('');
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [cartasDeApoioList, setCartasDeApoioList] = useState([]);
  const [cartaStatusList, setCartaStatusList] = useState([
    { label: 'Carregando...', value: '' },
  ]);
  const [item, setItem] = useState(0);

  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  function handleResetSearchFields() {
    setPage(1);
    setFilters('');
    setSearchFormData({
      projeto_name: { value: '' },
      tipo_carta_apoio: { value: '' },
      protocol: { value: '' },
    });
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    setSearchFormData(state => ({
      ...state,
      [name]: {
        value: type === 'checkbox' ? checked : value,
        filterName: e.target?.getAttribute('filterName'),
        filterOperator: e.target?.getAttribute('filterOperator'),
      },
    }));
  }

  const renderCartasDeApoio = useCallback(async () => {
    try {
      setLoading(true);

      const result = await ApiClient.doRequest({
        uri: `carta-apoio-request?page=${page}&filters[][carta_apoio_status.name@equal]=${cartaStatusList[item]?.label}${filters}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setTotPage(result.data.meta.pagination.total_pages);

      const cartasResponse = result?.data?.data;
      const cartasResponseAux = cartasResponse.map(carta => {
        const docCarta = carta.documents?.find(({ document }) =>
          document?.name?.startsWith('Carta de Apoio'),
        );

        if (!docCarta)
          return {
            uuid: carta.uuid,
            uuid_relacionada: carta.uuid_relacionada,
            tipo_doc: carta.tipo_doc,
            doc_link: null,
          };

        return {
          uuid: carta.uuid,
          uuid_relacionada: carta.uuid_relacionada,
          tipo_doc: carta.tipo_doc,
          doc_link: `${docCarta?.link}${carta.uuid_relacionada}`,
          name_view: carta.name_view,
          protocol: carta.protocol,
          status: carta.status,
          solicitante: carta.solicitante,
          created_at: carta.created_at,
          cpf_cnpj: carta.cpf_cnpj,
          email: carta.email,
        };
      });

      setCartasDeApoioList(cartasResponseAux);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: 'Não foi possível listar as Cartas de Apoio',
      });
    }
  }, [filters, cartaStatusList, item, page]);

  const renderStatus = useCallback(async () => {
    try {
      setLoading(true);

      const result = await ApiClient.doRequest({
        uri: `carta-apoio-status`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const cartaStatusResp = result.data.data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));
      setCartaStatusList(cartaStatusResp);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          projeto_name: Yup.string().test(
            'projeto-name',
            'Informe ao menos 2 caracteres para buscar',
            value => !(value && value?.length < 2),
          ),
          tipo_carta_apoio: Yup.string().test(
            'tipo-carta-apoio',
            'Informe ao menos 2 caracteres para buscar',
            value => !(value && value?.length < 2),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setPage(1);
        setFilters(buildQueryPackageFilters(searchFormData));
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [searchFormData],
  );

  function renderList() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && cartasDeApoioList.length <= 0) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {cartasDeApoioList.map(lista => (
          <ListaPageCartasDeApoio
            key={lista.uuid}
            data={lista}
            loadCartasDeApoio={renderCartasDeApoio}
            cartaStatusList={cartaStatusList}
          />
        ))}
      </>
    );
  }

  useEffect(() => {
    renderStatus();
  }, [renderStatus]);

  useEffect(() => {
    renderCartasDeApoio();
  }, [renderCartasDeApoio]);

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Options>
          {cartaStatusList.map(cartaStatus => (
            <Option
              active={item === cartaStatusList.indexOf(cartaStatus)}
              onClick={() => {
                setPage(1);
                setItem(cartaStatusList.indexOf(cartaStatus));
              }}
            >
              {cartaStatus.label}
            </Option>
          ))}
        </Options>
        <WhiteBox column maxHeight>
          <>
            {hiddenButton === true && (
              <span tooltip="Exibir filtro!">
                <button
                  type="button"
                  onClick={() => {
                    setShow(true);
                    setHiddenButton(false);
                  }}
                >
                  <BsFilterLeft color="#000" size={24} />
                </button>
              </span>
            )}
            {show === true && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Wrap>
                  {/* <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="protocol"
                      label="Protocolo"
                      value={searchFormData.protocol?.value}
                      onChange={handleChange}
                      filterOperator="equal"
                    />
                  </LimiterInputWidth> */}
                  {/* <Input
                    type="text"
                    name="projeto_name"
                    label="Projeto"
                    value={searchFormData.projeto_name?.value}
                    onChange={handleChange}
                    filterName="name_view"
                  /> */}
                  <Input
                    type="text"
                    name="tipo_carta_apoio"
                    label="Tipo Carta de Apoio"
                    value={searchFormData.tipo_carta_apoio?.value}
                    onChange={handleChange}
                    filterName="tipo_doc"
                  />
                </Wrap>
                <Wrap>
                  <WrapButtons>
                    <Button
                      type="submit"
                      btType="#26A2B1"
                      label="Buscar"
                      full
                      width="170px"
                    />
                    <Button
                      btType="#707683"
                      onClick={handleResetSearchFields}
                      label="Limpar"
                      full
                      width="100px"
                    />
                    <span tooltip="Ocultar filtro!">
                      <button
                        type="button"
                        onClick={() => {
                          setShow(false);
                          setHiddenButton(true);
                        }}
                      >
                        <BsFilter color="#000" size={24} />
                      </button>
                    </span>
                  </WrapButtons>
                </Wrap>
              </Form>
            )}
          </>
          <table>
            <TableHeader>
              <tr>
                <th>Data/Hora de Criação</th>
                <th>Tipo Carta de Apoio</th>
                <th>Projeto</th>
                <th>Protocolo</th>
                <th>Solicitante</th>
                <th>CPF/CNPJ</th>
                <th>E-mail</th>
                <th style={{ justifyContent: 'center' }}>Ações</th>
              </tr>
            </TableHeader>
            <WhiteBox column aCenter>
              <tbody>{renderList()}</tbody>
              <BasicPagination />
            </WhiteBox>
          </table>
        </WhiteBox>
      </Container>
    </PageContainer>
  );
}

export default withRouter(CartasDeApoio);
