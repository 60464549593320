import React from 'react';
import { withRouter } from 'react-router-dom';

import Modal from 'react-modal';

import {
  ModalContent,
  overlayStyle,
  contentStyle,
  ModalButton,
  Wrap,
} from './styles';

function ModalPorteFilmagem({ openModal, closeModal }) {
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={openModal}
      style={{
        overlay: overlayStyle,
        content: contentStyle,
      }}
    >
      <ModalButton onClick={closeModal}>X</ModalButton>
      <ModalContent>
        <h1 style={{ marginBottom: 10 }}>Baixo Impacto</h1>
        <span>
          <p>
            São as solicitações de filmagem ou apoio à filmagem caracterizadas
            por uma ou mais das características abaixo:
          </p>
          <p>
            - Interferem de maneira pouco significativa no fluxo e dinâmica do
            local solicitado, isto é, a filmagem não altera a rotina do local;
          </p>
          <p>- Normalmente não há demanda técnica para sua realização;</p>
          <p>
            - podem ser realizadas sem o apoio operacional da gestão pública ou
            de terceiros;
          </p>
          <p>- Equipes pequenas ou médias (até 20/30 pessoas no total);</p>
          <p>- Infraestrutura básica de equipamentos de filmagem;</p>
          <p>
            - Sem bloqueios e/ou ocupações totais, nem intervenções que exijam
            desvios de pedestres e/ou veículos.
          </p>
        </span>
        <h1 style={{ marginBottom: 10, marginTop: 10 }}>Médio Impacto</h1>
        <span style={{ marginBottom: 10 }}>
          <p>
            São as solicitações de filmagem ou apoio à filmagem caracterizadas
            por uma ou mais das características abaixo:
          </p>
          <p>
            - Interferem de maneira moderada no fluxo e funcionamento ou
            dinâmica do local solicitado, isto é, a filmagem altera parcialmente
            a rotina do local;
          </p>
          <p>
            - Normalmente hádemanda técnica para sua realização - podem ser
            realizadas com o apoio operacional da gestão pública ou de
            terceiros;
          </p>
          <p>- Equipes médias ou grandes(até 40/50 pessoas no total);</p>
          <p>
            - Equipamentos e infraestrutura de filmagem de médio ou grande
            porte;
          </p>
          <p>
            - Demandam intervenções no espaço público que o modifiquem ou
            adaptem como bloqueios e/ou ocupações totais ou intermitentes de
            pedestres e/ou veículos.
          </p>
        </span>
        <h1 style={{ marginBottom: 10, marginTop: 10 }}>Alto Impacto</h1>
        <span style={{ marginBottom: 10 }}>
          <p>
            São as solicitações de filmagem ou apoio à filmagem caracterizadas
            por uma ou mais das características abaixo:
          </p>
          <p>
            - Interferem de maneira significativa no fluxo e funcionamento ou
            dinâmica do local solicitado, isto é, a filmagem altera a rotina do
            local;
          </p>
          <p>
            - Demandam análise e planejamento por parte da gestão pública e
            normalmente acompanhamento para sua realização;
          </p>
          <p>
            - Filmagens com equipes médias ou grandes(a partir de 60/70
            pessoas);
          </p>
          <p>- Equipamentos e infraestrutura de filmagem de grande porte;</p>
          <p>
            - Intervenções complexas e no espaço público que o modifiquem ou
            adaptem como bloqueios e/ou ocupações totais ou intermitentes de
            pedestres e/ou veículos.
          </p>
        </span>
      </ModalContent>
    </Modal>
  );
}

export default withRouter(ModalPorteFilmagem);
