/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import {
  Container,
  Arquivo,
  ButtonsContent,
  FileNotExistsLabel,
} from './styles';

export default function File({
  label,
  required,
  attach,
  textFile,
  name,
  inputRef,
  onChange,
  onClick,
  icon,
  error,
  link,
  acceptFiles,
  isDisabled,
  ...rest
}) {
  const { fieldName, defaultValue = '', registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container style={rest.style}>
      <label htmlFor={fieldName}>
        {label}
        {required && <span>*</span>}
      </label>
      <Arquivo>
        <>
          {isDisabled && !link && (
            <FileNotExistsLabel>Arquivo não foi cadastrado</FileNotExistsLabel>
          )}

          {!isDisabled && (
            <>
              <span>{attach ? attach.name : textFile}</span>
              <input
                className="arquivo"
                type="file"
                id={fieldName}
                name={fieldName}
                ref={inputRef}
                defaultValue={defaultValue}
                onChange={onChange}
                accept={acceptFiles}
              />
            </>
          )}

          <ButtonsContent>
            {!isDisabled && (
              <button
                type="button"
                onClick={onClick}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                Selecionar
              </button>
            )}

            {link && (
              <a href={link} target="_blank" rel="noreferrer">
                Visualizar
              </a>
            )}
          </ButtonsContent>
        </>
      </Arquivo>

      {icon && <button type="submit">{icon}</button>}
      {error && <span className="error">{error}</span>}
    </Container>
  );
}
