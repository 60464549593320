import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { Container } from './styles';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage, showToastMessage } from '~/services/notification';
import { Wrap } from '~/pages/Usuario/Obra/styles';
import Button from '~/components/Button';
import { BoxHeader } from '~/pages/Usuario/styles';
import { Select } from '~/components/Forms';
import { LimiterInputWidth } from '~/styles/components';
import Load from '~/components/Load';
import WhiteBox from '~/components/WhiteBox';

const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function WorkStatusSwitch({ data }) {
  const [locacaoStatusList, setWorkStatusList] = useState([]);
  const [loading, setLoading] = useState(false);

  // refs
  const formWorkStatusRef = useRef();

  const getWorkStatusList = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await ApiClient.doRequest({
        uri: `${
          data?.entity === 'work' ? 'work-status' : 'locacao-status'
        }?paginate=false`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setWorkStatusList(
        resp?.data?.data?.map(({ uuid, name }) => ({
          value: uuid,
          label: name,
        })),
      );
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Falha ao buscar listagem de status. Tente mais tarde!',
      });
      setWorkStatusList([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const getCurrentWorkStatus = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await ApiClient.doRequest({
        uri: `${data?.entity}/${data?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const status =
        data?.entity === 'work'
          ? resp?.data?.data?.work_status
          : resp?.data?.data?.locacao_status;

      formWorkStatusRef.current?.setFieldValue('status_uuid', {
        label: status?.name,
        value: status?.uuid,
      });

      setLoading(false);
    } catch (err) {
      showToastMessage({
        type: 'error',
        text: 'Falha ao buscar status da entidade atual. Tente mais tarde!',
      });
      setLoading(false);
    }
  }, [data?.entity, data?.uuid]);

  useEffect(() => {
    getWorkStatusList();
    getCurrentWorkStatus();
  }, [getWorkStatusList, getCurrentWorkStatus]);

  async function handleSubmit() {
    try {
      // validar campos aqui caso precise

      setLoading(true);

      const indexesPatchWork =
        data?.entity === 'work'
          ? {
              ld_veiculacao_inicial: [],
              ld_streaming: [],
              ld_canais_abertos: [],
              ld_canais_filme: [],
              ld_canais_infantil: [],
              ld_canais_noticia: [],
              ld_canais_documentarios: [],
              ld_canais_variedade: [],
              ld_canais_esporte: [],
            }
          : {};

      const resp = await ApiClient.doRequest({
        uri: `${data?.entity}/${data?.uuid}`,
        method: ApiClient.PATCH,
        expectedStatus: 201,
        body: Object.assign(indexesPatchWork, {
          [`${
            data?.entity === 'work' ? 'work_status_uuid' : 'locacao_status_uuid'
          }`]: formWorkStatusRef?.current?.getFieldValue('status_uuid'),
        }),
      });

      const status =
        data?.entity === 'work'
          ? resp?.data?.data?.work_status
          : resp?.data?.data?.locacao_status;

      formWorkStatusRef?.current?.setFieldValue('status_uuid', {
        label: status?.name,
        value: status?.uuid,
      });

      await showModalMessage({
        type: 'success',
        text: 'Status modificado com sucesso.',
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao tentar salvar status.',
      });
    } finally {
      setLoading(false);
    }
  }

  function renderForm() {
    return (
      <>
        <Load inLoad={loading} />
        <WhiteBox column jCenter aCenter marginBottom="30px">
          <Wrap>
            <Container>
              <BoxHeader>
                <span className="boxText">Status</span>
              </BoxHeader>

              <Form ref={formWorkStatusRef}>
                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w75>
                    <Select
                      name="status_uuid"
                      label=""
                      placeholder="Selecione"
                      options={locacaoStatusList}
                      style={selectCustomStyles}
                      required
                    />
                  </LimiterInputWidth>

                  <Button
                    btType="#26A2B1"
                    label="Enviar"
                    full
                    width="140px"
                    loading={loading}
                    onClick={() => handleSubmit()}
                  />
                </Wrap>
              </Form>
            </Container>
          </Wrap>
        </WhiteBox>
      </>
    );
  }

  return renderForm();
}
