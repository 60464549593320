import React from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { Icon, Container, Content, Card } from './styles';

const CalendarioInfoIcon = () => {
  return (
    <Icon type="button">
      <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
      <Container>
        <Content>
          <Card>
            <span>
              <p>
                <u>Agendamento de visitas de locação:</u>
              </p>
              <p>1 dia útil para agendamento</p>
            </span>
          </Card>
          <Card>
            <span>
              <p>
                <u>Agendamento de visitas técnicas:</u>
              </p>
              <p>2 dias úteis para agendamento</p>
            </span>
          </Card>
          <Card>
            <span>
              <p>
                <u>Cancelamento de visitas agendadas:</u>
              </p>
              <p>em até 5 horas úteis antes do horário de realização</p>
            </span>
          </Card>
          <Card>
            <span>
              <p>
                <u>Atrasos em visitas agendadas:</u>
              </p>
              <p>
                1 hora útil de tolerância máxima para atrasos, mediante aviso e
                disponibilidade do(s) gestor(es). (Checar item 13 - Visitas)
              </p>
            </span>
          </Card>
        </Content>
      </Container>
    </Icon>
  );
};

export default CalendarioInfoIcon;
