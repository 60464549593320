/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const PageContainer = styled.main`
  display: flex;
  flex-direction: row;
  width: 100%;
  /*  max-width: 1920px; */
  background-color: #f2f2f2;
  margin: 0 auto;

  form {
    h2 {
      font-family: 'InterBold', sans-serif;
      font-size: 30px;
      line-height: 34px;
      color: #4f4f4f;
      margin-bottom: 50px;
    }

    input,
    textarea {
      background-color: #f2f2f2;
      border-radius: 5px;
      border: 1px solid #e2e2e2;
    }

    label {
      font-family: 'InterBold', sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #828282;
    }

    textarea {
      resize: none;
    }
  }
`;

export const Line = styled.hr`
  height: 1px;
  border-width: 0;
  color: #80808059;
  background-color: #80808059;
  margin: 20px 0;
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  .inputRow {
    input {
      background: #ffffff;
    }
  }

  &.small {
    max-width: 500px;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const TitleForm = styled.div`
  display: flex;
  width: 100%;

  h4 {
    font-family: 'PoppinsBold', sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #323c47;
    margin-top: 18px;
    margin-bottom: 8px;
  }
`;

export const PageWrapper = styled.section`
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin: 48px auto 0 auto;

  .icon-spin {
    align-self: center;
    margin-bottom: 32px;
    color: #ffffff;
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  @media screen and (max-width: 1160px) {
    flex-direction: column;
    padding: 48px 32px;
    margin: 0 auto;
  }

  @media screen and (max-width: 410px) {
    padding: 32px 16px;
  }
`;

export const TableHeader = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #4f4f4f;
  width: 100%;
  height: 64px;
  border-radius: 5px;
  margin-bottom: 16px;

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: none;
  }

  th {
    font-family: 'PoppinsBold', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    text-align: left;
    padding: 10px 0;
    width: 100%;
    max-width: 200px;

    &:last-child {
      text-align: center;
      max-width: 90px;
    }
  }
`;

export const ContainerInputFile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 24px;
  margin-top: 16px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: 'MuseoSans500', sans-serif;
    font-size: 15px;
    color: #000;
  }

  input {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    border: 1px #e2e2e2 solid;
    padding: 16px;
    font-size: 14px;
    font-family: 'MuseoSans500', sans-serif;
    color: #586365;
    background-color: #f2f2f2;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.075);

    &[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &[disabled] {
      background-color: #ededed;
      &::placeholder {
        content: '';
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0;
    top: 8px;

    svg {
      color: #fff;
    }
  }

  span {
    font-size: 14px;
    font-family: 'MuseoSans700', sans-serif;
    color: #ff0000;
    margin: 4px 0;
  }
`;

export const Arquivo = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  & span > p + p {
    margin-top: 15px;
  }

  p,
  span {
    font-size: 14px;
    line-height: 18px;
    font-family: 'PoppinsSemiBold', sans-serif;
    color: #586365;
    opacity: 0.65;
    width: 78%;
  }

  span {
    min-height: 49px;
    margin-bottom: 16px;
    margin-top: 8px;
    max-height: 150px;
    overflow-y: auto;
    padding: 16px;
    border-radius: 4px;
    border: 1px #e2e2e2 solid;
    background-color: #f2f2f2;
  }

  input.arquivo {
    background-color: #000;
    position: absolute;
    width: 0;
    height: 0;
    z-index: 9999;
    opacity: 0;
    cursor: pointer;
  }

  input.arquivo::before {
    content: 'Escolha aqui';
    display: none;
  }

  button {
    font-family: 'InterSemiBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    width: 20%;
    background-color: #26a2b1;
    min-height: 51px;
    color: #fff;
    border-radius: 3px;
  }

  @media only screen and (max-width: 767px) {
    span {
      font-size: 14px;
    }
  }
`;

export const MainLoading = styled.div`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;

export const SelectNoForm = styled.select`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  padding: 16px;
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #828282;

  /* box-shadow: 0 3px 12px rgba(0, 0, 0, 0.075); */

  &[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    }
  }

  &[disabled] {
    background-color: #ededed;
    &::placeholder {
      content: '';
    }
  }

  &::-webkit-input-placeholder {
    opacity: 0.65;
  }

  &:-ms-input-placeholder {
    opacity: 0.65;
  }

  &:-moz-placeholder {
    opacity: 0.65;
  }

  &::-moz-placeholder {
    opacity: 0.65;
  }

  &::placeholder {
    opacity: 0.65;
  }
`;

export const ConditionalContainer = styled.div`
  ${props =>
    props.show === null
      ? 'display: none;'
      : props.show === false
      ? 'display: none;'
      : 'display: flex;'}
  width: 100%;
  ${props => props.mgTop && `margin-top: ${props.mgTop}px;`};
  flex-direction: column;
`;

export const TextareaNoForm = styled.textarea`
  width: 100%;
  border-radius: 2px;
  border: 1px #d0d0d0 solid;
  padding: 16px;
  font-size: 16px;
  font-family: 'InterSemiBold', sans-serif;
  color: #828282;
  background-color: #fafafa;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.075);

  &::-webkit-input-placeholder {
    opacity: 0.65;
  }

  &:-ms-input-placeholder {
    opacity: 0.65;
  }

  &:-moz-placeholder {
    opacity: 0.65;
  }

  &::-moz-placeholder {
    opacity: 0.65;
  }

  &::placeholder {
    opacity: 0.65;
  }

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: 'MuseoSans500', sans-serif;
    font-size: 15px;
    color: #000000;
    margin-bottom: 8px;
  }

  span {
    font-size: 14px;
    font-family: 'InterBold', sans-serif;
    color: #ff0000;
    margin: 4px 0;
  }

  span.danger {
    font-family: 'PoppinsSemiBold', sans-serif;
  }
`;

export const LimiterInputWidth = styled.div`
  display: flex;
  margin-right: 16px;
  ${props => (props.w25 ? 'width: 24%;' : '')};
  ${props => (props.w50 ? 'width: 49%;' : '')};
  ${props => (props.w75 ? 'width: 74%;' : '')};
  ${props => (props.w100 ? 'width: 99%;' : '')};
  ${props => (props.marginNone ? 'margin: 0;' : '')};

  ${props => (props.algCenter ? 'align-items: center' : '')};
  ${props => (props.jstfyCenter ? 'justify-content: center' : '')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? [marginBottom] : '0')};

  &:nth-child(4) {
    margin-right: 0;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ cols }) =>
    cols ? `repeat(${cols}, 1fr)` : '1fr'};
  width: 100%;
  gap: ${({ gap }) => (gap ? `${gap}px` : '10px')};
`;

export const ActionInputMulti = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #d0d0d0;
  border-radius: 50%;
  background: #f2f2f2;

  height: 30px;
  width: 30px;
`;

export const ContainerMultiInputActions = styled.div`
  display: flex;
  align-items: center;

  & > button + button {
    margin-left: 5px;
  }
`;

export const MultiInputContainer = styled.div`
  margin: 20px 0;
  width: 100%;
`;

export const ApprovalContainer = styled.div`
  background-color: ${props =>
    props.hasApprovalFields ? '#bff6fc !important' : ''};
  width: 100%;
  padding: ${props => (props.hasApprovalFields ? '10px' : '')};
  margin-bottom: ${props => (props.hasApprovalFields ? '10px' : '')};
  border-radius: 10px;
`;

export const TooltipContainer = styled.div`
  width: 100%;

  ${({ value }) =>
    value &&
    css`
      position: relative;
      display: inline-block;

      &:hover::before {
        content: '${value}';
        font-family: 'InterSemiBold',sans-serif; */
        font-size: 16px;
        line-height: 20px;
        width: max-content;
        background-color: #828282;
        color: #fff;
        border-radius: 6px;
        padding: 5px;

        /* Posiciona o tooltip */
        position: absolute;
        top: -5px;
        z-index: 1;
      }
    `}
`;

export const TdEllipsis = styled.td`
  display: block !important;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
