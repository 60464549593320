import React from 'react';
import { Container } from './styles';

export const Text = ({ label, value }) => (
  <Container>
    <span className="label">{label}</span>
    {(value !== undefined || value !== null) && (
      <span className="value">{value}</span>
    )}
  </Container>
);
