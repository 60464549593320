import React, { useCallback, useEffect, useState } from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { RiArrowGoBackLine } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import MultiRef from 'react-multi-ref';
import { Form } from '@unform/web';

import { AiOutlineReload } from 'react-icons/ai';
import { Content, MainLoading, ContainerFooter } from './styles';
import { PageContent, PageFooter } from '~/pages/Usuario/styles';
import { GridContainer, PageContainer } from '~/styles/components';
import { showToastMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';

import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import { InputNoForm } from '~/components/Forms';
import { Button } from '~/components/global';
import VideoPopUp from '~/components/Usuario/VideoPopUp';
import prefeituraIlhaBela from '~/assets/images/prefeitura-ilhabela.png';
import clientResources from '~/data/clientResources';

const DuvidasFrequentes = () => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && (
        <MainLoading loading={loading}>
          <h3>Processando Informações</h3>
          <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
        </MainLoading>
      )}

      <Header>
        <LogoIcon disabled />
      </Header>

      <PageContainer style={{ backgroundColor: 'white' }}>
        <PageContent>
          <Content>
            <Form>
              <p>
                <span>
                  O sistema de Ilhabela faz parte de um sistema compartilhado
                  com a São Paulo Film Commission.
                  Os vídeos tutoriais foram criados para tirar dúvidas do acesso ao sistema.
                  <a
                    href="https://cadastro.filmesp.com/video-tutorial"
                    style={{ margin: 5 }}
                  >
                    Clique aqui
                  </a>
                  para acessar os vídeos tutoriais. Em caso de dúvidas, entre em contato pelo e-mail <q>ilhacine@ilhabela.sp.gov.br</q>
                </span>
              </p>
            </Form>
          </Content>
        </PageContent>
      </PageContainer>
      <ContainerFooter>
        <PageFooter className="container-footer">
          <img
            src={prefeituraIlhaBela}
            alt="Prefeitura de Ilha Bela"
            width={200}
          />
        </PageFooter>
      </ContainerFooter>
    </>
  );
};

export default DuvidasFrequentes;
