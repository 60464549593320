import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { format } from 'date-fns';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineCheck,
  AiOutlineClose,
} from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  ChoiceButtonLocacao,
  Textarea,
  InputMask,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ConditionalContainer,
  LimiterInputWidth,
  ActionInputMulti,
  ContainerMultiInputActions,
  MultiInputContainer,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import ImpactoListActions from '~/store/ducks/impacto-list';
import ComplementoNumeroListActions from '~/store/ducks/complemento-numero-list';
import FinalidadeReservaListActions from '~/store/ducks/finalidade-reserva-list';
import LocalGeradorListActions from '~/store/ducks/local-gerador-list';
import diffDatasFilmagem from '~/utils/functions/diffDatasFilmagem';
import diffHoursFilmagem from '~/utils/functions/diffHoursFilmagem';
import Chat from '~/components/Chat/chat';
import { useList } from '~/hooks/useFunction';
import {
  confirmEditDateHour,
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import { clearMasks } from '~/utils/functions/clearMasks';
import clientResources from '~/data/clientResources';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { IntervalFormMultiInput } from '~/components/Forms/IntervalFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const selectExtensaoList = [
  { value: 'metragem', label: 'Metragem' },
  { value: 'intervalo', label: 'Numeração' },
];

export default function ReservasVagasFilmInternasEdit() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseLocation, setResponseReservas] = useState();
  const [errorsForm, setErrorsForm] = useState({});
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);
  const [dadosCep, setDadosCep] = useState(null);

  // states multi input
  const [horasEntradaInputs, setHorasEntradaInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasEntradaInputs, setDatasEntradaInputs] = useState([
    { data_entrada: '' },
  ]);
  const [horasSaidaInputs, setHorasSaidaInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasSaidaInputs, setDatasSaidaInputs] = useState([
    { data_saida: '' },
  ]);

  const [intervaloInputs, setIntervaloInputs] = useState([
    { start: '', end: '' },
  ]);
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states choices
  const [localFilmagem, setLocalFilmagem] = useState(null);
  const [gerador, setGerador] = useState(null);
  const [blimpado, setBlimpado] = useState(null);
  const [drone, setDrone] = useState(null);
  const [associarLocacao, setAssociarLocacao] = useState(null);
  const [reservasVagas, setReservasVagas] = useState(null);
  const [baseApoio, setBaseApoio] = useState(null);

  // state outro fields
  const [showOutroFinReservaField, setShowOutroFinReservaField] =
    useState(false);
  const [showExtensaoMetragemField, setShowExtensaoMetragemField] =
    useState(false);
  const [showExtensaoIntervalosField, setShowExtensaoIntervalosField] =
    useState(false);
  const [showNumberFields, setShowNumberFields] = useState(false);
  const [showDescrAltoImpactoLocalField, setShowDescrAltoImpactoLocalField] =
    useState(false);

  // lists
  const reservaVagasFilmInternasAssociacaoList = useList({
    url: `locacao-reserva-vaga?filters[][work.uuid@equal]=${responseLocation?.work_uuid}&paginate=false&filters[][uuid@not_equal]=${responseLocation?.uuid}`,
  });

  // selectors
  const { data: impactoSelectList } = useSelector(state => state.impactoList);
  const { data: complementoNumeroSelectList } = useSelector(
    state => state.complementoNumeroList,
  );
  const { data: finalidadeReservaSelectList } = useSelector(
    state => state.finalidadeReservaList,
  );
  const { data: localGeradorSelectList } = useSelector(
    state => state.localGeradorList,
  );

  // Busca dados da Reserva
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-reserva-vaga/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set temp variables
      const blimpadoTmp =
        respLoc?.blimpado === null ? null : respLoc?.blimpado === 1;

      // set choices buttons
      setBaseApoio(
        typeof respLoc?.base_address === 'string' &&
          typeof respLoc?.base_num === 'string',
      );
      setReservasVagas(
        Object.values(respLoc?.locacao_via_externa || []).length > 0,
      );
      setBlimpado(blimpadoTmp);
      setLocalFilmagem(typeof respLoc?.endereco_filmagem === 'string');
      setGerador(
        blimpadoTmp === true ||
          Object.values(respLoc?.ld_local_gerador || {}).length > 0,
      );
      setDrone(respLoc?.drone === 1);
      setAssociarLocacao(Object.values(respLoc?.parent || {}).length > 0);

      // set campos 'multi'
      setDatasFilmagemInputs(
        respLoc?.date_locacao_reserva_vaga?.map(
          ({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      setDatasEntradaInputs(
        respLoc?.base_dates
          ?.filter(({ date_input }) => date_input === 'Entrada')
          ?.map(({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_entrada: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          }),
      );

      setDatasSaidaInputs(
        respLoc?.base_dates
          ?.filter(({ date_input }) => date_input === 'Saída')
          ?.map(({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_saida: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          }),
      );

      setHorasFilmagemInputs(
        respLoc?.hour_locacao_reserva_vaga?.map(
          ({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      setHorasEntradaInputs(
        respLoc?.base_hours
          ?.filter(({ hour_input }) => hour_input === 'Entrada')
          ?.map(({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          }),
      );

      setHorasSaidaInputs(
        respLoc?.base_hours
          ?.filter(({ hour_input }) => hour_input === 'Saída')
          ?.map(({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          }),
      );

      setIntervaloInputs(
        respLoc?.reserva_intervalos
          ? respLoc?.reserva_intervalos?.map(({ start, end }) => {
              return { start, end };
            })
          : [{ start: '', end: '' }],
      );

      // set campos 'Outro'
      setShowOutroFinReservaField(
        respLoc?.finalidades_reserva_vaga?.find(
          finalidade => finalidade.name === 'Outros',
        ),
      );

      // set select default values
      formRef.current?.setFieldValue('ld_complemento_numero_uuid', {
        label: respLoc?.ld_complemento_numero?.name,
        value: respLoc?.ld_complemento_numero?.uuid,
      });

      const filterExtensaoValue = respLoc?.reserva_intervalos
        ? 'intervalo'
        : 'metragem';
      formRef.current?.setFieldValue(
        'select_extensao',
        selectExtensaoList.filter(
          extensao => extensao.value === filterExtensaoValue,
        ),
      );

      formRef.current?.setFieldValue(
        'finalidades',
        respLoc?.finalidades_reserva_vaga?.map(ilfr => {
          return {
            label: ilfr.name,
            value: ilfr.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_local_gerador_uuid', {
        label: respLoc?.ld_local_gerador?.name,
        value: respLoc?.ld_local_gerador?.uuid,
      });

      formRef.current?.setFieldValue('ld_impacto_uuid', {
        label: respLoc?.ld_impacto?.name,
        value: respLoc?.ld_impacto?.uuid,
      });

      formRef.current?.setFieldValue('reserva_vagas_film_internas_associacao', {
        label: `${respLoc?.parent?.name_view} - ${respLoc?.parent?.protocol}`,
        value: {
          uuid: respLoc?.parent?.uuid,
          endpoint: respLoc?.parent?.endpoint,
        },
      });

      formRef.current?.setFieldValue(
        'cep',
        resp?.data?.data?.cep?.replace(/(\d)(\d{3})$/, '$1-$2'),
      );

      // set show campos condicionais
      setShowNumberFields(typeof respLoc?.number === 'string');
      setShowExtensaoMetragemField(
        Object.values(respLoc?.reserva_intervalos || []).length <= 0,
      );
      setShowExtensaoIntervalosField(
        Object.values(respLoc?.reserva_intervalos || []).length > 0,
      );
      setShowDescrAltoImpactoLocalField(
        typeof respLoc?.descr_alto_impacto_local === 'string',
      );

      setResponseReservas(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState?.viewData]);

  // effects
  useEffect(() => {
    dispatch(ImpactoListActions.showRequest());
    dispatch(ComplementoNumeroListActions.showRequest());
    dispatch(FinalidadeReservaListActions.showRequest());
    dispatch(LocalGeradorListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  function handleChangeCep(event) {
    const cep = event.target.value?.replace('-', '')?.replace('_', '');

    if (!cep || cep?.length < 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(res => res.json())
      .then(async data => {
        if (data?.erro === true) {
          await showModalMessage({
            type: 'error',
            text: 'CEP inexistente!',
          });
          setDadosCep(null);
          return;
        }

        setDadosCep(data);

        formRef.current?.setFieldValue('street', data.logradouro);
        formRef.current?.setFieldValue('neighborhood', data.bairro);
        formRef.current?.setFieldValue('number', '');
      });
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'number') {
      setShowNumberFields(value !== '');
    }
  }

  function handleMultiSelectFinalidades(e) {
    setShowOutroFinReservaField(!!e?.find(x => x.label === 'Outros'));

    setFormData(state => ({
      ...state,
      finalidades: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'select_extensao') {
      setShowExtensaoMetragemField(option.value === 'metragem');
      setShowExtensaoIntervalosField(option.value === 'intervalo');
    }
  }

  function formatEspecificaData(data) {
    const locacaoAssociada = reservaVagasFilmInternasAssociacaoList?.find(
      ({ uuid }) => uuid === data?.reserva_vagas_film_internas_associacao?.uuid,
    );

    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      ld_impacto_uuid: data?.ld_impacto_uuid,
      cep: data?.cep?.replace('-', ''),
      street: data?.street,
      number: data?.number,
      neighborhood: data?.neighborhood,
      finalidades: data?.finalidades,
      especificidades: data?.especificidades,
    });

    // dados de choices
    let dataBaseApoio = {};
    if (baseApoio) {
      const datasBaseEntrada = datasEntradaInputs
        ?.filter(item => typeof item === 'object')
        .map(item => ({
          date: getBrowserTransformDate(item.data_entrada),
          date_input: 'Entrada',
        }));

      const datasBaseSaida = datasSaidaInputs
        ?.filter(item => typeof item === 'object')
        .map(item => ({
          date: getBrowserTransformDate(item.data_saida),
          date_input: 'Saída',
        }));

      const horasBaseEntrada = horasEntradaInputs.map(item => ({
        ...item,
        hour_input: 'Entrada',
      }));
      const horasBaseSaida = horasSaidaInputs.map(item => ({
        ...item,
        hour_input: 'Saída',
      }));

      dataBaseApoio = {
        base_address: data?.base_address,
        base_num: data?.base_num,
        base_dates: [...datasBaseEntrada, ...datasBaseSaida],
        base_hours: [...horasBaseEntrada, ...horasBaseSaida],
      };
    }

    const dataLocalFilmagem = {
      endereco_filmagem: localFilmagem ? data?.endereco_filmagem : null,
    };

    const dataGerador = {
      ld_local_gerador_uuid: gerador ? data?.ld_local_gerador_uuid : null,
      blimpado: gerador ? blimpado : null,
    };

    const dataDrone = { drone };

    const dataAssociarLocacao = {
      parent_uuid: associarLocacao ? locacaoAssociada?.uuid : null,
    };

    // dados de campos exibidos condicionalmente
    const dataDescrAltoImpactoLocal = {
      descr_alto_impacto_local: showDescrAltoImpactoLocalField
        ? data?.descr_alto_impacto_local
        : null,
    };

    const dataNumberFields = showNumberFields
      ? { ld_complemento_numero_uuid: data?.ld_complemento_numero_uuid }
      : { ld_complemento_numero_uuid: null };

    const dataExtensaoMetragem = {
      metragem: showExtensaoMetragemField ? +data?.metragem : null,
    };

    const dataExtensaoIntervalos = {
      reserva_intervalo: showExtensaoIntervalosField ? intervaloInputs : null,
    };

    const dataOutroFinReserva = {
      outro_finalidade: showOutroFinReservaField
        ? data?.outro_finalidade
        : null,
    };

    // diff dates e hours
    const diffDatas = diffDatasFilmagem({
      datasFilmagemLocacao: responseLocation?.date_locacao_reserva_vaga?.map(
        ({ uuid, date, changes_to_approve }) =>
          omitEmpty({
            uuid,
            data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
            changes_to_approve,
          }),
      ),
      datasFilmagemInput: datasFilmagemInputs?.map(
        ({ uuid, data_filmagem, changes_to_approve }) =>
          omitEmpty({
            uuid,
            data_filmagem,
            changes_to_approve,
          }),
      ),
      dateKeyName: 'data_filmagem',
    });

    const diffHours = diffHoursFilmagem({
      hoursFilmagemLocacao: responseLocation?.hour_locacao_reserva_vaga?.map(
        ({ uuid, inicio, fim, changes_to_approve }) =>
          omitEmpty({
            uuid,
            inicio: inicio.substring(0, 5),
            fim: fim.substring(0, 5),
            changes_to_approve,
          }),
      ),
      hoursFilmagemInput: horasFilmagemInputs?.map(
        ({ uuid, inicio, fim, changes_to_approve }) =>
          omitEmpty({
            uuid,
            inicio,
            fim,
            changes_to_approve,
          }),
      ),
      hourBeginKeyName: 'inicio',
      hourEndKeyName: 'fim',
    });

    return Object.assign(
      dataNaoCondicional,
      dataBaseApoio,
      dataDescrAltoImpactoLocal,
      dataNumberFields,
      dataExtensaoMetragem,
      dataExtensaoIntervalos,
      { dates: diffDatas },
      { hours: diffHours },
      dataLocalFilmagem,
      dataGerador,
      dataDrone,
      dataAssociarLocacao,
      dataOutroFinReserva,
    );
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        number: Yup.string().typeError(defaultMsg).required(defaultMsg),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios',
      });
      return false;
    }

    return true;
  }

  async function handleSubmit(data) {
    console.log({ dataToBeUpdated: data });
    data.finalidades = formData?.finalidades;

    try {
      if (!(await handleValidationFields(data))) {
        return;
      }

      const reservasData = clearMasks(formatEspecificaData(data), ['cep']);

      setLoading(true);

      if (isAdminRequest) {
        await ApiClient.doRequest({
          uri: `locacao-reserva-vaga/${dadoState?.viewData?.uuid}`,
          method: ApiClient.PATCH,
          body: reservasData,
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title: 'Reservas de Vagas para Filmagens Internas',
          text: 'Alteração enviada com sucesso',
        });

        history.push('/locacoes');
      } else {
        if (!reservasData?.dates && !reservasData?.hours) {
          setLoading(false);
          await showModalMessage({
            type: 'info',
            title: 'Reservas de Vagas para Filmagens Internas',
            text: 'Não houve alteração em Data/Hora a ser salva',
          });
          return;
        }

        await ApiClient.doRequest({
          uri: `locacao-reserva-vaga/common-user/${dadoState?.viewData?.uuid}`,
          method: ApiClient.PATCH,
          body: {
            dates: reservasData?.dates,
            hours: reservasData?.hours,
          },
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title: 'Reservas de Vagas para Filmagens Internas',
          text: 'As alterações foram enviadas para avaliação da SPFilm',
        });

        history.push('/home-user');
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Reservas de Vagas para Filmagens Internas',
        text: 'Ocorreu um erro ao tentar gravar Reserva de Vagas Filmagens Internas. Verifique os dados.',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  // handle true/false
  function handleBaseApoioTrue(e) {
    e.preventDefault();
    setBaseApoio(true);
    setErrorsForm(state => ({
      ...state,
      baseApoio: false,
    }));
  }

  function handleBaseApoioFalse(e) {
    e.preventDefault();
    setBaseApoio(false);
    setErrorsForm(state => ({
      ...state,
      baseApoio: false,
    }));
  }

  function handleReservasVagasTrue(e) {
    e.preventDefault(e);
    setReservasVagas(true);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleReservasVagasFalse(e) {
    e.preventDefault(e);
    setReservasVagas(false);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleGeradorTrue(e) {
    e.preventDefault();
    setGerador(true);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorFalse(e) {
    e.preventDefault();
    setGerador(false);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleLocalFilmagemTrue(e) {
    e.preventDefault();
    setLocalFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleLocalFilmagemFalse(e) {
    e.preventDefault();
    setLocalFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleBlimpadorTrue(e) {
    e.preventDefault();
    setBlimpado(true);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBlimpadorFalse(e) {
    e.preventDefault();
    setBlimpado(false);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleDroneTrue(e) {
    e.preventDefault();
    setDrone(true);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleDroneFalse(e) {
    e.preventDefault();
    setDrone(false);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleAssociarLocacaoTrue(e) {
    e.preventDefault();
    setAssociarLocacao(true);
    setErrorsForm(state => ({
      ...state,
      associarLocacao: false,
    }));
  }

  function handleAssociarLocacaoFalse(e) {
    e.preventDefault();
    setAssociarLocacao(false);
    setErrorsForm(state => ({
      ...state,
      associarLocacao: false,
    }));
  }

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={!isAdminRequest}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_impacto_uuid"
                      label="Você classifica sua filmagem como sendo:"
                      placeholder="Selecione"
                      required
                      questionTooltip="Clique aqui para saber mais"
                      questionOnClick={() => setOpenModalPorteFilmagem(true)}
                      options={impactoSelectList}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange('ld_impacto_uuid', value);
                        setShowDescrAltoImpactoLocalField(
                          value.label === 'alto impacto no local',
                        );
                      }}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={showDescrAltoImpactoLocalField}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descr_alto_impacto_local"
                      label="Comente qual o desafio da sua produção para auxiliarmos em sua viabilização"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descr_alto_impacto_local
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w50>
                    <InputMask
                      name="cep"
                      label="CEP"
                      mask="99999-999"
                      onBlur={handleChangeCep}
                      required
                      disabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="street"
                      label="Endereço da Via"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.street
                      }
                      disabled
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.neighborhood
                      }
                      disabled
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="number"
                      label="Número"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.number
                      }
                      disabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showNumberFields}>
                    <LimiterInputWidth>
                      <Select
                        name="ld_complemento_numero_uuid"
                        label="Complemento"
                        required
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={complementoNumeroSelectList}
                        onChange={value => {
                          handleSelectChange(
                            'ld_complemento_numero_uuid',
                            value,
                          );
                        }}
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                  <LimiterInputWidth w50>
                    <Select
                      name="select_extensao"
                      label="Extensão"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={selectExtensaoList}
                      required
                      onChange={value => {
                        handleSelectChange('select_extensao', value);
                      }}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <ConditionalContainer show={showExtensaoMetragemField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name="metragem"
                        label="Metragem"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData && responseLocation?.metragem
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={showExtensaoIntervalosField}>
                  <IntervalFormMultiInput
                    label="Intervalo de Numeração"
                    tooltip="De ___ até ___ (Pode adicionar mais de um intervalo)"
                    isRequired={showExtensaoIntervalosField}
                    beginKeyName="start"
                    endKeyName="end"
                    isViewMode={!isAdminRequest}
                    intervals={intervaloInputs}
                    setIntervals={setIntervaloInputs}
                  />
                </ConditionalContainer>

                <Wrap>
                  <DateFormMultiInput
                    label="Data da Reserva"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    dateKeyName="data_filmagem"
                    tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                    isAdminRequest={isAdminRequest}
                    acceptPast
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Hora da Reserva (Início e Fim)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="O horário informado deve ser referente ao início da operacionalização da reserva de vagas, e não da filmagem. Possível adicionar mais de um horário."
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="finalidades"
                      label="Finalidade da Reserva de Vagas"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={finalidadeReservaSelectList}
                      required
                      isMulti
                      onChange={handleMultiSelectFinalidades}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showOutroFinReservaField}>
                    <LimiterInputWidth>
                      <Input
                        type="text"
                        name="outro_finalidade"
                        label="Outro Finalidade Reserva"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.outro_finalidade
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Já possui local definido para a base de apoio?"
                      onClickTrue={handleBaseApoioTrue}
                      onClickFalse={handleBaseApoioFalse}
                      selected={baseApoio}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.baseApoio === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={baseApoio}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="base_address"
                        label="Endereço"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData && responseLocation?.base_address
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                    <LimiterInputWidth w25>
                      <Input
                        type="text"
                        name="base_num"
                        label="Número"
                        onChange={handleChange}
                        maxLength={11}
                        defaultValue={
                          dadoState?.viewData && responseLocation?.base_num
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <BoxHeader>
                    <span className="boxText">Entrada da Base</span>
                  </BoxHeader>
                  <Wrap>
                    <DateFormMultiInput
                      label="Data da Reserva"
                      dates={datasEntradaInputs}
                      setDates={setDatasEntradaInputs}
                      setIsLoading={setLoading}
                      isViewMode={!isAdminRequest}
                      dateKeyName="data_entrada"
                      tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                      isAdminRequest={isAdminRequest}
                      acceptPast
                      isRequired={baseApoio}
                    />

                    <HourFormMultiInput
                      label="Hora da Entrada (Início e Fim)"
                      hours={horasEntradaInputs}
                      setHours={setHorasEntradaInputs}
                      setIsLoading={setLoading}
                      isViewMode={!isAdminRequest}
                      hourBeginKeyName="inicio"
                      hourEndKeyName="fim"
                      tooltip="Incluir horário de montagem/desmontagem"
                      isAdminRequest={isAdminRequest}
                      isRequired={baseApoio}
                    />
                  </Wrap>

                  <BoxHeader>
                    <span className="boxText">Saída da Base</span>
                  </BoxHeader>
                  <Wrap>
                    <DateFormMultiInput
                      label="Data da Reserva"
                      dates={datasSaidaInputs}
                      setDates={setDatasSaidaInputs}
                      setIsLoading={setLoading}
                      isViewMode={!isAdminRequest}
                      dateKeyName="data_saida"
                      tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                      isAdminRequest={isAdminRequest}
                      acceptPast
                      isRequired={baseApoio}
                    />

                    <HourFormMultiInput
                      label="Hora da Entrada (Início e Fim)"
                      hours={horasSaidaInputs}
                      setHours={setHorasSaidaInputs}
                      setIsLoading={setLoading}
                      isViewMode={!isAdminRequest}
                      hourBeginKeyName="inicio"
                      hourEndKeyName="fim"
                      tooltip="Incluir horário de montagem/desmontagem"
                      isAdminRequest={isAdminRequest}
                      isRequired={baseApoio}
                    />
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Já possui local definido para filmagem?"
                      onClickTrue={handleLocalFilmagemTrue}
                      onClickFalse={handleLocalFilmagemFalse}
                      selected={localFilmagem}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.localFilmagem === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={localFilmagem === true}>
                  <Wrap>
                    <Input
                      type="text"
                      name="endereco_filmagem"
                      label="Endereço da Filmagem"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.endereco_filmagem
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Haverá gerador?"
                      onClickTrue={handleGeradorTrue}
                      onClickFalse={handleGeradorFalse}
                      selected={gerador}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.gerador === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={gerador === true}>
                    <LimiterInputWidth w25>
                      <ChoiceButtonLocacao
                        label="Blimpado/Silencioso ou Não-Blimpado?"
                        onClickTrue={handleBlimpadorTrue}
                        onClickFalse={handleBlimpadorFalse}
                        selected={blimpado}
                        required
                        isDisabled={!isAdminRequest}
                        error={errorsForm.blimpado === true && 'Obrigatório'}
                        yesLabelValue="Blimpado"
                        noLabelValue="Não-Blimpado"
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <ConditionalContainer show={gerador === true}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_local_gerador_uuid"
                        label="O gerador ficará:"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={localGeradorSelectList}
                        required
                        onChange={value => {
                          handleSelectChange('ld_local_gerador_uuid', value);
                        }}
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Drone"
                      onClickTrue={handleDroneTrue}
                      onClickFalse={handleDroneFalse}
                      selected={drone}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.drone === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={drone === true}>
                  <Wrap>
                    <Textarea
                      name="autorizacao_drones"
                      rows="4"
                      value="Para filmagens com drones: o Espaço Aéreo não é de administração municipal, mas sim da
                    Federação/União, portanto, não faz parte do campo de atuação da SPFilm. A autorização para o uso
                    de drones deve ser solicitada diretamente à Agência Nacional de Aviação Civil (ANAC), mediante
                    cadastro no Sistema SARPAS, disponibilizado no site do Departamento de Controle do Espaço Aéreo
                    (DECEA)."
                      disabled
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Deseja associa-lá com uma Locação Reservas de Vagas Filmagens Internas já cadastrada?"
                      onClickTrue={handleAssociarLocacaoTrue}
                      onClickFalse={handleAssociarLocacaoFalse}
                      selected={associarLocacao}
                      required
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm.associarLocacao === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={associarLocacao}>
                  <LimiterInputWidth w50>
                    <Select
                      name="reserva_vagas_film_internas_associacao"
                      label="Escolha a Locação a ser associada:"
                      placeholder="Selecione"
                      options={reservaVagasFilmInternasAssociacaoList?.map(
                        ({ uuid, protocol }) => ({
                          label: `Locação Reserva de Vaga - ${protocol}`,
                          value: { uuid, endpoint: 'locacao-reserva-vaga' },
                        }),
                      )}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange(
                          'reserva_vagas_film_internas_associacao',
                          value,
                        );
                      }}
                      required
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>

                  {Object.values(
                    formData?.reserva_vagas_film_internas_associacao || {},
                  ).length > 0 && (
                    <Wrap>
                      <FormChoiceButton
                        nomeProjeto={dadoState?.nome_projeto}
                        editData={{
                          is_admin: isAdminRequest,
                          uuid: formData?.reserva_vagas_film_internas_associacao
                            ?.uuid,
                          endpoint:
                            formData?.reserva_vagas_film_internas_associacao
                              ?.endpoint,
                        }}
                      />
                    </Wrap>
                  )}
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label=" Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData && responseLocation?.especificidades
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && reservasVagas ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="reservas_vagas_locacao"
                          label="Reservas de Vagas"
                          placeholder="Selecione"
                          options={responseLocation?.locacao_via_externa?.map(
                            ({
                              uuid,
                              address,
                              number,
                              neighborhood,
                              endpoint,
                            }) => ({
                              label: `${address}, ${number}, ${neighborhood}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('reservas_vagas_locacao', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {formData?.reservas_vagas_locacao && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            editData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.reservas_vagas_locacao?.uuid,
                              endpoint: 'reservas-vagas-vinculado',
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de reserva de vagas em via próxima?"
                      onClickTrue={handleReservasVagasTrue}
                      onClickFalse={handleReservasVagasFalse}
                      selected={reservasVagas}
                      required
                      isDisabled
                      error={errorsForm.reservasVagas === true && 'Obrigatório'}
                    />
                  )}
                </Wrap>
              </WhiteBox>

              <Chat
                uuid={responseLocation?.uuid}
                chatKeyFilter="locacao_reserva_vaga"
                locacaoNameView={responseLocation?.name_view}
              />

              <Button
                type="submit"
                btType="#26A2B1"
                label="Enviar"
                full
                width="140px"
                loading={loading}
                style={{ position: 'relative', bottom: '35px' }}
              />
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Reservas de Vagas Filmagens Internas</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Reservas
                de Vagas Filmagens Internas
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
        <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
