/* eslint-disable */
import React, { Component } from 'react';
import { Form } from '@unform/web';
import { AiOutlineRight } from 'react-icons/ai';
import { FiCheckCircle } from 'react-icons/fi';
import { BoxHeader } from '~/pages/Usuario/styles';
import { Wrap } from '~/pages/Usuario/Obra/styles';
import { Select, Textarea } from '~/components/Forms';
import { Arquivo, ContainerInputFile } from '~/styles/components';
import Button from '~/components/Button';
import { HistoricoObra } from '~/components/Usuario';
import ApiClient from '~/utils/ApiClient';
import { selectCustomStyles } from '~/pages/Usuario/SolicitacaoNovaObra';
import MeUtil from '~/utils/MeUtil';
import { FiLoader } from 'react-icons/fi';
import WhiteBox from '~/components/WhiteBox';
import {
  showModalConfirm,
  showModalMessage,
  showToastMessage,
} from '~/services/notification';
import clientResources from '~/data/clientResources';

class Chat extends Component {
  firstLoad = false;

  constructor(props) {
    super(props);

    this.state = {
      comentarios: [],
      dataFile: [],
      tipoDoc: null,
      comentarioField: null,
      selectValue: null,
      inLoad: false,
      action: 1,
      mode: null,
      unreadChat: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps === this.props) {
      return;
    }

    this.load();
  }

  async load() {
    try {
      if (
        this.props.chatKeyFilter === undefined ||
        this.props.uuid === undefined
      ) {
        return;
      }

      this.setState({
        inLoad: true,
      });

      const resultRole = await MeUtil.hasRole(
        MeUtil.ROLE_ADMINISTRADOR,
        MeUtil.ROLE_MODERADOR,
      );

      const endpoint =
        this.props.chatKeyFilter === 'work'
          ? 'comments-work'
          : 'comments-locacao';

      const response = await ApiClient.doRequest({
        expectedStatus: 200,
        method: ApiClient.GET,
        uri: `/${endpoint}?order[id]=desc&paginate=false&filters[][${this.props.chatKeyFilter}.uuid@equal]=${this.props.uuid}`,
      });

      this.setState({
        comentarios: response.data.data,
        unreadChat: response.data?.data?.some(({ lido }) => lido === 0),
        inLoad: false,
        mode: resultRole === true ? 'admin' : 'comum',
      });
    } catch (e) {
      this.setState(prevState => {
        return { ...prevState, inLoad: false };
      });
      showToastMessage({
        type: 'error',
        text: 'Não foi possível carregar o chat',
      });
    }
  }

  handleSubmit = async () => {
    try {
      if (
        this.state.comentarioField === null ||
        this.state.comentarioField === ''
      ) {
        await showModalMessage({
          type: 'error',
          text: 'Campo comentário não pode ser vazio',
        });
        return;
      }

      const form = {
        comment: this.state.comentarioField,
        tipo_doc: this.state.tipoDoc,
      };

      this.setState({
        inLoad: true,
      });

      const endpoint =
        this.props.chatKeyFilter === 'work'
          ? 'comments-work'
          : 'comments-locacao';

      form[`${this.props.chatKeyFilter}_uuid`] = this.props.uuid;

      const pdfFilesBase64Promisses = Object.values(
        this.state.dataFile || {},
      ).map(async file => {
        const base64 = await this.convertBase64(file);
        return base64.split('base64,')[1];
      });

      let pdfFilesBase64;
      await Promise.all(pdfFilesBase64Promisses).then(values => {
        pdfFilesBase64 = values;
      });

      await ApiClient.doRequest({
        uri: endpoint,
        method: ApiClient.POST,
        expectedStatus: 201,
        body: Object.assign(form, { pdf_base64: pdfFilesBase64 }),
      });

      this.setState({
        inLoad: false,
        comentarioField: '',
        dataFile: [],
        tipoDoc: '',
      });

      this.load();
    } catch (e) {
      this.setState(prevState => {
        return { ...prevState, inLoad: false };
      });
      await showModalMessage({
        type: 'error',
        text: 'Erro ao tentar salvar!',
      });
    }
  };

  handleMarkAsRead = async () => {
    try {
      this.setState(prevState => {
        return { ...prevState, inLoad: true };
      });

      if (this.props.chatKeyFilter === 'work') {
        await ApiClient.doRequest({
          uri: `read-work-comment/${this.props.uuid}`,
          method: ApiClient.PATCH,
          expectedStatus: 200,
        });
      } else {
        await ApiClient.doRequest({
          uri: `read-locacao-comment/${this.props.uuid}`,
          method: ApiClient.PATCH,
          expectedStatus: 200,
          body: {
            name_view: this.props.locacaoNameView,
          },
        });
      }

      this.setState(prevState => {
        return { ...prevState, unreadChat: false };
      });
    } catch (error) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao marcar como lido!',
      });
    } finally {
      this.setState(prevState => {
        return { ...prevState, inLoad: false };
      });
    }
  };

  handleDelete = async (commentUuid, pdfBaseUuidArray) => {
    let confirm = false;

    await showModalConfirm({
      text: 'Deseja realmente excluir o documento?',
      callback: confirmed => {
        confirm = confirmed;
      },
    });

    if (!confirm) {
      return;
    }

    const endpoint =
      this.props.chatKeyFilter === 'work'
        ? 'comments-work'
        : 'comments-locacao';

    try {
      this.setState(prevState => {
        return { ...prevState, inLoad: true };
      });

      await ApiClient.doRequest({
        uri: `${endpoint}/${commentUuid}`,
        method: ApiClient.PATCH,
        expectedStatus: 201,
        body: {
          pdf_base64: null,
          pdf_base64_uuid: pdfBaseUuidArray,
        },
      });

      await ApiClient.doRequest({
        uri: `${endpoint}/${commentUuid}`,
        method: ApiClient.DELETE,
        expectedStatus: 201,
      });

      this.setState(prevState => {
        return {
          ...prevState,
          comentarios: this.state.comentarios?.filter(
            ({ uuid }) => uuid !== commentUuid,
          ),
        };
      });

      await showModalMessage({
        type: 'success',
        text: 'Comentário excluído com sucesso!',
      });
    } catch (error) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao tentar excluir comentário.',
      });
    } finally {
      this.setState(prevState => {
        return { ...prevState, inLoad: false };
      });
    }
  };

  renderComments() {
    return (
      <>
        {this.state.comentarios.map(lista => (
          <HistoricoObra
            key={lista.uuid}
            data={lista}
            onDelete={this.handleDelete}
          />
        ))}
      </>
    );
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      comentarioField: value,
    });
  };

  convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  handleFileChange = async event => {
    event.preventDefault();

    const files = event.target.files;

    const notAllowedFiles = Object.values(files || {})
      .map(file => {
        // if (!file.name.endsWith(this.state.selectValue)) {
        if (!file.name.endsWith('.pdf')) {
          showModalMessage({
            type: 'error',
            text: `Arquivos '${file.name}' possui formato não permitido.`,
          });
          return undefined;
        }
        return file;
      })
      .some(file => file === undefined);

    if (notAllowedFiles) {
      return;
    }

    this.setState({
      dataFile: Object.values(files || {}),
    });
  };

  getList() {
    if (this.state.mode === null) {
      return [];
    }

    if (this.state.mode === 'comum') {
      return clientResources.selects.system.chatUser;
    }

    if (this.props.chatKeyFilter === 'work') {
      return clientResources.selects.system.chatObra;
    }

    if (
      this.props.chatKeyFilter !== undefined &&
      this.props.chatKeyFilter !== 'work'
    ) {
      return clientResources.selects.system.chatLocacao;
    }

    return [];
  }

  render() {
    if (
      this.props.chatKeyFilter === undefined ||
      this.props.uuid === undefined
    ) {
      return <></>;
    }

    if (this.state.inLoad) {
      return (
        <WhiteBox column jCenter aCenter marginBottom="30px">
          <Wrap style={{ justifyContent: 'center' }}>
            <FiLoader size={20} className="icon-spin" />
          </Wrap>
        </WhiteBox>
      );
    }

    return (
      <WhiteBox column jCenter aCenter marginBottom="30px">
        <Wrap>
          <Wrap
            style={{
              flexDirection: 'column',
              maxHeight: 400,
              overflowY: 'auto',
              marginRight: 20,
              paddingRight: 20,
            }}
          >
            <BoxHeader style={{ justifyContent: 'flex-start' }}>
              <span className="boxText">Histórico</span>
              {!this.state.unreadChat && (
                <FiCheckCircle
                  size={20}
                  color="#26a2b1"
                  style={{ marginLeft: 5 }}
                  title="Histórico foi lido pelo Administrador"
                  cursor="help"
                />
              )}
            </BoxHeader>
            {this.state.comentarios.length > 0 && this.renderComments()}
          </Wrap>
          <Wrap style={{ flexDirection: 'column' }}>
            <BoxHeader>
              <span className="boxText">Envio de Documentos</span>
            </BoxHeader>
            <Form>
              <Select
                name="aux_text"
                label="Ações"
                placeholder="Selecione"
                style={selectCustomStyles}
                options={this.getList()}
                onChange={value => {
                  this.setState({
                    action: value.id,
                    selectValue: value.type_file,
                    comentarioField: value.description ?? value.label,
                    tipoDoc: value.label,
                  });
                }}
              />
              {this.state.action === 'outros' ||
                (this.state.mode === 'admin' && (
                  <Textarea
                    name="comentario"
                    label="Comentário"
                    onChange={this.handleChange}
                    value={this.state.comentarioField}
                    style={{
                      height: 180,
                    }}
                  />
                ))}
              <Wrap>
                <ContainerInputFile>
                  <Arquivo style={{ alignContent: 'flex-end' }}>
                    {this.state.selectValue != null && (
                      <>
                        <label htmlFor="fileComentario">Arquivo</label>
                        <span>
                          {Object.values(this.state.dataFile || {}).map(
                            file => (
                              <p>{file.name}</p>
                            ),
                          )}
                          {Object.values(this.state.dataFile || {}).length <=
                            0 && 'Nenhum arquivo selecionado'}
                        </span>
                        <input
                          style={{
                            width: '100%',
                            height: 50,
                          }}
                          multiple
                          className="arquivo"
                          type="file"
                          id="fileComentario"
                          name="fileComentario"
                          defaultValue=""
                          accept={this.state.selectValue}
                          onChange={this.handleFileChange}
                        />
                        <button type="button" style={{ alignSelf: 'flex-end' }}>
                          Selecionar
                        </button>
                      </>
                    )}
                    {this.state.action === 'outros' && (
                      <Select
                        name="type_file"
                        label="Tipo de arquivo"
                        placeholder=""
                        style={selectCustomStyles}
                        options={[
                          {
                            id: '.pdf',
                            label: 'PDF',
                          },
                          {
                            id: '.png,.jpeg',
                            label: 'Foto',
                          },
                        ]}
                        onChange={value => {
                          this.setState({
                            selectValue: value.id,
                          });
                        }}
                      />
                    )}
                  </Arquivo>
                </ContainerInputFile>
              </Wrap>
              <Wrap>
                {this.props.isAdminRequest && this.state.unreadChat && (
                  <Button
                    btType="grey"
                    label="Marcar como lido"
                    full
                    width="170px"
                    onClick={this.handleMarkAsRead}
                  />
                )}
                <Button
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="170px"
                  icon={<AiOutlineRight size={20} />}
                  action={this.handleSubmit}
                />
              </Wrap>
            </Form>
          </Wrap>
        </Wrap>
      </WhiteBox>
    );
  }
}

export default Chat;
