import { types as Parque } from "./types";

export function childToParentRequest(data) {
  return {
    type: Parque.REQUEST,
    payload: {
      data,
    },
  };
}

export function childToParentSuccess(data) {
  return {
    type: Parque.RESPONSE,
    payload: {
      data,
    },
  };
}
