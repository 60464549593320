import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AiFillPrinter } from 'react-icons/ai';
import { FiLoader } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BsFilterLeft, BsFilter } from 'react-icons/bs';
import { Input } from '~/components/Forms';

import {
  PageContainer,
  TableHeader,
  LimiterInputWidth,
} from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import {
  Container,
  Wrap,
  WrapButtons,
  Options,
  TableTopContent,
  TableButtons,
} from './styles';

import ListaPageLocacoes from '~/components/Admin/Home/ListPageLocacoes';
import ApiClient from '~/utils/ApiClient';
import { showToastMessage } from '~/services/notification';
import buildQueryFilters from '~/functions/buildQueryFilters';
import WhiteBox from '~/components/WhiteBox';
import Option from '~/components/Option';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#ffffff',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
  }),
};

function Locacao() {
  const [item, setItem] = useState(0);
  const [formData, setFormData] = useState({});
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [locacoesList, setLocacoesList] = useState([]);
  const [locacaoStatusList, setLocacaoStatusList] = useState([
    { label: 'Carregando...', value: '' },
  ]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState('');
  const [searchFormData, setSearchFormData] = useState({});

  const formRef = useRef();

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  const locacaoStatusOrderedList = [
    { label: 'Nova Locação', value: '' },
    { label: 'Em Andamento', value: '' },
    { label: 'Cancelados', value: '' },
    { label: 'Aprovadas', value: '' },
    { label: 'Pendente', value: '' },
    { label: 'Negados', value: '' },
    { label: 'Inválida', value: '' },
    { label: 'Outra adm.', value: 'Outra administração' },
    { label: 'Excluídas', value: '' },
    { label: 'Carta de Apoio', value: '' },
  ];

  const renderLocacaoStatusList = useCallback(async () => {
    try {
      setLoading(true);

      const result = await ApiClient.doRequest({
        uri: `locacao-status`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const locacaoStatusResp = result.data.data;

      const serializedLocacaoStatusList = locacaoStatusOrderedList.map(
        itemwso =>
          locacaoStatusResp
            .map(itemws => {
              if ((itemwso?.value || itemwso.label) === itemws.name) {
                return {
                  value: itemwso?.value,
                  label: itemwso.label,
                  uuid: itemws.uuid,
                };
              }
              return null;
            })
            .filter(itemws => itemws !== null)[0],
      );

      setLocacaoStatusList(serializedLocacaoStatusList);
    } catch (e) {
      showToastMessage({
        type: 'error',
        text: 'Não foi possível listar os status de Locações',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const renderLocacoes = useCallback(async () => {
    try {
      setLoading(true);

      const locacaoStatusName =
        locacaoStatusList[item]?.value || locacaoStatusList[item]?.label;

      const result = await ApiClient.doRequest({
        uri: `locacoes?page=${page}&status_name=${locacaoStatusName}${filters}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setTotPage(result.data.last_page);

      const locacoesResponse = result?.data?.data;
      const locacoesAux = locacoesResponse?.map(ilocacao => {
        return {
          uuid: ilocacao.uuid,
          locacaoDefaultUuid: ilocacao.locacao_default?.uuid,
          locacaoNumber: ilocacao.protocol,
          locacaoName: ilocacao.titulo_locacao,
          locacaoTipo: ilocacao.name_view,
          obraName: ilocacao?.work?.titulo_obra,
          produtoraName: ilocacao?.work?.branch?.fantasy_name,
          obraId: ilocacao?.work?.protocol,
          status:
            ilocacao?.locacao_default?.locacao_status?.name ||
            ilocacao?.locacao_status?.name,
          responsavelObra:
            ilocacao?.work?.proprietario_user?.user_personal_data?.name,
          responsavelAtendimento:
            ilocacao?.work?.responsavel_user?.user_personal_data?.name,
          endpoint: ilocacao.endpoint,
          created_at: ilocacao.created_at,
          ...ilocacao,
        };
      });

      setLocacoesList(locacoesAux);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showToastMessage({
        type: 'error',
        text: 'Não foi possível listar as Locações',
      });
    }
  }, [locacaoStatusList, item, page, filters]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        // const schema = Yup.object().shape({
        //   resp_atendimento: Yup.string().test(
        //     'responsavel-atendimento',
        //     'Informe ao menos 2 caracteres para buscar',
        //     value => !(value && value?.length < 2),
        //   ),
        //   produtora_name: Yup.string().test(
        //     'nome-produtora',
        //     'Informe ao menos 2 caracteres para buscar',
        //     value => !(value && value?.length < 2),
        //   ),
        // });

        // await schema.validate(data, {
        //   abortEarly: false,
        // });

        setPage(1);
        setFilters(buildQueryFilters(searchFormData));
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [searchFormData],
  );

  function renderList() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && locacoesList.length <= 0) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {locacoesList.map(lista => (
          <ListaPageLocacoes
            key={lista.uuid}
            data={lista}
            loadLocacoesObra={renderLocacoes}
            // onDelete={() => handleDelete(lista.id)}
          />
        ))}
      </>
    );
  }

  function handleResetSearchFields() {
    setPage(1);
    setFilters('');
    setSearchFormData({
      protocol: { value: '' },
      created_at: { value: '' },
      status_name: { value: '' },
      produtora_name: { value: '' },
      responsavel_atend_name: { value: '' },
      obra_protocol: { value: '' },
      obra_name: { value: '' },
      name_view: { value: '' },
    });
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    setSearchFormData(state => ({
      ...state,
      [name]: {
        value: type === 'checkbox' ? checked : value,
        filterName: e.target?.getAttribute('filterName'),
      },
    }));
  }

  useEffect(() => {
    renderLocacoes();
  }, [renderLocacoes]);

  useEffect(() => {
    renderLocacaoStatusList();
  }, [renderLocacaoStatusList]);

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Options>
          {locacaoStatusList.map(locacaoStatus => (
            <Option
              active={item === locacaoStatusList.indexOf(locacaoStatus)}
              onClick={() => {
                setPage(1);
                setItem(locacaoStatusList.indexOf(locacaoStatus));
              }}
            >
              {locacaoStatus.label}
            </Option>
          ))}
        </Options>
        <WhiteBox column maxHeight>
          <>
            {hiddenButton === true && (
              <span tooltip="Exibir filtro!">
                <button
                  type="button"
                  onClick={() => {
                    setShow(true);
                    setHiddenButton(false);
                  }}
                >
                  <BsFilterLeft color="#000" size={24} />
                </button>
              </span>
            )}
            {show === true && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Wrap>
                  <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="protocol"
                      label="N.º da Locação"
                      value={searchFormData.protocol?.value}
                      onChange={handleChange}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="obra_protocol"
                      label="N.º da Obra"
                      value={searchFormData.obra_protocol?.value}
                      onChange={handleChange}
                      filterName="work_protocol"
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w25>
                    <Input
                      type="date"
                      name="created_at"
                      label="Data de Criação"
                      value={searchFormData.created_at?.value}
                      onChange={handleChange}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="name_view"
                      label="Tipo da Locação"
                      value={searchFormData.name_view?.value}
                      onChange={handleChange}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  {/* <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="produtora_name"
                      label="Nome da Produtora"
                      value={searchFormData.produtora_name?.value}
                      onChange={handleChange}
                      filterName="produtora"
                    />
                  </LimiterInputWidth> */}
                  <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="obra_name"
                      label="Nome da Obra"
                      value={searchFormData.obra_name?.value}
                      onChange={handleChange}
                      filterName="work_titulo"
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="responsavel_atend_name"
                      label="Nome do Responsável pelo Atendimento"
                      value={searchFormData.responsavel_atend_name?.value}
                      onChange={handleChange}
                      filterName="responsavel"
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <WrapButtons>
                    <Button
                      type="submit"
                      btType="#26A2B1"
                      label="Buscar"
                      full
                      width="170px"
                    />
                    <Button
                      btType="#707683"
                      onClick={handleResetSearchFields}
                      label="Limpar"
                      full
                      width="100px"
                    />
                    <span tooltip="Ocultar filtro">
                      <button
                        type="button"
                        onClick={() => {
                          setShow(false);
                          setHiddenButton(true);
                        }}
                      >
                        <BsFilter color="#000" size={24} />
                      </button>
                    </span>
                  </WrapButtons>
                </Wrap>
              </Form>
            )}
          </>
          <table>
            <TableTopContent>
              <TableButtons>
                <Link
                  to={{
                    pathname: '/imprimir-locacoes',
                    state: { data: { locacoesList } },
                  }}
                >
                  <AiFillPrinter color="#4f4f4f" size={24} />
                  Imprimir Relatório
                </Link>
              </TableButtons>
            </TableTopContent>
            <TableHeader>
              <tr>
                <th>Data/Hora Criação</th>
                <th>Nº da Locação</th>
                <th>Nome da Locação</th>
                <th>Espaço da Locação</th>
                <th>Nome da Obra</th>
                <th>Produtora</th>
                <th>Nº da Obra</th>
                <th>Resp. Atendimento</th>
                <th>Ações</th>
              </tr>
            </TableHeader>
            <WhiteBox column aCenter>
              <tbody>{renderList()}</tbody>
              <BasicPagination />
            </WhiteBox>
          </table>
        </WhiteBox>
      </Container>
    </PageContainer>
  );
}

export default withRouter(Locacao);
