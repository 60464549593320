import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';

import { AiOutlineRight, AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { BiLoaderAlt } from 'react-icons/bi';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Button as ButtonMUI } from '@material-ui/core';
import Button from '~/components/Button';

import {
  ButtonBox,
  Wrap,
  LogOut,
  BannerContent,
  PageContent,
  TextBanner,
  FormTitle,
  PageFooter,
  BannerBackground,
  FormSideLeft,
  FormSideRight,
  RowContainer,
  AvatarContainer,
  CheckContainer,
  FormContainer,
  AutocompleteContainer,
  TermoBody,
  TermoFooter,
  MainLoading,
  CenterHeaderIcons,
} from './styles';

import './styles.css';

import {
  PageContainer,
  ActionInputMulti,
  MultiInputContainer,
  ContainerMultiInputActions,
} from '~/styles/components';

import { langs } from '~/data/catalogoTranslation';

import {
  Input,
  Textarea,
  ChoiceButtonLocacao,
  Select,
  FilePerfil,
  InputNoForm,
} from '~/components/Forms';

import {
  showModalConfirm,
  showModalMessage,
  showToastMessage,
} from '~/services/notification';

import { useCurrentUser } from '~/hooks/currentUser';
import { useAuth } from '~/hooks/auth';
import { useList } from '~/hooks/useFunction';
import ApiClient from '~/utils/ApiClient';
import CheckboxHtml from '~/components/Forms/CheckboxHtml';

import diffSitesCatalogo from './functions/diffSitesCatalogo';
import FlagIcon from '~/components/FlagIcon';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function Perfil() {
  const formRef = useRef(null);
  const avatarFileRef = useRef(null);
  const history = useHistory();

  const {
    state: { dadosPerfil },
    state: { trigger },
  } = useLocation();

  const { user, signOut } = useAuth();
  const { user: currentUser } = useCurrentUser();

  const [formDados, setFormDados] = useState({});
  const [responseDados, setResponseDados] = useState({});
  const [errorsForm, setErrorsForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [sitesList, setSitesList] = useState([{ url: '' }]);

  const [showMinibioIngles, setShowMinibioIngles] = useState(null);

  // choiceButtons states
  const [editMode, setEditMode] = useState(false);
  const [expFilmagem, setExpFilmagem] = useState(null);
  const [expProjeto, setExpProjeto] = useState(null);
  const [sustentabilidade, setSustentabilidade] = useState(null);
  const [shareInfo, setShareInfo] = useState(null);

  const dataLogado = JSON.parse(localStorage.getItem('persist:Spcine'));

  let langStorage = localStorage.getItem('@language');

  if (
    !langStorage ||
    langStorage === 'null' ||
    langStorage === null ||
    langStorage === undefined
  ) {
    localStorage.setItem('@language', 'BR');

    langStorage = localStorage.getItem('@language');
  }

  const fieldLang = {
    BR: 'name',
    EN: 'name_eng',
    ES: 'name_esp',
  };

  function logOut() {
    signOut();
  }

  const handleDeleteProfile = useCallback(async () => {
    let confirm = false;

    await showModalConfirm({
      text: 'Confirma a exclusão do perfil?',
      callback: confirmed => {
        confirm = confirmed;
      },
    });

    if (!confirm) {
      return;
    }

    try {
      setLoading(true);

      const uri = currentUser?.cnpj
        ? 'catalogo-profile-pj'
        : 'catalogo-profile-pf';

      await ApiClient.doRequest({
        uri: `${uri}/${responseDados?.uuid}`,
        method: ApiClient.DELETE,
        expectedStatus: 200,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Sucesso',
        text: 'Perfil excluído com sucesso!',
      });

      history.push('/escolha-acesso');
    } catch (err) {
      await showModalMessage({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao tentar excluir perfil!',
      });
    }

    setLoading(false);
  }, [currentUser?.cnpj, history, responseDados?.uuid]);

  const idiomasSelectOptions = useList({ url: `ld-idioma?paginate=false` }).map(
    ({ uuid, name }) => ({ label: name, value: uuid }),
  );

  const funcoesSelectOptions = useList({
    url: `ld-funcoes-catalogo?paginate=false`,
  }).map(lang => ({
    value: lang.uuid,
    label: lang[fieldLang[langStorage]],
  }));
  // .map(({ uuid, name }) => ({ label: name, value: uuid }));

  const atuacoesSelectOptions = useList({
    url: `ld-atuacao-catalogo?paginate=false`,
  }).map(({ uuid, name }) => ({ label: name, value: uuid }));

  const regimesSelectOptions = useList({
    url: `ld-regime-catalogo?paginate=false`,
  }).map(lang => ({
    value: lang.uuid,
    label: lang[fieldLang[langStorage]],
  }));
  const vinculosSelectOptions = useList({
    url: `ld-vinculo-institucional?paginate=false`,
  }).map(lang => ({
    value: lang.uuid,
    label: lang[fieldLang[langStorage]],
  }));

  const servicosSelectOptions = useList({
    url: `ld-servicos-ofertados?paginate=false`,
  }).map(({ uuid, name }) => ({ label: name, value: uuid }));

  const loadData = useCallback(() => {
    setExpFilmagem(responseDados?.experiencia_filmagem === 1);
    setExpProjeto(typeof responseDados?.idiomas_projetos === 'string');
    setSustentabilidade(
      typeof responseDados?.projetos_sustentabilidade === 'string',
    );
    setShareInfo(responseDados?.visible === 1);

    const sitesTmp = responseDados?.sites?.map(({ uuid, site }) => ({
      uuid,
      url: site,
    }));
    setSitesList(sitesTmp?.length > 0 ? sitesTmp : [{ url: '' }]);

    formRef.current?.setFieldValue(
      'ld_idioma',
      responseDados?.ld_idioma?.map(lang => ({
        value: lang.uuid,
        label: lang[fieldLang[langStorage]],
      })),
    );
    //   .map(({ name, uuid }) => ({
    //     label: name,
    //     value: uuid,
    //   })),
    // );

    formRef.current?.setFieldValue(
      'cargos',
      responseDados?.ld_funcoes_catalogo?.map(lang => ({
        value: lang.uuid,
        label: lang[fieldLang[langStorage]],
      })),
    );

    formRef.current?.setFieldValue(
      'ld_servico_ofertado',
      responseDados?.ld_servico_ofertado?.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    );

    formRef.current?.setFieldValue(
      'regimes',
      responseDados?.ld_regime_catalogo?.map(lang => ({
        value: lang.uuid,
        label: lang[fieldLang[langStorage]],
      })),
    );

    formRef?.current?.setFieldValue(
      'atuacoes',
      responseDados?.ld_atuacao_catalogo?.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    );

    formRef.current?.setFieldValue('ld_vinculo_institucional_uuid', {
      label: responseDados?.ld_vinculo_institucional?.[fieldLang[langStorage]],
      value: responseDados?.ld_vinculo_institucional?.uuid,
    });

    setShowMinibioIngles(typeof responseDados?.minibio_ingles === 'string');
  }, [responseDados, langStorage]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormDados(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormDados(state => ({ ...state, [name]: value }));
  }

  function handleMultiSelectChange(value, event) {
    setFormDados(state => ({
      ...state,
      [event.name]: value ? value.map(x => x.value) : [],
    }));
  }

  // handles choice buttons
  function handleExpFilmagemTrue(e) {
    e.preventDefault();
    setExpFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      expFilmagem: false,
    }));
  }

  function handleExpFilmagemFalse(e) {
    e.preventDefault();
    setExpFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      expFilmagem: false,
    }));
  }

  function handleExpProjetoTrue(e) {
    e.preventDefault();
    setExpProjeto(true);
    setErrorsForm(state => ({
      ...state,
      expProjeto: false,
    }));
  }

  function handleExpProjetoFalse(e) {
    e.preventDefault();
    setExpProjeto(false);
    setErrorsForm(state => ({
      ...state,
      expProjeto: false,
    }));
  }

  function handleSustentabilidadeTrue(e) {
    e.preventDefault();
    setSustentabilidade(true);
    setErrorsForm(state => ({
      ...state,
      sustentabilidade: false,
    }));
  }

  function handleSustentabilidadeFalse(e) {
    e.preventDefault();
    setSustentabilidade(false);
    setErrorsForm(state => ({
      ...state,
      sustentabilidade: false,
    }));
  }

  function handleShareInfoTrue(e) {
    e.preventDefault();
    setShareInfo(true);
    setErrorsForm(state => ({
      ...state,
      shareInfo: false,
    }));
  }

  function handleShareInfoFalse(e) {
    e.preventDefault();
    setShareInfo(false);
    setErrorsForm(state => ({
      ...state,
      shareInfo: false,
    }));
  }

  // handles multi input
  const handleMultiInputAddField = multiInputType => {
    if (multiInputType === 'site') {
      setSitesList(prevState => [...prevState, { url: '' }]);
    }
  };

  const handleMultiInputRemoveField = (index, multiInputType) => {
    if (multiInputType === 'site') {
      setSitesList(sitesList.filter((_, idx) => idx !== index));
    }
  };

  const handleMultiInputChange = (index, event, multiInputType) => {
    if (multiInputType === 'site') {
      const values = [...sitesList];
      values[index][event.target.name] = event.target.value;
      setSitesList(values);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        if (!dadosPerfil) {
          if (currentUser?.cnpj) {
            await ApiClient.doRequest({
              uri: `catalogo-profile-pj?filters[][branch.uuid@equal]=${currentUser?.uuid}`,
              method: ApiClient.GET,
              expectedStatus: 200,
            }).then(response => {
              setResponseDados(
                response.data.data.length > 0
                  ? response.data.data[0]
                  : response.data.data,
              );
              setEditMode(response?.data?.data[0]?.uuid === undefined);
              setLoading(false);
            });
          } else {
            await ApiClient.doRequest({
              uri: `catalogo-profile-pf?filters[][user.uuid@equal]=${currentUser?.uuid}`,
              method: ApiClient.GET,
              expectedStatus: 200,
            }).then(response => {
              setResponseDados(
                response.data.data.length > 0
                  ? response.data.data[0]
                  : response.data.data,
              );
              setEditMode(response?.data?.data[0]?.uuid === undefined);
              setLoading(false);
            });
          }
        } else {
          setTimeout(() => setLoading(false), 3000);
          setResponseDados(dadosPerfil);
        }
      } catch (err) {
        showToastMessage({
          type: 'error',
          text: 'Erro ao buscar dados de Perfil',
        });
        setLoading(false);
      }
    }
    fetchData();
  }, [dadosPerfil, currentUser]);

  const convertBase64 = arquivo => {
    if (!arquivo) {
      return new Promise((resolve, reject) => resolve(null));
    }
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  const handleValidationFields = async data => {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        cargos: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'cargos-selecionado',
            defaultMsg,
            value =>
              !(
                (value?.length <= 0 || !value) &&
                !currentUser?.cnpj &&
                !dadosPerfil?.fantasy_name
              ),
          ),
        ld_servico_ofertado: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'servico-ofertado-selecionado',
            defaultMsg,
            value =>
              !(
                (value?.length <= 0 || !value) &&
                (currentUser?.cnpj || dadosPerfil?.fantasy_name)
              ),
          ),
        atuacoes: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'atuacoes-selecionado',
            defaultMsg,
            value =>
              !(
                (value?.length <= 0 || !value) &&
                !currentUser?.cnpj &&
                !dadosPerfil?.fantasy_name
              ),
          ),
        regimes: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'regimes-selecionado',
            defaultMsg,
            value =>
              !(
                (value?.length <= 0 || !value) &&
                !currentUser?.cnpj &&
                !dadosPerfil?.fantasy_name
              ),
          ),
        ld_idioma: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'idiomas-selecionado',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
        ld_vinculo_institucional_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'vinculo-institucional-selecionado',
            defaultMsg,
            value =>
              !(
                (value?.length <= 0 || !value) &&
                !currentUser?.cnpj &&
                !dadosPerfil?.fantasy_name
              ),
          ),
        minibio: Yup.string()
          .typeError(defaultMsg)
          .test(
            'minibio',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      console.log(err);
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }
    return true;
  };

  const handleSubmit = async data => {
    try {
      data.ld_idioma = formDados.ld_idioma;
      data.cargos = formDados.cargos;
      data.atuacoes = formDados.atuacoes;
      data.regimes = formDados.regimes;
      data.ld_servico_ofertado = formDados.ld_servico_ofertado;

      if (!(await handleValidationFields(data))) return;

      const avatarBase64 = await convertBase64(
        avatarFileRef?.current?.files[0],
      );

      if (
        currentUser?.cnpj &&
        !responseDados?.files?.find(({ name }) => name === 'foto_branch') &&
        !avatarBase64
      ) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe a Logo do seu serviço.',
        });
        return;
      }

      // primeira gravacao
      if (!responseDados?.uuid && !formDados?.termos_aceitos) {
        await showModalMessage({
          type: 'error',
          text: 'Aceite os termos para efetuar a gravação dos dados!',
        });
        return;
      }

      setLoading(true);

      if (currentUser?.cnpj) {
        const currentAvatarFile = responseDados?.files?.find(
          ({ name }) => name === 'foto_branch',
        );

        const branchData = omitEmpty({
          // name: data?.name,
          // telefone: data?.telefone,
          photo_base64: avatarBase64 ? avatarBase64.split('base64,')[1] : null,
          photo_base64_uuid: avatarBase64 ? currentAvatarFile?.uuid : null,
        });

        await ApiClient.doRequest({
          uri: `branch/${currentUser?.uuid}`,
          method: ApiClient.PATCH,
          body: branchData,
          expectedStatus: 201,
        });

        const perfilPJ = omitEmpty({
          email: data?.email,
          minibio: data?.minibio,
          minibio_ingles: showMinibioIngles ? data?.minibio_ingles : null,
          site: data?.site,
          linkedin: data?.linkedin,
          instagram: data?.instagram,
          facebook: data?.facebook,
          experiencia_filmagem: expFilmagem,
          idiomas_projetos: expProjeto ? data.idiomas_projetos : null,
          ldIdioma: data.ld_idioma,
          projetos_sustentabilidade: sustentabilidade
            ? data?.projetos_sustentabilidade
            : null,
          ldServicoOfertado: data?.ld_servico_ofertado,
          visible: shareInfo,
        });

        if (responseDados?.uuid) {
          await ApiClient.doRequest({
            uri: `catalogo-profile-pj/${responseDados?.uuid}`,
            method: ApiClient.PATCH,
            body: perfilPJ,
            expectedStatus: 201,
          });
        } else {
          await ApiClient.doRequest({
            uri: 'catalogo-profile-pj',
            method: ApiClient.POST,
            body: Object.assign(perfilPJ, { branch_uuid: currentUser?.uuid }),
            expectedStatus: 201,
          });
        }
      } else {
        const currentAvatarFile = responseDados?.files?.find(
          ({ name }) => name === 'foto_user',
        );

        const userData = omitEmpty({
          // name: data?.name,
          // telefone: data?.telefone,
          drt: data?.drt,
          photo_base64: avatarBase64 ? avatarBase64.split('base64,')[1] : null,
          photo_base64_uuid: avatarBase64 ? currentAvatarFile?.uuid : null,
        });

        await ApiClient.doRequest({
          uri: `user/${currentUser?.uuid}`,
          method: ApiClient.PATCH,
          body: userData,
          expectedStatus: 201,
        });

        const perfilPF = omitEmpty({
          minibio: data?.minibio,
          minibio_ingles: showMinibioIngles ? data?.minibio_ingles : null,
          premios: data?.premios,
          imdb: data?.imdb,
          linkedin: data?.linkedin,
          instagram: data?.instagram,
          facebook: data?.facebook,
          filiacoes: data?.filiacoes,
          ldFuncoesCatalogo: data?.cargos,
          ldIdioma: data.ld_idioma,
          ldAtuacaoCatalogo: data?.atuacoes,
          ldRegimeCatalogo: data?.regimes,
          ld_vinculo_institucional_uuid: data?.ld_vinculo_institucional_uuid,
          vinculo: data?.vinculo,
          creditos: data?.creditos,
          visible: shareInfo,
        });

        if (responseDados?.uuid) {
          const diffSites = diffSitesCatalogo({
            sitesGravados: responseDados.sites?.map(({ uuid, url }) =>
              omitEmpty({
                uuid,
                url,
              }),
            ),
            sitesInput: sitesList,
          });

          await ApiClient.doRequest({
            uri: `catalogo-profile-pf/${responseDados?.uuid}`,
            method: ApiClient.PATCH,
            body: Object.assign(perfilPF, {
              sites: [omitEmpty(diffSites[0])],
            }),
            expectedStatus: 201,
          });
        } else {
          const response = await ApiClient.doRequest({
            uri: 'catalogo-profile-pf',
            method: ApiClient.POST,
            body: Object.assign(perfilPF, {
              sites: sitesList?.map(({ url }) => url),
            }),
            expectedStatus: 201,
          });
          setResponseDados(response?.data?.data);
        }
      }

      await showModalMessage({
        type: 'success',
        text: 'Perfil gravado com sucesso',
      });

      history.push('/catalogo/cadastro');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      await showModalMessage({
        type: 'error',
        text: 'Ocorreu um erro ao tentar gravar perfil. Verifique os dados.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <MainLoading loading={loading}>
          <h3>Processando Informações</h3>
          <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
        </MainLoading>
      )}

      <Header>
        <LogoIcon disabled={!dataLogado?.auth?.logado} />
        <CenterHeaderIcons>
          <FlagIcon />
          {dataLogado?.auth?.logado && (
            <LogOut onClick={() => logOut()}>
              <FiLogOut />
            </LogOut>
          )}
        </CenterHeaderIcons>
      </Header>

      <BannerHeader>
        <BannerBackground>
          <BannerContent>
            <TextBanner>
              <h1>{langs[langStorage].perfilTitleLabel}</h1>
              <span>
                Home | {langs[langStorage].cadastroLabel} |{' '}
                {langs[langStorage].perfilTitleLabel}
              </span>
            </TextBanner>
          </BannerContent>
        </BannerBackground>
      </BannerHeader>
      <PageContainer>
        <PageContent>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <WhiteBox
              marginBottom="30px"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <FormContainer>
                <FormSideLeft>
                  <AvatarContainer>
                    <FilePerfil
                      inputRef={avatarFileRef}
                      name="avatar"
                      link={
                        dadosPerfil
                          ? dadosPerfil?.files?.find(
                              ({ name }) =>
                                name === 'foto_branch' || name === 'foto_user',
                            )?.url
                          : responseDados?.files?.find(
                              ({ name }) =>
                                name === 'foto_branch' || name === 'foto_user',
                            )?.url
                      }
                      isDisabled={!editMode}
                    />
                  </AvatarContainer>
                  {(currentUser?.cnpj || dadosPerfil?.fantasy_name) && (
                    <>
                      <FormTitle>{langs[langStorage].dadosLabel}:</FormTitle>

                      <>
                        <RowContainer>
                          {!dadosPerfil && (
                            <Input
                              type="text"
                              name="razao_social"
                              label={langs[langStorage].razaoSocialLabel}
                              onChange={handleChange}
                              defaultValue={currentUser?.company_name}
                              disabled
                            />
                          )}
                          <Input
                            type="text"
                            name="fantasy_name"
                            label={langs[langStorage].nomeFantasiaLabel}
                            onChange={handleChange}
                            defaultValue={
                              dadosPerfil
                                ? dadosPerfil?.fantasy_name
                                : currentUser?.fantasy_name
                            }
                            disabled
                          />
                        </RowContainer>
                        {!dadosPerfil && (
                          <Input
                            type="text"
                            name="cnpj"
                            label={langs[langStorage].cnpjLabel}
                            onChange={handleChange}
                            defaultValue={currentUser?.cnpj}
                            disabled
                          />
                        )}
                      </>
                      <Select
                        name="ld_servico_ofertado"
                        label={langs[langStorage].servicosOfertadosLabel}
                        placeholder={langs[langStorage].selectLabel}
                        options={servicosSelectOptions}
                        style={selectCustomStyles}
                        onChange={handleMultiSelectChange}
                        required
                        isMulti
                        isDisabled={!editMode}
                      />
                    </>
                  )}

                  <>
                    {!currentUser?.cnpj && !dadosPerfil?.fantasy_name && (
                      <>
                        <FormTitle>
                          {langs[langStorage].dadosPessoaisLabel}
                        </FormTitle>
                        <Input
                          type="text"
                          name="name"
                          label={langs[langStorage].nomeLabel}
                          onChange={handleChange}
                          defaultValue={
                            dadosPerfil
                              ? dadosPerfil?.name
                              : currentUser?.user_personal_data?.name
                          }
                          disabled
                        />
                      </>
                    )}
                    {dataLogado?.auth?.logado && shareInfo && (
                      <RowContainer>
                        <Input
                          type="text"
                          name="telefone"
                          label={langs[langStorage].phoneLabel}
                          onChange={handleChange}
                          defaultValue={
                            currentUser?.phone ||
                            currentUser?.user_personal_data?.phone_mobile
                          }
                          disabled
                        />
                        <Input
                          type="email"
                          name="email"
                          label={langs[langStorage].emailLabel}
                          onChange={handleChange}
                          defaultValue={
                            typeof currentUser?.cnpj === 'number'
                              ? currentUser?.representante?.email
                              : currentUser?.email
                          }
                          disabled={!(currentUser?.cnpj && editMode)}
                        />
                      </RowContainer>
                    )}
                  </>

                  {!currentUser?.cnpj && !dadosPerfil?.fantasy_name && (
                    <>
                      <Input
                        type="text"
                        name="drt"
                        label={langs[langStorage].drtLabel}
                        onChange={handleChange}
                        defaultValue={
                          dadosPerfil
                            ? dadosPerfil?.drt
                            : currentUser?.user_personal_data?.drt
                        }
                        disabled={!editMode}
                      />
                      <Select
                        name="cargos"
                        label={langs[langStorage].cargoLabel}
                        placeholder={langs[langStorage].selectLabel}
                        options={funcoesSelectOptions}
                        style={selectCustomStyles}
                        onChange={handleMultiSelectChange}
                        required
                        isMulti
                        isDisabled={!editMode}
                      />
                      <Select
                        name="atuacoes"
                        label={langs[langStorage].atuacaoLabel}
                        placeholder={langs[langStorage].selectLabel}
                        options={atuacoesSelectOptions}
                        style={selectCustomStyles}
                        onChange={handleMultiSelectChange}
                        required
                        isMulti
                        isDisabled={!editMode}
                      />
                      <Select
                        name="regimes"
                        label={langs[langStorage].regimeTrabalhoLabel}
                        placeholder={langs[langStorage].selectLabel}
                        options={regimesSelectOptions}
                        style={selectCustomStyles}
                        onChange={handleMultiSelectChange}
                        required
                        isMulti
                        isDisabled={!editMode}
                      />
                      <Select
                        name="ld_vinculo_institucional_uuid"
                        label={langs[langStorage].vinculosLabel}
                        placeholder={langs[langStorage].selectLabel}
                        options={vinculosSelectOptions}
                        style={selectCustomStyles}
                        onChange={value => {
                          handleSelectChange(
                            'ld_vinculo_institucional_uuid',
                            value,
                          );
                        }}
                        isDisabled={!editMode}
                        required
                      />
                      {formDados?.ld_vinculo_institucional_uuid && (
                        <Input
                          type="text"
                          name="vinculo"
                          label="Especifique"
                          onChange={handleChange}
                          defaultValue={responseDados?.vinculo}
                          disabled={!editMode}
                        />
                      )}
                    </>
                  )}
                  <Textarea
                    type="text"
                    name="minibio"
                    label={langs[langStorage].miniBioLabel}
                    onChange={handleChange}
                    defaultValue={responseDados?.minibio}
                    rows="5"
                    required
                    disabled={!editMode}
                  />

                  <Select
                    name="ld_idioma"
                    label={langs[langStorage].idiomasLabel}
                    placeholder={langs[langStorage].selectLabel}
                    required
                    isMulti
                    options={idiomasSelectOptions}
                    style={selectCustomStyles}
                    onChange={(value, event) => {
                      handleMultiSelectChange(value, event);
                      setShowMinibioIngles(
                        value?.some(x => x.label === 'Inglês'),
                      );
                    }}
                    isDisabled={!editMode}
                  />

                  {showMinibioIngles && (
                    <Textarea
                      type="text"
                      name="minibio_ingles"
                      label={langs[langStorage].miniBioInglesLabel}
                      onChange={handleChange}
                      defaultValue={responseDados?.minibio_ingles}
                      rows="5"
                      disabled={!editMode}
                    />
                  )}
                </FormSideLeft>
                <FormSideRight>
                  {!!responseDados?.uuid &&
                    dataLogado?.auth?.logado &&
                    trigger === 'catalogo' && (
                      <>
                        <ToggleButtonGroup
                          value={editMode}
                          onChange={() => setEditMode(!editMode)}
                          name="edit-mode"
                          id="edit-mode"
                          exclusive
                          size="medium"
                          style={{ alignSelf: 'flex-end' }}
                        >
                          <ToggleButton
                            id="btnEditar"
                            selected={editMode}
                            sx={{
                              color: 'info.main',
                            }}
                          >
                            {langs[langStorage].editarLabel}
                          </ToggleButton>
                          <ButtonMUI
                            id="btnExcluir"
                            onClick={handleDeleteProfile}
                          >
                            {langs[langStorage].deletarLabel}
                          </ButtonMUI>
                        </ToggleButtonGroup>
                      </>
                    )}
                  {(currentUser?.cnpj || dadosPerfil?.fantasy_name) && (
                    <>
                      <FormTitle style={{ marginTop: 15, marginBottom: 15 }}>
                        {langs[langStorage].enderecoLabel}
                      </FormTitle>
                      <RowContainer>
                        <Input
                          type="text"
                          name="logradouro"
                          label={langs[langStorage].logradouroLabel}
                          onChange={handleChange}
                          defaultValue={
                            dadosPerfil
                              ? dadosPerfil?.street
                              : currentUser?.street
                          }
                          disabled
                        />
                        <Input
                          type="text"
                          name="numero"
                          label={langs[langStorage].numeroLabel}
                          onChange={handleChange}
                          defaultValue={
                            dadosPerfil
                              ? dadosPerfil?.number
                              : currentUser?.number
                          }
                          disabled
                        />
                      </RowContainer>
                      <RowContainer>
                        <Input
                          type="text"
                          name="bairro"
                          label={langs[langStorage].bairroLabel}
                          onChange={handleChange}
                          defaultValue={
                            dadosPerfil
                              ? dadosPerfil?.neighborhood
                              : currentUser?.neighborhood?.name
                          }
                          disabled
                        />
                        <Input
                          type="text"
                          name="cidade"
                          label={langs[langStorage].cidadeLabel}
                          onChange={handleChange}
                          defaultValue={
                            dadosPerfil
                              ? dadosPerfil?.city
                              : currentUser?.neighborhood?.city?.name
                          }
                          disabled
                        />
                      </RowContainer>
                    </>
                  )}

                  <FormTitle style={{ marginTop: 15, marginBottom: 15 }}>
                    {langs[langStorage].rdSocialLabel}
                  </FormTitle>

                  <>
                    <Input
                      type="text"
                      name="facebook"
                      label={langs[langStorage].facebookLabel}
                      onChange={handleChange}
                      defaultValue={responseDados?.facebook}
                      disabled={!editMode}
                    />
                    <Input
                      type="text"
                      name="linkedin"
                      label={langs[langStorage].linkedinLabel}
                      onChange={handleChange}
                      defaultValue={responseDados?.linkedin}
                      disabled={!editMode}
                    />
                    <Input
                      type="text"
                      name="instagram"
                      label={langs[langStorage].instagramLabel}
                      onChange={handleChange}
                      defaultValue={responseDados?.instagram}
                      disabled={!editMode}
                    />
                  </>

                  {(currentUser?.cnpj || dadosPerfil?.fantasy_name) && (
                    <Input
                      type="text"
                      name="site"
                      label={langs[langStorage].siteLabel}
                      onChange={handleChange}
                      defaultValue={responseDados?.site}
                      disabled={!editMode}
                    />
                  )}
                  {!currentUser?.cnpj && !dadosPerfil?.fantasy_name && (
                    <>
                      <FormTitle style={{ marginBottom: 15 }}>
                        {langs[langStorage].relacoesLabel}
                      </FormTitle>
                      <Input
                        type="text"
                        name="filiacoes"
                        label={langs[langStorage].filiacoesLabel}
                        onChange={handleChange}
                        defaultValue={responseDados?.filiacoes}
                        disabled={!editMode}
                      />

                      <AutocompleteContainer>
                        <span>{langs[langStorage].siteLabel}</span>
                        <MultiInputContainer style={{ margin: 0 }}>
                          {sitesList &&
                            sitesList?.map((inputField, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Wrap key={`${inputField}~${index}`}>
                                <InputNoForm
                                  type="text"
                                  name="url"
                                  disabled={!editMode}
                                  value={inputField.url}
                                  onChange={event =>
                                    handleMultiInputChange(index, event, 'site')
                                  }
                                />

                                {false && editMode && (
                                  <ContainerMultiInputActions
                                    style={{
                                      marginLeft: '8px',
                                    }}
                                  >
                                    {sitesList.length !== 1 && (
                                      <ActionInputMulti
                                        type="button"
                                        onClick={() =>
                                          handleMultiInputRemoveField(
                                            index,
                                            'site',
                                          )
                                        }
                                      >
                                        <AiOutlineMinus
                                          color="#AAA"
                                          size={24}
                                        />
                                      </ActionInputMulti>
                                    )}
                                    {sitesList.length - 1 === index && (
                                      <ActionInputMulti
                                        type="button"
                                        onClick={() =>
                                          handleMultiInputAddField('site')
                                        }
                                      >
                                        <AiOutlinePlus color="#AAA" size={24} />
                                      </ActionInputMulti>
                                    )}
                                  </ContainerMultiInputActions>
                                )}
                              </Wrap>
                            ))}
                        </MultiInputContainer>
                      </AutocompleteContainer>

                      <Input
                        type="text"
                        name="creditos"
                        label={langs[langStorage].creditosLabel}
                        onChange={handleChange}
                        defaultValue={responseDados?.creditos}
                        disabled={!editMode}
                      />
                      <Input
                        type="text"
                        name="premios"
                        label={langs[langStorage].nomeacoesLabel}
                        onChange={handleChange}
                        defaultValue={responseDados?.premios}
                        disabled={!editMode}
                      />
                      <Input
                        type="text"
                        name="imdb"
                        label={langs[langStorage].imdbLabel}
                        onChange={handleChange}
                        defaultValue={responseDados?.imdb}
                        disabled={!editMode}
                      />
                    </>
                  )}
                  {(currentUser?.cnpj || dadosPerfil?.fantasy_name) && (
                    <>
                      <ChoiceButtonLocacao
                        label={langs[langStorage].xpPreviaLabel}
                        onClickTrue={handleExpFilmagemTrue}
                        onClickFalse={handleExpFilmagemFalse}
                        selected={expFilmagem}
                        required
                        error={errorsForm.expFilmagem === true && 'Obrigatório'}
                        isDisabled={!editMode}
                      />
                      <ChoiceButtonLocacao
                        label={langs[langStorage].xpInternacionalLabel}
                        onClickTrue={handleExpProjetoTrue}
                        onClickFalse={handleExpProjetoFalse}
                        selected={expProjeto}
                        required
                        error={errorsForm.expProjeto === true && 'Obrigatório'}
                        isDisabled={!editMode}
                      />
                      {expProjeto === true && (
                        <Textarea
                          type="text"
                          name="idiomas_projetos"
                          label={langs[langStorage].idiomaProjetoLabel}
                          onChange={handleChange}
                          defaultValue={responseDados?.idiomas_projetos}
                          rows="2"
                          disabled={!editMode}
                        />
                      )}
                      <ChoiceButtonLocacao
                        label={langs[langStorage].sustentabilidadeLabel}
                        onClickTrue={handleSustentabilidadeTrue}
                        onClickFalse={handleSustentabilidadeFalse}
                        selected={sustentabilidade}
                        required
                        error={
                          errorsForm.sustentabilidade === true && 'Obrigatório'
                        }
                        isDisabled={!editMode}
                      />
                      {sustentabilidade === true && (
                        <Textarea
                          type="text"
                          name="projetos_sustentabilidade"
                          label={langs[langStorage].qualSustentabilidadeLabel}
                          onChange={handleChange}
                          defaultValue={
                            responseDados?.projetos_sustentabilidade
                          }
                          rows="4"
                          disabled={!editMode}
                        />
                      )}
                    </>
                  )}

                  {dataLogado?.auth?.logado && trigger === 'catalogo' && (
                    <ChoiceButtonLocacao
                      label={langs[langStorage].informacaoContratoLabel}
                      onClickTrue={handleShareInfoTrue}
                      onClickFalse={handleShareInfoFalse}
                      selected={shareInfo}
                      error={errorsForm.shareInfo === true && 'Obrigatório'}
                      isDisabled={!editMode}
                    />
                  )}
                </FormSideRight>
              </FormContainer>

              {dataLogado?.auth?.logado &&
                trigger === 'catalogo' &&
                !responseDados?.uuid && (
                  <>
                    <CheckContainer>
                      <TermoBody>
                        <p>
                          Em atenção ao cadastramento no banco de dados de
                          profissionais da Spcine, a interessada declara ciência
                          e concordância com os termos e condições abaixo:
                        </p>
                        <br />
                        <p>
                          1. O banco de dados de profissionais e prestadoras de
                          serviço do setor audiovisual tem por fundamento e
                          finalidade o atendimento do art.5º, III, do Decreto
                          Municipal nº 56.905/2016 e se presta para facilitar o
                          contato entre entidades do setor audiovisual e as
                          profissionais cadastradas.
                        </p>
                        <br />
                        <p>
                          2. O cadastramento é livre para qualquer interessada
                          que atenda aos requisitos, que será a única e
                          exclusiva responsável pela veracidade e fidedignidade
                          dos dados cadastrados, bem como por mantê-los
                          atualizados. A disponibilização do cadastro pela
                          Spcine em nenhuma hipótese deverá ser considerada uma
                          recomendação ou aval por parte da Spcine acerca da
                          interessada cadastrada.
                        </p>
                        <br />
                        <p>
                          3. Eventual contato, contratação ou formalização de
                          negócio entre entidades do setor audiovisual e
                          interessadas cadastradas serão de responsabilidade
                          exclusiva destas. Em nenhuma hipótese quaisquer destas
                          partes poderá obrigar ou assumir qualquer obrigação em
                          nome da Spcine, tampouco se utilizar da marca ou do
                          nome da Spcine para a consecução de quaisquer das
                          finalidades do negócio ou intenção associativa entre
                          elas
                        </p>
                        <br />
                        <p>
                          4. A disponibilização do cadastro pela Spcine em
                          nenhuma hipótese cria relação de representação
                          comercial, profissional ou trabalhista entre a Spcine
                          e a interessada cadastrada ou entre a Spcine e a
                          entidade do setor audiovisual, sendo estas
                          inteiramente responsáveis por seus atos e obrigações.
                          Nenhuma das partes poderá assumir ou criar qualquer
                          obrigação, expressa ou implícita, em nome da outra.
                        </p>
                        <br />
                        <p>
                          5. Em caso de eventual contratação ou formalização de
                          negócio entre entidades do setor audiovisual e
                          interessadas cadastradas, aquelas serão responsáveis
                          pelo cumprimento pontual de todas as obrigações
                          trabalhistas, previdenciárias, de segurança do
                          trabalho e contratuais relativas a suas funcionárias,
                          contratadas e colaboradoras.
                        </p>
                        <br />
                        <p>
                          6. Ao efetuarem o cadastramento, as interessadas se
                          declaram cientes e concordam que os dados cadastrados,
                          inclusive os dados pessoais e sensíveis,serão públicos
                          e acessíveis por parte de qualquer pessoa ou entidade
                          que acesse o banco de dados, na forma do art.26, §1º,
                          III, da Lei Federal nº 13.709/2018 (Lei Geral de
                          Proteção de Dados-LGPD).
                        </p>
                        <br />
                        <p>
                          7. Ao efetuarem o cadastramento, as interessadas desde
                          logo expressamente consentem com o tratamento e uso
                          compartilhado dos dados cadastrados por parte da
                          Spcine necessários à execução de políticas públicas
                          previstas em leis e regulamentos ou respaldadas em
                          contratos, convênios ou instrumentos congêneres.
                        </p>
                        <br />
                        <p>
                          8. O uso compartilhado de dados pessoais por parte da
                          Spcine atenderá exclusivamente a finalidades
                          específicas de execução de políticas públicas e
                          atribuição legal de seus órgãos e departamentos na
                          forma da Lei Municipal nº 15.929/2013 e demais normas
                          regulamentares, respeitados os princípios de proteção
                          de dados pessoais elencados no art.6º da LGPD, podendo
                          ser usados em especial para a coleta de dados
                          estatísticos do setor audiovisual na cidade de São
                          Paulo por meio do Observatório Spcine ou da São Paulo
                          Film Commission, para análise de perfil de
                          profissionais do setor audiovisual, estatísticas de
                          empregabilidade, formação etc. garantida, sempre que
                          possível, a anonimização de dados pessoais.
                        </p>
                        <br />
                        <p>
                          9. Considerando que os dados cadastrados serão de
                          acesso público e irrestrito por qualquer interessada,
                          a Spcine em nenhuma hipótese será responsável por
                          eventual utilização dos dados por terceiras para
                          finalidade diversa daquela prevista para sua própria
                          utilização nos termos do item 8 acima. A interessada
                          desde logo se declara ciente e concorda que a Spcine
                          não imporá qualquer limitação de acesso por parte de
                          terceiras aos dados cadastrados.
                        </p>
                        <br />
                        <p>
                          10. Ficam resguardados os direitos da titular dos
                          dados na forma do Capítulo III da LGPD. A interessada
                          poderá exercer os direitos de acesso, retificação,
                          cancelamento e oposição através do e-mail
                          filmesp@spcine.com.br
                        </p>
                        <br />
                        <p>
                          11. Na hipótese de eventual solicitação de eliminação
                          ou restrição de dados pela titular tornar inócuo o seu
                          cadastramento, a Spcine poderá excluir o cadastro por
                          completo nos termos do art.5º, §3º, III, do Decreto
                          Municipal nº 56.905/2016. Tal prerrogativa poderá
                          também ser exercida de ofício pela Spcine para a
                          manutenção de informações atualizadas e fidedignas no
                          banco de dados.
                        </p>
                      </TermoBody>
                    </CheckContainer>
                    <Wrap>
                      <TermoFooter>
                        <CheckboxHtml
                          name="termos_aceitos"
                          label="Li e concordo com os Termos & Condições."
                          onChange={e =>
                            setFormDados(state => ({
                              ...state,
                              termos_aceitos: e.target.checked,
                            }))
                          }
                        />
                      </TermoFooter>
                    </Wrap>
                  </>
                )}
            </WhiteBox>

            {editMode && (
              <ButtonBox>
                <Button
                  label="SALVAR"
                  btType="#26A2B1"
                  type="submit"
                  full
                  width="170px"
                  loading={loading}
                  icon={<AiOutlineRight size={18} />}
                />
              </ButtonBox>
            )}
          </Form>
        </PageContent>
      </PageContainer>
      <PageFooter>
      <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
