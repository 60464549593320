import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  h1 {
    font-family: 'InterRegular', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #000000;
    text-align: center;

    span {
      font-family: 'InterBold', sans-serif;
    }
  }
`;

export const ChoiceButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 60px;
`;

// Modal
export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: 40,
  borderRadius: 10,
  top: 0,
  left: 0,
  minHeight: 150,
  width: '90%',
  height: 'auto',
  maxWidth: 600,
  border: 'none',
};
