import React, { useEffect, useState, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { BsFillEyeFill } from 'react-icons/bs';

import Modal from 'react-modal';

import { format } from 'date-fns';

import { BiLoaderAlt } from 'react-icons/bi';
import { Form } from '@unform/web';
import { Input, File } from '~/components/Forms';

import {
  ModalContent,
  overlayStyle,
  contentStyle,
  BoxHeader,
  Wrap,
  ContentPDFDocument,
} from './styles';
import { LimiterInputWidth, MainLoading } from '~/styles/components';
import Button from '~/components/Button';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage } from '~/services/notification';

const convertBase64 = arquivo => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(arquivo);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = error => {
      reject(error);
    };
  });
};

const getBase64FromFile = async file => {
  if (!file) {
    return null;
  }
  const base64 = await convertBase64(file);
  // eslint-disable-next-line prefer-destructuring
  return base64.split('base64,')[1];
};

function ListaPageUsuarios({ data, onClose, cities, neighborhoods }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const cpfFileRef = useRef(null);
  const rgFileRef = useRef(null);
  const contratoFileRef = useRef(null);
  const docResponsavelFileRef = useRef(null);
  const cnpjFileRef = useRef(null);

  const [dataCpfFile, setDataCpfFile] = useState(null);
  const [dataRgFile, setDataRgFile] = useState(null);
  const [dataContratoFile, setDataContratoFile] = useState(null);
  const [dataDocResponsavelFile, setDataDocResponsavelFile] = useState(null);
  const [dataCnpjFile, setDataCnpjFile] = useState(null);

  useEffect(() => {
    if (data) {
      setFormData({
        name: data?.name,
        email: data?.email,
        cpf_cnpj: data?.cpf_cnpj,
        birth_date: data?.birth_date,
        fantasy_name: data?.fantasy_name,
        rg: data?.rg,
        phone_mobile: data?.phone_mobile,
        cep: data?.cep,
        street: data?.street,
        number: data?.number,
        complement: data?.complement,
        neighborhood: data?.neighborhood,
        drt: data?.drt,
        uf: data?.drt_uf,
        city: data?.city,
        city_uuid: data?.city_uuid,
        state: data?.state,
        state_abbr: data?.state_abbr,
        state_uuid: data?.state_uuid,
      });
    }
    console.log(data);
  }, [data]);

  const handleChangeCep = useCallback(async (event) => {
    setLoading(true);
    const { value } = event.target;
    if (!value || value.length < 8 || cities.length === 0) {
      setLoading(false);
      return;
    }
    let newFormData = { ...formData };
    const dataResponse = await fetch(`https://viacep.com.br/ws/${value}/json/`)
      .then(res => res.json())
      .then(response => {
        if (response.erro) {
          return false;
        }

        return {
          ...newFormData,
          street: response.logradouro,
          neighborhood: response.bairro,
          city: response.localidade,
          state_abbr: response.uf,
        };
      });
    if (!dataResponse) {
      setLoading(false);
      return;
    }
    newFormData = { ...newFormData, ...dataResponse };

    const selectedCity = cities.filter(
      city => newFormData?.city === city?.name && newFormData.state_abbr === city?.state?.abbr,
    );
    console.log(selectedCity);

    if (selectedCity.length === 0) {
      await showModalMessage({
        type: 'error',
        text: `Cidade ${newFormData?.city} não cadastrada`,
      });
      return;
    }

    // filtrar bairro existente em tabela
    const dataNeighborhood = {
      name: newFormData?.neighborhood,
      city_uuid: selectedCity[0]?.uuid,
    };

    // filtrar bairro preenchido com os já existentes em tabela
    const selectedNeighborhood = neighborhoods.filter(
      neighborhood => newFormData?.neighborhood === neighborhood?.name,
    );

    let neighborhoodCreated = [];
    // se o barro não existir na base de dados, criar bairro
    if (selectedNeighborhood?.length === 0) {
      await ApiClient.doRequest({
        uri: 'neighborhood',
        method: ApiClient.POST,
        expectedStatus: 201,
        body: dataNeighborhood,
      });

      // nova listagem de bairros em tabela
      const createdNeighborhood = await ApiClient.doRequest({
        uri: 'neighborhood?paginate=false',
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      // filtrar bairros
      neighborhoodCreated = createdNeighborhood?.data?.data.filter(
        neighborhood => newFormData?.neighborhood === neighborhood?.name,
      );
    }

    setFormData({
      ...newFormData,
      city_uuid: selectedNeighborhood?.length === 0
        ? neighborhoodCreated[0]?.city?.uuid
        : selectedNeighborhood[0]?.city?.uuid,
      neighborhood_uuid: selectedNeighborhood?.length === 0
        ? neighborhoodCreated[0]?.uuid
        : selectedNeighborhood[0]?.uuid
    });

    setLoading(false);
  }, [cities, neighborhoods, formData]);

  const handleSubmit = async () => {
    let formDataSend = { ...formData };
    let url = 'user';
    if (data?.type !== 'pf') {
      url = 'branch';

      delete formDataSend.birth_date;
      delete formDataSend.rg;
      delete formDataSend.phone_mobile;
      delete formDataSend.drt;

      formDataSend.cnpj = formDataSend.cpf_cnpj.replace(/\D/g, '');
      delete formDataSend.cpf_cnpj;

      formDataSend.company_name = formDataSend.name;
      delete formDataSend.name;

      if (dataContratoFile != null && dataContratoFile.size > 20971520) {
        await showModalMessage({
          type: 'error',
          text: 'O arquivo do Contrato Social deve ter no máximo 20Mb',
        });
        return;
      }
      if (dataDocResponsavelFile != null && dataDocResponsavelFile.size > 20971520) {
        await showModalMessage({
          type: 'error',
          text: 'O arquivo do Documento do Responsável deve ter no máximo 20Mb',
        });
        return;
      }
      if (dataCnpjFile != null && dataCnpjFile.size > 20971520) {
        await showModalMessage({
          type: 'error',
          text: 'O arquivo do Cartão CNPJ deve ter no máximo 20Mb',
        });
        return;
      }

      const contratoFileBase64 = await getBase64FromFile(dataContratoFile);
      const contratoFileBase64Key = {
        [dataContratoFile?.type?.startsWith('application')
          ? 'alvara_base64_pdf'
          : 'alvara_base64']: contratoFileBase64,
      };
      const contratoFileUuidKey = {
        [dataContratoFile?.type?.startsWith('application')
          ? 'alvara_base64_pdf_uuid'
          : 'alvara_base64_uuid']: formDataSend?.files?.find(
            ({ name }) => name === 'Contrato Social',
          )?.uuid,
      };
      const docResponsavelFileBase64 = await getBase64FromFile(
        dataDocResponsavelFile,
      );
      const docResponsavelFileBase64Key = {
        [dataDocResponsavelFile?.type?.startsWith('application')
          ? 'doc_responsavel_base64_pdf'
          : 'doc_responsavel_base64']: docResponsavelFileBase64,
      };
      const docResponsavelFileUuidKey = {
        [dataDocResponsavelFile?.type?.startsWith('application')
          ? 'doc_responsavel_base64_pdf_uuid'
          : 'doc_responsavel_base64_uuid']: formDataSend?.files?.find(
            ({ name }) => name === 'Documento do Responsável',
          )?.uuid,
      };
      const cnpjFileBase64 = await getBase64FromFile(dataCnpjFile);
      const cnpjFileBase64Key = {
        [dataCnpjFile?.type?.startsWith('application')
          ? 'cnpj_base64_pdf'
          : 'cnpj_base64']: cnpjFileBase64,
      };
      const cnpjFileUuidKey = {
        [dataCnpjFile?.type?.startsWith('application')
          ? 'cnpj_base64_pdf_uuid'
          : 'cnpj_base64_uuid']: formDataSend?.files?.find(
            ({ name }) => name === 'Cartão CNPJ',
          )?.uuid,
      };

      formDataSend = {
        ...formDataSend,
        ...contratoFileBase64Key,
        ...contratoFileUuidKey,
        ...docResponsavelFileBase64Key,
        ...docResponsavelFileUuidKey,
        ...cnpjFileBase64Key,
        ...cnpjFileUuidKey,
      };

    } else {
      console.log(dataCpfFile);
      if (dataCpfFile != null && dataCpfFile.size > 20971520) {
        await showModalMessage({
          type: 'error',
          text: 'O arquivo do CPF deve ter no máximo 20Mb',
        });
        return;
      }
      if (dataRgFile != null && dataRgFile.size > 20971520) {
        await showModalMessage({
          type: 'error',
          text: 'O arquivo do RG deve ter no máximo 20Mb',
        });
        return;
      }

      const cpfFileBase64 = await getBase64FromFile(dataCpfFile);
      const cpfFileBase64Key = {
        [dataCpfFile?.type?.startsWith('application')
          ? 'cpf_base64_pdf'
          : 'cpf_base64']: cpfFileBase64,
      };
      const cpfFileUuidKey = {
        [dataCpfFile?.type?.startsWith('application')
          ? 'cpf_base64_pdf_uuid'
          : 'cpf_base64_uuid']: formDataSend?.files?.find(
            ({ name }) => name === 'cpf',
          )?.uuid,
      };

      const rgFileBase64 = await getBase64FromFile(dataRgFile);
      const rgFileBase64Key = {
        [dataRgFile?.type?.startsWith('application')
          ? 'rg_base64_pdf'
          : 'rg_base64']: rgFileBase64,
      };
      const rgFileUuidKey = {
        [dataRgFile?.type?.startsWith('application')
          ? 'rg_base64_pdf_uuid'
          : 'rg_base64_uuid']: formDataSend?.files?.find(
            ({ name }) => name === 'rg',
          )?.uuid,
      };

      formDataSend = {
        ...formDataSend,
        ...cpfFileBase64Key,
        ...cpfFileUuidKey,
        ...rgFileBase64Key,
        ...rgFileUuidKey,
      };
    }

    const response = await ApiClient.doRequest({
      uri: `${url}/${data?.uuid}`,
      method: 'PUT',
      body: formDataSend
    });

    if (response.status !== 201) {
      await showModalMessage({
        type: 'error',
        text: response.data.error.message,
      });
      return;
    }
    await showModalMessage({
      type: 'success',
      text: 'Usuário atualizado com sucesso',
    });
    setOpen(false);
    onClose();
  }

  function handleCpfFile() {
    return cpfFileRef.current.click();
  }
  function handleRgFile() {
    return rgFileRef.current.click();
  }
  function handleContratoFile() {
    return contratoFileRef.current.click();
  }
  function handleDocResponsavelFile() {
    return docResponsavelFileRef.current.click();
  }
  function handleCnpjFile() {
    return cnpjFileRef.current.click();
  }

  function renderUserDetails() {
    return (
      <>
        <MainLoading loading={loading}>
          <h3>Processando Informações</h3>
          <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
        </MainLoading>
        <BoxHeader>
          <span className="boxText">
            {data?.type === 'pf' ? 'Dados do usuário' : 'Dados da empresa'}
          </span>
        </BoxHeader>
        <Form onSubmit={handleSubmit}>
          <Wrap>
            <Input
              type="text"
              name="nome"
              label={data?.type === 'pf' ? 'Nome' : 'Razão Social'}
              value={formData.name}
              onChange={e => setFormData({ ...formData, name: e.target.value })}
            />
          </Wrap>
          <Wrap>
            {data?.type === 'pf' && (
              <>
                <Input
                  type="text"
                  name="email"
                  label="E-mail"
                  value={formData.email}
                  onChange={e => setFormData({ ...formData, email: e.target.value })}
                />
                <Input
                  type="date"
                  name="birth_date"
                  label="Data de nascimento"
                  value={formData.birth_date}
                  onChange={e => setFormData({ ...formData, birth_date: e.target.value })}
                />
              </>
            )}
          </Wrap>
          {data?.type === 'pj' && (
            <Wrap>
              <Input
                type="text"
                name="fantasy_name"
                label="Nome Fantasia"
                value={formData.fantasy_name}
                onChange={e => setFormData({ ...formData, fantasy_name: e.target.value })}
              />
            </Wrap>
          )}
          <Wrap>
            <Input
              type="text"
              name="cpf_cnpj"
              label={data?.type === 'pf' ? 'CPF' : 'CNPJ'}
              value={formData.cpf_cnpj}
              onChange={e => setFormData({ ...formData, cpf_cnpj: e.target.value })}
            />
            {data?.type === 'pj' && (
              <Input
                type="text"
                name="phone"
                label="Telefone"
                value={formData.phone}
                onChange={e => setFormData({ ...formData, phone: e.target.value })}
              />
            )}
            {data?.type === 'pf' && (
              <>
                <Input
                  type="text"
                  name="rg"
                  label="RG/RNE"
                  value={formData.rg}
                  onChange={e => setFormData({ ...formData, rg: e.target.value })}
                />

                <Input
                  type="text"
                  name="phone_mobile"
                  label="Celular"
                  value={formData.phone_mobile}
                  onChange={e => setFormData({ ...formData, phone_mobile: e.target.value })}
                />
              </>
            )}
          </Wrap>
          <BoxHeader style={{ marginTop: 20 }}>
            <span className="boxText">Endereço</span>
          </BoxHeader>
          <Wrap>
            <LimiterInputWidth w25>
              <Input
                type="text"
                name="cep"
                label="CEP"
                value={formData.cep}
                onChange={e => setFormData({ ...formData, cep: e.target.value })}
                onBlur={handleChangeCep}
              />
            </LimiterInputWidth>
            <Input
              type="text"
              name="street"
              label="Rua"
              value={formData.street}
              onChange={e => setFormData({ ...formData, street: e.target.value })}
            />
          </Wrap>
          <Wrap>
            <LimiterInputWidth w50>
              <Input
                type="text"
                name="number"
                label="Número"
                value={formData.number}
                onChange={e => setFormData({ ...formData, number: e.target.value })}
              />
            </LimiterInputWidth>
            <Input
              type="text"
              name="complement"
              label="Complemento"
              value={formData.complement}
              onChange={e => setFormData({ ...formData, complement: e.target.value })}
            />
            <Input
              type="text"
              name="neighborhood"
              label="Bairro"
              value={formData.neighborhood}
              onChange={e => setFormData({ ...formData, neighborhood: e.target.value })}
            />
          </Wrap>
          <Wrap>
            <Input
              type="text"
              name="city"
              label="Cidade"
              value={formData?.city}
              disabled
            />
            <Input
              type="text"
              name="state"
              label="Estado"
              value={`${formData?.state} - ${formData?.state_abbr}`}
              disabled
            />
          </Wrap>
          <BoxHeader style={{ marginTop: 20 }}>
            <span className="boxText">Documentos</span>
          </BoxHeader>

          {data?.type === 'pf' && (
            <>
              <Wrap>
                <Input
                  type="text"
                  name="drt"
                  label="DRT"
                  value={formData.drt}
                  onChange={e => setFormData({ ...formData, drt: e.target.value })}
                />
                <Input
                  type="text"
                  name="drt_uf"
                  label="UF"
                  value={formData?.uf}
                  onChange={e => setFormData({ ...formData, uf: e.target.value })}
                />
              </Wrap>
              <Wrap>
                <File
                  label="CPF"
                  attach={dataCpfFile}
                  textFile="Nenhum arquivo selecionado"
                  name="cpf_file"
                  inputRef={cpfFileRef}
                  onClick={handleCpfFile}
                  onChange={event => setDataCpfFile(event.target.files[0])}
                  link={
                    formData?.files?.find(
                      ({ name }) => name === 'cpf',
                    )?.url
                  }
                />
              </Wrap>
              <span style={ { fontSize: 12, color: '#4f4f4f', marginRight: 12, marginTop: -12, float: 'right' } }>
                Tamanho máximo 20Mb
              </span>
              <Wrap>
                <File
                  label="RG"
                  attach={dataRgFile}
                  textFile="Nenhum arquivo selecionado"
                  name="rg_file"
                  inputRef={rgFileRef}
                  onClick={handleRgFile}
                  onChange={event => setDataRgFile(event.target.files[0])}
                  link={
                    formData?.files?.find(({ name }) => name === 'rg')
                      ?.url
                  }
                />
              </Wrap>
              <span style={ { fontSize: 12, color: '#4f4f4f', marginRight: 12, marginTop: -12, float: 'right' } }>
                Tamanho máximo 20Mb
              </span>
            </>
          )}

          {data?.type !== 'pf' && (
            <>
              <Wrap>
                <File
                  label="Contrato social"
                  attach={dataContratoFile}
                  textFile="Nenhum arquivo selecionado"
                  name="alvara_file"
                  inputRef={contratoFileRef}
                  onClick={handleContratoFile}
                  onChange={event => setDataContratoFile(event.target.files[0])}
                  link={
                    formData?.files?.find(
                      ({ name }) => name === "Contrato Social",
                    )?.url
                  }
                />
              </Wrap>
              <span style={ { fontSize: 12, color: '#4f4f4f', marginRight: 12, marginTop: -12, float: 'right' } }>
                Tamanho máximo 20Mb
              </span>
              <Wrap>
                <File
                  label="Documento do Responsável"
                  attach={dataDocResponsavelFile}
                  textFile="Nenhum arquivo selecionado"
                  name="doc_responsavel_file"
                  inputRef={docResponsavelFileRef}
                  onClick={handleDocResponsavelFile}
                  onChange={event => setDataDocResponsavelFile(event.target.files[0])}
                  link={
                    formData?.files?.find(({ name }) => name === "Documento do Responsável")
                      ?.url
                  }
                />
              </Wrap>
              <span style={ { fontSize: 12, color: '#4f4f4f', marginRight: 12, marginTop: -12, float: 'right' } }>
                Tamanho máximo 20Mb
              </span>
              <Wrap>
                <File
                  label="Cartão CNPJ"
                  attach={dataCnpjFile}
                  textFile="Nenhum arquivo selecionado"
                  name="cnpj_file"
                  inputRef={cnpjFileRef}
                  onClick={handleCnpjFile}
                  onChange={event => setDataCnpjFile(event.target.files[0])}
                  link={
                    formData?.files?.find(({ name }) => name === "Cartão CNPJ")
                      ?.url
                  }
                />
              </Wrap>
              <span style={ { fontSize: 12, color: '#4f4f4f', marginRight: 12, marginTop: -12, float: 'right' } }>
                Tamanho máximo 20Mb
              </span>
            </>
          )}

          {data?.files?.length > 0 && (
            <ContentPDFDocument>
              {data?.files.map(ifile => (
                <a
                  key={ifile.uuid}
                  href={ifile.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Ver {ifile.name}
                </a>
              ))}
            </ContentPDFDocument>
          )}

          <BoxHeader style={{ marginTop: 20 }}>
            <Button
              label="Salvar"
              btType="#26A2B1"
              type="submit"
              full
              width="157px"
            />
          </BoxHeader>
        </Form>
      </>
    );
  }

  return (
    <>
      <tr>
        <td>
          {data?.created_at === undefined
            ? ''
            : format(new Date(data?.created_at), 'dd/MM/yyyy')}
        </td>
        <td>{data && data?.cpf_cnpj}</td>
        <td>{data && data?.name}</td>
        {data?.type === 'pj' && <td>{data && data?.fantasy_name}</td>}
        <td className="buttons">
          <span tooltip="Mais detalhes...">
            <button type="button" onClick={() => setOpen(true)}>
              <BsFillEyeFill color="#4f4f4f" size={24} />
            </button>
          </span>
        </td>
      </tr>
      <Modal
        onRequestClose={() => setOpen(false)}
        ariaHideApp={false}
        isOpen={open}
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <ModalContent>{renderUserDetails()}</ModalContent>
      </Modal>
    </>
  );
}

export default withRouter(ListaPageUsuarios);
