import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiFillExclamationCircle } from 'react-icons/ai';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { Link, useLocation, useHistory } from 'react-router-dom';

import Modal from 'react-modal';

import ApiClient from '~/utils/ApiClient';

import { showModalMessage } from '~/services/notification';

import Button from '~/components/Button';
import BuscaCEP from '~/components/Forms/BuscaCEP';
import BuscaDocumento from '~/components/Forms/BuscaDocumento';

import {
  ButtonBox,
  Wrap,
  ModalContent,
  overlayStyle,
  contentStyle,
  ModalButton,
  ChoiceButtons,
  ActionButton,
  LimiterInputWidth,
} from './styles';

import {
  PageContainer,
  ContainerInputFile,
  Arquivo,
} from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  BoxHeader,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';

import CitiesListActions from '~/store/ducks/cities-list';
import NeighborhoodListActions from '~/store/ducks/neighborhood-list';
import userMeAction from '../../../store/ducks/userMe';

import { Input } from '~/components/Forms';
import LogoIcon from '~/components/LogoIcon';
import { useList } from '~/hooks/useFunction';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export default function PessoaFisica() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const formRef = useRef();

  const dadoState = location.state;

  const [formDados, setFormDados] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pfUuid, setPfUuid] = useState(undefined);

  const cpfFileRef = useRef(null);
  const rgFileRef = useRef(null);
  const [cpfFile, setCpfFile] = useState(undefined);
  const [rgFile, setRgFile] = useState(undefined);
  const [dataCpfFile, setDataCpfFile] = useState(undefined);
  const [dataRgFile, setDataRgFile] = useState(undefined);
  const { data: userData } = useSelector(state => state.userMe);

  const states = useList({ url: 'state?paginate=false' });

  function handleCloseModal() {
    setOpen(false);
    window.location.href('/cadastro-escolha');
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormDados(state => ({ ...state, [name]: value }));
  }

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;

    const file = event.target.files[0];

    if (file.type !== 'application/pdf') {
      await showModalMessage({
        type: 'error',
        text: 'Somente arquivos PDF estão permitidos para gravação!',
      });
      return;
    }

    const base64 = await convertBase64(file);

    if (name === 'fileRg') {
      setRgFile(base64.split('base64,'));
      setDataRgFile(file);
    }

    if (name === 'fileCpf') {
      setCpfFile(base64.split('base64,'));
      setDataCpfFile(file);
    }
  }

  function handleCpfFile() {
    return cpfFileRef.current.click();
  }

  function handleRgFile() {
    return rgFileRef.current.click();
  }

  function handlePfExists(value) {
    setPfUuid(value);
  }

  async function handleValidationFields(data) {
    const defaultMsg = 'Obrigatório';

    try {
      const schema = Yup.object().shape({
        birth_date_pf: Yup.string().typeError(defaultMsg).required(defaultMsg),
        cpf_pf: Yup.string().typeError(defaultMsg).required(defaultMsg),
        uf: Yup.string()
          .typeError(defaultMsg)
          .test(
            'uf',
            defaultMsg,
            value => !(data?.drt_numero && (!value || value.length <= 0)),
          ),
        name_pf: Yup.string().typeError(defaultMsg).required(defaultMsg),
        rg_pf: Yup.string().typeError(defaultMsg).required(defaultMsg),
        cep: Yup.string().typeError(defaultMsg).required(defaultMsg),
        number: Yup.string().typeError(defaultMsg).required(defaultMsg),
        phone_mobile_pf: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }
  }

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      if (pfUuid) {
        await showModalMessage({
          type: 'error',
          text: 'Pessoa Física já existe!',
        });
        return;
      }

      if (!(await handleValidationFields(data))) {
        return;
      }

      setLoading(true);

      try {
        formRef.current.setErrors({});

        const fileCpfName = cpfFile[1];
        const fileRgName = rgFile[1];

        formDados.cpf_base64 = fileCpfName;
        formDados.rg_base64 = fileRgName;

        formDados.rg = data.rg_pf.replace(/[^0-9]/g, '');
        formDados.cpf = data.cpf_pf.replace(/[^0-9]/g, '');
        formDados.cep = data.cep.replace(/[^0-9]/g, '');
        formDados.phone_mobile = data.phone_mobile_pf.replace(/[^0-9]/g, '');

        const dataPerson = {
          name: data?.name_pf,
          cpf: formDados?.cpf,
          rg: formDados.rg,
          phone_mobile: formDados.phone_mobile,
          cep: formDados?.cep,
          street: data?.logradouro,
          number: data?.number,
          complement: data?.complement,
          cpf_base64: formDados?.cpf_base64,
          rg_base64: formDados?.rg_base64,
          birth_date: data?.birth_date_pf,
          drt: data?.drt_numero,
          uf: data?.uf,
          address: {
            neighborhood: data?.neighborhood,
            city: data?.city,
            uf: data?.state,
          },
        };

        // criação de user pessoa física
        try {
          await ApiClient.doRequest({
            uri: 'user/set/fisica',
            method: ApiClient.POST,
            expectedStatus: 201,
            body: dataPerson,
          });
        } catch (error) {
          setLoading(false);
          await showModalMessage({
            type: 'error',
            title: 'Pessoa Física',
            text: 'Erro ao tentar gravar',
          });
          return;
        }

        await showModalMessage({
          type: 'success',
          title: 'Pessoa Física',
          text: 'Cadastro feito com sucesso',
        });

        reset();
        setCpfFile(undefined);
        setRgFile(undefined);
        setLoading(false);

        if (dadoState?.trigger === 'catalogo') {
          history.replace('/catalogo/cadastro');
        } else {
          setOpen(true);
        }
      } catch (err) {
        const validationErrors = {};
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [cpfFile, dadoState?.trigger, formDados, history, pfUuid, rgFile],
  );

  // Cities List
  const citiesListFetchCB = useCallback(() => {
    dispatch(CitiesListActions.request());
  }, [dispatch]);

  useEffect(() => {
    citiesListFetchCB();
  }, [citiesListFetchCB]);

  // Neighborhood List
  const neighborhoodListFetchCB = useCallback(() => {
    dispatch(NeighborhoodListActions.request());
  }, [dispatch]);

  useEffect(() => {
    neighborhoodListFetchCB();
  }, [neighborhoodListFetchCB]);

  // userMe
  const user = useCallback(() => {
    dispatch(userMeAction.showRequest());
  }, [dispatch]);

  useEffect(() => {
    user();
  }, [user]);

  return (
    <>
      <Header>
        <LogoIcon disabled />
      </Header>
      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Cadastro Pessoa Física</h1>
            <span>Pessoa Física</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>
      <PageContainer>
        <PageContent>
          {formDados && (
            <Form ref={formRef} autoComplete="off" onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Dados Pessoais:</span>
                  <span tooltip="Preencha seus dados pessoais">
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <BuscaDocumento
                  cpf
                  hideEmail
                  change={handleChange}
                  pfExistsFunc={handlePfExists}
                />
              </WhiteBox>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Endereço:</span>
                  <span tooltip="Preencha o Endereço">
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <BuscaCEP formRef={formRef} change={handleChange} />
              </WhiteBox>
              <WhiteBox column jCenter aCenter>
                <BoxHeader>
                  <span className="boxText">Documentos:</span>
                  <span tooltip="Faça upload do seu RG e CPF em formato PDF. Insira seu DRT caso possua">
                    <AiFillExclamationCircle />
                  </span>
                </BoxHeader>
                <Wrap>
                  <ContainerInputFile style={{ marginTop: 0 }}>
                    <label htmlFor="fileCpf">
                      CPF
                      <span className="boxText" style={{ color: 'red' }}>
                        *
                      </span>
                    </label>
                    <Arquivo>
                      <span>
                        {dataCpfFile
                          ? dataCpfFile?.name
                          : 'Nenhum arquivo selecionado'}
                      </span>

                      <input
                        className="arquivo"
                        type="file"
                        name="fileCpf"
                        ref={cpfFileRef}
                        defaultValue=""
                        onChange={handleFileChange}
                        required
                        accept="application/pdf"
                      />
                      <button type="button" onClick={handleCpfFile}>
                        Selecionar
                      </button>
                    </Arquivo>
                  </ContainerInputFile>
                  <ContainerInputFile style={{ marginTop: 0 }}>
                    <label htmlFor="fileRg">
                      RG
                      <span className="boxText" style={{ color: 'red' }}>
                        *
                      </span>
                    </label>
                    <Arquivo>
                      <span>
                        {dataRgFile
                          ? dataRgFile?.name
                          : 'Nenhum arquivo selecionado'}
                      </span>
                      <input
                        className="arquivo"
                        type="file"
                        name="fileRg"
                        ref={rgFileRef}
                        defaultValue=""
                        onChange={handleFileChange}
                        required
                        accept="application/pdf"
                      />
                      <button type="button" onClick={handleRgFile}>
                        Selecionar
                      </button>
                    </Arquivo>
                  </ContainerInputFile>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="number"
                      name="drt_numero"
                      label="DRT-Número"
                      questionTooltip="DRT é o registro junto à Delegacia Regional do Trabalho/Ministério do Trabalho requerido para exercer
                      profissões regulamentadas por lei. Conforme Lei Nº 6.533, de 24 de maio de 1978, Art. 6º – O exercício
                      das profissões de Artista e de Técnico em espetáculos de Diversões requer prévio registro na Delegacia
                      Regional do Trabalho do Ministério do Trabalho, o qual terá validade em todo o território nacional."
                      onChange={handleChange}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="uf"
                      label="UF"
                      onChange={handleChange}
                    />
                  </LimiterInputWidth>
                </Wrap>
              </WhiteBox>
              <ButtonBox>
                <Button btType="#4F4F4F" label="Voltar" full width="150px" />
                <Button
                  label="Concluir"
                  btType="#26A2B1"
                  type="submit"
                  full
                  width="157px"
                  loading={loading}
                />
              </ButtonBox>
            </Form>
          )}
        </PageContent>
      </PageContainer>
      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
      <Modal
        onRequestClose={handleCloseModal}
        isOpen={open}
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <ModalButton onClick={handleCloseModal}>X</ModalButton>
        <ModalContent>
          <h1>
            <span>Deseja cadastrar</span>
            <br />
            uma nova obra?
          </h1>
          <ChoiceButtons>
            <Link
              to={{
                pathname: '/escolha-acesso',
              }}
              style={{ width: ' 100%', maxWidth: 160 }}
            >
              <ActionButton href="/escolha-acesso">não</ActionButton>
            </Link>

            <Link
              to={{
                pathname: '/solicitacao-nova-obra',
                state: {
                  name: userData?.data?.user_personal_data?.name,
                },
              }}
              style={{ width: ' 100%', maxWidth: 160 }}
            >
              <ActionButton blue href="/solicitacao-nova-obra">
                sim
              </ActionButton>
            </Link>
          </ChoiceButtons>
        </ModalContent>
      </Modal>
    </>
  );
}
