import React, { useContext } from 'react';
import { ThemeContext } from '~/styles/ThemeProvider';
import { Option as StyledOption } from './styles';

const Option = ({ children, ...rest }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <StyledOption bgTheme={theme.background_page_color} {...rest}>
      {children}
    </StyledOption>
  );
};

export default Option;
