import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WhiteBox from '~/components/WhiteBox';

import { PageContainer, TableHeader } from '~/styles/components';

import { Container, TableTopContent } from './styles';

export default function PrintTableRelatorioLogin() {
  const { state } = useLocation();

  const { data } = state;

  useEffect(() => {
    window.print();
  }, []);

  return (
    <PageContainer>
      <Container>
        <table>
          <TableTopContent>
            <h2>Relatório de Login</h2>
          </TableTopContent>
          <TableHeader>
            <tr>
              <th>Nome</th>
              <th>Data</th>
              <th>Hora</th>
            </tr>
          </TableHeader>
          <WhiteBox column aCenter>
            <tbody>
              {data &&
                data?.map(lista => (
                  <tr key={lista.id}>
                    <td>{lista && lista?.name}</td>
                    <td>{lista && lista?.date}</td>
                    <td>{lista && lista?.time}</td>
                    <td>{lista && lista?.ip}</td>
                  </tr>
                ))}
            </tbody>
          </WhiteBox>
        </table>
      </Container>
    </PageContainer>
  );
}
