import React, { useRef, useState, useEffect, useCallback } from 'react';
import { BsFilterLeft, BsFilter } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { FiLoader } from 'react-icons/fi';
import UserListActions from '~/store/ducks/users-list';
import WorkedListActions from '~/store/ducks/work-list';
import userMeAction from '../../../store/ducks/userMe';
import { Input, Select } from '~/components/Forms';

import { PageContainer, TableHeader } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, TitleForm, Wrap, Options, WrapButtons } from './styles';

import { ListaPageEquipesAbaAtiv } from '~/components/Admin';

import WhiteBox from '~/components/WhiteBox';
import Option from '~/components/Option';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#000',
    backgroundColor: '#ffffff',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#000',
  }),
};

export const selectFormCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#000',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#000',
  }),
};

const Equipe = ({ data }) => {
  const dispatch = useDispatch();
  const { data: userList } = useSelector(state => state.usersList);
  const { data: workDataList, loading } = useSelector(state => state.workList);
  const { data: userMe } = useSelector(state => state.userMe);
  const [dataFilter, setDataFilter] = useState(null);
  const [item, setItem] = useState(1);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [show, setShow] = useState(false);
  const [selecionar, setSelecionar] = useState({});
  const [workFilterStatus, setWorkFilterStatus] = useState({});
  const [selectOptions, setSelectoptions] = useState([]);
  const [selectOptionUser, setSelectOptionUser] = useState({});

  const [workList, setWorkList] = useState(workDataList);

  const formAtivRef = useRef();
  const formObraRef = useRef();
  const formSelectRef = useRef();

  useEffect(() => {
    const uuid = userMe?.data?.uuid;
    const userPersonal = userMe?.data?.user_personal_data.name;
    const user = {
      id: uuid,
      label: userPersonal,
    };
    setSelectOptionUser(user);
  }, [userMe]);

  const userWork = useCallback(() => {
    const admin = 'Administrador';
    dispatch(
      UserListActions.request({
        roles: admin,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    userWork();
  }, [userWork]);

  const user = useCallback(() => {
    dispatch(userMeAction.showRequest());
  }, [dispatch]);

  useEffect(() => {
    user();
  }, [user]);

  const workListData = useCallback(() => {
    dispatch(
      WorkedListActions.filterRequest({
        uuid: selecionar.id || selectOptionUser.id,
      }),
    );
  }, [dispatch, selecionar, selectOptionUser]);

  useEffect(() => {
    workListData();
  }, [workListData]);

  useEffect(() => {
    setSelectoptions(userList);
  }, [userList]);

  function handleFilterStatusWork() {
    if (workFilterStatus !== '' || dataFilter !== '') {
      dispatch(
        WorkedListActions.request({
          name: workFilterStatus.label,
          date: dataFilter,
        }),
      );
    }
    return null;
  }

  function renderListAtiv() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && !workDataList.length) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {workDataList?.map(lista => (
          <ListaPageEquipesAbaAtiv
            key={lista.uuid}
            data={lista}
            // onDelete={() => handleDelete(lista.id)}
          />
        ))}
      </>
    );
  }

  function handleSelectChange(name, option) {
    const { label, id } = option;
    setSelecionar(state => ({ ...state, [name]: label, id }));
  }

  function handleFilterStatus(name, option) {
    const { label, id } = option;
    setWorkFilterStatus(state => ({ ...state, [name]: label, id }));
  }

  function handleFilterDate(e) {
    setDataFilter(e.target.value);
  }

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Wrap>
          <TitleForm>
            <h4>Equipes</h4>
          </TitleForm>
        </Wrap>
        <Form ref={formSelectRef}>
          <Wrap className="small">
            {!loading && (
              <Select
                name="select_uuid"
                label="Selecionar"
                placeholder=""
                style={selectCustomStyles}
                defaultValue={selectOptionUser}
                options={selectOptions}
                onChange={value => {
                  handleSelectChange('label', value);
                }}
              />
            )}
          </Wrap>
        </Form>
        <Options>
          <Option active={item === 1} onClick={() => setItem(1)}>
            Atividade Recente
          </Option>
        </Options>
        <WhiteBox column maxHeight>
          {item === 1 && (
            <>
              {hiddenButton === true && (
                <span tooltip="Exibir filtro!">
                  <button
                    type="button"
                    onClick={() => {
                      setShow(true);
                      setHiddenButton(false);
                    }}
                  >
                    <BsFilterLeft color="#000" size={24} />
                  </button>
                </span>
              )}

              <Form>
                {show === true && (
                  <Wrap>
                    <Input
                      type="date"
                      name="created_at"
                      label="Data"
                      value={dataFilter}
                      onChange={handleFilterDate}
                    />
                    <Input
                      type="text"
                      name="palavraChave"
                      label="Palavra Chave"
                      value={data?.palavraChave}
                      disabled
                    />
                    <WrapButtons>
                      <Button
                        btType="#26A2B1"
                        action={handleFilterStatusWork}
                        label="Buscar"
                        full
                      />
                      <span tooltip="Ocultar filtro!">
                        <button
                          type="button"
                          onClick={() => {
                            setShow(false);
                            setHiddenButton(true);
                          }}
                        >
                          <BsFilter color="#000" size={24} />
                        </button>
                      </span>
                    </WrapButtons>
                  </Wrap>
                )}
                <Wrap>
                  <table>
                    <TableHeader>
                      <tr>
                        <th>ID da Obra</th>
                        <th>Nome da Obra</th>
                        <th>Novas Locações</th>
                        <th>Alterações</th>
                        <th>Alertas de Pedidos</th>
                        <th>Alertas de Autorização</th>
                        <th>Ações</th>
                      </tr>
                    </TableHeader>
                    <WhiteBox column aCenter>
                      <tbody>{renderListAtiv()}</tbody>
                    </WhiteBox>
                  </table>
                </Wrap>
              </Form>
            </>
          )}
          {item === 2 && (
            <>
              {hiddenButton === true && (
                <span tooltip="Exibir filtro!">
                  <button
                    type="button"
                    onClick={() => {
                      setShow(true);
                      setHiddenButton(false);
                    }}
                  >
                    <BsFilterLeft color="#000" size={24} />
                  </button>
                </span>
              )}
            </>
          )}
        </WhiteBox>
      </Container>
    </PageContainer>
  );
};

export default Equipe;
