import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { FaPen } from 'react-icons/fa';
import { VscChromeClose } from 'react-icons/vsc';

import Modal from 'react-modal';

import { format } from 'date-fns';

import {
  ModalContent,
  ChoiceButtons,
  contentStyle,
  overlayStyle,
} from './styles';

import Button from '~/components/Button';
import ApiClient from '~/utils/ApiClient';

import { showModalMessage } from '~/services/notification';

import { ModalLembrete } from '~/components/Admin';
import { TdEllipsis } from '~/styles/components';

function ListaPageAvisos({ data, reloadList }) {
  const [loading, setLoading] = useState(false);
  const [openModalLembrete, setOpenModalLembrete] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);

  const handleEditAviso = useCallback(() => {
    setOpenModalLembrete(true);
  }, []);

  async function handleDeleteAviso() {
    try {
      setLoading(true);
      const result = await ApiClient.doRequest({
        uri: `reminder/${data.uuid}`,
        method: ApiClient.DELETE,
        expectedStatus: 201,
      });

      await showModalMessage({
        type: 'success',
        text: 'Exclusão realizada com sucesso',
      });
    } catch (error) {
      await showModalMessage({
        type: 'error',
        text: `Erro: ${error.message}`,
      });
    } finally {
      setOpenConfirmDialog(false);
      setLoading(false);
      reloadList();
    }
  }

  return (
    <>
      <tr>
        <td>{data && data?.title}</td>
        <TdEllipsis>{data && data?.text}</TdEllipsis>
        <td>{data && format(new Date(data?.ends), 'dd/MM/yyyy')}</td>
        <td>{data && data?.reminder_type.name}</td>
        <td className="buttons">
          <button type="button" onClick={() => handleEditAviso()}>
            <FaPen color="#4f4f4f" size={20} />
          </button>
          <button type="button" onClick={() => setOpenConfirmDialog(true)}>
            <VscChromeClose color="#4f4f4f" size={24} />
          </button>
        </td>
      </tr>
      <Modal
        onRequestClose={handleCloseModal}
        isOpen={openConfirmDialog}
        style={{
          overlay: overlayStyle,
          content: contentStyle,
        }}
      >
        <ModalContent>
          <h1>
            <span>Deseja excluir</span>
            <br />
            este aviso?
          </h1>
          <ChoiceButtons>
            <Button
              btType="#4f4f4f"
              action={() => setOpenConfirmDialog(false)}
              label="Não"
              full
              width="170px"
            />
            <Button
              btType="#26A2B1"
              action={() => handleDeleteAviso()}
              label="Sim"
              full
              loading={loading}
              width="170px"
            />
          </ChoiceButtons>
        </ModalContent>
      </Modal>

      <ModalLembrete
        editData={data}
        openModal={openModalLembrete}
        closeModal={() => setOpenModalLembrete(false)}
        loadReminder={() => reloadList()}
      />
    </>
  );
}

export default withRouter(ListaPageAvisos);
