import React, { useRef, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { BsFilterLeft, BsFilter } from 'react-icons/bs';
import { Input } from '~/components/Forms';

import {
  PageContainer,
  TableHeader,
  LimiterInputWidth,
} from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, WrapButtons, Options } from './styles';

import ApiClient from '~/utils/ApiClient';
import { showToastMessage } from '~/services/notification';
import CheckboxHtml from '~/components/Forms/CheckboxHtml';
import buildQueryPackageFilters from '~/functions/buildQueryPackageFilters';
import ListaPageDocumentosChat from '~/components/Admin/Home/ListaPageDocumentosChat';
import WhiteBox from '~/components/WhiteBox';
import Option from '~/components/Option';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#ffffff',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
  }),
};

function DocumentosChat() {
  const formRef = useRef();

  const [searchFormData, setSearchFormData] = useState({});
  const [item, setItem] = useState(0);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [documentosChatList, setDocumentosChatList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState('');

  const tabType = [
    { label: 'Locações', value: 'locacoes' },
    { label: 'Obras', value: 'obras' },
  ];

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    setSearchFormData(state => ({
      ...state,
      [name]: {
        value: type === 'checkbox' ? checked : value,
        filterName: e.target?.getAttribute('filterName'),
        filterOperator: e.target?.getAttribute('filterOperator'),
      },
    }));
  }

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  const renderDocumentosChat = useCallback(async () => {
    try {
      setLoading(true);

      let result;
      let dataList = [];

      if (tabType[item].label === 'Locações') {
        result = await ApiClient.doRequest({
          uri: `new-locacao-comments?page=${page}`,
          method: ApiClient.GET,
          expectedStatus: 200,
        });

        dataList = result?.data?.data?.map(locacao => ({
          ...locacao,
          name: locacao?.name_view,
          uuid: locacao.locacao_uuid,
        }));
      } else if (tabType[item].label === 'Obras') {
        result = await ApiClient.doRequest({
          uri: `new-comments-list?page=${page}`,
          method: ApiClient.GET,
          expectedStatus: 200,
        });

        dataList = result?.data?.data?.map(obra => ({
          ...obra,
          name: obra?.titulo_obra,
          uuid: obra.work_uuid,
          endpoint: 'work',
        }));
      }

      setTotPage(result.data?.last_page);
      setDocumentosChatList(dataList);
    } catch (e) {
      showToastMessage({
        type: 'error',
        text: `Não foi possível listar os documentos de ${tabType[item].label}`,
      });
    } finally {
      setLoading(false);
    }
  }, [item, page]);

  const handleSubmit = useCallback(
    async _ => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().test(
            'nome-informado',
            'Informe ao menos 3 caracteres para buscar',
            value => !(value && value?.length < 3),
          ),
        });

        await schema.validate(searchFormData, {
          abortEarly: false,
        });

        setPage(1);
        setFilters(buildQueryPackageFilters(searchFormData));
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [searchFormData],
  );

  function renderList() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && documentosChatList.length <= 0) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {documentosChatList.map(lista => (
          <ListaPageDocumentosChat key={lista.uuid} data={lista} />
        ))}
      </>
    );
  }

  useEffect(() => {
    renderDocumentosChat();
  }, [renderDocumentosChat]);

  function handleResetSearchFields() {
    setFilters('');
    setSearchFormData({
      created_at: { value: '' },
      id: { value: '' },
      name: { value: '' },
      read: { value: '' },
    });
  }

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Options>
          {tabType.map(type => (
            <Option
              active={item === tabType.indexOf(type)}
              onClick={() => {
                setPage(1);
                setItem(tabType.indexOf(type));
              }}
            >
              {type.label}
            </Option>
          ))}
        </Options>
        <WhiteBox column maxHeight>
          <>
            {hiddenButton === true && (
              <span tooltip="Exibir filtro!">
                <button
                  type="button"
                  onClick={() => {
                    setShow(true);
                    setHiddenButton(false);
                  }}
                >
                  <BsFilterLeft color="#000" size={24} />
                </button>
              </span>
            )}
            {show === true && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Wrap>
                  <LimiterInputWidth w25>
                    <Input
                      type="date"
                      name="created_at"
                      label="Data de Criação"
                      value={searchFormData.created_at?.value}
                      onChange={handleChange}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="id"
                      filterOperator="equal"
                      label="ID"
                      value={searchFormData.id?.value}
                      onChange={handleChange}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="name"
                      filterName="responsavel_user.name"
                      label="Nome"
                      value={searchFormData.name?.value}
                      onChange={handleChange}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w25>
                    <CheckboxHtml
                      name="read"
                      filterOperator="equal"
                      label="Lido?"
                      onChange={handleChange}
                      value={!!searchFormData.read?.value}
                      checked={!!searchFormData.read?.value}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w50>
                    <WrapButtons>
                      <Button
                        type="submit"
                        btType="#26A2B1"
                        label="Buscar"
                        full
                        width="170px"
                      />
                      <Button
                        btType="#707683"
                        onClick={handleResetSearchFields}
                        label="Limpar"
                        full
                        width="100px"
                      />
                      <span tooltip="Ocultar filtro!">
                        <button
                          type="button"
                          onClick={() => {
                            setShow(false);
                            setHiddenButton(true);
                          }}
                        >
                          <BsFilter color="#000" size={24} />
                        </button>
                      </span>
                    </WrapButtons>
                  </LimiterInputWidth>
                </Wrap>
              </Form>
            )}
          </>
          <table>
            {tabType[item].label === 'Locações' && (
              <TableHeader>
                <tr>
                  <th>Data de Criação da Locação</th>
                  <th>Nome da Locação</th>
                  <th>ID Locação</th>
                  <th style={{ justifyContent: 'center' }}>
                    Documentos não lidos
                  </th>
                  <th style={{ justifyContent: 'center' }}>Ações</th>
                </tr>
              </TableHeader>
            )}
            {tabType[item].label === 'Obras' && (
              <TableHeader>
                <tr>
                  <th>Data de Criação da Obra</th>
                  <th>Nome da Obra</th>
                  <th>ID Obra</th>
                  <th style={{ justifyContent: 'center' }}>
                    Documentos não lidos
                  </th>
                  <th style={{ justifyContent: 'center' }}>Ações</th>
                </tr>
              </TableHeader>
            )}
            <WhiteBox column aCenter>
              <tbody>{renderList()}</tbody>
              <BasicPagination />
            </WhiteBox>
          </table>
        </WhiteBox>
      </Container>
    </PageContainer>
  );
}

export default withRouter(DocumentosChat);
