import React, { useState } from 'react';
import {
  IoMdArrowDropdownCircle,
  IoMdArrowDroprightCircle,
} from 'react-icons/io';
import { BoxHeader } from '~/pages/Usuario/styles';
import { ConditionalContainer } from '~/styles/components';

export const AccordionItem = ({ opened = false, label, children }) => {
  const [isOpen, setIsOpen] = useState(opened);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <BoxHeader style={{}}>
        <button
          type="button"
          onClick={() => {
            setIsOpen(prevState => !prevState);
          }}
          style={{
            height: 22,
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {!isOpen ? (
            <IoMdArrowDroprightCircle color="#26A2B1" size={22} />
          ) : (
            <IoMdArrowDropdownCircle color="#26A2B1" size={22} />
          )}
          <span className="boxText" style={{ marginLeft: 5 }}>
            {label}
          </span>
        </button>
      </BoxHeader>
      <ConditionalContainer show={isOpen}>{children}</ConditionalContainer>
    </div>
  );
};
