import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import omitEmpty from 'omit-empty';
import { FiLoader } from 'react-icons/fi';
import { Container } from './styles';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage, showToastMessage } from '~/services/notification';
import { Wrap } from '~/pages/Usuario/Obra/styles';
import Button from '~/components/Button';
import { BoxHeader } from '~/pages/Usuario/styles';
import { Input, Select } from '~/components/Forms';
import WhiteBox from '~/components/WhiteBox';

const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function ValorLocacao({
  locacaoEndpoint,
  locacaoUuid,
  viewData,
  indexesUndefined,
}) {
  const [orgaoList, setOrgaoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [valorLocacaoData, setValorLocacaoData] = useState(
    Object.keys(omitEmpty(viewData || {})).length > 0 ? viewData : null,
  );

  // refs
  const formValorLocacaoRef = useRef();

  const getOrgaoList = useCallback(async () => {
    setLoading(true);
    try {
      const lista = await ApiClient.doRequest({
        uri: `document-initial?paginate=false`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setOrgaoList(
        lista?.data?.data?.map(({ uuid, initial, full_name }) => ({
          value: uuid,
          label: `${initial ? `${initial} - ` : ''} ${full_name}`,
        })),
      );

      setLoading(false);
    } catch (err) {
      showToastMessage({
        type: 'error',
        text: 'Falha ao buscar lista de Órgãos. Tente mais tarde!',
      });
      setLoading(false);
      setOrgaoList([]);
    }
  }, []);

  useEffect(() => {
    getOrgaoList();
  }, [getOrgaoList]);

  useEffect(() => {
    if (valorLocacaoData) {
      formValorLocacaoRef.current?.setFieldValue(
        'valor',
        valorLocacaoData?.valor,
      );
      formValorLocacaoRef.current?.setFieldValue(
        'initials',
        valorLocacaoData?.initials?.map(item => {
          return {
            label: `${item.initial} - ${item.full_name}`,
            value: item.uuid,
          };
        }),
      );
    }
  }, [loading, valorLocacaoData]);

  async function handleSubmit() {
    try {
      if (formData?.initials?.length <= 0) {
        await showModalMessage({
          type: 'error',
          text: 'Preencha os campos para salvar as informações',
        });
        return;
      }

      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri: `${locacaoEndpoint}/${locacaoUuid}`,
        method: ApiClient.PATCH,
        body: Object.assign(formData, indexesUndefined),
        expectedStatus: 201,
      });

      setValorLocacaoData(resp?.data?.data?.valor_locacao);
      await showModalMessage({
        type: 'success',
        text: 'Valor da Locação gravado com sucesso.',
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao tentar salvar o Valor da Locação.',
      });
    } finally {
      setLoading(false);
    }
  }

  function renderForm() {
    if (loading) {
      return (
        <WhiteBox column jCenter aCenter marginBottom="30px">
          <Wrap style={{ justifyContent: 'center' }}>
            <FiLoader size={20} className="icon-spin" />
          </Wrap>
        </WhiteBox>
      );
    }

    return (
      <WhiteBox column jCenter aCenter marginBottom="30px">
        <Wrap>
          <Container>
            <BoxHeader>
              <span className="boxText">Valor Locação</span>
            </BoxHeader>

            <Form ref={formValorLocacaoRef}>
              <Wrap style={{ alignItems: 'flex-end' }}>
                <Select
                  name="initials"
                  label="Órgãos"
                  placeholder="Selecione"
                  options={orgaoList}
                  style={selectCustomStyles}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      initials: Array.isArray(e) ? e.map(x => x.value) : [],
                    }))
                  }
                  isMulti
                  required
                />
                <Input
                  type="number"
                  step="0.01"
                  name="valor"
                  label="Valor Público"
                  required
                  onChange={e =>
                    setFormData(state => ({ ...state, valor: e.target.value }))
                  }
                />
                <Button
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  onClick={() => handleSubmit()}
                />
              </Wrap>
            </Form>
          </Container>
        </Wrap>
      </WhiteBox>
    );
  }

  return renderForm();
}
