import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Logotipo = styled.img`
  width: 100%;
  max-width: 210px;
  margin-bottom: 60px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`;

export const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  width: 270px;
  padding: 60px 0 0 5px;
  background-color: ${({ bgTheme }) => bgTheme || '#000'};
  height: 100vh;
  overflow-y: auto;
`;

export const BoxAside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 160px;
  padding: 24px;
  margin-top: 60px;
  background-color: #f5f5fb;
  border-radius: 12px;

  img {
    position: absolute;
    top: -40px;
  }

  p {
    text-align: center;
    color: #8da1b5;
    margin: 60px 0 16px 0;
  }
`;

export const LogOut = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 170px;
  height: 32px;
  margin: 8px 0;
  /* padding-left: 26px; */
  background-color: transparent;
  color: #272829;
  border-right: none;
  cursor: pointer;

  & > svg {
    margin-right: 18px;
    color: #ffffff;
  }

  & > span {
    font-family: 'InterSemiBold';
    font-size: 16px;
    line-height: 20px;
    color: #8da1b5;
    transition: 400ms ease;
  }
`;

export const EscolhaAcesso = styled(Link)`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 170px;
  height: 32px;
  margin: 8px 0;
  /* padding-left: 26px; */
  background-color: transparent;
  color: #272829;
  border-right: none;
  cursor: pointer;

  & > svg {
    margin-right: 18px;
    color: #ffffff;
  }

  & > span {
    font-family: 'InterSemiBold';
    font-size: 16px;
    line-height: 20px;
    color: #8da1b5;
    transition: 400ms ease;
  }
`;
