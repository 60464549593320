import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { BsFillEyeFill, BsFileEarmarkPlus } from 'react-icons/bs';
import {
  FaClipboardCheck,
  FaClipboardList,
  FaMapMarkedAlt,
  FaPen,
} from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import { format, parseISO } from 'date-fns';

function ListaPageObras({ data }) {
  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>
          {/* {data?.visit_date?.changes_to_approve ? (
            <span tooltip="Alteração a ser analisada pela SPFilm">
              <ApprovalContainer
                hasApprovalFields
                style={{ cursor: 'help', marginBottom: 0 }}
              >
                {validateDate(data?.visit_date?.value, 'yyyy-MM-dd HH:mm:ss')
                  ? format(parseISO(data?.visit_date?.value), 'dd/MM/yyyy')
                  : ''}
              </ApprovalContainer>
            </span>
          ) : (
            <span>
              {validateDate(data?.visit_date?.value, 'yyyy-MM-dd HH:mm:ss')
                ? format(parseISO(data?.visit_date?.value), 'dd/MM/yyyy')
                : ''}
            </span>
          )} */}
          {data && format(parseISO(data?.created_at), 'dd/MM/yyyy')}
        </td>
        <td style={{ textAlign: 'center' }}>{data && data?.protocol}</td>
        <td style={{ textAlign: 'center' }}>{data && data?.titulo_obra}</td>
        <td style={{ textAlign: 'center' }}>{data && data?.director?.name}</td>
        <td style={{ textAlign: 'center' }}>
          {data && data?.work_status?.name}
        </td>
        <td style={{ textAlign: 'center' }}>
          {data && data?.responsavel_user?.user_personal_data?.name}
        </td>
        <td style={{ textAlign: 'center' }}>
          {data && Object.values(data?.branch || {}).length > 0
            ? data?.branch?.company_name
            : data?.pessoa_fisica_user?.user_personal_data?.name}
        </td>
        <td
          className="buttons"
          style={{
            textAlign: 'unset',
            justifyContent: 'space-evenly',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span tooltip="Mais detalhes...">
            <Link
              to={{
                pathname: '/obra-detalhe',
                state: { dados: data },
              }}
            >
              <BsFillEyeFill color="#4f4f4f" size={24} />
            </Link>
          </span>

          <span tooltip="Editar">
            <Link
              to={{
                pathname: '/obra/edit',
                state: { data },
              }}
              style={{ margin: 2 }}
            >
              <FaPen color="#4f4f4f" size={20} />
            </Link>
          </span>

          {data?.work_status?.name !== 'Finalizado' && (
            <span tooltip="Adicionar Nova Locação...">
              <Link
                to={{
                  pathname: `/solicitacao-nova-locacao`,
                  state: {
                    uuid: data?.uuid,
                    nome_projeto: data?.director?.name,
                  },
                }}
                style={{ margin: 2 }}
                // onClick={() => setOpen(true)}
              >
                <FaMapMarkedAlt color="#4f4f4f" size={20} />
              </Link>
            </span>
          )}

          <span tooltip="Nova Carta de Apoio...">
            <Link
              to={{
                pathname: `solicitacao-nova-carta-de-apoio`,
                state: { trigger: 'obra', work_uuid: data?.uuid },
              }}
              style={{ margin: 2 }}
              // onClick={() => setOpen(true)}
            >
              <IoMdMail color="#4f4f4f" size={20} />
            </Link>
          </span>

          {data?.work_status?.name === 'Finalizado' && !data?.pesquisa_uuid && (
            <span tooltip="Realizar Pesquisa Socioeconômica...">
              <Link
                to={{
                  pathname: '/pesquisas/pesquisa-socioeconomica',
                  state: { dados: data, nome_projeto: data?.director?.name },
                }}
              >
                <FaClipboardList color="#4f4f4f" size={24} />
              </Link>
            </span>
          )}

          {data?.pesquisa_uuid && (
            <span tooltip="Visualizar Pesquisa Socioeconômica...">
              <Link
                to={{
                  pathname: '/pesquisas/pesquisa-socioeconomica',
                  state: {
                    viewData: { uuid: data?.pesquisa_uuid },
                    nome_projeto: data?.director?.name,
                  },
                }}
              >
                <FaClipboardCheck color="#4f4f4f" size={24} />
              </Link>
            </span>
          )}

          {data?.approve_requests?.some(({ approved }) => approved !== 1) && (
            <span
              tooltip="Aprovar acesso em obra..."
              style={{ cursor: 'pointer' }}
            >
              <Link
                to={{
                  pathname: 'aprovacao-obras',
                  state: data?.approve_requests,
                }}
              >
                <BsFileEarmarkPlus color="#4f4f4f" size={24} />
              </Link>
            </span>
          )}
        </td>
        {/* <Documents documents={data.documents} uuid={data.uuid} /> */}
      </tr>
      {/* <Modal
        openModal={open}
        closeModal={handleCloseModal}
        inputs={FormularioHistory}
      /> */}
    </>
  );
}

export default withRouter(ListaPageObras);
