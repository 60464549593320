import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Container } from "~/components/Forms/Checkbox/styles";

export const BoxBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .boxText {
    font-family: InterRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #26a2b1;
    margin-bottom: 20px;
  }
`;

export const MainLoading = styled.div`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;

export const ChoiceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardChoice = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  background: ${props => (props.blue ? '#48A2B1' : '#3f3f3f')};
  width: 100%;
  max-width: 480px;
  height: 300px;
  padding: 0 16px;
  border-radius: 15px;
  ${props => props.disabled && 'pointer-events: none;'}
  ${props => props.disabled && 'opacity: 0.7;'}
  ${props => props.disabled && 'cursor: not-allowed;'}
  ${props => !props.disabled && 'cursor: pointer;'}

  img {
    width: 100%;
    max-width: 100px;
  }

  h1 {
    font-family: InterRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #ffff;
    text-align: center;
  }

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const ProfilePhoto = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #000;
`;

export const RadioContainer = styled.div`
  width: 100%;
  height: 73px;
  background: #f2f2f2;
  //color: #26a2b1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
  cursor: pointer;

  h4 {
    padding: 9px 0 0 10px;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    font-family: InterRegular;
    color: #828282;
  }

  span {
    border-radius: 50px;
    background-color: #e0e0e0;
    width: 33px;
    height: 33px;

    &::after {
      left: 10px;
      top: 10px;
      width: 10px;
      height: 10px;
      background: #e0e0e0;
      border-radius: 50px;
      border-width: none;
    }
  }
`;

export const LogOut = styled.div`
  cursor: pointer;
`;
export const Access = styled(Link)`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 23px;
  & svg {
    margin-right: 4px;
  }
`;

export const ProfissionalListContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 15px;
  margin-top: 30px;

  width: 100%;
  height: 500px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6f6f6f;
  }
  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  }
`;

export const CardProfissionalList = styled.div`
  display: flex;

  border-radius: 10px;
  box-shadow: 1px 2px 5px #aaaaaa;

  margin-bottom: 30px;

  height: 130px;
  width: 100%;
`;

export const ItemProfissionalList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: ${({ bRadius }) => bRadius || '0'};

  width: ${({ width }) => width || '20%'};
  height: 100%;

  padding: 30px 15px;

  background: ${({ bgColor }) => bgColor || '#ffffff'};

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-family: 'InterSemiBold';
    font-size: 16px;
    line-height: 20px;
    background-color: #26a2b1;
    color: #ffffff;
    width: 100%;
    max-width: 250px;
    max-height: 54px;
    transition: 300ms ease;
    text-transform: uppercase;

    &:hover {
      transform: scale(0.98);
    }
  }

  & > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  & > h4,
  span {
    width: 100%;
    height: 100%;
    text-align: left;
  }

  & > h4 {
    font-family: 'InterBold', sans-serif;
    font-size: 14px;
    color: #828282;
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    font-family: 'InterRegular', sans-serif;
    font-size: 14px;
    color: #828282;
  }
`;
