export const gregorian_pt_br = {
  name: 'gregorian_pt_br',
  months: [
    ['Janeiro', 'Jan'],
    ['Fevereiro', 'Fev'],
    ['Março', 'Mar'],
    ['Abril', 'Abr'],
    ['Maio', 'Mai'],
    ['Junho', 'Jun'],
    ['Julho', 'Jul'],
    ['August', 'Ago'],
    ['Setembro', 'Set'],
    ['Outubro', 'Out'],
    ['Novembro', 'Nov'],
    ['Dezembro', 'Dez'],
  ],
  weekDays: [
    ['Sábado', 'Sab'],
    ['Domingo', 'Dom'],
    ['Segunda-Feira', 'Seg'],
    ['Terça-Feira', 'Ter'],
    ['Quarta-Feira', 'Qua'],
    ['Quinta-Feira', 'Qui'],
    ['Sexta-Feira', 'Sex'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
};
