import omitEmpty from 'omit-empty';

const buildQueryPackageFilters = searchData => {
  const arrayFilters = omitEmpty(
    Object.entries(searchData).map(([searchKey, searchValue]) => {
      const { value, filterName, filterOperator } = searchValue;

      if (!value) return '';

      return `filters[][${filterName || searchKey}@${
        filterOperator || 'like'
      }]=${typeof value === 'boolean' ? Number(value) : value}`;
    }),
  );

  return arrayFilters ? `&${arrayFilters.join('&')}` : '';
};

export default buildQueryPackageFilters;
