import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import '~/styles/sweetalert2-styles.css';

const showToastMessage = ({ type, text, timer = 5 }) => {
  if (
    ![
      'info',
      'error',
      'success',
      'warn',
      'warning',
      'dark',
      'default',
    ].includes(type)
  )
    throw new Error('Tipo de mensagem inexistente!');

  toast[type](text, {
    position: toast.POSITION.TOP_RIGHT,
    className: 'toastsuccess',
    bodyClassName: 'bodytoast',
    autoClose: timer * 1000,
  });
};

const showModalMessage = async ({
  type,
  title,
  text,
  timer = 2.5,
  doAfter = undefined,
}) => {
  await Swal.fire({
    allowOutsideClick: false,
    title,
    text,
    icon: type,
    timer: 1000 * timer,
    timerProgressBar: true,
    showConfirmButton: false,
  }).then(result => {
    if (doAfter && result.dismiss === Swal.DismissReason.timer) {
      doAfter();
    }
  });
};

const showModalConfirm = async ({
  type = 'question',
  title,
  text,
  primaryLabel = 'Sim',
  secondaryLabel = 'Não',
  callback,
}) => {
  await Swal.fire({
    allowOutsideClick: false,
    title,
    text,
    icon: type,
    confirmButtonText: primaryLabel,
    confirmButtonColor: '#26A2B1',
    cancelButtonText: secondaryLabel,
    cancelButtonColor: '#4f4f4f',
    showCancelButton: true,
  }).then(result => callback(result.isConfirmed));
};

export { showModalMessage, showModalConfirm, showToastMessage };
