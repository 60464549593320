import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WhiteBox from '~/components/WhiteBox';

import { PageContainer, TableHeader } from '~/styles/components';

import { Container, TableTopContent } from './styles';

export default function PrintTable() {
  const { state } = useLocation();

  const { data } = state;

  useEffect(() => {
    window.print();
  }, []);

  return (
    <PageContainer>
      <Container>
        <table>
          <TableTopContent>
            <h2>Filmagens {data?.dataFormatada}</h2>
          </TableTopContent>
          <TableHeader>
            <tr>
              <th>ID da Obra</th>
              <th>Localização</th>
              <th>Status</th>
              <th>Resp. Obra</th>
              <th>Resp. Atendimento</th>
            </tr>
          </TableHeader>
          <WhiteBox column aCenter>
            <tbody>
              {data &&
                data?.dataListagem.map(lista => (
                  <tr key={lista.id}>
                    <td>{lista.id}</td>
                    <td>{lista.location}</td>
                    <td>{lista.status}</td>
                    <td>{lista.responsavelObra}</td>
                    <td>{lista.responsavelAtendimento}</td>
                  </tr>
                ))}
            </tbody>
          </WhiteBox>
        </table>
      </Container>
    </PageContainer>
  );
}
