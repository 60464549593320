import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '../../services/notification';

import api, { makeOptions } from '../../services/api';
import UserAdminActions from '../ducks/userAdmin';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));

export function* fetchStore(action) {
  try {
    const { params: create } = action;
    const { data } = yield call(
      api.post,
      '/user-admin',
      create,
      makeOptions(dataLocal.auth.data),
    );
    yield put(UserAdminActions.createSuccess(data));

    showToastMessage({
      type: 'success',
      text: 'Usuário cadastrado com sucesso',
    });
  } catch (err) {
    yield put(UserAdminActions.createFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* fetchEdit(action) {
  try {
    const { data: post } = action;

    const { data: response } = yield call(
      api.patch,
      `/user-admin/${post.uuid}`,
      post,
      makeOptions(dataLocal.auth.data),
    );

    showToastMessage({
      type: 'success',
      text: 'Usuário alterado com sucesso',
    });
    yield put(UserAdminActions.editSuccess(response));
  } catch (err) {
    yield put(UserAdminActions.editFailure('Erro ao se comunicar com a API.'));
  }
}

export function* fetchDestroy(action) {
  try {
    const { uuid } = action;

    const { data: response } = yield call(
      api.delete,
      `user/${uuid}`,
      makeOptions(dataLocal.auth.data),
    );

    showToastMessage({
      type: 'success',
      text: 'Usuário removido com sucesso',
    });
    yield put(UserAdminActions.deleteSuccess(response));
  } catch (err) {
    yield put(
      UserAdminActions.deleteFailure('Erro ao se comunicar com a API.'),
    );
  }
}
