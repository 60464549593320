import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ApiClient from '~/utils/ApiClient';
import ApiToken from '~/utils/ApiToken';
import api from '~/services/api';

import { showModalMessage } from '~/services/notification';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const history = useHistory();
  const isAuthenticated = !!user;
  const location = useLocation();

  useEffect(() => {
    const access_token = localStorage.getItem('@Spcine:token');

    if (access_token) {
      ApiClient.doRequest({
        uri: `me`,
        method: ApiClient.GET,
        expectedStatus: 200,
      }).then(response => {
        const { uuid, user_personal_data, email, roles } = response.data.data;
        setUser({
          uuid,
          user_personal_data,
          email,
          roles: roles?.map(role => role.name),
        });

        roles?.forEach(item => {
          switch (item.name) {
            case 'Pessoa Fisica':
              localStorage.setItem('@roleP', JSON.stringify('Pessoa Fisica'));
              break;
            case 'Pessoa Jurídico':
              localStorage.setItem('@roleJ', JSON.stringify('Pessoa Jurídico'));
              break;
            case 'Administrador':
              localStorage.setItem('@roleAd', JSON.stringify('Administrador'));
              break;
            case 'Moderador':
              localStorage.setItem('@roleMd', JSON.stringify('Moderador'));
              break;
            default:
          }
        });
      });
    }
  }, []);

  const signIn = useCallback(
    async ({ email, password }) => {
      const language = localStorage.getItem('@language');

      localStorage.clear();

      localStorage.setItem('@language', language);

      try {
        const response = await ApiClient.doRequest({
          uri: 'login',
          auth: false,
          method: ApiClient.POST,
          body: {
            email,
            password,
          },
          expectedStatus: 200,
        });

        const { access_token } = response.data;

        ApiToken.saveToken(access_token);
        api.defaults.headers['Authorization'] = `Bearer ${access_token}`;

        localStorage.setItem('@Spcine:token', access_token);
        localStorage.setItem(
          'persist:Spcine',
          JSON.stringify({
            auth: {
              data: {
                access_token,
                token_type: 'bearer',
                expires_in: 216000,
              },
              loading: false,
              error: null,
              logado: true,
              countError: false,
            },
            _persist: {
              version: -1,
              rehydrated: true,
            },
          }),
        );
        localStorage.setItem('@roleP', JSON.stringify('Pessoa Fisica'));

        const redirectLink = new URLSearchParams(location.search).get(
          'redirectLink',
        );

        if (redirectLink) {
          history.push(redirectLink);
        } else {
          history.push('/escolha-acesso');
        }
      } catch (err) {
        const error = JSON.parse(err.toString().split('Error:')[1]);
        throw new Error(error?.error?.error?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@Spcine:token');
    localStorage.removeItem('@Spcine:currentUser');
    localStorage.removeItem('@meuapp/token');
    localStorage.removeItem('persist:Spcine');
    localStorage.removeItem('persist:Spcine2');
    localStorage.removeItem('@roleP');
    localStorage.removeItem('@roleJ');
    localStorage.removeItem('@roleAd');
    localStorage.removeItem('@roleMd');

    setUser({});

    history.push('/login');
  }, [history]);

  return (
    <AuthContext.Provider value={{ signIn, isAuthenticated, user, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth só pode ser usado com o AuthProvider');
  }

  return context;
};
