import styled from 'styled-components';

export const PageContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  color: #000;
`;
