import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  LimiterInputWidth,
  PageContainer,
  TitleForm,
  Wrap,
} from '~/styles/components';
import Aside from '~/components/Aside';

import { Container } from './styles';

import WhiteBox from '~/components/WhiteBox';
import { Select } from '~/components/Forms';
import { ObrasGeralForm } from './components/ObrasGeralForm';
import { LocacoesGeraisForm } from './components/LocacoesGeraisForm';
import { CartaDeApoioForm } from './components/CartaDeApoioForm';
import { PesquisaSocioeconomicaForm } from './components/PesquisaSocioeconomicaForm';
import { CatalogoProfissionaisServicosForm } from './components/CatalogoProfissionaisServicosForm';
import { UsuarioForm } from './components/UsuarioForm';
import { CienciaFilmagemInternaForm } from './components/CienciaFilmagemInternaForm';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const reportTypesList = [
  { value: 'obras-geral', label: 'Obras - Geral', form: <ObrasGeralForm /> },
  {
    value: 'locacoes-geral',
    label: 'Locações - Gerais',
    form: <LocacoesGeraisForm />,
  },
  {
    value: 'catalogo-profissionais-servicos',
    label: 'Catálogo de Profissionais e Serviços',
    form: <CatalogoProfissionaisServicosForm />,
  },
  {
    value: 'cartas-apoio',
    label: 'Cartas de Apoio',
    form: <CartaDeApoioForm />,
  },
  {
    value: 'ciencia-filmagem-interna',
    label: 'Ciência de Filmagem Interna',
    form: <CienciaFilmagemInternaForm />,
  },
  {
    value: 'obras-pesquisa-socioeconomica',
    label: 'Obras - Pesquisa Socioeconômica',
    form: <PesquisaSocioeconomicaForm />,
  },
  { value: 'usuario', label: 'Usuário', form: <UsuarioForm /> },
];

const Relatorio = () => {
  const formRef = useRef();
  const [seletedReport, setSeletedReport] = useState();

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Wrap>
          <TitleForm>
            <h4>Relatórios</h4>
          </TitleForm>
        </Wrap>
        <WhiteBox
          column
          jCenter
          style={{ maxHeight: 'none', marginBottom: 20 }}
        >
          <Form ref={formRef}>
            <Wrap>
              <LimiterInputWidth w25>
                <Select
                  name="tipo_relatorio"
                  label="Tipo de Relatório"
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={reportTypesList}
                  onChange={e => {
                    setSeletedReport(e);
                  }}
                />
              </LimiterInputWidth>
            </Wrap>
          </Form>

          {seletedReport?.form}
        </WhiteBox>
      </Container>
    </PageContainer>
  );
};

export default Relatorio;
