import React from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  Paper,
  Popper,
} from '@material-ui/core';
import { ArrowDropDownSharp } from '@material-ui/icons';
import { FiLoader } from 'react-icons/fi';
import { StyledButtonGroup, StyledMenuList } from './styles';

export const SplitButton = ({ buttons, isLoading, disableAllButtons }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (buttons?.length <= 0) {
    return <></>;
  }

  return (
    <>
      <StyledButtonGroup ref={anchorRef} disabled={disableAllButtons}>
        <Button
          onClick={buttons[0].onClick}
          className="default-button"
          disabled={buttons[0].disabled}
        >
          {isLoading ? (
            <FiLoader size={20} className="icon-spin" />
          ) : (
            buttons[0].label
          )}
        </Button>

        {buttons?.length > 1 && (
          <Button
            onClick={() => setOpen(prevOpen => !prevOpen)}
            className="arrow-button"
          >
            <ArrowDropDownSharp />
          </Button>
        )}
      </StyledButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList id="split-button-menu" autoFocusItem>
                  {buttons.slice(-1).map(button => (
                    <MenuItem
                      disabled={disableAllButtons || button.disabled}
                      key={button.label}
                      onClick={event => {
                        button.onClick(event);
                        setOpen(false);
                      }}
                    >
                      {button.label}
                    </MenuItem>
                  ))}
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
