import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #828282;
    margin-bottom: 8px;
  }

  /* ${({ marginLabel }) =>
    marginLabel
      ? `label: { margin: [marginLabel]; }`
      : `label: { margin-bottom: 8px; }`} */

  input {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    padding: 16px;
    font-family: 'InterSemiBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #828282;
    background-color: transparent;
    /* box-shadow: 0 3px 12px rgba(0, 0, 0, 0.075); */
    &[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &[type='color'] {
      height: 70px;
      width: 100%;
      cursor: pointer;
    }

    &[disabled] {
      background-color: #ededed;
      &::placeholder {
        content: '';
      }
    }

    &::-webkit-input-placeholder {
      opacity: 0.7;
    }

    &:-ms-input-placeholder {
      opacity: 0.7;
    }

    &:-moz-placeholder {
      opacity: 0.7;
    }

    &::-moz-placeholder {
      opacity: 0.7;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0;
    top: 8px;

    svg {
      color: #f2f2f2;
    }
  }

  span {
    font-size: 14px;
    font-family: 'InterBold', sans-serif;
    color: #ff0000;
    margin: 4px 0;
  }

  span.danger {
    font-family: 'PoppinsSemiBold', sans-serif;
  }
`;
