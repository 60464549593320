import { createGlobalStyle } from 'styled-components';
import InterRegular from '~/assets/fonts/Inter/Inter-Regular.ttf';
import InterSemiBold from '~/assets/fonts/Inter/Inter-SemiBold.ttf';
import InterBold from '~/assets/fonts/Inter/Inter-Bold.ttf';
import PoppinsRegular from '~/assets/fonts/Poppins/Poppins-Regular.ttf';
import PoppinsSemiBold from '~/assets/fonts/Poppins/Poppins-SemiBold.ttf';
import PoppinsBold from '~/assets/fonts/Poppins/Poppins-Bold.ttf';
import RobotoRegular from '~/assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoBold from '~/assets/fonts/Roboto/Roboto-Bold.ttf';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Barlow:400,600,700|Quicksand:700&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  @font-face {
    font-family: "InterRegular";
    src: url(${InterRegular}) format("opentype");
  }

  @font-face {
    font-family: "InterSemiBold";
    src: url(${InterSemiBold}) format("opentype");
  }

  @font-face {
    font-family: "InterBold";
    src: url(${InterBold}) format("opentype");
  }

  @font-face {
    font-family: "PoppinsRegular";
    src: url(${PoppinsRegular}) format("opentype");
  }

  @font-face {
    font-family: "PoppinsSemiBold";
    src: url(${PoppinsSemiBold}) format("opentype");
  }

  @font-face {
    font-family: "PoppinsBold";
    src: url(${PoppinsBold}) format("opentype");
  }

  @font-face {
    font-family: "RobotoRegular";
    src: url(${RobotoRegular}) format("opentype");
  }

  @font-face {
    font-family: "RobotoBold";
    src: url(${RobotoBold}) format("opentype");
  }

  html,
  body,
  #root {
    width: 100vw;
    height: 100vh;
    scroll-behavior: smooth;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color:#E5E5E5;
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: 'Quicksand', sans-serif;
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  input {
    font-family: 'Barlow', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Quicksand', sans-serif;
  }

  P {
    font-family: 'InterRegular', sans-serif;
    font-size: 16px;
    line-height: 20px;
  }

  ul {
    list-style: none;
  }

  .icon-spin {
    animation: iconSpin 2s infinite linear;
    color: #000;
  }

  ul.check-list {
    & > li::before {
      content: "\\2713\\0020";
    }
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    border-bottom: 1px solid #ebeff2;

    /* & + tr {
        border-bottom: 1px solid #ebeff2;
      } */

    td,
    th {
      width: 100%;
      max-width: 200px;
      text-align: left;

      &.enterprise {
        color: #323C47;
      }

      a {
        text-decoration: none;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.8;
        }
      }

      .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 50px;
      }

      button {
        margin-right: 20px;

        &:last-child {
          margin: 0;
        }
      }

      &:last-child {
        text-align: center;
        max-width: 90px;
      }
    }
  }

  tbody,
  thead {
    font-family: "PoppinsRegular", sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #707683;
    text-align: center;
    padding: 10px 0;
    width: 100%;
  }

/* tooltip */

  [tooltip] {
    position: relative;
  }

  [tooltip]::before,
  [tooltip]::after {
    text-transform: none;
    font-size: .9em;
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
  }
  [tooltip]::before {
    content: '';
    border: 5px solid transparent;
    z-index: 1001;
  }
  [tooltip]::after {
    content: attr(tooltip); /* magica! */

    /* editavel a gosto */
    font-family: "InterRegular", sans-serif;
    text-align: left;
    font-size: 15px;

    /*
    Tamanho deifinido auto.
      */
  // min-width: 3em;
  // max-width: 21em;
    width: 274px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1ch 1.5ch;
    border-radius: 10px;
  // border-radius: .3ch;
    box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
    background: #333;
    color: #fff;
    z-index: 1000; /* absurdo! 2 */
  }

/* Fazendo tooltip ao passar mouse */
  [tooltip]:hover::before,
  [tooltip]:hover::after {
    display: block;
  }

  /* não exibir tooltips em branco */
  [tooltip='']::before,
  [tooltip='']::after {
    display: none !important;
  }

/* FLOW: UP PADRAO */
  [tooltip]:not([flow])::before,
  [tooltip][flow^="up"]::before {
    bottom: 100%;
    left: calc(90% - 10px) !important;
    border-bottom-width: 0;
    border-top-color: #333;

  }
  [tooltip]:not([flow])::after,
  [tooltip][flow^="up"]::after {
    bottom: calc(100% + 5px);
  }
  [tooltip]:not([flow])::before,
  [tooltip]:not([flow])::after,
  [tooltip][flow^="up"]::before,
  [tooltip][flow^="up"]::after {
    //left: 50%;
    left: calc(0em - 90px);
    transform: translate(-50%, -.5em);
  }

  /* FLOW: DOWN */
  [tooltip][flow^="down"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #333;
  }
  [tooltip][flow^="down"]::after {
    top: calc(100% + 5px);
  }
  [tooltip][flow^="down"]::before,
  [tooltip][flow^="down"]::after {
    left: 50%;
    transform: translate(-50%, .5em);
  }

  /* FLOW: LEFT */
  [tooltip][flow^="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #333;
    left: calc(0em - 5px);
    transform: translate(-.5em, -50%);
  }
  [tooltip][flow^="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    transform: translate(-.5em, -50%);
  }

  /* FLOW: RIGHT */
  [tooltip][flow^="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #333;
    right: calc(0em - 5px);
    transform: translate(.5em, -50%);
  }
  [tooltip][flow^="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translate(.5em, -50%);
  }

  /* KEYFRAMES */
  @keyframes tooltips-vert {
    to {
      opacity: .9;
      transform: translate(-50%, 0);
    }
  }

  @keyframes tooltips-horz {
    to {
      opacity: .9;
      transform: translate(0, -50%);
    }
  }

  /* FX */
  [tooltip]:not([flow]):hover::before,
  [tooltip]:not([flow]):hover::after,
  [tooltip][flow^="up"]:hover::before,
  [tooltip][flow^="up"]:hover::after,
  [tooltip][flow^="down"]:hover::before,
  [tooltip][flow^="down"]:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
  }

  [tooltip][flow^="left"]:hover::before,
  [tooltip][flow^="left"]:hover::after,
  [tooltip][flow^="right"]:hover::before,
  [tooltip][flow^="right"]:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
  }
`;

export default GlobalStyle;
