import styled from 'styled-components';

export const MainLoading = styled.div`
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px 70px;
  span {
    width: 100%;
    text-align: center;
    color: #000;
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
  }
  a {
    width: 100%;
    text-align: center;
    color: blue;
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
  }
`;

export const ContainerFooter = styled.div`
  width: 100%;
  margin-top: 50vh;
  background: #f2f2f2;
  padding: 10px;
`;
