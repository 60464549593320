import diff from 'diff-arrays-of-objects';

export default function diffHoursFilmagem({
  hoursFilmagemLocacao,
  hoursFilmagemInput,
  hourBeginKeyName = 'start',
  hourEndKeyName = 'end',
}) {
  // if equals
  if (
    JSON.stringify(hoursFilmagemLocacao.sort()) ===
    JSON.stringify(hoursFilmagemInput.sort())
  ) {
    return null;
  }

  const results = diff(hoursFilmagemLocacao, hoursFilmagemInput, 'uuid');
  const { updated, added, removed } = results;

  const horasFilmagemDiff = added.concat(
    updated,
    removed?.map(hora => ({
      uuid: hora.uuid,
      [hourBeginKeyName]: '',
      [hourEndKeyName]: '',
    })),
  );

  return horasFilmagemDiff;
}
