import { Reducer } from 'redux';
import { types as Parque } from './types';

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: false,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Parque.REQUEST:
      return { ...state, loading: false };
    case Parque.RESPONSE:
      return {
        ...state,
        error: false,
        data: action.payload.data,
      };
    case Parque.FAILURE:
      return {
        ...state,
        error: true,
        data: [],
      };
    default:
      return state;
  }
};

export default reducer;
