import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Container } from './styles';
import './styles.css';

export const RadioNoForm = ({
  label,
  yesLabelValue,
  noLabelValue,
  name,
  value,
  onChange,
  disabled,
  required,
}) => {
  return (
    <Container>
      {label && (
        <h3>
          {label}
          {required && <span className="required">*</span>}
        </h3>
      )}
      <RadioGroup row name={name} value={value} onChange={onChange}>
        <FormControlLabel
          value="1"
          label={yesLabelValue || 'Sim'}
          control={<Radio color="default" disabled={disabled} />}
        />
        <FormControlLabel
          value="0"
          label={noLabelValue || 'Não'}
          control={<Radio color="default" disabled={disabled} />}
        />
      </RadioGroup>
    </Container>
  );
};
