import React from 'react';
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import clientResources from '~/data/clientResources';
import { confirmEditDateHour } from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import { BoxHeader } from '~/pages/Usuario/styles';
import { showModalMessage } from '~/services/notification';
import {
  ActionInputMulti,
  ContainerMultiInputActions,
  LimiterInputWidth,
  MultiInputContainer,
  TooltipContainer,
  Wrap,
} from '~/styles/components';
import InputNoForm from '../InputNoForm';

export const HourFormMultiInput = ({
  tooltip,
  label = 'Horas',
  hourBeginKeyName = 'start',
  hourEndKeyName = 'end',
  isAdminRequest = false,
  isViewMode = false,
  hours,
  setHours,
  setIsLoading,
  isRequired = false,
}) => {
  // constantes criadas por não estarem renderizando
  // os dados corretamente na tela ao remover
  const beginKeyName = hourBeginKeyName;
  const endKeyName = hourEndKeyName;

  const handleAddField = _ => {
    setHours(prevState => [
      ...prevState,
      { [beginKeyName]: '', [endKeyName]: '' },
    ]);
  };

  const handleChangeField = (index, event) => {
    const values = [...hours];
    values[index][event.target.name] = event.target.value;
    setHours(values);
  };

  const handleRemoveField = index => {
    setHours(hours?.filter((_, idx) => idx !== index));
  };

  async function handleSubmitChanges({ approved, index }) {
    setIsLoading(true);

    const values = [...hours];

    const resp = await confirmEditDateHour({
      approved,
      changesToApprove: values[index]?.changes_to_approve,
      multiInputType: 'hora',
    });

    await showModalMessage({
      type: resp.message_type,
      text: resp.message_text,
    });

    if (resp.message_type !== 'success') {
      setIsLoading(false);
      return;
    }

    values[index].show_confirm_btns = false;

    if (approved === 0) {
      values.splice(index, 1);
      window.location.reload();
    }

    setHours(values);
    setIsLoading(false);
  }

  const InnerTooltipContainer = ({ inputField, columnName, children }) => {
    if (isAdminRequest) {
      return (
        <TooltipContainer
          value={
            inputField?.show_confirm_btns &&
            (inputField?.changes_to_approve?.find(
              ({ column_name }) => column_name === columnName,
            )?.old_value
              ? `Valor anterior: ${inputField?.changes_to_approve
                  ?.find(({ column_name }) => column_name === columnName)
                  ?.old_value.substring(0, 5)}`
              : 'Não possui valor anterior')
          }
        >
          {children}
        </TooltipContainer>
      );
    }

    return (
      <TooltipContainer
        value={
          inputField?.changes_to_approve?.find(i => true)?.old_value &&
          clientResources.labels.locacao.dateHourEditTooltip
        }
      >
        {children}
      </TooltipContainer>
    );
  };

  return (
    <MultiInputContainer>
      <BoxHeader style={{ justifyContent: 'normal' }}>
        <span className="boxText" style={{ color: '#828282' }}>
          {label}
        </span>
        {isRequired && (
          <span className="boxText" style={{ color: 'red' }}>
            *
          </span>
        )}
        {tooltip && (
          <button type="button" tooltip={tooltip} style={{ marginLeft: 5 }}>
            <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
          </button>
        )}
      </BoxHeader>

      {hours?.map((inputField, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Wrap key={`${inputField}~${index}`}>
          <LimiterInputWidth w25>
            <InnerTooltipContainer
              columnName={beginKeyName}
              inputField={inputField}
            >
              <InputNoForm
                type="time"
                name={beginKeyName}
                required={isRequired}
                value={inputField[beginKeyName]}
                onChange={event => handleChangeField(index, event)}
                disabled={inputField?.changes_to_approve || isViewMode}
                editedDate={inputField?.changes_to_approve}
              />
            </InnerTooltipContainer>
          </LimiterInputWidth>

          <LimiterInputWidth w25>
            <InnerTooltipContainer
              columnName={endKeyName}
              inputField={inputField}
            >
              <InputNoForm
                type="time"
                name={endKeyName}
                required={isRequired}
                value={inputField[endKeyName]}
                onChange={event => handleChangeField(index, event)}
                disabled={inputField?.changes_to_approve || isViewMode}
                editedDate={inputField?.changes_to_approve}
              />
            </InnerTooltipContainer>
          </LimiterInputWidth>

          <ContainerMultiInputActions>
            {!isViewMode && (
              <>
                {!inputField?.show_confirm_btns && hours?.length !== 1 && (
                  <ActionInputMulti
                    type="button"
                    onClick={() => handleRemoveField(index)}
                  >
                    <AiOutlineMinus color="#AAA" size={24} />
                  </ActionInputMulti>
                )}
                {hours?.length - 1 === index && (
                  <ActionInputMulti type="button" onClick={handleAddField}>
                    <AiOutlinePlus color="#AAA" size={24} />
                  </ActionInputMulti>
                )}
              </>
            )}

            {isAdminRequest && inputField?.show_confirm_btns && (
              <>
                <ActionInputMulti
                  type="button"
                  onClick={() =>
                    handleSubmitChanges({
                      approved: 1,
                      index,
                    })
                  }
                  style={{ backgroundColor: '#00ff005c' }}
                >
                  <AiOutlineCheck color="#AAA" size={24} />
                </ActionInputMulti>

                <ActionInputMulti
                  type="button"
                  onClick={() =>
                    handleSubmitChanges({
                      approved: 0,
                      index,
                    })
                  }
                  style={{ backgroundColor: '#ff0000ad' }}
                >
                  <AiOutlineClose color="#AAA" size={24} />
                </ActionInputMulti>
              </>
            )}
          </ContainerMultiInputActions>
        </Wrap>
      ))}
    </MultiInputContainer>
  );
};
