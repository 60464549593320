import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #828282;
    margin-bottom: 5px;

    & > span.danger,
    span.required {
      color: red;
    }
  }
`;
