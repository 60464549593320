import React, { useState, useCallback, useEffect } from 'react';
import { isSafari } from 'react-device-detect';
import {
  AiFillExclamationCircle,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { InputNoForm } from '~/components/Forms';
import { RadioNoForm } from '~/components/Forms/RadioNoForm';
import Load from '~/components/Load';
import WhiteBox from '~/components/WhiteBox';
import { BoxHeader } from '~/pages/Usuario/styles';
import { showModalMessage } from '~/services/notification';
import {
  ActionInputMulti,
  ContainerMultiInputActions,
  GridContainer,
  Wrap,
} from '~/styles/components';
import ApiClient from '~/utils/ApiClient';
import ResponsaveisInfoIcon from '../ResponsaveisInfoIcon';

export const ResponsavelLegal = ({
  pjUuid,
  viewMode,
  responsaveisLegal,
  setResponsaveisLegal,
}) => {
  const [loading, setLoading] = useState(false);
  const [responsaveisLegalCnpj, setResponsaveisLegalCnpj] = useState([]);

  const searchResponsaveisLegalByCNPJ = useCallback(async () => {
    try {
      setResponsaveisLegalCnpj([]);

      if (!pjUuid) return;

      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri: `/find/branch-representantes/${pjUuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setLoading(false);

      setResponsaveisLegalCnpj(resp.data.data);
    } catch (error) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: `Erro ao buscar Responsável(veis) Legal do CNPJ informado`,
      });
    }
  }, [pjUuid]);

  useEffect(() => {
    searchResponsaveisLegalByCNPJ();
  }, [searchResponsaveisLegalByCNPJ]);

  const handleEmailExists = async (event, index) => {
    const { value } = event.target;

    if (!value) return;

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: `user/exist-by-email/${value}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setLoading(false);

      await showModalMessage({
        type: 'error',
        text: `Email '${value}' já cadastrado. Por favor, informe outro.`,
      });

      const values = [...responsaveisLegal];
      values[index][event.target.name] = '';
      setResponsaveisLegal(values);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSearchPhysicalPerson = async (event, index) => {
    const { value } = event.target;
    const searchValue = value?.replace(/[^0-9]/g, '');

    try {
      if (!searchValue || searchValue.length !== 11) throw new Error();

      setLoading(true);

      const response = await ApiClient.doRequest({
        uri: `/user/find/${searchValue}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const data = response?.data?.data;

      if (pjUuid !== undefined) {
        const respRep = await ApiClient.doRequest({
          uri: `/find/representante?branch_uuid=${pjUuid}&user_uuid=${data?.uuid}`,
          method: ApiClient.GET,
          expectedStatus: 200,
        });

        setLoading(false);

        if (typeof respRep?.data?.data?.uuid === 'string') {
          await showModalMessage({
            type: 'error',
            text: 'O CNPJ já possui um Representante cadastrado nesse CPF!',
          });
          throw new Error();
        }
      }

      const values = [...responsaveisLegal];

      values[index] = {
        ...values[index],
        name: data?.user_personal_data?.name,
        email: data?.email,
        phone_mobile: data?.user_personal_data?.phone_mobile,
        rg: data?.user_personal_data?.rg,
        birth_date: data?.user_birth_address?.birth_date,
        send_email: null,
        user_exists: true,
      };
      setResponsaveisLegal(values);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      const values = [...responsaveisLegal];
      values[index] = {
        ...values[index],
        uuid: '',
        name: '',
        corporate_email: '',
        email: '',
        phone_mobile: '',
        rg: '',
        birth_date: '',
        cargo: '',
        send_email: null,
        user_exists: false,
      };
      setResponsaveisLegal(values);
    }
  };

  const handleRemoveBox = ({ index }) => {
    setResponsaveisLegal(responsaveisLegal?.filter((_, idx) => idx !== index));
  };

  const handleAddBox = () => {
    setResponsaveisLegal(prevState => [...prevState, { uuid: '' }]);
  };

  const handleChange = ({ index, event }) => {
    const values = [...responsaveisLegal];
    values[index][event.target.name] = event.target.value;
    setResponsaveisLegal(values);
  };

  return (
    <>
      <Load inLoad={loading} />

      <WhiteBox column marginBottom="30px">
        <BoxHeader style={{ justifyContent: 'space-between' }}>
          <span className="boxText">Responsável Legal:</span>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <ResponsaveisInfoIcon
              responsaveisExistentes={responsaveisLegalCnpj}
            />
            <span tooltip="Preencha os dados" style={{ marginRight: 5 }}>
              <AiFillExclamationCircle />
            </span>
          </div>
        </BoxHeader>

        {responsaveisLegal?.map((inputField, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Wrap key={`${inputField}~${index}`}>
            <WhiteBox column style={{ bottom: '0' }}>
              <BoxHeader style={{ justifyContent: 'flex-end' }}>
                {!viewMode && (
                  <ContainerMultiInputActions>
                    {responsaveisLegal.length !== 1 && (
                      <ActionInputMulti
                        type="button"
                        onClick={() =>
                          handleRemoveBox({
                            index,
                          })
                        }
                      >
                        <AiOutlineMinus color="#AAA" size={24} />
                      </ActionInputMulti>
                    )}

                    {responsaveisLegal.length - 1 === index && (
                      <ActionInputMulti type="button" onClick={handleAddBox}>
                        <AiOutlinePlus color="#AAA" size={24} />
                      </ActionInputMulti>
                    )}
                  </ContainerMultiInputActions>
                )}
              </BoxHeader>
              <Wrap>
                <GridContainer cols={3}>
                  <InputNoForm
                    type="text"
                    name="cpf"
                    mask="999.999.999-99"
                    maskChar=""
                    questionTooltip="Caso o CPF esteja cadastrado como Representante neste CNPJ, não há necessidade de um novo cadastro"
                    label="CPF"
                    value={inputField.cpf}
                    disabled={viewMode}
                    onChange={event =>
                      handleChange({
                        index,
                        event,
                      })
                    }
                    onBlur={event => handleSearchPhysicalPerson(event, index)}
                    required={Object.values(responsaveisLegalCnpj).length <= 0}
                  />
                  <InputNoForm
                    type="text"
                    name="name"
                    label="Nome Civil/Nome Social"
                    required={Object.values(responsaveisLegalCnpj).length <= 0}
                    value={inputField.name}
                    disabled={inputField.user_exists}
                    onChange={event =>
                      handleChange({
                        index,
                        event,
                      })
                    }
                  />
                  <InputNoForm
                    type="text"
                    name="email"
                    label="E-mail"
                    value={inputField.email}
                    disabled={inputField.user_exists}
                    onBlur={e => handleEmailExists(e, index)}
                    required={Object.values(responsaveisLegalCnpj).length <= 0}
                    onChange={event =>
                      handleChange({
                        index,
                        event,
                      })
                    }
                  />
                  <InputNoForm
                    type="text"
                    name="phone_mobile"
                    label="Celular"
                    mask="(99) 99999-9999"
                    maskChar=""
                    disabled={inputField.user_exists}
                    value={inputField.phone_mobile}
                    required={Object.values(responsaveisLegalCnpj).length <= 0}
                    onChange={event =>
                      handleChange({
                        index,
                        event,
                      })
                    }
                  />
                  <InputNoForm
                    type="text"
                    name="rg"
                    label="RG/RNE"
                    disabled={inputField.user_exists}
                    value={inputField.rg}
                    required={Object.values(responsaveisLegalCnpj).length <= 0}
                    onChange={event =>
                      handleChange({
                        index,
                        event,
                      })
                    }
                  />
                  <InputNoForm
                    type="date"
                    name="birth_date"
                    label="Data de nascimento"
                    disabled={inputField.user_exists}
                    acceptPast
                    value={inputField.birth_date}
                    placeholder={isSafari && 'aaaa-mm-dd'}
                    required={Object.values(responsaveisLegalCnpj).length <= 0}
                    onChange={event =>
                      handleChange({
                        index,
                        event,
                      })
                    }
                  />
                  <InputNoForm
                    type="text"
                    name="cargo"
                    label="Cargo"
                    required={Object.values(responsaveisLegalCnpj).length <= 0}
                    value={inputField.cargo}
                    disabled={viewMode}
                    onChange={event =>
                      handleChange({
                        index,
                        event,
                      })
                    }
                  />
                  <InputNoForm
                    type="text"
                    name="corporate_email"
                    label="E-mail Corporativo"
                    value={inputField.corporate_email}
                    disabled={viewMode}
                    required={Object.values(responsaveisLegalCnpj).length <= 0}
                    onChange={event =>
                      handleChange({
                        index,
                        event,
                      })
                    }
                  />
                </GridContainer>
              </Wrap>
              {!viewMode && !loading && (
                <Wrap>
                  <RadioNoForm
                    name="send_email"
                    label="Deseja que esse usuário receba notificações automáticas enviadas pelo sistema?"
                    required
                    value={inputField.send_email}
                    onChange={event => {
                      handleChange({
                        index,
                        event,
                      });
                    }}
                  />
                </Wrap>
              )}
            </WhiteBox>
          </Wrap>
        ))}
      </WhiteBox>
    </>
  );
};
