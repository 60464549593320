import styled from 'styled-components';

export const PageContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: url(${props => props.background}) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 467px;
  margin: 0 auto;
  padding: 48px 32px 70px 32px;
  border: none;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: Column;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;

  h1 {
    font-family: 'InterBold', sans-serif;
    font-size: 30px;
    line-height: 36px;
    color: #3f3f3f;
    margin-bottom: 40px;
  }

  form {
    width: 100%;

    button {
      margin-top: 60px;
      margin-bottom: 24px;
    }

    & + input {
      margin-bottom: 16px;
    }
  }

  &.recovery {
    h1 {
      margin-bottom: 16px;
    }

    p {
      color: #3f3f3f;
      margin-bottom: 40px;
    }

    .inputRow {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  &.forgot-password {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'InterRegular';
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      color: #828282;

      svg {
        margin-right: 8px;
      }
    }

    button {
      display: flex;
      align-items: center;
      gap: 4px;
      margin: 0;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    width: 100%;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Logotips = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 0 35px 0 35px;

  img {
    &:first-child {
      margin-right: 8px;
    }
  }
`;

export const LandingContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const LandingHeader = styled.div`
  background-color: #777;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  > img {
    width: 100%;
    height: 490px;
    object-fit: cover;
    object-position: center;
  }

  > p {
    text-shadow: 'rgb(0 0 0) 1px 2px 3px';
    font-family: 'RobotoRegular', sans-serif;
    font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1600 - 300)));
    line-height: 51.28px;
    color: #ffffff;
    text-align: center;
    max-width: 630px;
    position: absolute;
  }
`;

export const NavHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 183px 0 44px;
  position: absolute;
  top: 0;

  img {
    max-width: 200px;
    max-height: 150px;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
`;

export const BannerMiddle = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 85px;

  img {
    width: 1162px;
    height: 712px;
  }
`;

export const LandingContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
`;

export const LandingBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px;

  > div.divApresentation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    margin-top: 50px;

    & + div {
    }

    & > img {
      width: 100%;
      max-width: fit-content;
      object-fit: scale-down;
    }

    p {
      text-align: 'justify';
      font-family: RobotoRegular;
      font-style: regular;
      font-weight: normal;
      font-size: 18px;
      line-height: 33.06px;
      color: #828282;
      /* max-width: 475px; */
      width: 100%;
      margin: 20px;
      text-align: justify;
    }
  }

  span {
    text-align: center;
    font-family: RobotoRegular;
    font-weight: normal;
    font-size: 40px;
    line-height: 183.69%;
    color: #4f4f4f;
    margin: 0 0 30px 0;
  }

  #ladding-box-text {
    width: 100%;
    max-width: 1170px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
  }

  #ladding-box-text p {
    font-family: RobotoRegular;
    font-size: 18px;
    width: 46%;
    color: #828282;
  }

  @media screen and (max-width: 1170px) {
    > div.divApresentation {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 1100px) {
    > div.divApresentation {
      flex-direction: column;

      & + div {
        flex-direction: column-reverse;
      }
    }
  }
`;

export const LandingSlide = styled.div`
  /* width: 100%; */
  display: flex;
  max-width: min-content;

  .swiper-pagination-bullet {
    display: none;
  }
`;

export const LogosProjeto = styled.div`
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  max-width: min-content;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 360px) {
    justify-items: center;
    grid-template-columns: 1fr;

    & > img + img {
      margin-top: 25px;
    }
  }
`;

export const LogosSocial = styled.div``;

export const LandingFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;

  background: #4f4f4f;

  & > div {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  & > div > img + img,
  & > div > a + a {
    margin-left: 15px;
  }

  & > div + div {
    margin-top: 25px;
  }
`;

export const AddressFooter = styled.div`
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;

  & > p + p {
    margin-top: 10px;
  }

  & > p > img {
    margin-right: 10px;
  }
`;

export const CardSlide = styled.div`
  width: 400px !important;
  height: 265px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  margin: 10px;

  display: flex;

  .card-left {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #26a2b1;
  }

  .card-right {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .card-right p {
    color: #828282;
    font-family: RobotoRegular;
    font-size: 18px;
    text-align: left;
  }

  .card-title {
    font-family: RobotoBold;
    font-size: 25px;
    width: 100%;
    color: #4f4f4f;
  }

  .card-body {
    width: 100%;
  }

  @media (max-width: 360px) {
    width: 240px !important;
  }
`;

export const WrapModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: 'InterBold', sans-serif;
    font-size: 30px;
    line-height: 36px;
    color: #3f3f3f;
    margin-bottom: 40px;
  }

  form {
    width: 100%;

    button {
      margin-top: 60px;
      margin-bottom: 24px;
    }

    & + input {
      margin-bottom: 16px;
    }
  }

  &.recovery {
    h1 {
      margin-bottom: 16px;
    }

    p {
      color: #3f3f3f;
      margin-bottom: 40px;
    }

    .inputRow {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
`;

export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '48px 32px 70px 32px',
  top: 0,
  left: 0,
  width: 467,
  border: 'none',
  borderRadius: '15px',
  boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.15)',
  webkitBoxShadow: '0px 5px 5px rgba(0, 0, 0, 0.15)',
  mozBoxShadow: '0px 5px 5px rgba(0, 0, 0, 0.15)',
};
