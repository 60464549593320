import React, { createContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { showModalMessage, showToastMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';
import tenancy_env from '~/utils/MultiTenancyUtil';

const defaultTheme = {
  side_bar_color: '#000',
  horizontal_bar_color: '#3f3f3f',
  background_page_color: '#ffffff',
  text_color: '#8DA1B5',
  system_name: 'SP Cine',
  system_logo: { uuid: undefined, url: undefined },
  system_background: { uuid: undefined, url: undefined },
};

const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState(defaultTheme);

  const encryptLS = themeLS =>
    CryptoJS.AES.encrypt(
      themeLS,
      tenancy_env('REACT_APP_API_BASE_URL').toString(),
    );

  const decryptLS = () =>
    localStorage.getItem('@Spcine:theme')
      ? CryptoJS.AES.decrypt(
          localStorage.getItem('@Spcine:theme'),
          tenancy_env('REACT_APP_API_BASE_URL'),
        ).toString(CryptoJS.enc.Utf8)
      : {};

  const formatThemeFromAPI = themeLS => {
    if (Object.values(themeLS || {}).length <= 0) return undefined;

    const systemLogoTmp = themeLS.files.find(({ name }) => name === 'Logo');

    const systemBgTmp = themeLS.files.find(({ name }) => name === 'Background');

    const themeAPI = {
      ..._.omit(themeLS, ['files']),
      system_logo: { uuid: systemLogoTmp?.uuid, url: systemLogoTmp?.url },
      system_background: {
        uuid: systemBgTmp?.uuid,
        url: systemBgTmp?.url,
      },
    };

    return themeAPI;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      let themeLS = decryptLS();

      if (Object.values(themeLS || {}).length <= 0) {
        try {
          const result = await ApiClient.doRequest({
            uri: 'customization',
            method: ApiClient.GET,
            expectedStatus: 200,
            token: '',
          });

          const themeAPI = formatThemeFromAPI(result.data.data);
          themeLS = JSON.stringify(themeAPI || defaultTheme);
        } catch (err) {
          themeLS = JSON.stringify(defaultTheme);
          showToastMessage({
            type: 'error',
            text: 'Erro ao tentar buscar customizações dos sistema!',
          });
        } finally {
          const hashTheme = encryptLS(themeLS);
          localStorage.setItem('@Spcine:theme', hashTheme);
        }
      }
      setTheme(JSON.parse(themeLS));
      setLoading(false);
    })();
  }, []);

  const updateTheme = useCallback(async newTheme => {
    try {
      setLoading(true);

      const uriMethod = !newTheme?.uuid
        ? { uri: 'customization', method: ApiClient.POST }
        : { uri: `customization/${newTheme.uuid}`, method: ApiClient.PATCH };

      const result = await ApiClient.doRequest({
        ...uriMethod,
        expectedStatus: 201,
        body: newTheme,
      });

      const themeAPI = formatThemeFromAPI(result.data.data);
      const hashTheme = encryptLS(JSON.stringify(themeAPI));

      localStorage.setItem('@Spcine:theme', hashTheme);
      setTheme(themeAPI);
      setLoading(false);
      await showModalMessage({
        type: 'success',
        title: 'Tema',
        text: 'Atualizações do tema salvos com sucesso',
      });
      return true;
    } catch (err) {
      await showModalMessage({
        type: 'error',
        title: 'Tema',
        text: 'Erro ao tentar salvar o novo tema',
      });
      setLoading(false);
      return false;
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ updateTheme, theme, loading }}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
