/* eslint-disable no-lonely-if */
import React, { useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { FiLoader } from 'react-icons/fi';
import { useLocation, useHistory } from 'react-router-dom';
import { Center } from './styles';
import tenancy_env from '~/utils/MultiTenancyUtil';
import { showModalMessage } from '~/services/notification';

export default function RedirecionaLocacao() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const locacaoBaseEndpoint = 'solicitacao-nova-locacao';
      const params = new URLSearchParams(location.search);
      const dataObject = JSON.parse(
        CryptoJS.AES.decrypt(
          params.get('query'),
          tenancy_env('REACT_APP_API_BASE_URL'),
        ).toString(CryptoJS.enc.Utf8),
      );

      // validation keys
      const commonKeys = ['endpoint', 'mode', 'is_admin'];
      if (!commonKeys.every(k => Object.keys(dataObject).includes(k))) {
        await showModalMessage({
          type: 'error',
          text: 'Parâmetros não encontrados!',
        });
        setTimeout(() => window.close(), 5000);
        return;
      }

      if (dataObject?.mode === 'view' || dataObject?.mode === 'edit') {
        if (!Object.keys(dataObject).includes('uuid')) {
          await showModalMessage({
            type: 'error',
            text: 'Parâmetros não encontrados para Visualização/Edição de Locação!',
          });
          setTimeout(() => window.close(), 5000);
          return;
        }
      }

      if (dataObject?.mode === 'create') {
        if (!Object.keys(dataObject).includes('work_uuid')) {
          await showModalMessage({
            type: 'error',
            text: 'Parâmetros não encontrados para Criação de Locação!',
          });
          setTimeout(() => window.close(), 5000);
          return;
        }
      }

      // redirects
      if (dataObject?.endpoint !== 'obra') {
        if (dataObject?.mode === 'create') {
          history.replace({
            pathname: `/${locacaoBaseEndpoint}/${dataObject?.endpoint}`,
            state: {
              uuid: dataObject?.work_uuid,
              nome_projeto: dataObject?.nome_projeto,
            },
          });
        }

        if (dataObject?.mode === 'edit') {
          history.replace({
            pathname: `/${locacaoBaseEndpoint}/${dataObject?.endpoint}/edit`,
            state: {
              viewData: {
                uuid: dataObject?.uuid,
                endpoint: dataObject?.endpoint,
              },
              isAdminRequest: dataObject?.is_admin,
              nome_projeto: dataObject?.nome_projeto,
            },
          });
        }

        if (dataObject?.mode === 'view') {
          history.replace({
            pathname: `/${locacaoBaseEndpoint}/${dataObject?.endpoint}`,
            state: {
              viewData: {
                uuid: dataObject?.uuid,
                endpoint: dataObject?.endpoint,
              },
              isAdminRequest: dataObject?.is_admin,
              nome_projeto: dataObject?.nome_projeto,
            },
          });
        }
      } else {
        if (dataObject?.mode === 'view') {
          history.replace({
            pathname: `/${dataObject?.endpoint}`,
            state: { data: dataObject },
          });
        }
      }
    })();
  }, []);

  return (
    <Center>
      <FiLoader size={50} className="icon-spin" />
    </Center>
  );
}
