import React, { useCallback, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { BsFillEyeFill } from 'react-icons/bs';
import { GoClippy } from 'react-icons/go';
import { format } from 'date-fns';
import { ModalStatusVisita } from '~/components/Admin';
import { useAuth } from '~/hooks/auth';

function ListaPageVisitasTecnicas({
  data,
  visitaStatusList,
  loadVisitasTecnicas,
}) {
  const [openModalStatusVisitas, setOpenModalStatusVisitas] = useState(false);
  const handleCloseModal = useCallback(() => {
    setOpenModalStatusVisitas(false);
  }, []);

  const { user: authUser } = useAuth();
  const hasAdminRole = authUser?.roles?.some(role => role === 'Administrador');

  return (
    <>
      <tr>
        <td>{data && data?.locacao_protocol}</td>
        <td>{data && data?.titulo_obra}</td>
        <td>
          {data &&
            format(
              new Date(`${data?.calendario[0]?.calendario_data} 00:00:00`),
              'dd/MM/yyyy',
            )}
        </td>
        <td>{data && data?.status.name}</td>
        <td
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="buttons"
        >
          <span tooltip="Mais detalhes...">
            <Link
              to={{
                pathname: `/solicitacao-nova-locacao/${data?.endpoint}/visita-tecnica`,
                state: { viewData: data },
              }}
              style={{ margin: 6 }}
            >
              <BsFillEyeFill color="#4f4f4f" size={20} />
            </Link>
          </span>
          {hasAdminRole && (
            <span tooltip="Mudar status...">
              <button
                type="button"
                onClick={() => setOpenModalStatusVisitas(true)}
              >
                <GoClippy color="#4f4f4f" size={22} />
              </button>
            </span>
          )}
        </td>
      </tr>
      <ModalStatusVisita
        visitaStatusList={visitaStatusList}
        visita={data}
        openModal={openModalStatusVisitas}
        closeModal={handleCloseModal}
        loadVisitas={loadVisitasTecnicas}
      />
    </>
  );
}

export default withRouter(ListaPageVisitasTecnicas);
