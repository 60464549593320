import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FiLogOut } from 'react-icons/fi';
import { BiLoaderAlt } from 'react-icons/bi';

import Avatar from '~/assets/images/catalogo/avatar-profissional-servico.png';

import Button from '~/components/Button';

import {
  Wrap,
  BannerContent,
  PageContent,
  TextBanner,
  PageFooter,
  BannerBackground,
  WrapButtons,
  BuscaContainer,
  ProfissionalListContainer,
  CardProfissionalList,
  ItemProfissionalList,
  OtherText,
  MainLoading,
  CenterHeaderIcons,
} from './styles';

import { PageContainer } from '~/styles/components';

import { Select } from '~/components/Forms';

import { langs } from '~/data/catalogoTranslation';

import FlagIcon from '~/components/FlagIcon';
import LogoIcon from '~/components/LogoIcon';
import { useAuth } from '~/hooks/auth';
import { LogOut } from '../styles';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage, showToastMessage } from '~/services/notification';
import clientResources from '~/data/clientResources';

export default function ProfissionalServico() {
  const history = useHistory();
  const formRef = useRef();

  const [formDados, setFormDados] = useState({});
  const [loading, setLoading] = useState(false);

  // Catálogo
  const [catalogoPF, setCatalogoPF] = useState([]);
  const [catalogoPJ, setCatalogoPJ] = useState([]);

  // Select options
  const [disableCargosSelect, setDisableCargosSelect] = useState(false);
  const [disableServicosSelect, setDisableServicosSelect] = useState(false);
  const [cargosOptions, setCargosOptions] = useState([]);
  const [servicosOptions, setServicosOptions] = useState([]);
  const [idiomasOptions, setIdiomasOptions] = useState([]);

  const { signOut } = useAuth();
  const dataLogado = JSON.parse(localStorage.getItem('persist:Spcine'));

  let langStorage = localStorage.getItem('@language');

  if (
    !langStorage ||
    langStorage === 'null' ||
    langStorage === null ||
    langStorage === undefined
  ) {
    localStorage.setItem('@language', 'BR');

    langStorage = localStorage.getItem('@language');
  }

  function handleViewProfile(profile) {
    history.push({ pathname: 'perfil', state: { dadosPerfil: profile } });
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    if (name === 'cargo') {
      setDisableServicosSelect(true);
      setDisableCargosSelect(false);
    }
    if (name === 'servico') {
      setDisableCargosSelect(true);
      setDisableServicosSelect(false);
    }
    setFormDados(state => ({ ...state, [name]: value }));
  }

  const fetchSelects = useCallback(async () => {
    try {
      const respCatalogo = await ApiClient.doRequest({
        uri: 'ld-funcoes-catalogo?paginate=false',
        method: ApiClient.GET,
        expectedStatus: 200,
        token: '',
      });

      const fieldLang = {
        BR: 'name',
        EN: 'name_eng',
        ES: 'name_esp',
      };

      // setando idioma no dropdown de cargo
      setCargosOptions(
        respCatalogo?.data?.data?.map(lang => ({
          value: lang.uuid,
          label: lang[fieldLang[langStorage]],
        })),
      );

      const respServicos = await ApiClient.doRequest({
        uri: 'ld-servicos-ofertados?paginate=false',
        method: ApiClient.GET,
        expectedStatus: 200,
        token: '',
      });

      setServicosOptions(
        respServicos?.data?.data?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );

      const respIdiomas = await ApiClient.doRequest({
        uri: 'ld-idioma?paginate=false',
        method: ApiClient.GET,
        expectedStatus: 200,
        token: '',
      });

      // setando idioma no dropdown de idioma
      setIdiomasOptions(
        respIdiomas?.data?.data?.map(lang => ({
          value: lang.uuid,
          label: lang[fieldLang[langStorage]],
        })),
      );
    } catch (err) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao buscar informações de catálogo',
      });
    }
  }, []);

  useEffect(() => {
    fetchSelects();
  }, [fetchSelects]);

  const renderCatalogo = useCallback(async () => {
    try {
      const responsePF = await ApiClient.doRequest({
        uri: `catalogo-sem-auth-pf?paginate=false${
          formDados?.cargo
            ? `&filters[][ld_funcoes_catalogo.uuid@equal]=${formDados?.cargo}`
            : ''
        }${
          formDados?.idioma
            ? `&filters[][ld_idioma.uuid@equal]=${formDados?.idioma}`
            : ''
        }`,
        method: ApiClient.GET,
        expectedStatus: 200,
        token: '',
      });
      setCatalogoPF(responsePF?.data?.data);

      const responsePJ = await ApiClient.doRequest({
        uri: `catalogo-sem-auth-pj?paginate=false${
          formDados?.servico
            ? `&filters[][ld_servico_ofertado.uuid@equal]=${formDados?.servico}`
            : ''
        }${
          formDados?.idioma
            ? `&filters[][ld_idioma.uuid@equal]=${formDados?.idioma}`
            : ''
        }`,
        method: ApiClient.GET,
        expectedStatus: 200,
        token: '',
      });

      setCatalogoPJ(responsePJ?.data?.data);
    } catch (err) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao buscar informações de catálogo',
      });
    }
  }, []);

  useEffect(() => {
    renderCatalogo();
  }, [renderCatalogo]);

  const handleSubmit = useCallback(
    async _ => {
      formRef.current.setErrors({});

      try {
        setLoading(true);
        if (disableServicosSelect) {
          setCatalogoPJ([]);

          const response = await ApiClient.doRequest({
            uri: `catalogo-sem-auth-pf?paginate=false${
              formDados?.cargo
                ? `&filters[][ld_funcoes_catalogo.uuid@equal]=${formDados?.cargo}`
                : ''
            }${
              formDados?.idioma
                ? `&filters[][ld_idioma.uuid@equal]=${formDados?.idioma}`
                : ''
            }`,
            method: ApiClient.GET,
            expectedStatus: 200,
            token: '',
          });
          setCatalogoPF(response?.data?.data);
        } else {
          setCatalogoPF([]);

          const response = await ApiClient.doRequest({
            uri: `catalogo-sem-auth-pj?paginate=false${
              formDados?.servico
                ? `&filters[][ld_servico_ofertado.uuid@equal]=${formDados?.servico}`
                : ''
            }${
              formDados?.idioma
                ? `&filters[][ld_idioma.uuid@equal]=${formDados?.idioma}`
                : ''
            }`,
            method: ApiClient.GET,
            expectedStatus: 200,
            token: '',
          });

          setCatalogoPJ(response?.data?.data);
        }
        setLoading(false);
      } catch (err) {
        await showModalMessage({ type: 'error', text: err.message });
      }
    },
    [
      disableServicosSelect,
      formDados?.cargo,
      formDados?.idioma,
      formDados?.servico,
    ],
  );

  const handleClearSelect = () => {
    setDisableCargosSelect(false);
    setDisableServicosSelect(false);
    setFormDados({});
    setCatalogoPF([]);
    setCatalogoPJ([]);
    formRef.current.reset();
  };

  return (
    <>
      <MainLoading loading={loading}>
        <h3>Processando Informações</h3>
        <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
      </MainLoading>
      <Header>
        <LogoIcon disabled={!dataLogado?.auth?.logado} />
        <CenterHeaderIcons>
          <FlagIcon />
          {dataLogado?.auth?.logado && (
            <LogOut onClick={() => signOut()}>
              <FiLogOut />
            </LogOut>
          )}
        </CenterHeaderIcons>
      </Header>
      <BannerHeader>
        <BannerBackground>
          <BannerContent>
            <TextBanner>
              <h1>{langs[langStorage].tituloLabel}</h1>
              <span>
                Home | {langs[langStorage].cadastroLabel} |
                <strong> {langs[langStorage].tituloLabel}</strong>
              </span>
            </TextBanner>
          </BannerContent>
        </BannerBackground>
      </BannerHeader>
      <PageContainer>
        <PageContent>
          {formDados && (
            <Form ref={formRef} autoComplete="off" onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BuscaContainer>
                  <Wrap>
                    <Select
                      name="cargo"
                      label={langs[langStorage].cargoLabel}
                      placeholder={langs[langStorage].selectLabel}
                      options={cargosOptions}
                      onChange={value => {
                        handleSelectChange('cargo', value);
                      }}
                      isDisabled={disableCargosSelect}
                    />
                    <OtherText>{langs[langStorage].ouLabel}</OtherText>
                    <Select
                      name="servico"
                      label={langs[langStorage].servicoLabel}
                      placeholder={langs[langStorage].selectLabel}
                      options={servicosOptions}
                      onChange={value => {
                        handleSelectChange('servico', value);
                      }}
                      isDisabled={disableServicosSelect}
                    />
                    <Select
                      name="idioma"
                      label={langs[langStorage].idiomaLabel}
                      placeholder={langs[langStorage].selectLabel}
                      options={idiomasOptions}
                      onChange={value => {
                        handleSelectChange('idioma', value);
                      }}
                    />
                    <WrapButtons>
                      <Button
                        type="submit"
                        label={langs[langStorage].buscarLabel}
                        btType="#26A2B1"
                        full
                        width="300px"
                      />
                      <Button
                        type="button"
                        label={langs[langStorage].limparLabel}
                        btType="#26A2B1"
                        full
                        width="75px"
                        onClick={handleClearSelect}
                      />
                    </WrapButtons>
                  </Wrap>
                </BuscaContainer>
                <ProfissionalListContainer>
                  {catalogoPF.length === 0 && catalogoPJ.length === 0 && (
                    <h4 style={{ textAlign: 'center', color: '#000' }}>
                      {langs[langStorage].listaVaziaLabel}
                    </h4>
                  )}
                  {catalogoPF.map(item => (
                    <CardProfissionalList>
                      <ItemProfissionalList
                        bgColor="#e0e0e0"
                        width="12%"
                        jCenter
                        bRadius="10px 0 0 10px"
                        style={{ justifyContent: 'center' }}
                      >
                        <img
                          src={
                            item?.files?.find(
                              ({ name }) => name === 'foto_user',
                            )?.url || Avatar
                          }
                          alt="Imagem Profissional"
                        />
                      </ItemProfissionalList>
                      <ItemProfissionalList width="15%">
                        <p>{langs[langStorage].nmLabel}</p>
                        <p>
                          <span>{item.name}</span>
                        </p>
                      </ItemProfissionalList>
                      <ItemProfissionalList>
                        <p>{langs[langStorage].cargoLabel}</p>
                        <p>
                          <span>
                            {langStorage &&
                              langStorage === 'BR' &&
                              item.ld_funcoes_catalogo.map(
                                func => `${func.name}, `,
                              )}
                            {langStorage &&
                              langStorage === 'EN' &&
                              item.ld_funcoes_catalogo.map(
                                func => `${func.name_eng}, `,
                              )}
                            {langStorage &&
                              langStorage === 'ES' &&
                              item.ld_funcoes_catalogo.map(
                                func => `${func.name_esp}, `,
                              )}
                          </span>
                        </p>
                      </ItemProfissionalList>
                      <ItemProfissionalList>
                        <p>{langs[langStorage].idiomasLabel}</p>
                        <p>
                          <span>
                            {langStorage &&
                              langStorage === 'BR' &&
                              item.ld_idioma.map(idm => `${idm.name},`)}
                            {langStorage &&
                              langStorage === 'EN' &&
                              item.ld_idioma.map(idm => `${idm.name_eng},`)}
                            {langStorage &&
                              langStorage === 'ES' &&
                              item.ld_idioma.map(idm => `${idm.name_esp},`)}
                          </span>
                        </p>
                      </ItemProfissionalList>
                      <ItemProfissionalList>
                        <p>
                          <h4>{langs[langStorage].miniBioLabel}</h4>
                        </p>
                        <p>
                          <span>{item.minibio}</span>
                        </p>
                      </ItemProfissionalList>
                      <ItemProfissionalList jCenter bRadius="0 10px 10px 0">
                        <Button
                          type="button"
                          label={langs[langStorage].verPerfilLabel}
                          btType="#26A2B1"
                          full
                          width="250px"
                          action={() => handleViewProfile(item)}
                        />
                      </ItemProfissionalList>
                    </CardProfissionalList>
                  ))}
                  {catalogoPJ.map(item => (
                    <CardProfissionalList>
                      <ItemProfissionalList
                        bgColor="#e0e0e0"
                        width="12%"
                        jCenter
                        bRadius="10px 0 0 10px"
                        style={{ justifyContent: 'center' }}
                      >
                        <img
                          src={
                            item?.files?.find(
                              ({ name }) => name === 'foto_branch',
                            )?.url || Avatar
                          }
                          alt="Imagem Profissional"
                        />
                      </ItemProfissionalList>
                      <ItemProfissionalList style={{ width: '15%' }}>
                        <p>Nome</p>
                        <p>
                          <span>{item.fantasy_name}</span>
                        </p>
                      </ItemProfissionalList>
                      <ItemProfissionalList>
                        <p>Serviços</p>
                        <p>
                          <span>
                            {item.ld_servico_ofertado.map(
                              serv => `${serv.name}`,
                            )}
                            <br />
                          </span>
                        </p>
                      </ItemProfissionalList>
                      <ItemProfissionalList>
                        <p>Idiomas</p>
                        <p>
                          <span>
                            {item.ld_idioma.map(idm => `${idm.name},`)}
                          </span>
                        </p>
                      </ItemProfissionalList>
                      <ItemProfissionalList>
                        <p>Minibio</p>
                        <p>
                          <span>{item.minibio}</span>
                        </p>
                      </ItemProfissionalList>
                      <ItemProfissionalList jCenter bRadius="0 10px 10px 0">
                        <Button
                          type="button"
                          label="VER PERFIL"
                          btType="#26A2B1"
                          full
                          width="250px"
                          action={() => handleViewProfile(item)}
                        />
                      </ItemProfissionalList>
                    </CardProfissionalList>
                  ))}
                </ProfissionalListContainer>
              </WhiteBox>
            </Form>
          )}
        </PageContent>
      </PageContainer>
      <PageFooter>
      <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
