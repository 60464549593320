const maskString = (value, pattern) => {
  if (!value) return value;

  let i = 0;
  const v = value.toString();
  // eslint-disable-next-line no-plusplus
  return pattern.replace(/#/g, () => v[i++] || '');
};

export default maskString;
