import { IconButton, Paper } from '@material-ui/core';
import styled from 'styled-components';
import { WhiteBox } from '~/components/WhiteBox/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-x: none;
  background-color: #f2f2f2;
  padding: 25px 25px 0 45px;
  margin: 0 auto;

  table {
    width: 100%;
    margin-top: 32px;

    thead {
      width: 100%;
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  form {
    width: 100%;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: stretch;
  width: 100%;

  ${WhiteBox} {
    justify-content: flex-start;
    margin: 30px 30px;

    img {
      height: 72px;
    }
  }
`;

export const CardImage = styled.div`
  display: flex;
  position: relative;
  margin-right: 62px;
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #eb5757;
  font-family: 'InterBold', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -15px;
  right: -30px;
`;

export const CardTitle = styled.div`
  font-family: 'InterBold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #4f4f4f;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 80px;

  h2 {
    font-family: 'InterSemiBold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #011f3b;
  }

  &.modalScrollBar {
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
`;

export const Lembretes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 440px;
  overflow: auto;
  overflow-x: none;
  border-left: 1px solid #bdbdbd;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const CardLembrete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 24px 48px 48px 24px;
  margin-bottom: 48px;

  &:last-child {
    margin: 0;
  }
`;

export const DateLembrete = styled.h5`
  font-family: 'PoppinsBold', sans-serif;
  font-size: 13px;
  line-height: 17px;
  color: #334d6e;
  opacity: 0.5;
  margin-bottom: 8px;
`;

export const DescriptionLembrete = styled.p`
  font-family: 'PoppinsSemiBold', sans-serif;
  font-size: 13px;
  line-height: 17px;
  color: #333333;
  opacity: 0.5;
`;

export const TableTopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0 24px 0;

  h2 {
    font-family: 'PoppinsBold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #323c47;
  }
`;

export const TableButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button,
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'PoppinsSemiBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #323c47;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      margin-right: 12px;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;

  a {
    font-family: 'RobotoBold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
  }

  label {
    color: #000;
    font-size: 13px;
    font-family: 'InterRegular', sans-serif;
  }

  .calendario {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 0;

    .react-calendar__month-view__days {
      display: flex;
      flex-wrap: wrap;
      height: 350px;
    }

    .react-calendar__navigation__label__labelText {
      color: #000;
      text-transform: capitalize;
      font-size: 25px;
    }

    .react-calendar__month-view__days__day {
      border-radius: 50%;
    }

    .react-calendar__tile--active {
      background-color: #5fae37;
    }

    .react-calendar__tile--now {
      border: 1px solid #26a2b1;
      color: #fff;
      background: #26a2b1;
    }

    .react-calendar__month-view__weekdays {
      color: #000;
    }
  }
`;

export const ButtonModal = styled.button`
  display: flex;
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  color: #99a6b7;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: 40,
  borderRadius: 10,
  top: 0,
  left: 0,
  minHeight: 150,
  width: '90%',
  height: '600px',
  maxWidth: 600,
  border: 'none',
};

export const ModalStyled = styled(Paper)`
  position: fixed;
  width: 50%;
  height: 400px;
  background: white;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;

  h4 {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
  }

  hr {
    margin-bottom: 40px;
  }
`;

export const CloseModalStyled = styled(IconButton)`
  float: right;
`;

export const ContentFilterModalStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const ButtonContentModalFilterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
