// @ts-nocheck
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  position: relative;
`;

export const Label = styled.label`
  font-family: 'CircularStdBook', sans-serif;
  font-size: 22px;
  line-height: 26px;
  color: ${({ disabled }) => (disabled ? '#E5E5E5' : '#373737')};

  @media screen and (max-width: 950px) {
    font-size: 15px;
    line-height: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background: transparent;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid black;
    position: relative;

    > img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  > label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #26a2b1;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }

  input {
    display: none;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--principal-40);
      border-color: var(--principal-40);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--principal-40);
    `}

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 950px) {
    span,
    span.resetPassword {
      font-size: 17px;
      line-height: 28px;
    }
  }
`;
