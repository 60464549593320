import { format, isValid, parse } from 'date-fns';
import { isSafari } from 'react-device-detect';
import ApiClient from '~/utils/ApiClient';

export function validDateArray(value, dateFieldName) {
  try {
    const pattern = isSafari ? 'dd/MM/yyyy' : 'yyyy-MM-dd';

    return value.every(date =>
      isValid(parse(date[dateFieldName], pattern, new Date())),
    );
  } catch (error) {
    return false;
  }
}

export function validHourArray(value, hourBeginFieldName, hourEndFieldName) {
  try {
    const pattern = 'HH:mm';

    return value.every(
      hour =>
        isValid(parse(hour[hourBeginFieldName], pattern, new Date())) &&
        isValid(parse(hour[hourEndFieldName], pattern, new Date())),
    );
  } catch (error) {
    return false;
  }
}

export function getBrowserTransformDate(value, isDatabaseDate = false) {
  const enDateFormat = 'yyyy-MM-dd';
  const ptBrDateFormat = 'dd/MM/yyyy';
  const fromDateFormat = !isDatabaseDate ? ptBrDateFormat : enDateFormat;
  const toDateFormat = !isDatabaseDate ? enDateFormat : ptBrDateFormat;

  return !isSafari
    ? value
    : format(parse(value, fromDateFormat, new Date()), toDateFormat);
}

const saveDateChanges = async (changesToApprove, approved) => {
  try {
    if (Object.values(changesToApprove || []).length <= 0) {
      return {
        message_type: 'info',
        message_text: 'Nenhuma mudança a ser efetuada',
      };
    }

    if (
      `${changesToApprove[0].new_value} 00:00:00` ===
      changesToApprove[0].old_value
    ) {
      return {
        message_type: 'info',
        message_text: 'Nenhuma mudança a ser efetuada',
      };
    }

    await ApiClient.doRequest({
      uri: `changes-to-approve/${changesToApprove[0].uuid}`,
      method: ApiClient.PATCH,
      expectedStatus: 201,
      body: { approved },
    });

    return {
      message_type: 'success',
      message_text: `Data ${
        approved ? 'aprovada' : 'desaprovada'
      } com sucesso!`,
    };
  } catch (error) {
    const errorBody = JSON.parse(error.message);
    return {
      message_type: 'error',
      message_text: errorBody.error.error.message,
    };
  }
};

const saveHourChanges = async (changesToApprove, approved) => {
  try {
    if (Object.values(changesToApprove || []).length <= 0) {
      return {
        message_type: 'info',
        message_text: 'Nenhuma mudança a ser efetuada',
      };
    }

    const hoursUuid = changesToApprove.map(({ uuid }) => uuid);

    await ApiClient.doRequest({
      uri: `changes-to-approve-hours`,
      method: ApiClient.PATCH,
      expectedStatus: 201,
      body: { approved, hours: hoursUuid },
    });

    return {
      message_type: 'success',
      message_text: `Horas ${
        approved ? 'aprovadas' : 'desaprovadas'
      } com sucesso!`,
    };
  } catch (error) {
    const errorBody = JSON.parse(error.message);
    return {
      message_type: 'error',
      message_text: errorBody.error.error.message,
    };
  }
};

export async function confirmEditDateHour({
  approved,
  changesToApprove,
  multiInputType,
}) {
  switch (multiInputType) {
    case 'data':
      return saveDateChanges(changesToApprove, approved);
    case 'hora':
      return saveHourChanges(changesToApprove, approved);
    default:
      return {
        message_type: 'error',
        message_text: 'Tipo desconhecido para efetuar mudanças',
      };
  }
}
