import diff from 'diff-arrays-of-objects';

export default function diffJao({ responsaveisGravados, responsaveisInput }) {
  // if equals
  if (
    JSON.stringify(responsaveisGravados.sort()) ===
    JSON.stringify(responsaveisInput.sort())
  ) {
    return null;
  }

  const results = diff(responsaveisGravados, responsaveisInput, 'uuid');
  const { updated, added, removed } = results;

  const responsaveisDiff = added.concat(
    updated,
    removed?.map(resp => ({ uuid: resp.uuid, cpf: '' })),
  );

  return responsaveisDiff;
}
