import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import CryptoJS from 'crypto-js';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  InputMask,
  Select,
  ChoiceButtonLocacao,
  Textarea,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ConditionalContainer,
  LimiterInputWidth,
  ActionInputMulti,
  MultiInputContainer,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import ImpactoListActions from '~/store/ducks/impacto-list';
import ComplementoNumeroListActions from '~/store/ducks/complemento-numero-list';
import FinalidadeReservaListActions from '~/store/ducks/finalidade-reserva-list';
import LocalGeradorListActions from '~/store/ducks/local-gerador-list';
import Documents from '~/components/Documents/Documents';
import {
  ModalidadePagamento,
  ObservacoesChat,
  ValorLocacao,
} from '~/components/Admin';
import Chat from '~/components/Chat/chat';
import LocacaoDocs from '~/pages/Usuario/Locacoes/LocacaoDocs';
import { useList } from '~/hooks/useFunction';
import LogoIcon from '~/components/LogoIcon';
import WorkStatusSwitch from '~/components/Admin/WorkStatusSwitch';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import {
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import tenancy_env from '~/utils/MultiTenancyUtil';
import { ActionButton } from '~/components/Forms/FormChoiceButton/styles';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';
import { IntervalFormMultiInput } from '~/components/Forms/IntervalFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const selectExtensaoList = [
  { value: 'metragem', label: 'Metragem' },
  { value: 'intervalo', label: 'Numeração' },
];

export default function ReservasVagasFilmInternas() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseLocation, setResponseLocation] = useState();
  const [errorsForm, setErrorsForm] = useState({});
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);
  const [dadosCep, setDadosCep] = useState(null);
  const [reservaMesmaLocacao, setReservaMesmaLocacao] = useState(null);
  const [outraReservaVagas, setOutraReservaVagas] = useState(null);
  const [associarLocacao, setAssociarLocacao] = useState(null);

  // states multi input
  const [intervaloInputs, setIntervaloInputs] = useState([
    { start: '', end: '' },
  ]);
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states choices
  const [localFilmagem, setLocalFilmagem] = useState(null);
  const [gerador, setGerador] = useState(null);
  const [blimpado, setBlimpado] = useState(null);
  const [drone, setDrone] = useState(null);

  // state outro fields
  const [showOutroFinReservaField, setShowOutroFinReservaField] =
    useState(false);
  const [showExtensaoMetragemField, setShowExtensaoMetragemField] =
    useState(false);
  const [showExtensaoIntervalosField, setShowExtensaoIntervalosField] =
    useState(false);
  const [showNumberFields, setShowNumberFields] = useState(false);
  const [showDescrAltoImpactoLocalField, setShowDescrAltoImpactoLocalField] =
    useState(false);

  // lists
  const reservaVagasFilmInternasAssociacaoList = useList({
    url: `locacao-reserva-vaga?filters[][work.uuid@equal]=${dadoState?.uuid}&paginate=false`,
  });

  // selectors
  const { data: impactoSelectList } = useSelector(state => state.impactoList);
  const { data: complementoNumeroSelectList } = useSelector(
    state => state.complementoNumeroList,
  );
  const { data: finalidadeReservaSelectList } = useSelector(
    state => state.finalidadeReservaList,
  );
  const { data: localGeradorSelectList } = useSelector(
    state => state.localGeradorList,
  );

  // Busca dados da Reserva
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-reserva-vaga/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set temp variables
      const blimpadoTmp =
        respLoc?.blimpado === null ? null : respLoc?.blimpado === 1;

      // set choices buttons
      setBlimpado(blimpadoTmp);
      setGerador(
        blimpadoTmp === true ||
          Object.values(respLoc?.ld_local_gerador || {}).length > 0,
      );
      setLocalFilmagem(typeof respLoc?.endereco_filmagem === 'string');
      setDrone(respLoc?.drone === 1);
      setAssociarLocacao(Object.values(respLoc?.parent || {}).length > 0);

      // set multi inputs
      setDatasFilmagemInputs(
        respLoc?.date_locacao_reserva_vaga?.map(
          ({ date, changes_to_approve }) => ({
            data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
            changes_to_approve,
          }),
        ),
      );

      setHorasFilmagemInputs(
        respLoc?.hour_locacao_reserva_vaga?.map(
          ({ inicio, fim, changes_to_approve }) => ({
            inicio: inicio.substring(0, 5),
            fim: fim.substring(0, 5),
            changes_to_approve,
          }),
        ),
      );

      setIntervaloInputs(
        respLoc?.reserva_intervalos
          ? respLoc?.reserva_intervalos?.map(({ start, end }) => {
              return { start, end };
            })
          : [{ start: '', end: '' }],
      );

      // set campos 'Outro'
      setShowOutroFinReservaField(
        respLoc?.finalidades_reserva_vaga?.find(
          finalidade => finalidade.name === 'Outros',
        ),
      );

      // set select default values
      formRef.current?.setFieldValue('ld_complemento_numero_uuid', {
        label: respLoc?.ld_complemento_numero?.name,
        value: respLoc?.ld_complemento_numero?.uuid,
      });

      const filterExtensaoValue =
        Object.values(respLoc?.reserva_intervalos || []).length > 0
          ? 'intervalo'
          : 'metragem';
      formRef.current?.setFieldValue(
        'select_extensao',
        selectExtensaoList.filter(
          extensao => extensao.value === filterExtensaoValue,
        ),
      );

      formRef.current?.setFieldValue(
        'finalidades',
        respLoc?.finalidades_reserva_vaga?.map(ilfr => {
          return {
            label: ilfr.name,
            value: ilfr.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_local_gerador_uuid', {
        label: respLoc?.ld_local_gerador?.name,
        value: respLoc?.ld_local_gerador?.uuid,
      });

      formRef.current?.setFieldValue('ld_impacto_uuid', {
        label: respLoc?.ld_impacto?.name,
        value: respLoc?.ld_impacto?.uuid,
      });

      formRef.current?.setFieldValue('reserva_vagas_film_internas_associacao', {
        label: `${respLoc?.parent?.name_view} - ${respLoc?.parent?.protocol}`,
        value: {
          uuid: respLoc?.parent?.uuid,
          endpoint: respLoc?.parent?.endpoint,
        },
      });

      formRef.current?.setFieldValue(
        'cep',
        resp?.data?.data?.cep?.replace(/(\d)(\d{3})$/, '$1-$2'),
      );

      // set show campos condicionais
      setShowNumberFields(typeof respLoc?.number === 'string');
      setShowExtensaoMetragemField(
        Object.values(respLoc?.reserva_intervalos || []).length <= 0,
      );
      setShowExtensaoIntervalosField(
        Object.values(respLoc?.reserva_intervalos || []).length > 0,
      );
      setShowDescrAltoImpactoLocalField(
        typeof respLoc?.descr_alto_impacto_local === 'string',
      );

      setResponseLocation(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState?.viewData]);

  // effects
  useEffect(() => {
    dispatch(ImpactoListActions.showRequest());
    dispatch(ComplementoNumeroListActions.showRequest());
    dispatch(FinalidadeReservaListActions.showRequest());
    dispatch(LocalGeradorListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  function handleChangeCep(event) {
    const cep = event.target.value?.replace('-', '')?.replace('_', '');

    if (!cep || cep?.length < 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(res => res.json())
      .then(async data => {
        if (data?.erro === true) {
          await showModalMessage({
            type: 'error',
            text: 'CEP inexistente!',
          });
          setDadosCep(null);
          return;
        }
        setDadosCep(data);
      });
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'number') {
      setShowNumberFields(value !== '');
    }
  }

  function handleMultiSelectFinalidades(e) {
    setShowOutroFinReservaField(!!e?.find(x => x.label === 'Outros'));

    setFormData(state => ({
      ...state,
      finalidades: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));

    if (name === 'select_extensao') {
      setShowExtensaoMetragemField(option.value === 'metragem');
      setShowExtensaoIntervalosField(option.value === 'intervalo');
    }
  }

  function formatEspecificaData(data) {
    const locacaoAssociada = reservaVagasFilmInternasAssociacaoList?.find(
      ({ uuid }) => uuid === data?.reserva_vagas_film_internas_associacao?.uuid,
    );

    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      ld_impacto_uuid: data?.ld_impacto_uuid,
      cep: data?.cep?.replace('-', ''),
      street: data?.street,
      number: data?.number,
      neighborhood: data?.neighborhood,
      finalidades: data?.finalidades,
      especificidades: data?.especificidades,
    });

    // dados de choices
    const dataLocalFilmagem = {
      endereco_filmagem: localFilmagem ? data?.endereco_filmagem : null,
    };

    const dataGerador = {
      ld_local_gerador_uuid: gerador ? data?.ld_local_gerador_uuid : null,
      blimpado: gerador ? blimpado : null,
    };

    const dataDrone = { drone };

    const dataAssociarLocacao = {
      parent_uuid: associarLocacao ? locacaoAssociada?.uuid : null,
    };

    // dados de campos exibidos condicionalmente
    const dataDescrAltoImpactoLocal = {
      descr_alto_impacto_local: showDescrAltoImpactoLocalField
        ? data?.descr_alto_impacto_local
        : null,
    };

    const dataNumberFields = showNumberFields
      ? { ld_complemento_numero_uuid: data?.ld_complemento_numero_uuid }
      : { ld_complemento_numero_uuid: null };

    const dataExtensaoMetragem = {
      metragem: showExtensaoMetragemField ? +data?.metragem : null,
    };

    const dataExtensaoIntervalos = {
      reserva_intervalo: showExtensaoIntervalosField ? intervaloInputs : null,
    };

    const dataOutroFinReserva = {
      outro_finalidade: showOutroFinReservaField
        ? data?.outro_finalidade
        : null,
    };

    // format datas reserva
    const datasReserva = datasFilmagemInputs
      .filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_filmagem));

    return Object.assign(
      dataNaoCondicional,
      dataDescrAltoImpactoLocal,
      dataNumberFields,
      dataExtensaoMetragem,
      dataExtensaoIntervalos,
      { dates: datasReserva },
      { hours: horasFilmagemInputs },
      dataLocalFilmagem,
      dataGerador,
      dataDrone,
      dataAssociarLocacao,
      dataOutroFinReserva,
    );
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        ld_impacto_uuid: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        descr_alto_impacto_local: Yup.string()
          .typeError(defaultMsg)
          .test(
            'alto-impacto-local-selecionado',
            defaultMsg,
            value =>
              !(
                showDescrAltoImpactoLocalField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        cep: Yup.string().typeError(defaultMsg).required(defaultMsg),
        street: Yup.string().typeError(defaultMsg).required(defaultMsg),
        number: Yup.string().typeError(defaultMsg).required(defaultMsg),
        neighborhood: Yup.string().typeError(defaultMsg).required(defaultMsg),
        select_extensao: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        finalidades: Yup.lazy(val =>
          Array.isArray(val)
            ? Yup.array().of(
                Yup.string().typeError(defaultMsg).required(defaultMsg),
              )
            : Yup.string().typeError(defaultMsg).required(defaultMsg),
        ),
        endereco_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'local-filmagem-selecionado',
            defaultMsg,
            value => !(localFilmagem === true && value.length <= 0),
          ),
        outro_finalidade: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-finalidade-selecionado',
            defaultMsg,
            value => !(showOutroFinReservaField === true && value.length <= 0),
          ),
        ld_complemento_numero_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'complemento-selecionado',
            defaultMsg,
            value => !(showNumberFields === true && value.length <= 0),
          ),
        metragem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'metragem-selecionado',
            defaultMsg,
            value => !(showExtensaoMetragemField === true && value.length <= 0),
          ),
        ld_local_gerador_uuid: Yup.string()
          .typeError(defaultMsg)
          .test(
            'gerador-selecionado',
            defaultMsg,
            value => !(gerador === true && value.length <= 0),
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }

      if (localFilmagem === null) {
        await showModalMessage({
          type: 'error',
          text: 'Local Filmagem não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          localFilmagem: true,
        }));
        return false;
      }

      if (gerador === null) {
        await showModalMessage({
          type: 'error',
          text: 'Gerador não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          gerador: true,
        }));
        return false;
      }

      if (gerador && blimpado === null) {
        await showModalMessage({
          type: 'error',
          text: 'Blimpado não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          blimpado: true,
        }));
        return false;
      }

      if (drone === null) {
        await showModalMessage({
          type: 'error',
          text: 'Drone não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          drone: true,
        }));
        return false;
      }

      if (outraReservaVagas === null) {
        await showModalMessage({
          type: 'error',
          text: 'Opção "Outra Reservas de vagas" não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          outraReservaVagas: true,
        }));
        return false;
      }

      if (outraReservaVagas && reservaMesmaLocacao === null) {
        await showModalMessage({
          type: 'error',
          text: 'Opção "Reservas para mesma Locação" não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          reservaMesmaLocacao: true,
        }));
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  function handleResetForm() {
    setFormData({});
    setErrorsForm({});
    setDadosCep(null);
    setOutraReservaVagas(null);
    setIntervaloInputs([{ start: '', end: '' }]);
    setHorasFilmagemInputs([{ inicio: '', fim: '' }]);
    setDatasFilmagemInputs([{ data_filmagem: '' }]);
    setLocalFilmagem(null);
    setGerador(null);
    setBlimpado(null);
    setDrone(null);
    setShowOutroFinReservaField(null);
    setShowExtensaoMetragemField(null);
    setShowExtensaoIntervalosField(null);
    setShowNumberFields(null);
    setShowDescrAltoImpactoLocalField(null);
    setReservaMesmaLocacao(null);

    formRef.current?.setFieldValue('ld_impacto_uuid', {});
    formRef.current?.setFieldValue('ld_complemento_numero_uuid', {});
    formRef.current?.setFieldValue('finalidades', []);
    formRef.current?.setFieldValue('ld_local_gerador_uuid', {});
    formRef.current?.setFieldValue('select_extensao', {});
  }

  async function handleSubmit(data, { reset }) {
    data.finalidades = formData?.finalidades;

    if (!(await handleValidationFields(data))) {
      return;
    }

    const reservasData = formatEspecificaData(data);

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: 'locacao-reserva-vaga',
        method: ApiClient.POST,
        body: Object.assign(reservasData, { work_uuid: dadoState?.uuid }),
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Reserva de Vagas Filmagens Internas',
        text: 'Locação gravada com sucesso',
      });

      if (reservaMesmaLocacao && outraReservaVagas) {
        handleResetForm();
        reset();
      } else {
        setTimeout(function () {
          history.push('/home-user');
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Reserva de Vagas Filmagens Internas',
        text: 'Ocorreu um erro ao tentar gravar Reservas de Vagas Filmagens Internas. Verifique os dados.',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  // handle true/false
  function handleGeradorTrue(e) {
    e.preventDefault();
    setGerador(true);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorFalse(e) {
    e.preventDefault();
    setGerador(false);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleLocalFilmagemTrue(e) {
    e.preventDefault();
    setLocalFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleLocalFilmagemFalse(e) {
    e.preventDefault();
    setLocalFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleBlimpadorTrue(e) {
    e.preventDefault();
    setBlimpado(true);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBlimpadorFalse(e) {
    e.preventDefault();
    setBlimpado(false);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleDroneTrue(e) {
    e.preventDefault();
    setDrone(true);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleDroneFalse(e) {
    e.preventDefault();
    setDrone(false);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleOutraReservaVagasTrue(e) {
    e.preventDefault(e);
    setOutraReservaVagas(true);
    setErrorsForm(state => ({
      ...state,
      outraReservaVagas: false,
    }));
  }

  function handleOutraReservaVagasFalse(e) {
    e.preventDefault(e);
    setOutraReservaVagas(false);
    setErrorsForm(state => ({
      ...state,
      outraReservaVagas: false,
    }));
  }

  function handleReservaMesmaLocacaoTrue(e) {
    e.preventDefault(e);
    setReservaMesmaLocacao(true);
    setErrorsForm(state => ({
      ...state,
      reservaMesmaLocacao: false,
    }));
  }

  function handleReservaMesmaLocacaoFalse(e) {
    e.preventDefault(e);
    setReservaMesmaLocacao(false);
    setErrorsForm(state => ({
      ...state,
      reservaMesmaLocacao: false,
    }));
  }

  function handleAssociarLocacaoTrue(e) {
    e.preventDefault();
    setAssociarLocacao(true);
    setErrorsForm(state => ({
      ...state,
      associarLocacao: false,
    }));
  }

  function handleAssociarLocacaoFalse(e) {
    e.preventDefault();
    setAssociarLocacao(false);
    setErrorsForm(state => ({
      ...state,
      associarLocacao: false,
    }));
  }

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                {!isAdminRequest && (
                  <>
                    <LocacaoDocs
                      excludes={['precosFilmagensEquipamentosPublicos']}
                    />
                  </>
                )}

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={dadoState?.viewData && true}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_impacto_uuid"
                      label="Você classifica sua filmagem como sendo:"
                      placeholder="Selecione"
                      required
                      questionTooltip="Clique aqui para saber mais"
                      questionOnClick={() => setOpenModalPorteFilmagem(true)}
                      options={impactoSelectList}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange('ld_impacto_uuid', value);
                        setShowDescrAltoImpactoLocalField(
                          value.label === 'alto impacto no local',
                        );
                      }}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={showDescrAltoImpactoLocalField}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descr_alto_impacto_local"
                      label="Comente qual o desafio da sua produção para auxiliarmos em sua viabilização"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descr_alto_impacto_local
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w50>
                    <InputMask
                      name="cep"
                      label="CEP"
                      mask="99999-999"
                      onBlur={handleChangeCep}
                      required
                      disabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="street"
                      label="Nome da Via"
                      required
                      value={
                        !dadosCep
                          ? responseLocation?.street || ''
                          : dadosCep?.logradouro
                      }
                      readOnly
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      required
                      value={
                        !dadosCep
                          ? responseLocation?.neighborhood || ''
                          : dadosCep?.bairro
                      }
                      readOnly
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <LimiterInputWidth w25>
                    <Input
                      type="text"
                      name="number"
                      label="Número"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.number
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showNumberFields}>
                    <LimiterInputWidth>
                      <Select
                        name="ld_complemento_numero_uuid"
                        label="Complemento"
                        required
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={complementoNumeroSelectList}
                        onChange={value => {
                          handleSelectChange(
                            'ld_complemento_numero_uuid',
                            value,
                          );
                        }}
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                  <LimiterInputWidth w50>
                    <Select
                      name="select_extensao"
                      label="Extensão"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={selectExtensaoList}
                      required
                      onChange={value => {
                        handleSelectChange('select_extensao', value);
                      }}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <ConditionalContainer show={showExtensaoMetragemField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name="metragem"
                        label="Metragem"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData && responseLocation?.metragem
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={showExtensaoIntervalosField}>
                  <IntervalFormMultiInput
                    label="Intervalo de Numeração"
                    tooltip="De ___ até ___ (Pode adicionar mais de um intervalo)"
                    isRequired={showExtensaoIntervalosField}
                    beginKeyName="start"
                    endKeyName="end"
                    isViewMode={dadoState?.viewData && true}
                    intervals={intervaloInputs}
                    setIntervals={setIntervaloInputs}
                  />
                </ConditionalContainer>

                <Wrap>
                  <DateFormMultiInput
                    label="Data da Reserva"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    dateKeyName="data_filmagem"
                    isAdminRequest={isAdminRequest}
                    tooltip="Possível adicionar mais de uma data"
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Hora da Reserva (Início - Fim)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="O horário informado deve ser referente ao início da operacionalização da reserva de vagas, e não da filmagem. Possível adicionar mais de um horário."
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="finalidades"
                      label="Finalidade da Reserva de Vagas"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={finalidadeReservaSelectList}
                      required
                      isMulti
                      onChange={handleMultiSelectFinalidades}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showOutroFinReservaField}>
                    <LimiterInputWidth>
                      <Input
                        type="text"
                        name="outro_finalidade"
                        label="Outro Finalidade Reserva"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.outro_finalidade
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Já possui local definido para filmagem?"
                      onClickTrue={handleLocalFilmagemTrue}
                      onClickFalse={handleLocalFilmagemFalse}
                      selected={localFilmagem}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.localFilmagem === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={localFilmagem === true}>
                  <Wrap>
                    <Input
                      type="text"
                      name="endereco_filmagem"
                      label="Endereço da Filmagem"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.endereco_filmagem
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap style={{ alignItems: 'flex-end' }}>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Haverá gerador?"
                      onClickTrue={handleGeradorTrue}
                      onClickFalse={handleGeradorFalse}
                      selected={gerador}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.gerador === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={gerador === true}>
                    <LimiterInputWidth w25>
                      <ChoiceButtonLocacao
                        label="Blimpado/Silencioso ou Não-Blimpado?"
                        onClickTrue={handleBlimpadorTrue}
                        onClickFalse={handleBlimpadorFalse}
                        selected={blimpado}
                        required
                        isDisabled={dadoState?.viewData && true}
                        error={errorsForm.blimpado === true && 'Obrigatório'}
                        yesLabelValue="Blimpado"
                        noLabelValue="Não-Blimpado"
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <ConditionalContainer show={gerador === true}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_local_gerador_uuid"
                        label="O gerador ficará:"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={localGeradorSelectList}
                        required
                        onChange={value => {
                          handleSelectChange('ld_local_gerador_uuid', value);
                        }}
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Drone"
                      onClickTrue={handleDroneTrue}
                      onClickFalse={handleDroneFalse}
                      selected={drone}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.drone === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={drone === true}>
                  <Wrap>
                    <Textarea
                      name="autorizacao_drones"
                      rows="4"
                      value="Para filmagens com drones: o Espaço Aéreo não é de administração municipal, mas sim da
                    Federação/União, portanto, não faz parte do campo de atuação da Ilhacine. A autorização para o uso
                    de drones deve ser solicitada diretamente à Agência Nacional de Aviação Civil (ANAC), mediante
                    cadastro no Sistema SARPAS, disponibilizado no site do Departamento de Controle do Espaço Aéreo
                    (DECEA)."
                      disabled
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Deseja associa-lá com uma Locação Reservas de Vagas Filmagens Internas já cadastrada?"
                      onClickTrue={handleAssociarLocacaoTrue}
                      onClickFalse={handleAssociarLocacaoFalse}
                      selected={associarLocacao}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={
                        errorsForm.associarLocacao === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={associarLocacao}>
                  <LimiterInputWidth w50>
                    <Select
                      name="reserva_vagas_film_internas_associacao"
                      label="Escolha a Locação a ser associada:"
                      placeholder="Selecione"
                      options={reservaVagasFilmInternasAssociacaoList?.map(
                        ({ uuid, protocol }) => ({
                          label: `Locação Reserva de Vaga - ${protocol}`,
                          value: { uuid, endpoint: 'locacao-reserva-vaga' },
                        }),
                      )}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange(
                          'reserva_vagas_film_internas_associacao',
                          value,
                        );
                      }}
                      required
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>

                  {Object.values(
                    formData?.reserva_vagas_film_internas_associacao || {},
                  ).length > 0 && (
                    <Wrap>
                      <FormChoiceButton
                        nomeProjeto={dadoState?.nome_projeto}
                        viewData={{
                          is_admin: isAdminRequest,
                          uuid: formData?.reserva_vagas_film_internas_associacao
                            ?.uuid,
                          endpoint:
                            formData?.reserva_vagas_film_internas_associacao
                              ?.endpoint,
                        }}
                      />
                    </Wrap>
                  )}
                </ConditionalContainer>

                {/* Exibir estas Choices exclusivamente na gravação dessa Locação */}
                {!dadoState?.viewData && (
                  <>
                    <Wrap>
                      <ChoiceButtonLocacao
                        label="Deseja adicionar outra reserva de vagas?"
                        onClickTrue={handleOutraReservaVagasTrue}
                        onClickFalse={handleOutraReservaVagasFalse}
                        selected={outraReservaVagas}
                        required
                        error={
                          errorsForm.outraReservaVagas === true && 'Obrigatório'
                        }
                      />
                    </Wrap>

                    {outraReservaVagas && (
                      <Wrap>
                        <ChoiceButtonLocacao
                          label="A nova reserva de vagas é para a mesma locação?"
                          onClickTrue={handleReservaMesmaLocacaoTrue}
                          onClickFalse={handleReservaMesmaLocacaoFalse}
                          selected={reservaMesmaLocacao}
                          required
                          error={
                            errorsForm.reservaMesmaLocacao === true &&
                            'Obrigatório'
                          }
                        />
                      </Wrap>
                    )}
                  </>
                )}

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label=" Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData && responseLocation?.especificidades
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                {responseLocation?.documents?.length > 0 && isAdminRequest && (
                  <Wrap>
                    <Documents
                      documents={responseLocation?.documents}
                      uuid={responseLocation.uuid}
                    />
                  </Wrap>
                )}

                {responseLocation?.created_at && isAdminRequest && (
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="created_at"
                        label="Data de criação"
                        defaultValue={format(
                          new Date(responseLocation?.created_at),
                          'dd/MM/yyyy HH:mm',
                        )}
                        disabled
                      />
                    </LimiterInputWidth>
                  </Wrap>
                )}
              </WhiteBox>

              <Chat
                uuid={responseLocation?.uuid}
                chatKeyFilter="locacao_reserva_vaga"
                locacaoNameView={responseLocation?.name_view}
              />

              {responseLocation?.valor_locacao && isAdminRequest && (
                <ValorLocacao
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.valor_locacao}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ModalidadePagamento
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.modalidade_pagamento}
                />
              )}

              {responseLocation && isAdminRequest && (
                <WorkStatusSwitch
                  data={{
                    entity: 'locacao-reserva-vaga',
                    uuid: responseLocation?.uuid,
                  }}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ObservacoesChat workUuid={responseLocation?.work_uuid} />
              )}

              {!dadoState.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}

              {Object.keys(responseLocation || {}).length > 0 && (
                <Link
                  to={{
                    pathname: 'redireciona-endpoint',
                    search: `query=${encodeURIComponent(
                      CryptoJS.AES.encrypt(
                        JSON.stringify({
                          is_admin: isAdminRequest,
                          uuid: responseLocation?.uuid,
                          endpoint: responseLocation?.endpoint,
                          mode: 'edit',
                          nome_projeto: responseLocation?.titulo_obra,
                        }),
                        tenancy_env('REACT_APP_API_BASE_URL'),
                      ).toString(),
                    )}`,
                  }}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    width: '100%',
                    maxWidth: '140px',
                    position: 'relative',
                    bottom: '35px',
                  }}
                >
                  <ActionButton
                    style={{
                      backgroundColor: '#828282',
                    }}
                  >
                    Editar
                  </ActionButton>
                </Link>
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Reservas de Vagas Filmagens Internas</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Reservas
                de Vagas Filmagens Internas
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
