/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useField } from '@unform/core';

import { Container, Label, Content } from './styles';

import Camera from '~/assets/images/icons/Camera.svg';
import { showModalMessage } from '~/services/notification';

export default function FilePerfil({
  name,
  inputRef,
  error,
  link,
  isDisabled,
  ...rest
}) {
  const { fieldName, defaultValue = '', registerField } = useField(name);
  const [preview, setPreview] = useState();

  const handlePreview = async e => {
    const file = e.target.files?.[0];

    if (!file) {
      setPreview(null);
      return;
    }

    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      await showModalMessage({
        type: 'error',
        text: 'Formato de Imagem não permitido!',
      });
      setPreview(null);
      return;
    }

    setPreview(URL.createObjectURL(file));
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
    setPreview(link);
  }, [fieldName, inputRef, link, registerField]);

  return (
    <Container>
      {rest?.label && (
        <Label disabled={isDisabled} htmlFor={rest.name}>
          {rest?.label}
        </Label>
      )}
      <Content>
        <div>{preview && <img src={preview} alt="Preview" width="100" />}</div>
        <label
          htmlFor={rest?.id}
          style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        >
          <img
            src={Camera}
            alt="Câmera"
            onClick={isDisabled ? null : () => inputRef.current.click()}
          />
        </label>
        <input
          type="file"
          id={fieldName}
          name={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          onChange={handlePreview}
          {...rest}
          accept=".png,.jpg,.jpeg"
          // webkitDirectory={inputRef.current?.value}
        />
      </Content>
      {error && <span className="error">{error}</span>}
    </Container>
  );
}
