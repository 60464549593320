/* eslint-disable no-underscore-dangle */
import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators } = createActions(
  {
    showRequest: ["data", "history"],
    showSuccess: ["data"],
    showFailure: ["error"],
  },
  { prefix: "USERS_ME_" }
);

export const UserMeTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
};

// show
export const _showRequest = (state) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

export const _showSuccess = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

export const _showFailure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_REQUEST]: _showRequest,
  [Types.SHOW_SUCCESS]: _showSuccess,
  [Types.SHOW_FAILURE]: _showFailure,
});
