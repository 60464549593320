/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { isEqual, orderBy } from 'lodash';
import omitEmpty from 'omit-empty';
import { useHistory, useLocation } from 'react-router';
import { format } from 'date-fns';
import { useAuth } from '~/hooks/auth';

import {
  PageContainer,
  LimiterInputWidth,
  GridContainer,
  Wrap,
  ApprovalContainer,
  TooltipContainer,
} from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import LogoIcon from '~/components/LogoIcon';
import { NavBarHeader } from '~/components/Usuario';
import Load from '~/components/Load';
import Button from '~/components/Button';
import ApiClient from '~/utils/ApiClient';
import { showModalMessage, showToastMessage } from '~/services/notification';
import BuscaPessoa from './components/BuscaPessoa';
import { Input, Select } from '~/components/Forms';
import { useList } from '~/hooks/useFunction';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import { ContatoResponsavel } from '../../SolicitacaoNovaObra/components/ContatoResponsavel';
import diffResponsaveis from './functions/diffResponsaveis';
import { clearMasks } from '~/utils/functions/clearMasks';
import clientResources from '~/data/clientResources';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  menuList: provided => ({
    ...provided,
    height: '125px',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const EditObra = () => {
  const { user: authUser } = useAuth();
  const hasAdminRole = authUser?.roles?.some(role => role === 'Administrador');

  const location = useLocation();
  const history = useHistory();

  const dadoState = location.state;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseObra, setResponseObra] = useState({});
  const [hasApprovedFieldsFormato, setHasApprovedFieldsFormato] =
    useState(false);

  const [hasApprovedFieldsVeiculacao, setHasApprovedFieldsVeiculacao] =
    useState(false);

  const [responsaveisComission, setResponsaveisComission] = useState([]);
  const [responsaveisDiretor, setResponsaveisDiretor] = useState([]);
  const [responsaveisCoordenacao, setResponsaveisCoordenacao] = useState([]);
  const [responsaveisProdExecutiva, setResponsaveisProdExecutiva] = useState(
    [],
  );

  // lists
  const workTypeSelectOptions = useList({
    url: 'work-type?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));
  const veiculacaoInicialList = useList({
    url: 'ld-veiculo-inicial?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisAbertosList = useList({
    url: 'ld-canais-aberto?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosFilmeList = useList({
    url: 'ld-canais-pagos-filme?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosInfantilList = useList({
    url: 'ld-canais-pagos-infantil?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosNoticiaList = useList({
    url: 'l-canais-pagos-noticia?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosDocumentarioList = useList({
    url: 'ld-canais-pagos-documentario?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosVariedadeList = useList({
    url: 'ld-canais-pagos-variedade?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const canaisPagosEsporteList = useList({
    url: 'ld-canais-pagos-esporte?paginate=false',
  })?.map(({ uuid, name }) => ({ value: uuid, label: name }));
  const streamingList = useList({ url: 'ld-streaming?paginate=false' })?.map(
    ({ uuid, name }) => ({ value: uuid, label: name }),
  );

  const fetchObraData = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `work/${dadoState?.data?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respObra = resp?.data?.data;

      formRef?.current?.setFieldValue('formato_obra_uuid', {
        label: respObra?.work_type?.value?.name,
        value: respObra?.work_type?.value?.uuid,
      });

      // render components veiculacao inicial
      formRef?.current?.setFieldValue(
        'ld_veiculacao_inicial',
        respObra.ld_veiculacao_inicial?.value?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_abertos',
        respObra?.ld_canais_abertos?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_filme',
        respObra?.ld_canais_filme?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_infantil',
        respObra?.ld_canais_infantil?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_noticia',
        respObra?.ld_canais_noticia?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_documentarios',
        respObra?.ld_canais_documentarios?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_variedade',
        respObra?.ld_canais_variedade?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_canais_esporte',
        respObra?.ld_canais_esporte?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );
      formRef?.current?.setFieldValue(
        'ld_streaming',
        respObra?.ld_streaming?.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
      );

      formRef?.current?.setFieldValue(
        'outro_veiculacao',
        respObra?.outro_veiculacao,
      );

      // verify if has fields to approve
      const hasChangesFormato = Object.keys(respObra?.work_type).concat(
        Object.keys(respObra?.visit_date),
        Object.keys(respObra?.visit_date_fim),
        Object.keys(respObra?.previsao_estreia),
      );

      const hasChangesVeiculacao = Object.keys(respObra?.ld_veiculacao_inicial);

      setHasApprovedFieldsFormato(
        hasChangesFormato.includes('changes_to_approve'),
      );
      setHasApprovedFieldsVeiculacao(
        hasChangesVeiculacao.includes('changes_to_approve'),
      );

      // responsaveis
      setResponsaveisComission(
        respObra?.work_contacts?.map(respc => ({
          uuid: respc.user.uuid,
          cpf: respc.user.user_personal_data.cpf,
          name: respc.user.user_personal_data.name,
          email: respc.user.email,
          phone_mobile: respc.user.user_personal_data.phone_mobile,
          rg: respc.user.user_personal_data.rg,
          birth_date: respc.user.user_birth_address.birth_date,
          send_email: String(respc.send_email),
        })),
      );

      setResponsaveisDiretor(
        respObra?.work_directors?.map(respc => ({
          uuid: respc.user.uuid,
          cpf: respc.user.user_personal_data.cpf,
          name: respc.user.user_personal_data.name,
          email: respc.user.email,
          phone_mobile: respc.user.user_personal_data.phone_mobile,
          rg: respc.user.user_personal_data.rg,
          birth_date: respc.user.user_birth_address.birth_date,
          send_email: String(respc.send_email),
        })),
      );

      setResponsaveisProdExecutiva(
        respObra?.work_executive_producer?.map(respc => ({
          uuid: respc.user.uuid,
          cpf: respc.user.user_personal_data.cpf,
          name: respc.user.user_personal_data.name,
          email: respc.user.email,
          phone_mobile: respc.user.user_personal_data.phone_mobile,
          rg: respc.user.user_personal_data.rg,
          birth_date: respc.user.user_birth_address.birth_date,
          send_email: String(respc.send_email),
        })),
      );

      setResponsaveisCoordenacao(
        respObra?.work_production_coordinator?.map(respc => ({
          uuid: respc.user.uuid,
          cpf: respc.user.user_personal_data.cpf,
          name: respc.user.user_personal_data.name,
          email: respc.user.email,
          phone_mobile: respc.user.user_personal_data.phone_mobile,
          rg: respc.user.user_personal_data.rg,
          birth_date: respc.user.user_birth_address.birth_date,
          send_email: String(respc.send_email),
        })),
      );

      setResponseObra(respObra);
    } catch (error) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao buscar Obra',
      });
    } finally {
      setLoading(false);
    }
  }, [dadoState?.data?.uuid]);

  useEffect(() => fetchObraData(), [fetchObraData]);

  const handleChange = (name, value) => {
    setFormData(state => ({ ...state, [name]: value }));
  };

  const diffItem = (itemA, itemB, key = '') => {
    if (Array.isArray(itemA) && Array.isArray(itemB) && key) {
      return isEqual(
        orderBy(itemA, [key], ['asc']),
        orderBy(itemB, [key], ['asc']),
      );
    }

    return isEqual(itemA, itemB);
  };

  const handleValidationFields = useCallback(async () => {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        ld_veiculacao_inicial: Yup.array()
          .typeError(defaultMsg)
          .test(
            'veiculacao-inicial-nao-selecionado',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      if (
        responsaveisComission.some(
          ({ uuid, send_email }) => uuid === '' || send_email === null,
        )
      ) {
        await showModalMessage({
          type: 'error',
          text: 'Preencha todos os campos do Responsável pelo contato junto a São Paulo',
        });
        return false;
      }

      if (
        responsaveisDiretor.some(
          ({ uuid, send_email }) => uuid === '' || send_email === null,
        )
      ) {
        await showModalMessage({
          type: 'error',
          text: 'Preencha todos os campos do Responsável pela Direção de Produção',
        });
        return false;
      }

      if (
        responsaveisCoordenacao.some(
          ({ uuid, send_email }) => uuid === '' || send_email === null,
        )
      ) {
        await showModalMessage({
          type: 'error',
          text: 'Preencha todos os campos do Responsável pela Coordenação de Produção',
        });
        return false;
      }

      if (
        responsaveisProdExecutiva.some(
          ({ uuid, send_email }) => uuid === '' || send_email === null,
        )
      ) {
        await showModalMessage({
          type: 'error',
          text: 'Preencha todos os campos do Responsável pela Produção Executiva',
        });
        return false;
      }

      return true;
    } catch (error) {
      const validationErrors = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });

      return false;
    }
  }, [
    formData,
    responsaveisComission,
    responsaveisCoordenacao,
    responsaveisDiretor,
    responsaveisProdExecutiva,
  ]);

  const handleSubmitFormato = useCallback(
    async approveStatus => {
      try {
        setLoading(true);

        const body = {
          approved: approveStatus,
          changes: omitEmpty([
            responseObra?.work_type?.changes_to_approve?.uuid,
            responseObra?.visit_date?.changes_to_approve?.uuid,
            responseObra?.visit_date_fim?.changes_to_approve?.uuid,
            responseObra?.previsao_estreia?.changes_to_approve?.uuid,
          ]),
        };

        await ApiClient.doRequest({
          uri: `changes-to-approve-work`,
          method: ApiClient.PATCH,
          expectedStatus: 201,
          body,
        });

        await showModalMessage({
          type: 'success',
          text: `Informações ${
            approveStatus === 1 ? 'aprovadas' : 'recusadas'
          } com sucesso`,
        });

        setHasApprovedFieldsFormato(false);

        if (approveStatus === 0) {
          window.location.reload();
        }
      } catch (error) {
        await showModalMessage({
          type: 'error',
          text: `Erro ao tentar ${
            approveStatus === 1 ? 'aprovar' : 'recusar'
          } novas informações de Obra`,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      responseObra?.previsao_estreia?.changes_to_approve,
      responseObra?.visit_date?.changes_to_approve,
      responseObra?.visit_date_fim?.changes_to_approve,
      responseObra?.work_type?.changes_to_approve,
    ],
  );

  const handleSubmitVeiculacao = useCallback(
    async approveStatus => {
      try {
        setLoading(true);

        const body = {
          approved: approveStatus,
        };

        await ApiClient.doRequest({
          uri: `changes-to-approve-veiculacao/${responseObra?.ld_veiculacao_inicial?.changes_to_approve?.uuid}`,
          method: ApiClient.PATCH,
          expectedStatus: 201,
          body,
        });

        await showModalMessage({
          type: 'success',
          text: `Informações ${
            approveStatus === 1 ? 'aprovadas' : 'recusadas'
          } com sucesso`,
        });

        setHasApprovedFieldsVeiculacao(false);

        if (approveStatus === 0) {
          window.location.reload();
        }
      } catch (error) {
        await showModalMessage({
          type: 'error',
          text: `Erro ao tentar ${
            approveStatus === 1 ? 'aprovar' : 'recusar'
          } novas informações de Obra`,
        });
      } finally {
        setLoading(false);
      }
    },
    [responseObra?.ld_veiculacao_inicial?.changes_to_approve?.uuid],
  );

  const handleSubmit = useCallback(
    async _ => {
      try {
        if (!(await handleValidationFields())) {
          return;
        }

        setLoading(true);

        const hasDiffWorkType = !diffItem(
          responseObra?.work_type?.value?.uuid,
          formData?.formato_obra_uuid,
        );

        const hasDiffVeiculacao = !diffItem(
          responseObra?.ld_veiculacao_inicial?.value?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_veiculacao_inicial?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const hasDiffTVAberta = !diffItem(
          responseObra?.ld_canais_abertos?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_canais_abertos?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const hasDiffStreaming = !diffItem(
          responseObra?.ld_streaming?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_streaming?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const hasDiffFilmes = !diffItem(
          responseObra?.ld_canais_filme?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_canais_filme?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const hasDiffInfantis = !diffItem(
          responseObra?.ld_canais_infantil?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_canais_infantil?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const hasDiffNoticias = !diffItem(
          responseObra?.ld_canais_noticia?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_canais_noticia?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const hasDiffDocumentarios = !diffItem(
          responseObra?.ld_canais_documentarios?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_canais_documentarios?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const hasDiffVariedades = !diffItem(
          responseObra?.ld_canais_variedade?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_canais_variedade?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const hasDiffEsportes = !diffItem(
          responseObra?.ld_canais_esporte?.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })),
          formData?.ld_canais_esporte?.map(({ label, value }) => ({
            label,
            value,
          })),
          'label',
        );

        const formatoFields = hasApprovedFieldsFormato
          ? {}
          : {
              work_type_uuid: hasDiffWorkType
                ? formData?.formato_obra_uuid
                : undefined,
              visit_date: formData?.visit_date,
              visit_date_fim: formData?.visit_date_fim,
              previsao_estreia: formData?.previsao_estreia,
            };

        const veiculacaoFields = {
          ld_veiculacao_inicial: hasDiffVeiculacao
            ? formData?.ld_veiculacao_inicial?.map(({ value }) => value) || []
            : [],

          ld_canais_abertos: hasDiffTVAberta
            ? formData?.ld_canais_abertos === null
              ? null
              : formData?.ld_canais_abertos?.map(({ value }) => value) || []
            : [],

          ld_canais_documentarios: hasDiffDocumentarios
            ? formData?.ld_canais_documentarios === null
              ? null
              : formData?.ld_canais_documentarios?.map(({ value }) => value) ||
                []
            : [],

          ld_canais_esporte: hasDiffEsportes
            ? formData?.ld_canais_esporte === null
              ? null
              : formData?.ld_canais_esporte?.map(({ value }) => value) || []
            : [],

          ld_canais_filme: hasDiffFilmes
            ? formData?.ld_canais_filme === null
              ? null
              : formData?.ld_canais_filme?.map(({ value }) => value) || []
            : [],

          ld_canais_infantil: hasDiffInfantis
            ? formData?.ld_canais_infantil === null
              ? null
              : formData?.ld_canais_infantil?.map(({ value }) => value) || []
            : [],

          ld_canais_variedade: hasDiffVariedades
            ? formData?.ld_canais_variedade === null
              ? null
              : formData?.ld_canais_variedade?.map(({ value }) => value) || []
            : [],

          ld_canais_noticia: hasDiffNoticias
            ? formData?.ld_canais_noticia === null
              ? null
              : formData?.ld_canais_noticia?.map(({ value }) => value) || []
            : [],

          ld_streaming: hasDiffStreaming
            ? formData?.ld_streaming === null
              ? null
              : formData?.ld_streaming?.map(({ value }) => value) || []
            : [],

          outro_veiculacao: formData?.outro_veiculacao,
        };

        // responsaveis
        const diffContatoResps = diffResponsaveis({
          responsaveisGravados: responseObra?.work_contacts?.map(resp => ({
            uuid: resp.user.uuid,
            cpf: resp.user.user_personal_data.cpf,
            send_email: String(resp.send_email),
          })),
          responsaveisInput: responsaveisComission.map(
            ({ uuid, cpf, send_email }) => ({
              uuid,
              cpf,
              send_email,
            }),
          ),
        });

        const diffDirecaoResps = diffResponsaveis({
          responsaveisGravados: responseObra?.work_directors?.map(resp => ({
            uuid: resp.user.uuid,
            cpf: resp.user.user_personal_data.cpf,
            send_email: String(resp.send_email),
          })),
          responsaveisInput: responsaveisDiretor.map(
            ({ uuid, cpf, send_email }) => ({
              uuid,
              cpf,
              send_email,
            }),
          ),
        });

        const diffPExecutivaResps = diffResponsaveis({
          responsaveisGravados: responseObra?.work_executive_producer?.map(
            resp => ({
              uuid: resp.user.uuid,
              cpf: resp.user.user_personal_data.cpf,
              send_email: String(resp.send_email),
            }),
          ),
          responsaveisInput: responsaveisProdExecutiva.map(
            ({ uuid, cpf, send_email }) => ({
              uuid,
              cpf,
              send_email,
            }),
          ),
        });

        const diffPCoordenacaoResps = diffResponsaveis({
          responsaveisGravados: responseObra?.work_production_coordinator?.map(
            resp => ({
              uuid: resp.user.uuid,
              cpf: resp.user.user_personal_data.cpf,
              send_email: String(resp.send_email),
            }),
          ),
          responsaveisInput: responsaveisCoordenacao.map(
            ({ uuid, cpf, send_email }) => ({
              uuid,
              cpf,
              send_email,
            }),
          ),
        });

        const body = Object.assign(
          omitEmpty({
            ...formatoFields,
          }),
          {
            contacts:
              diffContatoResps?.map(resp =>
                clearMasks(resp, ['cpf', 'rg', 'phone_mobile']),
              ) || {},
            production_director:
              diffDirecaoResps?.map(resp =>
                clearMasks(resp, ['cpf', 'rg', 'phone_mobile']),
              ) || {},
            executive_producer:
              diffPExecutivaResps?.map(resp =>
                clearMasks(resp, ['cpf', 'rg', 'phone_mobile']),
              ) || {},
            production_coordinator:
              diffPCoordenacaoResps?.map(resp =>
                clearMasks(resp, ['cpf', 'rg', 'phone_mobile']),
              ) || {},
          },
          {
            ...veiculacaoFields,
          },
        );

        if (hasAdminRole) {
          await ApiClient.doRequest({
            uri: `work/${dadoState?.data?.uuid}`,
            method: ApiClient.PATCH,
            expectedStatus: 201,
            body,
          });
        } else {
          await ApiClient.doRequest({
            uri: `work-common-user/${dadoState?.data?.uuid}`,
            method: ApiClient.PATCH,
            expectedStatus: 201,
            body,
          });
        }

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title: 'Obra',
          text: hasAdminRole
            ? 'Alteração enviada com sucesso'
            : 'As alterações foram enviadas para avaliação da SPFilm',
        });

        history.goBack();
      } catch (error) {
        setLoading(false);

        await showModalMessage({
          type: 'error',
          title: 'Obra',
          text: 'Erro ao tentar salvar mudanças de Obra',
        });
      }
    },
    [
      dadoState?.data?.uuid,
      formData?.formato_obra_uuid,
      formData?.ld_canais_abertos,
      formData?.ld_canais_documentarios,
      formData?.ld_canais_esporte,
      formData?.ld_canais_filme,
      formData?.ld_canais_infantil,
      formData?.ld_canais_noticia,
      formData?.ld_canais_variedade,
      formData?.ld_streaming,
      formData?.ld_veiculacao_inicial,
      formData?.outro_veiculacao,
      formData?.previsao_estreia,
      formData?.visit_date,
      formData?.visit_date_fim,
      handleValidationFields,
      hasAdminRole,
      hasApprovedFieldsFormato,
      responsaveisComission,
      responsaveisCoordenacao,
      responsaveisDiretor,
      responsaveisProdExecutiva,
      responseObra?.ld_canais_abertos,
      responseObra?.ld_canais_documentarios,
      responseObra?.ld_canais_esporte,
      responseObra?.ld_canais_filme,
      responseObra?.ld_canais_infantil,
      responseObra?.ld_canais_noticia,
      responseObra?.ld_canais_variedade,
      responseObra?.ld_streaming,
      responseObra?.ld_veiculacao_inicial?.value,
      responseObra?.work_contacts,
      responseObra?.work_directors,
      responseObra?.work_executive_producer,
      responseObra?.work_production_coordinator,
      responseObra?.work_type?.value?.uuid,
    ],
  );

  const renderForm = () => {
    if (!loading && Object.values(responseObra).length <= 0) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Obra encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        <Load inLoad={loading} />
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Informações gerais</span>
                </BoxHeader>

                <ApprovalContainer hasApprovalFields={hasApprovedFieldsFormato}>
                  {hasApprovedFieldsFormato && (
                    <BoxHeader>
                      <span className="boxText">
                        Alterações a serem analisadas pela SPFilm
                      </span>
                    </BoxHeader>
                  )}
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="formato_obra_uuid"
                        label="Formato da Obra Audiovisual"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={workTypeSelectOptions}
                        onChange={value =>
                          handleChange('formato_obra_uuid', value.value)
                        }
                        isDisabled={hasApprovedFieldsFormato}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <Wrap>
                    <LimiterInputWidth w100>
                      <TooltipContainer
                        value={
                          responseObra?.visit_date?.changes_to_approve
                            ? `Valor anterior: ${format(
                                new Date(
                                  responseObra?.visit_date?.changes_to_approve?.old_value,
                                ),
                                'dd/MM/yyyy',
                              )}`
                            : 'Não possui valor anterior'
                        }
                      >
                        <Input
                          type="date"
                          name="visit_date"
                          label="Data ou previsão de início das filmagens"
                          defaultValue={
                            responseObra?.visit_date?.value &&
                            new Date(responseObra?.visit_date?.value)
                              .toISOString()
                              .split('T')[0]
                          }
                          onKeyDown={e => e.target.blur()}
                          onChange={event =>
                            handleChange('visit_date', event.target.value)
                          }
                          disabled={hasApprovedFieldsFormato}
                        />
                      </TooltipContainer>
                    </LimiterInputWidth>

                    <LimiterInputWidth w100>
                      <TooltipContainer
                        value={
                          responseObra?.visit_date_fim?.changes_to_approve
                            ? `Valor anterior: ${format(
                                new Date(
                                  responseObra?.visit_date_fim?.changes_to_approve?.old_value,
                                ),
                                'dd/MM/yyyy',
                              )}`
                            : 'Não possui valor anterior'
                        }
                      >
                        <Input
                          type="date"
                          name="visit_date_fim"
                          label="Data ou previsão de fim das filmagens"
                          defaultValue={
                            responseObra?.visit_date_fim?.value &&
                            new Date(responseObra?.visit_date_fim?.value)
                              .toISOString()
                              .split('T')[0]
                          }
                          onKeyDown={e => e.target.blur()}
                          onChange={event =>
                            handleChange('visit_date_fim', event.target.value)
                          }
                          disabled={hasApprovedFieldsFormato}
                        />
                      </TooltipContainer>
                    </LimiterInputWidth>

                    <LimiterInputWidth w100>
                      <TooltipContainer
                        value={
                          responseObra?.previsao_estreia?.changes_to_approve
                            ? `Valor anterior: ${format(
                                new Date(
                                  responseObra?.previsao_estreia?.changes_to_approve?.old_value,
                                ),
                                'dd/MM/yyyy',
                              )}`
                            : 'Não possui valor anterior'
                        }
                      >
                        <Input
                          type="date"
                          questionTooltip="Neste campo deve ser informado o período previsto para estreia da obra."
                          name="previsao_estreia"
                          label="Previsão de estreia"
                          defaultValue={
                            responseObra?.previsao_estreia?.value &&
                            new Date(
                              `${responseObra?.previsao_estreia?.value} 00:00:00`,
                            )
                              .toISOString()
                              .split('T')[0]
                          }
                          onKeyDown={e => e.target.blur()}
                          onChange={event =>
                            handleChange('previsao_estreia', event.target.value)
                          }
                          disabled={hasApprovedFieldsFormato}
                        />
                      </TooltipContainer>
                    </LimiterInputWidth>
                  </Wrap>

                  {hasAdminRole && hasApprovedFieldsFormato && (
                    <Wrap>
                      <Button
                        type="button"
                        btType="#104810d9"
                        label="Aprovar"
                        full
                        width="140px"
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleSubmitFormato(1)}
                      />
                      <Button
                        type="button"
                        btType="#ff0000c9"
                        label="Recusar"
                        full
                        width="140px"
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleSubmitFormato(0)}
                        style={{
                          marginLeft: 5,
                        }}
                      />
                    </Wrap>
                  )}
                </ApprovalContainer>

                <ApprovalContainer
                  hasApprovalFields={hasApprovedFieldsVeiculacao}
                >
                  {hasApprovedFieldsVeiculacao && (
                    <BoxHeader>
                      <span className="boxText">
                        Alterações a serem analisadas pela SPFilm
                      </span>
                    </BoxHeader>
                  )}
                  <Wrap>
                    <GridContainer cols={2}>
                      <LimiterInputWidth w100>
                        <Select
                          name="ld_veiculacao_inicial"
                          label="Veiculação inicial prevista"
                          placeholder="Selecione"
                          questionTooltip="Neste campo deve ser informado o meio de exibição da obra (canal e/ou plataforma que o conteúdo será exibido ou comercializado). Os dados sobre veiculação prevista da obra são exclusivamente para uso interno da SPFilm."
                          style={selectCustomStyles}
                          required
                          options={veiculacaoInicialList}
                          isDisabled={hasApprovedFieldsVeiculacao}
                          onChange={e => {
                            handleChange('ld_veiculacao_inicial', e);

                            const listsVeicIni = [
                              {
                                label: 'TV Aberta',
                                key_name: 'ld_canais_abertos',
                              },
                              {
                                label: 'Plataforma de Streaming',
                                key_name: 'ld_streaming',
                              },
                              {
                                label: 'TV Paga',
                                key_name: 'ld_canais_filme',
                              },
                              {
                                label: 'TV Paga',
                                key_name: 'ld_canais_infantil',
                              },
                              {
                                label: 'TV Paga',
                                key_name: 'ld_canais_noticia',
                              },
                              {
                                label: 'TV Paga',
                                key_name: 'ld_canais_documentarios',
                              },
                              {
                                label: 'TV Paga',
                                key_name: 'ld_canais_variedade',
                              },
                              {
                                label: 'TV Paga',
                                key_name: 'ld_canais_esporte',
                              },
                              {
                                label: 'Outros',
                                key_name: 'outro_veiculacao',
                              },
                            ];

                            listsVeicIni.forEach((key, _) =>
                              setFormData(state => ({
                                ...state,
                                [key.key_name]: e?.some(
                                  ({ label }) => label === key.label,
                                )
                                  ? state[key.key_name]
                                  : null,
                              })),
                            );
                          }}
                          isMulti
                        />
                      </LimiterInputWidth>

                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'TV Aberta',
                      ) && (
                        <LimiterInputWidth w100>
                          <Select
                            name="ld_canais_abertos"
                            label="Televisão - Canais Abertos"
                            placeholder="Selecione"
                            style={selectCustomStyles}
                            options={canaisAbertosList}
                            onChange={e => handleChange('ld_canais_abertos', e)}
                            isMulti
                            isDisabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}

                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'TV Paga',
                      ) && (
                        <LimiterInputWidth w100>
                          <Select
                            name="ld_canais_filme"
                            label="Filmes/Séries"
                            placeholder="Selecione"
                            style={selectCustomStyles}
                            options={canaisPagosFilmeList}
                            onChange={e => handleChange('ld_canais_filme', e)}
                            isMulti
                            isDisabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}
                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'TV Paga',
                      ) && (
                        <LimiterInputWidth w100>
                          <Select
                            name="ld_canais_infantil"
                            label="Infantis"
                            placeholder="Selecione"
                            style={selectCustomStyles}
                            options={canaisPagosInfantilList}
                            onChange={e =>
                              handleChange('ld_canais_infantil', e)
                            }
                            isMulti
                            isDisabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}
                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'TV Paga',
                      ) && (
                        <LimiterInputWidth w100>
                          <Select
                            name="ld_canais_noticia"
                            label="Notícias"
                            placeholder="Selecione"
                            style={selectCustomStyles}
                            options={canaisPagosNoticiaList}
                            onChange={e => handleChange('ld_canais_noticia', e)}
                            isMulti
                            isDisabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}
                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'TV Paga',
                      ) && (
                        <LimiterInputWidth w100>
                          <Select
                            name="ld_canais_documentarios"
                            label="Documentários"
                            placeholder="Selecione"
                            style={selectCustomStyles}
                            options={canaisPagosDocumentarioList}
                            onChange={e =>
                              handleChange('ld_canais_documentarios', e)
                            }
                            isMulti
                            isDisabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}
                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'TV Paga',
                      ) && (
                        <LimiterInputWidth w100>
                          <Select
                            name="ld_canais_variedade"
                            label="Variedades"
                            placeholder="Selecione"
                            style={selectCustomStyles}
                            options={canaisPagosVariedadeList}
                            onChange={e =>
                              handleChange('ld_canais_variedade', e)
                            }
                            isMulti
                            isDisabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}
                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'TV Paga',
                      ) && (
                        <LimiterInputWidth w100>
                          <Select
                            name="ld_canais_esporte"
                            label="Esportes"
                            placeholder="Selecione"
                            style={selectCustomStyles}
                            options={canaisPagosEsporteList}
                            onChange={e => handleChange('ld_canais_esporte', e)}
                            isMulti
                            isDisabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}
                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'Plataforma de Streaming',
                      ) && (
                        <LimiterInputWidth w100>
                          <Select
                            name="ld_streaming"
                            label="Plataforma de Streaming"
                            placeholder="Selecione"
                            style={selectCustomStyles}
                            options={streamingList}
                            onChange={e => handleChange('ld_streaming', e)}
                            isMulti
                            isDisabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}
                      {formData?.ld_veiculacao_inicial?.some(
                        ({ label }) => label === 'Outros',
                      ) && (
                        <LimiterInputWidth w100>
                          <Input
                            name="outro_veiculacao"
                            label="Outros"
                            onChange={event =>
                              handleChange(
                                'outro_veiculacao',
                                event.target.value,
                              )
                            }
                            disabled={hasApprovedFieldsVeiculacao}
                          />
                        </LimiterInputWidth>
                      )}
                    </GridContainer>
                  </Wrap>

                  {hasAdminRole && hasApprovedFieldsVeiculacao && (
                    <Wrap>
                      <Button
                        type="button"
                        btType="#104810d9"
                        label="Aprovar"
                        full
                        width="140px"
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleSubmitVeiculacao(1)}
                      />
                      <Button
                        type="button"
                        btType="#ff0000c9"
                        label="Recusar"
                        full
                        width="140px"
                        loading={loading}
                        disabled={loading}
                        onClick={() => handleSubmitVeiculacao(0)}
                        style={{
                          marginLeft: 5,
                        }}
                      />
                    </Wrap>
                  )}
                </ApprovalContainer>

                <ContatoResponsavel
                  boxTitle={
                    clientResources.labels.solicitacaoNovaObra
                      .responsavelSpFilmContatoMulti
                  }
                  responsaveis={responsaveisComission}
                  setResponsaveis={setResponsaveisComission}
                  editMode
                  bottomSize={1}
                />

                <ContatoResponsavel
                  boxTitle="Dados do responsável pela Direção de Produção:"
                  responsaveis={responsaveisDiretor}
                  setResponsaveis={setResponsaveisDiretor}
                  editMode
                  bottomSize={1}
                />

                <ContatoResponsavel
                  boxTitle="Dados do responsável pela Coordenação de Produção:"
                  responsaveis={responsaveisCoordenacao}
                  setResponsaveis={setResponsaveisCoordenacao}
                  editMode
                  bottomSize={1}
                />

                <ContatoResponsavel
                  boxTitle="Dados do responsável pela Produção Executiva:"
                  responsaveis={responsaveisProdExecutiva}
                  setResponsaveis={setResponsaveisProdExecutiva}
                  editMode
                  bottomSize={1}
                />
              </WhiteBox>

              <Button
                type="submit"
                btType="#26A2B1"
                label="Enviar"
                full
                width="140px"
                loading={loading}
                disabled={loading}
                style={{ position: 'relative', bottom: '35px' }}
              />
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  };

  return (
    <>
      <Header>
        <LogoIcon />
        {!hasAdminRole && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>
              {responseObra?.nome_projeto
                ? `Edição de '${responseObra?.nome_projeto}'`
                : ''}
            </h1>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
};

export default EditObra;
