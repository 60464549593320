import React, { useContext } from 'react';
import { ThemeContext } from '~/styles/ThemeProvider';
import { Header as StyledHeader } from './styles';

const Header = ({ children }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <StyledHeader bgTheme={theme.horizontal_bar_color}>{children}</StyledHeader>
  );
};

export default Header;
