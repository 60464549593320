import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import Modal from 'react-modal';

import * as Yup from 'yup';

import { Form } from '@unform/web';
import {
  RespSelectContainer,
  ModalContent,
  overlayStyle,
  contentStyle,
  ModalButton,
} from './styles';

import ApiClient from '~/utils/ApiClient';

import { Select } from '~/components/Forms';
import { showModalMessage } from '~/services/notification';
import Button from '~/components/Button';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    width: '250px',
    padding: 0,
  }),
  valueContainer: provided => ({
    ...provided,
    color: '#828282',
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

function ModalStatusObra({
  obra,
  workStatusList,
  openModal,
  closeModal,
  loadWorks,
}) {
  const [formData, setFormData] = useState({});
  const [load, setLoad] = useState(false);

  const formRef = useRef();

  function handleSelectChange(name, option) {
    const value = option ? { uuid: option.uuid, label: option.label } : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoad(true);

      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        work_status_uuid: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });
    } catch (err) {
      setLoad(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return;
    }

    try {
      const result = await ApiClient.doRequest({
        uri: `work/${obra.uuid}`,
        method: ApiClient.PATCH,
        expectedStatus: 201,
        body: {
          work_status_uuid: formData.work_status_uuid.uuid,
          ld_veiculacao_inicial: [],
          ld_streaming: [],
          ld_canais_abertos: [],
          ld_canais_filme: [],
          ld_canais_infantil: [],
          ld_canais_noticia: [],
          ld_canais_documentarios: [],
          ld_canais_variedade: [],
          ld_canais_esporte: [],
        },
      });

      setTimeout(async function () {
        await showModalMessage({
          type: 'success',
          text: `Status de Obra trocada para ${formData.work_status_uuid.label}`,
        });
        loadWorks();
        setFormData({});
        setLoad(false);
        closeModal();
      }, 2000);
    } catch (err) {
      setLoad(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
    }
  }

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={openModal}
      style={{
        overlay: overlayStyle,
        content: {
          ...contentStyle,
          overflow: 'visible',
          height: '200px',
          width: '400px',
        },
      }}
    >
      <ModalButton onClick={closeModal}>X</ModalButton>
      <ModalContent>
        {formData && (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <RespSelectContainer>
              <Select
                name="work_status_uuid"
                label="Status"
                placeholder="Selecione"
                classError="error"
                required
                options={workStatusList}
                style={selectCustomStyles}
                onChange={value => {
                  handleSelectChange('work_status_uuid', value);
                }}
              />
              <div style={{ marginTop: 10 }}>
                <Button
                  type="submit"
                  btType="#26A2B1"
                  action={handleSubmit}
                  label="Salvar"
                  full
                  loading={load}
                  width="150px"
                />
              </div>
            </RespSelectContainer>
          </Form>
        )}
      </ModalContent>
    </Modal>
  );
}

export default withRouter(ModalStatusObra);
