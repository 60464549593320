import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import clientResources from '~/data/clientResources';
import { ThemeContext } from '~/styles/ThemeProvider';

const LogoIcon = ({ disabled, ...rest }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Link
      to={{ pathname: disabled ? null : '/escolha-acesso' }}
      style={{ pointerEvents: disabled ? 'none' : 'all' }}
    >
      <img
        src={
          theme.system_logo.url ||
          clientResources.images.system.spFilmComissionLogo
        }
        alt="Logo do Sistema"
        style={{ width: '100%', maxWidth: 200}}
        {...rest}
      />
    </Link>
  );
};

export default LogoIcon;
