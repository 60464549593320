import React from 'react';

import { AiFillExclamationCircle } from 'react-icons/ai';

import { PageContainer } from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';

import { NavBarHeader } from '~/components/Usuario';

import { Wrap } from './styles';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export default function Termo() {
  const user = {
    id: 10,
    name: ``,
    avatar: `https://i.pinimg.com/564x/ec/49/f2/ec49f2ab11ef3e48ffce105318309a48.jpg`,
  };

  return (
    <>
      <Header>
        <LogoIcon disabled />
        <NavBarHeader data={user} />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Termo comprobatório</h1>
            <span>
              Home | Cadastro de locação | Finalização de Cadastro | Termo
              comprobatório
            </span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      <PageContainer>
        <PageContent>
          <WhiteBox column jCenter aCenter marginBottom="30px">
            <Wrap style={{ justifyContent: 'space-between' }}>
              <span
                style={{
                  color: 'black',
                  fontSize: 16,
                  height: 180,
                  width: '95%',
                  overflowY: 'scroll',
                  scrollbarColor: '#f1f1f1',
                  scrollbarWidth: 10,
                  scrollBehavior: 'smooth',
                  textAlign: 'left',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse sed purus velit. Cras pretium sollicitudin
                consequat.
              </span>
              <span style={{ color: 'gray' }} tooltip="Finalize o cadastro">
                <AiFillExclamationCircle />
              </span>
            </Wrap>
            <Wrap style={{ justifyContent: 'left' }}>
              <input type="checkbox" id="agree" name="agree" />
              <label
                htmlFor="agree"
                style={{
                  color: 'black',
                  marginLeft: 10,
                  fontWeight: 'bold',
                }}
              >
                Li e concordo com os Temos & Condições
              </label>
            </Wrap>
          </WhiteBox>
        </PageContent>
      </PageContainer>

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
