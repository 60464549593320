export const getSubdomainName = _ => {
  const urlHost = window.location.host;
  const hostSplited = urlHost.split('.');

  if (hostSplited[0].startsWith('localhost')) {
    return undefined;
  }

  return hostSplited[0].replace('-', '_');
};

const getValueBySubDomain = value => {
  const subdomain = getSubdomainName();

  if (!subdomain) return value;

  return `${value}_${subdomain.toUpperCase()}`;
};

const tenancy_env = value => {
  const valueFormat = getValueBySubDomain(value);

  return process.env[valueFormat];
};

export default tenancy_env;
