import styled, { css } from 'styled-components';

export const Container = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 170px;
  position: relative;

  height: 32px;
  margin: 8px 0;
  /* padding-left: 26px; */
  background-color: transparent;
  color: ${({ btType, colorTheme }) =>
    colorTheme || (btType === 'disable' ? '#8DA1B5' : '#272829')};
  border-right: ${({ active }) =>
    active === 'disable' ? `none` : `3px solid #5E81F4`};

  svg {
    margin-right: 8px;
    color: ${({ btType, colorTheme }) =>
      colorTheme || (btType === 'disable' ? '#f2f2f2' : '#ffffff')};
  }

  img {
    margin-right: 8px;
  }

  ${({ counter }) =>
    (counter || counter > 0) &&
    css`
      &::after {
        content: '${counter}';

        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;

        bottom: 0px;
        left: -45px;
        width: 25px;
        height: 25px;
        padding: 5px;

        font-family: 'InterSemiBold';
        font-size: 14px;

        border-radius: 50%;
        background-color: #fff;
        box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px,
          rgb(9 30 66 / 13%) 0px 0px 1px 1px;
        color: ${({ active, colorTheme }) =>
          colorTheme || (active === 'disable' ? '#8DA1B5' : '#272829')};
      }
    `}
`;

export const BoxImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 26px;
  margin-right: 10px;
  position: relative;

  & > svg > * {
    stroke: ${({ colorTheme }) => colorTheme};
  }
`;

export const Text = styled.p`
  font-family: 'InterSemiBold';
  font-size: 16px;
  line-height: 20px;
  color: ${({ active, colorTheme }) =>
    colorTheme || (active === 'disable' ? '#8DA1B5' : '#272829')};
  transition: 400ms ease;
`;

export const NavItem = styled.div``;
export const DropdownMenu = styled.div`
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
`;
