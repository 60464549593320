/* eslint-disable no-case-declarations */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import CryptoJS from 'crypto-js';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  ChoiceButtonLocacao,
  Textarea,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import LocacaoDocs from '../../LocacaoDocs/index';

import {
  PageContainer,
  ConditionalContainer,
  TextareaNoForm,
  LimiterInputWidth,
  MultiInputContainer,
  ActionInputMulti,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';
import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import FinalidadeAcessoListActions from '~/store/ducks/finalidade-acesso-list';
import ImpactoListActions from '~/store/ducks/impacto-list';
import Documents from '~/components/Documents/Documents';
import Chat from '~/components/Chat/chat';
import { useList } from '~/hooks/useFunction';
import {
  ModalidadePagamento,
  ObservacoesChat,
  ValorLocacao,
} from '~/components/Admin';
import LogoIcon from '~/components/LogoIcon';
import WorkStatusSwitch from '~/components/Admin/WorkStatusSwitch';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import {
  getBrowserTransformDate,
  validDateArray,
} from '../../functions/date-hour-utils';
import tenancy_env from '~/utils/MultiTenancyUtil';
import { ActionButton } from '~/components/Forms/FormChoiceButton/styles';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function AcessoCalcadoes() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseLocation, setResponseLocation] = useState();
  const [errorsForm, setErrorsForm] = useState({});
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);

  const [associarLocacao, setAssociarLocacao] = useState(null);
  const [showDescrAltoImpactoLocalField, setShowDescrAltoImpactoLocalField] =
    useState(false);

  // states multi input
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states finalidade acesso options
  const [cargaDescargaInputs, setCargaDescargaInputs] = useState({});
  const [permGeradorInputs, setPermGeradorInputs] = useState([]);
  const [permVeicCenicoInputs, setPermVeicCenicoInputs] = useState([]);
  const [montEstrutInputs, setMontEstrutInputs] = useState([]);
  const [outrosInputs, setOutrosInputs] = useState({});

  // states choices
  const [reservasVagas, setReservasVagas] = useState(null);

  // selectors
  const acessoAssociacaoList = useList({
    url: `acesso-associacao/${dadoState?.uuid}?paginate=false`,
  });

  const { data: finalidadeAcessoSelectList } = useSelector(
    state => state.finalidadeAcessoList,
  );
  const { data: impactoSelectList } = useSelector(state => state.impactoList);

  // Busca dados da Reserva
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-acesso-calcadoes/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set choice buttons
      setAssociarLocacao(Object.values(respLoc?.associada || {}).length > 0);
      setReservasVagas(
        Object.values(respLoc?.locacao_via_externa || []).length > 0,
      );

      // set multi inputs values
      setDatasFilmagemInputs(
        respLoc?.dates?.map(({ date, changes_to_approve }) => ({
          data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
          changes_to_approve,
        })),
      );

      setShowDescrAltoImpactoLocalField(
        typeof respLoc?.descr_alto_impacto_local === 'string',
      );

      // set multi select finalidade acessos
      const ldFinalidadesAcessos = [];
      ldFinalidadesAcessos.push(respLoc?.carga_descarga?.ld_finalidade_acesso);
      ldFinalidadesAcessos.push(
        respLoc?.perm_gerador?.length > 0
          ? respLoc?.perm_gerador[0]?.ld_finalidade_acesso
          : undefined,
      );
      ldFinalidadesAcessos.push(
        respLoc?.perm_veiculo_cenico?.length > 0
          ? respLoc?.perm_veiculo_cenico[0]?.ld_finalidade_acesso
          : undefined,
      );
      ldFinalidadesAcessos.push(
        respLoc?.montagem_estrutura?.length > 0
          ? respLoc?.montagem_estrutura[0]?.ld_finalidade_acesso
          : undefined,
      );
      ldFinalidadesAcessos.push(respLoc?.outros?.ld_finalidade_acesso);

      respLoc.ld_finalidades_acesso = ldFinalidadesAcessos.filter(
        item => item !== undefined,
      );

      // set cards finalidade acesso
      if (respLoc?.carga_descarga) {
        setCargaDescargaInputs({
          placa: respLoc?.carga_descarga?.placa,
          modelo_veiculo: respLoc?.carga_descarga?.modelo_veiculo,
          finalidade_acesso_label:
            respLoc?.carga_descarga?.ld_finalidade_acesso?.name,
          finalidade_acesso_uuid:
            respLoc?.carga_descarga?.ld_finalidade_acesso?.uuid,
          horas: respLoc?.carga_descarga?.hour_acesso_calcadoes,
        });
      }

      if (respLoc?.perm_gerador) {
        setPermGeradorInputs(
          respLoc?.perm_gerador?.map(permGerador => ({
            placa: permGerador?.placa,
            modelo_veiculo: permGerador?.modelo_veiculo,
            finalidade_acesso_label: permGerador?.ld_finalidade_acesso?.name,
            finalidade_acesso_uuid: permGerador?.ld_finalidade_acesso?.uuid,
            horas: permGerador?.hour_acesso_calcadoes,
          })),
        );
      }

      if (respLoc?.perm_veiculo_cenico) {
        setPermVeicCenicoInputs(
          respLoc?.perm_veiculo_cenico?.map(permVeicCenico => ({
            placa: permVeicCenico?.placa,
            modelo_veiculo: permVeicCenico?.modelo_veiculo,
            finalidade_acesso_label: permVeicCenico?.ld_finalidade_acesso?.name,
            finalidade_acesso_uuid: permVeicCenico?.ld_finalidade_acesso?.uuid,
            horas: permVeicCenico?.hour_acesso_calcadoes,
          })),
        );
      }

      if (respLoc?.montagem_estrutura) {
        setMontEstrutInputs(
          respLoc?.montagem_estrutura?.map(montEstrut => ({
            placa: montEstrut?.placa,
            modelo_veiculo: montEstrut?.modelo_veiculo,
            descricao_estrutura: montEstrut?.descricao_estrutura,
            finalidade_acesso_label: montEstrut?.ld_finalidade_acesso?.name,
            finalidade_acesso_uuid: montEstrut?.ld_finalidade_acesso?.uuid,
          })),
        );
      }

      if (respLoc?.outros) {
        setOutrosInputs({
          outro_finalidade: respLoc?.outros?.outro_finalidade,
          finalidade_acesso_label: respLoc?.outros?.ld_finalidade_acesso?.name,
          finalidade_acesso_uuid: respLoc?.outros?.ld_finalidade_acesso?.uuid,
        });
      }

      // set select default values
      formRef.current?.setFieldValue('ld_impacto_uuid', {
        label: respLoc?.ld_impacto?.name,
        value: respLoc?.ld_impacto?.uuid,
      });

      formRef.current?.setFieldValue(
        'ld_finalidades_acesso',
        respLoc?.ld_finalidades_acesso?.map(ilfa => {
          return {
            label: ilfa?.name,
            value: ilfa?.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('acesso_associacao', {
        label: `${respLoc?.associada?.name_view} - ${respLoc?.associada?.protocol}`,
        value: {
          uuid: respLoc?.associada?.uuid,
          endpoint: respLoc?.associada?.endpoint,
        },
      });

      setResponseLocation(respLoc);
      setLoading(false);
    }
  }, [dadoState]);

  // effects
  useEffect(() => {
    dispatch(FinalidadeAcessoListActions.showRequest());
    dispatch(ImpactoListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  const handleMultiSelectFinalidades = useCallback(
    e => {
      setFormData(state => ({
        ...state,
        ld_finalidades_acesso: Array.isArray(e) ? e.map(x => x.value) : [],
      }));

      if (e) {
        const cargaDescargaSelected = e?.find(
          x => x.label === 'Carga e Descarga',
        );

        const permGeradorSelected = e?.find(
          x => x.label === 'Permanência de gerador',
        );

        const permVeicCenicoSelected = e?.find(
          x => x.label === 'Permanência de veículo cênico',
        );

        const montEstrutSelected = e?.find(
          x => x.label === 'Montagem de estruturas sobre veículo de apoio',
        );

        const outrosSelected = e?.find(x => x.label === 'Outros');

        if (
          cargaDescargaSelected &&
          Object.keys(cargaDescargaInputs).length === 0
        ) {
          setCargaDescargaInputs({
            placa: '',
            modelo_veiculo: '',
            finalidade_acesso_label: cargaDescargaSelected.label,
            finalidade_acesso_uuid: cargaDescargaSelected.value,
            horas: [{ inicio: '', fim: '' }],
          });
        } else if (!cargaDescargaSelected) {
          setCargaDescargaInputs({});
        }

        if (permGeradorSelected && permGeradorInputs.length === 0) {
          setPermGeradorInputs([
            {
              placa: '',
              modelo_veiculo: '',
              finalidade_acesso_label: permGeradorSelected.label,
              finalidade_acesso_uuid: permGeradorSelected.value,
              horas: [{ inicio: '', fim: '' }],
            },
          ]);
        } else if (!permGeradorSelected) {
          setPermGeradorInputs([]);
        }

        if (permVeicCenicoSelected && permVeicCenicoInputs.length === 0) {
          setPermVeicCenicoInputs([
            {
              placa: '',
              modelo_veiculo: '',
              finalidade_acesso_label: permVeicCenicoSelected.label,
              finalidade_acesso_uuid: permVeicCenicoSelected.value,
              horas: [{ inicio: '', fim: '' }],
            },
          ]);
        } else if (!permVeicCenicoSelected) {
          setPermVeicCenicoInputs([]);
        }

        if (montEstrutSelected && montEstrutInputs.length === 0) {
          setMontEstrutInputs([
            {
              placa: '',
              modelo_veiculo: '',
              descricao_estrutura: '',
              finalidade_acesso_label: montEstrutSelected.label,
              finalidade_acesso_uuid: montEstrutSelected.value,
            },
          ]);
        } else if (!montEstrutSelected) {
          setMontEstrutInputs([]);
        }

        if (outrosSelected && Object.keys(outrosInputs).length === 0) {
          setOutrosInputs({
            outro_finalidade: '',
            finalidade_acesso_label: outrosSelected.label,
            finalidade_acesso_uuid: outrosSelected.value,
          });
        } else if (!outrosSelected) {
          setOutrosInputs({});
        }
      } else {
        setPermVeicCenicoInputs([]);
        setPermGeradorInputs([]);
        setMontEstrutInputs([]);
        setCargaDescargaInputs({});
        setOutrosInputs({});
      }
    },
    [
      cargaDescargaInputs,
      permGeradorInputs,
      permVeicCenicoInputs,
      montEstrutInputs,
      outrosInputs,
    ],
  );

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        ld_impacto_uuid: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        acesso_associacao: Yup.string()
          .typeError(defaultMsg)
          .test(
            'locacao-associada-selecionado',
            defaultMsg,
            value =>
              !(associarLocacao === true && (value?.length <= 0 || !value)),
          ),
        address_calcadao: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        address_filmagem: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        ld_finalidades_acesso: Yup.lazy(val =>
          Array.isArray(val)
            ? Yup.array().of(
                Yup.string().typeError(defaultMsg).required(defaultMsg),
              )
            : Yup.string().typeError(defaultMsg).required(defaultMsg),
        ),
        descr_alto_impacto_local: Yup.string()
          .typeError(defaultMsg)
          .test(
            'alto-impacto-local-selecionado',
            defaultMsg,
            value =>
              !(
                showDescrAltoImpactoLocalField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (associarLocacao === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Associar Locação' não foi informada!",
        });
        setErrorsForm(state => ({
          ...state,
          associarLocacao: true,
        }));
        return false;
      }

      if (reservasVagas === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Reserva de Vagas' não foi informada!",
        });

        setErrorsForm(state => ({
          ...state,
          reservasVagas: true,
        }));
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  function formatEspecificaData(data) {
    const locacaoAssociada = acessoAssociacaoList?.find(
      ({ uuid }) => uuid === data?.acesso_associacao?.uuid,
    );

    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      ld_impacto_uuid: data?.ld_impacto_uuid,
      address_calcadao: data?.address_calcadao,
      address_filmagem: data?.address_filmagem,
      especificidades: data?.especificidades,
    });

    // dados de choices
    const dataAssociarLocacao = associarLocacao
      ? {
          locacao: {
            reference_uuid: locacaoAssociada?.uuid,
            name_view: locacaoAssociada?.name_view,
          },
        }
      : { locacao: null };

    // dados de campos exibidos condicionalmente
    const dataDescrAltoImpactoLocal = {
      descr_alto_impacto_local: showDescrAltoImpactoLocalField
        ? data?.descr_alto_impacto_local
        : null,
    };

    const datasFilmagem = datasFilmagemInputs
      .filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_filmagem));

    return Object.assign(
      dataNaoCondicional,
      dataAssociarLocacao,
      dataDescrAltoImpactoLocal,
      { dates: datasFilmagem },
      { carga_descarga: cargaDescargaInputs },
      { perm_veiculo_cenico: permVeicCenicoInputs },
      { perm_gerador: permGeradorInputs },
      { montagem_estrutura: montEstrutInputs },
      { outros: outrosInputs },
    );
  }

  async function handleSubmit(data) {
    data.ld_finalidades_acesso = formData?.ld_finalidades_acesso;

    if (!(await handleValidationFields(data))) {
      return;
    }

    const especificaData = formatEspecificaData(data);

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: 'locacao-acesso-calcadoes',
        method: ApiClient.POST,
        body: Object.assign(especificaData, { work_uuid: dadoState?.uuid }),
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Acesso ou Permanência de Veículos - Calçadões',
        text: 'Locação gravada com sucesso',
      });

      // conclusao
      const urlReservas = '/solicitacao-nova-locacao/reservas-vagas-vinculado';
      const hasReservas = dadoState?.redirections?.some(
        ({ url }) => url === urlReservas,
      );

      const redirections = [
        ...(dadoState?.redirections || []),
        { url: urlReservas, next: hasReservas || reservasVagas },
      ].filter(({ next }) => !!next);

      const [nextRedirect] = redirections;

      if (nextRedirect) {
        history.replace({
          pathname: nextRedirect.url,
          state: {
            uuid: dadoState?.uuid,
            redirections: redirections.filter(
              ({ url }) => url !== nextRedirect.url,
            ),
          },
        });
      } else {
        history.push('/home-user');
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      await showModalMessage({
        type: 'error',
        title: 'Acesso ou Permanência de Veículos - Calçadões',
        text: 'Ocorreu um erro ao tentar gravar. Verifique os dados.',
      });
      setLoading(false);
    }
  }

  // handle true/false
  function handleReservasVagasTrue(e) {
    e.preventDefault(e);
    setReservasVagas(true);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleReservasVagasFalse(e) {
    e.preventDefault(e);
    setReservasVagas(false);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleAssociarLocacaoTrue(e) {
    e.preventDefault();
    setAssociarLocacao(true);
    setErrorsForm(state => ({
      ...state,
      associarLocacao: false,
    }));
  }

  function handleAssociarLocacaoFalse(e) {
    e.preventDefault();
    setAssociarLocacao(false);
    setErrorsForm(state => ({
      ...state,
      associarLocacao: false,
    }));
  }

  const handleFinAcessoOptionFieldsAdd = ({
    index,
    multiInputType,
    finalidadeAcessoOption,
  }) => {
    if (multiInputType === 'hora_veiculo_carga') {
      const valuesHoras = [...cargaDescargaInputs.horas];
      valuesHoras.push({ inicio: '', fim: '' });
      setCargaDescargaInputs(prevState => ({
        ...prevState,
        horas: valuesHoras,
      }));
    } else if (multiInputType === 'hora_veiculo_gerador') {
      const values = [...permGeradorInputs];
      const valuesHoras = [...permGeradorInputs[index].horas];
      valuesHoras.push({ inicio: '', fim: '' });
      values[index].horas = valuesHoras;
      setPermGeradorInputs(values);
    } else if (multiInputType === 'hora_veiculo_veic_cenico') {
      const values = [...permVeicCenicoInputs];
      const valuesHoras = [...permVeicCenicoInputs[index].horas];
      valuesHoras.push({ inicio: '', fim: '' });
      values[index].horas = valuesHoras;
      setPermVeicCenicoInputs(values);
    } else if (multiInputType === 'perm_gerador') {
      setPermGeradorInputs(prevState => [
        ...prevState,
        {
          placa: '',
          modelo_veiculo: '',
          finalidade_acesso_label: finalidadeAcessoOption.label,
          finalidade_acesso_uuid: finalidadeAcessoOption.uuid,
          horas: [{ inicio: '', fim: '' }],
        },
      ]);
    } else if (multiInputType === 'perm_veic_cenico') {
      setPermVeicCenicoInputs(prevState => [
        ...prevState,
        {
          placa: '',
          modelo_veiculo: '',
          finalidade_acesso_label: finalidadeAcessoOption.label,
          finalidade_acesso_uuid: finalidadeAcessoOption.uuid,
          horas: [{ inicio: '', fim: '' }],
        },
      ]);
    } else if (multiInputType === 'mont_estrut') {
      setMontEstrutInputs(prevState => [
        ...prevState,
        {
          placa: '',
          modelo_veiculo: '',
          descricao_estrutura: '',
          finalidade_acesso_label: finalidadeAcessoOption.label,
          finalidade_acesso_uuid: finalidadeAcessoOption.uuid,
        },
      ]);
    }
  };

  const handleFinAcessoOptionFieldsRemove = ({
    index,
    horaIndex,
    multiInputType,
  }) => {
    if (multiInputType === 'hora_veiculo_carga') {
      const horas = cargaDescargaInputs.horas.filter(
        (_, idx) => idx !== horaIndex,
      );
      setCargaDescargaInputs(prevState => ({ ...prevState, horas }));
    } else if (multiInputType === 'hora_veiculo_gerador') {
      const values = [...permGeradorInputs];
      values[index].horas = values[index].horas.filter(
        (_, idx) => idx !== horaIndex,
      );
      setPermGeradorInputs(values);
    } else if (multiInputType === 'hora_veiculo_veic_cenico') {
      const values = [...permVeicCenicoInputs];
      values[index].horas = values[index].horas.filter(
        (_, idx) => idx !== horaIndex,
      );
      setPermVeicCenicoInputs(values);
    } else if (multiInputType === 'perm_gerador') {
      setPermGeradorInputs(permGeradorInputs.filter((_, idx) => idx !== index));
    } else if (multiInputType === 'perm_veic_cenico') {
      setPermVeicCenicoInputs(
        permVeicCenicoInputs.filter((_, idx) => idx !== index),
      );
    } else if (multiInputType === 'mont_estrut') {
      setMontEstrutInputs(montEstrutInputs.filter((_, idx) => idx !== index));
    }
  };

  const handleFinAcessoOptionFieldsChange = ({
    index,
    horaIndex,
    event,
    multiInputType,
  }) => {
    if (multiInputType === 'hora_veiculo_carga') {
      const horas = [...cargaDescargaInputs.horas];
      horas[horaIndex][event.target.name] = event.target.value;
      setCargaDescargaInputs(prevState => ({ ...prevState, horas }));
    } else if (multiInputType === 'hora_veiculo_gerador') {
      const values = [...permGeradorInputs];
      const valuesHoras = [...values[index].horas];
      valuesHoras[horaIndex][event.target.name] = event.target.value;
      values[index].horas = valuesHoras;
      setPermGeradorInputs(values);
    } else if (multiInputType === 'hora_veiculo_veic_cenico') {
      const values = [...permVeicCenicoInputs];
      const valuesHoras = [...values[index].horas];
      valuesHoras[horaIndex][event.target.name] = event.target.value;
      values[index].horas = valuesHoras;
      setPermVeicCenicoInputs(values);
    } else if (multiInputType === 'perm_veic_cenico') {
      const values = [...permVeicCenicoInputs];
      values[index][event.target.name] = event.target.value;
      setPermVeicCenicoInputs(values);
    } else if (multiInputType === 'perm_gerador') {
      const values = [...permGeradorInputs];
      values[index][event.target.name] = event.target.value;
      setPermGeradorInputs(values);
    } else if (multiInputType === 'mont_estrut') {
      const values = [...montEstrutInputs];
      values[index][event.target.name] = event.target.value;
      setMontEstrutInputs(values);
    }
  };

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                {!isAdminRequest && (
                  <>
                    <LocacaoDocs
                      excludes={[
                        'precosFilmagensEquipamentosPublicos',
                        'precosFilmagensVia',
                      ]}
                    />
                  </>
                )}

                <BoxHeader>
                  <span className="boxText">Informações gerais</span>
                </BoxHeader>
                <Wrap>
                  <Textarea
                    name="form_info"
                    rows="3"
                    value='O prazo da São Paulo Film Commission só passará a valer a partir do envio das informações
                    completas no preenchimento deste formulário. Para solicitações de filmagens externas em vias pedestrealizadas (calçadões) é necessário
                    preencher o formulário "Utilização de Vias para Filmagens ou Apoio a Filmagens".&#13;&#13;Não é necessário autorização para carga e descarga nos seguintes períodos:&#13;&#13;Das 20h00 de segunda, terça, quarta, quinta e sexta até 07h00 do dia seguinte, das 14h00 de sábado
                    até 07h00 de segunda-feira;&#13;Os veículos não podem ultrapassar 07 toneladas de PBT (peso bruto total) de peso máximo, sendo autorizado um veículo por vez.'
                    disabled
                  />
                </Wrap>

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={dadoState?.viewData && true}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_impacto_uuid"
                      label="Você classifica sua filmagem como sendo:"
                      placeholder="Selecione"
                      options={impactoSelectList}
                      onChange={value => {
                        handleSelectChange('ld_impacto_uuid', value);
                        setShowDescrAltoImpactoLocalField(
                          value.label === 'alto impacto no local',
                        );
                      }}
                      required
                      questionTooltip="Clique aqui para saber mais"
                      questionOnClick={() => setOpenModalPorteFilmagem(true)}
                      style={selectCustomStyles}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={showDescrAltoImpactoLocalField}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descr_alto_impacto_local"
                      label="Comente qual o desafio da sua produção para auxiliarmos em sua viabilização"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.descr_alto_impacto_local
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Deseja associa-lá com uma Locação cadastrada?"
                      onClickTrue={handleAssociarLocacaoTrue}
                      onClickFalse={handleAssociarLocacaoFalse}
                      selected={associarLocacao}
                      error={
                        errorsForm.associarLocacao === true && 'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                      required
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={associarLocacao}>
                  <LimiterInputWidth w50>
                    <Select
                      name="acesso_associacao"
                      label="Escolha a Locação a ser associada:"
                      placeholder="Selecione"
                      options={acessoAssociacaoList?.map(
                        ({ uuid, name_view, protocol, endpoint }) => ({
                          label: `${name_view} - ${protocol}`,
                          value: { uuid, endpoint },
                        }),
                      )}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange('acesso_associacao', value);
                      }}
                      required
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>

                  {Object.values(formData?.acesso_associacao || {}).length >
                    0 && (
                    <Wrap>
                      <FormChoiceButton
                        nomeProjeto={dadoState?.nome_projeto}
                        viewData={{
                          is_admin: isAdminRequest,
                          uuid: formData?.acesso_associacao?.uuid,
                          endpoint: formData?.acesso_associacao?.endpoint,
                        }}
                      />
                    </Wrap>
                  )}
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="address_calcadao"
                    label="Endereço do Calçadão"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData && responseLocation?.address_calcadao
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="text"
                    name="address_filmagem"
                    label="Endereço da Filmagem"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData && responseLocation?.address_filmagem
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <DateFormMultiInput
                  label="Data"
                  dates={datasFilmagemInputs}
                  setDates={setDatasFilmagemInputs}
                  setIsLoading={setLoading}
                  isViewMode={dadoState?.viewData}
                  dateKeyName="data_filmagem"
                  isAdminRequest={isAdminRequest}
                  isRequired
                />

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_finalidades_acesso"
                      label="Qual a finalidade de acesso ao calçadão?"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={finalidadeAcessoSelectList}
                      required
                      isMulti
                      onChange={handleMultiSelectFinalidades}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer
                  show={Object.keys(cargaDescargaInputs).length !== 0}
                >
                  <WhiteBox
                    column
                    marginBottom="30px"
                    style={{ position: 'unset' }}
                  >
                    <BoxHeader style={{ justifyContent: 'space-between' }}>
                      <span className="boxText">
                        {cargaDescargaInputs.finalidade_acesso_label}
                      </span>
                    </BoxHeader>
                    <Wrap>
                      <LimiterInputWidth w25>
                        <InputNoForm
                          type="text"
                          name="placa"
                          placeholder="Placa"
                          required={
                            Object.keys(cargaDescargaInputs).length !== 0
                          }
                          value={cargaDescargaInputs.placa}
                          onChange={e =>
                            setCargaDescargaInputs(prevState => ({
                              ...prevState,
                              placa: e.target.value,
                            }))
                          }
                          disabled={dadoState?.viewData && true}
                        />
                      </LimiterInputWidth>

                      <LimiterInputWidth w25>
                        <InputNoForm
                          type="text"
                          name="modelo_veiculo"
                          placeholder="Modelo do Veículo"
                          required={
                            Object.keys(cargaDescargaInputs).length !== 0
                          }
                          value={cargaDescargaInputs.modelo_veiculo}
                          onChange={e =>
                            setCargaDescargaInputs(prevState => ({
                              ...prevState,
                              modelo_veiculo: e.target.value,
                            }))
                          }
                          disabled={dadoState?.viewData && true}
                        />
                      </LimiterInputWidth>
                    </Wrap>

                    <MultiInputContainer>
                      <BoxHeader style={{ justifyContent: 'normal' }}>
                        <span className="boxText" style={{ color: '#828282' }}>
                          Horário
                        </span>
                        <span className="boxText" style={{ color: 'red' }}>
                          *
                        </span>
                        <button
                          type="button"
                          tooltip="Incluir horário de montagem/desmontagem"
                          style={{ marginLeft: 5 }}
                        >
                          <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
                        </button>
                      </BoxHeader>

                      {cargaDescargaInputs.horas?.map(
                        (horaField, horaIndex) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Wrap key={`${horaField}~${horaIndex}`}>
                            <LimiterInputWidth w25>
                              <InputNoForm
                                type="time"
                                name="inicio"
                                required
                                value={horaField.inicio}
                                onChange={event =>
                                  handleFinAcessoOptionFieldsChange({
                                    horaIndex,
                                    event,
                                    multiInputType: 'hora_veiculo_carga',
                                  })
                                }
                                disabled={dadoState?.viewData && true}
                              />
                            </LimiterInputWidth>

                            <LimiterInputWidth w25>
                              <InputNoForm
                                type="time"
                                name="fim"
                                required
                                value={horaField.fim}
                                onChange={event =>
                                  handleFinAcessoOptionFieldsChange({
                                    horaIndex,
                                    event,
                                    multiInputType: 'hora_veiculo_carga',
                                  })
                                }
                                disabled={dadoState?.viewData && true}
                              />
                            </LimiterInputWidth>

                            {!dadoState?.viewData && (
                              <ContainerMultiInputActions>
                                {cargaDescargaInputs.horas.length !== 1 && (
                                  <ActionInputMulti
                                    type="button"
                                    onClick={() =>
                                      handleFinAcessoOptionFieldsRemove({
                                        horaIndex,
                                        multiInputType: 'hora_veiculo_carga',
                                      })
                                    }
                                  >
                                    <AiOutlineMinus color="#AAA" size={24} />
                                  </ActionInputMulti>
                                )}
                                {cargaDescargaInputs.horas.length - 1 ===
                                  horaIndex && (
                                  <ActionInputMulti
                                    type="button"
                                    onClick={() =>
                                      handleFinAcessoOptionFieldsAdd({
                                        multiInputType: 'hora_veiculo_carga',
                                      })
                                    }
                                  >
                                    <AiOutlinePlus color="#AAA" size={24} />
                                  </ActionInputMulti>
                                )}
                              </ContainerMultiInputActions>
                            )}
                          </Wrap>
                        ),
                      )}
                    </MultiInputContainer>
                  </WhiteBox>
                </ConditionalContainer>

                {permGeradorInputs?.map((inputField, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Wrap key={`${inputField}~${index}`}>
                    <WhiteBox
                      column
                      marginBottom="30px"
                      style={{ position: 'unset' }}
                    >
                      <BoxHeader style={{ justifyContent: 'space-between' }}>
                        <span className="boxText">
                          {inputField.finalidade_acesso_label}
                        </span>
                        {!dadoState?.viewData && (
                          <ContainerMultiInputActions>
                            {permGeradorInputs.length !== 1 && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleFinAcessoOptionFieldsRemove({
                                    index,
                                    multiInputType: 'perm_gerador',
                                  })
                                }
                              >
                                <AiOutlineMinus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}

                            {permGeradorInputs.length - 1 === index && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleFinAcessoOptionFieldsAdd({
                                    multiInputType: 'perm_gerador',
                                    finalidadeAcessoOption: {
                                      uuid: inputField.finalidade_acesso_uuid,
                                      label: inputField.finalidade_acesso_label,
                                    },
                                  })
                                }
                              >
                                <AiOutlinePlus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}
                          </ContainerMultiInputActions>
                        )}
                      </BoxHeader>
                      <Wrap>
                        <LimiterInputWidth w25>
                          <InputNoForm
                            type="text"
                            name="placa"
                            placeholder="Placa"
                            required
                            value={inputField.placa}
                            onChange={event =>
                              handleFinAcessoOptionFieldsChange({
                                index,
                                event,
                                multiInputType: 'perm_gerador',
                              })
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>

                        <LimiterInputWidth w25>
                          <InputNoForm
                            type="text"
                            name="modelo_veiculo"
                            placeholder="Modelo do Veículo"
                            required
                            value={inputField.modelo_veiculo}
                            onChange={event =>
                              handleFinAcessoOptionFieldsChange({
                                index,
                                event,
                                multiInputType: 'perm_gerador',
                              })
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>
                      </Wrap>

                      <MultiInputContainer>
                        <BoxHeader style={{ justifyContent: 'normal' }}>
                          <span
                            className="boxText"
                            style={{ color: '#828282' }}
                          >
                            Horário
                          </span>
                          <span className="boxText" style={{ color: 'red' }}>
                            *
                          </span>
                          <button
                            type="button"
                            tooltip="Incluir horário de montagem/desmontagem"
                            style={{ marginLeft: 5 }}
                          >
                            <BsFillQuestionCircleFill
                              color="#4f4f4f"
                              size={16}
                            />
                          </button>
                        </BoxHeader>

                        {inputField.horas?.map((horaField, horaIndex) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Wrap key={`${horaField}~${horaIndex}`}>
                            <LimiterInputWidth w25>
                              <InputNoForm
                                type="time"
                                name="inicio"
                                required
                                value={horaField.inicio}
                                onChange={event =>
                                  handleFinAcessoOptionFieldsChange({
                                    index,
                                    horaIndex,
                                    event,
                                    multiInputType: 'hora_veiculo_gerador',
                                  })
                                }
                                disabled={dadoState?.viewData && true}
                              />
                            </LimiterInputWidth>

                            <LimiterInputWidth w25>
                              <InputNoForm
                                type="time"
                                name="fim"
                                required
                                value={horaField.fim}
                                onChange={event =>
                                  handleFinAcessoOptionFieldsChange({
                                    index,
                                    horaIndex,
                                    event,
                                    multiInputType: 'hora_veiculo_gerador',
                                  })
                                }
                                disabled={dadoState?.viewData && true}
                              />
                            </LimiterInputWidth>

                            {!dadoState?.viewData && (
                              <ContainerMultiInputActions>
                                {inputField.horas.length !== 1 && (
                                  <ActionInputMulti
                                    type="button"
                                    onClick={() =>
                                      handleFinAcessoOptionFieldsRemove({
                                        index,
                                        horaIndex,
                                        multiInputType: 'hora_veiculo_gerador',
                                      })
                                    }
                                  >
                                    <AiOutlineMinus color="#AAA" size={24} />
                                  </ActionInputMulti>
                                )}
                                {inputField.horas.length - 1 === horaIndex && (
                                  <ActionInputMulti
                                    type="button"
                                    onClick={() =>
                                      handleFinAcessoOptionFieldsAdd({
                                        index,
                                        multiInputType: 'hora_veiculo_gerador',
                                      })
                                    }
                                  >
                                    <AiOutlinePlus color="#AAA" size={24} />
                                  </ActionInputMulti>
                                )}
                              </ContainerMultiInputActions>
                            )}
                          </Wrap>
                        ))}
                      </MultiInputContainer>
                    </WhiteBox>
                  </Wrap>
                ))}

                {permVeicCenicoInputs?.map((inputField, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Wrap key={`${inputField}~${index}`}>
                    <WhiteBox
                      column
                      marginBottom="30px"
                      style={{ position: 'unset' }}
                    >
                      <BoxHeader style={{ justifyContent: 'space-between' }}>
                        <div>
                          <span className="boxText">
                            {inputField.finalidade_acesso_label}
                          </span>
                          <button
                            type="button"
                            tooltip="Caso a filmagem seja externa, é necessário preencher o formulário “Utilização de Vias para Filmagens ou Apoio a Filmagens”"
                            style={{ marginLeft: 5 }}
                          >
                            <BsFillQuestionCircleFill
                              color="#4f4f4f"
                              size={16}
                            />
                          </button>
                        </div>

                        {!dadoState?.viewData && (
                          <ContainerMultiInputActions>
                            {permVeicCenicoInputs.length !== 1 && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleFinAcessoOptionFieldsRemove({
                                    index,
                                    multiInputType: 'perm_veic_cenico',
                                  })
                                }
                              >
                                <AiOutlineMinus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}

                            {permVeicCenicoInputs.length - 1 === index && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleFinAcessoOptionFieldsAdd({
                                    multiInputType: 'perm_veic_cenico',
                                    finalidadeAcessoOption: {
                                      uuid: inputField.finalidade_acesso_uuid,
                                      label: inputField.finalidade_acesso_label,
                                    },
                                  })
                                }
                              >
                                <AiOutlinePlus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}
                          </ContainerMultiInputActions>
                        )}
                      </BoxHeader>
                      <Wrap>
                        <LimiterInputWidth w25>
                          <InputNoForm
                            type="text"
                            name="placa"
                            placeholder="Placa"
                            required
                            value={inputField.placa}
                            onChange={event =>
                              handleFinAcessoOptionFieldsChange({
                                index,
                                event,
                                multiInputType: 'perm_veic_cenico',
                              })
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>

                        <LimiterInputWidth w25>
                          <InputNoForm
                            type="text"
                            name="modelo_veiculo"
                            placeholder="Modelo do Veículo"
                            required
                            value={inputField.modelo_veiculo}
                            onChange={event =>
                              handleFinAcessoOptionFieldsChange({
                                index,
                                event,
                                multiInputType: 'perm_veic_cenico',
                              })
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>
                      </Wrap>

                      <MultiInputContainer>
                        <BoxHeader style={{ justifyContent: 'normal' }}>
                          <span
                            className="boxText"
                            style={{ color: '#828282' }}
                          >
                            Horário
                          </span>
                          <span className="boxText" style={{ color: 'red' }}>
                            *
                          </span>
                          <button
                            type="button"
                            tooltip="Incluir horário de montagem/desmontagem"
                            style={{ marginLeft: 5 }}
                          >
                            <BsFillQuestionCircleFill
                              color="#4f4f4f"
                              size={16}
                            />
                          </button>
                        </BoxHeader>

                        {inputField.horas?.map((horaField, horaIndex) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Wrap key={`${horaField}~${horaIndex}`}>
                            <LimiterInputWidth w25>
                              <InputNoForm
                                type="time"
                                name="inicio"
                                required
                                value={horaField.inicio}
                                onChange={event =>
                                  handleFinAcessoOptionFieldsChange({
                                    index,
                                    horaIndex,
                                    event,
                                    multiInputType: 'hora_veiculo_veic_cenico',
                                  })
                                }
                                disabled={dadoState?.viewData && true}
                              />
                            </LimiterInputWidth>

                            <LimiterInputWidth w25>
                              <InputNoForm
                                type="time"
                                name="fim"
                                required
                                value={horaField.fim}
                                onChange={event =>
                                  handleFinAcessoOptionFieldsChange({
                                    index,
                                    horaIndex,
                                    event,
                                    multiInputType: 'hora_veiculo_veic_cenico',
                                  })
                                }
                                disabled={dadoState?.viewData && true}
                              />
                            </LimiterInputWidth>

                            {!dadoState?.viewData && (
                              <ContainerMultiInputActions>
                                {inputField.horas.length !== 1 && (
                                  <ActionInputMulti
                                    type="button"
                                    onClick={() =>
                                      handleFinAcessoOptionFieldsRemove({
                                        index,
                                        horaIndex,
                                        multiInputType:
                                          'hora_veiculo_veic_cenico',
                                      })
                                    }
                                  >
                                    <AiOutlineMinus color="#AAA" size={24} />
                                  </ActionInputMulti>
                                )}
                                {inputField.horas.length - 1 === horaIndex && (
                                  <ActionInputMulti
                                    type="button"
                                    onClick={() =>
                                      handleFinAcessoOptionFieldsAdd({
                                        index,
                                        multiInputType:
                                          'hora_veiculo_veic_cenico',
                                      })
                                    }
                                  >
                                    <AiOutlinePlus color="#AAA" size={24} />
                                  </ActionInputMulti>
                                )}
                              </ContainerMultiInputActions>
                            )}
                          </Wrap>
                        ))}
                      </MultiInputContainer>
                    </WhiteBox>
                  </Wrap>
                ))}

                {montEstrutInputs?.map((inputField, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Wrap key={`${inputField}~${index}`}>
                    <WhiteBox
                      column
                      marginBottom="30px"
                      style={{ position: 'unset' }}
                    >
                      <BoxHeader style={{ justifyContent: 'space-between' }}>
                        <span className="boxText">
                          {inputField.finalidade_acesso_label}
                        </span>
                        {!dadoState?.viewData && (
                          <ContainerMultiInputActions>
                            {montEstrutInputs.length !== 1 && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleFinAcessoOptionFieldsRemove({
                                    index,
                                    multiInputType: 'mont_estrut',
                                  })
                                }
                              >
                                <AiOutlineMinus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}

                            {montEstrutInputs.length - 1 === index && (
                              <ActionInputMulti
                                type="button"
                                onClick={() =>
                                  handleFinAcessoOptionFieldsAdd({
                                    multiInputType: 'mont_estrut',
                                    finalidadeAcessoOption: {
                                      uuid: inputField.finalidade_acesso_uuid,
                                      label: inputField.finalidade_acesso_label,
                                    },
                                  })
                                }
                              >
                                <AiOutlinePlus color="#AAA" size={24} />
                              </ActionInputMulti>
                            )}
                          </ContainerMultiInputActions>
                        )}
                      </BoxHeader>
                      <Wrap>
                        <LimiterInputWidth w25>
                          <InputNoForm
                            type="text"
                            name="placa"
                            placeholder="Placa"
                            required
                            value={inputField.placa}
                            onChange={event =>
                              handleFinAcessoOptionFieldsChange({
                                index,
                                event,
                                multiInputType: 'mont_estrut',
                              })
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>

                        <LimiterInputWidth w25>
                          <InputNoForm
                            type="text"
                            name="modelo_veiculo"
                            placeholder="Modelo do Veículo"
                            required
                            value={inputField.modelo_veiculo}
                            onChange={event =>
                              handleFinAcessoOptionFieldsChange({
                                index,
                                event,
                                multiInputType: 'mont_estrut',
                              })
                            }
                            disabled={dadoState?.viewData && true}
                          />
                        </LimiterInputWidth>
                      </Wrap>

                      <Wrap>
                        <TextareaNoForm
                          type="text"
                          rows={3}
                          name="descricao_estrutura"
                          placeholder="Defina as estruturas"
                          required
                          value={inputField.descricao_estrutura}
                          onChange={event =>
                            handleFinAcessoOptionFieldsChange({
                              index,
                              event,
                              multiInputType: 'mont_estrut',
                            })
                          }
                          disabled={dadoState?.viewData && true}
                        />
                      </Wrap>
                    </WhiteBox>
                  </Wrap>
                ))}

                <ConditionalContainer
                  show={Object.keys(outrosInputs).length !== 0}
                >
                  <WhiteBox
                    column
                    marginBottom="30px"
                    style={{ position: 'unset' }}
                  >
                    <BoxHeader style={{ justifyContent: 'space-between' }}>
                      <span className="boxText">
                        {outrosInputs.finalidade_acesso_label}
                      </span>
                    </BoxHeader>
                    <Wrap>
                      <InputNoForm
                        type="text"
                        name="outro_finalidade"
                        placeholder="Outros"
                        required={Object.keys(outrosInputs).length !== 0}
                        value={outrosInputs.outro_finalidade}
                        onChange={e =>
                          setOutrosInputs(prevState => ({
                            ...prevState,
                            outro_finalidade: e.target.value,
                          }))
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </WhiteBox>
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label=" Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData && responseLocation?.especificidades
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && reservasVagas ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="reservas_vagas_locacao"
                          label="Reservas de Vagas"
                          placeholder="Selecione"
                          options={responseLocation?.locacao_via_externa?.map(
                            ({
                              uuid,
                              address,
                              number,
                              neighborhood,
                              endpoint,
                            }) => ({
                              label: `${address}, ${number}, ${neighborhood}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('reservas_vagas_locacao', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {formData?.reservas_vagas_locacao && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            viewData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.reservas_vagas_locacao?.uuid,
                              endpoint: 'reservas-vagas-vinculado',
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de reserva de vagas em via próxima?"
                      onClickTrue={handleReservasVagasTrue}
                      onClickFalse={handleReservasVagasFalse}
                      selected={reservasVagas}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.reservasVagas === true && 'Obrigatório'}
                    />
                  )}
                </Wrap>

                {responseLocation?.documents?.length > 0 && isAdminRequest && (
                  <Wrap>
                    <Documents
                      documents={responseLocation?.documents}
                      uuid={responseLocation.uuid}
                    />
                  </Wrap>
                )}

                {responseLocation?.created_at && isAdminRequest && (
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="created_at"
                        label="Data de criação"
                        defaultValue={format(
                          new Date(responseLocation?.created_at),
                          'dd/MM/yyyy HH:mm',
                        )}
                        disabled
                      />
                    </LimiterInputWidth>
                  </Wrap>
                )}
              </WhiteBox>

              <Chat
                uuid={responseLocation?.uuid}
                chatKeyFilter="locacao_acesso_calcadoes"
                locacaoNameView={responseLocation?.name_view}
              />

              {responseLocation?.valor_locacao && isAdminRequest && (
                <ValorLocacao
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.valor_locacao}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ModalidadePagamento
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.modalidade_pagamento}
                />
              )}

              {responseLocation && isAdminRequest && (
                <WorkStatusSwitch
                  data={{
                    entity: 'locacao-acesso-calcadoes',
                    uuid: responseLocation?.uuid,
                  }}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ObservacoesChat workUuid={responseLocation?.work_uuid} />
              )}

              {dadoState?.uuid && !dadoState.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  disabled={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}

              {Object.keys(responseLocation || {}).length > 0 && (
                <Link
                  to={{
                    pathname: 'redireciona-endpoint',
                    search: `query=${encodeURIComponent(
                      CryptoJS.AES.encrypt(
                        JSON.stringify({
                          is_admin: isAdminRequest,
                          uuid: responseLocation?.uuid,
                          endpoint: responseLocation?.endpoint,
                          mode: 'edit',
                          nome_projeto: responseLocation?.titulo_obra,
                        }),
                        tenancy_env('REACT_APP_API_BASE_URL'),
                      ).toString(),
                    )}`,
                  }}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    width: '100%',
                    maxWidth: '140px',
                    position: 'relative',
                    bottom: '35px',
                  }}
                >
                  <ActionButton
                    style={{
                      backgroundColor: '#828282',
                    }}
                  >
                    Editar
                  </ActionButton>
                </Link>
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Acesso ou Permanência de Veículos - Calçadões</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Acesso ou
                Permanência de Veículos - Calçadões
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
