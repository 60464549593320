import styled from 'styled-components';
import { WhiteBox } from '~/components/WhiteBox/styles';

export const MainLoading = styled.div `
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;

  .icon-spin {
    animation: iconSpin 1s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  h3 {
    font-family: 'InterBold', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 12px;
  }
`;

export const HeaderContent = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CenterHeaderIcons = styled.div `
display: flex;
flex-direction: row;
flex-wrap: nowrap;
align-content: center;
justify-content: center;
align-items: center;
`;

export const LogoHeader = styled.div `
  width: 30%;
  img {
    width: 260px;
    height: 90px;
  }
`;

export const ButtonBox = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 10px;
    }
  }
`;
export const Wrap = styled.div `
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const LimiterInputWidth = styled.div `
  display: flex;
  margin-right: 16px;
  ${props => (props.w25 ? 'width: 24%;' : '')}
  ${props => (props.w50 ? 'width: 49%;' : '')}

  &:nth-child(4) {
    margin-right: 0;
  }
`;

export const InputFileContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const OtherText = styled.h3 `
  margin-right: 12px;
  margin-top: 40px;
  color: #828282;
`;

export const InputFile = styled.div `
  width: 50%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 5px;
  &:first-child {
    margin-right: 13px;
  }

  span {
    color: #828282;
    font-size: 16px;
  }
`;

export const ButtonContainer = styled.div `
  width: 100%;
  display: flex;
`;

export const ModalContent = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  h1 {
    font-family: 'InterRegular', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #000000;
    text-align: center;

    span {
      font-family: 'InterBold', sans-serif;
    }
  }
`;

export const ModalButton = styled.button `
  display: flex;
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  color: #99a6b7;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const overlayStyle = {
    zIndex: 5,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 40,
    borderRadius: 10,
    top: 0,
    left: 0,
    minHeight: 150,
    width: '90%',
    height: 'auto',
    maxWidth: 600,
    border: 'none',
};

export const ChoiceButtons = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
`;

export const ActionButton = styled.a `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'RobotoBold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  background-color: #4f4f4f;
  ${props => props.blue && 'background-color: #26A2B1'};
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  width: 100%;
  max-width: 160px;
  padding: 16px 0;
  border-radius: 50px;

  &:first-child {
    margin-right: 20px;
  }
`;

export const BannerBackground = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: linear-gradient(90deg, #323232 14.34%, rgba(50, 50, 50, 0) 88.3%);
  opacity: 0.9;
`;

export const BannerContent = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1100px;
`;

export const TextBanner = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 1100px;

  h1 {
    font-family: InterBold;
    font-size: 30px;
    line-height: 36px;
    color: #fff;
    margin-bottom: 10px;
  }

  span {
    font-family: InterRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    color: #e0e0e0;
  }
`;

export const BoxHeader = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .boxText {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #26a2b1;
  }

  span {
    color: #828282;
    font-size: 24px;
  }
`;

export const PageContent = styled.div `
  width: 100%;
  max-width: 1100px;
  height: auto;
  margin: 0 auto;
  ${WhiteBox} {
    position: relative;
    bottom: 62px;
  }
`;

export const PageFooter = styled.div `
  width: 100%;
  margin-top: auto;
  background: #f2f2f2;
  padding: 10px;
`;

export const WrapButtons = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 80%;
  height: 100%;

  button.defaultButton {
    margin-top: 25px;
    margin-left: 5px;
  }
`;

export const BuscaContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 96%;

  padding: 25px;
  border-radius: 5px;

  background: #3f3f3f;
`;

export const ProfissionalListContainer = styled.div `
  display: flex;
  flex-direction: column;

  padding: 15px;
  margin-top: 30px;

  width: 100%;
  height: 500px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6f6f6f;
  }
  &::-webkit-scrollbar-track {
    background-color: #f2f2f2;
  }
`;

export const CardProfissionalList = styled.div `
  display: flex;

  border-radius: 10px;
  box-shadow: 1px 2px 5px #aaaaaa;

  margin-bottom: 30px;

  height: 130px;
  width: 100%;
`;

export const ItemProfissionalList = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-radius: ${({ bRadius }) => bRadius || '0'};

  width: ${({ width }) => width || '20%'};
  height: 100%;

  padding: 30px 15px;

  background: ${({ bgColor }) => bgColor || '#ffffff'};

  & > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  & > p {
    font-family: 'InterBold', sans-serif;
    font-size: 14px;
    color: #828282;
    width: 100%;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      font-family: 'InterRegular', sans-serif;
      font-size: 14px;
      color: #828282;
    }
  }
`;