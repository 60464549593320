import styled from 'styled-components';

export const ResponsavelButton = styled.button`
  display: flex;

  background: #4f4f4f;
  border-radius: 10px;
  height: 35px;
  align-items: center;
  justify-content: center;

  padding: 0 10px !important;

  &:hover {
    transform: scale(1.1);
  }

  span {
    color: #fff;
    font-family: 'PoppinsBold', sans-serif;
    font-size: 14px;
    margin-right: 5px;
  }
`;

export const FlagIcon = styled.span`
  cursor: pointer;
  font-size: 24px;
  color: ${props => (props.isMarked ? '#26A2B1' : '#4f4f4f')};

  &:hover {
    & svg {
      color: ${props => (props.isMarked ? '#4f4f4f' : '#26A2B1')};
    }
  }
`;

export const ActionsContainer = styled.div`
  visibility: hidden;
  display: none;
  background-color: #fff;

  box-shadow: -1px 1.6px 9px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  right: 0;
  bottom: 25px;
`;

export const ActionsIcon = styled.span`
  cursor: pointer;
  position: relative;

  &:hover {
    & > ${ActionsContainer} {
      visibility: visible;
      display: block;
    }
  }
`;
