import styled from 'styled-components';

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 10px;
    }
  }
`;
export const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const LimiterInputWidth = styled.div`
  display: flex;
  margin-right: 16px;
  ${props => (props.w25 ? 'width: 24%;' : '')}
  ${props => (props.w50 ? 'width: 49%;' : '')}

  &:nth-child(4) {
    margin-right: 0;
  }
`;

export const InputFileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InputFile = styled.div`
  width: 50%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 5px;
  &:first-child {
    margin-right: 13px;
  }

  span {
    color: #828282;
    font-size: 16px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  h1 {
    font-family: 'InterRegular', sans-serif;
    font-size: 32px;
    line-height: 36px;
    color: #000000;
    text-align: center;

    span {
      font-family: 'InterBold', sans-serif;
    }
  }
`;

export const ModalButton = styled.button`
  display: flex;
  font-family: 'InterSemiBold', sans-serif;
  font-size: 16px;
  color: #99a6b7;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const overlayStyle = {
  zIndex: 5,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
};

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: 40,
  borderRadius: 10,
  top: 0,
  left: 0,
  minHeight: 150,
  width: '90%',
  height: 'auto',
  maxWidth: 600,
  border: 'none',
};

export const ChoiceButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 60px;

  a:last-child {
    margin-left: 20px;
  }
`;

export const ActionButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'RobotoBold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  background-color: #4f4f4f;
  ${props => props.blue && 'background-color: #26A2B1'};
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  width: 100%;
  max-width: 160px;
  padding: 16px 0;
  border-radius: 50px;

  &:first-child {
    margin-right: 20px;
  }
`;
