import styled from 'styled-components';

export const Container = styled.div`
  visibility: hidden;
  display: none;
  background-color: #fff;

  box-shadow: -1px 1.6px 9px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  left: 0px;
  top: 30px;
  z-index: 1182329137921;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #893d93;
  border-radius: 10px;
  padding: 10px;

  height: 200px;
  width: 200px;

  & > span > * {
    color: #fff;
    font-family: 'InterSemiBold', sans-serif;
    font-size: 14px;
  }
`;

export const Icon = styled.button`
  cursor: pointer;
  position: relative;
  margin-left: 5px;

  &:hover {
    & > ${Container} {
      visibility: visible;
      display: block;
    }
  }
`;
