import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './styles.css';

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasConsent = Cookies.get('cookieConsent');
    setShowBanner(!hasConsent);
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setShowBanner(false);
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'false', { expires: 365 });
    setShowBanner(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalKeyDown = event => {
    if (event.key === 'Escape') {
      handleModalClose();
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleModalKeyDown);
    return () => {
      document.removeEventListener('keydown', handleModalKeyDown);
    };
  }, []);

  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-consent-banner">
      <p id="banner-text">
        Este site utiliza cookies para melhorar sua experiência de navegação. Os
        cookies são pequenos arquivos de texto que são armazenados no seu
        dispositivo quando você visita um site. Eles são amplamente utilizados
        para tornar os sites mais eficientes, personalizados e seguros.
      </p>
      <p>
        Ao aceitar você está concordando com o uso de cookies descrito nesta política.
        <button
          type="button"
          className="link-button"
          onClick={handleModalOpen}
          aria-label="Abrir modal com mais informações"
        >
          <span className="link-text">Clique aqui para saber mais</span>
        </button>
      </p>
      <button className="button-accept" type="button" onClick={handleAccept}>
        Aceitar
      </button>
      <button className="button-decline" type="button" onClick={handleDecline}>
        Recusar
      </button>
      {showModal && (
        <div className="cookie-modal">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              onClick={handleModalClose}
              aria-label="Fechar modal"
            >
              &times;
            </button>
            <h2 style={{color: 'black', textAlign: 'center', marginBottom: '1em' }}>
              Diretrizes de Utilização de Cookies
            </h2>
            <p style={{ textAlign: 'left', marginBottom: '1em' }}>
              <strong>1- Tipos de Cookies Utilizados</strong>
            </p>
            <ul
              style={{ color: 'black', textAlign: 'left', marginBottom: '1em' }}
            >
              <li style={{ marginBottom: '1em' }}>
                <strong>a) Cookies Necessários:</strong> Esses cookies são
                essenciais para o funcionamento básico do site. Eles permitem
                que você navegue pelo site e utilize seus recursos, como acessar
                áreas seguras e preencher formulários. Sem esses cookies, o site
                não funcionaria corretamente.
              </li>
              <li style={{ marginBottom: '1em' }}>
                <strong>b) Cookies de Desempenho:</strong> Esses cookies coletam
                informações sobre como os visitantes utilizam nosso site, como
                as páginas mais visitadas, o tempo gasto nelas e possíveis
                mensagens de erro. Esses dados nos ajudam a melhorar a
                funcionalidade do site e aprimorar sua experiência de navegação.
              </li>
              <li style={{ marginBottom: '1em' }}>
                <strong>c) Cookies de Funcionalidade:</strong> Esses cookies
                permitem que o site se lembre das escolhas que você faz (como
                seu nome de usuário, idioma ou região) e forneçam recursos
                personalizados. Eles também podem ser usados para fornecer
                serviços que você solicitou, como assistir a um vídeo ou
                comentar em um blog.
              </li>
              <li>
                <strong>d) Cookies de Publicidade:</strong> Esses cookies são
                utilizados para exibir anúncios relevantes para você e seus
                interesses. Eles também são usados para limitar o número de
                vezes que você vê um anúncio e ajudam a medir a eficácia das
                campanhas publicitárias.
              </li>
            </ul>
            <p style={{ textAlign: 'left', marginBottom: '1em' }}>
              <strong>2- Controle de Cookies</strong>
            </p>
            <p style={{ textAlign: 'left', marginBottom: '1em' }}>
              Ao visitar nosso site, você concorda com o uso de cookies de
              acordo com esta política de cookies. No entanto, você tem o
              controle total sobre o uso de cookies. Você pode ajustar as
              configurações do seu navegador para recusar todos ou alguns tipos
              de cookies. No entanto, observe que a desativação de certos
              cookies pode afetar a funcionalidade do site e limitar sua
              experiência de navegação.
            </p>
            <p style={{ textAlign: 'left', marginBottom: '1em' }}>
              <strong>3- Privacidade e Proteção de Dados</strong>
            </p>
            <p style={{ textAlign: 'left'}}>
              Valorizamos sua privacidade e protegemos seus dados pessoais de
              acordo com as leis de proteção de dados aplicáveis. Para obter
              mais informações sobre como coletamos, usamos e protegemos seus
              dados pessoais.
            </p>
            <p style={{ textAlign: 'left'}}>
              Este aviso de cookies pode ser atualizado de tempos em tempos.
              Recomendamos que você reveja esta página periodicamente para se
              manter informado sobre quaisquer alterações.
            </p>
            <p style={{ textAlign: 'left', marginBottom: '1em' }}>
              Se você tiver alguma dúvida ou preocupação sobre o uso de cookies
              em nosso site, entre em contato conosco.
            </p>
            <p style={{ textAlign: 'left' }}><strong>Ilhabela Film Commission</strong></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieConsentBanner;
