import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #828282;
    margin-bottom: 8px;
  }
`;

export const ActionButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'RobotoBold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  background-color: #26a2b1;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  width: 100%;
  max-width: 160px;
  padding: 16px 0;
  border-radius: 50px;
  cursor: pointer;

  &:first-child {
    margin-right: 20px;
  }
`;
