import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '../../services/notification';

import api, { makeOptions } from '../../services/api';
import LogsActions from '../ducks/logs';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));
// const getAuthData = (state) => state.auth.data;

export function* fetch(action) {
  try {
    // const authData = yield select(getAuthData);
    const { params = {} } = action;
    const page = params.pagina !== undefined ? `&page=${params.pagina}` : '';
    const name =
      params.name !== undefined
        ? `&filters[][user.user_personal_data.name@equal]=${params.name}`
        : '';
    const date =
      params.date !== undefined
        ? `&filters[][user.user_personal_data.created_at@equal]=${params.date}`
        : '';

    const url = `/login-log?${date}${name}${page}`;
    const { data: responseData } = yield call(
      api.get,
      url,
      makeOptions(dataLocal.auth.data),
    );
    const { data, ...pagination } = responseData;
    yield put(LogsActions.success(data, pagination));
  } catch (err) {
    yield put(LogsActions.failure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}
