// @ts-nocheck
import React, { createContext, useCallback, useContext, useState } from 'react';

const CurrentUserContext = createContext({});

export const CurrentUserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const getUser = localStorage.getItem('@Spcine:currentUser');

    if(getUser) {
      return JSON.parse(getUser);
    }

    return {};
  });

  const addUser = useCallback((object) => {
    localStorage.setItem('@Spcine:currentUser', JSON.stringify(object));

    setUser(object);
  }, []);

  return (
    <CurrentUserContext.Provider value={{ addUser, user }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);

  if(!context) {
    throw new Error('addUser só pode ser usado com o CurrentUserProvider');
  }

  return context;
}
