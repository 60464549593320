/* eslint-disable no-unused-vars */
import { call, put } from 'redux-saga/effects';
import { showToastMessage } from '~/services/notification';

import api from '~/services/api';
import RegisterActions from '~/store/ducks/register';

export function* register(action) {
  try {
    const { data: post } = action;
    const { data } = yield call(api.post, `/user`, post);
    yield put(RegisterActions.registerSuccess(data));
    showToastMessage({
      type: 'success',
      text: 'Cadastro feito com sucesso',
    });
  } catch (err) {
    const result = yield put(
      RegisterActions.registerFailure(err.response.data.error.message),
    );
    showToastMessage({
      type: 'error',
      text: 'Error ao cadastrar usuário',
    });
  }
}
