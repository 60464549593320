/* eslint-disable consistent-return */
import { Form } from '@unform/web';
import { format, parse } from 'date-fns';
import omitEmpty from 'omit-empty';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import * as Yup from 'yup';
import Button from '~/components/Button';
import { CheckboxHtml, Input, Select } from '~/components/Forms';
import { AccordionItem } from '~/components/Forms/AccordionItem';
import MultiDateInput from '~/components/Forms/MultiDateInput';
import { Text } from '~/components/Forms/Text';
import { VirtualTable } from '~/components/Forms/VirtualTable';
import { showModalMessage } from '~/services/notification';
import {
  GridContainer,
  LimiterInputWidth,
  Line,
  Wrap,
} from '~/styles/components';
import ApiClient from '~/utils/ApiClient';
import tipoLocacaoList from '~/data/tiposDeLocacao';
import { useList } from '~/hooks/useFunction';
import { RadioNoForm } from '~/components/Forms/RadioNoForm';
import { SplitButton } from '~/components/SplitButton';
import { BoxHeader } from '~/pages/Usuario/styles';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const uri = 'report-locacao';

// const valorLocacaoSelectList = [
//   { value: 'both', label: 'Todos os casos' },
//   { value: null, label: 'Locações não pagas' },
//   { value: true, label: 'Apenas locações pagas' },
// ];

export const LocacoesGeraisForm = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [equipamentosEsportivosList, setEquipamentosEsportivosList] = useState(
    [],
  );
  const [espacosSelectList, setEspacosSelectList] = useState([]);
  const [subEspacosSMCSelectList, setSubEspacosSMCSelectList] = useState([]);

  const handleValidationFields = useCallback(async () => {
    try {
      formRef.current.setErrors({});

      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        datas_cadastro: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'datas-cadastro-nao-informadas',
            defaultMsg,
            value => !(value?.length <= 0 || !value),
          ),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });

      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }, [formData]);

  const bodyBuilder = useCallback(async () => {
    if (!(await handleValidationFields())) return null;

    try {
      // format dates
      const datasCadastro = formData?.datas_cadastro?.map(date =>
        format(parse(date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      );

      // espacos rules
      const espacos = {
        [formData?.tipo_locacao?.espacoName]:
          formData?.tipo_locacao?.value === 'Locação Espaços Cultura'
            ? formData?.espaco_select?.value
            : formData?.espaco_multi_select?.map(({ value }) => value),
      };

      const subEspacoSMC =
        Object.values(formData?.tipo_locacao?.subEspaco || {}).length > 0
          ? {
              [formData?.tipo_locacao?.subEspaco[formData?.espaco_select?.label]
                ?.subEspacoName]: formData?.ld_sub_espaco_smc?.map(
                ({ value }) => value,
              ),
            }
          : {};

      const geradorRadioValues = {
        0: 'zero',
        1: 'one',
      };

      const body = omitEmpty({
        start_date: datasCadastro[0],
        end_date:
          datasCadastro.length === 1 ? datasCadastro[0] : datasCadastro[1],
        impacto: formData?.ld_impacto?.value,
        status: formData?.locacao_status?.map(({ value }) => value),
        gerador: !formData?.gerador ? undefined : formData?.gerador,
        blimpado: !formData?.blimpado
          ? undefined
          : geradorRadioValues[formData?.blimpado],
        veiculo_gerador: !formData?.gerador_portatil
          ? undefined
          : geradorRadioValues[formData?.gerador_portatil],
        fin_base_apoio: formData?.ld_finalidade_base_apoio?.map(
          ({ value }) => value,
        ),
        captação_som_direto: formData?.captacao_som_direto,
        drone: formData?.drone,
        cooproduction: formData?.coproducao,
        projecao: formData?.projecoes,
        efeitos_especiais: formData?.efeitos_especiais,
        desligamento_luz: formData?.desligamento_luz,
        equipamentos: formData?.ld_equipamento_filmagem?.map(
          ({ value }) => value,
        ),
        l_equipamentos: formData?.l_equipamento_esportivo?.map(
          ({ value }) => value,
        ),
        modalidade_pagamento: formData?.modalidade_pagamento?.map(
          ({ value }) => value,
        ),
        initials: formData?.orgaos_select?.map(({ value }) => value),
        work_title: formData?.work_title,
        tamanho_equipe_inicio: formData?.tamanho_equipe_inicio,
        tamanho_equipe_fim: formData?.tamanho_equipe_fim,
        name_view: formData?.tipo_locacao?.value,
        endereco: formData?.espaco_input,
        ...espacos,
        ...subEspacoSMC,
      });

      return body;
    } catch (error) {
      showModalMessage({
        type: 'error',
        text: 'Erro ao formatar dados para requisição',
      });
      return null;
    }
  }, [
    formData?.blimpado,
    formData?.captacao_som_direto,
    formData?.coproducao,
    formData?.datas_cadastro,
    formData?.desligamento_luz,
    formData?.drone,
    formData?.efeitos_especiais,
    formData?.espaco_input,
    formData?.espaco_multi_select,
    formData?.espaco_select?.label,
    formData?.espaco_select?.value,
    formData?.gerador,
    formData?.gerador_portatil,
    formData?.l_equipamento_esportivo,
    formData?.ld_equipamento_filmagem,
    formData?.ld_finalidade_base_apoio,
    formData?.ld_impacto?.value,
    formData?.ld_sub_espaco_smc,
    formData?.locacao_status,
    formData?.modalidade_pagamento,
    formData?.orgaos_select,
    formData?.projecoes,
    formData?.tamanho_equipe_fim,
    formData?.tamanho_equipe_inicio,
    formData?.tipo_locacao?.espacoName,
    formData?.tipo_locacao?.subEspaco,
    formData?.tipo_locacao?.value,
    formData?.work_title,
    handleValidationFields,
  ]);

  const getEspacoLocacao = (locacao, name_view) => {
    const espacos = {
      'Locação Acesso Calçadões':
        locacao?.address_filmagem || locacao?.address_calcadao,
      'Locação Autódromo': locacao?.ld_lista_autodromo?.name,
      'Locação Cemitério':
        locacao?.ld_cemiterio?.nome === 'Outro'
          ? locacao?.outro_cemiterio
          : locacao?.ld_cemiterio?.nome,
      'Locação Galeria': locacao?.ld_galeria?.name,
      'Locação Filmagem Mercado':
        locacao?.l_mercados?.name === 'Outro'
          ? locacao?.outro_mercado
          : locacao?.l_mercados?.name,
      'Locação Filmagem Parque':
        locacao?.l_parques?.name === 'Outro'
          ? locacao?.outro_parque
          : locacao?.l_parques?.name ?? 'Não informado',
      'Locação Feira Livre': locacao?.address,
      'Locação Planetário': locacao?.ld_planetario?.name,
      'Locação Teatro': locacao?.ld_teatro?.name,
      'Locação Terminal': locacao?.ld_terminais?.name,
      'Locação Praça Pública': locacao?.street,
      'Locação Camera Car': locacao?.endereco_filmagem ?? 'Não informado',
      'Locação Projeção Pública': `${locacao?.address_local}, ${locacao?.number_local}, ${locacao?.neighborhood_local}`,
      'Locação Outros Espaços': locacao?.address,
      'Locação Espaços Cultura': locacao?.endereco_local,
      'Locação Espaços SMAD': locacao?.address,
      'Locação Utilização de Via':
        locacao?.address_temp ||
        `${locacao?.address || ''} ${locacao?.number || ''} ${
          locacao?.neighborhood || ''
        } ${locacao?.ld_complemento_numero?.name || ''} ${
          locacao?.via_sentido || ''
        }`,
      'Locação Reserva de Vaga': `${locacao?.street || ''} ${
        locacao?.number || ''
      } ${locacao?.neighborhood || ''} CEP: ${locacao?.cep || ''}`,
    };

    return espacos[name_view] || '';
  };

  const handleClickGerar = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri,
        method: ApiClient.POST,
        body,
        expectedStatus: 200,
      });

      setResponseData({
        ...resp.data,
        ...{
          list: resp.data.list.map(locacao => ({
            created_at: locacao.created_at,
            protocol: locacao.protocol,
            nome_locacao: locacao.titulo_locacao ?? locacao.name_view,
            espaco: getEspacoLocacao(locacao, locacao.name_view),
            work_protocol: locacao?.work?.protocol,
            tamanho_equipe_tecnica:
              locacao?.locacao_default?.tamanho_equipe_tecnica,
            tamanho_do_elenco: locacao?.locacao_default?.tamanho_do_elenco,
          })),
        },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  const handleClickExportarExcel = useCallback(async () => {
    try {
      const body = await bodyBuilder();

      if (!body) return;

      setLoading(true);

      await ApiClient.doRequest({
        uri: `${uri}/xls`,
        method: ApiClient.POST,
        body: { ...body, report_name: 'getLocacaoReport' },
        expectedStatus: 200,
        isBinary: true,
        fileName: 'relatorio.xlsx',
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, [bodyBuilder]);

  // useEffect(() => {
  //   setFormData(state => ({
  //     ...state,
  //     valor_locacao: valorLocacaoSelectList[0],
  //   }));
  // }, []);

  const handleResetForm = () => {
    setFormData({
      datas_cadastro: [],
      tipo_locacao: '',
      ld_impacto: '',
      work_title: '',
      tamanho_equipe_inicio: '',
      tamanho_equipe_fim: '',
      locacao_status: [],
      modalidade_pagamento: [],
      ld_equipamento_filmagem: [],
    });
  };

  const handleChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  // lists and fetchs

  const tipoImpactoList = useList({
    url: 'ld-impacto?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const locacaoStatusList = useList({
    url: 'locacao-status?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const finalidadesBaseApoioList = useList({
    url: 'ld-finalidade-base-apoio?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const modalidadesPagamentoList = useList({
    url: 'modalidade-pagamento?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const estruturaEquipamentosFilmagemList = useList({
    url: 'ld-equipamento-filmagem?paginate=false',
  }).map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  const orgaosList = useList({
    url: 'document-initial?paginate=false',
  }).map(({ uuid, initial, full_name }) => ({
    value: uuid,
    label: `${initial ? `${initial} - ` : ''} ${full_name}`,
  }));

  const fetchEspacos = useCallback(async () => {
    if (formData?.tipo_locacao?.espacoComponentElement !== 'select') return;

    try {
      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri: `${formData?.tipo_locacao?.espacoRoute}?paginate=false`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setLoading(false);
      setEspacosSelectList(
        resp?.data?.data?.map(({ name, nome, uuid }) => ({
          value: uuid,
          label: name || nome,
        })),
      );
    } catch (error) {
      setLoading(false);
      showModalMessage({
        type: 'error',
        title: `Falha ao tentar carregar espaços de ${formData?.tipo_locacao?.label}`,
      });
    }
  }, [
    formData?.tipo_locacao?.espacoComponentElement,
    formData?.tipo_locacao?.espacoRoute,
    formData?.tipo_locacao?.label,
  ]);

  const fetchEquipamentosEsportivos = useCallback(async () => {
    if (
      ![
        'Locação Cemitério',
        'Locação Feira Livre',
        'Locação Filmagem Parque',
        'Locação Filmagem Mercado',
        'Locação Filmagem Esportivo',
      ].includes(formData?.tipo_locacao?.value)
    )
      return [];

    try {
      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri: `l-equipamento-esportivo?paginate=false`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setLoading(false);
      setEquipamentosEsportivosList(
        resp?.data?.data?.map(({ name, uuid }) => ({
          value: uuid,
          label: name,
        })),
      );
    } catch (error) {
      setLoading(false);
      setEquipamentosEsportivosList([]);
      showModalMessage({
        type: 'error',
        title: 'Falha ao tentar carregar equipamentos esportivos',
      });
    }
  }, [formData?.tipo_locacao?.value]);

  const fetchSubEspacosSMC = useCallback(async () => {
    if (
      !(
        formData?.tipo_locacao?.value === 'Locação Espaços Cultura' &&
        Object.keys(formData?.tipo_locacao?.subEspaco).includes(
          formData?.espaco_select?.label,
        )
      )
    )
      return;

    try {
      setLoading(true);

      const resp = await ApiClient.doRequest({
        uri: `${
          formData?.tipo_locacao?.subEspaco[formData?.espaco_select?.label]
            ?.subEspacoRoute
        }?paginate=false`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setLoading(false);
      setSubEspacosSMCSelectList(
        resp?.data?.data?.map(({ name, nome, uuid }) => ({
          value: uuid,
          label: name || nome,
        })),
      );
    } catch (error) {
      setLoading(false);
      showModalMessage({
        type: 'error',
        title: `Falha ao tentar carregar espaços de ${formData?.tipo_locacao?.label}`,
      });
    }
  }, [
    formData?.espaco_select?.label,
    formData?.tipo_locacao?.label,
    formData?.tipo_locacao?.subEspaco,
    formData?.tipo_locacao?.value,
  ]);

  useEffect(() => {
    fetchEquipamentosEsportivos();
  }, [fetchEquipamentosEsportivos]);

  useEffect(() => {
    fetchEspacos();
  }, [fetchEspacos]);

  useEffect(() => {
    fetchSubEspacosSMC();
  }, [fetchSubEspacosSMC]);

  // renders

  const renderEquipamentosEsportivos = useCallback(() => {
    if (
      ![
        'Locação Cemitério',
        'Locação Feira Livre',
        'Locação Filmagem Parque',
        'Locação Filmagem Mercado',
        'Locação Filmagem Esportivo',
      ].includes(formData?.tipo_locacao?.value)
    )
      return <></>;

    return (
      <Select
        name="l_equipamento_esportivo"
        label="Equipamentos Esportivos"
        isMulti
        isLoading={loading}
        isDisabled={loading}
        value={formData?.l_equipamento_esportivo}
        placeholder="Selecione"
        style={selectCustomStyles}
        options={equipamentosEsportivosList}
        onChange={e => {
          handleChange(
            'l_equipamento_esportivo',
            e?.map(({ value, label }) => ({ value, label })),
          );
        }}
      />
    );
  }, [
    equipamentosEsportivosList,
    formData?.l_equipamento_esportivo,
    formData?.tipo_locacao?.value,
    loading,
  ]);

  const renderSubEspacosSMC = useCallback(() => {
    if (
      !(
        formData?.tipo_locacao?.value === 'Locação Espaços Cultura' &&
        Object.keys(formData?.tipo_locacao?.subEspaco).includes(
          formData?.espaco_select?.label,
        )
      )
    )
      return <></>;

    return (
      <Select
        name="ld_sub_espaco_smc"
        label={formData?.espaco_select?.label}
        isMulti
        isLoading={loading}
        isDisabled={loading}
        value={formData?.ld_sub_espaco_smc}
        placeholder="Selecione"
        style={selectCustomStyles}
        options={subEspacosSMCSelectList}
        onChange={e => {
          handleChange(
            'ld_sub_espaco_smc',
            e?.map(({ value, label }) => ({ value, label })),
          );
        }}
      />
    );
  }, [
    formData?.tipo_locacao?.value,
    formData?.tipo_locacao?.subEspaco,
    formData?.espaco_select?.label,
    formData?.ld_sub_espaco_smc,
    loading,
    subEspacosSMCSelectList,
  ]);

  const renderEspacos = useCallback(() => {
    if (!formData?.tipo_locacao?.espacoComponentElement) return <></>;

    if (formData?.tipo_locacao?.espacoComponentElement === 'input')
      return (
        <Input
          type="text"
          name="espaco_input"
          label={formData?.tipo_locacao?.espacoComponentLabel || 'Espaço'}
          value={formData?.espaco_input}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
      );

    if (formData?.tipo_locacao?.value === 'Locação Espaços Cultura')
      return (
        <Select
          name="espaco_select"
          label="Espaço"
          isLoading={loading}
          isDisabled={loading}
          value={formData?.espaco_select}
          placeholder="Selecione"
          style={selectCustomStyles}
          options={espacosSelectList}
          onChange={e => {
            handleChange('ld_sub_espaco_smc', []);
            handleChange('espaco_select', e);
          }}
        />
      );

    return (
      <Select
        name="espaco_multi_select"
        label="Espaço"
        isMulti
        isLoading={loading}
        isDisabled={loading}
        value={formData?.espaco_multi_select}
        placeholder="Selecione"
        style={selectCustomStyles}
        options={espacosSelectList}
        onChange={e => {
          handleChange(
            'espaco_multi_select',
            e?.map(({ value, label }) => ({ value, label })),
          );
        }}
      />
    );
  }, [
    formData?.tipo_locacao?.espacoComponentElement,
    formData?.tipo_locacao?.espacoComponentLabel,
    formData?.tipo_locacao?.value,
    formData?.espaco_input,
    formData?.espaco_select,
    formData?.espaco_multi_select,
    loading,
    espacosSelectList,
  ]);

  const renderFiltros = () => {
    return (
      <>
        <Wrap>
          <GridContainer cols={4} gap={30}>
            <MultiDateInput
              range
              name="datas_cadastro"
              label="Data de Cadastro"
              required
              value={formData?.datas_cadastro}
              onChange={e => {
                handleChange(
                  'datas_cadastro',
                  e?.map(
                    ({ day, month, year }) =>
                      `${String(day).padStart(2, '0')}/${String(month).padStart(
                        2,
                        '0',
                      )}/${year}`,
                  ),
                );
              }}
            />

            {Object.values(formData?.datas_cadastro || []).length > 1 && (
              <>
                <Select
                  name="tipo_locacao"
                  label="Tipo de Locação"
                  value={formData?.tipo_locacao}
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={tipoLocacaoList}
                  onChange={e => {
                    handleChange('tipo_locacao', e);
                    handleChange('espaco_input', '');
                    handleChange('espaco_select', '');
                    handleChange('espaco_multi_select', []);
                    handleChange('l_equipamento_esportivo', []);

                    if (e?.value === 'locacao-projecao') {
                      handleChange('ld_impacto', '');
                    }
                  }}
                />

                {renderEspacos()}

                {renderSubEspacosSMC()}

                {formData?.tipo_locacao?.value !==
                  'Locação Projeção Pública' && (
                  <Select
                    name="ld_impacto"
                    label="Impactos"
                    value={formData?.ld_impacto}
                    placeholder="Selecione"
                    style={selectCustomStyles}
                    options={tipoImpactoList}
                    onChange={e => {
                      handleChange('ld_impacto', e);
                    }}
                  />
                )}

                <Select
                  name="locacao_status"
                  label="Status"
                  isMulti
                  value={formData?.locacao_status}
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={locacaoStatusList}
                  onChange={e => {
                    handleChange(
                      'locacao_status',
                      e?.map(({ value, label }) => ({ value, label })),
                    );
                  }}
                />

                <Select
                  name="modalidade_pagamento"
                  label="Modalidades de Pagamento"
                  isMulti
                  value={formData?.modalidade_pagamento}
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={modalidadesPagamentoList}
                  onChange={e => {
                    handleChange(
                      'modalidade_pagamento',
                      e?.map(({ value, label }) => ({ value, label })),
                    );
                  }}
                />

                <Select
                  name="ld_equipamento_filmagem"
                  label="Estrutura e Equipamentos de Filmagem"
                  isMulti
                  value={formData?.ld_equipamento_filmagem}
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={estruturaEquipamentosFilmagemList}
                  onChange={e => {
                    handleChange(
                      'ld_equipamento_filmagem',
                      e?.map(({ value, label }) => ({ value, label })),
                    );
                  }}
                />

                {renderEquipamentosEsportivos()}

                <Input
                  type="text"
                  label="Nome da Obra"
                  name="work_title"
                  value={formData?.work_title}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />

                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-around',
                    }}
                  >
                    <LimiterInputWidth w50 style={{ marginRight: 0 }}>
                      <Input
                        type="number"
                        label="Tamanho da equipe"
                        name="tamanho_equipe_inicio"
                        value={formData?.tamanho_equipe_inicio}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                    </LimiterInputWidth>
                    <BoxHeader
                      style={{
                        width: '30px',
                        justifyContent: 'center',
                      }}
                    >
                      <span className="boxText" style={{ color: '#828282' }}>
                        a
                      </span>
                    </BoxHeader>
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        name="tamanho_equipe_fim"
                        value={formData?.tamanho_equipe_fim}
                        onChange={e =>
                          handleChange(e.target.name, e.target.value)
                        }
                      />
                    </LimiterInputWidth>
                  </div>
                </div>

                {/* <Select
                  name="valor_locacao_select"
                  label="Valor da locação"
                  value={formData?.valor_locacao_select}
                  placeholder="Selecione"
                  style={selectCustomStyles}
                  options={valorLocacaoSelectList}
                  onChange={e => {
                    handleChange('valor_locacao_select', e);
                  }}
                /> */}

                <Select
                  name="orgaos_select"
                  label="Órgãos"
                  placeholder="Selecione"
                  options={orgaosList}
                  style={selectCustomStyles}
                  onChange={e =>
                    handleChange(
                      'orgaos_select',
                      e?.map(({ value, label }) => ({ value, label })),
                    )
                  }
                  isMulti
                />

                <CheckboxHtml
                  name="gerador"
                  label="Gerador?"
                  value={formData?.gerador}
                  checked={formData?.gerador}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                    handleChange('blimpado', null);
                    handleChange('gerador_portatil', null);
                  }}
                />

                {formData?.gerador && (
                  <>
                    <RadioNoForm
                      name="blimpado"
                      value={formData?.blimpado}
                      yesLabelValue="Blimpado"
                      noLabelValue="Não-Blimpado"
                      style={{ display: 'flex' }}
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                    />
                    <RadioNoForm
                      name="gerador_portatil"
                      value={formData?.gerador_portatil}
                      yesLabelValue="Gerador portátil"
                      noLabelValue="Sobre um veículo"
                      style={{ display: 'flex' }}
                      onChange={e => {
                        handleChange(e.target.name, e.target.value);
                      }}
                    />
                  </>
                )}

                <CheckboxHtml
                  name="base_apoio"
                  label="Base de apoio?"
                  value={formData?.base_apoio}
                  checked={formData?.base_apoio}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                    handleChange('ld_finalidade_base_apoio', []);
                  }}
                />

                {formData?.base_apoio && (
                  <Select
                    name="ld_finalidade_base_apoio"
                    label="Finalidades Base Apoio"
                    isMulti
                    value={formData?.ld_finalidade_base_apoio}
                    placeholder="Selecione"
                    style={selectCustomStyles}
                    options={finalidadesBaseApoioList}
                    onChange={e => {
                      handleChange(
                        'ld_finalidade_base_apoio',
                        e?.map(({ value, label }) => ({ value, label })),
                      );
                    }}
                  />
                )}

                <CheckboxHtml
                  name="captacao_som_direto"
                  label="Captação de som direto?"
                  value={formData?.captacao_som_direto}
                  checked={formData?.captacao_som_direto}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <CheckboxHtml
                  name="drone"
                  label="Drone?"
                  value={formData?.drone}
                  checked={formData?.drone}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <CheckboxHtml
                  name="efeitos_especiais"
                  label="Efeitos especiais?"
                  value={formData?.efeitos_especiais}
                  checked={formData?.efeitos_especiais}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <CheckboxHtml
                  name="projecoes"
                  label="Projeções?"
                  value={formData?.projecoes}
                  checked={formData?.projecoes}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />

                <CheckboxHtml
                  name="desligamento_luz"
                  label="Desligamento de luz?"
                  value={formData?.desligamento_luz}
                  checked={formData?.desligamento_luz}
                  onChange={e => {
                    handleChange(e.target.name, e.target.checked);
                  }}
                />
              </>
            )}
          </GridContainer>
        </Wrap>

        <Wrap style={{ justifyContent: 'flex-end', marginTop: 10 }}>
          <SplitButton
            buttons={[
              {
                label: 'Gerar',
                onClick: handleClickGerar,
              },
              {
                label: 'Exportar Excel...',
                onClick: handleClickExportarExcel,
              },
            ]}
            isLoading={loading}
            disableAllButtons={loading}
          />

          <Button
            type="button"
            btType="#828282"
            onClick={handleResetForm}
            label="Limpar campos"
            full
            loading={loading}
            disabled={loading}
            width="150px"
            style={{ marginLeft: 5 }}
          />
        </Wrap>
        <Line />
      </>
    );
  };

  const renderQuantitativo = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData).length <= 0 ||
      responseData?.statistics?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    return (
      <>
        <GridContainer cols={5} style={{ marginTop: 15 }}>
          {responseData.statistics?.map(({ label, value }) => (
            <Text label={label} value={value} />
          ))}
        </GridContainer>
        <Line />
      </>
    );
  };

  const renderListagemBaseNova = () => {
    if (loading)
      return (
        <FiLoader
          size={20}
          className="icon-spin"
          style={{ alignSelf: 'center' }}
        />
      );

    if (
      Object.keys(responseData).length <= 0 ||
      responseData?.list?.length <= 0
    )
      return <span>Nenhum registro encontrado</span>;

    const columnsNames = [
      'Data/Hora Criação',
      'Nº da Locação',
      'Nome da Locação',
      'Espaço da Locação',
      'Nº da Obra',
      'Tamanho Equipe Técnica',
      'Tamanho Elenco',
    ];

    const tableData = responseData.list.map(data => ({
      created_at: format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm'),
      protocol: data?.protocol,
      nome_locacao: data?.nome_locacao,
      espaco: data?.espaco,
      work_protocol: data?.work_protocol,
      tamanho_equipe_tecnica: data?.tamanho_equipe_tecnica,
      tamanho_do_elenco: data?.tamanho_do_elenco,
    }));

    return (
      <VirtualTable
        title="Impressão da listagem de Locação"
        height={500}
        width="100%"
        itemCount={tableData?.length}
        itemSize={40}
        tableData={tableData}
        showPrinter
        columnsNames={columnsNames}
        header={
          <thead>
            <tr>
              {columnsNames.map(columnName => (
                <th>{columnName}</th>
              ))}
            </tr>
          </thead>
        }
        row={({ index, style }) => (
          <tr>
            <td>{tableData[index].created_at}</td>
            <td>{tableData[index].protocol}</td>
            <td>{tableData[index].nome_locacao}</td>
            <td>{tableData[index].espaco}</td>
            <td>{tableData[index].work_protocol}</td>
            <td>{tableData[index].tamanho_equipe_tecnica}</td>
            <td>{tableData[index].tamanho_do_elenco}</td>
          </tr>
        )}
        footer={
          <tfoot>
            <tr>
              <td colSpan={3}>Fim de listagem</td>
            </tr>
          </tfoot>
        }
      />
    );
  };

  return (
    <Form ref={formRef}>
      <Wrap>
        <AccordionItem opened label="Filtros">
          {renderFiltros()}
        </AccordionItem>
      </Wrap>

      <Wrap>
        <AccordionItem label="Quantitativos">
          {renderQuantitativo()}
        </AccordionItem>
      </Wrap>

      <Wrap>
        <AccordionItem label="Listagem (Base nova)">
          {renderListagemBaseNova()}
        </AccordionItem>
      </Wrap>
    </Form>
  );
};
