export const clearMasks = (data, onlyKeys) => {
  const newData = {};

  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'string' && onlyKeys.includes(key))
      newData[key] = data[key]?.replace(/[^0-9]/g, '');
  });

  return { ...data, ...newData };
};
