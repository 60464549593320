import React, { useContext } from 'react';
import { ThemeContext } from '~/styles/ThemeProvider';
import { BannerHeader as StyledBannerHeader } from './styles';
import bgHeader from '~/assets/images/usuario/bg-header.png';
import clientResources from '~/data/clientResources';

const BannerHeader = ({ children }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <StyledBannerHeader
      bgImage={
        theme.system_background.url || clientResources.images.system.bgHeader
      }
    >
      {children}
    </StyledBannerHeader>
  );
};

export default BannerHeader;
