import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Container } from "~/components/Forms/Checkbox/styles";

export const BoxBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .boxText {
    font-family: InterRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #26a2b1;
    margin-bottom: 20px;
  }
`;

export const ChoiceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardChoice = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  background: ${props => props.blue ? '#48A2B1' : '#3f3f3f'};
  width: 100%;
  max-width: 480px;
  height: 350px;
  padding: 0 16px;
  border-radius: 15px;
  ${props => props.disabled && 'opacity: 0.7;'}
  ${props => props.disabled && 'cursor: not-allowed;'}
  ${props => !props.disabled && 'cursor: pointer;'}

  img {
    width: 100%;
    max-width: 100px;
  }

  h1 {
    font-family: InterRegular;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #ffff;
    text-align: center;
  }

  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const LogOut = styled.div`
  cursor: pointer;
`;
