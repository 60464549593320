import styled from 'styled-components';
import { WhiteBox } from '~/components/WhiteBox/styles';

export const BannerContent = styled.div`
  width: 100%;
  height: 100%;

  background: linear-gradient(90deg, #323232 14.34%, rgba(50, 50, 50, 0) 88.3%);
  opacity: 0.9;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  h1 {
    font-family: InterBold;
    font-size: 30px;
    line-height: 36px;
    color: #fff;
    margin-bottom: 10px;
  }

  span {
    font-family: InterRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    color: #e0e0e0;
  }
`;

export const TextTutorial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  h1 {
    font-family: InterBold;
    font-size: 30px;
    line-height: 36px;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  span {
    font-family: InterRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    color: #e0e0e0;
  }
`;

export const BoxHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .boxText {
    font-family: 'InterBold', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #26a2b1;
  }

  span {
    color: #828282;
    font-size: 24px;
  }
`;

export const PageContent = styled.div`
  width: 100%;
  max-width: 1100px;
  height: auto;
  margin: 0 auto;

  ${WhiteBox} {
    position: relative;
    bottom: 62px;
  }
`;

export const PageFooter = styled.div`
  width: 100%;
  margin-top: auto;
  background: #f2f2f2;
  padding: 10px;
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    &:first-child {
      margin-right: 10px;
    }
  }
`;


export const InputSearch = styled.input`
  width: 50vh;
  height: 5vh;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  padding: 16px;
  font-family: 'InterSemiBold',sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
}
`;
