import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { BsFillEyeFill } from 'react-icons/bs';
import { format } from 'date-fns';

function ListaPageDocumentosChat({ data }) {
  return (
    <>
      <tr>
        <td>
          {data && format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm')}
        </td>
        <td>{data && data?.name}</td>
        <td>{data && data?.protocol}</td>
        <td
          style={{
            justifyContent: 'center',
          }}
        >
          {data && data?.quantidade_new}
        </td>
        <td
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className="buttons"
        >
          <span tooltip="Mais detalhes...">
            {data?.endpoint === 'work' ? (
              <Link
                to={{
                  pathname: '/obra',
                  state: { data },
                }}
              >
                <BsFillEyeFill color="#4f4f4f" size={24} />
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/solicitacao-nova-locacao/${data?.endpoint}`,
                  state: { viewData: data, isAdminRequest: true },
                }}
              >
                <BsFillEyeFill color="#4f4f4f" size={24} />
              </Link>
            )}
          </span>
        </td>
      </tr>
    </>
  );
}

export default withRouter(ListaPageDocumentosChat);
