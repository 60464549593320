/* eslint-disable no-case-declarations */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import CryptoJS from 'crypto-js';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

import ApiClient from '~/utils/ApiClient';

import {
  Input,
  Select,
  ChoiceButtonLocacao,
  Textarea,
  FormChoiceButton,
  File,
  InputNoForm,
} from '~/components/Forms';

import LocacaoDocs from '~/pages/Usuario/Locacoes/LocacaoDocs';

import {
  PageContainer,
  ConditionalContainer,
  LimiterInputWidth,
  MultiInputContainer,
  ActionInputMulti,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';
import Button from '~/components/Button';

import { showModalMessage } from '~/services/notification';

import SomFilmagemListActions from '~/store/ducks/som-filmagem-list';
import Documents from '~/components/Documents/Documents';
import Chat from '~/components/Chat/chat';
import {
  ModalidadePagamento,
  ObservacoesChat,
  ValorLocacao,
} from '~/components/Admin';
import LogoIcon from '~/components/LogoIcon';
import WorkStatusSwitch from '~/components/Admin/WorkStatusSwitch';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import {
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import tenancy_env from '~/utils/MultiTenancyUtil';
import { ActionButton } from '~/components/Forms/FormChoiceButton/styles';
import clientResources from '~/data/clientResources';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

export default function Projecoes() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();
  const projecaoFileRef = useRef(null);

  // states
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responseLocation, setResponseLocation] = useState();
  const [errorsForm, setErrorsForm] = useState({});
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);

  // state file
  const [projecaoFile, setProjecaoFile] = useState(null);
  const [dataProjecaoFile, setDataProjecaoFile] = useState(null);
  // states multi input
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states choices
  const [projetadoEquipMunicipal, setProjetadoEquipMunicipal] = useState(null);
  const [interferenciaExterna, setInterferenciaExterna] = useState(null);
  const [captacaoSomDireto, setCaptacaoSomDireto] = useState(null);
  const [somFilmagem, setSomFilmagem] = useState(null);
  const [efeitosEspeciais, setEfeitosEspeciais] = useState(null);
  const [reservasVagas, setReservasVagas] = useState(null);

  // state outro fields
  const [showOutroSomFilmagemField, setShowOutroSomFilmagemField] =
    useState(false);
  const [showDescrSomFilmagemField, setShowDescrSomFilmagemField] =
    useState(false);

  // selectors
  const { data: somFilmagemSelectList } = useSelector(
    state => state.somFilmagemList,
  );

  // Busca dados da Reserva
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-projecao/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set choice buttons
      setProjetadoEquipMunicipal(respLoc?.projetado_equip_municipal === 1);
      setInterferenciaExterna(respLoc?.interferencia === 1);
      setCaptacaoSomDireto(respLoc?.captacao_som_direto === 1);
      setSomFilmagem(Object.values(respLoc?.ld_som_filmagem || []).length > 0);
      setEfeitosEspeciais(typeof respLoc?.descr_efeitos === 'string');
      setReservasVagas(
        Object.values(respLoc?.locacao_via_externa || []).length > 0,
      );

      // set multi input
      setDatasFilmagemInputs(
        respLoc?.dates_locacao?.map(({ date, changes_to_approve }) => ({
          data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
          changes_to_approve,
        })),
      );

      setHorasFilmagemInputs(
        respLoc?.hour_projecao?.map(({ inicio, fim, changes_to_approve }) => ({
          inicio: inicio.substring(0, 5),
          fim: fim.substring(0, 5),
          changes_to_approve,
        })),
      );

      // set campos 'Outro'
      setShowOutroSomFilmagemField(
        respLoc?.ld_som_filmagem?.find(equip => equip.name === 'outro'),
      );
      setShowDescrSomFilmagemField(
        typeof respLoc?.descricoes_som_filmagem === 'string',
      );

      // set select default values
      formRef.current?.setFieldValue(
        'ld_som_filmagem',
        respLoc?.ld_som_filmagem?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      setResponseLocation(respLoc);
      setLoading(false);
    }
  }, [dadoState]);

  // effects
  useEffect(() => {
    dispatch(SomFilmagemListActions.showRequest());
    visualizacaoDadosCB();
  }, [dispatch, visualizacaoDadosCB]);

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;

    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    if (name === 'projecao_file') {
      setProjecaoFile(base64.split('base64,'));
      setDataProjecaoFile(file);
      setErrorsForm(state => ({
        ...state,
        projecaoFile: false,
      }));
    }

    setFormData(state => ({ ...state, [name]: base64.split('base64,')[1] }));
  }

  function handleProjecaoFile() {
    return projecaoFileRef.current.click();
  }

  // Verification files
  let fileProjecao = [];

  // Filter files
  fileProjecao = responseLocation?.files?.filter(
    file => file.name === 'Conteúdo Projeção',
  );

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleValidationFields(data) {
    try {
      formRef.current.setErrors({});
      const defaultMsg = 'Obrigatório';

      const schema = Yup.object().shape({
        address_local: Yup.string().typeError(defaultMsg).required(defaultMsg),
        number_local: Yup.string().typeError(defaultMsg).required(defaultMsg),
        neighborhood_local: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        address_montagem: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        number_montagem: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        neighborhood_montagem: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tamanho_equipe_tecnica: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        tamanho_do_elenco: Yup.string()
          .typeError(defaultMsg)
          .required(defaultMsg),
        descricao_cena: Yup.string().typeError(defaultMsg).required(defaultMsg),
        descr_efeitos: Yup.string()
          .typeError(defaultMsg)
          .test(
            'efeitos-especiais-selecionado',
            defaultMsg,
            value => !(efeitosEspeciais && (value?.length <= 0 || !value)),
          ),
        ld_som_filmagem: Yup.array()
          .of(Yup.string().typeError(defaultMsg).required(defaultMsg))
          .typeError(defaultMsg)
          .test(
            'som-filmagem-selecionado',
            defaultMsg,
            value => !(somFilmagem === true && (value?.length <= 0 || !value)),
          ),
        outro_som_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'outro-som-filmagem-selecionado',
            defaultMsg,
            value =>
              !(
                somFilmagem &&
                showOutroSomFilmagemField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
        descricoes_som_filmagem: Yup.string()
          .typeError(defaultMsg)
          .test(
            'descricoes-som-filmagem-selecionado',
            defaultMsg,
            value =>
              !(
                somFilmagem &&
                showDescrSomFilmagemField === true &&
                (value?.length <= 0 || !value)
              ),
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }

      // validação arquivos
      if (!data?.projecao_file) {
        await showModalMessage({
          type: 'error',
          text: 'Arquivo Conteúdo Projetado não foi informado!',
        });
        setErrorsForm(state => ({
          ...state,
          projecaoFile: true,
        }));
        return false;
      }

      // Campos 'choice button' não selecionados
      if (projetadoEquipMunicipal === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Conteúdo Projetado em Equipamento Público' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          projetadoEquipMunicipal: true,
        }));
        return false;
      }

      if (interferenciaExterna === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Interferência Externa' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          interferenciaExterna: true,
        }));
        return false;
      }

      if (captacaoSomDireto === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Captação de Som Direto' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          captacaoSomDireto: true,
        }));
        return false;
      }

      if (somFilmagem === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Som na Filmagem' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          somFilmagem: true,
        }));
        return false;
      }

      if (efeitosEspeciais === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Efeitos Especiais' não foi informado!",
        });
        setErrorsForm(state => ({
          ...state,
          efeitosEspeciais: true,
        }));
        return false;
      }

      if (reservasVagas === null) {
        await showModalMessage({
          type: 'error',
          text: "Opção 'Reserva de Vagas' não foi informada!",
        });
        setErrorsForm(state => ({
          ...state,
          reservasVagas: true,
        }));
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }

    return true;
  }

  function formatEspecificaData(data) {
    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      tamanho_equipe_tecnica: data?.tamanho_equipe_tecnica,
      tamanho_do_elenco: data?.tamanho_do_elenco,
      descricao_cena: data?.descricao_cena,
      especificidades: data?.especificidades,
      address_local: data?.address_local,
      number_local: data?.number_local,
      neighborhood_local: data?.neighborhood_local,
      address_montagem: data?.address_montagem,
      number_montagem: data?.number_montagem,
      neighborhood_montagem: data?.neighborhood_montagem,
      projecao_conteudo_base64: data?.projecao_file,
      projetado_equip_municipal: projetadoEquipMunicipal,
      interferencia: interferenciaExterna,
    });

    // dados de choices
    const dataCaptacaoSomDireto = { captacao_som_direto: captacaoSomDireto };

    const dataEfeitosEspeciais = {
      descr_efeitos: efeitosEspeciais ? data?.descr_efeitos : null,
    };

    const dataSomFilmagem = {
      ld_som_filmagem: somFilmagem ? data?.ld_som_filmagem : null,
    };

    // dados de campos exibidos condicionalmente
    const dataOutroSomFilmagem = {
      outro_som_filmagem:
        somFilmagem && showOutroSomFilmagemField
          ? data?.outro_som_filmagem
          : null,
    };

    const dataDescrSomFilmagem = {
      descricoes_som_filmagem:
        somFilmagem && showDescrSomFilmagemField
          ? data?.descricoes_som_filmagem
          : null,
    };

    // format datas filmagem
    const datasFilmagem = datasFilmagemInputs
      .filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_filmagem));

    return Object.assign(
      dataNaoCondicional,
      { dates: datasFilmagem },
      { hours: horasFilmagemInputs },
      dataEfeitosEspeciais,
      dataCaptacaoSomDireto,
      dataSomFilmagem,
      dataDescrSomFilmagem,
      dataOutroSomFilmagem,
    );
  }

  async function handleSubmit(data) {
    // files
    data.projecao_file = formData?.projecao_file;

    // selects
    data.ld_som_filmagem = formData?.ld_som_filmagem;

    if (!(await handleValidationFields(data))) {
      return;
    }

    const especificaData = formatEspecificaData(data);

    setLoading(true);

    try {
      await ApiClient.doRequest({
        uri: 'locacao-projecao',
        method: ApiClient.POST,
        body: Object.assign(especificaData, { work_uuid: dadoState?.uuid }),
        expectedStatus: 201,
      });

      setLoading(false);

      await showModalMessage({
        type: 'success',
        title: 'Projeções em Logradouro Público com Estrutura Interna',
        text: 'Locação gravada com sucesso',
      });

      // conclusao
      const urlReservas = '/solicitacao-nova-locacao/reservas-vagas-vinculado';
      const hasReservas = dadoState?.redirections?.some(
        ({ url }) => url === urlReservas,
      );

      const redirections = [
        ...(dadoState?.redirections || []),
        { url: urlReservas, next: hasReservas || reservasVagas },
      ].filter(({ next }) => !!next);

      const [nextRedirect] = redirections;

      if (nextRedirect) {
        history.replace({
          pathname: nextRedirect.url,
          state: {
            uuid: dadoState?.uuid,
            redirections: redirections.filter(
              ({ url }) => url !== nextRedirect.url,
            ),
          },
        });
      } else {
        history.push('/home-user');
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title: 'Projeções em Logradouro Público com Estrutura Interna',
        text: 'Ocorreu um erro ao tentar gravar Projeções em Logradouro Público com Estrutura Interna. Verifique os dados.',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  // handle true/false
  function handleProjetadoEquipMunicipalTrue(e) {
    e.preventDefault();
    setProjetadoEquipMunicipal(true);
    setErrorsForm(state => ({
      ...state,
      projetadoEquipMunicipal: false,
    }));
  }

  function handleProjetadoEquipMunicipalFalse(e) {
    e.preventDefault();
    setProjetadoEquipMunicipal(false);
    setErrorsForm(state => ({
      ...state,
      projetadoEquipMunicipal: false,
    }));
  }

  function handleInterferenciaExternaTrue(e) {
    e.preventDefault();
    setInterferenciaExterna(true);
    setErrorsForm(state => ({
      ...state,
      interferenciaExterna: false,
    }));
  }

  function handleInterferenciaExternaFalse(e) {
    e.preventDefault();
    setInterferenciaExterna(false);
    setErrorsForm(state => ({
      ...state,
      interferenciaExterna: false,
    }));
  }

  function handleCaptacaoSomDiretoTrue(e) {
    e.preventDefault();
    setCaptacaoSomDireto(true);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleCaptacaoSomDiretoFalse(e) {
    e.preventDefault();
    setCaptacaoSomDireto(false);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleSomFilmagemTrue(e) {
    e.preventDefault(e);
    setSomFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleSomFilmagemFalse(e) {
    e.preventDefault(e);
    setSomFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleEfeitosEspeciaisTrue(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(true);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleEfeitosEspeciaisFalse(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(false);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleReservasVagasTrue(e) {
    e.preventDefault(e);
    setReservasVagas(true);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleReservasVagasFalse(e) {
    e.preventDefault(e);
    setReservasVagas(false);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleMultiSelectSomFilmagem(e) {
    setShowOutroSomFilmagemField(!!e?.find(x => x.label === 'outro'));
    setShowDescrSomFilmagemField(
      e?.some(equip => equip.label?.includes('descrever')) || false,
    );
    setFormData(state => ({
      ...state,
      ld_som_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function renderForm() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                {!isAdminRequest && (
                  <>
                    <LocacaoDocs
                      excludes={[
                        'precosFilmagensEquipamentosPublicos',
                        'precosFilmagensVia',
                      ]}
                    />
                  </>
                )}

                <BoxHeader>
                  <span className="boxText">Informações gerais</span>
                </BoxHeader>
                <Wrap>
                  <Textarea
                    name="form_info"
                    rows="4"
                    value="A São Paulo Film Commission é responsável por autorizar projeções exclusivamente
                    realizadas e vinculadas às filmagens. Para projeções que ficarão expostas por tempo
                    superior ao período autorizado para filmagem, ou quando não houver filmagens
                    atreladas, a produção deve solicitar diretamente à CPPU (Comissão de Proteção à
                    Paisagem Urbana).&#13;&#13;Este formulário é unicamente para solicitações de projeções externas com
                    estruturas internas, isto é, sem ocupação e/ou intervenção em espaços públicos
                    para montagem de infraestrutura. Caso a produção necessite utilizar espaços
                    públicos, deverá ser solicitar no formulário “Utilização de Vias para Filmagens ou
                    Apoio às Filmagens”.&#13;&#13;É necessário que a produção solicite autorização dos responsáveis pelo
                    imóvel/edificação no qual o conteúdo será projetado."
                    disabled
                  />
                </Wrap>

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={dadoState?.viewData && true}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <Input
                    type="text"
                    name="address_local"
                    label="Endereço do local que será realizada a projeção"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData && responseLocation?.address_local
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="number"
                    name="number_local"
                    label="Número"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData && responseLocation?.number_local
                    }
                    disabled={dadoState?.viewData && true}
                  />

                  <Input
                    type="text"
                    name="neighborhood_local"
                    label="Bairro"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.neighborhood_local
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="text"
                    name="address_montagem"
                    label="Endereço que será montada a estrutura interna para projeção:"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData && responseLocation?.address_montagem
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="number"
                    name="number_montagem"
                    label="Número"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData && responseLocation?.number_montagem
                    }
                    disabled={dadoState?.viewData && true}
                  />

                  <Input
                    type="text"
                    name="neighborhood_montagem"
                    label="Bairro"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.neighborhood_montagem
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <DateFormMultiInput
                    label="Data(s)"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    dateKeyName="data_filmagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Horários(s)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={dadoState?.viewData}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="Incluir horário de montagem/desmontagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="number"
                    name="tamanho_equipe_tecnica"
                    label="Tamanho da Equipe Técnica"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.tamanho_equipe_tecnica
                    }
                    disabled={dadoState?.viewData && true}
                  />
                  <Input
                    type="number"
                    name="tamanho_do_elenco"
                    label="Tamanho do elenco"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState?.viewData && responseLocation?.tamanho_do_elenco
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>
                <Wrap>
                  <Textarea
                    type="text"
                    name="descricao_cena"
                    label="Descrição da Cena"
                    onChange={handleChange}
                    required
                    rows="3"
                    defaultValue={
                      dadoState?.viewData && responseLocation?.descricao_cena
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <BoxHeader>
                  <span className="boxText">Dados sobre a projeção</span>
                </BoxHeader>

                <Wrap>
                  <File
                    label="Informe qual o conteúdo projetado"
                    attach={dataProjecaoFile}
                    textFile="Nenhum arquivo selecionado"
                    name="projecao_file"
                    required
                    inputRef={projecaoFileRef}
                    onChange={handleFileChange}
                    onClick={handleProjecaoFile}
                    error={errorsForm.projecaoFile === true && 'Obrigatório'}
                    link={fileProjecao?.length > 0 && fileProjecao[0].url}
                    isDisabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="O conteúdo será projetado em algum equipamento público municipal (biblioteca,
                        centros culturais, galerias, etc)?"
                      onClickTrue={handleProjetadoEquipMunicipalTrue}
                      onClickFalse={handleProjetadoEquipMunicipalFalse}
                      selected={projetadoEquipMunicipal}
                      required
                      error={
                        errorsForm?.projetadoEquipMunicipal === true &&
                        'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                {projetadoEquipMunicipal && (
                  <Wrap>
                    <Textarea
                      type="text"
                      name="info_projetado_equip_municipal"
                      rows="2"
                      value="Caso o conteúdo seja projetado em equipamentos ou áreas públicas municipais, a produção
                      deverá preencher o formulário de locações da SPFilm correspondente ao local."
                      disabled
                    />
                  </Wrap>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá alguma interferência externa em locais públicos municipais além da
                      projeção?"
                      onClickTrue={handleInterferenciaExternaTrue}
                      onClickFalse={handleInterferenciaExternaFalse}
                      selected={interferenciaExterna}
                      required
                      error={
                        errorsForm?.interferenciaExterna === true &&
                        'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                {interferenciaExterna && (
                  <Wrap>
                    <Textarea
                      type="text"
                      name="info_interferencia_externa"
                      rows="2"
                      value="Caso seja necessária alguma interferência externa em vias conteúdo seja projetado em
                      equipamentos ou áreas públicas municipais, a produção deverá preencher o formulário de
                      locações da SPFilm correspondente ao local."
                      disabled
                    />
                  </Wrap>
                )}

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Captação de som direto?"
                      onClickTrue={handleCaptacaoSomDiretoTrue}
                      onClickFalse={handleCaptacaoSomDiretoFalse}
                      selected={captacaoSomDireto}
                      required
                      error={
                        errorsForm?.captacaoSomDireto === true && 'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá som na filmagem?"
                      onClickTrue={handleSomFilmagemTrue}
                      onClickFalse={handleSomFilmagemFalse}
                      selected={somFilmagem}
                      required
                      error={errorsForm?.somFilmagem === true && 'Obrigatório'}
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={somFilmagem}>
                  <Wrap>
                    <LimiterInputWidth w75>
                      <Select
                        name="ld_som_filmagem"
                        label="Sons de Filmagem"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        required
                        options={somFilmagemSelectList}
                        onChange={handleMultiSelectSomFilmagem}
                        isMulti
                        isDisabled={dadoState?.viewData && true}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <ConditionalContainer show={showOutroSomFilmagemField}>
                    <Wrap>
                      <Input
                        type="text"
                        name="outro_som_filmagem"
                        label="Outro Som de Filmagem"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.outro_som_filmagem
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </ConditionalContainer>

                  <ConditionalContainer show={showDescrSomFilmagemField}>
                    <Wrap>
                      <Textarea
                        label="Descreva a(s) característica(s)"
                        required
                        name="descricoes_som_filmagem"
                        onChange={handleChange}
                        rows="3"
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.descricoes_som_filmagem
                        }
                        disabled={dadoState?.viewData && true}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Efeitos Especiais"
                      onClickTrue={handleEfeitosEspeciaisTrue}
                      onClickFalse={handleEfeitosEspeciaisFalse}
                      selected={efeitosEspeciais}
                      required
                      error={
                        errorsForm?.efeitosEspeciais === true && 'Obrigatório'
                      }
                      isDisabled={dadoState?.viewData && true}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={efeitosEspeciais}>
                  <Wrap>
                    <Textarea
                      label="Quais"
                      required
                      name="descr_efeitos"
                      onChange={handleChange}
                      rows="3"
                      defaultValue={
                        dadoState?.viewData && responseLocation?.descr_efeitos
                      }
                      disabled={dadoState?.viewData && true}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label=" Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData && responseLocation?.especificidades
                    }
                    disabled={dadoState?.viewData && true}
                  />
                </Wrap>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && reservasVagas ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="reservas_vagas_locacao"
                          label="Reservas de Vagas"
                          placeholder="Selecione"
                          options={responseLocation?.locacao_via_externa?.map(
                            ({
                              uuid,
                              address,
                              number,
                              neighborhood,
                              endpoint,
                            }) => ({
                              label: `${address}, ${number}, ${neighborhood}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('reservas_vagas_locacao', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {formData?.reservas_vagas_locacao && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            viewData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.reservas_vagas_locacao?.uuid,
                              endpoint: 'reservas-vagas-vinculado',
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de reserva de vagas em via próxima?"
                      onClickTrue={handleReservasVagasTrue}
                      onClickFalse={handleReservasVagasFalse}
                      selected={reservasVagas}
                      required
                      isDisabled={dadoState?.viewData && true}
                      error={errorsForm.reservasVagas === true && 'Obrigatório'}
                    />
                  )}
                </Wrap>

                {responseLocation?.documents?.length > 0 && isAdminRequest && (
                  <Wrap>
                    <Documents
                      documents={responseLocation?.documents}
                      uuid={responseLocation.uuid}
                    />
                  </Wrap>
                )}

                {responseLocation?.created_at && isAdminRequest && (
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="created_at"
                        label="Data de criação"
                        defaultValue={format(
                          new Date(responseLocation?.created_at),
                          'dd/MM/yyyy HH:mm',
                        )}
                        disabled
                      />
                    </LimiterInputWidth>
                  </Wrap>
                )}
              </WhiteBox>

              <Chat
                uuid={responseLocation?.uuid}
                chatKeyFilter="locacao_projecao"
                locacaoNameView={responseLocation?.name_view}
              />

              {responseLocation?.valor_locacao && isAdminRequest && (
                <ValorLocacao
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.valor_locacao}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ModalidadePagamento
                  locacaoEndpoint={dadoState?.viewData?.endpoint}
                  locacaoUuid={responseLocation?.uuid}
                  viewData={responseLocation?.modalidade_pagamento}
                />
              )}

              {responseLocation && isAdminRequest && (
                <WorkStatusSwitch
                  data={{
                    entity: 'locacao-projecao',
                    uuid: responseLocation?.uuid,
                  }}
                />
              )}

              {responseLocation && isAdminRequest && (
                <ObservacoesChat workUuid={responseLocation?.work_uuid} />
              )}

              {dadoState?.uuid && !dadoState?.viewData && (
                <Button
                  type="submit"
                  btType="#26A2B1"
                  label="Enviar"
                  full
                  width="140px"
                  loading={loading}
                  disabled={loading}
                  style={{ position: 'relative', bottom: '35px' }}
                />
              )}

              {Object.keys(responseLocation || {}).length > 0 && (
                <Link
                  to={{
                    pathname: 'redireciona-endpoint',
                    search: `query=${encodeURIComponent(
                      CryptoJS.AES.encrypt(
                        JSON.stringify({
                          is_admin: isAdminRequest,
                          uuid: responseLocation?.uuid,
                          endpoint: responseLocation?.endpoint,
                          mode: 'edit',
                          nome_projeto: responseLocation?.titulo_obra,
                        }),
                        tenancy_env('REACT_APP_API_BASE_URL'),
                      ).toString(),
                    )}`,
                  }}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    width: '100%',
                    maxWidth: '140px',
                    position: 'relative',
                    bottom: '35px',
                  }}
                >
                  <ActionButton
                    style={{
                      backgroundColor: '#828282',
                    }}
                  >
                    Editar
                  </ActionButton>
                </Link>
              )}
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Projeções em Logradouro Público com Estrutura Interna</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Projeções
                em Logradouro Público com Estrutura Interna
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderForm()}

      <PageFooter>
       <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
