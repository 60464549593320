import React, { useRef, useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { BsFilterLeft, BsFilter } from 'react-icons/bs';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FiLoader } from 'react-icons/fi';
import { CheckboxHtml, Input, Select } from '~/components/Forms';

import {
  PageContainer,
  TableHeader,
  LimiterInputWidth,
} from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, Options, WrapButtons } from './styles';

import ListaPageObras from '~/components/Admin/Home/ListaPageObras';
import ApiClient from '~/utils/ApiClient';
import buildQueryPackageFilters from '~/functions/buildQueryPackageFilters';
import WhiteBox from '~/components/WhiteBox';
import Option from '~/components/Option';
import { showToastMessage } from '~/services/notification';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

function Obra() {
  const [item, setItem] = useState(0);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [responsavelList, setResponsavelList] = useState([]);
  const [workList, setWorkList] = useState([]);
  const [workStatusList, setWorkStatusList] = useState([
    { label: 'Carregando...', value: '' },
  ]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState('');
  const [searchFormData, setSearchFormData] = useState({});

  const formRef = useRef();

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  const workStatusOrderedList = [
    { label: 'Novas Obras', value: '' },
    { label: 'Em andamento', value: '' },
    { label: 'Cancelados', value: '' },
    { label: 'Finalizados', value: 'Finalizado' },
    { label: 'Pendentes', value: 'Pendente' },
    { label: 'Negados', value: '' },
    { label: 'Inválidas', value: 'Inválida' },
    { label: 'Outra adm.', value: 'Outra Administração' },
    { label: 'Excluídos', value: '' },
    { label: 'Carta de Apoio', value: '' },
  ];

  function handleResetSearchFields() {
    setPage(1);
    setFilters('');
    setSearchFormData({
      created_at: { value: '' },
      protocol: { value: '' },
      obra_name: { value: '' },
      produtora_name: { value: '' },
      priority: { value: '' },
    });
    formRef.current.setFieldValue('resp_atendimento', {
      label: 'Selecione',
      value: '',
    });
  }

  const renderResponsavelSelect = useCallback(async () => {
    try {
      const result = await ApiClient.doRequest({
        uri: 'user-responsavel',
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setResponsavelList(
        result.data.data.map(({ uuid, user_personal_data }) => ({
          value: uuid,
          label: user_personal_data?.name,
        })),
      );
    } catch (error) {
      showToastMessage({
        type: 'error',
        text: `Erro: ${error.message}`,
      });
    }
  }, []);

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    setSearchFormData(state => ({
      ...state,
      [name]: {
        value: type === 'checkbox' ? checked : value,
        filterName: e.target?.getAttribute('filterName'),
        filterOperator: e.target?.getAttribute('filterOperator'),
      },
    }));
  }

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          obra_name: Yup.string().test(
            'nome-obra',
            'Informe ao menos 2 caracteres para buscar',
            value => !(value && value?.length < 2),
          ),
          produtora_name: Yup.string().test(
            'nome-produtora',
            'Informe ao menos 2 caracteres para buscar',
            value => !(value && value?.length < 2),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setPage(1);
        setFilters(buildQueryPackageFilters(searchFormData));
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [searchFormData],
  );

  const renderTabs = useCallback(async () => {
    try {
      setLoading(true);

      const result = await ApiClient.doRequest({
        uri: `work-status`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const workStatusResp = result.data.data;

      const serializedWorkStatusList = workStatusOrderedList.map(
        itemwso =>
          workStatusResp
            .map(itemws => {
              if ((itemwso?.value || itemwso.label) === itemws.name) {
                return {
                  value: itemwso?.value,
                  label: itemwso.label,
                  uuid: itemws.uuid,
                };
              }
              return null;
            })
            .filter(itemws => itemws !== null)[0],
      );

      setWorkStatusList(serializedWorkStatusList);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const load = useCallback(async () => {
    try {
      setLoading(true);

      const status = workStatusList[item]?.value || workStatusList[item]?.label;

      const result = await ApiClient.doRequest({
        uri: `work?order[id]=desc&filters[][work_status.name@equal]=${status}&page=${page}${filters}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setTotPage(result.data.meta.pagination.total_pages);

      setWorkList(result.data.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [page, workStatusList, item, filters]);

  function renderList() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && !workList.length) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {workList.map(lista => (
          <ListaPageObras
            key={lista.id}
            workStatusList={workStatusList}
            data={lista}
            loadWorks={load}
            // onDelete={() => handleDelete(lista.id)}
          />
        ))}
      </>
    );
  }

  useEffect(() => {
    renderTabs();
  }, [renderTabs]);

  useEffect(() => {
    load();
    renderResponsavelSelect();
  }, [load, renderResponsavelSelect]);

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Options>
          {workStatusList.map(workStatus => (
            <Option
              active={item === workStatusList.indexOf(workStatus)}
              onClick={() => {
                setPage(1);
                setItem(workStatusList.indexOf(workStatus));
              }}
            >
              {workStatus.label}
            </Option>
          ))}
        </Options>
        <WhiteBox column maxHeight>
          {hiddenButton === true && (
            <span tooltip="Exibir filtro!">
              <button
                type="button"
                onClick={() => {
                  setShow(true);
                  setHiddenButton(false);
                }}
              >
                <BsFilterLeft color="#000" size={24} />
              </button>
            </span>
          )}
          {show === true && (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Wrap>
                <Input
                  type="text"
                  name="protocol"
                  label="N.º da Obra"
                  value={searchFormData.protocol?.value}
                  onChange={handleChange}
                  filterOperator="equal"
                />
                <Input
                  type="date"
                  name="created_at"
                  label="Data de Criação"
                  value={searchFormData.created_at?.value}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  name="obra_name"
                  label="Nome da Obra"
                  value={searchFormData.obra_name?.value}
                  onChange={handleChange}
                  filterName="titulo_obra"
                />
                <Select
                  name="resp_atendimento"
                  label="Responsável por atendimento"
                  placeholder="Selecione"
                  options={responsavelList}
                  style={selectCustomStyles}
                  filterName="responsavel_user.user_personal_data.name"
                  filterOperator="equal"
                  onChange={e => {
                    const fieldRef =
                      formRef.current.getFieldRef('resp_atendimento');

                    setSearchFormData(state => ({
                      ...state,
                      [fieldRef.props.name]: {
                        value: e.label === 'Selecione' ? '' : e.label,
                        filterName: fieldRef.props.filterName,
                        filterOperator: fieldRef.props.filterOperator,
                      },
                    }));
                  }}
                />
              </Wrap>
              <Wrap>
                <LimiterInputWidth w25>
                  <Input
                    type="text"
                    name="produtora_name"
                    label="Nome da Produtora"
                    value={searchFormData.produtora_name?.value}
                    onChange={handleChange}
                    filterName="branch.fantasy_name"
                  />
                </LimiterInputWidth>
                <LimiterInputWidth w25>
                  <CheckboxHtml
                    name="priority"
                    filterOperator="equal"
                    label="Prioridade?"
                    onChange={handleChange}
                    value={!!searchFormData.priority?.value}
                    checked={!!searchFormData.priority?.value}
                  />
                </LimiterInputWidth>
              </Wrap>
              <Wrap>
                <WrapButtons>
                  <Button
                    type="submit"
                    btType="#26A2B1"
                    label="Buscar"
                    full
                    width="170px"
                  />
                  <Button
                    btType="#707683"
                    onClick={handleResetSearchFields}
                    label="Limpar"
                    full
                    width="100px"
                  />
                  <span tooltip="Ocultar filtro">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false);
                        setHiddenButton(true);
                      }}
                    >
                      <BsFilter color="#000" size={24} />
                    </button>
                  </span>
                </WrapButtons>
              </Wrap>
            </Form>
          )}
          <table>
            <TableHeader>
              <tr>
                <th>Data/Hora Criação</th>
                <th>Nº da Obra</th>
                <th>Nome da Obra</th>
                <th>Produtora</th>
                <th>Resp. Obra</th>
                <th style={{ justifyContent: 'center' }}>Total Locações</th>
                <th style={{ justifyContent: 'center' }}>Resp. Atendimento</th>
                <th style={{ justifyContent: 'center' }}>Ações</th>
              </tr>
            </TableHeader>
            <WhiteBox column aCenter>
              <tbody>{renderList()}</tbody>
              <BasicPagination />
            </WhiteBox>
          </table>
        </WhiteBox>
      </Container>
    </PageContainer>
  );
}

export default withRouter(Obra);
