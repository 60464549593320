import React, { useRef, useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import omitEmpty from 'omit-empty';
import { Form } from '@unform/web';
import { BiLoaderAlt } from 'react-icons/bi';

import {
  LimiterInputWidth,
  PageContainer,
  TooltipContainer,
} from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, TitleForm, MainLoading } from './styles';

import WhiteBox from '~/components/WhiteBox';
import { File, Input } from '~/components/Forms';
import { ThemeContext } from '~/styles/ThemeProvider';
import { showModalMessage } from '~/services/notification';
import getImageDimensions from '~/functions/getImageDimensions';
import { formatFileSize } from '~/functions/file';

const Tema = () => {
  const { theme, updateTheme, loading: tLoading } = useContext(ThemeContext);

  const formRef = useRef();
  const systemBackgroundFileRef = useRef(null);
  const systemLogoFileRef = useRef(null);

  const [formData, setFormData] = useState({});
  const [dataSystemBackgroundFile, setDataSystemBackgroundFile] =
    useState(null);
  const [dataSystemLogoFile, setDataSystemLogoFile] = useState(null);

  useEffect(() => {
    setFormData(theme);
    formRef.current.setFieldValue('system_name', theme.system_name);
    formRef.current.setFieldValue('side_bar_color', theme.side_bar_color);
    formRef.current.setFieldValue(
      'horizontal_bar_color',
      theme.horizontal_bar_color,
    );
    formRef.current.setFieldValue(
      'background_page_color',
      theme.background_page_color,
    );
    formRef.current.setFieldValue('text_color', '#0078f0');
  }, [theme]);

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  function handleChange(name, value) {
    setFormData(state => ({ ...state, [name]: value }));
  }

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;

    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      await showModalMessage({
        type: 'error',
        text: 'Formato de Imagem não permitido!',
      });
      return;
    }

    try {
      const { width, height } = await getImageDimensions(
        URL.createObjectURL(file),
      );

      switch (name) {
        case 'system_background_file':
          // 1920x945 | <= 2.9mb
          if (!(width === 1920 && height === 945 && file.size / 1024 <= 2900)) {
            await showModalMessage({
              type: 'error',
              text: 'A imagem não respeita as restrições de upload do sistema. Por favor, consulte os requisitos no ícone de informação.',
            });
            return;
          }
          break;
        case 'system_logo_file':
          // 261x91 | <= 1MB
          if (
            !(width === 261 && height === 91 && formatFileSize(file.size) <= 10)
          ) {
            await showModalMessage({
              type: 'error',
              text: 'A imagem não respeita as restrições de upload do sistema. Por favor, consulte os requisitos no ícone de informação.',
            });
            return;
          }
          break;

        default:
          await showModalMessage({
            type: 'error',
            text: 'Tipo de Imagem não definida no sistema.',
          });
          return;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'A imagem informada não pôde ser processada. Por favor, tente novamente ou informe uma outra imagem.',
      });
      return;
    }

    const base64 = await convertBase64(file);

    if (name === 'system_background_file') {
      setDataSystemBackgroundFile(file);
    }

    if (name === 'system_logo_file') {
      setDataSystemLogoFile(file);
    }

    setFormData(state => ({ ...state, [name]: base64.split('base64,')[1] }));
  }

  async function handleValidationFields() {
    try {
      formRef.current.setErrors({});

      const defaultMsg = 'Obrigatório';
      const schema = Yup.object().shape({
        system_name: Yup.string().typeError(defaultMsg).required(defaultMsg),
      });

      await schema.validate(formData, {
        abortEarly: false,
      });

      return true;
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Preencha os campos obrigatórios!',
      });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      return false;
    }
  }

  async function handleSubmit() {
    if (!(await handleValidationFields())) {
      return;
    }

    const body = omitEmpty({
      ..._.omit(formData, [
        'system_background',
        'system_logo',
        'system_background_file',
        'system_logo_file',
      ]),
      background_image_base64: formData.system_background_file,
      background_image_base64_uuid: formData.system_background_file
        ? formData.system_background.uuid
        : '',
      logo_base64: formData.system_logo_file,
      logo_base64_uuid: formData.system_logo_file
        ? formData.system_logo.uuid
        : '',
    });

    if (await updateTheme(body)) {
      setDataSystemBackgroundFile(null);
      setDataSystemLogoFile(null);
    }
  }

  return (
    <>
      {tLoading && (
        <MainLoading loading={tLoading}>
          <h3>Processando Informações</h3>
          <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
        </MainLoading>
      )}

      <PageContainer>
        <Aside />
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Wrap>
              <TitleForm>
                <h4>Tema</h4>
              </TitleForm>
            </Wrap>

            <WhiteBox column>
              <Wrap>
                <LimiterInputWidth w50>
                  <Input
                    type="text"
                    name="system_name"
                    label="Nome do sistema"
                    required
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                  />
                </LimiterInputWidth>
              </Wrap>
              <Wrap>
                <LimiterInputWidth w50>
                  <Input
                    type="color"
                    name="side_bar_color"
                    label="Cor barra menu lateral (admin)"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                  />
                </LimiterInputWidth>
                <LimiterInputWidth w50>
                  <Input
                    type="color"
                    name="horizontal_bar_color"
                    label="Cor barra horizontal (sistema)"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                  />
                </LimiterInputWidth>
                <LimiterInputWidth w50>
                  <Input
                    type="color"
                    name="background_page_color"
                    label="Cor fundo caixa (sistema)"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                  />
                </LimiterInputWidth>
                <LimiterInputWidth w50>
                  <Input
                    type="color"
                    name="text_color"
                    label="Cor texto menu lateral (admin)"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(name, value);
                    }}
                  />
                </LimiterInputWidth>
              </Wrap>

              <Wrap>
                <TooltipContainer value="Largura: 1920px x Altura: 945px. Tamanho: até 2.9mb">
                  <File
                    label="Imagem de fundo"
                    attach={dataSystemBackgroundFile}
                    textFile="Nenhum arquivo selecionado"
                    name="system_background_file"
                    inputRef={systemBackgroundFileRef}
                    onChange={handleFileChange}
                    onClick={() => systemBackgroundFileRef.current.click()}
                    link={theme.system_background.url}
                    acceptFiles=".png,.jpg,.jpeg"
                  />
                </TooltipContainer>
              </Wrap>

              <Wrap>
                <TooltipContainer value="Largura: 261px x Altura: 91px. Tamanho: até 1mb">
                  <File
                    label="Logo do sistema"
                    attach={dataSystemLogoFile}
                    textFile="Nenhum arquivo selecionado"
                    name="system_logo_file"
                    inputRef={systemLogoFileRef}
                    onChange={handleFileChange}
                    onClick={() => systemLogoFileRef.current.click()}
                    link={theme.system_logo.url}
                    acceptFiles=".png,.jpg,.jpeg"
                    questionTooltip=""
                  />
                </TooltipContainer>
              </Wrap>

              <Button
                type="submit"
                btType="#26A2B1"
                label="Salvar"
                full
                width="140px"
                loading={tLoading}
              />
            </WhiteBox>
          </Form>
        </Container>
      </PageContainer>
    </>
  );
};

export default withRouter(Tema);
