import diff from 'diff-arrays-of-objects';

export default function diffSitesCatalogo({ sitesGravados, sitesInput }) {
  // if equals
  if (
    JSON.stringify(sitesGravados.sort()) === JSON.stringify(sitesInput.sort())
  ) {
    return null;
  }

  const results = diff(sitesGravados, sitesInput, 'uuid');
  const { updated, added, removed } = results;

  const sitesDiff = added.concat(
    updated,
    removed?.map(site => ({ uuid: site.uuid, url: '' })),
  );

  return sitesDiff;
}
