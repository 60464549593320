/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { AiFillExclamationCircle } from 'react-icons/ai';

import { BiLoaderAlt } from 'react-icons/bi';

import {
  BoxBody,
  CardChoice,
  ChoiceBox,
  LogOut,
  RadioContainer,
  ProfilePhoto,
  MainLoading,
} from './styles';

import {
  BannerContent,
  TextBanner,
  BoxHeader,
  PageContent,
  PageFooter,
} from '~/pages/Usuario/styles';

import { PageContainer } from '~/styles/components';

import userIcon from '~/assets/images/usuario/user.png';
import paperIcon from '~/assets/images/usuario/paper.png';
import { showToastMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';

import { useAuth } from '~/hooks/auth';
import { useCurrentUser } from '~/hooks/currentUser';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

const Catalogo = () => {
  const history = useHistory();
  const { user, signOut } = useAuth();
  const { addUser } = useCurrentUser();

  const [branch, setBranch] = useState([]);
  const [loading, setLoading] = useState(false);

  function logOut() {
    signOut();
  }

  //  if (name === 'Pessoa Jurídico') {}
  //  const rolesValidator = user.roles?.some(roles => roles === 'Pessoa Jurídico');

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        await ApiClient.doRequest({
          uri: 'branch',
          method: ApiClient.GET,
          expectedStatus: 200,
        }).then(response => setBranch(response.data.data || []));
      } catch (e) {
        showToastMessage({
          type: 'error',
          text: 'Erro ao buscar Pessoas Jurídica',
        });
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  async function handleUser(data) {
    await addUser(data);

    history.push({ pathname: 'perfil', state: { trigger: 'catalogo' } });
  }

  const temPessoaFisica = user.roles?.some(role => role === 'Pessoa Fisica');
  // const temPessoaJuridica = user.roles?.some(
  //   role => role === 'Pessoa Juridico',
  // );

  return (
    <>
      {loading && (
        <MainLoading loading={loading}>
          <h3>Processando Informações</h3>
          <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
        </MainLoading>
      )}

      <Header>
        <LogoIcon />
        <LogOut onClick={() => logOut()}>
          <FiLogOut />
        </LogOut>
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Catálogo de Profissionais</h1>
          </TextBanner>
        </BannerContent>
      </BannerHeader>
      <PageContainer>
        <PageContent>
          <WhiteBox column aCenter>
            <BoxBody>
              <BoxHeader>
                <span className="boxText">Cadastre novos perfis:</span>
                <span
                  tooltip="
                  “Cadastro Pessoa Física”: registro individual de profissionais/estudantes com ou sem CNPJ.
                  Podem ser responsáveis por uma produção ou somente fazer parte dela.

                  “Cadastro Pessoa Jurídica” registro de uma produtora/empresa responsável por alguma
                  obra.
                  "
                >
                  <AiFillExclamationCircle />
                </span>
              </BoxHeader>
              <ChoiceBox style={{ marginBottom: '32px' }}>
                {!loading && !temPessoaFisica && (
                  <CardChoice
                    to={{
                      pathname: '/cadastro-pessoa-fisica',
                      state: { trigger: 'catalogo' },
                    }}
                  >
                    <span>
                      <img src={userIcon} alt="user ico" />
                    </span>
                    <h1>
                      Cadastro
                      <br />
                      Pessoa Física
                    </h1>
                  </CardChoice>
                )}

                {!loading && (
                  <CardChoice
                    to={{
                      pathname: '/catalogo/cadastro-pj',
                      state: { trigger: 'catalogo' },
                    }}
                  >
                    <span>
                      <img src={paperIcon} alt="paper ico" />
                    </span>
                    <h1>
                      Cadastro
                      <br />
                      Pessoa Jurídica
                    </h1>
                  </CardChoice>
                )}
                <CardChoice to="/catalogo/profissional-servico" blue>
                  <span>
                    <img src={userIcon} alt="paper ico" />
                  </span>
                  <h1>Catálogo de perfis</h1>
                </CardChoice>
              </ChoiceBox>
              {user.roles?.map(itemRoles => {
                if (itemRoles === 'Pessoa Fisica') {
                  return (
                    <div onClick={() => handleUser(user)}>
                      <RadioContainer>
                        <ProfilePhoto>
                          <img
                            src={
                              clientResources.images.system.companyProfileLogo
                            }
                            alt="user profile"
                          />
                        </ProfilePhoto>
                        <h4>{user?.user_personal_data?.name}</h4>
                      </RadioContainer>
                    </div>
                  );
                }
                if (itemRoles === 'Pessoa Jurídico') {
                  const branchs = [];
                  branch.map(itemBranch => {
                    branchs.push(
                      <div
                        key={itemBranch.uuid}
                        onClick={() => handleUser(itemBranch)}
                      >
                        <RadioContainer>
                          <ProfilePhoto>
                            <img
                              src={
                                clientResources.images.system.companyProfileLogo
                              }
                              alt="user profile"
                            />
                          </ProfilePhoto>
                          <h4>{itemBranch.fantasy_name}</h4>
                        </RadioContainer>
                      </div>,
                    );
                  });
                  return branchs;
                }
                return false;
              })}
            </BoxBody>
          </WhiteBox>
        </PageContent>
      </PageContainer>
      <PageFooter>
        <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
};

export default Catalogo;
