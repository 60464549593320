import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FaPen, FaTrash } from 'react-icons/fa';
import ModalGerenciamentoEquipe from '../ModalGerenciamentoEquipe';
import RolesActions from '~/store/ducks/userRoles';
import ApiClient from '~/utils/ApiClient';
import { showModalConfirm, showModalMessage } from '~/services/notification';

function ListPageGerenciamentoEquipes({ data, load }) {
  const dispatch = useDispatch();
  const { data: rolesUser } = useSelector(state => state.roles);
  const [openModalGerenEquipe, setOpenModalGerenEquipe] = useState(false);

  const excluirUsuario = async userUuid => {
    let confirm = false;

    await showModalConfirm({
      text: 'Este usuário selecionado será desvinculado da equipe. Ok?',
      callback: confirmed => {
        confirm = confirmed;
      },
    });

    if (!confirm) {
      return false;
    }

    try {
      await ApiClient.doRequest({
        uri: `user/${userUuid}`,
        method: ApiClient.DELETE,
        expectedStatus: 201,
      });

      await showModalMessage({
        type: 'success',
        text: 'Exclusão efetuada com sucesso!',
      });
      return true;
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao tentar excluir usuário!',
      });
      return false;
    }
  };

  const roles = useCallback(() => {
    dispatch(RolesActions.request());
  }, [dispatch]);

  useEffect(() => {
    roles();
  }, [roles]);

  const userRoles = rolesUser?.some(role => role.name === 'Administrador');

  return (
    <>
      <tr>
        <td>{data && data?.user_personal_data?.name}</td>
        <td>{data && data?.email}</td>
        <td>
          {(data && data?.roles[0]?.name) || (data && data?.roles[1]?.name)}
        </td>
        {userRoles && (
          <td className="buttons" style={{ justifyContent: 'space-evenly' }}>
            <button type="button" onClick={() => setOpenModalGerenEquipe(true)}>
              <FaPen color="#4f4f4f" size={20} />
            </button>
            <button
              type="button"
              onClick={async () => {
                if (await excluirUsuario(data?.uuid)) {
                  load();
                }
              }}
            >
              <FaTrash color="#4f4f4f" size={20} />
            </button>
          </td>
        )}
      </tr>
      <ModalGerenciamentoEquipe
        data={data}
        openModal={openModalGerenEquipe}
        closeModal={() => setOpenModalGerenEquipe(false)}
        load={load}
      />
    </>
  );
}

export default withRouter(ListPageGerenciamentoEquipes);
