import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { BiLoaderAlt } from 'react-icons/bi';
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineClose,
  AiOutlineCheck,
} from 'react-icons/ai';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

import omitEmpty from 'omit-empty';
import ApiClient from '~/utils/ApiClient';

import Chat from '~/components/Chat/chat';

import {
  Input,
  Select,
  Textarea,
  ChoiceButtonLocacao,
  File,
  FormChoiceButton,
  InputNoForm,
} from '~/components/Forms';

import {
  PageContainer,
  ActionInputMulti,
  MultiInputContainer,
  ConditionalContainer,
  LimiterInputWidth,
  ContainerMultiInputActions,
  TooltipContainer,
} from '~/styles/components';

import { NavBarHeader, ModalPorteFilmagem } from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { Wrap, MainLoading } from './styles';

import ComplementoNumeroListActions from '~/store/ducks/complemento-numero-list';
import EfeitoEspecialListActions from '~/store/ducks/efeito-especial-list';
import SomFilmagemListActions from '~/store/ducks/som-filmagem-list';
import EquipamentoFilmagemListActions from '~/store/ducks/equipamento-filmagem-list';
import ImpactoListActions from '~/store/ducks/impacto-list';
import FinalidadeBaseListActions from '~/store/ducks/finalidade-base-apoio-list';
import LocalGeradorListActions from '~/store/ducks/local-gerador-list';

import { showModalMessage } from '~/services/notification';

import Button from '~/components/Button';
import diffDatasFilmagem from '~/utils/functions/diffDatasFilmagem';
import diffHoursFilmagem from '~/utils/functions/diffHoursFilmagem';
import { useList } from '~/hooks/useFunction';
import LogoIcon from '~/components/LogoIcon';
import {
  confirmEditDateHour,
  getBrowserTransformDate,
  validDateArray,
  validHourArray,
} from '~/pages/Usuario/Locacoes/functions/date-hour-utils';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';
import { HourFormMultiInput } from '~/components/Forms/HourFormMultiInput';
import { DateFormMultiInput } from '~/components/Forms/DateFormMultiInput';

export const selectCustomStyles = {
  option: provided => ({
    ...provided,
    borderBottom: `1px #ddd solid`,
    color: '#828282',
    backgroundColor: '#f2f2f2',
    padding: 16,
    fontFamily: 'PoppinsSemiBold',
    fontSize: 14,
    boxSizing: 'border-box',
    outline: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 16,
    fontSize: 14,
    margin: 0,
  }),
  container: provided => ({
    ...provided,
    padding: 0,
    marginBottom: 15,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    padding: 0,
    fontFamily: 'PoppinsSemiBold',
  }),
  control: provided => ({
    ...provided,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#d0d0d0',
    borderRadius: 2,
    boxSizing: 'border-box',
    backgroundColor: '#f2f2f2',
    boxShadow: '0 3px 12px rgba(0, 0, 0, 0.075)',
    height: 48,
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 16,
    fontSize: 14,
    color: '#828282',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    fontSize: 10,
    color: '#828282',
  }),
};

const optionsTipoBloqueio = [
  {
    value: 'todas_as_faixas',
    label: 'De todas as faixas',
  },
  {
    value: 'algumas_faixas',
    label: 'De algumas faixas',
  },
];

export default function UtilizacaoViaEdit() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const dadoState = location.state;
  const isAdminRequest = dadoState?.isAdminRequest || false;

  // refs
  const formRef = useRef();
  const croquiFileRef = useRef(null);
  const relatorioArteFileRef = useRef(null);
  const conteudoProjecaoRef = useRef(null);
  const iluminacaoFileRef = useRef(null);

  // states
  const [responseLocation, setResponseLocation] = useState();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [errorsForm, setErrorsForm] = useState({});
  const [openModalPorteFilmagem, setOpenModalPorteFilmagem] = useState(false);
  const [baseApoio, setBaseApoio] = useState(null);

  // states multi input
  const [horasEntradaInputs, setHorasEntradaInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasEntradaInputs, setDatasEntradaInputs] = useState([
    { data_entrada: '' },
  ]);
  const [horasSaidaInputs, setHorasSaidaInputs] = useState([
    { inicio: '', fim: '' },
  ]);

  const [datasSaidaInputs, setDatasSaidaInputs] = useState([
    { data_saida: '' },
  ]);
  const [horasFilmagemInputs, setHorasFilmagemInputs] = useState([
    { inicio: '', fim: '' },
  ]);
  const [datasFilmagemInputs, setDatasFilmagemInputs] = useState([
    { data_filmagem: '' },
  ]);

  // states choices
  const [associarLocacao, setAssociarLocacao] = useState(null);
  const [localFilmagem, setLocalFilmagem] = useState(null);
  const [tipoFilmagem, setTipoFilmagem] = useState(null);
  const [tipoBloqueioAlgumasFaixas, setTipoBloqueioAlgumasFaixas] =
    useState(null);
  const [filmagemExternaEm, setFilmagemExternaEm] = useState(null);
  const [intervencaoArte, setIntervencaoArte] = useState(null);
  const [gerador, setGerador] = useState(null);
  const [blimpado, setBlimpado] = useState(null);
  const [baseLocal, setBaseLocal] = useState(null);
  const [captacaoSomDireto, setCaptacaoSomDireto] = useState(null);
  const [somFilmagem, setSomFilmagem] = useState(null);
  const [drone, setDrone] = useState(null);
  const [efeitosEspeciais, setEfeitosEspeciais] = useState(null);
  const [projecao, setProjecao] = useState(null);
  const [projecaoLogo, setProjecaoLogo] = useState(null);
  const [desligarLuz, setDesligarLuz] = useState(null);
  const [reservasVagas, setReservasVagas] = useState(null);
  const [publicoPrivado, setPublicoPrivado] = useState(null);

  // select options
  const [filmagemInternaOpts, setFilmagemInternaOpts] = useState(null);
  const [filmagemExternaOpts, setFilmagemExternaOpts] = useState(null);
  const [finalidadeBloqueioOpts, setFinalidadeBloqueioOpts] = useState(null);

  // state files
  const [croquiFile, setCroquiFile] = useState(null);
  const [dataCroquiFile, setDataCroquiFile] = useState(null);
  const [dataRelatorioArteFile, setDataRelatorioArteFile] = useState(null);
  const [relatorioArteFile, setRelatorioArteFile] = useState(null);
  const [dataConteudoProjecaoFile, setDataConteudoProjecaoFile] =
    useState(null);
  const [conteudoProjecaoFile, setConteudoProjecaoFile] = useState(null);
  const [dataIluminacaoFile, setDataIluminacaoFile] = useState(null);
  const [iluminacaoFile, setIluminacaoFile] = useState(null);

  // state outro fields
  const [showFinalidadeBloqueio, setShowFinalidadeBloqueio] = useState(null);
  const [showDescrAltoImpactoLocalField, setShowDescrAltoImpactoLocalField] =
    useState(false);
  const [showOutroEquipamentoField, setShowOutroEquipamentoField] =
    useState(false);
  const [showOutroSomFilmagemField, setShowOutroSomFilmagemField] =
    useState(false);
  const [showOutroFinalidadeField, setShowOutroFinalidadeField] =
    useState(false);
  const [selectedFilmagemInternaOpt, setSelectedFilmagemInternaOpt] =
    useState(null);
  const [selectedFilmagemExternaOpt, setSelectedFilmagemExternaOpt] =
    useState(null);
  const [
    showDescrEquipamentoFilmagemField,
    setShowDescrEquipamentoFilmagemField,
  ] = useState(false);
  const [showDescrSomFilmagemField, setShowDescrSomFilmagemField] =
    useState(false);

  // selectors
  const utilizacaoAssociacaoList = useList({
    url: `utilizacao-associacao/${responseLocation?.work_uuid}?paginate=false`,
  });

  const { data: complementoNumeroSelectList } = useSelector(
    state => state.complementoNumeroList,
  );
  const { data: equipamentosSelectList } = useSelector(
    state => state.equipamentoFilmagemList,
  );
  const { data: somFilmagemSelectList } = useSelector(
    state => state.somFilmagemList,
  );
  const { data: efeitoEspecialSelectList } = useSelector(
    state => state.efeitoEspecialList,
  );
  const { data: impactoSelectList } = useSelector(state => state.impactoList);

  const { data: finalidadeSelectList } = useSelector(
    state => state.finalidadeBaseApoioList,
  );
  const { data: localGeradorSelectList } = useSelector(
    state => state.localGeradorList,
  );

  const filmagemInternaListFetchCB = useCallback(async () => {
    const resp = await ApiClient.doRequest({
      uri: `ld-tipo-interna`,
      method: ApiClient.GET,
      expectedStatus: 200,
    });

    const list = resp?.data?.data.map(({ uuid, name }) => ({
      value: uuid,
      label: name,
    }));

    setFilmagemInternaOpts(list);
  }, []);

  const filmagemExternaListFetchCB = useCallback(async () => {
    const resp = await ApiClient.doRequest({
      uri: `ld-tipo-externa`,
      method: ApiClient.GET,
      expectedStatus: 200,
    });

    const list = resp?.data?.data.map(({ uuid, name }) => ({
      value: uuid,
      label: name,
    }));

    setFilmagemExternaOpts(list);
  }, []);

  const finalidadeBloqueioListFetchCB = useCallback(async () => {
    const resp = await ApiClient.doRequest({
      uri: `ld-finalidade-bloqueio`,
      method: ApiClient.GET,
      expectedStatus: 200,
    });

    const list = resp?.data?.data.map(({ uuid, name }) => ({
      value: uuid,
      label: name,
    }));

    setFinalidadeBloqueioOpts(list);
  }, []);

  // effects
  useEffect(() => {
    dispatch(ImpactoListActions.showRequest());
    dispatch(ComplementoNumeroListActions.showRequest());
    dispatch(EquipamentoFilmagemListActions.showRequest());
    dispatch(SomFilmagemListActions.showRequest());
    dispatch(EfeitoEspecialListActions.showRequest());
    dispatch(FinalidadeBaseListActions.showRequest());
    dispatch(LocalGeradorListActions.showRequest());
    filmagemExternaListFetchCB();
    filmagemInternaListFetchCB();
    finalidadeBloqueioListFetchCB();
  }, [
    dispatch,
    filmagemExternaListFetchCB,
    filmagemInternaListFetchCB,
    finalidadeBloqueioListFetchCB,
  ]);

  // Busca dados da locação
  const visualizacaoDadosCB = useCallback(async () => {
    if (dadoState?.viewData) {
      setLoading(true);
      const resp = await ApiClient.doRequest({
        uri: `locacao-utilizacao-via/${dadoState?.viewData?.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const respLoc = resp?.data?.data;

      // set temp variables
      const blimpadoTmp =
        respLoc?.locacao_default.blimpado === null
          ? null
          : respLoc?.locacao_default.blimpado === 1;

      const projecaoLogoTmp =
        typeof respLoc?.locacao_default.porcentagem_projecao === 'number' ||
        typeof respLoc?.locacao_default.area_tempo_projeção === 'string';

      const publicoPrivadoTmp =
        respLoc?.locacao_default?.imovel_publico_privado === null
          ? null
          : respLoc?.locacao_default?.imovel_publico_privado === 1;

      const filmagemExternaEmTmp =
        respLoc?.filmagem_externa_em === null
          ? null
          : respLoc?.filmagem_externa_em === 1;

      const fileCroqui = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Croqui',
      );
      const fileArte = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Relatório de Arte',
      );
      const fileProjecao = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Projeção',
      );
      const fileLuz = respLoc?.locacao_default.files?.filter(
        file => file.name === 'Arquivo Iluminação',
      );

      // set choices buttons
      setBaseApoio(
        typeof respLoc?.base_address === 'string' &&
          typeof respLoc?.base_num === 'string',
      );
      setAssociarLocacao(Object.values(respLoc?.associada || {}).length > 0);
      setLocalFilmagem(!(typeof respLoc?.address_temp === 'string'));
      setTipoFilmagem(Object.values(respLoc?.ld_tipo_interna || {}).length > 0);
      setFilmagemExternaEm(filmagemExternaEmTmp);
      setIntervencaoArte(
        typeof respLoc?.locacao_default.desc_intervencao_arte === 'string' ||
          fileArte?.length > 0,
      );
      setBlimpado(blimpadoTmp);
      setGerador(
        blimpadoTmp !== null ||
          Object.values(respLoc?.ld_local_gerador || {}).length > 0,
      );
      setBaseLocal(
        Object.values(respLoc?.locacao_default.ld_finalidade_base_apoio || [])
          .length > 0 ||
          typeof respLoc?.locacao_default.local_base_apoio === 'string' ||
          typeof respLoc?.locacao_default.dimensao_base_apoio === 'string' ||
          typeof respLoc?.locacao_default.quantidade_barracas === 'string',
      );
      setCaptacaoSomDireto(respLoc?.locacao_default.captação_som_direto === 1);
      setSomFilmagem(
        Object.values(respLoc?.locacao_default.ld_som_filmagem || []).length >
          0,
      );
      setDrone(respLoc?.locacao_default.drone === 1);
      setEfeitosEspeciais(
        Object.values(respLoc?.locacao_default.ld_efeitos_especiais || [])
          .length > 0,
      );
      setProjecao(
        typeof respLoc?.locacao_default?.estrutura_projecao === 'string' ||
          typeof respLoc?.locacao_default?.instalacao_estrutura_projecao ===
            'string' ||
          publicoPrivadoTmp ||
          projecaoLogoTmp ||
          fileProjecao?.length > 0,
      );
      setPublicoPrivado(publicoPrivadoTmp);
      setProjecaoLogo(projecaoLogoTmp);
      setDesligarLuz(fileLuz?.length > 0);

      setReservasVagas(
        Object.values(respLoc?.locacao_via_externa || []).length > 0,
      );

      // set campos 'multi'
      setDatasFilmagemInputs(
        respLoc?.locacao_default?.dates_locacao_default?.map(
          ({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      setDatasEntradaInputs(
        respLoc?.base_dates
          ?.filter(({ date_input }) => date_input === 'Entrada')
          ?.map(({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_entrada: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          }),
      );

      setDatasSaidaInputs(
        respLoc?.base_dates
          ?.filter(({ date_input }) => date_input === 'Saída')
          ?.map(({ uuid, date, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              data_saida: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          }),
      );

      setHorasFilmagemInputs(
        respLoc?.locacao_default?.hours_locacao_default?.map(
          ({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          },
        ),
      );

      setHorasEntradaInputs(
        respLoc?.base_hours
          ?.filter(({ hour_input }) => hour_input === 'Entrada')
          ?.map(({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          }),
      );

      setHorasSaidaInputs(
        respLoc?.base_hours
          ?.filter(({ hour_input }) => hour_input === 'Saída')
          ?.map(({ uuid, inicio, fim, changes_to_approve }) => {
            return omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
              show_confirm_btns: changes_to_approve !== undefined,
            });
          }),
      );

      // set campos 'Outro'
      setShowOutroEquipamentoField(
        respLoc?.locacao_default?.ld_equipamento_filmagem?.find(
          equip => equip.name === 'Outro',
        ),
      );
      setShowOutroFinalidadeField(
        respLoc?.locacao_default?.ld_finalidade_base_apoio?.find(
          equip => equip.name === 'outro, descreva',
        ),
      );
      setShowOutroSomFilmagemField(
        respLoc?.locacao_default?.ld_som_filmagem?.find(
          equip => equip.name === 'outro',
        ),
      );
      setShowDescrEquipamentoFilmagemField(
        typeof respLoc?.locacao_default?.descricoes_equipamento_filmagem ===
          'string',
      );
      setShowDescrSomFilmagemField(
        typeof respLoc?.locacao_default?.descricoes_som_filmagem === 'string',
      );
      setShowDescrAltoImpactoLocalField(
        typeof respLoc?.locacao_default?.descr_alto_impacto_local === 'string',
      );

      // set select default values
      formRef.current?.setFieldValue('ld_impacto_uuid', {
        label: respLoc?.locacao_default?.ld_impacto?.name,
        value: respLoc?.locacao_default?.ld_impacto?.uuid,
      });

      formRef.current?.setFieldValue(
        'ld_equipamento_filmagem',
        respLoc?.locacao_default?.ld_equipamento_filmagem?.map(lfba => {
          return {
            label: lfba.name,
            value: lfba.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_local_gerador_uuid', {
        label: respLoc?.ld_local_gerador?.name,
        value: respLoc?.ld_local_gerador?.uuid,
      });

      formRef.current?.setFieldValue('ld_tipo_interna_uuid', {
        label: respLoc?.ld_tipo_interna?.name,
        value: respLoc?.ld_tipo_interna?.uuid,
      });

      formRef.current?.setFieldValue('ld_tipo_externa_uuid', {
        label: respLoc?.ld_tipo_externa?.name,
        value: respLoc?.ld_tipo_externa?.uuid,
      });

      formRef.current?.setFieldValue(
        'tipo_bloqueio_interna',
        optionsTipoBloqueio.filter(
          tb => tb.value === respLoc?.tipo_bloqueio_interna,
        ),
      );

      formRef.current?.setFieldValue(
        'tipo_bloqueio_externa',
        optionsTipoBloqueio.filter(
          tb => tb.value === respLoc?.tipo_bloqueio_externa,
        ),
      );

      formRef.current?.setFieldValue(
        'ld_finalidade_base_apoio',
        respLoc?.locacao_default?.ld_finalidade_base_apoio?.map(lfba => {
          return {
            label: lfba.name,
            value: lfba.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue(
        'ld_som_filmagem',
        respLoc?.locacao_default?.ld_som_filmagem?.map(ilef => {
          return {
            label: ilef.name,
            value: ilef.uuid,
          };
        }),
      );

      formRef.current?.setFieldValue('ld_efeitos_especiais_uuid', {
        label: respLoc?.locacao_default?.ld_efeitos_especiais?.name,
        value: respLoc?.locacao_default?.ld_efeitos_especiais?.uuid,
      });

      formRef.current?.setFieldValue('ld_complemento_numero_uuid', {
        label: respLoc?.ld_complemento_numero?.name,
        value: respLoc?.ld_complemento_numero?.uuid,
      });

      formRef.current?.setFieldValue('ld_finalidade_bloqueio_uuid', {
        label: respLoc?.ld_finalidade_bloqueio?.name,
        value: respLoc?.ld_finalidade_bloqueio?.uuid,
      });

      formRef.current?.setFieldValue(
        'utilizacao_associacao',
        Object.values(respLoc?.associada || {}).length <= 0
          ? {}
          : {
              label: `${respLoc?.associada?.name_view} - ${respLoc?.associada?.protocol}`,
              value: {
                uuid: respLoc?.associada?.uuid,
                endpoint: respLoc?.associada?.endpoint,
              },
            },
      );

      setSelectedFilmagemInternaOpt(respLoc?.ld_tipo_interna?.name);
      setSelectedFilmagemExternaOpt(respLoc?.ld_tipo_externa?.name);
      setTipoBloqueioAlgumasFaixas(
        respLoc?.tipo_bloqueio_externa === 'algumas_faixas' ||
          respLoc?.tipo_bloqueio_interna === 'algumas_faixas',
      );
      setResponseLocation(resp?.data?.data);
      setLoading(false);
    }
  }, [dadoState]);

  useEffect(() => {
    visualizacaoDadosCB();
  }, [visualizacaoDadosCB]);

  const convertBase64 = arquivo => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(arquivo);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  // Verification files
  let fileCroqui = [];
  let fileArte = [];
  let fileProjecao = [];
  let fileLuz = [];

  // Filter files
  fileCroqui = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Arquivo Croqui',
  );
  fileArte = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Relatório de Arte',
  );
  fileProjecao = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Arquivo Projeção',
  );
  fileLuz = responseLocation?.locacao_default.files?.filter(
    file => file.name === 'Arquivo Iluminação',
  );

  // handles files
  function handleIluminacaoFile() {
    return iluminacaoFileRef.current.click();
  }

  function handleProjecaoFile() {
    return conteudoProjecaoRef.current.click();
  }

  function handleCroquiFile() {
    return croquiFileRef.current.click();
  }

  function handleRelatorioArteFile() {
    return relatorioArteFileRef.current.click();
  }

  // handles multi select
  function handleMultiSelectEquipamentos(e) {
    setShowOutroEquipamentoField(!!e?.find(x => x.label === 'Outro'));
    setShowDescrEquipamentoFilmagemField(
      e?.some(equip => equip.label?.includes(':')) || false,
    );

    setFormData(state => ({
      ...state,
      ld_equipamento_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectSomFilmagem(e) {
    setShowOutroSomFilmagemField(!!e?.find(x => x.label === 'outro'));
    setShowDescrSomFilmagemField(
      e?.some(equip => equip.label?.includes('descrever')) || false,
    );

    setFormData(state => ({
      ...state,
      ld_som_filmagem: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  function handleMultiSelectFinalidades(e) {
    setShowOutroFinalidadeField(!!e?.find(x => x.label === 'outro, descreva'));

    setFormData(state => ({
      ...state,
      ld_finalidade_base_apoio: Array.isArray(e) ? e.map(x => x.value) : [],
    }));
  }

  // handles true/false

  function handleBaseApoioTrue(e) {
    e.preventDefault();
    setBaseApoio(true);
    setErrorsForm(state => ({
      ...state,
      baseApoio: false,
    }));
  }

  function handleBaseApoioFalse(e) {
    e.preventDefault();
    setBaseApoio(false);
    setErrorsForm(state => ({
      ...state,
      baseApoio: false,
    }));
  }
  function handleTipoFilmagemTrue(e) {
    e.preventDefault();
    setTipoFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      tipoFilmagem: false,
    }));
  }

  function handleTipoFilmagemFalse(e) {
    e.preventDefault();
    setTipoFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      tipoFilmagem: false,
    }));
  }

  function handleProjecaoLogoTrue(e) {
    e.preventDefault();
    setProjecaoLogo(true);
    setErrorsForm(state => ({
      ...state,
      projecaoLogo: false,
    }));
  }

  function handleProjecaoLogoFalse(e) {
    e.preventDefault();
    setProjecaoLogo(false);
    setErrorsForm(state => ({
      ...state,
      projecaoLogo: false,
    }));
  }

  function handleDesligarLuzTrue(e) {
    e.preventDefault(e);
    setDesligarLuz(true);
    setErrorsForm(state => ({
      ...state,
      desligarLuz: false,
    }));
  }

  function handleDesligarLuzFalse(e) {
    e.preventDefault(e);
    setDesligarLuz(false);
    setErrorsForm(state => ({
      ...state,
      desligarLuz: false,
    }));
  }

  function handleReservasVagasTrue(e) {
    e.preventDefault(e);
    setReservasVagas(true);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handleReservasVagasFalse(e) {
    e.preventDefault(e);
    setReservasVagas(false);
    setErrorsForm(state => ({
      ...state,
      reservasVagas: false,
    }));
  }

  function handlePublicoPrivadoTrue(e) {
    e.preventDefault();
    setPublicoPrivado(true);
    setErrorsForm(state => ({
      ...state,
      publicoPrivado: false,
    }));
  }

  function handlePublicoPrivadoFalse(e) {
    e.preventDefault();
    setPublicoPrivado(false);
    setErrorsForm(state => ({
      ...state,
      publicoPrivado: false,
    }));
  }

  function handleDroneTue(e) {
    e.preventDefault();
    setDrone(true);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleDroneFalse(e) {
    e.preventDefault();
    setDrone(false);
    setErrorsForm(state => ({
      ...state,
      drone: false,
    }));
  }

  function handleCaptacaoSomDiretoTrue(e) {
    e.preventDefault();
    setCaptacaoSomDireto(true);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleCaptacaoSomDiretoFalse(e) {
    e.preventDefault();
    setCaptacaoSomDireto(false);
    setErrorsForm(state => ({
      ...state,
      captacaoSomDireto: false,
    }));
  }

  function handleLocalFilmagemTrue(e) {
    e.preventDefault();
    setLocalFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleLocalFilmagemFalse(e) {
    e.preventDefault();
    setLocalFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      localFilmagem: false,
    }));
  }

  function handleFilmagemExternaEmTrue(e) {
    e.preventDefault();
    setFilmagemExternaEm(true);
    setErrorsForm(state => ({
      ...state,
      filmagemExternaEm: false,
    }));
  }

  function handleFilmagemExternaEmFalse(e) {
    e.preventDefault();
    setFilmagemExternaEm(false);
    setErrorsForm(state => ({
      ...state,
      filmagemExternaEm: false,
    }));
  }

  function handleIntervencaoArteTrue(e) {
    e.preventDefault();
    setIntervencaoArte(true);
    setErrorsForm(state => ({
      ...state,
      intervencaoArte: false,
    }));
  }

  function handleIntervencaoArteFalse(e) {
    e.preventDefault();
    setIntervencaoArte(false);
    setErrorsForm(state => ({
      ...state,
      intervencaoArte: false,
    }));
  }

  function handleGeradorTrue(e) {
    e.preventDefault();
    setGerador(true);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleGeradorFalse(e) {
    e.preventDefault();
    setGerador(false);
    setErrorsForm(state => ({
      ...state,
      gerador: false,
    }));
  }

  function handleBlimpadorTrue(e) {
    e.preventDefault();
    setBlimpado(true);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBlimpadorFalse(e) {
    e.preventDefault();
    setBlimpado(false);
    setErrorsForm(state => ({
      ...state,
      blimpado: false,
    }));
  }

  function handleBaseLocalTrue(e) {
    e.preventDefault();
    setBaseLocal(true);
    setErrorsForm(state => ({
      ...state,
      baseLocal: false,
    }));
  }

  function handleBaseLocalFalse(e) {
    e.preventDefault();
    setBaseLocal(false);
    setErrorsForm(state => ({
      ...state,
      baseLocal: false,
    }));
  }

  function handleSomFilmagemTrue(e) {
    e.preventDefault(e);
    setSomFilmagem(true);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleSomFilmagemFalse(e) {
    e.preventDefault(e);
    setSomFilmagem(false);
    setErrorsForm(state => ({
      ...state,
      somFilmagem: false,
    }));
  }

  function handleEfeitosEspeciaisTrue(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(true);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleEfeitosEspeciaisFalse(e) {
    e.preventDefault(e);
    setEfeitosEspeciais(false);
    setErrorsForm(state => ({
      ...state,
      efeitosEspeciais: false,
    }));
  }

  function handleProjecaoTrue(e) {
    e.preventDefault();
    setProjecao(true);
    setErrorsForm(state => ({
      ...state,
      projecao: false,
    }));
  }

  function handleProjecaoFalse(e) {
    e.preventDefault();
    setProjecao(false);
    setErrorsForm(state => ({
      ...state,
      projecao: false,
    }));
  }

  function handleAssociarLocacaoTrue(e) {
    e.preventDefault();
    setAssociarLocacao(true);
    setErrorsForm(state => ({
      ...state,
      associarLocacao: false,
    }));
  }

  function handleAssociarLocacaoFalse(e) {
    e.preventDefault();
    setAssociarLocacao(false);
    setErrorsForm(state => ({
      ...state,
      associarLocacao: false,
    }));
  }

  async function handleFileChange(event) {
    event.preventDefault();
    const { name } = event.target;

    const file = event.target.files[0];
    const base64 = await convertBase64(file);

    if (name === 'croqui_file') {
      setCroquiFile(base64.split('base64,'));
      setDataCroquiFile(file);
      setErrorsForm(state => ({
        ...state,
        croquiFile: false,
      }));
    }

    if (name === 'relatorio_arte_file') {
      setRelatorioArteFile(base64.split('base64,'));
      setDataRelatorioArteFile(file);
      setErrorsForm(state => ({
        ...state,
        relatorioArteFile: false,
      }));
    }

    if (name === 'conteudo_projecao_file') {
      setConteudoProjecaoFile(base64.split('base64,'));
      setDataConteudoProjecaoFile(file);
      setErrorsForm(state => ({
        ...state,
        conteudoProjecaoFile: false,
      }));
    }

    if (name === 'iluminacao_file') {
      setIluminacaoFile(base64.split('base64,'));
      setDataIluminacaoFile(file);
      setErrorsForm(state => ({
        ...state,
        iluminacaoFile: false,
      }));
    }

    setFormData(state => ({ ...state, [name]: base64.split('base64,')[1] }));
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectChange(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));
  }

  function handleSelectFilmagem(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));

    if (tipoFilmagem === true) {
      setSelectedFilmagemInternaOpt(option?.label);
    } else {
      setSelectedFilmagemExternaOpt(option?.label);
    }
  }

  function handleSelectFinalidadeBloqueio(name, option) {
    const value = option ? option.value : null;
    setFormData(state => ({ ...state, [name]: value }));

    setShowFinalidadeBloqueio(option?.label);
  }

  function formatDefaultData(data) {
    // files uuids
    const fileCroquiUuid = fileCroqui?.length > 0 ? fileCroqui[0].uuid : null;
    const fileArteUuid = fileArte?.length > 0 ? fileArte[0].uuid : null;
    const fileProjecaoUuid =
      fileProjecao?.length > 0 ? fileProjecao[0].uuid : null;
    const fileLuzUuid = fileLuz?.length > 0 ? fileLuz[0].uuid : null;

    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      ld_impacto_uuid: data?.ld_impacto_uuid,
      tamanho_equipe_tecnica: data?.tamanho_equipe_tecnica,
      tamanho_do_elenco: data?.tamanho_do_elenco,
      descricao_cena: data?.descricao_cena,
      croqui_base64: data?.croqui_file ? data?.croqui_file : null,
      croqui_base64_uuid: data?.croqui_file ? fileCroquiUuid : null,
      ld_equipamento_filmagem: data?.ld_equipamento_filmagem,
      especificidades: data?.especificidades,
    });

    // dados de choices

    const dataIntervencaoArte = intervencaoArte
      ? {
          desc_intervencao_arte: data?.desc_intervencao_arte,
          relat_arte_base64: data?.relatorio_arte_file
            ? data?.relatorio_arte_file
            : null,
          relat_arte_base64_uuid: data?.relatorio_arte_file
            ? fileArteUuid
            : null,
        }
      : {
          desc_intervencao_arte: null,
          relat_arte_base64: null,
          relat_arte_base64_uuid: fileArteUuid,
        };

    const dataGerador = {
      blimpado: !gerador ? null : blimpado,
    };

    const dataBaseLocal = baseLocal
      ? {
          ld_finalidade_base_apoio: data?.ld_finalidade_base_apoio,
          local_base_apoio: data?.local_base_apoio,
          dimensao_base_apoio: data?.dimensao_base_apoio,
          quantidade_barracas: data?.quantidade_barracas,
        }
      : {
          ld_finalidade_base_apoio: null,
          local_base_apoio: null,
          dimensao_base_apoio: null,
          quantidade_barracas: null,
        };

    const dataCaptacaoSomDireto = { captação_som_direto: captacaoSomDireto };

    const dataSomFilmagem = {
      ld_som_filmagem: somFilmagem ? data?.ld_som_filmagem : null,
    };

    const dataDrone = { drone };

    const dataEfeitosEspeciais = {
      ld_efeitos_especiais_uuid: efeitosEspeciais
        ? data?.ld_efeitos_especiais_uuid
        : null,
    };

    const dataProjecao = projecao
      ? {
          estrutura_projecao: data?.estrutura_projecao,
          instalacao_estrutura_projecao: data?.instalacao_estrutura_projecao,
          imovel_publico_privado: publicoPrivado,
          projecao_base64: data?.conteudo_projecao_file
            ? data?.conteudo_projecao_file
            : null,
          projecao_base64_uuid: data?.conteudo_projecao_file
            ? fileProjecaoUuid
            : null,
        }
      : {
          estrutura_projecao: null,
          instalacao_estrutura_projecao: null,
          imovel_publico_privado: null,
          projecao_base64: null,
          projecao_base64_uuid: fileProjecaoUuid,
        };

    const dataProjecaoLogo =
      projecao && projecaoLogo
        ? {
            porcentagem_projecao: +data?.porcentagem_projecao || undefined,
            area_tempo_projeção: data?.area_tempo_projeção,
          }
        : { porcentagem_projecao: null, area_tempo_projeção: null };

    const dataIluminacao = desligarLuz
      ? {
          iluminacao_base64: data?.iluminacao_file
            ? data?.iluminacao_file
            : null,
          iluminacao_base64_uuid: data?.iluminacao_file ? fileLuzUuid : null,
        }
      : { iluminacao_base64: null, iluminacao_base64_uuid: fileLuzUuid };

    // dados de campos exibidos condicionalmente
    const dataDescrAltoImpactoLocal = {
      descr_alto_impacto_local: showDescrAltoImpactoLocalField
        ? data?.descr_alto_impacto_local
        : null,
    };

    const dataDescrEquipamentoFilmagem = {
      descricoes_equipamento_filmagem: showDescrEquipamentoFilmagemField
        ? data?.descricoes_equipamento_filmagem
        : null,
    };

    const dataDescrSomFilmagem = {
      descricoes_som_filmagem:
        somFilmagem && showDescrSomFilmagemField
          ? data?.descricoes_som_filmagem
          : null,
    };

    const dataOutroEquipamento = {
      outro_equipamento: showOutroEquipamentoField
        ? data?.outro_equipamento
        : null,
    };

    const dataOutroFinalidade = {
      outro_finalidade:
        baseLocal && showOutroFinalidadeField ? data?.outro_finalidade : null,
    };

    const dataOutroSomFilmagem = {
      outro_som_filmagem:
        somFilmagem && showOutroSomFilmagemField
          ? data?.outro_som_filmagem
          : null,
    };

    // diff dates e hours
    const diffDatas = diffDatasFilmagem({
      datasFilmagemLocacao:
        responseLocation.locacao_default.dates_locacao_default.map(
          ({ uuid, date, changes_to_approve }) =>
            omitEmpty({
              uuid,
              data_filmagem: getBrowserTransformDate(date.split(' ')[0], true),
              changes_to_approve,
            }),
        ),
      datasFilmagemInput: datasFilmagemInputs?.map(
        ({ uuid, data_filmagem, changes_to_approve }) =>
          omitEmpty({
            uuid,
            data_filmagem,
            changes_to_approve,
          }),
      ),
      dateKeyName: 'data_filmagem',
    });

    const diffHours = diffHoursFilmagem({
      hoursFilmagemLocacao:
        responseLocation?.locacao_default.hours_locacao_default?.map(
          ({ uuid, inicio, fim, changes_to_approve }) =>
            omitEmpty({
              uuid,
              inicio: inicio.substring(0, 5),
              fim: fim.substring(0, 5),
              changes_to_approve,
            }),
        ),
      hoursFilmagemInput: horasFilmagemInputs?.map(
        ({ uuid, inicio, fim, changes_to_approve }) =>
          omitEmpty({
            uuid,
            inicio,
            fim,
            changes_to_approve,
          }),
      ),
      hourBeginKeyName: 'inicio',
      hourEndKeyName: 'fim',
    });

    const datasBaseEntrada = datasEntradaInputs
      ?.filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_entrada));

    const datasBaseSaida = datasSaidaInputs
      ?.filter(item => typeof item === 'object')
      .map(item => getBrowserTransformDate(item.data_saida));

    return Object.assign(
      dataNaoCondicional,
      { dates: diffDatas },
      { hours: diffHours },
      dataIntervencaoArte,
      dataGerador,
      dataBaseLocal,
      dataCaptacaoSomDireto,
      dataSomFilmagem,
      dataDrone,
      dataEfeitosEspeciais,
      dataProjecao,
      dataProjecaoLogo,
      dataIluminacao,
      dataDescrAltoImpactoLocal,
      dataDescrEquipamentoFilmagem,
      dataDescrSomFilmagem,
      dataOutroEquipamento,
      dataOutroFinalidade,
      dataOutroSomFilmagem,
    );
  }

  function formatEspecificaData(data) {
    const locacaoAssociada = utilizacaoAssociacaoList?.find(
      ({ uuid }) => uuid === data?.utilizacao_associacao?.uuid,
    );

    // dados já exibidos sem condicoes
    const dataNaoCondicional = omitEmpty({
      titulo_locacao: data?.titulo_locacao,
      neighborhood: data?.neighborhood,
    });

    // dados de choices
    let dataBaseApoio = {};
    if (baseApoio) {
      const datasBaseEntrada = datasEntradaInputs
        .filter(item => typeof item === 'object')
        .map(item => ({
          date: getBrowserTransformDate(item.data_entrada),
          date_input: 'Entrada',
        }));

      const datasBaseSaida = datasSaidaInputs
        .filter(item => typeof item === 'object')
        .map(item => ({
          date: getBrowserTransformDate(item.data_saida),
          date_input: 'Saída',
        }));

      const horasBaseEntrada = horasEntradaInputs.map(item => ({
        ...item,
        hour_input: 'Entrada',
      }));
      const horasBaseSaida = horasSaidaInputs.map(item => ({
        ...item,
        hour_input: 'Saída',
      }));

      dataBaseApoio = {
        base_address: data?.base_address,
        base_num: data?.base_num,
        base_dates: [...datasBaseEntrada, ...datasBaseSaida],
        base_hours: [...horasBaseEntrada, ...horasBaseSaida],
      };
    }

    const dataAssociarLocacao = associarLocacao
      ? {
          locacao: {
            reference_uuid: locacaoAssociada?.uuid,
            name_view: locacaoAssociada?.name_view,
            method:
              Object.values(responseLocation?.associada || {}).length > 0
                ? 'change'
                : 'save',
          },
        }
      : { locacao: { method: 'delete' } };

    const dataLocalFilmagem = localFilmagem
      ? {
          address: data?.address,
          number: data?.number,
          ld_complemento_numero_uuid: data?.ld_complemento_numero_uuid,
          via_sentido: data?.via_sentido,
          address_temp: null,
        }
      : {
          address: null,
          number: null,
          ld_complemento_numero_uuid: null,
          via_sentido: null,
          address_temp: data?.address_temp,
        };

    const dataTipoFilmagem = tipoFilmagem
      ? {
          ld_tipo_interna_uuid: data?.ld_tipo_interna_uuid,
          ld_tipo_externa_uuid: null,
        }
      : {
          ld_tipo_interna_uuid: null,
          ld_tipo_externa_uuid: data?.ld_tipo_externa_uuid,
        };

    const dataFilmagemExternaEm = {
      filmagem_externa_em: tipoFilmagem !== false ? null : filmagemExternaEm,
    };

    /* Tipo Filmagem: Interna */

    // ocupação
    const dataFilmagemInternaOcupacao =
      tipoFilmagem === true &&
      selectedFilmagemInternaOpt?.startsWith('Ocupação') === true
        ? {
            estrutura_filmagem_interna: data?.estrutura_filmagem_interna,
            dimensao_area_filmagem_interna:
              +data?.dimensao_area_filmagem_interna || undefined,
          }
        : {
            estrutura_filmagem_interna: null,
            dimensao_area_filmagem_interna: null,
          };

    const dataFilmagemInternaOcupacaoFaixaJuntoMeioFioMontEstrutApoio =
      tipoFilmagem === true &&
      selectedFilmagemInternaOpt ===
        'Ocupação de uma faixa junto ao meio fio para montagem de estrutura de apoio'
        ? {
            extensao_ocupacao_faixa_interna:
              data?.extensao_ocupacao_faixa_interna,
          }
        : {
            extensao_ocupacao_faixa_interna: null,
          };

    // bloqueio
    const dataFilmagemInternaBloqueioIntermitente =
      tipoFilmagem === true &&
      selectedFilmagemInternaOpt?.startsWith('Bloqueio intermitente')
        ? {
            tipo_bloqueio_interna: data?.tipo_bloqueio_interna,
            periodo_bloqueio_intermitente_interna:
              data?.periodo_bloqueio_intermitente_interna,
            numero_faixas_interna: tipoBloqueioAlgumasFaixas
              ? data?.numero_faixas_interna
              : null,
          }
        : {
            tipo_bloqueio_interna: null,
            periodo_bloqueio_intermitente_interna: null,
            numero_faixas_interna: null,
          };

    const dataFilmagemInternaBloqueioTotal =
      selectedFilmagemInternaOpt?.startsWith('Bloqueio total') === true
        ? {
            inicio_horario_bloqueio_interna:
              data?.inicio_horario_bloqueio_interna,
            fim_horario_bloqueio_interna: data?.fim_horario_bloqueio_interna,
          }
        : {
            inicio_horario_bloqueio_interna: null,
            fim_horario_bloqueio_interna: null,
          };

    const dataFilmagemInternaBloqueioTotalAlgumaFaixa =
      tipoFilmagem === true &&
      selectedFilmagemInternaOpt ===
        'Bloqueio total de alguma(s) faixa(s) para captação de áudio ou para campo de câmera'
        ? {
            numero_faixas_bloqueadas_interna:
              data?.numero_faixas_bloqueadas_interna,
            sentido_faixas_bloqueadas_interna:
              data?.sentido_faixas_bloqueadas_interna,
          }
        : {
            numero_faixas_bloqueadas_interna: null,
            sentido_faixas_bloqueadas_interna: null,
          };

    const dataFilmagemInternaBloqueioTotalVia =
      tipoFilmagem === true &&
      selectedFilmagemInternaOpt ===
        'Bloqueio total de via para captação de áudio ou para campo de câmera'
        ? {
            ld_finalidade_bloqueio_uuid: data?.ld_finalidade_bloqueio_uuid,
          }
        : {
            ld_finalidade_bloqueio_uuid: null,
          };

    /* Tipo Filmagem: Externa */

    // ocupação
    const dataFilmagemExternaOcupacao =
      tipoFilmagem === false &&
      selectedFilmagemExternaOpt?.startsWith('Ocupação') === true
        ? {
            estrutura_filmagem_externa: data?.estrutura_filmagem_externa,
            dimensao_area_filmagem_externa:
              +data?.dimensao_area_filmagem_externa || undefined,
          }
        : {
            estrutura_filmagem_externa: null,
            dimensao_area_filmagem_externa: null,
          };

    // bloqueio
    const dataFilmagemExternaBloqueioIntermitente =
      tipoFilmagem === false &&
      selectedFilmagemExternaOpt?.startsWith('Bloqueio intermitente')
        ? {
            tipo_bloqueio_externa: data?.tipo_bloqueio_externa,
            periodo_bloqueio_intermitente_externa:
              data?.periodo_bloqueio_intermitente_externa,
            numero_faixas_externa: tipoBloqueioAlgumasFaixas
              ? data?.numero_faixas_externa
              : null,
          }
        : {
            tipo_bloqueio_externa: null,
            periodo_bloqueio_intermitente_externa: null,
            numero_faixas_externa: null,
          };

    const dataFilmagemExternaBloqueioTotalVia =
      tipoFilmagem === false &&
      selectedFilmagemExternaOpt?.startsWith('Bloqueio total da via')
        ? {
            inicio_horario_bloqueio_externa:
              data?.inicio_horario_bloqueio_externa,
            fim_horario_bloqueio_externa: data?.fim_horario_bloqueio_externa,
            inicio_numeracao_bloqueio_externa:
              data?.inicio_numeracao_bloqueio_externa,
            fim_numeracao_bloqueio_externa:
              data?.fim_numeracao_bloqueio_externa,
          }
        : {
            inicio_horario_bloqueio_externa: null,
            fim_horario_bloqueio_externa: null,
            inicio_numeracao_bloqueio_externa: null,
            fim_numeracao_bloqueio_externa: null,
          };

    const dataGerador = {
      ld_local_gerador_uuid: !gerador ? null : data?.ld_local_gerador_uuid,
    };

    return Object.assign(
      dataBaseApoio,
      dataNaoCondicional,
      dataAssociarLocacao,
      dataLocalFilmagem,
      dataGerador,
      dataTipoFilmagem,
      dataFilmagemExternaEm,
      dataFilmagemInternaOcupacao,
      dataFilmagemInternaOcupacaoFaixaJuntoMeioFioMontEstrutApoio,
      dataFilmagemInternaBloqueioIntermitente,
      dataFilmagemInternaBloqueioTotal,
      dataFilmagemInternaBloqueioTotalAlgumaFaixa,
      dataFilmagemInternaBloqueioTotalVia,
      dataFilmagemExternaOcupacao,
      dataFilmagemExternaBloqueioIntermitente,
      dataFilmagemExternaBloqueioTotalVia,
    );
  }

  async function handleValidationFields(data) {
    try {
      if (!validDateArray(datasFilmagemInputs, 'data_filmagem')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas datas válidas!',
        });
        return false;
      }

      if (!validHourArray(horasFilmagemInputs, 'inicio', 'fim')) {
        await showModalMessage({
          type: 'error',
          text: 'Por favor, informe apenas horas válidas!',
        });
        return false;
      }
    } catch (err) {
      await showModalMessage({
        type: 'error',
        text: err.message,
      });
      return false;
    }

    return true;
  }

  async function handleSubmit(data) {
    // files
    data.croqui_file = formData?.croqui_file;
    data.relatorio_arte_file = formData?.relatorio_arte_file;
    data.conteudo_projecao_file = formData?.conteudo_projecao_file;
    data.iluminacao_file = formData?.iluminacao_file;

    // selects
    data.ld_equipamento_filmagem = formData?.ld_equipamento_filmagem;
    data.ld_finalidade_base_apoio = formData?.ld_finalidade_base_apoio;
    data.ld_som_filmagem = formData?.ld_som_filmagem;

    try {
      if (!(await handleValidationFields(data))) {
        return;
      }

      const defaultData = formatDefaultData(data);
      const especificaData = formatEspecificaData(data);

      setLoading(true);

      if (isAdminRequest) {
        await ApiClient.doRequest({
          uri: `locacao-default/${responseLocation?.locacao_default?.uuid}`,
          method: ApiClient.PATCH,
          body: defaultData,
          expectedStatus: 201,
        });

        await ApiClient.doRequest({
          uri: `locacao-utilizacao-via/${responseLocation?.uuid}`,
          method: ApiClient.PATCH,
          body: especificaData,
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title:
            'Filmagens (ou apoio) em ruas/calçadas/vias/viadutos/passarelas/túneis/correlatos',
          text: 'Alteração enviada com sucesso',
        });

        history.push('/locacoes');
      } else {
        if (!defaultData?.dates && !defaultData?.hours) {
          setLoading(false);
          await showModalMessage({
            type: 'info',
            title:
              'Filmagens (ou apoio) em ruas/calçadas/vias/viadutos/passarelas/túneis/correlatos',
            text: 'Não houve alteração em Data/Hora a ser salva',
          });
          return;
        }

        await ApiClient.doRequest({
          uri: `locacao-default/common-user/${responseLocation?.locacao_default?.uuid}`,
          method: ApiClient.PATCH,
          body: {
            dates: defaultData?.dates,
            hours: defaultData?.hours,
          },
          expectedStatus: 201,
        });

        setLoading(false);

        await showModalMessage({
          type: 'success',
          title:
            'Filmagens (ou apoio) em ruas/calçadas/vias/viadutos/passarelas/túneis/correlatos',
          text: 'As alterações foram enviadas para avaliação da SPFilm',
        });

        history.push('/home-user');
      }
    } catch (err) {
      setLoading(false);
      await showModalMessage({
        type: 'error',
        title:
          'Filmagens (ou apoio) em ruas/calçadas/vias/viadutos/passarelas/túneis/correlatos',
        text: 'Ocorreu um erro ao tentar gravar Filmagens (ou apoio) em ruas/calçadas/vias. Verifique os dados.',
      });

      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function renderLocacao() {
    if (dadoState?.viewData && !loading && !responseLocation) {
      return (
        <PageContainer style={{ height: '100%' }}>
          <PageContent>
            <WhiteBox column jCenter aCenter marginBottom="30px">
              <h3 style={{ color: '#828282' }}>Nenhuma Locação encontrada</h3>
            </WhiteBox>
          </PageContent>
        </PageContainer>
      );
    }

    return (
      <>
        {loading && (
          <MainLoading loading={loading}>
            <h3>Processando Informações</h3>
            <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
          </MainLoading>
        )}
        <PageContainer>
          <PageContent>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <WhiteBox column jCenter aCenter marginBottom="30px">
                <BoxHeader>
                  <span className="boxText">Informações gerais</span>
                </BoxHeader>
                <Wrap>
                  <Textarea
                    name="form_info"
                    rows="3"
                    value='Caso a produção precise utilizar mais de uma via para a mesma locação (Ex.: Trajeto;
                      Filmagens em esquinas; Filmagens em uma via com apoio em outra via próxima), deverá especificar no
                      campo "Endereço". Só serão enviados para a análise dos órgãos os pedidos enviados com todas as informações
                      obrigatórias solicitadas.'
                    disabled
                  />
                </Wrap>

                {responseLocation?.protocol && (
                  <BoxHeader>
                    <span
                      className="boxText"
                      style={{ color: '#828282' }}
                    >{`[ Número: ${responseLocation?.protocol} ]`}</span>
                  </BoxHeader>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="titulo_locacao"
                      label="Nome da Locação (Opcional)"
                      onChange={handleChange}
                      defaultValue={
                        dadoState?.viewData && responseLocation?.titulo_locacao
                      }
                      disabled={!isAdminRequest}
                      questionTooltip="Para controle interno das produções, é possível adicionar o nome da locação, como por exemplo: Casa da Personagem Principal. Esse controle não constará em pedidos e documentações oficiais, e é somente um facilitador em projetos de grande porte."
                    />
                  </LimiterInputWidth>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_impacto_uuid"
                      label="Você classifica sua filmagem como sendo:"
                      placeholder="Selecione"
                      options={impactoSelectList}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange('ld_impacto_uuid', value);
                        setShowDescrAltoImpactoLocalField(
                          value.label === 'alto impacto no local',
                        );
                      }}
                      required
                      questionTooltip="Clique aqui para saber mais"
                      questionOnClick={() => setOpenModalPorteFilmagem(true)}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={showDescrAltoImpactoLocalField}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="descr_alto_impacto_local"
                      label="Comente qual o desafio da sua produção para auxiliarmos em sua viabilização"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descr_alto_impacto_local
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Deseja associa-lá com uma Locação cadastrada?"
                      onClickTrue={handleAssociarLocacaoTrue}
                      onClickFalse={handleAssociarLocacaoFalse}
                      selected={associarLocacao}
                      error={
                        errorsForm.associarLocacao === true && 'Obrigatório'
                      }
                      isDisabled={!isAdminRequest}
                      required
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={associarLocacao}>
                  <LimiterInputWidth w50>
                    <Select
                      name="utilizacao_associacao"
                      label="Escolha a Locação associada:"
                      placeholder="Selecione"
                      options={utilizacaoAssociacaoList?.map(
                        ({ uuid, name_view, protocol, endpoint }) => ({
                          label: `${name_view} - ${protocol}`,
                          value: { uuid, endpoint },
                        }),
                      )}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectChange('utilizacao_associacao', value);
                      }}
                      required
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>

                  {Object.values(formData?.utilizacao_associacao || {}).length >
                    0 && (
                    <Wrap>
                      <FormChoiceButton
                        nomeProjeto={dadoState?.nome_projeto}
                        editData={{
                          is_admin: isAdminRequest,
                          uuid: formData?.utilizacao_associacao?.uuid,
                          endpoint: formData?.utilizacao_associacao?.endpoint,
                        }}
                      />
                    </Wrap>
                  )}
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Já possui local definido para a base de apoio?"
                      onClickTrue={handleBaseApoioTrue}
                      onClickFalse={handleBaseApoioFalse}
                      selected={baseApoio}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.baseApoio === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={baseApoio}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="base_address"
                        label="Endereço"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData && responseLocation?.base_address
                        }
                        disabled={!isAdminRequest}
                        required={baseApoio}
                      />
                    </LimiterInputWidth>
                    <LimiterInputWidth w25>
                      <Input
                        type="text"
                        name="base_num"
                        label="Número"
                        onChange={handleChange}
                        maxLength={11}
                        defaultValue={
                          dadoState?.viewData && responseLocation?.base_num
                        }
                        disabled={!isAdminRequest}
                        required={baseApoio}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <BoxHeader>
                    <span className="boxText">Entrada da Base</span>
                  </BoxHeader>
                  <Wrap>
                    <DateFormMultiInput
                      label="Data da Reserva"
                      dates={datasEntradaInputs}
                      setDates={setDatasEntradaInputs}
                      setIsLoading={setLoading}
                      isViewMode={!isAdminRequest}
                      dateKeyName="data_entrada"
                      tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                      isAdminRequest={isAdminRequest}
                      acceptPast
                      isRequired={baseApoio}
                    />

                    <HourFormMultiInput
                      label="Hora da Entrada (Início e Fim)"
                      hours={horasEntradaInputs}
                      setHours={setHorasEntradaInputs}
                      setIsLoading={setLoading}
                      isViewMode={!isAdminRequest}
                      hourBeginKeyName="inicio"
                      hourEndKeyName="fim"
                      tooltip="Incluir horário de montagem/desmontagem"
                      isAdminRequest={isAdminRequest}
                      isRequired={baseApoio}
                    />
                  </Wrap>

                  <BoxHeader>
                    <span className="boxText">Saída da Base</span>
                  </BoxHeader>
                  <Wrap>
                    <DateFormMultiInput
                      label="Data da Reserva"
                      dates={datasSaidaInputs}
                      setDates={setDatasSaidaInputs}
                      setIsLoading={setLoading}
                      isViewMode={!isAdminRequest}
                      dateKeyName="data_saida"
                      tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                      isAdminRequest={isAdminRequest}
                      acceptPast
                      isRequired={baseApoio}
                    />

                    <HourFormMultiInput
                      label="Hora da Entrada (Início e Fim)"
                      hours={horasSaidaInputs}
                      setHours={setHorasSaidaInputs}
                      setIsLoading={setLoading}
                      isViewMode={!isAdminRequest}
                      hourBeginKeyName="inicio"
                      hourEndKeyName="fim"
                      tooltip="Incluir horário de montagem/desmontagem"
                      isAdminRequest={isAdminRequest}
                      isRequired={baseApoio}
                    />
                  </Wrap>
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Já possui a definição de local exato da solicitação de filmagem/apoio?"
                      onClickTrue={handleLocalFilmagemTrue}
                      onClickFalse={handleLocalFilmagemFalse}
                      selected={localFilmagem}
                      error={errorsForm.localFilmagem === true && 'Obrigatório'}
                      isDisabled={!isAdminRequest}
                      required
                    />
                  </LimiterInputWidth>
                </Wrap>
                <ConditionalContainer show={localFilmagem === false}>
                  <Wrap>
                    <Input
                      type="text"
                      name="address_temp"
                      label="Endereço previsto"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.address_temp
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>
                <ConditionalContainer show={localFilmagem === true}>
                  <>
                    <Wrap>
                      <Input
                        type="text"
                        name="address"
                        label="Endereço"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData && responseLocation?.address
                        }
                        disabled={!isAdminRequest}
                      />
                      <Input
                        type="number"
                        name="number"
                        label="Número"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData && responseLocation?.number
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                    <Wrap>
                      <Select
                        name="ld_complemento_numero_uuid"
                        label="Opções"
                        placeholder="Selecione"
                        options={complementoNumeroSelectList}
                        style={selectCustomStyles}
                        onChange={value => {
                          handleSelectChange(
                            'ld_complemento_numero_uuid',
                            value,
                          );
                        }}
                        required
                        isDisabled={!isAdminRequest}
                      />
                      <Input
                        type="text"
                        name="via_sentido"
                        label="Caso a via possua mais de um sentido, especificar qual:"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData && responseLocation?.via_sentido
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </>
                </ConditionalContainer>
                <Wrap>
                  <LimiterInputWidth w50>
                    <Input
                      type="text"
                      name="neighborhood"
                      label="Bairro"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData && responseLocation?.neighborhood
                      }
                      disabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>
                <Wrap>
                  <DateFormMultiInput
                    label="Data(s)"
                    dates={datasFilmagemInputs}
                    setDates={setDatasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    dateKeyName="data_filmagem"
                    tooltip="Possível adicionar mais de uma data. Fundo azul indica campo aguardando aprovação."
                    isAdminRequest={isAdminRequest}
                    acceptPast
                    isRequired
                  />

                  <HourFormMultiInput
                    label="Horário(s)"
                    hours={horasFilmagemInputs}
                    setHours={setHorasFilmagemInputs}
                    setIsLoading={setLoading}
                    isViewMode={false}
                    hourBeginKeyName="inicio"
                    hourEndKeyName="fim"
                    tooltip="Incluir horário de montagem/desmontagem"
                    isAdminRequest={isAdminRequest}
                    isRequired
                  />
                </Wrap>

                <Wrap>
                  <Input
                    type="number"
                    name="tamanho_equipe_tecnica"
                    label="Tamanho da Equipe Técnica"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState.viewData &&
                      responseLocation?.locacao_default?.tamanho_equipe_tecnica
                    }
                    disabled={!isAdminRequest}
                  />
                  <Input
                    type="number"
                    name="tamanho_do_elenco"
                    label="Tamanho do elenco"
                    onChange={handleChange}
                    required
                    defaultValue={
                      dadoState.viewData &&
                      responseLocation?.locacao_default?.tamanho_do_elenco
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>
                <Wrap>
                  <Textarea
                    type="text"
                    name="descricao_cena"
                    label="Descrição da Cena"
                    onChange={handleChange}
                    rows="3"
                    required
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.descricao_cena
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap>
                  <File
                    label="Croqui da Intervenção"
                    attach={dataCroquiFile}
                    textFile="Nenhum arquivo selecionado"
                    name="croqui_file"
                    required
                    inputRef={croquiFileRef}
                    onChange={handleFileChange}
                    onClick={handleCroquiFile}
                    error={errorsForm.croquiFile === true && 'Obrigatório'}
                    link={fileCroqui?.length > 0 && fileCroqui[0].url}
                    isDisabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Tipo de filmagem:"
                      onClickTrue={handleTipoFilmagemTrue}
                      onClickFalse={handleTipoFilmagemFalse}
                      selected={tipoFilmagem}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.tipoFilmagem === true && 'Obrigatório'}
                      yesLabelValue="Interna"
                      noLabelValue="Externa"
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={tipoFilmagem === true}>
                    <Select
                      name="ld_tipo_interna_uuid"
                      label="Ocupação desejada"
                      placeholder="Selecione"
                      options={filmagemInternaOpts}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectFilmagem('ld_tipo_interna_uuid', value);
                      }}
                      required
                      isDisabled={!isAdminRequest}
                    />
                  </ConditionalContainer>

                  <ConditionalContainer
                    show={tipoFilmagem === false}
                    style={{ flexDirection: 'row' }}
                  >
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Filmagem em:"
                        onClickTrue={handleFilmagemExternaEmTrue}
                        onClickFalse={handleFilmagemExternaEmFalse}
                        selected={filmagemExternaEm}
                        required
                        isDisabled={!isAdminRequest}
                        error={
                          errorsForm.filmagemExternaEm === true && 'Obrigatório'
                        }
                        yesLabelValue="Ponto Fixo"
                        noLabelValue="Trajeto"
                      />
                    </LimiterInputWidth>

                    <Select
                      name="ld_tipo_externa_uuid"
                      label="Ocupação desejada"
                      placeholder="Selecione"
                      options={filmagemExternaOpts}
                      style={selectCustomStyles}
                      onChange={value => {
                        handleSelectFilmagem('ld_tipo_externa_uuid', value);
                      }}
                      required
                      isDisabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>

                {/* Interna Opts Ocupação */}
                <ConditionalContainer
                  show={
                    tipoFilmagem === true &&
                    selectedFilmagemInternaOpt?.startsWith('Ocupação') === true
                  }
                >
                  {/* OPT 2 */}
                  {selectedFilmagemInternaOpt ===
                    'Ocupação total do passeio (calçada) para montagem de estruturas de apoio' && (
                    <Wrap>
                      <Textarea
                        name="interna-opt2-info"
                        rows={3}
                        defaultValue="Em casos de bloqueio total da calçada é obrigatória a canalização dos
                        pedestres. Caso seja em local de estacionamento permitido, a produção deve solicitar
                        uma reserva de vagas, e caso não seja, a SPFilm avaliará junto à CET sobre as possíveis
                        opções de canalização que a produção poderá operacionalizar."
                        disabled
                      />
                    </Wrap>
                  )}

                  {/* OPT 3 */}
                  {selectedFilmagemInternaOpt ===
                    'Ocupação de uma faixa junto ao meio fio para montagem de estrutura de apoio' && (
                    <Wrap>
                      <Textarea
                        name="interna-opt3-info"
                        rows={4}
                        defaultValue="Caso a ocupação da faixa seja em local de estacionamento permitido, a
                        produção deve solicitar uma reserva de vagas iniciando em horário anterior ao da
                        ocupação da faixa para garantir que estará livre no momento da montagem do apoio;
                        caso seja em local de estacionamento proibido, a SPFilm avaliará junto à CET sobre as
                        possíveis opções de canalização que a produção poderá operacionalizar."
                        disabled
                      />
                    </Wrap>
                  )}

                  <Wrap>
                    <Textarea
                      type="text"
                      name="estrutura_filmagem_interna"
                      label="Qual a estrutura que será montada"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.estrutura_filmagem_interna
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        step="0.01"
                        name="dimensao_area_filmagem_interna"
                        label="Qual a dimensão (extensão ou área) aproximada da área ocupada:"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.dimensao_area_filmagem_interna
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>

                    {/* OPT 3 */}
                    <ConditionalContainer
                      show={
                        selectedFilmagemInternaOpt ===
                        'Ocupação de uma faixa junto ao meio fio para montagem de estrutura de apoio'
                      }
                    >
                      <LimiterInputWidth>
                        <Input
                          type="number"
                          step="0.01"
                          name="extensao_ocupacao_faixa_interna"
                          label="Extensão da ocupação"
                          onChange={handleChange}
                          required
                          defaultValue={
                            dadoState?.viewData &&
                            responseLocation?.extensao_ocupacao_faixa_interna
                          }
                          disabled={!isAdminRequest}
                        />
                      </LimiterInputWidth>
                    </ConditionalContainer>
                  </Wrap>
                </ConditionalContainer>

                {/* Interna Opts Bloqueio */}
                <ConditionalContainer
                  show={
                    tipoFilmagem === true &&
                    selectedFilmagemInternaOpt?.startsWith('Bloqueio') === true
                  }
                >
                  {/* OPT 4 */}
                  <ConditionalContainer
                    show={
                      selectedFilmagemInternaOpt ===
                      'Bloqueio intermitente da via para captação de áudio ou para campo de câmera'
                    }
                  >
                    <Wrap>
                      <Select
                        name="tipo_bloqueio_interna"
                        label="Tipo de bloqueio"
                        placeholder="Selecione"
                        options={optionsTipoBloqueio}
                        style={selectCustomStyles}
                        onChange={value => {
                          handleSelectChange('tipo_bloqueio_interna', value);
                          setTipoBloqueioAlgumasFaixas(
                            value.value === 'algumas_faixas',
                          );
                        }}
                        required
                        isDisabled={!isAdminRequest}
                      />

                      <ConditionalContainer
                        show={tipoBloqueioAlgumasFaixas === true}
                        style={{ marginRight: 15 }}
                      >
                        <Input
                          type="number"
                          name="numero_faixas_interna"
                          label="Número de faixas"
                          onChange={handleChange}
                          required
                          defaultValue={
                            dadoState?.viewData &&
                            responseLocation?.numero_faixas_interna
                          }
                          disabled={!isAdminRequest}
                        />
                      </ConditionalContainer>

                      <Input
                        type="number"
                        name="periodo_bloqueio_intermitente_interna"
                        label="Período de bloqueio intermitente"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.periodo_bloqueio_intermitente_interna
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>

                  {/* OPT 5 */}
                  <ConditionalContainer
                    show={
                      selectedFilmagemInternaOpt ===
                      'Bloqueio total de alguma(s) faixa(s) para captação de áudio ou para campo de câmera'
                    }
                  >
                    <Wrap>
                      <Input
                        type="number"
                        name="numero_faixas_bloqueadas_interna"
                        label="Número de faixas bloqueadas"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.numero_faixas_bloqueadas_interna
                        }
                        disabled={!isAdminRequest}
                      />

                      <Input
                        name="sentido_faixas_bloqueadas_interna"
                        label="Sentido das faixas bloqueadas"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.sentido_faixas_bloqueadas_interna
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>

                  {/* OPT 6 */}
                  <ConditionalContainer
                    show={
                      selectedFilmagemInternaOpt ===
                      'Bloqueio total de via para captação de áudio ou para campo de câmera'
                    }
                  >
                    <Wrap>
                      <Select
                        name="ld_finalidade_bloqueio_uuid"
                        label="Finalidade de bloqueio"
                        placeholder="Selecione"
                        options={finalidadeBloqueioOpts}
                        style={selectCustomStyles}
                        onChange={value => {
                          handleSelectFinalidadeBloqueio(
                            'ld_finalidade_bloqueio_uuid',
                            value,
                          );
                        }}
                        required
                        isDisabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>

                  {/* OPTs Não Bloqueio intermitente */}
                  <ConditionalContainer
                    show={
                      selectedFilmagemInternaOpt?.startsWith(
                        'Bloqueio total',
                      ) === true
                    }
                  >
                    <Wrap className="label">
                      <BoxHeader>
                        <span className="boxText">
                          Horário exato do bloqueio
                        </span>
                      </BoxHeader>
                      <Wrap>
                        <LimiterInputWidth w25>
                          <Input
                            type="time"
                            name="inicio_horario_bloqueio_interna"
                            label="Das"
                            onChange={handleChange}
                            required
                            defaultValue={
                              dadoState?.viewData &&
                              responseLocation?.inicio_horario_bloqueio_interna
                            }
                            disabled={!isAdminRequest}
                          />
                        </LimiterInputWidth>
                        <LimiterInputWidth w25>
                          <Input
                            type="time"
                            name="fim_horario_bloqueio_interna"
                            label="Às"
                            onChange={handleChange}
                            required
                            defaultValue={
                              dadoState?.viewData &&
                              responseLocation?.fim_horario_bloqueio_interna
                            }
                            disabled={!isAdminRequest}
                          />
                        </LimiterInputWidth>
                      </Wrap>
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                {/* Externa Opts Ocupação */}
                <ConditionalContainer
                  show={
                    tipoFilmagem === false &&
                    selectedFilmagemExternaOpt?.startsWith('Ocupação') === true
                  }
                >
                  {/* OPT 2 */}
                  {selectedFilmagemExternaOpt ===
                    'Ocupação total do passeio (calçada) para cena' && (
                    <Wrap>
                      <Textarea
                        name="externa-opt2-info"
                        rows="3"
                        defaultValue="Em casos de bloqueio total da calçada é obrigatória a canalização dos
                      pedestres. Caso seja em local de estacionamento permitido, a produção deve solicitar
                      uma reserva de vagas, e caso não seja, a SPFilm avaliará junto à CET sobre as possíveis
                      opções de canalização que a produção poderá operacionalizar."
                        disabled
                      />
                    </Wrap>
                  )}

                  {/* OPT 3 */}
                  {selectedFilmagemExternaOpt ===
                    'Ocupação de faixa(s) junto ao meio fio para cena' && (
                    <Wrap>
                      <Textarea
                        name="externa-opt3-info"
                        rows="4"
                        defaultValue="Caso a ocupação da faixa seja em local de estacionamento permitido, a
                      produção deve solicitar uma reserva de vagas iniciando em horário anterior ao da
                      ocupação da faixa para garantir que estará livre no momento da montagem do apoio;
                      caso seja em local de estacionamento proibido, a SPFilm avaliará junto à CET sobre as
                      possíveis opções de canalização que a produção poderá operacionalizar."
                        disabled
                      />
                    </Wrap>
                  )}

                  <Wrap>
                    <Textarea
                      type="text"
                      name="estrutura_filmagem_externa"
                      label="Qual a estrutura que será montada"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.estrutura_filmagem_externa
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <Input
                        type="number"
                        step="0.01"
                        name="dimensao_area_filmagem_externa"
                        label="Qual a dimensão (extensão ou área) da estrutura"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.dimensao_area_filmagem_externa
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                {/* Externa Opts Bloqueio */}
                <ConditionalContainer
                  show={
                    tipoFilmagem === false &&
                    selectedFilmagemExternaOpt?.startsWith('Bloqueio') === true
                  }
                >
                  {/* OPT 4 */}
                  <ConditionalContainer
                    show={
                      selectedFilmagemExternaOpt ===
                      'Bloqueio intermitente de faixa(s) da via para cena'
                    }
                  >
                    <Wrap>
                      <Select
                        name="tipo_bloqueio_externa"
                        label="Tipo de bloqueio"
                        placeholder="Selecione"
                        options={optionsTipoBloqueio}
                        style={selectCustomStyles}
                        onChange={value => {
                          handleSelectChange('tipo_bloqueio_externa', value);
                          setTipoBloqueioAlgumasFaixas(
                            value.value === 'algumas_faixas',
                          );
                        }}
                        required
                        isDisabled={!isAdminRequest}
                      />

                      <ConditionalContainer
                        show={tipoBloqueioAlgumasFaixas === true}
                        style={{ marginRight: 15 }}
                      >
                        <Input
                          type="number"
                          name="numero_faixas_externa"
                          label="Números de faixas"
                          onChange={handleChange}
                          required
                          defaultValue={
                            dadoState?.viewData &&
                            responseLocation?.numero_faixas_externa
                          }
                          disabled={!isAdminRequest}
                        />
                      </ConditionalContainer>
                      <Input
                        type="number"
                        name="periodo_bloqueio_intermitente_externa"
                        label="Período de bloqueio intermitente"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.periodo_bloqueio_intermitente_externa
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>

                  {/* OPT 5 */}
                  <ConditionalContainer
                    show={
                      selectedFilmagemExternaOpt ===
                      'Bloqueio total da via para cena'
                    }
                  >
                    <Wrap>
                      <Textarea
                        name="externa-opt5-info"
                        rows={1}
                        defaultValue="Em casos de bloqueio total da via pode ser solicitado a confecção de faixa de aviso
                        para instalação na via."
                        disabled
                      />
                    </Wrap>
                    <Wrap>
                      <Wrap className="label">
                        <BoxHeader>
                          <span className="boxText">
                            Horário exato do bloqueio
                          </span>
                        </BoxHeader>
                        <Wrap>
                          <Input
                            type="time"
                            name="inicio_horario_bloqueio_externa"
                            label="Das"
                            onChange={handleChange}
                            required
                            defaultValue={
                              dadoState?.viewData &&
                              responseLocation?.inicio_horario_bloqueio_externa
                            }
                            disabled={!isAdminRequest}
                          />
                          <Input
                            type="time"
                            name="fim_horario_bloqueio_externa"
                            label="Às"
                            onChange={handleChange}
                            required
                            defaultValue={
                              dadoState?.viewData &&
                              responseLocation?.fim_horario_bloqueio_externa
                            }
                            disabled={!isAdminRequest}
                          />
                        </Wrap>
                      </Wrap>
                      <span style={{ padding: 8 }} />
                      <Wrap className="label">
                        <BoxHeader>
                          <span className="boxText">
                            Numeração exata da extensão de bloqueio
                          </span>
                        </BoxHeader>
                        <Wrap>
                          <Input
                            type="number"
                            name="inicio_numeracao_bloqueio_externa"
                            label="De"
                            onChange={handleChange}
                            required
                            defaultValue={
                              dadoState?.viewData &&
                              responseLocation?.inicio_numeracao_bloqueio_externa
                            }
                            disabled={!isAdminRequest}
                          />
                          <Input
                            type="number"
                            name="fim_numeracao_bloqueio_externa"
                            label="Até"
                            onChange={handleChange}
                            required
                            defaultValue={
                              dadoState?.viewData &&
                              responseLocation?.fim_numeracao_bloqueio_externa
                            }
                            disabled={!isAdminRequest}
                          />
                        </Wrap>
                      </Wrap>
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá alguma intervenção de arte no local?"
                      onClickTrue={handleIntervencaoArteTrue}
                      onClickFalse={handleIntervencaoArteFalse}
                      selected={intervencaoArte}
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm?.intervencaoArte === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={intervencaoArte === true}>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="desc_intervencao_arte"
                      label="Descreva a(s) intervenção(ões)"
                      onChange={value => {
                        handleChange(value);
                      }}
                      required
                      rows="2"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.desc_intervencao_arte
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                  <Wrap>
                    <File
                      label="Relatório de Arte:"
                      attach={dataRelatorioArteFile}
                      textFile="Nenhum arquivo selecionado"
                      name="relatorio_arte_file"
                      inputRef={relatorioArteFileRef}
                      onChange={handleFileChange}
                      onClick={handleRelatorioArteFile}
                      error={
                        errorsForm?.relatorioArteFile === true && 'Obrigatório'
                      }
                      link={fileArte?.length > 0 && fileArte[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <Select
                      name="ld_equipamento_filmagem"
                      label="Qual a sua estrutura e equipamentos de filmagem:"
                      placeholder="Selecione"
                      options={equipamentosSelectList}
                      style={selectCustomStyles}
                      onChange={handleMultiSelectEquipamentos}
                      required
                      isMulti
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                  <ConditionalContainer show={showOutroEquipamentoField}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="outro_equipamento"
                        label="Outro Equipamento"
                        onChange={handleChange}
                        defaultValue={
                          responseLocation?.locacao_default?.outro_equipamento
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={showDescrEquipamentoFilmagemField}>
                  <Wrap>
                    <Textarea
                      label="Descreva a(s) extensão(ões)"
                      required
                      name="descricoes_equipamento_filmagem"
                      onChange={handleChange}
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.descricoes_equipamento_filmagem
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá gerador?"
                      onClickTrue={handleGeradorTrue}
                      onClickFalse={handleGeradorFalse}
                      selected={gerador}
                      isDisabled={!isAdminRequest}
                      error={errorsForm?.gerador === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={gerador === true}>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Blimpado/Silencioso ou Não-Blimpado?"
                        onClickTrue={handleBlimpadorTrue}
                        onClickFalse={handleBlimpadorFalse}
                        selected={blimpado}
                        isDisabled={!isAdminRequest}
                        error={errorsForm?.blimpado === true && 'Obrigatório'}
                        yesLabelValue="Blimpado"
                        noLabelValue="Não-Blimpado"
                      />
                    </LimiterInputWidth>
                  </ConditionalContainer>
                </Wrap>
                <ConditionalContainer show={gerador === true}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_local_gerador_uuid"
                        label="O gerador ficará:"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        options={localGeradorSelectList}
                        required
                        onChange={value => {
                          handleSelectChange('ld_local_gerador_uuid', value);
                        }}
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Precisará montar Base de Apoio no local?"
                      onClickTrue={handleBaseLocalTrue}
                      onClickFalse={handleBaseLocalFalse}
                      selected={baseLocal}
                      required
                      isDisabled={!isAdminRequest}
                      error={errorsForm.baseLocal === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={baseLocal}>
                  <Wrap style={{ marginBottom: 0 }}>
                    <LimiterInputWidth w50>
                      <Select
                        name="ld_finalidade_base_apoio"
                        label="Selecione as Finalidades"
                        placeholder="Selecione"
                        options={finalidadeSelectList}
                        style={selectCustomStyles}
                        required
                        onChange={handleMultiSelectFinalidades}
                        isMulti
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                    <ConditionalContainer show={showOutroFinalidadeField}>
                      <Input
                        type="text"
                        name="outro_finalidade"
                        label="Outra Finalidade"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.outro_finalidade
                        }
                        disabled={!isAdminRequest}
                      />
                    </ConditionalContainer>
                  </Wrap>

                  <Wrap style={{ alignItems: 'flex-end' }}>
                    <LimiterInputWidth w50>
                      <Input
                        type="text"
                        name="local_base_apoio"
                        label="Descreva local da base de apoio"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.local_base_apoio
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>

                    <LimiterInputWidth w25 style={{ marginRight: 0 }}>
                      <Input
                        type="number"
                        step="0.01"
                        name="dimensao_base_apoio"
                        label="Descreva a dimensão estimada da área"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.dimensao_base_apoio
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>

                    <BoxHeader style={{ justifyContent: 'center', width: 50 }}>
                      <span className="boxText" style={{ color: '#828282' }}>
                        ou
                      </span>
                    </BoxHeader>

                    <LimiterInputWidth w25>
                      <Input
                        type="number"
                        name="quantidade_barracas"
                        label="Descreva a quantidade de barracas a serem montadas"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default.quantidade_barracas
                        }
                        disabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Captação de som direto?"
                      onClickTrue={handleCaptacaoSomDiretoTrue}
                      onClickFalse={handleCaptacaoSomDiretoFalse}
                      selected={captacaoSomDireto}
                      required
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm.captacaoSomDireto === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá som na filmagem?"
                      onClickTrue={handleSomFilmagemTrue}
                      onClickFalse={handleSomFilmagemFalse}
                      selected={somFilmagem}
                      required
                      error={errorsForm?.somFilmagem === true && 'Obrigatório'}
                      isDisabled={!isAdminRequest}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={somFilmagem}>
                  <Wrap>
                    <LimiterInputWidth w75>
                      <Select
                        name="ld_som_filmagem"
                        label="Sons de Filmagem"
                        placeholder="Selecione"
                        style={selectCustomStyles}
                        required
                        options={somFilmagemSelectList}
                        onChange={handleMultiSelectSomFilmagem}
                        isMulti
                        isDisabled={!isAdminRequest}
                      />
                    </LimiterInputWidth>
                  </Wrap>

                  <ConditionalContainer show={showOutroSomFilmagemField}>
                    <Wrap>
                      <Input
                        type="text"
                        name="outro_som_filmagem"
                        label="Outro Som de Filmagem"
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.outro_som_filmagem
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>

                  <ConditionalContainer show={showDescrSomFilmagemField}>
                    <Wrap>
                      <Textarea
                        label="Descreva a(s) característica(s)"
                        required
                        name="descricoes_som_filmagem"
                        onChange={handleChange}
                        rows="3"
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default
                            ?.descricoes_som_filmagem
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w25>
                    <ChoiceButtonLocacao
                      label="Drone"
                      onClickTrue={handleDroneTue}
                      onClickFalse={handleDroneFalse}
                      selected={drone}
                      isDisabled={!isAdminRequest}
                      error={errorsForm?.drone === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                {drone === true && (
                  <Wrap>
                    <Textarea
                      name="autorizacao_drones"
                      onChange={handleChange}
                      rows="4"
                      value="Para filmagens com drones: o Espaço Aéreo não é de administração municipal, mas sim da
                      Federação/União, portanto, não faz parte do campo de atuação da SPFilm. A autorização para o uso
                      de drones deve ser solicitada diretamente à Agência Nacional de Aviação Civil (ANAC), mediante
                      cadastro no Sistema SARPAS, disponibilizado no site do Departamento de Controle do Espaço Aéreo
                      (DECEA)."
                      disabled
                    />
                  </Wrap>
                )}

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Efeitos Especiais"
                      onClickTrue={handleEfeitosEspeciaisTrue}
                      onClickFalse={handleEfeitosEspeciaisFalse}
                      selected={efeitosEspeciais}
                      isDisabled={!isAdminRequest}
                      error={
                        errorsForm?.efeitosEspeciais === true && 'Obrigatório'
                      }
                    />
                  </LimiterInputWidth>

                  <ConditionalContainer show={efeitosEspeciais === true}>
                    <Select
                      name="ld_efeitos_especiais_uuid"
                      label="Efeitos Especiais"
                      placeholder="Selecione"
                      style={selectCustomStyles}
                      options={efeitoEspecialSelectList}
                      onChange={value => {
                        handleSelectChange('ld_efeitos_especiais_uuid', value);
                      }}
                      required
                      isDisabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá projeção visível a partir de logradouro público?"
                      onClickTrue={handleProjecaoTrue}
                      onClickFalse={handleProjecaoFalse}
                      selected={projecao}
                      isDisabled={!isAdminRequest}
                      error={errorsForm?.projecao === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <Wrap>
                  <ConditionalContainer show={projecao === true}>
                    <File
                      label="Campo para upload do conteúdo da projeção"
                      attach={dataConteudoProjecaoFile}
                      textFile="Nenhum arquivo selecionado"
                      name="conteudo_projecao_file"
                      inputRef={conteudoProjecaoRef}
                      onChange={handleFileChange}
                      onClick={handleProjecaoFile}
                      error={
                        errorsForm?.conteudoProjecaoFile === true &&
                        'Obrigatório'
                      }
                      link={fileProjecao?.length > 0 && fileProjecao[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </ConditionalContainer>
                </Wrap>

                <ConditionalContainer show={projecao === true}>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Será projetado em imóvel público ou privado?"
                        onClickTrue={handlePublicoPrivadoTrue}
                        onClickFalse={handlePublicoPrivadoFalse}
                        selected={publicoPrivado}
                        isDisabled={!isAdminRequest}
                        error={
                          errorsForm?.publicoPrivado === true && 'Obrigatório'
                        }
                        yesLabelValue="Público"
                        noLabelValue="Privado"
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="estrutura_projecao"
                      label="Descreva a estrutura para projeção"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default?.estrutura_projecao
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                  <Wrap>
                    <Textarea
                      type="text"
                      name="instalacao_estrutura_projecao"
                      label="Descreva local de instalação para estrutura de projeção"
                      onChange={handleChange}
                      required
                      rows="3"
                      defaultValue={
                        dadoState?.viewData &&
                        responseLocation?.locacao_default
                          ?.instalacao_estrutura_projecao
                      }
                      disabled={!isAdminRequest}
                    />
                  </Wrap>
                  <Wrap>
                    <LimiterInputWidth w50>
                      <ChoiceButtonLocacao
                        label="Haverá exibição de logomarca na projeção? Se sim, qual porcentagem ocupará da área total da projeção e por quanto tempo será projetada?"
                        onClickTrue={handleProjecaoLogoTrue}
                        onClickFalse={handleProjecaoLogoFalse}
                        selected={projecaoLogo}
                        isDisabled={!isAdminRequest}
                        error={
                          errorsForm?.projecaoLogo === true && 'Obrigatório'
                        }
                      />
                    </LimiterInputWidth>
                  </Wrap>
                  <ConditionalContainer show={projecaoLogo === true}>
                    <Wrap>
                      <Input
                        type="number"
                        step="0.01"
                        name="porcentagem_projecao"
                        label="Porcentagem que a projeção irá ocupar"
                        onChange={handleChange}
                        required
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default
                            ?.porcentagem_projecao
                        }
                        disabled={!isAdminRequest}
                      />
                      <Input
                        type="time"
                        name="area_tempo_projeção"
                        label="Tempo de Projeção"
                        required
                        onChange={handleChange}
                        defaultValue={
                          dadoState?.viewData &&
                          responseLocation?.locacao_default?.area_tempo_projeção
                        }
                        disabled={!isAdminRequest}
                      />
                    </Wrap>
                  </ConditionalContainer>
                </ConditionalContainer>

                <Wrap>
                  <LimiterInputWidth w50>
                    <ChoiceButtonLocacao
                      label="Haverá desligamento de iluminação no local?"
                      onClickTrue={handleDesligarLuzTrue}
                      onClickFalse={handleDesligarLuzFalse}
                      selected={desligarLuz}
                      isDisabled={!isAdminRequest}
                      error={errorsForm?.desligarLuz === true && 'Obrigatório'}
                    />
                  </LimiterInputWidth>
                </Wrap>

                <ConditionalContainer show={desligarLuz}>
                  <Wrap>
                    <File
                      label="Arquivo do local:"
                      attach={dataIluminacaoFile}
                      textFile="Nenhum arquivo selecionado"
                      name="iluminacao_file"
                      inputRef={iluminacaoFileRef}
                      onChange={handleFileChange}
                      onClick={handleIluminacaoFile}
                      error={
                        errorsForm?.iluminacaoFile === true && 'Obrigatório'
                      }
                      link={fileLuz?.length > 0 && fileLuz[0].url}
                      isDisabled={!isAdminRequest}
                    />
                  </Wrap>
                </ConditionalContainer>

                <Wrap>
                  <Input
                    type="text"
                    name="especificidades"
                    label="Descreva especificidades não contempladas nas perguntas acima"
                    onChange={handleChange}
                    defaultValue={
                      dadoState?.viewData &&
                      responseLocation?.locacao_default?.especificidades
                    }
                    disabled={!isAdminRequest}
                  />
                </Wrap>

                <Wrap style={{ flexDirection: 'column' }}>
                  {dadoState?.viewData && reservasVagas ? (
                    <>
                      <LimiterInputWidth w50>
                        <Select
                          name="reservas_vagas_locacao"
                          label="Reservas de Vagas"
                          placeholder="Selecione"
                          options={responseLocation?.locacao_via_externa?.map(
                            ({
                              uuid,
                              address,
                              number,
                              neighborhood,
                              endpoint,
                            }) => ({
                              label: `${address}, ${number}, ${neighborhood}`,
                              value: { uuid, endpoint },
                            }),
                          )}
                          style={selectCustomStyles}
                          onChange={value => {
                            handleSelectChange('reservas_vagas_locacao', value);
                          }}
                        />
                      </LimiterInputWidth>

                      {formData?.reservas_vagas_locacao && (
                        <Wrap>
                          <FormChoiceButton
                            nomeProjeto={dadoState?.nome_projeto}
                            editData={{
                              is_admin: isAdminRequest,
                              uuid: formData?.reservas_vagas_locacao?.uuid,
                              endpoint: 'reservas-vagas-vinculado',
                            }}
                          />
                        </Wrap>
                      )}
                    </>
                  ) : (
                    <ChoiceButtonLocacao
                      label="Necessita de reserva de vagas em via próxima?"
                      onClickTrue={handleReservasVagasTrue}
                      onClickFalse={handleReservasVagasFalse}
                      selected={reservasVagas}
                      required
                      isDisabled
                      error={errorsForm.reservasVagas === true && 'Obrigatório'}
                    />
                  )}
                </Wrap>
              </WhiteBox>

              <Chat
                uuid={responseLocation?.locacao_default?.uuid}
                chatKeyFilter="locacao_default"
                locacaoNameView={responseLocation?.name_view}
              />

              <Button
                type="submit"
                btType="#26A2B1"
                label="Enviar"
                full
                width="140px"
                loading={loading}
                style={{ position: 'relative', bottom: '35px' }}
                disabled={loading}
              />
            </Form>
          </PageContent>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <Header>
        <LogoIcon />
        {!isAdminRequest && <NavBarHeader />}
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Filmagens (ou apoio) em ruas/calçadas/vias</h1>
            {!isAdminRequest && (
              <span>
                Home | Obras | {dadoState?.nome_projeto} | Locações | Filmagens
                (ou apoio) em ruas/calçadas/vias
              </span>
            )}
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      {renderLocacao()}

      <PageFooter>
        <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>

      <ModalPorteFilmagem
        openModal={openModalPorteFilmagem}
        closeModal={() => setOpenModalPorteFilmagem(false)}
      />
    </>
  );
}
