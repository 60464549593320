import React from 'react';
import { Link } from 'react-router-dom';
import BannerHeader from '~/components/BannerHeader';
import Header from '~/components/Header';
import LogoIcon from '~/components/LogoIcon';
import clientResources from '~/data/clientResources';
import {
  BannerContent,
  PageContent,
  PageFooter,
  TextBanner,
} from '~/pages/Usuario/styles';
import { PageContainer } from '~/styles/components';
import { Content } from './styles';

export const NotFound = () => {
  return (
    <>
      <Header>
        <LogoIcon disabled />
      </Header>
      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Página não encontrada</h1>
          </TextBanner>
        </BannerContent>
      </BannerHeader>
      <PageContainer style={{ backgroundColor: '#fff' }}>
        <Content>
          <h1>
            A página solicitada não foi encontrada. <br /> Mas você pode
            continuar utilizando o sistema clicando{' '}
            <Link to="/escolha-acesso">aqui</Link>
          </h1>
        </Content>
      </PageContainer>
      <PageFooter style={{ position: 'absolute', bottom: 0, left: 0 }}>
      <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
};
