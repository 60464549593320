import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const LimiterInputWidth = styled.div`
  display: flex;
  margin-right: 16px;
  ${(props) => (props.w25 ? "width: 24%;" : "")}
  ${(props) => (props.w50 ? "width: 49%;" : "")}

  &:nth-child(4) {
    margin-right: 0;
  }
`;
