import styled from "styled-components";

export const ModalDocumentsStyled = styled.div`
  position: fixed;
  width: 800px;
  max-height: 900px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 15%);
  -webkit-box-shadow: 0px 5px 5px rgb(0 0 0 / 15%);
  border-radius: 10px;
  z-index: 999;
  overflow: hidden;
  padding-bottom: 20px;
`;
