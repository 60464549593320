import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

import CookieConsentBanner from '~/components/CookieConsentBanner';
import './config/ReactotronConfig';
import { store, persistor } from './store';

import AppProvider from '~/hooks';

import GlobalStyle from '~/styles/global';
import history from '~/services/history';
import Routes from '~/routes';

import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from './styles/ThemeProvider';

const App = () => (
  <BrowserRouter forceRefresh>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppProvider>
          <ThemeProvider>
            <GlobalStyle />
            <ToastContainer />
            <Routes history={history} />
            <CookieConsentBanner />
          </ThemeProvider>
        </AppProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

export default App;
