/* eslint-disable no-unused-vars */
import { call, put, select } from 'redux-saga/effects';
import { showToastMessage } from '~/services/notification';

import api, { makeOptions } from '~/services/api';
import WorkActions from '../ducks/work';

const dataLocal = JSON.parse(localStorage.getItem('persist:Spcine'));

export function* create(action) {
  try {
    const { data: post } = action;
    const { data } = yield call(
      api.post,
      '/work',
      post,
      makeOptions(dataLocal.auth.data),
    );

    yield put(WorkActions.createSuccess(data));

    showToastMessage({
      type: 'success',
      text: 'Obra cadastrada com sucesso',
    });
  } catch (err) {
    yield put(WorkActions.createFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* show(action) {
  try {
    // const authData = yield select(getAuthData);
    const { uuid } = action;
    const { data } = yield call(
      api.get,
      `/work/${uuid}`,
      makeOptions(dataLocal.auth.data),
    );
    yield put(WorkActions.showSuccess(data));
  } catch (err) {
    yield put(WorkActions.showFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* edit(action) {
  try {
    // const authData = yield select(getAuthData);
    const { data: post } = action;
    const { data } = yield call(
      api.patch,
      `/work/${post.uuid}`,
      post,
      makeOptions(dataLocal.auth.data),
    );

    yield put(WorkActions.editSuccess(data));
    showToastMessage({
      type: 'success',
      text: 'Informações atualizadas com sucesso',
    });
  } catch (err) {
    yield put(WorkActions.editFailure(err.response.data.error.message));
    showToastMessage({
      type: 'error',
      text: err.response.data.error.message,
    });
  }
}

export function* destroy(action) {
  try {
    const { uuid } = action;
    // const authData = yield select(getAuthData);

    const { data: response } = yield call(
      api.delete,
      `work/${uuid}`,
      makeOptions(dataLocal.auth.data),
    );

    showToastMessage({
      type: 'success',
      text: 'Obra removida com sucesso',
    });
    yield put(WorkActions.deleteSuccess(response));
  } catch (err) {
    yield put(WorkActions.deleteFailure('Erro ao se comunicar com a API.'));
  }
}
