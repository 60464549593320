import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-x: none;
  background-color: #f2f2f2;
  padding: 25px 25px 0 45px;
  margin: 0 auto;

  table {
    width: 100%;
    margin-top: 32px;

    thead {
      width: 100%;
      padding-right: 40px;
      padding-left: 40px;
      background: #ffffff;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);

      th {
        color: #323c47;
      }
    }
  }
`;

export const TableTopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0 24px 0;

  h2 {
    font-family: "PoppinsBold", sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #323c47;
  }
`;
