import React from 'react';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { FaCloudDownloadAlt } from 'react-icons/fa';

import Button from '~/components/Button';

import { PageContainer } from '~/styles/components';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { ButtonBox, Wrap } from './styles';
import LogoIcon from '~/components/LogoIcon';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import clientResources from '~/data/clientResources';

export default function FinalizacaoCadastro() {
  return (
    <>
      <Header>
        <LogoIcon disabled />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>Finalização de Cadastro</h1>
            <span>Home | Cadastro de Obras | Finalização de Cadastro</span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      <PageContainer>
        <PageContent>
          <WhiteBox column jCenter aCenter marginBottom="30px">
            <Wrap>
              <span style={{ color: 'black', fontSize: 16 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse sed purus velit.
              </span>
              <span style={{ color: 'black' }} tooltip="Finalize o cadastro">
                <AiFillExclamationCircle />
              </span>
            </Wrap>
            <BoxHeader>
              <span className="boxText">
                Estimativa de gastos na cidade de São Paulo
              </span>
            </BoxHeader>
            <Wrap style={{ justifyContent: 'space-between', color: 'black' }}>
              <span>Arquivo.pdf</span>
              <button type="button">
                <FaCloudDownloadAlt size={20} />
              </button>
            </Wrap>

            <Wrap style={{ justifyContent: 'space-between', color: 'black' }}>
              <span>Arquivo.pdf</span>
              <button type="button">
                <FaCloudDownloadAlt size={20} />
              </button>
            </Wrap>
          </WhiteBox>

          <ButtonBox>
            <Button btType="#4F4F4F" label="Voltar" full width="150px" />
            <Button
              label="Concluir"
              btType="#26A2B1"
              type="submit"
              full
              width="157px"
            />
          </ButtonBox>
        </PageContent>
      </PageContainer>

      <PageFooter>
      <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}
