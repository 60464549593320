import diff from 'diff-arrays-of-objects';
import diffInnerHorasFinalidade from './diffInnerHorasFinalidade';

export default function _({
  permVeicCenicoLocacao,
  permVeicCenicoInput,
}) {
  // se opção não foi gravada no banco antes
  if (Object.values(permVeicCenicoLocacao || []).length <= 0 &&
    Object.values(permVeicCenicoInput || []).length <= 0) {
    return []
  }

  // se a opção não for mais selecionada
  if (Object.values(permVeicCenicoInput || []).length <= 0) {
    return permVeicCenicoLocacao?.map(({uuid, hour_acesso_calcadoes}) => {
      const horas = hour_acesso_calcadoes
        .map(hora => ({ uuid: hora.uuid, inicio: '', fim: ''}));
      return { uuid, placa: '', modelo_veiculo: '', horas }
    });
  }

  // cria listas temporarias dos cards sem as horas
  const permVeicCenicoLocacaoTmp = permVeicCenicoLocacao?.map(({uuid, placa, modelo_veiculo, hour_acesso_calcadoes, ld_finalidade_acesso}) => 
    ({ uuid, placa, modelo_veiculo, horas: hour_acesso_calcadoes, finalidade_acesso_uuid: ld_finalidade_acesso?.uuid }));
  const permVeicCenicoInputTmp = permVeicCenicoInput.map(({uuid, placa, modelo_veiculo, horas, finalidade_acesso_uuid}) => 
    ({ uuid: uuid || '', placa, modelo_veiculo, horas, finalidade_acesso_uuid }));  

  // ve se tem diferença entre os cards
  const results = diff(permVeicCenicoLocacaoTmp, permVeicCenicoInputTmp, 'uuid');
  const { updated, added, removed } = results;

  // verifica e faz uma nova saída adicionando as horas para cards que foram atualizados
  const cardsAtualizados = permVeicCenicoLocacaoTmp?.map(permVeicCenico => {
    const permVeicCenicoUpdated = updated.find(({uuid}) => permVeicCenico.uuid === uuid)

    if (!permVeicCenicoUpdated) {
      return null;
    }
    
    const horas = diffInnerHorasFinalidade({
      innerHorasLocacao: permVeicCenico.horas,
      innerHorasInput: permVeicCenicoUpdated.horas
    });

    return {...permVeicCenicoUpdated, horas}
  });

  // verifica e faz uma nova saída adicionando as horas para cards que foram removidos
  const cardsRemovidos = removed.map(({uuid}) => {
    const horas = permVeicCenicoLocacao
      ?.find(permVeicCenico => permVeicCenico.uuid === uuid)
      ?.hour_acesso_calcadoes
      ?.map(hora => ({ uuid: hora.uuid, inicio: '', fim: '' }));
  
    return { uuid, placa: '', modelo_veiculo: '', horas };
  });

  const permVeicCenicoDiff = added.concat(cardsAtualizados, cardsRemovidos).filter(item => !!item);
  return permVeicCenicoDiff;  
}
