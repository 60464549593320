/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useContext } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import OnOutsiceClick from 'react-outclick';
import { FiMail } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { AiOutlineLogout, AiOutlineSwap, AiOutlineMenu } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import './styles.css';

import { useCurrentUser } from '~/hooks/currentUser';
import { useAuth } from '~/hooks/auth';
import { ThemeContext } from '~/styles/ThemeProvider';

function NavBarHeader({ data }) {
  const { signOut } = useAuth();
  const { user } = useCurrentUser();
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { theme } = useContext(ThemeContext);

  const hist = useHistory();

  function logout() {
    signOut();
  }

  return (
    <>
      <div
        style={{
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
            marginRight: 10,
            textAlign: 'center',
            alignContent: 'center',
            top: '50%',
            marginTop: 10,
            color: '#fff',
            fontSize: 17,
          }}
        >
          {user && (user?.name || user?.fantasy_name)}
        </span>
        <FaUserCircle
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            marginRight: 10,
          }}
          alt="avatar"
        />
        <nav className="navbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <button type="button" onClick={() => setOpen(!open)}>
                <AiOutlineMenu size={40} color="#fff" />
              </button>

              {open && (
                <OnOutsiceClick onOutsideClick={() => setOpen(false)}>
                  <div
                    className="dropdown"
                    ref={dropdownRef}
                    style={{
                      backgroundColor: theme.horizontal_bar_color,
                    }}
                  >
                    <div className="menu">
                      {/* <a href="/perfil" className="menu-item">
                        <span className="icon-button">
                          <CgProfile />
                        </span>
                        Perfil
                      </a> */}
                      {hist && hist?.location?.pathname !== '/perfil' && (
                        <a href="/perfil" className="menu-item">
                          <span className="icon-button">
                            <CgProfile />
                          </span>
                          Perfil
                        </a>
                      )}
                      {hist && (
                        <Link
                          to={{
                            pathname: 'solicitacao-nova-carta-de-apoio',
                            state: { trigger: 'menu_header' },
                          }}
                          className="menu-item"
                        >
                          <span className="icon-button">
                            <FiMail />
                          </span>
                          Carta de Apoio
                        </Link>
                      )}

                      <a href="/cadastro-escolha" className="menu-item">
                        <span className="icon-button">
                          <AiOutlineSwap />
                        </span>
                        Trocar Acesso
                      </a>
                      <div id="logout-item" onClick={() => logout()}>
                        <span
                          className="icon-button"
                          style={{ marginRight: '0.5rem' }}
                        >
                          <AiOutlineLogout />
                        </span>
                        Logout
                      </div>
                    </div>
                  </div>
                </OnOutsiceClick>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default withRouter(NavBarHeader);
