export default [
  {
    label:
      'Filmagens (ou apoio) em ruas, calçadas, vias, viadutos, passarelas, túneis, correlatos...',
    value: 'Locação Utilização de Via',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Endereço',
  },
  {
    label: 'Reservas de Vagas para Filmagens Internas',
    value: 'Locação Reserva de Vaga',

    espacoComponentElement: 'input',
    espacoComponentLabel: 'Rua',
  },
  // {
  //   label: 'Reservas de Vagas Vinculado',
  //   espacoComponentElement: 'input',
  //   espacoComponentLabel: 'Rua',
  // },
  {
    label: 'Acesso ou Permanência de Veículos - Calçadões',
    value: 'Locação Acesso Calçadões',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Endereço',
  },
  {
    label: 'Filmagens no Autódromo de Interlagos',
    value: 'Locação Autódromo',
    espacoRoute: 'ld-lista-autodromo',
    espacoName: 'ld_lista_autodromo',
    espacoComponentElement: 'select',
  },
  {
    label: 'Trajeto de Câmera Car',
    value: 'Locação Camera Car',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Endereço',
  },
  {
    label: 'Filmagens em Cemitérios',
    value: 'Locação Cemitério',
    espacoRoute: 'ld-cemiterio',
    espacoName: 'ld_cemiterio',
    espacoComponentElement: 'select',
  },
  {
    label:
      'Filmagens em Edifícios-Sede da Prefeitura (Ed. Matarazzo, Sampaio Moreira, etc)',
    value: 'Locação Edifício',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Nome Edifício',
  },
  {
    label:
      'Filmagens em Espaços de Cultura (Centros Culturais, Casas de Cultura, Bibliotecas, Arquivo Histórico, Museu da Cidade, etc)',
    value: 'Locação Espaços Cultura',
    espacoRoute: 'ld-equipamentos-smc',
    espacoName: 'ld_equipamentos_smc',
    espacoComponentElement: 'select',
    subEspaco: {
      Bibliotecas: {
        subEspacoName: 'ld_biblioteca',
        subEspacoRoute: 'ld-biblioteca',
      },
      'Casas de Cultura': {
        subEspacoName: 'ld_casas_cultura',
        subEspacoRoute: 'ld-casas-cultura',
      },
      'Centros Culturais': {
        subEspacoName: 'ld_centros_culturais',
        subEspacoRoute: 'ld-centro-cultural',
      },
      'Espaços do Museu da cidade de São Paulo': {
        subEspacoName: 'ld_museu',
        subEspacoRoute: 'ld-museu',
      },
    },
  },
  {
    label:
      'Filmagem em Espaços da Secretaria de Assistência e Desenvolvimento Social (Centro de Acolhida, etc)',
    value: 'Locação Espaços SMAD',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Endereço',
  },
  {
    label: 'Filmagens em Equipamentos da Educação (CEU, EMEI, EMEF etc)',
    value: 'Locação Equipamento Educação',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Nome Equipamento Educação',
  },
  {
    label:
      'Filmagens em Equipamentos Esportivos (Clubes Escola, Centro de Esportes, etc)',
    value: 'Locação Filmagem Esportivo',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Centro Esportivo',
  },
  {
    label: 'Filmagens em Equipamentos da Saúde (Hospitais, UBSs, etc)',
    value: 'Locação Equipamentos Saúde',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Nome Equipamento',
  },
  {
    label: 'Filmagens em Feiras Livres',
    value: 'Locação Feira Livre',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Endereço',
  },
  {
    label: 'Galerias',
    value: 'Locação Galeria',
    espacoRoute: 'ld-galeria',
    espacoName: 'ld_galeria',
    espacoComponentElement: 'select',
  },
  {
    label: 'Filmagens em Mercados e Sacolões',
    value: 'Locação Filmagem Mercado',
    espacoRoute: 'l-mercado',
    espacoName: 'ld_mercado',
    espacoComponentElement: 'select',
  },
  {
    label: 'Filmagens em Parques',
    value: 'Locação Filmagem Parque',
    espacoRoute: 'l-parque',
    espacoName: 'ld_parque',
    espacoComponentElement: 'select',
  },
  {
    label: 'Filmagens em Planetários',
    value: 'Locação Planetário',
    espacoRoute: 'ld-planetario',
    espacoName: 'ld_planetario',
    espacoComponentElement: 'select',
  },
  {
    label: 'Praças',
    value: 'Locação Praça Pública',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Endereço/Local/Praça',
  },
  {
    label: 'Projeções em Logradouro Público com Estrutura Interna',
    value: 'Locação Projeção Pública',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Rua',
  },
  {
    label: 'Teatros',
    value: 'Locação Teatro',
    espacoRoute: 'ld-teatro',
    espacoName: 'ld_teatros',
    espacoComponentElement: 'select',
  },
  {
    label: 'Filmagens em Terminais de Ônibus',
    value: 'Locação Terminal',
    espacoRoute: 'ld-terminal',
    espacoName: 'ld_terminais',
    espacoComponentElement: 'select',
  },
  {
    label: 'Outros Espaços',
    value: 'Locação Outros Espaços',
    espacoComponentElement: 'input',
    espacoComponentLabel: 'Endereço',
  },
];
