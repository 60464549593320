import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, useLocation, Link } from 'react-router-dom';
import './styles.css';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Carousel from 'react-elastic-carousel';

import { BiLoaderAlt } from 'react-icons/bi';
import { FiLoader } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';

import ApiClient from '~/utils/ApiClient';
import { showToastMessage } from '~/services/notification';

import {
  ApprovalContainer,
  GridContainer,
  PageContainer,
  TableHeader,
} from '~/styles/components';

import {
  Documento,
  ListaPageLocacoes,
  NavBarHeader,
} from '~/components/Usuario';

import {
  BannerContent,
  TextBanner,
  PageContent,
  PageFooter,
  BoxHeader,
} from '~/pages/Usuario/styles';

import { DataCell, MainLoading, Wrap } from './styles';
import Button from '~/components/Button';
import Chat from '~/components/Chat/chat';

import manualMarca from '~/assets/documets/Manual_Marca.pdf';
import tabelaDescontos from '~/assets/documets/Tabela_Descontos_Formato.pdf';
import ilhacineTabelaDescontos from '~/assets/documets/Tabela_Descontos_Ilhabela.pdf';
import LogoIcon from '~/components/LogoIcon';
import ListaPageVisitasTecnicas from '~/components/Admin/Home/ListaPageVisitasTecnicas';
import Header from '~/components/Header';
import BannerHeader from '~/components/BannerHeader';
import WhiteBox from '~/components/WhiteBox';
import validateDate from '~/functions/validateDate';
import clientResources from '~/data/clientResources';

const comentarios = [
  {
    id: 1,
    img: `https://i.pinimg.com/originals/a4/4a/f3/a44af3bb5f074e3cdb4be8a56232c996.jpg`,
    user_name: `Aang`,
    file_name: `Comprovante de Pagamento`,
    comentario: `Segue o comprovante de pagamento da reserva`,
  },
  {
    id: 2,
    img: `https://i.pinimg.com/564x/3f/a8/fc/3fa8fcbb479d7811871071c1691e3629.jpg`,
    user_name: `Edward`,
    file_name: `Guia de Horários`,
  },
];

const documentos = [
  {
    id: 1,
    name: `Manual da Marca`,
    file: `Manual_Marca.pdf`,
    url: `${process.env.PUBLIC_URL}${manualMarca}`,
  },
  {
    id: 2,
    name: `Tabela de Descontos por Formato`,
    file: `Tabela_Descontos_Formato.pdf`,
    url: clientResources.tenant === 'ilhacine' ? `${process.env.PUBLIC_URL}${ilhacineTabelaDescontos}` : `${process.env.PUBLIC_URL}${tabelaDescontos}`,
  },
];

function Obras() {
  const [loading, setLoading] = useState(false);
  const [listLocacoes, setListLocacoes] = useState([]);
  const [listVisitasTecnicas, setListVisitasTecnicas] = useState([]);
  const [page, setPage] = useState(1);
  const [totPage, setTotPage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [responseObra, setResponseObra] = useState({});
  const [hasApprovedFieldsFormato, setHasApprovedFieldsFormato] =
    useState(false);
  const [hasApprovedFieldsVeiculacao, setHasApprovedFieldsVeiculacao] =
    useState(false);

  const { state } = useLocation();
  const { dados } = state;

  // --- pagination --- inicio
  const handleChangePaginator = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }));

  function BasicPagination() {
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <Pagination
          count={totPage}
          page={page}
          onChange={handleChangePaginator}
        />
      </div>
    );
  }
  // --- pagination --- fim

  const fetchObra = useCallback(async () => {
    try {
      setLoading(true);

      const resultObra = await ApiClient.doRequest({
        uri: `work/${dados.uuid}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const obraData = resultObra.data.data;

      const hasChangesFormato = Object.keys(obraData?.work_type).concat(
        Object.keys(obraData?.visit_date),
        Object.keys(obraData?.visit_date_fim),
        Object.keys(obraData?.previsao_estreia),
      );

      const hasChangesVeiculacao = Object.keys(obraData?.ld_veiculacao_inicial);

      setHasApprovedFieldsFormato(
        hasChangesFormato.includes('changes_to_approve'),
      );
      setHasApprovedFieldsVeiculacao(
        hasChangesVeiculacao.includes('changes_to_approve'),
      );

      setResponseObra(obraData);
    } catch (err) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao tentar buscar os dados da Obra',
      });
    } finally {
      setLoading(false);
    }
  }, [dados.uuid]);

  useEffect(() => fetchObra(), [fetchObra]);

  const filterLocacoes = useCallback(async () => {
    try {
      setLoading(true);

      const resultLBW = await ApiClient.doRequest({
        uri: `work/locacoes-by-work/${responseObra.uuid}?page=${page}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const locacoesByWork = resultLBW?.data?.data;
      setTotPage(resultLBW.data.last_page);

      const obraLocacoesList = locacoesByWork?.map(ilocacao => {
        return {
          uuid: ilocacao.uuid,
          locacaoDefaultUuid: ilocacao.locacao_default?.uuid,
          locacaoNumber: ilocacao.protocol,
          name: ilocacao.titulo_locacao || ilocacao.name,
          type: ilocacao.name_view,
          status:
            ilocacao?.locacao_default?.locacao_status?.name ||
            ilocacao?.locacao_status?.name,
          endpoint: ilocacao.endpoint,
          hasUnreadDocChat:
            ilocacao?.locacao_default?.comments_locacao?.some(
              ({ lido }) => lido === 0,
            ) || ilocacao?.comments_locacao?.some(({ lido }) => lido === 0),
        };
      });

      setListLocacoes(obraLocacoesList);
    } catch (err) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao buscar locações de Obra',
      });
    } finally {
      setLoading(false);
    }
  }, [responseObra, page]);

  const filterVisitaTecnica = useCallback(async () => {
    try {
      setLoading(true);

      const resultVT = await ApiClient.doRequest({
        uri: `visita-tecnica?page=${page}`,
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      const visitasTecnicas = resultVT?.data?.data;
      setTotPage(resultVT.data.last_page);
      setListVisitasTecnicas(visitasTecnicas);
    } catch (err) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao buscar Visitas Técnicas',
      });
    } finally {
      setLoading(false);
    }
  }, [responseObra, page]);

  useEffect(() => {
    switch (tabIndex) {
      case 1:
        filterLocacoes();
        break;
      case 2:
        filterVisitaTecnica();
        break;
      default:
        break;
    }
  }, [filterLocacoes, filterVisitaTecnica, tabIndex]);

  useEffect(() => setPage(1), [tabIndex]);

  function renderLocacao() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && !listLocacoes.length) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {listLocacoes?.map(lista => (
          <ListaPageLocacoes
            key={lista?.uuid}
            data={lista}
            nomeProjeto={responseObra?.nome_projeto}
          />
        ))}
      </>
    );
  }

  function renderVisitaTecnica() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && !listVisitasTecnicas.length) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {listVisitasTecnicas?.map(lista => (
          <ListaPageVisitasTecnicas key={lista?.uuid} data={lista} />
        ))}
      </>
    );
  }

  return (
    <>
      {loading && (
        <MainLoading loading={loading}>
          <h3>Processando Informações</h3>
          <BiLoaderAlt size={32} color="#ffffff" className="icon-spin" />
        </MainLoading>
      )}
      <Header>
        <LogoIcon />
        <NavBarHeader />
      </Header>

      <BannerHeader>
        <BannerContent>
          <TextBanner>
            <h1>{responseObra && responseObra?.nome_projeto}</h1>
            <span>
              Home| Obras | {responseObra && responseObra?.nome_projeto}
            </span>
          </TextBanner>
        </BannerContent>
      </BannerHeader>

      <PageContainer>
        <PageContent>
          <WhiteBox>
            <Tabs
              style={{ width: '100%' }}
              selectedIndex={tabIndex}
              onSelect={index => setTabIndex(index)}
            >
              <TabList style={{ border: 0 }}>
                <Tab>Obra</Tab>
                <Tab>Locações</Tab>
                <Tab>Visitas Técnicas</Tab>
              </TabList>

              <TabPanel>
                <BoxHeader>
                  <span className="boxText">Documentos</span>
                </BoxHeader>
                <Wrap>
                  <Carousel
                    style={{ width: '100%' }}
                    showArrows={false}
                    itemsToShow={3}
                  >
                    {documentos.map(lista => {
                      if (clientResources.tenant === 'ilhacine' && lista.id === 1) return (<></>);
                      return (
                      <>
                        <Documento key={lista.id} data={lista} />
                      </>
                    )})}

                    {responseObra?.files != null &&
                      responseObra?.files.map(lista => (
                        <Documento key={lista.uuid} data={lista} />
                      ))}
                  </Carousel>
                </Wrap>

                <Chat uuid={responseObra?.uuid} chatKeyFilter="work" />

                <BoxHeader>
                  <span className="boxText">Resumo</span>
                </BoxHeader>

                <div>
                  <Wrap>
                    <GridContainer cols={4}>
                      <DataCell>
                        <strong>Data de cadastro</strong>
                        <span>
                          {responseObra?.created_at
                            ? format(
                                parseISO(responseObra?.created_at),
                                'dd/MM/yyyy HH:mm',
                              )
                            : ''}
                        </span>
                      </DataCell>

                      <DataCell>
                        <strong>Nº da Obra</strong>
                        <span>{responseObra && responseObra?.protocol}</span>
                      </DataCell>

                      <DataCell>
                        <strong>Nome da Obra</strong>
                        <span>{responseObra && responseObra?.titulo_obra}</span>
                      </DataCell>

                      <DataCell>
                        <strong>Nome da Produtora</strong>
                        <span>
                          {responseObra &&
                            (responseObra?.branch?.fantasy_name ||
                              responseObra?.pessoa_fisica_user
                                ?.user_personal_data?.name)}
                        </span>
                      </DataCell>
                    </GridContainer>
                  </Wrap>

                  <Wrap>
                    <ApprovalContainer
                      hasApprovalFields={hasApprovedFieldsFormato}
                    >
                      {hasApprovedFieldsFormato && (
                        <BoxHeader>
                          <span className="boxText">
                            Alterações a serem analisadas pela SPFilm
                          </span>
                        </BoxHeader>
                      )}
                      <GridContainer cols={4}>
                        <DataCell>
                          <strong>Formato da Obra</strong>
                          <span>
                            {responseObra &&
                              responseObra?.work_type?.value?.name}
                          </span>
                        </DataCell>

                        <DataCell>
                          <strong>
                            Data ou previsão de início das filmagens
                          </strong>
                          <span>
                            {responseObra &&
                            validateDate(
                              responseObra?.visit_date?.value,
                              'yyyy-MM-dd HH:mm:ss',
                            )
                              ? format(
                                  new Date(
                                    `${responseObra?.visit_date?.value.replace(
                                      ' ',
                                      'T',
                                    )}`,
                                  ),
                                  'dd/MM/yyyy',
                                )
                              : ''}
                          </span>
                        </DataCell>

                        <DataCell>
                          <strong>Data ou previsão de fim das filmagens</strong>
                          <span>
                            {responseObra &&
                            validateDate(
                              responseObra?.visit_date_fim?.value,
                              'yyyy-MM-dd HH:mm:ss',
                            )
                              ? format(
                                  new Date(
                                    `${responseObra?.visit_date_fim?.value.replace(
                                      ' ',
                                      'T',
                                    )}`,
                                  ),
                                  'dd/MM/yyyy',
                                )
                              : ''}
                          </span>
                        </DataCell>

                        <DataCell>
                          <strong>Previsão de estreia</strong>
                          <span>
                            {responseObra?.previsao_estreia?.value
                              ? format(
                                  new Date(
                                    `${responseObra?.previsao_estreia?.value}T00:00:00`,
                                  ),
                                  'MM/yyyy',
                                )
                              : ''}
                          </span>
                        </DataCell>
                      </GridContainer>
                    </ApprovalContainer>
                  </Wrap>

                  <Wrap>
                    <ApprovalContainer
                      hasApprovalFields={hasApprovedFieldsVeiculacao}
                    >
                      {hasApprovedFieldsVeiculacao && (
                        <BoxHeader>
                          <span className="boxText">
                            Alterações a serem analisadas pela SPFilm
                          </span>
                        </BoxHeader>
                      )}

                      <GridContainer cols={4}>
                        <DataCell>
                          <strong>Veiculação inicial prevista</strong>
                          <span>
                            {responseObra?.ld_veiculacao_inicial?.value.map(
                              ({ name }) => `${name}; `,
                            )}
                          </span>
                        </DataCell>

                        {Object.keys(responseObra?.ld_canais_abertos || [])
                          .length > 0 && (
                          <DataCell>
                            <strong>Televisão - Canais Abertos</strong>
                            <span>
                              {responseObra?.ld_canais_abertos?.map(
                                ({ name }) => `${name}; `,
                              )}
                            </span>
                          </DataCell>
                        )}

                        {Object.keys(responseObra?.ld_canais_filme || [])
                          .length > 0 && (
                          <DataCell>
                            <strong>Filmes/Séries</strong>
                            <span>
                              {responseObra?.ld_canais_filme?.map(
                                ({ name }) => `${name}; `,
                              )}
                            </span>
                          </DataCell>
                        )}

                        {Object.keys(responseObra?.ld_canais_infantil || [])
                          .length > 0 && (
                          <DataCell>
                            <strong>Infantis</strong>
                            <span>
                              {responseObra?.ld_canais_infantil?.map(
                                ({ name }) => `${name}; `,
                              )}
                            </span>
                          </DataCell>
                        )}

                        {Object.keys(responseObra?.ld_canais_noticia || [])
                          .length > 0 && (
                          <DataCell>
                            <strong>Notícias</strong>
                            <span>
                              {responseObra?.ld_canais_noticia?.map(
                                ({ name }) => `${name}; `,
                              )}
                            </span>
                          </DataCell>
                        )}

                        {Object.keys(
                          responseObra?.ld_canais_documentarios || [],
                        ).length > 0 && (
                          <DataCell>
                            <strong>Documentários</strong>
                            <span>
                              {responseObra?.ld_canais_documentarios?.map(
                                ({ name }) => `${name}; `,
                              )}
                            </span>
                          </DataCell>
                        )}

                        {Object.keys(responseObra?.ld_canais_variedade || [])
                          .length > 0 && (
                          <DataCell>
                            <strong>Variedades</strong>
                            <span>
                              {responseObra?.ld_canais_variedade?.map(
                                ({ name }) => `${name}; `,
                              )}
                            </span>
                          </DataCell>
                        )}

                        {Object.keys(responseObra?.ld_canais_esporte || [])
                          .length > 0 && (
                          <DataCell>
                            <strong>Esportes</strong>
                            <span>
                              {responseObra?.ld_canais_esporte?.map(
                                ({ name }) => `${name}; `,
                              )}
                            </span>
                          </DataCell>
                        )}

                        {Object.keys(responseObra?.ld_streaming || []).length >
                          0 && (
                          <DataCell>
                            <strong>Plataforma de Streaming</strong>
                            <span>
                              {responseObra?.ld_streaming?.map(
                                ({ name }) => `${name}; `,
                              )}
                            </span>
                          </DataCell>
                        )}

                        {typeof responseObra?.outro_veiculacao === 'string' && (
                          <DataCell>
                            <strong>Outros</strong>
                            <span>{responseObra?.outro_veiculacao}</span>
                          </DataCell>
                        )}
                      </GridContainer>
                    </ApprovalContainer>
                  </Wrap>

                  <Wrap>
                    <GridContainer cols={4}>
                      <DataCell>
                        <strong>Produção independente</strong>
                        <span>
                          {responseObra?.is_indie === 1 ? 'Sim' : 'Não'}
                        </span>
                      </DataCell>

                      <DataCell>
                        <strong>Production Service</strong>
                        <span>
                          {responseObra?.is_production_service === 1
                            ? 'Sim'
                            : 'Não'}
                        </span>
                      </DataCell>

                      <DataCell>
                        <strong>Coprodução</strong>
                        <span>
                          {responseObra?.nome_segunda_empresa === 1
                            ? 'Sim'
                            : 'Não'}
                        </span>
                      </DataCell>

                      <DataCell>
                        <strong>Incentivo</strong>
                        <span>
                          {responseObra?.is_program_incentive === 1
                            ? 'Sim'
                            : 'Não'}
                        </span>
                      </DataCell>

                      <DataCell>
                        <strong>Produção Estudantil?</strong>
                        <span>
                          {responseObra?.is_student_production === 1
                            ? 'Sim'
                            : 'Não'}
                        </span>
                      </DataCell>

                      {responseObra?.nome_segunda_empresa === 1 && (
                        <DataCell>
                          <strong>Segunda empresa</strong>
                          <span>
                            {responseObra && responseObra?.nome_segunda_empresa}
                          </span>
                        </DataCell>
                      )}

                      <DataCell>
                        <strong>Estado de origem</strong>
                        <span>{responseObra && responseObra?.state?.name}</span>
                      </DataCell>

                      <DataCell>
                        <strong>Status</strong>
                        <span>
                          {responseObra && responseObra?.work_status?.name}
                        </span>
                      </DataCell>

                      {responseObra?.nome_segunda_empresa === 1 && (
                        <DataCell>
                          <strong>País de origem</strong>
                          <span>
                            {responseObra && responseObra?.country?.name}
                          </span>
                        </DataCell>
                      )}

                      <DataCell>
                        <strong>Responsável pelo atendimento</strong>
                        <span>
                          {responseObra &&
                            responseObra?.responsavel_user?.user_personal_data
                              ?.name}
                        </span>
                      </DataCell>
                    </GridContainer>
                  </Wrap>
                  <Wrap>
                    <DataCell>
                      <strong>Sinopse da Obra</strong>
                      <span>{responseObra && responseObra?.synopsis}</span>
                    </DataCell>
                  </Wrap>

                  <BoxHeader>
                    <span className="boxText">Diretor</span>
                  </BoxHeader>

                  <Wrap>
                    <DataCell>
                      <strong>Nome / Nome Social</strong>
                      <span>
                        {responseObra && responseObra?.director?.name}
                      </span>
                    </DataCell>
                  </Wrap>
                  <Wrap>
                    <DataCell>
                      <strong>Mini currículo</strong>
                      <span>
                        {responseObra && responseObra?.director?.curriculum}
                      </span>
                    </DataCell>
                  </Wrap>

                  <BoxHeader>
                    <span className="boxText">
                      {
                        clientResources.labels.obra
                          .contatoResponsavelSpFilmComission
                      }
                    </span>
                  </BoxHeader>

                  {responseObra &&
                    responseObra?.work_contacts?.map(contact => (
                      <Wrap key={contact.uuid}>
                        <GridContainer cols={4}>
                          <DataCell>
                            <strong>Nome Civil / Nome Social</strong>
                            <span>
                              {contact?.user?.user_personal_data?.name}
                            </span>
                          </DataCell>
                          <DataCell>
                            <strong>E-mail</strong>
                            <span>{contact?.user?.email}</span>
                          </DataCell>
                          <DataCell>
                            <strong>CPF / CNPJ</strong>
                            <span>
                              {contact?.user?.user_personal_data?.cpf ||
                                contact?.user?.user_personal_data?.cnpj}
                            </span>
                          </DataCell>
                          <DataCell>
                            <strong>RG</strong>
                            <span>{contact?.user?.user_personal_data?.rg}</span>
                          </DataCell>
                          <DataCell>
                            <strong>Telefone</strong>
                            <span>
                              {contact?.user?.user_personal_data?.phone_mobile}
                            </span>
                          </DataCell>
                        </GridContainer>
                      </Wrap>
                    ))}

                  <BoxHeader>
                    <span className="boxText">Coordenação</span>
                  </BoxHeader>

                  {responseObra &&
                    responseObra?.work_production_coordinator?.map(
                      coordinator => (
                        <Wrap key={coordinator.uuid}>
                          <GridContainer cols={4}>
                            <DataCell>
                              <strong>Nome Civil / Nome Social</strong>
                              <span>
                                {coordinator?.user?.user_personal_data?.name}
                              </span>
                            </DataCell>
                            <DataCell>
                              <strong>E-mail</strong>
                              <span>{coordinator?.user?.email}</span>
                            </DataCell>
                            <DataCell>
                              <strong>CPF / CNPJ</strong>
                              <span>
                                {coordinator?.user?.user_personal_data?.cpf ||
                                  coordinator?.user?.user_personal_data?.cnpj}
                              </span>
                            </DataCell>
                            <DataCell>
                              <strong>RG</strong>
                              <span>
                                {coordinator?.user?.user_personal_data?.rg}
                              </span>
                            </DataCell>
                            <DataCell>
                              <strong>Telefone</strong>
                              <span>
                                {
                                  coordinator?.user?.user_personal_data
                                    ?.phone_mobile
                                }
                              </span>
                            </DataCell>
                          </GridContainer>
                        </Wrap>
                      ),
                    )}

                  <BoxHeader>
                    <span className="boxText">Direção de Produção</span>
                  </BoxHeader>

                  {responseObra &&
                    responseObra?.work_directors?.map(director => (
                      <Wrap key={director.uuid}>
                        <GridContainer cols={4}>
                          <DataCell>
                            <strong>Nome Civil / Nome Social</strong>
                            <span>
                              {director?.user?.user_personal_data?.name}
                            </span>
                          </DataCell>
                          <DataCell>
                            <strong>E-mail</strong>
                            <span>{director?.user?.email}</span>
                          </DataCell>
                          <DataCell>
                            <strong>CPF / CNPJ</strong>
                            <span>
                              {director?.user?.user_personal_data?.cpf ||
                                director?.user?.user_personal_data?.cnpj}
                            </span>
                          </DataCell>
                          <DataCell>
                            <strong>RG</strong>
                            <span>
                              {director?.user?.user_personal_data?.rg}
                            </span>
                          </DataCell>
                          <DataCell>
                            <strong>Telefone</strong>
                            <span>
                              {director?.user?.user_personal_data?.phone_mobile}
                            </span>
                          </DataCell>
                        </GridContainer>
                      </Wrap>
                    ))}

                  <BoxHeader>
                    <span className="boxText">Produção Executiva</span>
                  </BoxHeader>

                  {responseObra &&
                    responseObra?.work_executive_producer?.map(executive => (
                      <Wrap key={executive.uuid}>
                        <GridContainer cols={4}>
                          <DataCell>
                            <strong>Nome Civil / Nome Social</strong>
                            <span>
                              {executive?.user?.user_personal_data?.name}
                            </span>
                          </DataCell>
                          <DataCell>
                            <strong>E-mail</strong>
                            <span>{executive?.user?.email}</span>
                          </DataCell>
                          <DataCell>
                            <strong>CPF / CNPJ</strong>
                            <span>
                              {executive?.user?.user_personal_data?.cpf ||
                                executive?.user?.user_personal_data?.cnpj}
                            </span>
                          </DataCell>
                          <DataCell>
                            <strong>RG</strong>
                            <span>
                              {executive?.user?.user_personal_data?.rg}
                            </span>
                          </DataCell>
                          <DataCell>
                            <strong>Telefone</strong>
                            <span>
                              {
                                executive?.user?.user_personal_data
                                  ?.phone_mobile
                              }
                            </span>
                          </DataCell>
                        </GridContainer>
                      </Wrap>
                    ))}
                </div>
              </TabPanel>
              <TabPanel>
                <Wrap>
                  <table style={{ width: '100%' }}>
                    <TableHeader style={{ justifyContent: 'space-between' }}>
                      <tr>
                        <th style={{ marginLeft: 10 }}>Nº da Locação</th>
                        <th>Nome</th>
                        <th>Tipo</th>
                        <th>Status</th>
                        <th style={{ marginRight: 10, width: '25%' }}>
                          {responseObra?.work_status?.name !== 'Finalizado' ? (
                            <Link
                              to={{
                                pathname: '/solicitacao-nova-locacao',
                                state: {
                                  uuid: responseObra?.uuid,
                                  nome_projeto: responseObra?.nome_projeto,
                                },
                              }}
                            >
                              <Button
                                btType="#26A2B1"
                                label="Nova"
                                full
                                width="200px"
                              />
                            </Link>
                          ) : (
                            'Ações'
                          )}
                        </th>
                      </tr>
                    </TableHeader>
                    <tbody style={{ width: '100%' }}>
                      {renderLocacao()}
                      <BasicPagination />
                    </tbody>
                  </table>
                </Wrap>
              </TabPanel>
              <TabPanel>
                <Wrap>
                  <table style={{ width: '100%' }}>
                    <TableHeader style={{ justifyContent: 'space-between' }}>
                      <tr>
                        <th style={{ marginLeft: 10 }}>N.º Locação</th>
                        <th>Nome Obra</th>
                        <th>Data Visita</th>
                        <th>Status</th>
                        <th style={{ marginRight: 10, width: '25%' }}>Ações</th>
                      </tr>
                    </TableHeader>
                    <tbody style={{ width: '100%' }}>
                      {renderVisitaTecnica()}
                      <BasicPagination />
                    </tbody>
                  </table>
                </Wrap>
              </TabPanel>
            </Tabs>
          </WhiteBox>
        </PageContent>
      </PageContainer>

      <PageFooter>
        <img
          src={clientResources.images.system.spcineLogo}
          alt={clientResources.images.system.altLogo}
        />
        <img
          src={clientResources.images.system.culturaSpLogo}
          alt={clientResources.images.system.altCultura}
        />
      </PageFooter>
    </>
  );
}

export default withRouter(Obras);
