import axios from "axios";
import querystring from "querystring";
import tenancy_env from "~/utils/MultiTenancyUtil";

const headers = {
  Accept: "application/json",
  // Authorization: tryGetToken(),
};

function makeUrl() {
  const baseUrl = tenancy_env('REACT_APP_API_BASE_URL');
  const prefix = tenancy_env('REACT_APP_API_PREFIX');
  const version = tenancy_env('REACT_APP_API_VERSION');
  const url = `${baseUrl}/${prefix}/${version}`;

  return url;
}

const api = axios.create({
  baseURL: makeUrl(),
  // headers,
});

const queryBuilder = (params) => {
  const cleanParams = {};
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) cleanParams[key] = value;
  });
  return querystring.stringify(cleanParams);
};

const makeOptions = (authData) => ({
  headers: {
    ...headers,
    ...(authData && {
      Authorization: `Bearer ${authData.access_token}`,
    }),
  },
});

export default api;
export { makeOptions, queryBuilder };
