/* eslint-disable */

import moment from "moment";

let dateUtil = null;

const FORMAT_DATE = 'DD/MM/YYYY';
const FORMAT_DATE_TIME = 'DD/MM/YYYY hh:mm:ss';
const FORMAT_DATE_API = 'YYYY-MM-DD';

class DateUtil {
  constructor(dateObject) {
    this.dateObject = moment(dateObject);
  }

  static raw(date) {
    dateUtil = new DateUtil(date);
    return dateUtil;
  }

  toDate() {
    return this.dateObject.format(FORMAT_DATE);
  }

  toDateTime() {
    return this.dateObject.format(FORMAT_DATE_TIME);
  }

  toApiFormat() {
    return this.dateObject.format(FORMAT_DATE_API);
  }

  isBeforeNow() {
    const dateNow = moment();

    return dateNow.isBefore(this.dateObject);
  }


}

export default DateUtil;
