import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';

function LocacaoCard({ label, route, workUuid, text, nomeProjeto }) {
  const url = `/solicitacao-nova-locacao${route}`;

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedContent, setCollapsedContent] = useState(false);

  function handleCaretClick(e) {
    e.preventDefault();
    setCollapsed(!collapsed);
    setCollapsedContent(!collapsedContent);
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#e8e8e8',
        width: '100%',
        borderRadius: 15,
        maxHeight: 300,
        minHeight: 70,
        flexDirection: 'column',
        marginBottom: 15,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'row',
          height: 50,
        }}
      >
        <Link
          to={{
            pathname: url,
            state: { uuid: workUuid, nome_projeto: nomeProjeto },
          }}
          style={{ textDecoration: 'none', width: '80%' }}
        >
          <span
            style={{
              color: '#828282',
              fontWeight: 'bold',
              fontSize: 20,
            }}
          >
            {label}
          </span>
        </Link>

        <div style={{ width: '10%' }}>
          <button type="button" onClick={handleCaretClick}>
            <span>
              {collapsed === true ? (
                <AiFillCaretDown color="#828282" size={20} />
              ) : (
                <AiFillCaretRight color="#828282" size={20} />
              )}
            </span>
          </button>
        </div>
      </div>
      {text && collapsedContent === true && (
        <div style={{ height: 200, width: '95%' }}>
          <span
            style={{
              color: '#828282',
              fontWeight: 'bold',
              fontSize: 17,
              textAlign: 'justify',
            }}
          >
            {text}
          </span>
        </div>
      )}
    </div>
  );
}

export default withRouter(LocacaoCard);
