import React from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import { Container, CheckButtons, CheckButton } from './styles';

export default function ChoiceButton({
  label,
  disableCursor,
  selected,
  onClick,
  marginTop,
  questionTooltip,
  questionOnClick,
}) {
  return (
    <Container marginTop={marginTop}>
      <p>
        {label}
        {questionTooltip && (
          <button
            type="button"
            tooltip={questionTooltip}
            onClick={questionOnClick}
            style={{ marginLeft: 5 }}
          >
            <BsFillQuestionCircleFill color="#4f4f4f" size={16} />
          </button>
        )}
      </p>
      <CheckButtons>
        <CheckButton
          type="button"
          onClick={onClick}
          disableCursor={disableCursor}
          selected={selected === true}
        >
          Sim
        </CheckButton>
        <CheckButton
          type="button"
          onClick={onClick}
          disableCursor={disableCursor}
          selected={selected === false}
        >
          Não
        </CheckButton>
      </CheckButtons>
    </Container>
  );
}
