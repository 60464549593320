import React, { useState, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { BsFillPersonPlusFill, BsFillEyeFill } from 'react-icons/bs';
import { GoClippy } from 'react-icons/go';
import { FaClipboard, FaClipboardCheck, FaClipboardList, FaPen } from 'react-icons/fa';
import { AiFillFlag } from 'react-icons/ai';
import { CgMenuGridR } from 'react-icons/cg';

import { format } from 'date-fns';
import {
  ActionsContainer,
  ActionsIcon,
  FlagIcon,
  ResponsavelButton,
} from './styles';
import { ModalAdicionaResponsavel, ModalStatusObra } from '~/components/Admin';
import Documents from '~/components/Documents/Documents';
import { showModalMessage } from '~/services/notification';
import ApiClient from '~/utils/ApiClient';
import { GridContainer } from '~/styles/components';

function ListaPageObras({ data, workStatusList, loadWorks }) {
  const [openModalStatusObra, setOpenModalStatusObra] = useState(false);
  const [openModalAdiciona, setOpenModalAdiciona] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModalStatusObra(false);
    setOpenModalAdiciona(false);
  }, []);

  const handleWorkPriority = useCallback(async () => {
    try {
      await ApiClient.doRequest({
        uri: `work/${data?.uuid}`,
        method: ApiClient.PATCH,
        body: {
          priority: !data?.priority,
          ld_veiculacao_inicial: [],
          ld_streaming: [],
          ld_canais_abertos: [],
          ld_canais_filme: [],
          ld_canais_infantil: [],
          ld_canais_noticia: [],
          ld_canais_documentarios: [],
          ld_canais_variedade: [],
          ld_canais_esporte: [],
        },
        expectedStatus: 201,
      });

      await showModalMessage({
        type: 'success',
        text: `Obra ${
          data?.priority ? 'desmarcada' : 'marcada'
        } como prioridade`,
      });

      loadWorks();
    } catch (error) {
      await showModalMessage({
        type: 'error',
        text: 'Erro ao tentar mudar prioridade de Obra',
      });
    }
  }, [data?.priority, data?.uuid, loadWorks]);

  return (
    <>
      <tr>
        <td>
          {data && format(new Date(data?.created_at), 'dd/MM/yyyy HH:mm')}
        </td>
        <td>{data && data?.protocol}</td>
        <td>{data && data?.titulo_obra}</td>
        <td>{data && data?.branch?.fantasy_name}</td>
        <td>
          {data && !data?.branch
            ? data?.pessoa_fisica_user?.user_personal_data?.name
            : data?.branch?.company_name}
        </td>
        <td style={{ justifyContent: 'center' }}>
          {data && data?.count_locacoes}
        </td>

        <td style={{ justifyContent: 'space-evenly' }}>
          {data && !data.responsavel_user && (
            <ResponsavelButton
              type="button"
              onClick={() => setOpenModalAdiciona(true)}
            >
              <BsFillPersonPlusFill color="#FFF" size={20} />
            </ResponsavelButton>
          )}
          {data && data.responsavel_user && (
            <>
              <span tooltip="Mudar responsável...">
                <button
                  type="button"
                  onClick={() => setOpenModalAdiciona(true)}
                >
                  <GoClippy color="#4f4f4f" size={22} />
                </button>
              </span>
              <span>{data.responsavel_user.user_personal_data.name}</span>
            </>
          )}
        </td>

        <td style={{ justifyContent: 'space-evenly' }}>
          <FlagIcon isMarked={data?.priority}>
            <span
              tooltip={`${
                data?.priority ? 'Desmarcar' : 'Marcar'
              } como Prioridade`}
            >
              <AiFillFlag size={24} onClick={() => handleWorkPriority()} />
            </span>
          </FlagIcon>
          <span tooltip="Gerar documentos...">
            <Documents iconOnly documents={data.documents} uuid={data.uuid} />
          </span>
          <ActionsIcon>
            <ActionsContainer>
              <GridContainer cols={3} gap={20}>
                <span tooltip="Mudar status...">
                  <button
                    type="button"
                    onClick={() => setOpenModalStatusObra(true)}
                  >
                    <GoClippy color="#4f4f4f" size={22} />
                  </button>
                </span>
                <span tooltip="Mais detalhes...">
                  <Link
                    to={{
                      pathname: '/obra',
                      state: { data },
                    }}
                  >
                    <BsFillEyeFill color="#4f4f4f" size={24} />
                  </Link>
                </span>
                <span tooltip="Editar...">
                  <Link
                    to={{
                      pathname: '/obra/edit',
                      state: { data },
                    }}
                    style={{ margin: 2 }}
                  >
                    <FaPen color="#4f4f4f" size={20} />
                  </Link>
                </span>

                {data?.pesquisa_uuid && (
                  <span tooltip="Visualizar Pesquisa Socioeconômica...">
                    <Link
                      to={{
                        pathname: '/pesquisas/pesquisa-socioeconomica',
                        state: {
                          viewData: { uuid: data?.pesquisa_uuid },
                          nome_projeto: data?.director?.name,
                        },
                      }}
                    >
                      <FaClipboardCheck color="#4f4f4f" size={24} />
                    </Link>
                  </span>
                )}
                {data?.work_status?.name === 'Finalizado' && !data?.pesquisa_uuid && (
                  <span tooltip="Preencher Pesquisa Socioeconômica...">
                    <Link
                      to={{
                        pathname: '/pesquisas/pesquisa-socioeconomica',
                        state: { dados: data, nome_projeto: data?.director?.name },
                      }}
                    >
                      <FaClipboardList color="#4f4f4f" size={24} />
                    </Link>
                  </span>
                )}
              </GridContainer>
            </ActionsContainer>
            <CgMenuGridR color="#4f4f4f" size={22} />
          </ActionsIcon>
        </td>

        {/* <td style={{ justifyContent: 'space-evenly' }}>
          <FlagIcon isMarked={data?.priority}>
            <span
              tooltip={`${
                data?.priority ? 'Desmarcar' : 'Marcar'
              } como Prioridade`}
            >
              <AiFillFlag size={24} onClick={() => handleWorkPriority()} />
            </span>
          </FlagIcon>

          <span tooltip="Mudar status...">
            <button type="button" onClick={() => setOpenModalStatusObra(true)}>
              <GoClippy color="#4f4f4f" size={22} />
            </button>
          </span>
          <span tooltip="Mais detalhes...">
            <Link
              to={{
                pathname: '/obra',
                state: { data },
              }}
            >
              <BsFillEyeFill color="#4f4f4f" size={24} />
            </Link>
          </span>
          <span tooltip="Editar...">
            <Link
              to={{
                pathname: '/obra/edit',
                state: { data },
              }}
              style={{ margin: 2 }}
            >
              <FaPen color="#4f4f4f" size={20} />
            </Link>
          </span>
          {data?.pesquisa_uuid && (
            <span tooltip="Visualizar Pesquisa Socioeconômica...">
              <Link
                to={{
                  pathname: '/pesquisas/pesquisa-socioeconomica',
                  state: {
                    viewData: { uuid: data?.pesquisa_uuid },
                    nome_projeto: data?.director?.name,
                  },
                }}
              >
                <FaClipboardCheck color="#4f4f4f" size={24} />
              </Link>
            </span>
          )}
          <span tooltip="Gerar documentos...">
            <Documents iconOnly documents={data.documents} uuid={data.uuid} />
          </span>
        </td> */}
      </tr>
      <ModalAdicionaResponsavel
        obra={data}
        openModal={openModalAdiciona}
        closeModal={handleCloseModal}
        loadWorks={loadWorks}
      />

      <ModalStatusObra
        workStatusList={workStatusList}
        obra={data}
        openModal={openModalStatusObra}
        closeModal={handleCloseModal}
        loadWorks={loadWorks}
      />
    </>
  );
}

export default withRouter(ListaPageObras);
