import styled from "styled-components";

export const DocumentCard = styled.button`
  color: "#000";
  display: flex;
  flex-direction: "row";
  justify-content: "space-around";
  min-width: 100%;
  height: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  margin: 5px;
`;
