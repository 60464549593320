import React from "react";
import { withRouter, Link } from "react-router-dom";
import { AiOutlineFile } from "react-icons/ai";
import { DocumentCard } from "./styles";

function Documento({ data }) {

  return (
    <>
      <a href={data?.url} target="blank">
        <DocumentCard
          type="button"
          style={{ alignContent: "center", justifyContent: "center" }}
          >
          <div style={{ width: "20%" }}>
            <AiOutlineFile size={40} color="#828282" />
          </div>
          <div style={{ alignSelf: "center" }}>
            <span
              style={{
                color: "#828282",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 17,
                height: 25,
              }}>
              {data?.name}
            </span>
          </div>
        </DocumentCard>
      </a>
    </>
  );
}

export default withRouter(Documento);
