import React, { useRef, useState, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
// import { Input } from '~/components/Forms';

// import { format } from "date-fns";
// import pt from "date-fns/locale/pt";

import { FiLoader } from 'react-icons/fi';
import { PageContainer, TableHeader } from '~/styles/components';

import Aside from '~/components/Aside';
import { Button } from '~/components/global';

import { Container, Wrap, WrapButtons, TitleForm } from './styles';

import { ModalLembrete } from '~/components/Admin';

import ListPageAvisos from '~/components/Admin/Home/ListPageAvisos';

import ApiClient from '~/utils/ApiClient';
import WhiteBox from '~/components/WhiteBox';

const Avisos = () => {
  const [openModalLembrete, setOpenModalLembrete] = useState(false);
  const [reminderList, setReminderList] = useState([]);
  const [loading, setLoading] = useState(false);

  const formRef = useRef();

  async function handleSubmit() {
    try {
      formRef.current.setErrors({});

      // dispatch(CompanyActions.createRequest(formData));
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function load() {
    try {
      setLoading(true);
      const result = await ApiClient.doRequest({
        uri: 'reminder?order[id]=desc',
        method: ApiClient.GET,
        expectedStatus: 200,
      });

      setReminderList(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    load();
  }, []);

  function renderList() {
    if (loading) {
      return <FiLoader size={20} className="icon-spin" />;
    }

    if (!loading && !reminderList.length) {
      return 'Nenhum registro encontrado';
    }

    return (
      <>
        {reminderList.map(item => (
          <ListPageAvisos
            key={item.uuid}
            data={item}
            reloadList={load}
            // onDelete={() => handleDelete(lista.id)}
          />
        ))}
      </>
    );
  }

  return (
    <PageContainer>
      <Aside />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Wrap>
            <TitleForm>
              <h4>Avisos</h4>
            </TitleForm>
          </Wrap>
          <Wrap>
            <WrapButtons>
              <Button
                type="submit"
                btType="#26A2B1"
                action={() => {
                  setOpenModalLembrete(true);
                }}
                label="Criar"
                full
                width="170px"
              />
            </WrapButtons>
          </Wrap>
        </Form>

        <WhiteBox column maxHeight>
          <table>
            <TableHeader>
              <tr>
                <th>Título</th>
                <th>Conteúdo</th>
                <th>Prazo</th>
                <th>Tipo</th>
                <th>Ações</th>
              </tr>
            </TableHeader>
            <WhiteBox column aCenter>
              <tbody>{renderList()}</tbody>
            </WhiteBox>
          </table>
        </WhiteBox>
      </Container>
      <ModalLembrete
        openModal={openModalLembrete}
        closeModal={() => setOpenModalLembrete(false)}
        loadReminder={load}
      />
    </PageContainer>
  );
};

export default Avisos;
