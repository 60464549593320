import React, { useContext, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { ThemeContext } from '~/styles/ThemeProvider';
import { Container, BoxImage, Text, NavItem, DropdownMenu } from './styles';

export default function ItemAside({
  active,
  btType,
  route,
  Icon,
  text,
  submenu,
  link,
  title,
  counter,
}) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const { theme } = useContext(ThemeContext);

  return (
    <Container
      active={active}
      btType={btType}
      href={route}
      colorTheme={theme.text_color}
      counter={counter}
    >
      {/* <BoxImage colorTheme={theme.text_color}>
        <Icon active={active} />
      </BoxImage> */}
      <Text active={active} colorTheme={theme.text_color}>
        {text}
      </Text>
    </Container>
  );
}
